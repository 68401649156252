import { Component } from '@angular/core';

@Component({
  selector: 'app-form-control',
  standalone: true,
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        gap: var(--size-space-b);
      }
    `,
  ],
})
export class FormControlComponent {}
