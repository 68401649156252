import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  InputTextModule,
  OverflowGradientModule,
  RadioButtonModule,
  SheetModule,
  ToastModule,
} from '@intemp/unijob-ui';
import { VacancyUnibaseContactImportComponent } from './unibase-contact-import.component';
import { FormsModule } from '@angular/forms';
import {
  ButtonSolidComponent,
  InputContainerComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';

@NgModule({
  declarations: [VacancyUnibaseContactImportComponent],
  exports: [VacancyUnibaseContactImportComponent],
  imports: [
    SharedDefaultModule,
    SheetModule,
    OverflowGradientModule,
    FormsModule,
    InputTextModule,
    RadioButtonModule,
    ToastModule,
    ButtonSolidComponent,
    InputContainerComponent,
    TextInputComponent,
  ],
})
export class VacancyUnibaseContactImportModule {}
