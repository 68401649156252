import { inject, Injectable, signal } from '@angular/core';
import {
  MatchFragment,
  MatchUpdateGQL,
  MatchUpdateInput,
} from '../../../../graphql/generated';
import { ToastService } from '@intemp/unijob-ui2';
import { I18NextPipe } from 'angular-i18next';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import { filter } from 'rxjs/operators';

@Injectable()
export class MatchSheetFormService {
  toastService = inject(ToastService);
  i18next = inject(I18NextPipe);
  matchUpdateGQL = inject(MatchUpdateGQL);

  responsibleUserId = new FormControl<string | null>(null);
  internalRemarks = new FormControl<string | null>(null);

  match = signal<MatchFragment | null>(null);

  constructor() {
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    this.responsibleUserId.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(() => this.responsibleUserId.dirty),
      )
      .subscribe((value) => {
        this.submitFieldApiUpdate('responsibleUserId', value);
      });

    this.internalRemarks.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(() => this.internalRemarks.dirty),
      )
      .subscribe((value) => {
        this.submitFieldApiUpdate('internalRemarks', value);
      });
  }

  patchForm(match: MatchFragment) {
    this.responsibleUserId.patchValue(match.responsibleUser.userId, {
      emitEvent: false,
    });
    this.internalRemarks.patchValue(match.internalRemarks, {
      emitEvent: false,
    });
    this.match.set(match);
  }

  submitFieldApiUpdate(key: keyof MatchUpdateInput, value: any) {
    const uuid = this.match()?.uuid;
    if (!uuid) throw new Error('Match UUID is missing');
    this.matchUpdateGQL
      .mutate({
        input: {
          [key]: value,
          uuid,
        },
      })
      .subscribe();
  }
}
