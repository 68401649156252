<div class="uj-flex uj-flex-col uj-gap-f">
  <ng-container *ngIf="!environment.hideBetaTalentFields">
    <unijob-info-box
      [message]="'availabilityWillBeDisplayAtInterviewAndSynced' | i18next"
      type="INFO"></unijob-info-box>
  </ng-container>
  <div
    class="uj-flex uj-flex-col uj-gap-c"
    *ngIf="
      availabilityDataForm.controls.workAvailability.value as workAvailability
    ">
    <ui-library-form-field
      fullWidth
      required
      [label]="'availability' | i18next | firstUppercase"
      [labelFor]="'availability' + componentId">
      <ui-library-simple-select
        [formControl]="availabilityDataForm.controls.workAvailability"
        [placeholderText]="'choose' | i18next"
        [options]="availabilityOptions"
        size="M"
        design="outline"
        backgroundColor="white"></ui-library-simple-select>
    </ui-library-form-field>

    @if (workAvailability === WorkAvailabilityEnum.OPEN_TO_WORK) {
      <ui-library-form-field
        fullWidth
        [label]="'availableForWorkAt' | i18next | firstUppercase"
        [labelFor]="'availableForWorkAt' + componentId">
        <unijob-date-picker
          [formControl]="availabilityDataForm.controls.availableForWorkAt"
          [placeholder]="'selectDate' | i18next"
          mode="single"
          format="d.m.Y" />
      </ui-library-form-field>
    } @else if (workAvailability === WorkAvailabilityEnum.IN_EMPLOYMENT) {
      <div class="uj-flex uj-flex-col sm:uj-flex-row uj-gap-c">
        <ng-container [ngTemplateOutlet]="companyInput"></ng-container>
        <div class="uj-basis-1/2">
          <ui-library-form-field
            fullWidth
            [label]="'employedUntil' | i18next | firstUppercase"
            [labelFor]="'employedUntil' + componentId">
            <unijob-date-picker
              [formControl]="availabilityDataForm.controls.employedUntil"
              [placeholder]="'selectDate' | i18next"
              mode="single"
              format="d.m.Y" />
          </ui-library-form-field>
        </div>
      </div>
    } @else if (workAvailability === WorkAvailabilityEnum.PLACED) {
      <div class="uj-flex uj-flex-col sm:uj-flex-row uj-gap-c">
        <ng-container [ngTemplateOutlet]="companyInput"></ng-container>

        <div class="uj-basis-1/2">
          <ui-library-form-field
            fullWidth
            [label]="'since' | i18next | firstUppercase"
            [labelFor]="'employedSince' + componentId">
            <unijob-date-picker
              [id]="'employedSince' + componentId"
              [formControl]="availabilityDataForm.controls.employedSince"
              [placeholder]="'selectDate' | i18next"
              mode="single"
              format="d.m.Y" />
          </ui-library-form-field>
        </div>
      </div>
    } @else if (workAvailability === WorkAvailabilityEnum.BLACKLISTED) {
      <ng-container
        [ngTemplateOutlet]="showBlacklistedReasonInput"></ng-container>
    }
  </div>
  <div class="uj-flex uj-flex-col uj-gap-c">
    <unijob-checkbox
      [formControl]="availabilityDataForm.controls.registeredWithRAV"
      [label]="'registeredWithRAV' | i18next"></unijob-checkbox>
    <unijob-checkbox
      [formControl]="availabilityDataForm.controls.nonCompeteAgreement"
      [label]="'nonCompeteAgreement' | i18next"></unijob-checkbox>

    <unijob-checkbox
      [formControl]="availabilityDataForm.controls.mustServeMilitaryService"
      [label]="'mustServeMilitaryService' | i18next"></unijob-checkbox>
    <div
      class="uj-ml-[36px]"
      *ngIf="availabilityDataForm.controls.mustServeMilitaryService.value">
      <ui-library-form-field
        fullWidth
        [label]="'nextMilitaryServiceAt' | i18next | firstUppercase"
        [labelFor]="'nextMilitaryServiceAt' + componentId">
        <unijob-date-picker
          [id]="'nextMilitaryServiceAt' + componentId"
          [formControl]="availabilityDataForm.controls.nextMilitaryServiceAt"
          mode="single"
          language="de"
          [placeholder]="'selectDate' | i18next"
          format="d.m.Y" />
      </ui-library-form-field>
    </div>
  </div>

  <ui-library-form-field
    fullWidth
    [label]="'remark' | i18next | firstUppercase"
    [labelFor]="'availabilityNote' + componentId">
    <unijob-textarea
      [id]="'availabilityNote' + componentId"
      [formControl]="availabilityDataForm.controls.availabilityNote"
      [placeholder]="'addRemark' | i18next"
      elevation="outline"
      theme="white"></unijob-textarea>
  </ui-library-form-field>
</div>

<ng-template #companyInput>
  <ui-library-form-field
    class="uj-basis-1/2"
    [label]="'companies' | i18next | firstUppercase"
    [labelFor]="'companies' + componentId">
    <input
      ui-library-text-input
      [id]="'companies' + componentId"
      [placeholder]="'companies' | i18next"
      [formControl]="availabilityDataForm.controls.employmentCompany"
      type="text" />
  </ui-library-form-field>
</ng-template>

<ng-template #showBlacklistedReasonInput>
  <ui-library-form-field
    fullWidth
    required
    [label]="'reason' | i18next | firstUppercase"
    [labelFor]="'reason' + componentId">
    <input
      ui-library-text-input
      [id]="'reason' + componentId"
      [placeholder]="'reason' | i18next"
      [formControl]="availabilityDataForm.controls.blackListedReason"
      type="text" />
  </ui-library-form-field>
</ng-template>

<ui-library-modal
  [id]="blacklistConfirmationModalId"
  size="S">
  <ng-container slot="header">
    {{ "setTalentToBlackList" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <unijob-info-box
      type="WARNING"
      [message]="
        'attentionOnlyUserWithPermissionUndoBlacklist' | i18next
      "></unijob-info-box>
    <ng-container
      [ngTemplateOutlet]="showBlacklistedReasonInput"></ng-container>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="cancelChanges()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="confirmBlackListing()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button">
        {{ "confirm" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>
