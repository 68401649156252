import {
  AfterViewInit,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  CheckboxModule,
  ContextMenuModule,
  DatePickerModule,
  IconModule,
  InputTextModule,
  OverflowGradientModule,
  randomId,
  SheetModule,
  TabModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { UserAvatarModule } from '../../user-avatar/user-avatar.module';
import { VacanciesStatusIndicatorModule } from '../../vacancies-status-indicator/vacancies-status-indicator.module';
import { LetDirective } from '@ngrx/component';
import { UserInfoContextMenuModule } from '../../user/user-info-context-menu/user-Info-context-menu.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { getSalutationOptions } from '../helpers/getSalutationOptions';
import { TalentFormEditService } from '../services/talentFormEdit.service';
import { TalentChildrenComponent } from './talent-children/talent-children.component';
import { FocusTrackerDirective } from '../../vacancy-detail/focusTracker.directive';
import { TalentNationalityComponent } from './talent-nationality/talent-nationality.component';
import { getDriversLicenseOptions } from '../helpers/getDriversLicenseOptions';
import {
  AvatarComponent,
  FormFieldComponent,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';
import { getReligiousDenominationOptions } from '../helpers/getReligiousDenominationOptions';
import { getMaritalStatusOptions } from '../helpers/getMaritalStatusOptions';
import { TalentAhvNumberComponent } from './talent-ahvNumber/talent-ahv-number.component';
import { FormControlComponent } from '../../../../shared/components/form-control/form-control.component';
import { InputLabelComponent } from '../../../../shared/components/input-label/input-label.component';
import { TalentAvatarInputComponent } from './talent-avatar-input/talent-avatar-input.component';
import { environment } from '../../../../../environments/environment';
import { VacancyPublishableFieldsEnum } from '../../../../graphql/generated';
import { IMaskDirective } from 'angular-imask';
import { FactoryArg } from 'imask';

@Component({
  standalone: true,
  selector: 'app-talent-personal-data',
  templateUrl: './talent-personal-data.component.html',
  imports: [
    SheetModule,
    AsyncPipe,
    AvatarComponent,
    I18NextModule,
    IconModule,
    NgIf,
    OverflowGradientModule,
    SharedDefaultModule,
    TabModule,
    UserAvatarModule,
    VacanciesStatusIndicatorModule,
    LetDirective,
    UserInfoContextMenuModule,
    ContextMenuModule,
    FormsModule,
    ReactiveFormsModule,
    UjSelectInputModule,
    InputTextModule,
    DatePickerModule,
    TalentChildrenComponent,
    TalentNationalityComponent,
    SimpleSelectComponent,
    CheckboxModule,
    TalentAhvNumberComponent,
    TalentAvatarInputComponent,
    FormControlComponent,
    InputLabelComponent,
    FormFieldComponent,
    TextInputComponent,
    IMaskDirective,
  ],
  providers: [],
})
export class TalentPersonalDataComponent implements AfterViewInit {
  i18next = inject(I18NextPipe);
  formService = inject(TalentFormEditService);

  salutationOptions = getSalutationOptions(this.i18next);
  driversLicenseOptions = getDriversLicenseOptions(this.i18next);
  religiousDenominationOptions = getReligiousDenominationOptions(this.i18next);
  maritalStatusOptions = getMaritalStatusOptions(this.i18next);
  ibanControl = this.formService.personalData.controls.iban;

  personalDataForm = this.formService.personalData;

  componentId = randomId();

  ibanMask: FactoryArg = {
    mask: '{aa}00 0000 0000 0000 0000 0',
    prepareChar: (char: string) => {
      if (char.match(/[a-zA-Z]/)) {
        return char.toUpperCase();
      }
      return char;
    },
  };

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  ngAfterViewInit() {
    this.listenToFocusTrackerChange();
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }

  protected readonly environment = environment;
  protected readonly VacancyPublishableFieldsEnum =
    VacancyPublishableFieldsEnum;
}
