import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ContextMenuModule,
  IconModule,
  InputTextModule,
  SheetModule,
  TooltipModule,
  UnijobModule,
} from '@intemp/unijob-ui';
import { LetDirective } from '@ngrx/component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserInfoContextMenuModule } from '../user/user-info-context-menu/user-Info-context-menu.module';
import { ChannelSlotsComponent } from './channel-slots.component';
import { RelativeTimePipe } from '../../helpers/pipes/relative-time.pipe';
import { AvatarComponent, TagComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [ChannelSlotsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    AvatarComponent,
    InputTextModule,
    FormsModule,
    UnijobModule,
    SharedDefaultModule,
    ContextMenuModule,
    TooltipModule,
    SheetModule,
    UserAvatarModule,
    UserInfoContextMenuModule,
    LetDirective,
    RelativeTimePipe,
    TagComponent,
  ],
  exports: [ChannelSlotsComponent],
})
export class ChannelSlotsModule {}
