<ui-library-select
  [ngModel]="this.chosenKey | async"
  (ngModelChange)="newKeyChosen($event)"
  [collapsedSelectedItemComponent]="selectedComponent"
  [expandedSelectedItemComponent]="selectedComponent"
  [isSingleSelect]="true"
  [disabled]="disabled">
  <ui-library-textbox-list-item [actionGenerator]="searchFilter" />
  <ui-library-selection-list [behavior]="singleSelectBehavior">
    <ng-template #noItemSelectedTemplate
      ><!-- That case does not exist --></ng-template
    >
    <ng-template #noItemsTemplate>
      <app-avatar-empty-item
        *ngIf="!loading"
        letters="🙈"
        title="{{ 'unfortunatelyNoCompanyFound' | i18next }}"
        description="{{ 'checkSearchInputsOrImportCompany' | i18next }}" />
    </ng-template>
    <app-avatar-list-item
      *ngFor="let entry of options5 | async; trackBy: trackByKey"
      [_value]="entry.key"
      [label]="entry" />
  </ui-library-selection-list>
  <div
    class="uj-m-d uj-flex uj-justify-center uj-py-f"
    *ngIf="loading">
    <app-loading-spinner position="static"></app-loading-spinner>
  </div>
  <div class="uj-mx-d uj-mb-d uj-border-t uj-border-border">
    <p class="uj-text-text-m/[20px] uj-py-d uj-text-text">
      {{ "searchCompanyLocationOptionsFooterInstruction" | i18next }}
    </p>
    <button
      id="companyContact"
      class="uj-w-full"
      ui-library-button-outline
      type="button"
      iconRight="download_arrow"
      iconLeft="company_2"
      theme="flat"
      size="M"
      corner="rounded"
      btnColor="bg-pink"
      (click)="$event.stopPropagation(); importUnibaseCompanySheet.open()">
      {{ "importCompany" | i18next }}
    </button>
  </div>
</ui-library-select>
<app-vacancy-unibase-company-import
  (importComplete)="
    importUnibaseCompanySheet.complete($event);
    importUnibaseCompanySheet.close()
  "></app-vacancy-unibase-company-import>

<ng-template #placeholderAvatar>
  <ui-library-avatar
    content="icon"
    icon="company_2"
    size="s"></ui-library-avatar>
</ng-template>
