import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { randomId, SheetService } from '@intemp/unijob-ui';
import { ToastService } from '@intemp/unijob-ui2';
import { addDays, format, formatDistanceStrict } from 'date-fns';
import { de } from 'date-fns/locale';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { DATE_FORMAT } from '../../../../app.constants';
import { ConsultantService } from '../../../../core/services/consultant.service';
import {
  ChannelExportUniversaljobSearchCommissionSplit,
  ChannelExportUniversaljobSearchOptionEnum,
  ChannelExportUniversaljobSearchPlanEnum,
  ChannelExportUniversaljobSearchPricePerOptionOptionsFragment,
  ChannelExportUniversaljobSearchPricePerPlanOptionsFragment,
  ChannelFragment,
  UniBaseXVacancyChannelConfigurationStatusEnum,
  UniBaseXVacancyChannelFragment,
  UserListItemFragment,
  UserSelfFragment,
} from '../../../../graphql/generated';
import { UserService } from '../../../../models/shared/user/user.service';
import { ChannelsService } from '../../../../models/unibase/channels/channels.service';
import { endDateWithDistance } from '../../../../models/unibase/channels/channels.types';
import { allSearchPlanFeaturePoints } from '../../../../models/unibase/channels/searchPlanFeaturePoint.enum';
import {
  dayIsBefore,
  dayIsInPast,
} from '../../../../shared/helpers/functions/date-helpers/dayIsInPast';
import { filterIsNotEmpty } from '../../../../shared/helpers/functions/filterIsNotEmpty';

@Component({
  selector: 'app-vacancy-promote-search-addon-sheet',
  templateUrl: './vacancy-promote-search-addon-sheet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyPromoteSearchAddonSheetComponent
  implements OnInit, OnDestroy
{
  destroyed$ = new Subject<void>();
  protected readonly sheetId = randomId();

  protected needsToBeRequested = false;

  @Input() recentConfig: UniBaseXVacancyChannelFragment | undefined;

  public searchChannel$ = this.channelsService.channelsSubscription$.pipe(
    map((channels) => {
      return channels.items.find(
        (channel) =>
          channel.exportSettings.universaljobSearch?.enabled === true,
      );
    }),
  );

  public options$ = this.searchChannel$.pipe(
    map((searchChannel) => {
      if (!searchChannel) {
        return [];
      }
      const optionsArray: (ChannelExportUniversaljobSearchPricePerOptionOptionsFragment & {
        optionType: ChannelExportUniversaljobSearchOptionEnum;
      })[] = Object.entries(
        searchChannel.exportSettings?.universaljobSearch?.pricePerOption ?? {},
      ).map(([key, value]) => ({
        optionType: key as ChannelExportUniversaljobSearchOptionEnum,
        ...value,
      }));

      return optionsArray;
    }),
  );

  public plans$ = this.searchChannel$.pipe(
    map((searchChannel) => {
      if (!searchChannel) {
        return [];
      }
      const plansArray: (ChannelExportUniversaljobSearchPricePerPlanOptionsFragment & {
        planType: ChannelExportUniversaljobSearchPlanEnum;
      })[] = Object.entries(
        searchChannel.exportSettings?.universaljobSearch?.pricePerPlan ?? {},
      ).map(([key, value]) => ({
        planType: key as ChannelExportUniversaljobSearchPlanEnum,
        ...value,
      }));

      return plansArray;
    }),
  );

  consultantUsers: (UserListItemFragment | UserSelfFragment)[] = [];
  loggedInUser?: UserSelfFragment;

  // commission data
  commissionTotalCHF = 0;

  firstCommissionUserOptions: (UserListItemFragment | UserSelfFragment)[] = [];
  secondCommissionUserOptions: (UserListItemFragment | UserSelfFragment)[] = [];
  firstCommissionUser?: UserListItemFragment | UserSelfFragment;
  secondCommissionUser?: UserListItemFragment | UserSelfFragment;

  // commission errors
  commissionOverLimit = false;
  noNegativeCommission = false;
  commissionSplitDoesNotAddUpToTotal = false;
  protected selectedPlan$ = new BehaviorSubject<
    ChannelExportUniversaljobSearchPlanEnum | undefined
  >(undefined);
  protected selectedOptions$ = new BehaviorSubject<
    ChannelExportUniversaljobSearchOptionEnum[]
  >([]);

  protected toggleOption(option: ChannelExportUniversaljobSearchOptionEnum) {
    const selectedOptions = this.selectedOptions$.value;
    if (selectedOptions.includes(option)) {
      this.selectedOptions$.next(
        selectedOptions.filter((selectedOption) => selectedOption !== option),
      );
    } else {
      this.selectedOptions$.next([...selectedOptions, option]);
    }
  }

  protected totals$: Observable<
    { price: number; commission: number } | undefined
  > = combineLatest([
    this.selectedPlan$,
    this.selectedOptions$,
    this.plans$,
    this.options$,
  ]).pipe(
    map(([selectedPlan, selectedOptions, plans, options]) => {
      const plan = plans.find((plan) => plan.planType === selectedPlan);
      if (!plan) {
        return undefined;
      }
      const optionsTotal = this.recentConfig
        ? 0
        : options
            .filter((option) => {
              return selectedOptions.includes(option.optionType);
            })
            .reduce((acc, option) => {
              return acc + option.price;
            }, 0);

      const optionCommissionsTotal = this.recentConfig
        ? 0
        : options
            .filter((option) => {
              return selectedOptions.includes(option.optionType);
            })
            .reduce((acc, option) => {
              return acc + option.commission;
            }, 0);
      return {
        price:
          (this.recentConfig ? (plan.extensionPrice ?? 0) : plan.price) +
          optionsTotal,
        commission:
          (this.recentConfig
            ? (plan.extensionCommission ?? 0)
            : plan.commission) + optionCommissionsTotal,
      };
    }),
  );

  protected firstCommission: BehaviorSubject<number> = new BehaviorSubject(0);
  protected secondCommission: BehaviorSubject<number> = new BehaviorSubject(0);

  protected orderButtonIsDisabled$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  public startDate: BehaviorSubject<Date[]> = new BehaviorSubject<Date[]>([
    new Date(),
  ]);
  public dateWithDistance: Observable<endDateWithDistance | null> =
    this.startDate.pipe(
      filter(filterIsNotEmpty),
      combineLatestWith(this.searchChannel$),
      map(([startDate, searchChannel]) => {
        const maxDurationDays = searchChannel?.maxDurationDays;
        if (!maxDurationDays) {
          return null;
        }
        const endDate = addDays(startDate[0], maxDurationDays);
        return {
          endDateFormatted: format(endDate, DATE_FORMAT),
          endDateDistance: formatDistanceStrict(endDate, new Date(), {
            addSuffix: true,
            locale: de,
            unit: 'day',
          }),
        };
      }),
    );

  private vacancyId$ = new ReplaySubject<string>(1);

  protected notes = '';

  @Input() set vacancyId(value: string | undefined | null) {
    if (value) {
      this.vacancyId$.next(value);
    }
  }

  @Output() sheetClosed = new EventEmitter<void>();

  protected selectedOption: ChannelExportUniversaljobSearchPlanEnum | undefined;

  splitCommissionEvenly = false;

  constructor(
    public sheetService: SheetService,
    private userService: UserService,
    private consultantService: ConsultantService,
    private channelsService: ChannelsService,
    private toastService: ToastService,
  ) {}

  async ngOnInit() {
    this.loggedInUser = await this.userService.getUser();
    this.firstCommissionUser = this.loggedInUser;

    // if recentConfig is given, it means that this is an extension
    if (this.recentConfig) {
      // re-apply the settings from the recent config
      this.selectedPlan$.next(
        this.recentConfig.exportSettings?.universaljobSearch?.plan,
      );
      const initialOptions = this.recentConfig.exportSettings
        ?.universaljobSearch?.options as
        | ChannelExportUniversaljobSearchOptionEnum[]
        | undefined
        | null;
      this.selectedOptions$.next(initialOptions ?? []);

      // if currentConfig is running, set start date to end date of current config
      if (
        this.recentConfig.status ===
          (UniBaseXVacancyChannelConfigurationStatusEnum.RUNNING ||
            UniBaseXVacancyChannelConfigurationStatusEnum.PENDING) &&
        this.recentConfig.runtimes?.[0]?.expiration
      ) {
        this.startDate.next([
          new Date(this.recentConfig.runtimes?.[0]?.expiration),
        ]);
      }
    }

    // once we receive consultant users, we can set the first and second commission user options
    this.consultantService.consultantUsers$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.consultantUsers = result;
        this.optionSelected();
      });
    combineLatest([
      this.firstCommission.pipe(distinctUntilChanged()),
      this.secondCommission.pipe(distinctUntilChanged()),
      this.totals$.pipe(distinctUntilChanged()),
    ]).subscribe(([firstCommission, secondCommission, totals]) => {
      this.commissionChanged(
        firstCommission,
        secondCommission,
        totals?.commission ?? 0,
      );
      this.orderButtonIsDisabled$.next(
        this.orderButtonIsDisabled(totals?.commission ?? 0),
      );
    });

    // update firstCommission when totals change
    this.totals$.subscribe((val) =>
      this.firstCommission.next(val?.commission ?? 0),
    );

    // remove options that are not compatible with the selected plan
    combineLatest([this.selectedPlan$, this.options$])
      .pipe(
        map(([selectedPlan, options]) => {
          if (!selectedPlan) {
            return [];
          }
          return this.selectedOptions$.value.filter((optionKey) =>
            options
              .find((option) => option.optionType === optionKey)
              ?.onlyForPlans?.includes(selectedPlan),
          );
        }),
      )
      .subscribe((filteredOptions) => {
        this.selectedOptions$.next(filteredOptions);
      });

    // if options change check if needsToBeRequested should be set to true or false
    this.selectedOptions$.subscribe((options) => {
      if (
        options.includes(ChannelExportUniversaljobSearchOptionEnum.VIDEO) &&
        !this.recentConfig
      ) {
        this.needsToBeRequested = true;
      } else {
        this.needsToBeRequested = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openSheet() {
    this.sheetService.open(this.sheetId);
  }

  closeSheet() {
    this.sheetService.close(this.sheetId);
    this.sheetClosed.emit();
  }

  commissionChanged(
    firstCommission: number,
    secondCommission: number,
    maxTotalCommission: number,
  ) {
    if (firstCommission + secondCommission > maxTotalCommission) {
      this.commissionOverLimit = true;
      this.noNegativeCommission = false;
      this.commissionSplitDoesNotAddUpToTotal = false;
      this.commissionTotalCHF = 0;
    } else if (
      this.secondCommissionUser &&
      firstCommission + secondCommission !== maxTotalCommission
    ) {
      this.commissionSplitDoesNotAddUpToTotal = true;
      this.commissionOverLimit = false;
      this.noNegativeCommission = false;
      this.commissionTotalCHF = 0;
    } else if (firstCommission < 0 || secondCommission < 0) {
      this.commissionOverLimit = false;
      this.noNegativeCommission = true;
      this.commissionSplitDoesNotAddUpToTotal = false;
      this.commissionTotalCHF = 0;
    } else {
      this.commissionOverLimit = false;
      this.noNegativeCommission = false;
      this.commissionSplitDoesNotAddUpToTotal = false;
      this.commissionTotalCHF = firstCommission + secondCommission;
    }
  }

  updateFirstChosen(
    value: (UserListItemFragment | UserSelfFragment) | undefined,
  ) {
    if (!value) {
      return;
    }
    this.firstCommissionUser = value;
    this.optionSelected();
  }

  setSplitCommissionEvenly(
    value: (UserListItemFragment | UserSelfFragment) | undefined,
  ) {
    if (this.secondCommissionUser && !value) {
      this.splitCommissionEvenly = false;
    } else if (this.secondCommission.value === 0) {
      this.splitCommissionEvenly = true;
    }
  }

  updateSecondChosen(
    value: (UserListItemFragment | UserSelfFragment) | undefined,
  ) {
    this.setSplitCommissionEvenly(value);
    this.secondCommissionUser = value;
    this.optionSelected();
  }

  optionSelected() {
    if (
      this.loggedInUser &&
      this.firstCommissionUser?._id !== this.loggedInUser?._id &&
      this.secondCommissionUser?._id !== this.loggedInUser?._id &&
      this.consultantUsers.indexOf(this.loggedInUser)
    ) {
      this.consultantUsers.splice(
        this.consultantUsers.indexOf(this.loggedInUser),
        1,
      );
      this.consultantUsers.unshift(this.loggedInUser);
    }
    this.firstCommissionUserOptions = this.consultantUsers.filter((user) => {
      return user._id !== this.secondCommissionUser?._id;
    });
    this.secondCommissionUserOptions = this.consultantUsers.filter((user) => {
      return user._id !== this.firstCommissionUser?._id;
    });
  }

  private get commissionSplit(): ChannelExportUniversaljobSearchCommissionSplit[] {
    if (!this.firstCommissionUser && !this.secondCommissionUser) {
      return [];
    }
    const commissionSplit: ChannelExportUniversaljobSearchCommissionSplit[] =
      [];

    if (this.firstCommissionUser) {
      commissionSplit.push({
        userUuid: this.firstCommissionUser._id,
        amount: this.firstCommission.value,
      });
    }
    if (this.secondCommissionUser) {
      commissionSplit.push({
        userUuid: this.secondCommissionUser._id,
        amount: this.secondCommission.value,
      });
    }
    return commissionSplit;
  }

  private orderButtonIsDisabled(maxTotalCommission: number): boolean {
    return !(
      this.commissionTotalCHF === maxTotalCommission &&
      this.startDate !== undefined &&
      this.selectedPlan$.value !== undefined
    );
  }

  protected submitAddon(searchChannel: ChannelFragment) {
    this.vacancyId$.pipe(take(1)).subscribe((id) => {
      const start: Date | undefined = this.startDate.value[0];
      const selectedPlan = this.selectedPlan$.value;
      const selectedOptions = this.selectedOptions$.value;
      if (!selectedPlan) {
        this.toastService.makeToast({
          type: 'ERROR',
          message: 'noPlanSelected',
        });
        return;
      }
      const exportSettings = {
        universaljobSearch: {
          notes: this.notes,
          start: start.toISOString(),
          commissionSplit: this.commissionSplit,
          plan: selectedPlan,
          options: selectedOptions,
          isExtension: this.recentConfig ? true : false,
        },
      };
      if (this.needsToBeRequested) {
        this.channelsService
          .requestChannel({
            vacancyUuid: id,
            exportSettings,
            channelUuid: searchChannel.uuid,
          })
          .subscribe(() => this.closeSheet());
      } else {
        this.channelsService
          .liveActivate({
            vacancyUuid: id,
            exportSettings,
            channelUuid: searchChannel.uuid,
          })
          .subscribe(() => this.closeSheet());
      }
    });
  }

  protected dayIsDisabled = (date: Date) => {
    if (this.recentConfig) {
      if (
        this.recentConfig.status ===
          UniBaseXVacancyChannelConfigurationStatusEnum.RUNNING &&
        this.recentConfig.runtimes?.[0]?.expiration
      ) {
        return dayIsBefore(
          date,
          new Date(this.recentConfig.runtimes?.[0]?.expiration),
        );
      }
    }
    return dayIsInPast(date);
  };

  protected getFromOptionInfoMap =
    this.channelsService.getFromOptionInfoMap.bind(this.channelsService);

  protected getFromPlanInfoMap = this.channelsService.getFromPlanInfoMap.bind(
    this.channelsService,
  );
  protected allPlanFeaturePoints = allSearchPlanFeaturePoints;
}
