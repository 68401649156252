<ng-container [ngSwitch]="vacancyHistory?.changelogEntry?.changelogType">
  <app-vacancy-changelog-record
    *ngSwitchCase="ChangelogEnum.FIELD"
    (triggerRevertRecord)="triggerRevertRecord.emit($event)"
    [record]="recordEntry()"
    [isUnpublishedChange]="vacancyHistory?.isUnpublishedChange"
    [vacancy]="vacancy" />

  <app-vacancy-changelog-status-entry
    *ngSwitchCase="ChangelogEnum.STATUS"
    [record]="recordEntry()" />
</ng-container>
