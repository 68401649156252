import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VacancyArchiveReasonEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';
import { getArchiveReasonsTextOptions } from '../helpers/getArchiveReasonsTextOptions';

@Component({
  selector: 'app-vacancy-select-archive',
  templateUrl: './vacancy-select-archive-reason.component.html',
})
export class VacancySelectArchiveReasonComponent {
  @Input() archiveReason: VacancyArchiveReasonEnum | null = null;
  archiveReasonsOptions = getArchiveReasonsTextOptions(this.i18next);

  constructor(private i18next: I18NextPipe) {}
  @Output() selectChange = new EventEmitter<VacancyArchiveReasonEnum>();
}
