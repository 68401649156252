<div class="uj-flex uj-flex-col uj-gap-f uj-w-full">
  <div>
    <app-input-label
      [htmlFor]="'communicationLanguage' + componentId"
      [required]="true"
      >{{ "communicationLanguage" | i18next | firstUppercase }}
    </app-input-label>
    <app-supported-language-selector
      [(languageInput)]="
        communicationLanguageControl
      "></app-supported-language-selector>
    <span class="text-s">
      {{ "willBeUsedForAutomatedCommunicationLikeEmail" | i18next }}</span
    >
  </div>
  <app-talent-phones></app-talent-phones>
  <app-talent-emails></app-talent-emails>
  <ng-container *ngIf="!environment.hideBetaTalentFields">
    <app-talent-addresses></app-talent-addresses>
    <app-talent-links></app-talent-links>
  </ng-container>
</div>
