import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { DriversLicenseEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getDriversLicenseOptions = (
  i18next: I18NextPipe,
): SelectOption<DriversLicenseEnum>[] => {
  return [
    {
      value: DriversLicenseEnum.A,
      name: i18next.transform('DriversLicenseEnum.A'),
    },
    {
      value: DriversLicenseEnum.A1,
      name: i18next.transform('DriversLicenseEnum.A1'),
    },
    {
      value: DriversLicenseEnum.B,
      name: i18next.transform('DriversLicenseEnum.B'),
    },
    {
      value: DriversLicenseEnum.B1,
      name: i18next.transform('DriversLicenseEnum.B1'),
    },
    {
      value: DriversLicenseEnum.BE,
      name: i18next.transform('DriversLicenseEnum.BE'),
    },
    {
      value: DriversLicenseEnum.BPT,
      name: i18next.transform('DriversLicenseEnum.BPT'),
    },
    {
      value: DriversLicenseEnum.C,
      name: i18next.transform('DriversLicenseEnum.C'),
    },
    {
      value: DriversLicenseEnum.C1,
      name: i18next.transform('DriversLicenseEnum.C1'),
    },
    {
      value: DriversLicenseEnum.C1E,
      name: i18next.transform('DriversLicenseEnum.C1E'),
    },
    {
      value: DriversLicenseEnum.CE,
      name: i18next.transform('DriversLicenseEnum.CE'),
    },
    {
      value: DriversLicenseEnum.CZV,
      name: i18next.transform('DriversLicenseEnum.CZV'),
    },
    {
      value: DriversLicenseEnum.D,
      name: i18next.transform('DriversLicenseEnum.D'),
    },
    {
      value: DriversLicenseEnum.D1,
      name: i18next.transform('DriversLicenseEnum.D1'),
    },
    {
      value: DriversLicenseEnum.D1E,
      name: i18next.transform('DriversLicenseEnum.D1E'),
    },
    {
      value: DriversLicenseEnum.DE,
      name: i18next.transform('DriversLicenseEnum.DE'),
    },
    {
      value: DriversLicenseEnum.F,
      name: i18next.transform('DriversLicenseEnum.F'),
    },
    {
      value: DriversLicenseEnum.G,
      name: i18next.transform('DriversLicenseEnum.G'),
    },
    {
      value: DriversLicenseEnum.M,
      name: i18next.transform('DriversLicenseEnum.M'),
    },
    {
      value: DriversLicenseEnum.NEGATIVE_A,
      name: i18next.transform('DriversLicenseEnum.NEGATIVE_A'),
    },
  ];
};
