import { Component, inject } from '@angular/core';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { I18NextModule } from 'angular-i18next';
import {
  CheckboxModule,
  InfoBoxModule,
  InputTextModule,
  ResponsiveService,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { PreferredLocationsFormGroup } from '../../talent-sheet.types';
import { randomId } from '../../../../helpers/functions/randomId';
import { ArrayActionEnum } from '../../../../../graphql/generated';
import { TalentTargetJobEntryComponent } from '../talent-target-job-title/talent-target-job-entry/talent-target-job-entry.component';
import { TalentPreferredLocationEntryComponent } from './talent-preferred-location-entry/talent-preferred-location-entry.component';
import { FormFieldComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-preferred-locations',
  templateUrl: 'talent-preferred-locations.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    NgForOf,
    NgIf,
    SharedDefaultModule,
    ReactiveFormsModule,
    InfoBoxModule,
    InputTextModule,
    UjSelectInputModule,
    CheckboxModule,
    TalentTargetJobEntryComponent,
    TalentPreferredLocationEntryComponent,
    FormFieldComponent,
  ],
})
export class TalentPreferredLocationsComponent {
  formService = inject(TalentFormEditService);
  responsiveService = inject(ResponsiveService);

  isSmDown$ = this.responsiveService.isSmDown$;

  newLocationEntry = new FormGroup<PreferredLocationsFormGroup>({
    uuid: new FormControl<string | null>(randomId()),
    type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.ADDED),
    location: new FormControl<string | null>(null),
    radiusKm: new FormControl<number | null>(null),
  });

  trackByUUID(index: number, item: FormGroup<PreferredLocationsFormGroup>) {
    return item.value.uuid;
  }
}
