import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { randomId } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { combineLatest, Subject } from 'rxjs';
import {
  ChangelogFieldDisplayType,
  SalaryUnitEnum,
  UniBaseXVacancyUpdateInput,
  UserBotFragment,
  UserListItemFragment,
  VacancyArchiveReasonEnum,
  VacancyDetailFragment,
  VacancyEmploymentTypeEnum,
  VacancyHistoryEntryFragment,
  VacancyLanguageRequirement,
  VacancyLanguageRequirementInput,
  VacancyPositionTypeEnum,
  VacancyStatusEnum,
  VacancyUpdateMutationVariables,
} from '../../../../graphql/generated';
import { ConsultantService } from '../../../../core/services/consultant.service';
import { LanguageService } from '../../../../core/services/language.service';
import { UserService } from '../../../../models/shared/user/user.service';
import { VacanciesService } from '../../../../models/unibase/vacancies/vacancies.service';
import { getTranslatedVacancyLocale } from '../../../../pages/vacancies/helpers/getTranslatedVacancyLocale';
import { getDisplayFieldName } from '../../../helpers/functions/vacancy/getDisplayFieldNames';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../app.constants';
import { SubmitVacancyUpdateInput } from '../../../../pages/vacancies/vacancy.types';
import { format } from 'date-fns';
import { takeUntil } from 'rxjs/operators';
import { getSalaryUnitTextOptions } from '../../vacancy-detail/helpers/getSalaryUnitTextOptions';
import { getEmploymentTypeTextOptions } from '../../vacancy-detail/helpers/getEmploymentTypeTextOptions';
import { getPositionTypeTextOptions } from '../../vacancy-detail/helpers/getPositionTypeTextOptions';
import { getArchiveReasonsTextOptions } from '../../vacancy-detail/helpers/getArchiveReasonsTextOptions';
import { getEmploymentTermTextOptions } from '../../vacancy-detail/helpers/getEmploymentTermTextOptions';
import { ModalService } from '@intemp/unijob-ui2';

type FieldKeys = keyof Omit<
  VacancyUpdateMutationVariables['input'],
  'vacancyUuid' | 'doNotMerge' | 'companyContactUuid' | 'transition'
>;

type ChangelogEntry = NonNullable<
  VacancyHistoryEntryFragment['changelogEntry']
>;

@Component({
  selector: 'app-vacancy-changelog-record',
  templateUrl: './vacancy-changelog-record.component.html',
})
export class VacancyChangelogRecordComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() isUnpublishedChange?: boolean = false;
  @Input({ required: true }) vacancy!: VacancyDetailFragment;
  @Input({ required: true })
  record!: ChangelogEntry;
  user: UserListItemFragment | UserBotFragment | undefined;
  isCurrentUser = false;
  revertChangesModalId = 'revertChangesModalId' + randomId();
  canRevertRecord = false;
  contentIsIdentical = false;
  @Output() triggerRevertRecord = new EventEmitter<SubmitVacancyUpdateInput>();
  public DATE_TIME_FORMAT = DATE_TIME_FORMAT;

  destroyed$ = new Subject<void>();
  constructor(
    public consultantService: ConsultantService,
    private userService: UserService,
    private vacanciesService: VacanciesService,
    private modalService: ModalService,
    private i18NextPipe: I18NextPipe,
    private languageService: LanguageService,
  ) {}

  async ngOnInit() {
    this.consultantService.consultantsById$.subscribe((users) => {
      this.user = users[this.record?.userId ?? ''];
    });

    combineLatest([
      this.consultantService.consultantsById$,
      this.userService.readyUser$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([users, currentUser]) => {
        this.isCurrentUser =
          users[this.record?.userId]?._id === currentUser?._id;
      });

    if (!this.vacancy) return;
    if (this.record.revertInput) {
      this.contentIsIdentical = revertResultIsIdentical(
        this.record.revertInput,
        this.vacancy,
        this.record.fieldName as FieldKeys,
        this.record.groupName as FieldKeys,
      );
    }

    if (
      this.record.revertInput &&
      (this.record.fieldName === 'archiveReason' ||
        this.vacancy.status !== VacancyStatusEnum.ARCHIVED)
    ) {
      this.canRevertRecord = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.record) {
      this.prepareRecord();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  prepareRecord() {
    if (this.record) {
      const fieldName = this.record
        .fieldName as keyof UniBaseXVacancyUpdateInput;

      if (fieldName === 'languageRequirements') {
        this.record = prepareLanguageRequirements(
          this.record,
          this.vacanciesService,
          this.languageService,
        );
      } else if (fieldName === 'fixedTerm') {
        this.record = prepareEmploymentTerm(this.record, this.i18NextPipe);
        return;
      } else if (
        fieldName === 'employmentStart' ||
        fieldName === 'employmentEnd'
      ) {
        this.record = prepareDateRecord(this.record);
        return;
      } else if (fieldName === 'salaryUnit') {
        this.record = prepareSalaryUnit(this.record, this.i18NextPipe);
      } else if (fieldName === 'positionType') {
        this.record = preparePositionType(this.record, this.i18NextPipe);
      } else if (fieldName === 'archiveReason') {
        this.record = prepareArchiveReason(this.record, this.i18NextPipe);
      } else if (fieldName === 'employmentType') {
        this.record = prepareEmploymentType(this.record, this.i18NextPipe);
      } else if (fieldName === 'workloadFlexible') {
        this.record = prepareCustomFieldAndGroupNameToDisplay(
          this.record,
          fieldName,
          'employment',
        );
        return this.record;
      } else if (fieldName === 'salaryFlexible') {
        this.record = prepareCustomFieldAndGroupNameToDisplay(
          this.record,
          fieldName,
          'salary',
        );
        return this.record;
      }

      // deliberately mutating fieldNameI18n and groupNameI18n
      this.record = {
        ...this.record,
        fieldNameI18n: getDisplayFieldName(fieldName),
        groupNameI18n: this.record.groupName
          ? getDisplayFieldName(this.record.groupName)
          : null,
      };
    }
  }

  revertChanges(record: ChangelogEntry) {
    if (!record.revertInput) return;

    const submitVacancyUpdateInput = record.revertInput;

    if (submitVacancyUpdateInput) {
      this.triggerRevertRecord.emit(submitVacancyUpdateInput);
    } else {
      console.error('this should never happen log');
    }
    this.modalService.close(this.revertChangesModalId);
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }
  openModal(id: string): void {
    this.modalService.open(id);
  }

  protected readonly DisplayType = ChangelogFieldDisplayType;
}

function revertResultIsIdentical(
  revertInput: UniBaseXVacancyUpdateInput,
  vacancy: VacancyDetailFragment,
  fieldKey: FieldKeys,
  groupName?: FieldKeys,
): boolean {
  const uniBaseX = vacancy?.uniBaseX;
  let currentValue;
  let revertValue = revertInput[fieldKey];
  if (!uniBaseX) {
    return false;
  }

  if (fieldKey === 'companyLocationUuid') {
    currentValue = uniBaseX.companyLocation?.uuid;
  } else if (fieldKey === 'customerBranchUuid') {
    currentValue = uniBaseX.customerBranch?.uuid;
  } else if (fieldKey === 'responsibleUserId') {
    currentValue = uniBaseX.responsibleUser?.userId;
  } else if (fieldKey === 'languageRequirements') {
    currentValue = uniBaseX.languageRequirements;
    const currentLanguageRequirements = uniBaseX.languageRequirements;
    const languageRequirementsInput = revertInput.languageRequirements;

    if (languageRequirementsInput && currentLanguageRequirements) {
      // languageAfterRevert mocks the state of the languageRequirements after the revert
      const languageAfterRevert = languageStateAfterRevert(
        languageRequirementsInput,
        currentLanguageRequirements,
      ).filter((lr) => !lr.delete);
      revertValue = languageAfterRevert;

      currentValue = currentLanguageRequirements
        .map((lr) => {
          return `${lr.language}-${lr.proficiency}`;
        })
        .toString();
      revertValue = languageAfterRevert
        .map((lr) => {
          return `${lr.language}-${lr.proficiency}`;
        })
        .toString();
    }
  } else {
    currentValue = uniBaseX[fieldKey];
    if (
      groupName &&
      groupName !== ('workload' as keyof UniBaseXVacancyUpdateInput) &&
      groupName !== ('desiredAge' as keyof UniBaseXVacancyUpdateInput) &&
      groupName !== 'companyLocationUuid' &&
      groupName !== 'customerBranchUuid' &&
      groupName !== 'responsibleUserId'
    ) {
      currentValue = uniBaseX?.[groupName][fieldKey];
      revertValue = revertInput?.[groupName][fieldKey];
    }
  }

  return JSON.stringify(currentValue) === JSON.stringify(revertValue);
}

// THIS FUNCTION IS DUPLICATE FROM BACKEND called 'getResolvedLanguage', if you change it here, change it there too ------
function languageStateAfterRevert(
  languageRequirementsInput: readonly VacancyLanguageRequirementInput[],
  currentLanguageRequirements?: readonly VacancyLanguageRequirementInput[],
) {
  const newLanguageRequirements: VacancyLanguageRequirementInput[] = [];

  for (const languageRequirement of languageRequirementsInput) {
    const { uuid } = languageRequirement;
    if (
      !currentLanguageRequirements ||
      currentLanguageRequirements.length === 0
    ) {
      newLanguageRequirements.push(languageRequirement);
      continue;
    } else {
      newLanguageRequirements.push(...currentLanguageRequirements);
    }

    const existingLanguage = currentLanguageRequirements.find(
      (item) => item.uuid === uuid,
    );

    if (!existingLanguage) {
      newLanguageRequirements.push(languageRequirement);
      continue;
    }

    if (existingLanguage) {
      const index = newLanguageRequirements.findIndex(
        (item) => item.uuid === uuid,
      );

      if (languageRequirement.delete) {
        newLanguageRequirements.splice(index, 1);
      } else {
        newLanguageRequirements[index] = languageRequirement;
      }
    }
  }

  return newLanguageRequirements;
}

function prepareLanguageRequirements(
  record: ChangelogEntry,
  vacanciesService: VacanciesService,
  languageService: LanguageService,
): ChangelogEntry {
  const current = record.displayedCurrentValue
    ? (JSON.parse(record.displayedCurrentValue) as VacancyLanguageRequirement)
    : undefined;
  const previous = record.displayedPreviousValue
    ? (JSON.parse(record.displayedPreviousValue) as VacancyLanguageRequirement)
    : undefined;

  const updatedDisplayedCurrentValue = current
    ? getLanguageProficiencyString(current, vacanciesService, languageService)
    : record.displayedCurrentValue;

  const updatedDisplayedPreviousValue = previous
    ? getLanguageProficiencyString(previous, vacanciesService, languageService)
    : record.displayedPreviousValue;

  return {
    ...record,
    displayedCurrentValue: updatedDisplayedCurrentValue,
    displayedPreviousValue: updatedDisplayedPreviousValue,
  };
}

function getLanguageProficiencyString(
  languageProficiency: VacancyLanguageRequirement,
  vacanciesService: VacanciesService,
  languageService: LanguageService,
) {
  const language = getTranslatedVacancyLocale(
    languageService.getActiveLanguage(),
    languageProficiency.language,
  );
  const proficiency = vacanciesService.getProficiencyName(
    languageProficiency.proficiency,
  );
  return `${language}, ${proficiency}`;
}

function prepareSalaryUnit(
  record: ChangelogEntry,
  i18next: I18NextPipe,
): ChangelogEntry {
  const current = record.displayedCurrentValue as SalaryUnitEnum | undefined;
  const previous = record.displayedPreviousValue as SalaryUnitEnum | undefined;
  const salaryUnitsOptions = getSalaryUnitTextOptions(i18next);

  const updatedDisplayedCurrentValue = current
    ? (salaryUnitsOptions.find((option) => option.value === current)?.label ??
      '')
    : record.displayedCurrentValue;

  const updatedDisplayedPreviousValue = previous
    ? (salaryUnitsOptions.find((option) => option.value === previous)?.label ??
      '')
    : record.displayedPreviousValue;

  return {
    ...record,
    displayedCurrentValue: updatedDisplayedCurrentValue,
    displayedPreviousValue: updatedDisplayedPreviousValue,
  };
}

function prepareEmploymentType(
  record: ChangelogEntry,
  i18next: I18NextPipe,
): ChangelogEntry {
  const current = record.displayedCurrentValue as
    | VacancyEmploymentTypeEnum
    | undefined;
  const previous = record.displayedPreviousValue as
    | VacancyEmploymentTypeEnum
    | undefined;

  const employmentTypeOptions = getEmploymentTypeTextOptions(i18next);

  const updatedDisplayedCurrentValue = current
    ? (employmentTypeOptions.find((option) => option.value === current)
        ?.label ?? '')
    : record.displayedCurrentValue;

  const updatedDisplayedPreviousValue = previous
    ? (employmentTypeOptions.find((option) => option.value === previous)
        ?.label ?? '')
    : record.displayedPreviousValue;

  return {
    ...record,
    displayedCurrentValue: updatedDisplayedCurrentValue,
    displayedPreviousValue: updatedDisplayedPreviousValue,
  };
}

function preparePositionType(
  record: ChangelogEntry,
  i18next: I18NextPipe,
): ChangelogEntry {
  const current = record.displayedCurrentValue as
    | VacancyPositionTypeEnum
    | undefined;
  const previous = record.displayedPreviousValue as
    | VacancyPositionTypeEnum
    | undefined;

  const positionTypeOptions = getPositionTypeTextOptions(i18next);

  const updatedDisplayedCurrentValue = current
    ? (positionTypeOptions.find((option) => option.value === current)?.label ??
      '')
    : record.displayedCurrentValue;

  const updatedDisplayedPreviousValue = previous
    ? (positionTypeOptions.find((option) => option.value === previous)?.label ??
      '')
    : record.displayedPreviousValue;

  return {
    ...record,
    displayedCurrentValue: updatedDisplayedCurrentValue,
    displayedPreviousValue: updatedDisplayedPreviousValue,
  };
}

function prepareArchiveReason(
  record: ChangelogEntry,
  i18next: I18NextPipe,
): ChangelogEntry {
  const current = record.displayedCurrentValue as
    | VacancyArchiveReasonEnum
    | undefined;
  const previous = record.displayedPreviousValue as
    | VacancyArchiveReasonEnum
    | undefined;

  const archiveReasons = getArchiveReasonsTextOptions(i18next);

  const updatedDisplayedCurrentValue = current
    ? (archiveReasons.find((option) => option.value === current)?.label ?? '')
    : record.displayedCurrentValue;

  const updatedDisplayedPreviousValue = previous
    ? (archiveReasons.find((option) => option.value === previous)?.label ?? '')
    : record.displayedPreviousValue;

  return {
    ...record,
    displayedCurrentValue: updatedDisplayedCurrentValue,
    displayedPreviousValue: updatedDisplayedPreviousValue,
  };
}

function prepareCustomFieldAndGroupNameToDisplay(
  record: ChangelogEntry,
  fieldName: string,
  groupNameI18n: string,
): ChangelogEntry {
  return {
    ...record,
    fieldNameI18n: getDisplayFieldName(fieldName),
    groupNameI18n,
  };
}

function prepareEmploymentTerm(
  record: ChangelogEntry,
  i18next: I18NextPipe,
): ChangelogEntry {
  const currentIsFixedTerm = record.action === 'activated';
  const previousIsFixedTerm = record.revertInput?.fixedTerm;

  const employmentTermOptions = getEmploymentTermTextOptions(i18next);

  const updatedDisplayedCurrentValue =
    employmentTermOptions.find((option) => option.value === currentIsFixedTerm)
      ?.label ?? null;

  const updatedDisplayedPreviousValue =
    employmentTermOptions.find((option) => option.value === previousIsFixedTerm)
      ?.label ?? null;
  return {
    ...record,
    displayedCurrentValue: updatedDisplayedCurrentValue,
    displayedPreviousValue: updatedDisplayedPreviousValue,
    fieldNameI18n: getDisplayFieldName('employmentContract'),
    action: 'changed',
  };
}

function prepareDateRecord(record: ChangelogEntry): ChangelogEntry {
  let current: Date | undefined;
  let previous: Date | undefined;
  if (record.displayedCurrentValue) {
    current = new Date(JSON.parse(record.displayedCurrentValue));
  }
  if (record.displayedPreviousValue) {
    previous = new Date(JSON.parse(record.displayedPreviousValue));
  }
  return {
    ...record,
    displayedCurrentValue: current ? format(current, DATE_FORMAT) : '',
    displayedPreviousValue: previous ? format(previous, DATE_FORMAT) : '',
  };
}
