<div class="uj-flex uj-flex-col uj-gap-f">
  <unijob-info-box
    [message]="'preferencesWillBeDisplayAtInterviewAndSynced' | i18next"
    type="INFO">
  </unijob-info-box>
  <div class="uj-flex uj-flex-col uj-gap-c">
    <app-talent-target-job-title />
  </div>
  <app-talent-preferences-employment />

  <div class="input-wrap">
    <ui-library-form-field
      fullWidth
      [label]="'workModel' | i18next | firstUppercase">
      <unijob-select
        showInvalidState="true"
        multiselect
        [options]="workArrangementOptions"
        size="size-m"
        [placeholder]="'chooseType' | i18next"
        elevation="box-outline"
        [formControl]="workArrangement"
        focusTracker="workArrangement"
        bgColor="bg-white">
      </unijob-select>
    </ui-library-form-field>
  </div>

  <app-talent-salary-input />

  <div class="uj-flex uj-flex-col uj-w-full uj-gap-d">
    <app-talent-preferred-locations />
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row"
      [class.uj-gap-c]="isSmDown$ | async">
      <ui-library-form-field
        fullWidth
        class="uj-flex uj-flex-col uj-flex-1"
        [label]="'transportation' | i18next | firstUppercase">
        <div class="uj-flex uj-gap-e uj-items-center">
          <unijob-checkbox
            [formControl]="commutingByCar"
            [label]="'commutingByCar' | i18next" />
          <unijob-checkbox
            [formControl]="commutingByPublicTransport"
            [label]="'commutingByPublicTransport' | i18next" />
          <unijob-checkbox
            [formControl]="commutingByBicycle"
            [label]="'commutingByBicycle' | i18next" />
        </div>
      </ui-library-form-field>
      <ui-library-form-field
        [label]="'maxCommutingTimeMinutes' | i18next | firstUppercase"
        [labelFor]="'addDuration' + componentId">
        <div class="uj-flex">
          <input
            class="uj-flex-1"
            ui-library-text-input
            [id]="'addDuration' + componentId"
            positionInGroup="start-seamless"
            [placeholder]="'addDuration' | i18next"
            [formControl]="maxCommutingTimeMinutes"
            type="text" />
          <div
            [class.uj-text-text-grey]="formIsDisabled"
            [class.uj-bg-surface-grape]="formIsDisabled"
            class="uj-px-d uj-py-c uj-border uj-border-l-0 uj-rounded-tr uj-rounded-r-b uj-border-border uj-text-text-54 uj-flex uj-items-center">
            min
          </div>
        </div>
      </ui-library-form-field>
    </div>
  </div>

  <ui-library-form-field
    [label]="'remark' | i18next | firstUppercase"
    [labelFor]="'preferenceNote' + componentId">
    <unijob-textarea
      [id]="preferenceNote + componentId"
      [formControl]="preferenceNote"
      [placeholder]="'addRemark' | i18next"
      elevation="outline"
      theme="white">
    </unijob-textarea>
  </ui-library-form-field>
</div>
