import {
  ChangelogFieldDisplayType,
  TalentHistoryEntryFragment,
  TalentSortEnum,
  TalentTableColumnIdEnum,
  TalentUpdateInput,
} from '../../graphql/generated';
import { SortKeyWithColumnLabel } from '../../shared/generic-modules/advanced-data-list/advanced-data-view.types';

export type TableColumns = TalentTableColumnIdEnum;
export type ColumnSortKeys = Partial<Record<TableColumns, TalentSortEnum>>;
export type TalentColumnTitles = Record<TalentTableColumnIdEnum, string>;

export type TalentSortKeyWithColumnLabel = SortKeyWithColumnLabel & {
  value: TalentSortEnum;
  label: string;
};

export enum TalentTabEnum {
  PROFILE = 'profile',
  DOCUMENTS = 'documents',
}

export type PreparedTalentChangeLog = NonNullable<
  Omit<TalentHistoryEntryFragment, 'mutation'>
> & {
  fieldName?: keyof TalentUpdateInput;
  fieldLabel?: string;
  groupName?: string;
  groupLabel?: string;
  displayedPreviousValue?: string;
  displayedCurrentValue?: string;
  mutationFieldType?: ChangelogFieldDisplayType;
  contentIsIdentical?: boolean;
  hideRevertButton?: boolean;
  action?: ChangelogState;
  mutation: {
    version: number;
    mutatedAt: string;
    input: TalentUpdateInput;
  } | null;
};

export type ChangelogState =
  | 'added'
  | 'created'
  | 'changed'
  | 'removed'
  | 'activated'
  | 'deactivated'
  | 'restored'
  | 'published'
  | 'archived'
  | 'scheduled'
  | 'unscheduled'
  | 'generated';
