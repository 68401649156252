import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'link',
  standalone: true,
})
export class LinkPipe implements PipeTransform {
  transform(value?: string): unknown {
    if (!value) {
      return '';
    }
    if (value.startsWith('http://') || value.startsWith('https://')) {
      return value;
    }
    return `https://${value}`;
  }
}
