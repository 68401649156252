import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SheetService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { formatDistanceStrict } from 'date-fns';
import { de } from 'date-fns/locale';
import {
  UniBaseXVacancyChannelConfigurationStatusEnum,
  UniBaseXVacancyChannelFragment,
  VacancyArchiveReasonEnum,
  VacancyDetailFragment,
  VacancyStatusTransitionEnum,
} from '../../../../graphql/generated';
import { SubmitVacancyUpdateInput } from '../../../../pages/vacancies/vacancy.types';
import { ChannelsService } from '../../../../models/unibase/channels/channels.service';

@Component({
  selector: 'app-vacancy-archive-sheet',
  templateUrl: './vacancy-archive-sheet.component.html',
})
export class VacancyArchiveSheetComponent {
  @Input({ required: true }) sheetId!: string;
  private _vacancy!: VacancyDetailFragment | undefined;
  get vacancy() {
    return this._vacancy;
  }
  @Input() set vacancy(vacancy: VacancyDetailFragment | undefined) {
    this._vacancy = vacancy;
    this.activePrioBoostConfigs =
      this.vacancy?.uniBaseX?.configuredChannels?.filter(
        (config) =>
          config.exportSettings?.prioBoost?.enabled &&
          [UniBaseXVacancyChannelConfigurationStatusEnum.RUNNING].includes(
            config.status,
          ),
      );
    this.activeSearchConfigs =
      this.vacancy?.uniBaseX?.configuredChannels?.filter(
        (config) =>
          config.exportSettings?.universaljobSearch?.enabled &&
          [
            UniBaseXVacancyChannelConfigurationStatusEnum.RUNNING,
            UniBaseXVacancyChannelConfigurationStatusEnum.PENDING,
            UniBaseXVacancyChannelConfigurationStatusEnum.REQUESTED,
          ].includes(config.status),
      );
    if (this.activeSearchConfigs) {
      const expirationString =
        this.activeSearchConfigs?.[(this.activeSearchConfigs?.length ?? 0) - 1]
          ?.runtimes?.[0]?.expiration ?? undefined;
      if (expirationString) {
        this.activeSearchLastUntil = new Date(expirationString);
      }
    }
    if (this.activeSearchLastUntil) {
      this.activeSearchLastUntilDays = formatDistanceStrict(
        this.activeSearchLastUntil,
        new Date(),
        {
          addSuffix: true,
          locale: de,
          unit: 'day',
        },
      );
    }
  }
  @Output() triggerAutoSave = new EventEmitter<SubmitVacancyUpdateInput>();
  @Output() sheetClosed = new EventEmitter<undefined>();

  protected deactivationNotes = '';

  archiveReason = new FormControl();
  formGroup = new FormGroup({
    archiveReason: this.archiveReason,
  });
  constructor(
    private i18nPipe: I18NextPipe,
    private sheetService: SheetService,
    private channelsService: ChannelsService,
  ) {}

  protected activePrioBoostConfigs:
    | UniBaseXVacancyChannelFragment[]
    | undefined = undefined;
  protected activeSearchConfigs: UniBaseXVacancyChannelFragment[] | undefined =
    undefined;
  public activeSearchLastUntil: Date | undefined = undefined;
  public activeSearchLastUntilDays: string | undefined = undefined;

  archiveVacancy() {
    const submitVacancyUpdateInput: SubmitVacancyUpdateInput = {
      transition: VacancyStatusTransitionEnum.ARCHIVE,
      archiveReason: this.archiveReason.value,
    };
    if (this.activeSearchConfigs) {
      this.activeSearchConfigs.forEach((config) => {
        if (!this.vacancy) {
          return;
        }
        this.channelsService
          .completeChannel({
            channelUuid: config.channelReference.uuid,
            vacancyUuid: this.vacancy.uuid,
            deactivationNotes: this.deactivationNotes,
          })
          .subscribe();
      });
    }
    this.triggerAutoSave.emit(submitVacancyUpdateInput);
    this.closeSheet();
  }

  closeSheet() {
    this.sheetService.close(this.sheetId);
    this.sheetClosed.emit();
  }

  updateArchiveReason(event: VacancyArchiveReasonEnum) {
    this.archiveReason.setValue(event);
  }
}
