import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VacancyResponsibilityComponent } from './vacancy-responsibility.component';
import { ConsultantSelectModule } from '../consultant-select/consultant-select.module';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InfoBoxModule, UjSelectInputModule } from '@intemp/unijob-ui';
import { SimpleSelectComponent } from '@intemp/unijob-ui2';
import { LetDirective } from '@ngrx/component';

@NgModule({
  declarations: [VacancyResponsibilityComponent],
  imports: [
    CommonModule,
    ConsultantSelectModule,
    SharedDefaultModule,
    ReactiveFormsModule,
    UjSelectInputModule,
    SimpleSelectComponent,
    InfoBoxModule,
    LetDirective,
  ],
  exports: [VacancyResponsibilityComponent],
})
export class VacancyResponsibilityModule {}
