import { Equal, Hash } from 'effect';
import { ContactFragment } from '../../../../graphql/generated';
import { BigSelectAction } from '../../big-select/big-select.component';
import { NoZeroPipe } from '../../../helpers/pipes/noZero.pipe';
import { TitleAndTextValueWithActionsAndAvatar } from '../company-location-select/avatar-selected-item/avatar-selected-item.component';
import { TemplateRef } from '@angular/core';

// TODO: Implement SelectableData instead
export class CompanyContactAvatar
  implements TitleAndTextValueWithActionsAndAvatar, Equal.Equal
{
  get key(): string {
    return this.contact.uuid;
  }

  get title(): string {
    return `${this.contact.firstName} ${this.contact.lastName}`;
  }

  get text(): string {
    return (
      this.contact.email ??
      this.contact.phoneFix ??
      this.contact.phoneMobile ??
      ''
    );
  }

  private computeActions(): BigSelectAction[] {
    const actions: BigSelectAction[] = [];

    if (this.contact.email) {
      actions.push({
        icon: 'mail',
        labelTranslationKey: 'writeEmail',
        onClick: () => {
          window.open(`mailto:${this.contact.email}`);
        },
      });
    }
    const transformedFixPhone = this.contact.phoneFix
      ? this.noZeroPipe.transform(this.contact.phoneFix)
      : undefined;
    const transformedMobilePhone = this.contact.phoneMobile
      ? this.noZeroPipe.transform(this.contact.phoneMobile)
      : undefined;
    if (transformedFixPhone || transformedMobilePhone) {
      actions.push({
        icon: 'phone',
        labelTranslationKey: 'call',
        onClick: () => {
          window.open(`tel:${transformedFixPhone ?? transformedMobilePhone}`);
        },
      });
    }

    return actions;
  }

  private _cachedActions: BigSelectAction[] | undefined;

  get actions(): BigSelectAction[] {
    this._cachedActions ??= this.computeActions();
    return this._cachedActions;
  }

  [Equal.symbol](that: Equal.Equal): boolean {
    if (that instanceof CompanyContactAvatar) {
      return (
        Equal.equals(this.key, that.key) &&
        Equal.equals(this.title, that.title) &&
        Equal.equals(this.text, that.text)
      );
    }
    return false;
  }

  [Hash.symbol](): number {
    return Hash.hash(this.text && this.key && this.title);
  }

  constructor(
    private contact: ContactFragment,
    private noZeroPipe: NoZeroPipe,
    public avatarTemplateRef: TemplateRef<any>,
  ) {}
}
