{
  "13thPaycheck": "13e mois de salaire",
  "1DayToApprove": "1 jour à approuver",
  "1DayToTransfer": "1 jour à transmettre",
  "absence": "absence",
  "absenceTimespan": "période de l'absence",
  "absent": "Absence",
  "acceptedFileTypes": "png, jpg, pdf, doc, max. 2 Mo",
  "acceptTermsAndConditions": "Je suis d'accord avec la politique de confidentialité",
  "accident": "accident",
  "actions": "Actions",
  "active": "Actif",
  "activeEmployees": "employé(e)s actifs/actives",
  "activeEmployments": "engagements actifs",
  "activeLimited": "actif de {{ from }} ({{ fromDays }} jours) à {{ until }} ({{ untilDays }} jours)",
  "activeRuntime": "durée active {{ from }} - {{ until }}",
  "activeSince": "actif depuis {{ from }} ({{ fromDays }} jours)",
  "activeTill": "actif jusqu'à {{ until }} ({{ untilDays }} jours)",
  "addAddon": "Ajouter un add-on",
  "addAttachment": "Ajouter une pièce jointe",
  "addEntry": "ajouter du contenu",
  "addHoursRowNote": "centre de frais ou autres remarques",
  "addLanguage": "Ajouter une langue",
  "addNote": "ajouter une note",
  "addOn": "Add-on",
  "addOnSearch": "Add-on: Search",
  "addOnSearchMax": "Max",
  "addOnSearchPush": "Push",
  "addOnSearchTarget": "Target",
  "addReceipt": "Ajouter un reçu",
  "addRemarksLikeKeywords": "Ajouter des remarques comme des mots-clés, etc.",
  "address": "adresse",
  "addTab": "Ajouter un onglet",
  "adjustedBy": "ajusté par",
  "adjustment": "correction",
  "adjustmentNote": "note concernant la correction",
  "adjustSearchOrFilter": "Ajustez vos critères de recherche/filtre ou réinitialisez-les.",
  "adjustSearchOrResetFilter": "Ajuster la recherche ou réinitialiser le filtre",
  "advertisement": "Annonce",
  "after": "Après",
  "age": "Âge",
  "ahvCard": "carte AVS",
  "ahvNumber": "Numéro AVS",
  "all": "tous",
  "allBranches": "Toutes les branches",
  "allTalents": "Tous les candidats",
  "allRequiredFieldsAreFilled": "Tous les champs requis sont remplis",
  "allVacancies": "Tous les postes vacants",
  "alreadyImported": "déjà importé",
  "amountDaysWithoutHoursEntries": "Nombre de jours sans saisie d'heures",
  "amountOfAbsences": "total d'absences",
  "applicationFormLink": "Lien du formulaire de candidature",
  "apply": "Appliquer",
  "approve": "approuver",
  "approved": "approuvé",
  "approveDay": "approuver le jour",
  "approveDays": "approuver les jours",
  "approveDaysConfirmation": "Veux-tu approuver le contenu?",
  "approveDaysHint": "Après cela, les jours ne peuvent plus être modifiés.",
  "archiveReason": "Raison de l'archivage",
  "areAllEntriesChecked": "Toutes les entrées de la semaine ont été verifiées?",
  "areAllEntriesComplete": "Toutes les entrées de la semaine ont été faites?",
  "areYouSure": "Êtes-vous sûr(e) ?",
  "ascending": "ascendant",
  "assignment": "contrat",
  "attachment": "Annexe",
  "attention": "Attention",
  "authenticate": "authentifier",
  "authentication": "authentification",
  "autoDetectedAfterTheVacancyIsPublished": "Détecté automatiquement après la publication de la vacance.",
  "bankAccountNumber": "numéro de compte",
  "bankingInfo": "coordonnées bancaires",
  "bankingInfoUsageHint": "Tes coordonnées bancaires sont nécessaires pour le paiement du salaire.",
  "before": "Avant",
  "beingCompressed": "Compression en cours...",
  "beingUploaded": "Téléchargement en cours...",
  "benefit": "Profiter",
  "benefits": "Avantages",
  "billing": "facturation",
  "branch": "Branche",
  "branchVacancies": "Postes à pourvoir dans une filiale",
  "bulkEdit": "Traitement multiple",
  "byArrangement": "sur rendez-vous",
  "byDate": "par date",
  "calendarWeekX": "semaine calendaire {{week}}",
  "call": "appeler",
  "cancel": "annuler",
  "talents": "talents",
  "cannotBeCombined": "Actuellement, les add-ons ne peuvent pas être combinés.",
  "changeCanNotBeRevertedAsContentIsIdentical": "Le changement ne peut pas être annulé car le contenu est identique",
  "changeHistory": "Historique des changements",
  "changelogActions": {
    "activated": "activé",
    "added": "ajouté",
    "changed": "modifié",
    "created": "créé",
    "deactivated": "désactivé",
    "removed": "retiré",
    "restored": "restauré"
  },
  "changelogStatus": {
    "ARCHIVED": "a archivé <span class='text-m-b'>{{entryName}}</span>",
    "DELETED": "a supprimé <span class='text-m-b'>{{entryName}}</span>",
    "DRAFT": "a créé <span class='text-m-b'>{{entryName}}</span>",
    "LIVE": "a publié <span class='text-m-b'>{{entryName}}</span>",
    "SCHEDULED": "a planifié <span class='text-m-b'>{{entryName}}</span>"
  },
  "changelogUniBotActions": {
    "activated": "a activé <span class='text-m-b'>{{entryName}}</span>",
    "added": "a ajouté <span class='text-m-b'>{{entryName}}</span>",
    "changed": "a modifié <span class='text-m-b'>{{entryName}}</span>",
    "created": "a créé <span class='text-m-b'>{{entryName}}</span>",
    "deactivated": "a désactivé <span class='text-m-b'>{{entryName}}</span>",
    "removed": "a retiré <span class='text-m-b'>{{entryName}}</span>",
    "scheduled": "a planifié <span class='text-m-b'>{{entryName}}</span>"
  },
  "changePicture": "changer l'image",
  "changeReverted": "Changement annulé",
  "changes": "Modifications",
  "changesApply": "Appliquer les changements",
  "changeSelection": "Changer la sélection",
  "changesPublished": "Modifications publiées",
  "changeVacancy": "Modifier la vacance",
  "changeXVacancies": "Modifier {{count}} vacances",
  "character": "Caractère",
  "charged": "comptabilisé",
  "checklist": "Liste de vérification",
  "checkYourInput": "Vérifiez votre saisie",
  "checkYourSearchInput": "Vérifiez votre saisie de recherche.",
  "choose": "choisir",
  "chooseAbsence": "choisir la raison de l'absence",
  "chooseAPlan": "Choisir une offre",
  "chooseCompanyContact": "Choisir un contact",
  "chooseCompanyLocation": "Choisir entreprise/emplacement",
  "chooseDate": "sélectionner la date",
  "chooseDateRange": "sélectionner la période",
  "chooseDocument": "Choisir un document",
  "chooseEmployment": "Choisir l'emploi",
  "chooseFile": "choisir le fichier",
  "chooseFiles": "choisir des fichiers",
  "chooseFileType": "Sélectionner le type de fichier",
  "chooseFunction": "Choisir la fonction",
  "chooseType": "Choisir le type",
  "city": "lieu",
  "civilOrigin": "Origine civile",
  "clearSearchHistory": "Effacer l'historique de recherche",
  "close": "fermer",
  "commission": "Commission",
  "commissionOverLimit": "Commission au-dessus de la limite",
  "commissionSplitDoesNotAddUpToTotal": "La répartition des commissions ne correspond pas au total",
  "commissionTotalWithoutTax": "Totale sans TVA",
  "companies": "entreprises",
  "company": "entreprise",
  "companyCreated": "Entreprise créée!",
  "companyDeleted": "Entreprise supprimée!",
  "companyDuplicatedSuccessfully": "Une entreprise dupliquée avec succès!",
  "companyLocation": "Emplacement de l'entreprise",
  "companySavedSuccessfully": "Entreprise enregistrée",
  "companySheetAlreadyOpen": "Une entreprise est déjà en cours de traitement.",
  "companyShortName": "Nom de l'entreprise (abrégé)",
  "confirmationEmailSent": "Nous avons envoyé un email de confirmation à <b>{{email}}</b>.",
  "confirmDelete": "Confirmer la suppression",
  "confirmDeleteTab": "Voulez-vous vraiment supprimer l'onglet ?",
  "confirmWithEnter": "Confirmer avec Entrée ↵",
  "contact": "Contact",
  "contactOnTeams": "Contact sur Teams",
  "contactPerson": "personne de contact",
  "contacts": "Contacts",
  "contactUs": "Contactez-nous",
  "copy": "copie",
  "copyLink": "Copier le lien",
  "copyToClipboard": "Copier dans le presse-papiers",
  "cost": "coût",
  "country": "pays",
  "createAbsence": "enregistrer une absence",
  "createdAt": "Créé le",
  "createNewUser": "Créer un nouvel utilisateur",
  "createUser": "Créer un utilisateur",
  "creationDate": "Date de création",
  "criteria": "Critères",
  "cropProfileImage": "Ajuster la photo du profil",
  "currentVersion": "Version actuelle",
  "customer": "Client",
  "dashboard": "aperçu",
  "date": "Datum",
  "dateOfBirth": "date de naissance",
  "dayApprove": "Approbations journalières",
  "days": "jours",
  "daySort": "arranger les jours",
  "delete": "supprimer",
  "deleteAll": "supprimer tout",
  "deleteEntry": "Supprimer l'entrée",
  "deleteVacancy": "Supprimer le poste vacant?",
  "deleteVacancyConfirmationHint": "Aimeriez-vous vraiment supprimer le poste vacant?",
  "descending": "descendant",
  "description": "description",
  "descriptionAndTitle": "Description / Titre",
  "desiredAge": "Âge souhaité",
  "details": "détails",
  "deviatingRecipient": "bénéficaire différent",
  "directions": "itinéraire",
  "discardChanges": "rejeter",
  "displayAbsence": "afficher l'absence",
  "displayInJobAdvertisement": "afficher dans l'annonce d'emploi",
  "displayReport": "afficher le rapport",
  "documents": "Documents",
  "documentType": "Type de document",
  "doNotUseEmailOrPhone": "Ne pas utiliser d'adresse e-mail ou de téléphone",
  "doYouWantToRevertThisChange": "Voulez-vous annuler ce changement",
  "draft": "Brouillon",
  "driversLicense": "Permis de conduire",
  "dropDocumentHere": "Déposez le document ici",
  "dropDocumentsHere": "Déposez les documents ici",
  "duplicate": "Dupliquer",
  "duplicateVacancy": "Dupliquer la vacance",
  "duration": "durée",
  "edit": "Modifier",
  "editAbsence": "modifier une absence",
  "editColumns": "Modifier les colonnes",
  "editCompany": "Modifier l'entreprise",
  "editNote": "Modifier la note",
  "editReport": "modifier le rapport",
  "editTable": "Modifier le tableau",
  "editTabs": "Modifier les onglets",
  "editVacancies": "Traiter les postes vacants",
  "editVacancy": "Modifier le poste vacant",
  "editXCompanies": "Modifier {{amount}} entreprises",
  "editXVacancies": "{{amount}} Traiter les postes vacants",
  "education": "Éducation",
  "email": "email",
  "emailAddress": "adresse email",
  "emailIsNowVerified": "Merci, l'adresse email {{email}} a été vérifiée.",
  "emailSent": "Email envoyé",
  "employee": "Employé",
  "employeeResponsibility": "Responsabilité",
  "employeeResponsibilityHint": "Vous ne recevez des notifications que pour les collaborateurs sélectionnés.",
  "employees": "employé(e)s",
  "employment": "Emploi",
  "employments": "engagements",
  "employmentSaved": "engagement enrégistré",
  "emptyEmployeeData": "Aucune donnée n'a encore été transférée",
  "emptyOption": "Option vide",
  "emptyTemplate": "Modèle vide",
  "emptyVacancy": "Vacance vide",
  "end": "fin",
  "endVacancy": "Terminer le poste vacant",
  "english": "Anglais",
  "enoughApplicantsReceived": "Assez de candidats reçus",
  "enterAmount": "Entrer le montant",
  "enterJobDescription": "Entrer la description du poste",
  "enterReport": "saisir un rapport",
  "enterSearchTerm": "Entrez un terme de recherche",
  "enterUrl": "Entrer l'URL",
  "enterWorkloadPercentage": "Entrer le pourcentage de charge de travail",
  "entries": "entrées",
  "entryDeleteConfirmation": "Effacer le contenu?",
  "entryDeleted": "contenu supprimé",
  "entryDeleteHint": "Le contenu n'est pas révocable.",
  "entryRemoved": "contenu supprimé",
  "error_description": {
    "accessExpired": "Malheureusement, le lien utilisé a expiré",
    "accessExpiredHint": "Veuillez cliquer sur connexion puis sur Mot de passe oublié pour recevoir un nouveau lien",
    "linkOnlyValidOnce": "Ce lien n'est valide qu'une seule fois",
    "linkOnlyValidOnceHint": "Veuillez utiliser vos données de connexion existantes pour vous connecter"
  },
  "publishExactJobLocationInsteadOfReplacement": "Utiliser l'emplacement exact au lieu du remplacement",
  "publishExactJobLocation": "Utiliser l'emplacement exact",
  "existingCompanies": "des entreprises existantes",
  "existingVacancies": "postes vacants existants",
  "expenses": "frais",
  "export": "Exporter",
  "extendSearch": "Étendre la recherche",
  "fieldRequired": "Stp remplit cette case",
  "fieldsRequired": "Remplire tous les cases obligatoires svp",
  "file": "fichier",
  "fileDeleteConfirmation": "supprimer le fichier",
  "fileDeleteHint": "Le fichier n'est pas révocable.",
  "fileRemoved": "supprimé le fichier",
  "fileToLargeError": "Fichier trop volumineux",
  "fileTooBig": "Le fichier dépasse la limite d'importation de 5 MB",
  "fileType": "Format du fichier",
  "fileUploadComplete": "fichier importé avec succès",
  "filter": "Filtre",
  "finishedRuntime": "terminé, durée {{ from }} - {{ until }}",
  "firstName": "prénom",
  "french": "Français",
  "from": "de",
  "fromValueCanNotBeGreaterThanToValue": "La valeur 'de' ne peut pas être supérieure à la valeur 'à'",
  "galaxusBenefitDescription": "Abonnements de téléphonie mobile Galaxus Mobile : données illimitées pour seulement <span class='text-l-b'>  15.- par mois. </span> Pour chaque membre de la famille, vous économisez tous <span class='text-l-b'>  1.-/mois par abonnement. </span> <br /> <br /> Utilisez notre code entreprise et inscrivez-vous maintenant.",
  "generalError": "erreur",
  "generalErrorText": "Une erreur inconnue s'est produite. Nous nous excusons pour le désagrément occasionné.",
  "german": "Allemand",
  "goodDay": "bonjour",
  "goodEvening": "bonsoir",
  "goodMorning": "bonjour",
  "gotoLogin": "Login",
  "great": "super!",
  "hasAccountQuestion": "Déjà un compte ?",
  "hasAllowedDataUsageAt": "Autorisation d'utilisation des données à",
  "hasHandWrittenHours": "Heures de travail notées manuellement?",
  "hasNotBeenActivated": "N'a pas été activé",
  "hasNotBeenPublished": "N'a pas été publié",
  "help": "Aider",
  "hideLocation": "Cacher le lieu",
  "hideSalary": "Cacher le salaire",
  "history": "Histoire",
  "historyEntriesWillBeDisplayHere": "Les entrées d'historique seront affichées ici",
  "hour": "Heure",
  "hours": "heures",
  "hoursToBeApproved": "heures de travail à approuver",
  "iban": "IBAN",
  "imageCompressionError": "Échec de la compression de l'image.",
  "immediately": "de suite",
  "import": "Importer",
  "importCompany": "Importer l'entreprise",
  "importCompanyInstruction": "Pour ajouter de nouvelles entreprises ou modifier des entreprises existantes, l'ancienne UniBase doit toujours être utilisée.<br />Les modifications seront synchronisées.",
  "importContact": "Importer le contact",
  "importContactInstruction": "Pour ajouter de nouveaux contacts ou modifier des contacts existants, l'ancienne UniBase doit toujours être utilisée.<br />Les modifications seront synchronisées.",
  "iMyself": "Moi",
  "inactive": "Inactif",
  "inactiveEmployees": "employé(e)s inactifs/inactives",
  "inactiveEmployments": "engagements antérieurs",
  "inactiveRuntime": "inactif, durée {{ from }} - {{ until }}",
  "inactiveSince": "inactif depuis {{ from }} ({{ fromDays }} jours)",
  "info": "info",
  "initialVacancyActivation": "Première mise en ligne du poste vacant",
  "inputHours": "entrér les heures",
  "intern": "Stagiaire",
  "internalChannels": "Canaux internes",
  "internNoteExample": "Exemple : Le client souhaite un homme",
  "invalidAccountOrCode": "Compte ou code invalide",
  "invalidEmail": "Stp vérifie ton adresse email",
  "invalidHoursRow": "Stp vérifie les heures de travail",
  "invalidIBAN": "Stp vérifie ton IBAN",
  "invalidPhone": "Stp vérifie ton numéro de téléphone",
  "invalidTime": "Temps invalide",
  "isoWeek": "semaine calendaire",
  "italian": "Italien",
  "job": "Profession",
  "JobAdvert": "Annonce d'emploi",
  "jobCompany": "Entreprise",
  "jobLocationAddressReplacement": "Région de travail",
  "jobNotificationRequired": "Notification d'emploi requise",
  "jobTitle": "Intitulé du poste",
  "jobTitleShown": "Titre du poste affiché",
  "jobTitleShownDescription": "Description du titre de poste affiché",
  "just_now": "À l'instant",
  "language": "Langue",
  "languageRequirements": "Exigences linguistiques",
  "languages": "Langues",
  "last_changes_at": "Dernières modifications le {{timePast}}",
  "last_changes_hours_ago": "Dernières modifications il y a {{timePast}} heures",
  "last_changes_hours_ago_short": "il y a {{timePast}} h",
  "last_changes_minutes_ago": "Dernières modifications il y a {{timePast}} minutes",
  "last_changes_minutes_ago_short": "il y a {{timePast}} min",
  "saving": "sauvegarder...",
  "lastName": "nom",
  "lastUpdated": "Dernière mise à jour",
  "lastUpdatedAt": "Charge de travail de",
  "leadershipPosition": "Poste de direction",
  "linkCopied": "Lien copié",
  "linkInvalid": "lien non valide",
  "linkInvalidText": "Le lien utilisé n'est pas valide. Soit le mot de passe a déjà été changé soit le lien a expiré.",
  "linkToAdvert": "Lien vers l'annonce",
  "linkToVacancy": "Lien vers le poste vacant",
  "list": "liste",
  "location": "Lieu",
  "loggingIn": "Inscription en cours...",
  "loggingOut": "Désinscription en cours...",
  "login": "inscription",
  "logout": "se déconnecter",
  "manageEntries": "Gérer les entrées",
  "manualSlots": "Créneaux manuels",
  "personalSlots": "Créneaux personnels",
  "manualSlotsInfoTextFR": "Ici, tu trouveras les liens pour les plateformes d'emploi avec des créneaux manuels.",
  "personalSlotsInfoTextFR": "Ici, tu trouveras les liens pour les plateformes d'emploi avec des créneaux personnels.",
  "externalChannels": "Canaux externes",
  "maritalStatus": "État civil",
  "MAX": "Max",
  "maxLengthCharacters": "Cette case doit contenir au maximum {{max}} caractères.",
  "maxPlanTooltip": "Portée et ciblage d'audience spécifique",
  "maxValue": "Valeur maximale",
  "me": "Moi",
  "microsoftTeams": "Microsoft Teams",
  "minLengthCharacters": "Cette case doit contenir au moins {{min}} caractères.",
  "minutes": "Minutes",
  "minutesShort": "min",
  "minValue": "Valeur minimale",
  "mipDuration": "Durée du MIP",
  "missingAbsenceDuration": "Indiquer la durée d'absence svp",
  "missingAbsenceType": "Choisir la raison d'absence svp",
  "missingAdjustmentDuration": "Indiquer le montant de la correction svp",
  "missingDuration": "Indiquer la durée svp",
  "missingExpenseCost": "enter le montant svp",
  "missingExpenseNote": "Indiquer une spécification des dépenses svp",
  "missingFromTime": "Saisir l'heure d'arrivée svp",
  "missingToTime": "Saisir l'heure de départ svp",
  "moneyAmount": "montant",
  "month": "Mois",
  "moreActions": "Autres actions",
  "multiEditFailed": "Édition multiple échouée",
  "mustServeMilitaryService": "Doit effectuer le service militaire",
  "myVacancies": "Mes postes vacants",
  "name": "nom",
  "nationality": "Nationalité",
  "negativeDuration": "Le temps de travail d'un jour ne doit pas être en négatif.",
  "new": "Nouveau",
  "newCompany": "Nouvelle entreprise",
  "newContact": "Nouveau contact",
  "newDocuments": "Nouveaux documents",
  "newVacancy": "Nouveau poste vacant",
  "newTalent": "Nouveau candidat",
  "nextMilitaryService": "Prochain service militaire",
  "nextPaymentIntendedAt": "prochain paiement prévu le",
  "no": "Non",
  "noAbsenceTypeSelected": "sélectionner la raison d'absence svp",
  "noAccountQuestion": "Pas encore de compte?",
  "noChangesFound": "Aucun changement trouvé",
  "noCompanyFound": "Aucune entreprise trouvée",
  "unfortunatelyNoCompanyFound": "Malheureusement, aucune entreprise trouvée",
  "checkSearchInputsOrImportCompany": "Vérifiez votre saisie de recherche ou importez l'entreprise de l'ancienne UniBase.",
  "noContactFound": "Malheureusement, aucun contact trouvé",
  "noDataSelected": "Il n'y a pas de données à transférer dans la période sélectionnée",
  "noDataToTransfer": "Il n'y a actuellement aucune donnée à transmettre.",
  "noDocumentsFound": "Il n'y a pas de documents disponibles",
  "noDocumentUploaded": "Aucun document téléchargé",
  "noEmailRecievedQuestion": "Tu n'as pas reçu d'email?",
  "noEmployeeFound": "Malheureusement, nous n'avons trouvé aucun employé",
  "noEmployeesDisplayed": "Aucune entrée n'est affichée. Si nécessaire, adaptez le filtre sous \"Compétence\".",
  "noEmployments": "aucun engagement disponible",
  "noEmploymentsFound": "Malheureusement, nous n'avons trouvé aucun emploi.",
  "noEntries": "aucune entrée de données",
  "noEntriesInSearchHistory": "Aucune entrée dans l'historique de recherche",
  "noJobNotificationRequired": "Aucune notification d'emploi requise",
  "nonCompeteAgreement": "Accord de non-concurrence",
  "noNegativeCommission": "Aucune commission négative autorisée",
  "noNewEmploymentsOrDocuments": "Il n'y a actuellement aucun engagement actif ni de nouveau document.",
  "noNews": "Rien de nouveau.",
  "noReceiptUploaded": "Aucun reçu téléchargé",
  "noReportSelected": "Préciser la période et le rapport svp",
  "noSelectOptions": "Aucun choix possible",
  "note": "note",
  "notes": "Notes",
  "nothingToDo": "Actuellement il n'y a aucune tâche à accomplir.",
  "noTimespanSelected": "choisir la période svp",
  "online": "En ligne",
  "onlyAfterPublishing": "Les add-ons ne peuvent être ajoutés qu'après la publication de la vacance.",
  "oops": "Oups",
  "open": "ouvert",
  "openHours": "heures non-payées",
  "openMap": "Ouvrir la carte",
  "or": "ou",
  "orderWithCosts": "Commander avec coûts",
  "originalAd": "Annonce originale",
  "others": "autres",
  "overlappingHoursRows": "Les heures entrées d'un jour ne doivent pas se chevaucher.",
  "own": "Propre",
  "pageNotFound": "Page non trouvée",
  "paid": "payé",
  "participate-now": "Participer maintenant",
  "partTime": "Temps partiel",
  "past": "Passé",
  "pause": "pause",
  "paySlips": "fiche de salaire",
  "permanentPosition": "Poste permanent",
  "personalDetails": "données personnelles",
  "personalDocuments": "documents personnel",
  "phone": "Téléphone",
  "phoneNumber": "numéro de téléphone",
  "phoneNumberMustStartWith+": "Veuillez saisir l'indicatif régional du numéro de téléphone (par ex. +41)",
  "placeOfBirth": "Lieu de naissance",
  "platformLinks": "Liens de plateforme",
  "platformLinksOnlyAvailableForLiveVacancies": "Liens de plateforme disponibles uniquement pour les vacances actives",
  "platforms": "Plateformes",
  "pleaseFillAllTheRequiredFields": "Veuillez remplir tous les champs obligatoires.",
  "pleaseCheckTheFollowingFields": "Veuillez vérifier les champs suivants",
  "pleaseFillJobTitleFirst": "Veuillez d'abord remplir l'intitulé du poste.",
  "pleaseResolveErrorsInOrderToSave": "Veuillez résoudre les erreurs afin de sauvegarder.",
  "pleaseSafeBeforeAddingAbsence": "Sauvegarde tes modifications avant de saisir une absence stp",
  "pleaseSafeBeforeAddingExpenses": "Veuillez enregistrer vos modifications avant d'ajouter des frais",
  "pleaseSafeBeforeEditingAbsence": "Sauvegarde tes changements avant de modifier une absence stp",
  "pleaseSafeBeforeEditingExpenses": "Veuillez enregistrer vos modifications avant de modifier des frais",
  "pleaseSafeBeforeReportUpload": "Sauvegarde tes modifications avant d'importer un rapport stp",
  "pleaseSafeBeforeTransfer": "Sauvegarde tes modifications avant de transmettre des données stp",
  "pleaseSafeEntryBeforeUpload": "sauvegarder l'entrée avant de la télécharger svp",
  "pleaseSelectDocumentType": "Veuillez sélectionner un type de document.",
  "pleaseSelectOneOfTheFollowingReasons": "Veuillez sélectionner l'une des raisons suivantes.",
  "pleaseStandBy": "Patience stp",
  "pleaseVerifyYourEmail": "Vérifie ton adresse email stp.",
  "politePleaseSafeBeforeAcceptingHours": "Veuillez enregistrer les modifications avant de confirmer les heures",
  "politePleaseSafeBeforeAcceptingReport": "Veuillez enregistrer les modifications avant d'accepter le rapport",
  "politePleaseSafeBeforeRejectingHours": "Veuillez enregistrer les modifications avant de refuser des heures.",
  "politePleaseSafeBeforeReportUpload": "Veuillez enregistrer les modifications avant de télécharger un rapport.",
  "position": "Position",
  "possiblyOther": "autres si disponibles",
  "possiblyPermit": "permis, si disponible",
  "preferredEmploymentType": "Type d'emploi préféré",
  "preferredLocation": "Lieu préféré",
  "preferredLocationRadiusKm": "Rayon préféré autour du lieu en km",
  "preferredMaxCommutingTimeMinutes": "Temps maximum de trajet préféré en minutes",
  "preferredWorkArrangement": "Arrangement de travail préféré",
  "prefersCommutingByBicycle": "Préfère se déplacer à vélo",
  "prefersCommutingByCar": "Préfère se déplacer en voiture",
  "prefersCommutingByPublicTransport": "Préfère les transports en commun",
  "presentation": "affichage",
  "preview": "Aperçu",
  "primaryAddressLocation": "Localisation de l'adresse principale",
  "primaryAddressStreet": "Rue de l'adresse principale",
  "primaryAddressZip": "Code postal de l'adresse principale",
  "primaryEmail": "E-mail principal",
  "primaryPhone": "Téléphone principal",
  "professionalTitle": "Titre professionnel",
  "profile": "profil",
  "profileImageRemoved": "photo du profil supprimée",
  "profileImageSaved": "photo du profil sauvegardée",
  "promos": "Promos",
  "promote": "Promouvoir",
  "publicationChanged": "Publication modifiée",
  "publicationChangedAt": "Publication modifiée à",
  "publish": "Publier",
  "publishChanges": "Publier les modifications",
  "publishChangesInDays": "Publier les modifications dans {{days}} jours",
  "publishVacancy": "Publier un poste vacant",
  "publishVacancyConfirmationHint": "Voulez-vous vraiment publier le poste vacant?",
  "publishVacancyInDays": "Publier dans {{days}} jours",
  "PUSH": "Push",
  "pushPlanTooltip": "Placement sur toutes les plateformes d'emploi pertinentes",
  "qualifications": "Qualifications",
  "readDocuments": "documents lus",
  "readyToPublish": "Prêt à publier",
  "reason": "raison",
  "reasonForArchiving": "Raison de l'archivage",
  "redirectingToLogin": "Redirection vers la connexion",
  "referenceNumber": "Numéro de référence",
  "register": "enregistrer",
  "registeredWithRav": "Enregistré auprès du RAV",
  "registration": "enregistrement",
  "registrationCompleted": "Ton inscription a été effectuée avec succès.",
  "reject": "refuser",
  "rejectDay": "refuser le jour",
  "rejectDays": "refuser les jours",
  "rejectDaysHint": "Veux-tu donner une raison?",
  "rejectDaysReasonPlaceholder": "ajouter la raison de refus",
  "rejected": "refusé",
  "rejectedInfoAt": "le",
  "rejectedInfoFrom": "refusé par",
  "religiousDenomination": "Dénomination religieuse",
  "remark": "Remarque",
  "remarksForPublishing": "Remarques pour la publication",
  "removePicture": "supprimer l'image",
  "rename": "Renommer",
  "replace": "remplacer",
  "report": "rapport",
  "reportIsTransmittedDirectly": "Le rapport est transmis directement",
  "reportIsTransmittedDirectlyMessage": "Attention, le rapport est transmis directement. Cette action est irréversible. Pour effectuer des modifications, veuillez contacter votre conseiller dans la section 'Contacts'.",
  "reportState": "données via rapport",
  "reportTimespan": "période du rapport",
  "resendEmail": "Envoyé l'email à nouveau",
  "resendSms": "envoyer à nouveau des sms",
  "resetSearchOrFilter": "Réinitialiser la recherche ou le filtre",
  "residencePermit": "Permis de séjour",
  "residencePermitValidUntil": "Permis de séjour valide jusqu'à",
  "responsibleContact": "Contact responsable",
  "responsibleEmployee": "Collaborateur responsable",
  "restore": "Restaurer",
  "restoredSuccessfully": "Restauré avec succès",
  "restoreHint": "Voulez-vous vraiment restaurer la version {{version}}? Les versions ultérieures seront perdues!",
  "restoreVersion": "Restaurer la version",
  "revert": "Revenir",
  "revertChange": "Annuler le changement",
  "sadlyWeDidntFindVacancies": "Malheureusement, nous n'avons trouvé aucune vacance",
  "salary": "Salaire",
  "salaryCanNotBeZero": "Le salaire ne peut pas être nul",
  "salaryCurrency": "Devise du salaire",
  "salaryExpectations": "Prévisions salariales",
  "salaryFix": "Salaire fixe",
  "salaryHoursPerWeek": "Heures de salaire par semaine",
  "publishSalary": "Publier le salaire",
  "salaryPaymentsPerYear": "Paiements de salaire par an",
  "salaryRangeFromTo": "Utiliser Plage: De - À",
  "salaryUnit": "Unité de salaire",
  "samsungBenefitConditions": "En tant que collaborateur(trice) d'universaljob, tu profites de réductions sur une sélection de produits et d'offres groupées passionnantes. Il te suffit de cliquer sur le bouton suivant pour accéder à la boutique interne de Samsung. Nous te souhaitons beaucoup de plaisir lors de tes achats.",
  "save": "sauvegarder",
  "saveChanges": "sauvegarder",
  "saved": "sauvegardé",
  "savedTabs": "onglets enregistrés",
  "saveSearchQuery": "Enregistrer le filtre comme standard",
  "scheduledToBePublished": "en attente jusqu'à {{ from }} ({{ fromDays }} jours), actif de {{ from }} à {{ until }}",
  "scrollTop": "défiler vers le haut",
  "search": "Recherche",
  "searchAddon": "Recherche",
  "searchTalents": "Rechercher des candidats",
  "searchCompanyContactOptionsFooterInstruction": "Le contact n'est pas dans la liste?",
  "searchCompanyContactPlaceholder": "Rechercher par nom, e-mail, téléphone",
  "searchCompanyLocationOptionsFooterInstruction": "L'entreprise n'est pas dans la liste?",
  "searchCompanyLocationPlaceholder": "Rechercher par nom, rue, code postal, ville",
  "searchFilter": "filtre",
  "searchFilterReset": "Réinitialiser la recherche/le filtre",
  "searchFor": "rechercher {{searchText}}",
  "searchIn": "chercher dans {{field}}",
  "searchInDateOfBirth": "Rechercher dans la date de naissance",
  "searchInEmail": "Rechercher dans l'email",
  "searchInFirstName": "Rechercher dans le prénom",
  "searchInLastName": "Rechercher dans le nom de famille",
  "searchInLocation": "Rechercher dans le lieu",
  "searchInPhone": "Rechercher dans le téléphone",
  "searchInStreet": "Rechercher dans la rue",
  "searchInTitle": "Rechercher dans le titre",
  "searchInVacancyNumber": "Rechercher dans le numéro de vacance",
  "searchInWorkPlace": "Rechercher sur le lieu de travail",
  "searchInZip": "Rechercher dans le code postal",
  "searchTermOrSelectCategory": "Entrez un terme de recherche ou sélectionnez une catégorie",
  "searchVacancies": "Rechercher des postes vacants",
  "securityCode": "code de sécurité",
  "selectBranch": "Sélectionner une branche",
  "selectConsultant": "Sélectionner un consultant",
  "selectDocumentType": "Sélectionner le type de document",
  "selectOptions": "Sélectionner des options",
  "selectReason": "Sélectionner une raison",
  "selectSort": "Choisir le tri",
  "selectValue": "choisir",
  "sendApplication": "Envoyer la candidature",
  "sentEmailTo": "Nous avons envoyé un email à {{email}}.",
  "serverError": "erreur du serveur",
  "settings": "Paramètres",
  "share": "Partager",
  "showAll": "Afficher tout",
  "showAllDocuments": "afficher tous les documents",
  "showAllEmployments": "afficher tous les engagements",
  "showDetails": "afficher les détails",
  "showHours": "afficher les heures",
  "showMap": "afficher la carte",
  "showPlatformLinks": "Afficher les liens de plateforme",
  "sick": "malade",
  "sickness": "maladie",
  "signOn": "s'inscrire",
  "signOnWith": "s'inscrire avec {{type}}",
  "signUpWith": "Enregistrez vous avec {{type}}.",
  "since": "Depuis",
  "sinceArchived": "depuis le {{date}} ({{duration}} {{durationUnit}})",
  "sinceDaysPublished": "depuis le {{date}} ({{duration}} {{durationUnit}})",
  "sinceDeleted": "depuis le {{date}} ({{duration}} {{durationUnit}})",
  "skills": "Compétences",
  "sorting": "séléction",
  "specialistResponsibility": "Responsabilité spécialisée",
  "spellingChecked": "Orthographe vérifiée",
  "splitCommission": "Commission partagée",
  "splitWithNoone": "Partager avec personne",
  "staffConditions": "Condition des collaborateurs",
  "start": "début",
  "startAndEndTimeMustBeDifferent": "Start- und Endzeit müssen unterschiedlich sein",
  "startDate": "Date de début",
  "wishDate": "Date de souhait",
  "statements": "attestation",
  "statistics": "Statistik",
  "status": "Statut",
  "stop": "Arrêter",
  "street": "rue",
  "streetAndNumber": "Rue et numéro",
  "streetPlusNumber": "Rue + Numéro",
  "submit": "transmettre",
  "submitDaysConfirmation": "Veux-tu transmettre le contenu?",
  "submitDaysHint": "Après cela, les entrées ne peuvent plus être modifiées dans la période spécifiée.",
  "successfullySaved": "Enregistré avec succès",
  "support": {
    "companyUser": {
      "adjust": "Corriger",
      "adjustText_1": "Si le nombre d'heures est incorrect, vous pouvez les ajuster en utilisant une entrée de correction. Cliquez sur l'icône du crayon pour modifier l'entrée actuelle. L'entrée d'origine restera visible avec une barre de suppression.",
      "adjustText_2": "L'employé verra la correction, la date de modification et le nom de la personne qui a apporté la correction. Vous pouvez corriger non seulement les entrées d'heures, mais aussi les notes, les dépenses, les absences ou les rapports.",
      "adjustText_3": "Corriger l'entrée respective",
      "approveAndReject": "Approuver et rejeter",
      "approveText_1": "Une fois que vous avez terminé de vérifier les heures de travail de l'employé, vous pouvez les approuver pour le paiement en utilisant le bouton <span class='text-l-b'>Approuver</span>.",
      "approveText_2": "Aucune modification supplémentaire n'est possible après l'approbation.",
      "generalQuestionsText": "Pour toute question, veuillez contacter votre contact universaljob, que vous pouvez trouver dans le menu principal sous <span class='text-l-b'>Contact</span>.",
      "rejectText_1": "Si les données comportent encore des erreurs ou si, par exemple, un reçu obligatoire n'est pas joint, vous pouvez <span class='text-l-b'>rejeter</span> les heures afin que l'employé puisse fournir les informations manquantes.",
      "rejectText_2": "L'employé ne peut modifier à nouveau les heures que si vous les rejetez.",
      "reviewAndCorrect": "Vérifier et corriger",
      "reviewAndCorrectText_1": "Lorsqu'un employé a terminé l'enregistrement de ses heures de travail et les a libérées pour vérification, elles seront marquées. Cliquez sur <span class='text-l-b'>Employés</span> dans le menu principal pour voir toutes les <span class='text-l-b'>libérations en cours</span>. Pour afficher les heures, cliquez sur <span class='text-l-b'>Afficher les heures</span> pour l'employé correspondant.",
      "technicalQuestions": "Questions techniques",
      "technicalQuestionsText": "Pour les problèmes techniques avec hub.universaljob.ch ou les suggestions d'amélioration, vous pouvez contacter directement les développeurs de hub.universaljob.ch :<br><br>intemp AG<br><a class='uj-underline' href='mailto:support@intemp.ch'>support@intemp.ch</a><br>+41 52 551 22 22"
    },
    "employee": {
      "absenceEditOrDelete": "Modifier ou supprimer l'absence",
      "absenceEditOrDeleteText": "Les absences enregistrées sont ajoutées au jour correspondant. Le bouton <span class='text-l-b'>Modifier l'absence</span> devient visible.",
      "absenceEditOrDeleteText_2": "Ici, l'absence peut être modifiée ou supprimée. Les reçus associés peuvent également être agrandis ou supprimés.",
      "absenceEditOrDeleteText_3": "Si une absence a déjà été soumise, vous pouvez seulement l'ouvrir pour afficher le reçu, par exemple. Comme toutes les entrées soumises, elle ne peut plus être modifiée.",
      "add": "Ajouter",
      "addHoursEntries": "Ajouter des entrées d'heures",
      "addNote": "Ajouter une nouvelle note",
      "addReceipt": "Ajouter un nouveau reçu",
      "breaks": "Pauses",
      "breaksText_1": "Les pauses peuvent être saisies <span class='text-l-b'>manuellement</span> pour une entrée d'heure. Elles seront <span class='text-l-b'>déduites</span> du total des heures",
      "breaksText_2": "Une pause entre deux entrées d'heure est <span class='text-l-b'>automatiquement</span> détectée",
      "callOperation": "Appeler l'opération",
      "clickStartAndDatePeriod": "Cliquez sur la date de début, puis sur la date de fin",
      "clickTwiceOnTheSameDay": "Cliquez deux fois sur la même date",
      "deleteEntry": "Supprimer l'entrée d'heures respective",
      "deleteReport": "Supprimer le rapport",
      "deleteReportText_1": "Si vous souhaitez supprimer un rapport ou si vous avez téléchargé le mauvais document, veuillez nous contacter pour correction.",
      "editNote": "Modifier une note enregistrée",
      "editReceipt": "Modifier et supprimer un reçu enregistré",
      "enterAbsences": "Saisir les absences",
      "enterAbsencesText": "Dans l'opération correspondante, cliquez sur <span class='text-l-b'>Saisir une absence</span> dans la colonne de droite (sur les smartphones, utilisez le menu des points à côté de <span class='text-l-b'>Soumettre les jours</span>).",
      "enterAbsencesText_2": "Vous pouvez également ajouter une absence en utilisant le bouton plus sur un jour spécifique. La seule différence est que le jour actuel est déjà sélectionné en tant que période.",
      "enterExpenses": "Saisir les dépenses",
      "enterExpensesText": "Pour saisir les dépenses, cliquez sur l'opération correspondante. En utilisant le <span class='text-l-b'>symbole plus</span>, vous pouvez ajouter une entrée de dépense pour le jour souhaité. <br><br> Ensuite, fournissez une description et un montant pour la dépense. Vous pouvez capturer un reçu en utilisant l'icône de l'appareil photo.",
      "enterHours": "Entrer les heures",
      "enterHoursText_1": "Pour entrer les heures, cliquez sur <span class='text-l-b'>Opérations</span> dans le menu principal à gauche. Ensuite, sélectionnez l'opération souhaitée dans <span class='text-l-b'>Opérations actives</span>.",
      "enterHoursText_2": "Si vous voyez le bouton <span class='text-l-b'>Télécharger le rapport</span> au lieu de <span class='text-l-b'>Entrer les heures</span> pour l'opération souhaitée, cela signifie que votre employeur ne dispose pas encore d'un compte pour approuver numériquement les heures. Étant donné que les heures de travail doivent toujours être confirmées, vous pouvez télécharger des rapports signés dans ce cas ou demander à votre employeur de demander un compte pour la vérification numérique des heures.",
      "enterHoursText_3": "Lorsque vous êtes dans la section d'entrée des heures, vous pouvez déployer le mois souhaité pour y saisir les heures. Vous trouverez une entrée d'heures existante pour chaque jour, et vous pouvez ajouter des entrées supplémentaires à l'aide du <span class='text-l-b'>symbole plus</span>.",
      "enterWorkReports": "Saisir les rapports de travail",
      "generalQuestions": "Questions générales",
      "generalQuestionsText": "Pour toute question, veuillez contacter votre interlocuteur chez universaljob, que vous pouvez trouver dans le menu principal sous <span class='text-l-b'>Contact</span>.",
      "hoursEntries": "Entrées d'heures",
      "pointsMenuSmartphone": "Menu des points sur smartphone",
      "pointsMenuSmartphoneHint": "Une note ou un reçu a été ajouté ici",
      "pointsMenuSmartphoneText": "En plus de l'entrée des heures, il y a des fonctions d'édition supplémentaires sur mobile",
      "receipt": "Reçu",
      "selectDay": "Sélectionner un jour",
      "selectSingleDay": "Sélectionner un seul jour",
      "sendReport": "Envoyer le rapport",
      "sendReportText_1": "Dans l'opération correspondante, cliquez sur <span class='text-l-b'>Télécharger le rapport</span> dans la colonne de droite (sur les smartphones, utilisez le menu des points à côté de <span class='text-l-b'>Soumettre les jours</span>).",
      "sendReportText_2": "Si vous disposez d'un rapport, il devrait également inclure vos <span class='text-l-b'>dépenses</span>. Par conséquent, il n'est pas possible de saisir des entrées supplémentaires pour les jours qui ont un rapport attaché.",
      "sendReportText_3": "Lorsque vous téléchargez un rapport, il est directement transmis à universaljob.",
      "setPeriod": "Définir une période",
      "submitData": "Soumettre",
      "submitReport": "Soumettre le rapport",
      "submitReportText_1": "Une fois que vous avez enregistré toutes les heures, les absences et les dépenses jusqu'à un certain point, vous pouvez envoyer les données enregistrées à votre employeur pour examen en utilisant le bouton <span class='text-l-b'>Soumettre</span>.<br><br> Sélectionnez une heure jusqu'à laquelle vous souhaitez soumettre les données, puis cliquez sur <span class='text-l-b'>Soumettre les jours</span>.",
      "submitReportText_2": "Une fois que vous avez soumis les données, vous ne pouvez plus les modifier ! S'il y a encore des erreurs dans les données, votre employeur doit refuser les heures pour les rendre à nouveau modifiables.",
      "technicalQuestions": "Questions techniques",
      "technicalQuestionsText": "En cas de difficultés techniques avec hub.universaljob.ch ou de suggestions d'amélioration, vous pouvez contacter directement les développeurs de hub.universaljob.ch :<br><br>intemp AG<br><a class='uj-underline' href='mailto:support1@intemp.ch'>support@intemp.ch</a><br>+41 52 551 22 22",
      "uploadReport": "Télécharger le rapport",
      "uploadReportText_1": "Vous pouvez sélectionner la période du rapport et télécharger le document correspondant en utilisant le bouton <span class='text-l-b'>Télécharger le rapport</span>."
    }
  },
  "switchToFixedEmployment": "Passer à un emploi fixe",
  "switchToFixedSalary": "Passer à un salaire fixe",
  "switzerland": "Suisse",
  "TARGET": "Target",
  "targetPlanTooltip": "Publicité sur Google et les réseaux sociaux",
  "templates": "Modèles",
  "temporary": "Temporaire",
  "testString": "Exemple de chaîne de test",
  "tiles": "carrés",
  "timespan": "période",
  "title": "Titre",
  "to": "jusqu'à",
  "toBeApproved": "attend l'approbation",
  "toBranchPage": "Aller à la page de succursale",
  "toSearch": "Chercheur",
  "total": "total",
  "totalExpenses": "total des dépenses",
  "totalHours": "total des heures de travail",
  "totalPriceExclTax": "Total excl. TVA",
  "totalRequired": "La durée de travail d'un jour ne peut pas être en négatif.",
  "transferDays": "transmettre des données",
  "transferDaysUntil": "Transmettre les jours jusqu'au",
  "transferSuccess": "données transmises",
  "translatedPayrollLanguages": {
    "ALB": "Albanais",
    "BUL": "Bulgare",
    "DES": "Allemand",
    "ENU": "Anglais",
    "FRS": "Français",
    "HRV": "Croate",
    "ITS": "Italien",
    "POL": "Polonais",
    "POR": "Portugais",
    "RUM": "Roumain",
    "SLO": "Slovaque",
    "SPA": "Espagnol",
    "SRP": "Serbe",
    "TUR": "Turc"
  },
  "tryAgain": "Réessayer",
  "type": "sorte",
  "uj-post-to-win-first-price": "1er prix 500.– CHF",
  "uj-post-to-win-second-price": "2e prix 300.– CHF",
  "uj-post-to-win-third-price": "3e prix 200.– CHF",
  "uj-post-to-win-title": "Gagne chaque mois l‘un des prix suivants ! ",
  "ujWebsite": "Site web UJ",
  "ujWebsiteLinkCopied": "Lien copié",
  "unpublishedChanges": "Modifications non publiées",
  "unsavedChangesConfirmation": "Veux-tu rejeter ou enregister les modifications?",
  "unsavedChangesCustomerConfirmation": "Souhaitez-vous rejeter ou enregister les modifications?",
  "unsavedChangesCustomerTitle": "corrigé",
  "unsavedChangesTitle": "modifié",
  "unschedule": "Désordonnancer",
  "updated": "Actualisé",
  "upload": "importer",
  "uploadAbsenceSuccessful": "l'absence à été enrégistrée",
  "uploadAttachment": "Importer la pièce jointe",
  "uploadedWrongRapport": "Si tu souhaites changer ton rapport, contact-nous par email stp.",
  "uploadError": "Téléchargement annulé, veuillez réessayer.",
  "uploadExpensesSuccessful": "Les frais ont été enregistrés",
  "uploadFile": "importé le fichier",
  "uploadFilesHint": "Importer les documents suivants stp, si tu ne nous les as pas déjà transféré:",
  "uploadMedicalCertificate": "Importer le certificat médical",
  "uploadPaymentReceipt": "Importer une quittance",
  "uploadPicture": "importer une image",
  "uploadReport": "Importer un rapport",
  "uploadReportSuccessful": "rapport importé avec succès",
  "userManagement": "Gestion des utilisateurs",
  "users": "Utilisateur",
  "vacancies": "Postes vacants",
  "vacanciesSavedSuccessfully": "Postes vacants enregistrés",
  "vacancy": "Vacance",
  "vacancyArchived": "Vacance archivée",
  "vacancyBranch": "Branche de la vacance",
  "vacancyChangelogActions": {
    "activated": "a activé <span class='text-m-b'>la vacance</span>",
    "added": "a ajouté <span class='text-m-b'>{{entryName}}</span>",
    "archived": "a archivé <span class='text-m-b'>la vacance</span>",
    "changed": "a modifié <span class='text-m-b'>{{entryName}}</span>",
    "created": "a créé <span class='text-m-b'>{{entryName}}</span>",
    "deactivated": "a désactivé <span class='text-m-b'>la vacance</span>",
    "deleted": "a supprimé <span class='text-m-b'>la vacance</span>",
    "published": "a publié <span class='text-m-b'>la vacance</span>",
    "removed": "a retiré <span class='text-m-b'>{{entryName}}</span>",
    "restored": "a restauré <span class='text-m-b'>la vacance</span>",
    "scheduled": "a planifié <span class='text-m-b'>la vacance</span>",
    "unscheduled": "a levé le blocage de <span class='text-m-b'>la vacance</span>",
    "generated": "a généré <span class='text-m-b'>la vacance number</span>"
  },
  "vacancyChangelogEntry": {
    "nestedEntry": "a <span class='text-m-b'>{{action}}</span> <span class='text-m-b'>{{entryName}}</span> dans <span class='text-m-b'>{{parentName}}</span>",
    "singleEntry": "a <span class='text-m-b'>{{action}}</span> <span class='text-m-b'>{{entryName}}</span>"
  },
  "vacancyCreated": "Poste vacant créé!",
  "vacancyDeleted": "Poste vacant supprimé!",
  "vacancyDuplicatedSuccessfully": "Poste vacant dupliqué avec succès!",
  "vacancyHasBlockingPromotion": "La vacance a une promotion bloquante",
  "vacancyNr": "N° de poste vacant",
  "vacancyNumber": "Numéro de la vacance",
  "vacancyNumberCopied": "Numéro de la vacance copié",
  "vacancyPublishedSuccessfully": "Poste vacant publié avec succès.",
  "vacancySavedSuccessfully": "Poste vacant enregistré",
  "vacancySheetAlreadyOpen": "Un poste vacant est déjà en cours de traitement.",
  "vacancyStatusEnum": {
    "ARCHIVED": "Archivé",
    "DELETED": "Supprimé",
    "DRAFT": "Brouillon",
    "LIVE": "Actif"
  },
  "UniBaseXVacancyChannelConfigurationStatusEnum": {
    "PENDING": "En attente",
    "RUNNING": "En cours",
    "COMPLETED": "Terminé",
    "EXPIRED": "Expiré",
    "REQUESTED": "en attente de validation"
  },
  "vacancyTitle": "Intitulé du poste vacant",
  "vacation": "vacances",
  "vacationReferred": "Congés pris",
  "verificationEmail": "Malheureusement il y a un problème. Réessaye plus tard stp.",
  "verificationEmailResend": "email a été renvoyé",
  "verificationMessage": "Veuillez vérifier votre identité.",
  "verificationSms": "Nous t'avons envoyé un SMS à <b>{number}}</b> avec le code pour activer ton compte.{{number}}",
  "verifyUser": "En vérifiant ton données une erreur s'est produit.",
  "version": "Version",
  "versionRestoredSuccessfully": "Version restaurée avec succès",
  "versions": "Versions",
  "view": "Voir",
  "viewCompany": "Voir l'entreprise",
  "viewVacancy": "Voir le poste vacant",
  "vitrineTV": "Vitrine TV",
  "wageStatements": "déclaration de salaire",
  "waitingUntil": "En attente jusqu'à {{date}} ({{duration}} {{durationUnit}})",
  "welcome": "bienvenue",
  "welcomeAt": "Bienvenue chez",
  "whatDoesTheCompanyDoPlaceHolder": "Décrivez brièvement l'entreprise et ce qui en fait un employeur attrayant.",
  "whatDoiGetPlaceHolder": "Mentionnez d'autres avantages qui rendent votre entreprise unique et un lieu de travail attrayant.",
  "whatDoiNeedToBeSuccessfulPlaceHolder": "Décrivez les exigences pour le candidat, telles que l'expérience professionnelle souhaitée, les qualifications et compétences nécessaires.",
  "whatIsMyFunctionPlaceHolder": "Décrivez les tâches précises et les responsabilités du poste pour que les candidats potentiels comprennent ce qui rend ce poste attrayant.",
  "willBePublishedAfter": "sera actif après la publication de la vacance",
  "willNotBePublished": "ne sera pas publié",
  "workArrangement": "Arrangement de travail",
  "workAvailability": "Disponibilité pour le travail",
  "workDurationMustBeGreaterThanPauseDuration": "Die Arbeitsdauer muss grösser sein als die Pausendauer",
  "workload": "Charge de travail",
  "workloadCanNotBeZero": "La charge de travail ne peut pas être nulle",
  "workloadFlexible": "Charge de travail flexible",
  "workloadFrom": "Taux d'occupation de",
  "workPlace": "Lieu d'intervention",
  "exactWorkPlace": "Lieu de travail exact",
  "workRegion": "Région de travail",
  "write": "écrire",
  "writeEmail": "Écrire un courriel",
  "xDaysToApprove": "{{amount}} de jours à approuver",
  "xDaysToTransfer": "{{amount}} jours à transmettre",
  "xVacanciesCouldNotBeUpdated": "{{count}} vacances n'ont pas pu être mises à jour",
  "xVacanciesUpdated": "{{count}} vacances mises à jour",
  "year": "Année",
  "years": "Années",
  "yes": "Oui",
  "yourContactAtUniversalJob": "votre contact avec Universal-Job",
  "zip": "code postale",
  "zwischenverdienste": "revenue intérmédiaire",
  "cannotReachServerAttemtingToReconnect": "Reconnexion en cours. Veuillez patienter un instant.",
  "genderAddition": "(h/f/d)",
  "hide-preview": "Masquer l'aperçu",
  "editSavedFilter": "Modifier le filtre enregistré",
  "editTemporaryFilter": "Modifier le filtre temporaire",
  "showSearchHistory": "Afficher l'historique de recherche",
  "searchInJobCompany": "Rechercher dans l'entreprise",
  "searchInContact": "Rechercher dans le contact",
  "promotion": "Promotion",
  "regionOrAproximateLocation": "Région ou lieu approximatif",
  "months": "Mois",
  "seconds": "Secondes",
  "validationVacancyTitleMaxLength": "Raccourcissez votre intitulé de poste si possible",
  "validationVacancyTitleDoubledTermsProvidedByOtherFields": "Contenu en double : supprimez les indications de pourcentage ou de genre",
  "validationVacancyTitleBannedFillerWordsOrCharacters": "N'utilisez pas de 'mots de remplissage' tels que : immédiat, recherché, urgent, ou des caractères spéciaux comme : «!», «:», «*»",
  "validationVacancyTitleIsEmpty": "Veuillez saisir une désignation de poste",
  "youAreOffline": "Vous êtes hors ligne",
  "pageIsReloading": "La page est en cours de rechargement",
  "useFromTo": "de - à utiliser",
  "employmentContract": "Contrat de travail",
  "employmentStart": "Début de l'emploi",
  "employmentEnd": "Fin de l'emploi",
  "stats": "Statistiques",
  "trackedActionType": {
    "VACANCY_VIEWED": "Vues",
    "VACANCY_APPLICATION": "Candidatures",
    "VACANCY_APPLICATION_FORM_OPENED": "Formulaire de candidature ouvert",
    "VACANCY_TVSCREEN_QR_CODE_SCANNED": "Code QR de l'écran TV scanné"
  },
  "perChannel": "par canal",
  "applicationToViewRatio": "Ratio candidature/vues",
  "tvScreen": "TV-Screen",
  "sinceRelativeDate": "depuis {{date}} ({{relativeDate}})",
  "contractTerm": "Durée du contrat",
  "whatMakesTheCompanyTitle": "Qu'est-ce qui fait l'entreprise ?",
  "whatAreMyBenefits": "Quels sont mes avantages ?",
  "whatDoINeedTitle": "De quoi ai-je besoin pour réussir ?",
  "whatIsMyFunctionTitle": "Quelle est ma fonction ?",
  "totalViewed": "Vues",
  "totalApplications": "Candidatures",
  "prioBoost": "Prio Boost",
  "editSlots": "Modifier les emplacements",
  "prioBoostDescription": "Tu peux booster jusqu'à {{max}} de tes postes vacants sur les plateformes d'emploi 🚀.",
  "prioBoostSlotGuidance": "Actuellement, tu as priorisé les postes vacants suivants :",
  "onlyResponsibleCanChangeChannelConfig": "Seul le responsable de l'entreprise peut modifier la configuration des canaux",
  "onlyUsersOfSameBranchCanExtendAddons": "Seul les employées de la même branche peut ajouter un add-on.",
  "onlyUserOfSameBranchCanChannelConfig": "Seul les employées de la même branche peut modifier la configuration des canaux.",
  "onlyUsersOfSameBranchCanAddAddons": "Seul les employées de la même branche peut prolonger un addon-on",
  "maxSlotsPerUserReached": "Tu as atteint le nombre maximum d'emplacements",
  "maxSlotsTotalReached": "Tu as atteint le nombre maximum d'emplacements",
  "warningChannelsWithMaxRunningAndPendingSlotsPerUserWillBeDeactivated": "Attention: L'active Prio-Boost est désactivée si vous changez l'employée responsable.",
  "currentChannelStatus": "Promotion: Statut",
  "currentChannelUuid": "Promotion: Canal",
  "Options": "Options",
  "searchVideoActiveWarning": "La date de début est fixée par notre marketing dès que la date de production de la vidéo est connue.",
  "extendSearchAddon": "Prolonger le dernier add-on",
  "SearchPlanFeaturePoint": {
    "TalentExclusivity": "Exclusivité des candidats",
    "ActiveSearchers": "Chercheurs actifs",
    "PassiveSearchers": "Chercheurs passifs",
    "RecruitingViaJobPlatforms": "Recrutement via les plateformes d'emploi",
    "RecruitingViaSocialMedia": "Recrutement via les médias sociaux"
  },
  "VIDEOFeaturePoint": {
    "coversTheBroadestSearchWithMax": "Couvre la recherche la plus large avec «MAX»",
    "strengthenEmployerAttractiveness": "Renforcer l'attractivité de l'employeur",
    "completeVideoProductionWithProfessionals": "Production vidéo complète avec des professionnels",
    "authenticAndProfessional": "Authentique et professionnel",
    "modernAppearanceAndMoreVisibility": "Aspect moderne et plus de visibilité"
  },
  "openInNewTab": "Ouvrir dans un nouvel onglet",
  "searchAddonWarningVacancyArchiveTitle": "Terminer la recherche prématurément ?",
  "searchAddonWarningVacancyArchiveMessage": "La recherche réservée est encore active jusqu'au {{ until }} ({{ untilDays }} jours). Aucun remboursement ne sera effectué en cas de fin prématurée. Voulez-vous vraiment l'arrêter ?",
  "pioBoostWarningVacancyArchiveMessage": "La vacance sera retirée de l'emplacement Prio Boost",
  "deactivationNotes": "Remarques pour la terminaison",
  "deactivationNotesPlaceholder": "Remarques telles que la raison de la terminaison anticipée.",
  "channelRequested": "en attente d'approbation - date souhaitée: {{ from }}",
  "addonAcceptRequest": "Activer l'add-on",
  "resume": "Curriculum vitae",
  "journey": "Journey",
  "addChild": "Ajouter un enfant",
  "loadMore": "Charger plus",
  "onlyShowingXEntries": "Affichage de {{count}} entrées",
  "areYouSureYouWantToDeleteThisEntry": "Êtes-vous sûr de vouloir supprimer cette entrée ?",
  "validUntil": "Valable jusqu'au",
  "talentStatusWillChangeAsSoonPermitPresent": "Dès qu'un permis est présent, le statut du candidat passera à 'Ouvert au travail'.",
  "chooseCategory": "Choisir une catégorie",
  "vehicleAvailable": "Véhicule disponible",
  "chooseReligiousDenomination": "Choisir une confession religieuse",
  "chooseMaritalStatus": "Choisir un état civil",
  "telephone": "Téléphone",
  "addNumber": "Ajouter un numéro",
  "otherNumbers": "Autres numéros",
  "primaryPhoneUpdated": "Numéro de téléphone principal défini",
  "primaryEmailUpdated": "Email principal défini",
  "willBeYourPrimaryPhone": "sera défini comme numéro principal",
  "primary": "Principal",
  "otherEmails": "Autres emails",
  "addEmail": "Ajouter un email",
  "addresses": "Adresses",
  "addAddress": "Ajouter une adresse",
  "children": "Enfants",
  "addUrl": "Ajouter une URL",
  "invalidUrl": "L'URL est invalide, vérifiez votre saisie",
  "availabilityWillBeDisplayAtInterviewAndSynced": "Les disponibilités seront également affichées lors de l'entretien et synchronisées",
  "availableForWorkAt": "Disponible à partir de",
  "selectDate": "Sélectionner une date",
  "registeredWithRAV": "Enregistré au RAV",
  "nextService": "Prochain service",
  "nextMilitaryServiceAt": "Prochain service militaire",
  "addRemark": "Ajouter une remarque",
  "availabilityNote": "Note de disponibilité",
  "talentChangelogActions": {
    "added": "a ajouté <span class='text-m-b'>{{entryName}}</span>",
    "changed": "a modifié <span class='text-m-b'>{{entryName}}</span>",
    "created": "a créé <span class='text-m-b'>{{entryName}}</span>",
    "removed": "a supprimé <span class='text-m-b'>{{entryName}}</span>",
    "deleted": "a supprimé le <span class='text-m-b'>candidat</span>"
  },
  "talentChangelogEntry": {
    "nestedEntry": "a ajouté <span class='text-m-b'>{{entryName}}</span> dans <span class='text-m-b'>{{parentName}}</span> {{action}}",
    "singleEntry": "a ajouté <span class='text-m-b'>{{entryName}}</span> {{action}}",
    "customEntry": {
      "CREATION": "a créé le <span class='text-m-b'>candidat</span>",
      "HAS_ACCEPTED_DATA_PRIVACY_POLICY": "Le candidat a accepté la politique de confidentialité.",
      "HAS_SENT_DATA_PRIVACY_POLICY_EMAIL": "a envoyé un email de politique de confidentialité à <span class='text-m-b'>{{sentTo}}</span>"
    }
  },
  "setTalentToBlackList": "Ajouter le candidat à la liste noire",
  "attentionOnlyUserWithPermissionUndoBlacklist": "Attention : seuls les utilisateurs autorisés peuvent annuler le statut de liste noire.",
  "confirm": "Confirmer",
  "preferencesWillBeDisplayAtInterviewAndSynced": "Les préférences seront également affichées lors de l'entretien et synchronisées.",
  "targetJob": "Emploi cible",
  "addJob": "Ajouter un emploi",
  "workModel": "Modèle de travail",
  "salaryExpectation": "Prétention salariale",
  "addLocation": "Ajouter un lieu",
  "enterRoute": "Saisir l'itinéraire",
  "radius": "Rayon",
  "transportation": "Moyen de transport",
  "commutingByPublicTransport": "Transport public",
  "commutingByCar": "Voiture",
  "commutingByBicycle": "Vélo",
  "maxCommutingTimeMinutes": "Temps de trajet max. (minutes)",
  "addDuration": "Ajouter une durée",
  "talentLifeCycle": "Cycle de vie du candidat",
  "availability_until": "Disponible jusqu'au {{date}} ({{days}} jours)",
  "data_deletion_notice": "Selon les réglementations suisses sur la protection des données, nous sommes tenus de supprimer toutes les données personnelles de {{firstName}} {{lastName}} après 6 mois.",
  "data_deletion_notice_notification_message": "{{firstName}} {{lastName}} sera notifié 4 semaines avant la date limite et aura la possibilité de prolonger la période.",
  "dataPrivacyPolicy": "Politique de confidentialité",
  "no_email_for_privacy_policy": "Aucun email disponible pour envoyer la politique de confidentialité.",
  "privacy_policy_not_accepted_will_be_deleted": "Si la politique de confidentialité n'est pas acceptée d'ici le <span class='text-m-b'> {{date}} ({{days}} jours) </span>, le candidat sera supprimé.",
  "privacy_policy_accepted": "Politique de confidentialité acceptée le {{date}}.",
  "privacy_policy_not_accepted": "Politique de confidentialité non encore acceptée.",
  "send_privacy_policy_email": "Envoyer un email de politique de confidentialité",
  "privacy_policy_email_sent_log": "<span class='text-m-b'>{{name}}</span> a envoyé un email à <span class='text-m-b'> {{email}} </span>.",
  "alreadyAccepted": "Politique de confidentialité déjà acceptée",
  "invalidToken": "Jeton invalide",
  "privacyPolicyAccepted": "Politique de confidentialité acceptée avec succès.",
  "dataPrivacyPolicyNotAccepted": "Confidentialité non acceptée",
  "talentNotFound": "Candidat non trouvé",
  "matching": "Correspondance",
  "leadsOrSources": "Leads / sources",
  "addLeadsAndSource": "Ajouter un lead / une source",
  "recommendation": "Recommandation",
  "addRecommendation": "Ajouter une recommandation",
  "internalNotes": "Notes internes",
  "addNotes": "Ajouter des notes",
  "talentNumber": "Numéro du candidat",
  "talentNumberCopied": "Numéro du candidat copié",
  "salutation": "Salutation",
  "hasVehicle": "Véhicule disponible",
  "phones": "Téléphones",
  "emails": "Emails",
  "employedUntil": "En service jusqu'au",
  "employedSince": "En service depuis",
  "zipCodeIsInvalid": "Le code postal saisi semble invalide ou être une boîte postale. Veuillez corriger le code postal.",
  "publishedWorkLocation": "Lieu de travail publié",
  "noTalentsFound": "Keine Talente gefunden",
  "createdBy": "Créé par",
  "lastModifiedBy": "Dernière modification par",
  "searchInCompany": "Rechercher dans l'entreprise",
  "newDocumentsAvailable": "{{count}} nouveaux documents disponibles",
  "thereAreNewDocumentsAvailableForTalent": "Il y a {{count}} nouveaux documents pour ce talent",
  "activeDocuments": "Documents actifs",
  "generatingPages": "Génération des pages pour {{file}}",
  "savingFile": "Enregistrement du fichier pour {{file}}",
  "savingPages": "Enregistrement des pages pour {{file}}",
  "uncheckedDocuments": "Documents non vérifiés",
  "originalFiles": "Fichiers originaux",
  "checkDocuments": "Vérifier les documents",
  "actives": "Actifs",
  "unchecked": "Non vérifiés",
  "originals": "Originaux",
  "loadPdf": "Charger le PDF",
  "uploadDocument": "Télécharger le document",
  "uploadingFile": "Téléchargement du fichier {{file}}",
  "error": "Erreur",
  "downloading": "Téléchargement",
  "favoritesWillBeUsedByDefaultDuringRecommendation": "Les favoris seront utilisés par défaut lors de la recommandation",
  "moveUp": "Déplacer vers le haut",
  "moveDown": "Déplacer vers le bas",
  "moveToActiveDocuments": "Déplacer vers les documents actifs",
  "unfavorite": "Retirer des favoris",
  "makeFavorite": "Ajouter aux favoris",
  "download": "Télécharger",
  "remove": "Supprimer",
  "removeDocument": "Supprimer le document",
  "areYouSureYouWantToRemoveDocument": "Êtes-vous sûr de vouloir supprimer définitivement le document ?",
  "page": "Page",
  "addedToFavorites": "Ajouté aux favoris",
  "removedFromFavorites": "Retiré des favoris",
  "movedToActiveDocuments": "Déplacé vers les documents actifs",
  "files": "Fichiers",
  "pages": "Pages",
  "ujInternalDescription": "Offres d’emploi internes pour la recherche de nouveaux collaborateurs chez universaljob.",
  "thisDocumentHasAlreadyBeenUploaded": "Ce document a déjà été téléchargé",
  "emailAlreadyExists": "L'email existe déjà",
  "staffRoles": "Rôles du personnel"
}
