<ng-container [formGroup]="languageForm">
  <div
    *ngIf="!(isXsDown$ | async)"
    class="uj-flex uj-flex-col sm:uj-flex-row uj-justify-evenly">
    <ui-library-simple-select
      class="uj-w-[142px]"
      size="M"
      searchable
      placeholderText="Deutsch"
      [options]="displayedOptions"
      design="outline"
      formControlName="language"
      backgroundColor="white"
      positionInGroup="start-seamless">
    </ui-library-simple-select>
    <ui-library-select
      class="uj-grow"
      size="M"
      [style]="'outline'"
      formControlName="proficiency"
      backgroundColor="white"
      [collapsedSelectedItemComponent]="TextSelectedItemComponent"
      [expandedSelectedItemComponent]="TextSelectedItemComponent"
      [isSingleSelect]="true"
      positionInGroup="center">
      <ng-template #noItemSelectedTemplate
        ><!-- Currently not used --></ng-template
      >
      <ui-library-selection-list [behavior]="singleSelectBehavior">
        <ui-library-list-item
          *ngFor="
            let item of languageProficiencyEnumOptionsWithoutLast;
            index as i
          "
          [_value]="item.value"
          [label]="item.label"
          size="M"
          bgColor="white"
          [style]="'outline'"
          ><div class="uj-grow list-item-text">
            {{ item.label }}
          </div></ui-library-list-item
        >
        <hr />
        <ui-library-list-item
          [_value]="languageProficiencyEnumOptions.at(-1)!.value"
          [label]="languageProficiencyEnumOptions.at(-1)!.label"
          size="M"
          bgColor="white"
          [style]="'outline'"
          ><div class="uj-grow list-item-text">
            {{ languageProficiencyEnumOptions.at(-1)!.label }}
          </div></ui-library-list-item
        >
      </ui-library-selection-list>
    </ui-library-select>
    <button
      [disabled]="!!languageForm?.disabled"
      (click)="removeEntry.emit()"
      ui-library-button-outline
      type="button"
      iconOnly="trash"
      color="dark"
      positionInGroup="end-seamless"></button>
  </div>

  <!-- TODO use css Order instead of js   -->
  <div
    *ngIf="isXsDown$ | async"
    class="uj-flex uj-gap-c">
    <span class="uj-h-auto uj-w-[3px] uj-bg-violet-290/54 uj-rounded"></span>
    <div
      class="uj-flex uj-flex-col uj-justify-center uj-flex-1 uj-gap-c sm:uj-flex-row">
      <div class="uj-flex uj-flex-row">
        <ui-library-simple-select
          class="uj-grow"
          size="M"
          placeholderText="Deutsch"
          [options]="displayedOptions"
          design="outline"
          formControlName="language"
          backgroundColor="white">
        </ui-library-simple-select>
        <button
          [disabled]="!!languageForm?.disabled"
          (click)="removeEntry.emit()"
          ui-library-button-outline
          type="button"
          iconOnly="trash"
          color="dark"
          positionInGroup="end-seamless"></button>
      </div>
      <ui-library-simple-select
        class="uj-grow"
        size="M"
        placeholderText="C2 - Muttersprachliches Niveau"
        design="outline"
        [options]="languageProficiencyEnumOptions"
        formControlName="proficiency"
        backgroundColor="white">
      </ui-library-simple-select>
    </div>
  </div>
</ng-container>
