<ng-container *ngIf="shown$ | async">
  <div class="overlay animate-in">
    <div
      class="overlay-content"
      *ngIf="text$ | async as text">
      {{ text | i18next }}
      <div class="spinner-container">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </div>
  </div>
</ng-container>
