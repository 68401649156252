<div class="uj-flex uj-flex-col uj-gap-d uj-w-full">
  <div class="uj-flex uj-gap-c uj-w-full">
    <ui-library-form-field
      fullWidth
      [labelFor]="'addJob' + componentId">
      <input
        ui-library-text-input
        [id]="'addJob' + componentId"
        [placeholder]="'addJob' | i18next"
        [formControl]="titleGroup.controls.title"
        (change)="onChange()"
        type="text" />
    </ui-library-form-field>
    <ng-container *ngIf="!isNewEntry">
      <button
        (click)="deleteJobTitle()"
        ui-library-button-outline
        type="button"
        color="dark"
        iconOnly="trash"
        class="hover:uj-text-ui-negative"></button>
    </ng-container>
  </div>
</div>
