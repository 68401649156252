import { computed, inject, Injectable, Signal } from '@angular/core';
import { TalentFormEditService } from './talentFormEdit.service';
import { differenceInDays, format } from 'date-fns';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../app.constants';
import { TalentSendPrivacyPolicyNotificationGQL } from '../../../../graphql/generated';
import { ToastService } from '@intemp/unijob-ui2';
import { I18NextPipe } from 'angular-i18next';

@Injectable()
export class TalentPrivacyPolicyService {
  formService = inject(TalentFormEditService);
  queryGQL = inject(TalentSendPrivacyPolicyNotificationGQL);
  toastService = inject(ToastService);
  i18next = inject(I18NextPipe);

  talent = this.formService.talent;

  talentDataAvailableUntil: Signal<{ date: string; days: number }> = computed(
    () => {
      const talent = this.talent();
      if (!talent)
        return {
          date: 'Should not happen - no talent data found',
          days: 0,
        };
      return {
        date: format(new Date(talent.talentDataAvailableUntil), DATE_FORMAT),
        days: differenceInDays(
          new Date(talent.talentDataAvailableUntil),
          new Date(),
        ),
      };
    },
  );

  hasAcceptedPrivacyPolicy = computed(() => {
    const talent = this.talent();
    const has30LeftBeforeDeleted = this.talentDataAvailableUntil().days <= 30;
    if (!talent || !talent.hasAllowedDataUsageAt || has30LeftBeforeDeleted)
      return false;
    return format(new Date(talent.hasAllowedDataUsageAt), DATE_FORMAT);
  });

  hasNoEmail = computed(() => {
    return !this.talent()?.emails.length;
  });

  sentMails = computed<
    {
      date: string;
      sentTo: string;
      sentBy: string;
    }[]
  >(() => {
    const talent = this.talent();
    if (!talent || !talent.dataPrivacyPolicySentEmails) return [];
    return talent.dataPrivacyPolicySentEmails.map((entry) => {
      return {
        date: format(new Date(entry.date), DATE_TIME_FORMAT),
        sentTo: entry.sentTo,
        sentBy: entry.sentBy,
      };
    });
  });

  sendEmail() {
    const talent = this.talent();
    if (!talent) {
      throw new Error('Talent not found');
    }
    this.queryGQL
      .mutate({
        talentUuid: talent.uuid,
      })
      .subscribe((result) => {
        if (result.data?.talentSendPrivacyPolicyNotification) {
          this.toastService.makeToast({
            type: 'SUCCESS',
            message: this.i18next.transform('emailSent'),
            duration: 6000,
          });
        }
      });
  }
}
