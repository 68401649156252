import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  CompaniesTextMatchSearchGQL,
  CompanyByLocationUuidGQL,
  CompanyByLocationUuidQueryVariables,
} from '../../../graphql/generated';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(
    private companiesTextSearchGQL: CompaniesTextMatchSearchGQL,
    private companyByLocationUuidGQL: CompanyByLocationUuidGQL,
  ) {}

  companiesTextSearch(searchString: string) {
    return this.companiesTextSearchGQL
      .fetch(
        {
          searchString: searchString,
          skip: 0,
          limit: 20,
        },
        { fetchPolicy: 'no-cache' },
      )
      .pipe(
        map((res) => {
          return res.data[`companiesTextMatchSearch`];
        }),
      );
  }

  companyQueryByLocation(variables: CompanyByLocationUuidQueryVariables) {
    return this.companyByLocationUuidGQL
      .fetch(variables, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data[`companyByLocationUUID`];
        }),
      );
  }
}
