<ui-library-card
  padding="12px"
  interactable="true"
  [selected]="selected">
  <div class="document-content">
    @if (page()) {
      <div
        class="handle"
        cdkDragHandle>
        <ui-library-icon
          icon="drag_handle"
          color="light-grey"></ui-library-icon>
      </div>
    }
    <div class="content">
      @if (page(); as page) {
        <div
          [class]="
            'document-wrapper' +
            (this.pageInView() ? ' document-wrapper--active' : '')
          ">
          <img
            [src]="getDocumentPreviewSrc(pageOrFile())"
            [alt]="pageOrFile().title"
            loading="lazy" />
          <div
            *ngIf="preview.observed"
            (click)="previewClicked($event)"
            class="overlay">
            <ui-library-icon
              color="accent"
              icon="preview_zoom"></ui-library-icon>
          </div>
          <div
            class="favorite"
            *ngIf="page && page.favorite">
            <ui-library-icon
              icon="star_filled"
              size="8"
              color="warning-1"></ui-library-icon>
          </div>
        </div>
      }
      @if (file(); as file) {
        <ui-library-icon
          class="uj-h-f uj-flex uj-flex-col uj-items-center uj-justify-center"
          icon="file_document_blank"
          size="20"
          color="dark"></ui-library-icon>
      }
      <div class="main-content">
        <div class="header">
          <span *ngIf="pageNumber">{{ pageNumber | number: "2.0" }}</span>
          <span
            #titleElement
            [class]="
              'title' + (editingTitle() !== false ? ' title--editing' : '')
            "
            [contentEditable]="titleChange.observed && editingTitle() !== false"
            (click)="
              titleChange.observed && editingTitle() !== false
                ? $event.stopPropagation()
                : editTitleClicked($event)
            "
            (input)="updateEditingTitle($event)"
            (appClickedOutside)="saveEditedTitleClicked()"
            (keyDown.enter)="saveEditedTitleClicked($event)"
            (keyDown.escape)="cancelEditingTitleEscapePushed($event)">
            {{ pageOrFile().title }}
          </span>
          <div class="controls">
            @if (file(); as file) {
              <button
                ui-library-button-text
                [disabled]="
                  downloadStatus().progress !== 0 ||
                  downloadStatus().errorMessage
                "
                color="dark"
                iconOnly="download"
                (click)="downloadDocument(pageOrFile())"></button>
            }
            @if (page(); as page) {
              <button
                *ngIf="toggleFavorite.observed && editingTitle() === false"
                ui-library-button-text
                color="warning-1"
                uiLibraryTooltip
                [tooltip]="
                  'favoritesWillBeUsedByDefaultDuringRecommendation' | i18next
                "
                [iconOnly]="page.favorite ? 'star_filled' : 'star'"
                (click)="toggleFavoriteClicked($event)"></button>
              <button
                *ngIf="titleChange.observed && editingTitle() !== false"
                ui-library-button-text
                color="dark"
                iconOnly="check"
                (click)="saveEditedTitleClicked($event)"></button>

              <button
                *ngIf="makeActive.observed"
                ui-library-button-text
                color="dark"
                iconOnly="folder_check"
                (click)="makeActiveClicked($event)"></button>
              <div class="uj-text-border-border">|</div>
              <button
                ui-library-button-text
                [contextMenu]="contextMenu"
                (click)="$event.stopPropagation()"
                iconOnly="menu_dots_vertical"></button>
              <ng-template #contextMenu>
                <div class="uj-flex uj-flex-col uj-items-start">
                  <button
                    ui-library-button-text
                    fullWidth
                    align="start"
                    *ngIf="moveUp.observed"
                    color="dark"
                    iconLeft="arrow_up"
                    [disabled]="pageNumber === 1"
                    (click)="moveUpClicked($event)">
                    {{ "moveUp" | i18next }}
                  </button>

                  <button
                    ui-library-button-text
                    fullWidth
                    align="start"
                    *ngIf="moveDown.observed"
                    color="dark"
                    iconLeft="arrow_down"
                    (click)="moveDownClicked($event)">
                    {{ "moveDown" | i18next }}
                  </button>

                  <button
                    *ngIf="makeActive.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    iconLeft="folder_check"
                    (click)="makeActiveClicked($event)">
                    {{ "moveToActiveDocuments" | i18next }}
                  </button>

                  <ui-library-divider></ui-library-divider>
                  <button
                    *ngIf="toggleFavorite.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    [iconLeft]="
                      !page.favorite
                        ? 'star_filled'
                        : 'star_remove_unstar_cross_2'
                    "
                    (click)="toggleFavoriteClicked($event)">
                    {{
                      page.favorite
                        ? ("unfavorite" | i18next)
                        : ("makeFavorite" | i18next)
                    }}
                  </button>

                  <button
                    *ngIf="preview.observed"
                    (click)="previewClicked($event)"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    iconLeft="preview_zoom">
                    {{ "preview" | i18next }}
                  </button>

                  <button
                    *ngIf="titleChange.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    iconLeft="edit_pen_1"
                    (click)="editTitleClicked($event)">
                    {{ "rename" | i18next }}
                  </button>

                  <ng-container
                    *ngIf="
                      downloadStatus().progress === 0 ||
                      downloadStatus().errorMessage
                    ">
                    <button
                      ui-library-button-text
                      fullWidth
                      align="start"
                      color="dark"
                      iconLeft="download"
                      (click)="downloadDocument(pageOrFile())">
                      {{ "download" | i18next }}
                    </button>
                  </ng-container>
                  <ui-library-divider></ui-library-divider>
                  <button
                    *ngIf="remove.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="accent"
                    color="dark"
                    iconLeft="trash"
                    (click)="removeConfirmModal.openModal()">
                    {{ "remove" | i18next }}
                  </button>
                </div>
              </ng-template>
              <ui-library-modal
                #removeConfirmModal
                size="XS">
                <ng-container slot="header">{{
                  "removeDocument" | i18next
                }}</ng-container>
                <ng-container slot="content">
                  {{ "areYouSureYouWantToRemoveDocument" | i18next }}
                </ng-container>
                <ng-container slot="footer">
                  <button
                    ui-library-button-outline
                    (click)="removeConfirmModal.closeModal()">
                    {{ "cancel" | i18next }}
                  </button>
                  <button
                    ui-library-button-solid
                    (click)="
                      removeConfirmModal.closeModal(); removeClicked($event)
                    ">
                    {{ "remove" | i18next }}
                  </button>
                </ng-container>
              </ui-library-modal>
            }
          </div>
        </div>

        <div class="main-content__content">
          <div class="date_and_source">
            <span>{{
              pageOrFile().createdAt | date: "dd.MM.yyyy - HH:mm"
            }}</span>
          </div>
          <div class="page_and_filename">
            @if (page(); as page) {
              <span>{{ "page" | i18next }}</span>
              <span>{{ page.page }}</span>
              <span>{{ "from" | i18next }}</span>
              <span>{{
                page.talentFileReference.mediaObject.metadata?.numberOfPages ??
                  "?"
              }}</span>
              <span class="page_and_filename__title">
                ({{ page.talentFileReference.title }})</span
              >
            }
            @if (file(); as file) {
              <span>{{ file.title }}</span>
            }
          </div>
        </div>

        <ng-container
          *ngIf="
            downloadStatus().progress > 0 && !downloadStatus().errorMessage
          ">
          <ui-library-progress-bar
            [progress]="downloadStatus().progress"
            [hint]="'downloading' | i18next"></ui-library-progress-bar>
        </ng-container>
        <unijob-info-box
          *ngIf="downloadStatus()?.errorMessage"
          [title]="'error' | i18next"
          [message]="downloadStatus().errorMessage ?? ''"
          type="ERROR"
          [closable]="true">
        </unijob-info-box>
      </div>
    </div>
  </div>
</ui-library-card>
