import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnchorNavigationComponent } from './anchor-navigation.component';
import { TabModule } from '@intemp/unijob-ui';

@NgModule({
  declarations: [AnchorNavigationComponent],
  imports: [CommonModule, TabModule],
  exports: [AnchorNavigationComponent],
})
export class AnchorNavigationModule {}
