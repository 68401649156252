import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from '../../models/shared/user/user.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  title = 'pageNotFound';
  constructor(public userService: UserService) {}
}
