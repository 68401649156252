import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from '@angular/core';
import { ContextMenuModule, randomId, SheetModule } from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { ChangelogFieldDisplayType } from '../../../../graphql/generated';
import { UserAvatarModule } from '../../user-avatar/user-avatar.module';
import { UserInfoContextMenuModule } from '../../user/user-info-context-menu/user-Info-context-menu.module';
import { DATE_TIME_FORMAT } from '../../../../app.constants';
import { TalentChangelogService } from '../services/changelog-talent.service';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { map, shareReplay } from 'rxjs/operators';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ModalComponent,
  ModalService,
} from '@intemp/unijob-ui2';

type ChangelogEntry = PreparedTalentChangeLog;

@Component({
  standalone: true,
  selector: 'app-talent-changelog-record',
  templateUrl: './talent-changelog-record.component.html',
  imports: [
    SheetModule,
    SharedDefaultModule,
    UserAvatarModule,
    UserInfoContextMenuModule,
    ContextMenuModule,
    ButtonOutlineComponent,
    ButtonSolidComponent,
    ModalComponent,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentChangelogRecordComponent {
  service = inject(TalentChangelogService);
  cd = inject(ChangeDetectorRef);
  modalService = inject(ModalService);

  @Input({ required: true }) record!: ChangelogEntry;

  user$ = this.service.consultants$.pipe(
    map((users) => {
      return users[this.record.userId];
    }),
    shareReplay(1),
  );

  isCurrentUser$ = this.service.consultantsAndCurrentUser$.pipe(
    map(([users, currentUser]) => {
      return users[this.record.userId]?._id === currentUser?._id;
    }),
    shareReplay(1),
  );

  revertChangesModalId = 'revertChangesModalId' + randomId();

  revertChanges(record: ChangelogEntry) {
    const input = record.revertInput;
    if (!input) return;

    const fieldName = this.record.fieldName;
    if (!fieldName) {
      throw new Error('Field name is missing for' + this.record.fieldName);
    }
    if (!record._id) {
      throw new Error('record id is missing for' + record.fieldName);
    }

    this.service.revertChanges(record._id);
    this.modalService.close(this.revertChangesModalId);
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }
  openModal(id: string): void {
    this.modalService.open(id);
  }

  protected readonly DATE_TIME_FORMAT = DATE_TIME_FORMAT;
  protected readonly DisplayType = ChangelogFieldDisplayType;
}
