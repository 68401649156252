<label class="uj-block uj-mb-b text-m-b">
  {{ "responsibleEmployee" | i18next | firstUppercase
  }}<span class="red-text">*</span>
</label>
<ng-container *ngrxLet="allConsultants() as allConsultants">
  <app-consultant-select
    [formControl]="consultantIdControl()"
    [allConsultants]="allConsultants"
    class="uj-mb-e">
  </app-consultant-select>
</ng-container>
