import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  QueryList,
  signal,
  ViewChildren,
} from '@angular/core';
import { InfoBoxModule } from '@intemp/unijob-ui';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  IconComponent,
  TagComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { environment } from '../../../../../environments/environment';
import { TalentFragment } from '../../../../graphql/generated';
import { GlobalSheetsService } from '../../global-sheets/global-sheets.service';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';

@Component({
  standalone: true,
  selector: 'app-talent-document-preview',
  templateUrl: './talent-document-preview.component.html',
  styleUrls: ['./talent-document-preview.component.scss'],
  imports: [
    I18NextModule,
    SharedDefaultModule,
    IconComponent,
    ButtonTextComponent,
    InfoBoxModule,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    PdfViewerModule,
    TagComponent,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentDocumentPreviewComponent {
  globalSheetsService = inject(GlobalSheetsService);
  constructor() {
    effect(() => {
      const uuid = this.uuid();
      if (uuid) {
        this.scrollToImage(uuid);
      }
    });
  }

  readonly uuid = input<string | undefined>(undefined);
  readonly mode = input<string | undefined>(undefined);
  readonly talent = input.required<TalentFragment>();
  readonly shownPages = computed(() => {
    switch (this.mode()) {
      case 'all':
        return this.talent().pages;
      case 'favorite':
        return this.talent().pages.filter((page) => page.favorite);
      case 'inactive':
        return this.talent().pages.filter((page) => !page.active);
      case 'active':
      default:
        return this.talent().pages.filter((page) => page.active);
    }
  });
  loadedPdfs = signal(new Set<string>());

  @ViewChildren('lazyImage') imageElements!: QueryList<ElementRef>;

  private scrollToImage(pageUuid: string) {
    const imageElement = this.imageElements.find((element) => {
      return element.nativeElement.getAttribute('data-page-uuid') === pageUuid;
    });
    if (!imageElement) {
      return;
    }
    const farToScroll =
      Math.abs(
        imageElement.nativeElement.getBoundingClientRect().top -
          window.innerHeight / 2,
      ) > 4000;
    imageElement.nativeElement.scrollIntoView({
      behavior: farToScroll ? 'instant' : 'smooth',
    });
    // try again to compensate for lazy loaded elements in initial viewports
    setTimeout(() => {
      imageElement.nativeElement.scrollIntoView();
    }, 500);
    setTimeout(() => {
      imageElement.nativeElement.scrollIntoView();
    }, 1000);
  }

  i18next = inject(I18NextPipe);
  public getDocumentPreviewSrc(
    page: TalentFragment['pages'][number] | TalentFragment['files'][number],
  ) {
    const avatarSrcPath = page.mediaObject.sizes
      ?.find((size) => size.name === 'proxy')
      ?.src.replace(':talentUuid', this.talent().uuid);
    return avatarSrcPath ? environment.mediaUrl + avatarSrcPath : '';
  }

  public getDocumentDownloadSrc(
    page: TalentFragment['pages'][number] | TalentFragment['files'][number],
  ) {
    return (
      environment.mediaUrl +
        page.mediaObject.downloadUrl?.replace(
          ':talentUuid',
          this.talent().uuid,
        ) || ''
    );
  }

  public trackByPage(index: number, page: TalentFragment['pages'][number]) {
    return page.uuid + page.favorite.toString() + page.active.toString();
  }

  public loadPdf(page: TalentFragment['pages'][number]) {
    const updatedSet = this.loadedPdfs();
    updatedSet.add(page.uuid);
    this.loadedPdfs.set(updatedSet);
  }

  public downloadStatus = signal<{
    progress: number;
    errorMessage?: string;
    src: string;
  }>({
    progress: 0,
    src: '',
    errorMessage: undefined,
  });

  onScrollPages(event: Event) {
    const target = event.target as HTMLElement;
    const pagesElements = target.querySelectorAll('.page');

    //find the page that is most visible in the viewport
    let maxVisibleArea = 0;
    let mostVisiblePage: Element | null = null;
    pagesElements.forEach((pageElement) => {
      const rect = pageElement.getBoundingClientRect();
      const visibleArea =
        Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
      if (visibleArea > maxVisibleArea) {
        maxVisibleArea = visibleArea;
        mostVisiblePage = pageElement;
      }
    });

    if (!mostVisiblePage) {
      return;
    }

    const pageUuid = (mostVisiblePage as Element).id;
    this.globalSheetsService.documentsPreviewActivePage.set(pageUuid);
  }
}
