<div>
  <div class="uj-flex uj-items-center uj-justify-between">
    <div
      class="uj-flex uj-flex-2 uj-w-0 uj-flex-1 uj-min-h-[36px] uj-items-center">
      <h2 class="uj-truncate title-m">
        {{
          (match().talent.firstName
            ? match().talent.firstName
            : ("firstName" | i18next | firstUppercase)) +
            " " +
            (match().talent.lastName
              ? match().talent.lastName
              : ("lastName" | i18next | firstUppercase))
        }}
      </h2>
    </div>
    <div
      class="uj-items-center uj-justify-end uj-hidden uj-ml-d md:uj-flex"
      *ngIf="userService.readyUser$ | async">
      <div>
        <app-current-user-avatar />
      </div>
    </div>
  </div>
  <div class="uj-flex uj-mt-b uj-center md:uj-justify-between">
    <div class="uj-flex uj-items-center">
      <div class="uj-flex uj-gap-b uj-items-center">
        <span
          ui-library-dot
          size="S"
          corner="round"
          [color]="
            match().status === MatchStatusEnum.NEW
              ? 'accent'
              : match().status === MatchStatusEnum.ACTIVE
                ? 'positive'
                : 'grey'
          ">
        </span>
        <span class="uj-text-text text-s-b">
          {{ "MatchStatusEnum." + match().status | i18next }}
        </span>
      </div>
      <ng-container *ngIf="match().matchNumber">
        <span class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
        <span class="text-s">{{ match().matchNumber }} </span>
      </ng-container>
    </div>

    <div
      class="uj-flex uj-flex-row-reverse uj-items-center uj-ml-c md:uj-flex-row">
      <div class="uj-flex uj-flex-row-reverse uj-items-center md:uj-flex-row">
        <span class="uj-hidden text-s sm:uj-block">
          {{ lastUpdatedForUserAt().lastEditTime }}
        </span>
        <span class="uj-block text-s sm:uj-hidden">
          {{ lastUpdatedForUserAt().lastEditTimeMobile }}
        </span>
        <i
          ui-library-icon-tag
          icon="check"
          class="uj-mr-[6px] md:uj-mr-0 md:uj-ml-[6px]"
          color="positive"></i>
      </div>
    </div>
  </div>
</div>
