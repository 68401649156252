import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { SexEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getSalutationOptions = (
  i18next: I18NextPipe,
): SelectOption<SexEnum>[] => {
  return [
    {
      value: SexEnum.FEMALE,
      name: i18next.transform('Salutation.FEMALE'),
    },
    {
      value: SexEnum.MALE,
      name: i18next.transform('Salutation.MALE'),
    },
    {
      value: SexEnum.OTHER,
      name: i18next.transform('Salutation.OTHER'),
    },
  ];
};
