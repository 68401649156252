import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  DatePickerModule,
  InfoBoxModule,
  InputTextModule,
  randomId,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import {
  FormFieldComponent,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';
import { FormControlComponent } from '../../../../../shared/components/form-control/form-control.component';
import { InputLabelComponent } from '../../../../../shared/components/input-label/input-label.component';
import { IMaskDirective } from 'angular-imask';
import { FactoryArg } from 'imask';

@Component({
  standalone: true,
  selector: 'app-talent-ahv-number',
  templateUrl: 'talent-ahv-number.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    UjSelectInputModule,
    DatePickerModule,
    SimpleSelectComponent,
    InputTextModule,
    InfoBoxModule,
    FormControlComponent,
    InputLabelComponent,
    FormFieldComponent,
    TextInputComponent,
    IMaskDirective,
  ],
})
export class TalentAhvNumberComponent {
  formService = inject(TalentFormEditService);
  ahvNumberControl = this.formService.personalData.controls.ahvNumber;
  componentId = randomId();
  ahvMask: FactoryArg = { mask: '{756.}0000.0000.00' };
}
