<ui-library-form-field
  fullWidth
  [label]="'talent' | i18next | firstUppercase">
  <ui-library-card
    id="match-talent-card"
    padding="8px"
    interactable
    (click)="openTalent(match().talent.uuid)"
    class="uj-flex uj-gap-d">
    <div class="uj-flex uj-gap-d uj-items-center">
      <ui-library-avatar
        size="s"
        content="letters"
        [letters]="match().talent.firstName?.slice(0, 2)"
        icon="user"></ui-library-avatar>
      <div class="uj-flex-1 uj-w-0 uj-grow">
        <div class="uj-flex">
          <span class="text-m-b uj-truncate"
            >{{ match().talent.firstName }} {{ match().talent.lastName }}</span
          >
        </div>
        <div
          *ngIf="match().talent.workAvailability as workAvailability"
          class="uj-flex uj-gap-b uj-items-center uj-truncate">
          <div class="uj-flex uj-gap-b uj-items-center uj-truncate">
            <span
              class="uj-min-w-[8px]"
              ui-library-dot
              size="S"
              corner="round"
              [color]="
                workAvailability === WorkAvailabilityEnum.OPEN_TO_WORK
                  ? 'positive'
                  : workAvailability === WorkAvailabilityEnum.PLACED
                    ? 'grey'
                    : workAvailability === WorkAvailabilityEnum.BLACKLISTED
                      ? 'dark'
                      : workAvailability === WorkAvailabilityEnum.IN_EMPLOYMENT
                        ? 'warning-1'
                        : 'grey'
              ">
            </span>
            <span class="uj-text-text text-s-b uj-truncate">{{
              "WorkAvailabilityEnum." + workAvailability | i18next
            }}</span>
          </div>
          <span
            *ngIf="availableForWorkAt() as availableForWorkAt"
            class="uj-text-text-s uj-w-0 uj-grow uj-truncate">
            {{
              "asFrom"
                | i18next
                  : {
                      date: availableForWorkAt.date | date,
                      duration: availableForWorkAt.duration,
                      durationUnit: availableForWorkAt.durationUnit | i18next,
                    }
            }}
          </span>
        </div>
      </div>
      <div class="uj-flex uj-gap-c uj-items-center">
        <a
          *ngIf="match().talent?.phones?.at(0)"
          (click)="$event.stopPropagation()"
          target="_blank"
          [href]="'tel:' + match().talent?.phones?.at(0)"
          [tooltip]="'call' | i18next"
          ui-library-button-text
          iconOnly="phone"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"></a>
        <a
          *ngIf="match().talent?.emails?.at(0)"
          (click)="$event.stopPropagation()"
          target="_blank"
          [href]="'mailto:' + match().talent?.emails?.at(0)"
          [tooltip]="'writeEmail' | i18next"
          ui-library-button-text
          iconOnly="mail"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"></a>
        <button
          ui-library-button-text
          iconOnly="preview_zoom"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"
          (click)="
            $event.stopPropagation(); openTalentPreview(match().talent.uuid)
          "></button>
        <div class="uj-h-e uj-w-[1px] uj-bg-border"></div>
        <button
          ui-library-button-text
          iconOnly="arrow_right"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"></button>
      </div>
    </div>
  </ui-library-card>
</ui-library-form-field>
