<ui-library-select
  [ngModel]="this.chosenKey | async"
  (ngModelChange)="newKeyChosen($event)"
  [collapsedSelectedItemComponent]="selectedComponent"
  [expandedSelectedItemComponent]="selectedComponent"
  [isSingleSelect]="true"
  [validation]="selectValidation"
  [disabled]="disabled">
  <ng-template #noItemSelectedTemplate>
    <ui-library-avatar
      content="icon"
      icon="user"
      size="s"></ui-library-avatar>
    <div class="uj-flex-grow uj-min-w-0 ng-tns-c2327231876-2">
      <span>{{ "chooseCompanyContact" | i18next }}</span>
    </div>
  </ng-template>
  <ui-library-textbox-list-item [actionGenerator]="searchFilter" />
  <ui-library-selection-list [behavior]="singleSelectBehavior">
    <app-avatar-list-item
      *ngFor="let entry of options | async; trackBy: trackByKey"
      [_value]="entry.key"
      [label]="entry" />
  </ui-library-selection-list>
  <div class="uj-mx-d uj-mb-d uj-border-t uj-border-border">
    <p class="uj-text-text-m/[20px] uj-py-d uj-text-text">
      {{ "searchCompanyContactOptionsFooterInstruction" | i18next }}
    </p>
    <button
      id="companyContact"
      class="uj-w-full"
      ui-library-button-outline
      type="button"
      iconRight="download_arrow"
      iconLeft="user"
      (click)="$event.stopPropagation(); importUnibaseContactSheet.open()">
      {{ "importContact" | i18next }}
    </button>
  </div>
</ui-library-select>

<app-vacancy-unibase-contact-import
  (importComplete)="
    importUnibaseContactSheet.complete($event);
    importUnibaseContactSheet.close()
  "
  [companyLocationUuid]="
    companyLocationUuid
  "></app-vacancy-unibase-contact-import>
<ng-template #placeholderAvatar>
  <ui-library-avatar
    content="icon"
    icon="user"
    size="s"></ui-library-avatar>
</ng-template>
