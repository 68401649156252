import { Component, input, model } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { InfoBoxModule } from '@intemp/unijob-ui';
import { LetDirective } from '@ngrx/component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SimpleSelectComponent } from '@intemp/unijob-ui2';
import { ConsultantSelectModule } from '../../consultant-select/consultant-select.module';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { UserListItemFragment } from '../../../../graphql/generated';

@Component({
  standalone: true,
  selector: 'app-match-responsibility',
  templateUrl: './match-responsibility.component.html',
  imports: [
    AsyncPipe,
    ConsultantSelectModule,
    I18NextModule,
    InfoBoxModule,
    LetDirective,
    SharedDefaultModule,
    ReactiveFormsModule,
    SimpleSelectComponent,
  ],
  providers: [],
})
export class MatchResponsibilityComponent {
  allConsultants = input.required<UserListItemFragment[]>();
  consultantIdControl = model.required<FormControl>();
}
