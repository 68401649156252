import {
  CompanyFragment,
  CompanyLocationFragment,
} from '../../../../graphql/generated';

/**
 * By providing company and a location this function will generate the most appropriate name for the location.
 */
export default function generateCompanyLocationName(
  company: CompanyFragment,
  location: CompanyLocationFragment,
): string {
  if (company.locations.length === 1) {
    return company.legalName;
  }

  const allLocationsInSameCity = company.locations.every(
    (companyLocation) =>
      companyLocation.address.location === location.address.location,
  );
  if (allLocationsInSameCity) {
    return `${company.legalName} (${location.address.street})`;
  }

  const multipleLocationsWithinSameCity = company.locations.some(
    (companyLocation) =>
      companyLocation.uuid !== location.uuid &&
      companyLocation.address.location === location.address.location,
  );

  if (multipleLocationsWithinSameCity) {
    // return `${company.legalName} (${location.address.location}, ${location.address.street})`;
    // At this point it does not help since the address is shown adjacent to the company name anyway.
    return company.legalName;
  }

  return `${company.legalName} (${location.address.location})`;
}
