import {
  ChangelogFieldDisplayType,
  TalentFragment,
  TalentHistoryEntryFragment,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { TalentImageFields } from '../../talent-sheet/talent-sheet.types';
import { resolveChangelogAction } from './resolveChangelogAction';
import { revertResultTypeIsIdentical } from './revertResultTypeIsIdentical';
import { environment } from '../../../../../environments/environment';

export function prepareTalentChangLogImage(
  entry: TalentHistoryEntryFragment,
  fieldName: keyof TalentImageFields,
  talent: TalentFragment,
): PreparedTalentChangeLog {
  const displayedCurrentValue = entry.mutation?.input[fieldName]
    ? getDisplayedValueByFieldName(fieldName, entry._id, false)
    : undefined;
  const displayedPreviousValue = entry.revertInput?.[fieldName]
    ? getDisplayedValueByFieldName(
        fieldName,
        entry._id,
        entry.revertInput !== null,
      )
    : undefined;
  return {
    ...entry,
    fieldName,
    displayedCurrentValue,
    displayedPreviousValue,
    revertInput: entry.revertInput,
    mutationFieldType: ChangelogFieldDisplayType.IMAGE,
    action: resolveChangelogAction(
      displayedCurrentValue,
      displayedPreviousValue,
    ),
    contentIsIdentical: revertResultTypeIsIdentical(fieldName, entry, talent),
  };
}

function getDisplayedValueByFieldName(
  fieldName: keyof TalentImageFields,
  entryId: string | null,
  isRevert: boolean,
) {
  switch (fieldName) {
    case 'avatar':
      // because resolver fields (sizes) does not work currently
      return `${environment.mediaUrl}/talent-avatar/history/image/${entryId}/${isRevert ? 'revert' : 'input'}/abc/avatarM`;
    default:
      return 'Unhandeled image field type';
  }
}
