<div
  class="uj-flex uj-items-center uj-p-d uj-border uj-rounded uj-border-border uj-gap-d">
  <ng-container *ngIf="isEditingAddress; else preview">
    <div
      class="uj-w-full uj-grid uj-grid-cols-1 md:uj-grid-cols-4 uj-gap-c uj-items-end fist:uj-mt-d"
      [formGroup]="address">
      <ui-library-form-field
        class="md:uj-col-start-1 md:uj-col-end-5"
        fullWidth
        required
        [label]="'streetAndNumber' | i18next | firstUppercase"
        [labelFor]="'streetAndNumber' + componentId">
        <input
          ui-library-text-input
          [id]="'streetAndNumber' + componentId"
          [placeholder]="'streetAndNumber' | i18next"
          formControlName="streetAndNumber"
          focusTracker="streetAndNumber"
          type="text" />
      </ui-library-form-field>
      <ui-library-form-field
        class="md:uj-col-start-1 md:uj-col-end-2"
        fullWidth
        required
        [label]="'zip' | i18next | firstUppercase"
        [labelFor]="'zip' + componentId">
        <input
          ui-library-text-input
          [id]="'zip' + componentId"
          [placeholder]="'zip' | i18next"
          formControlName="zip"
          focusTracker="zip"
          type="text" />
      </ui-library-form-field>
      <ui-library-form-field
        class="md:uj-col-span-3"
        fullWidth
        required
        [label]="'location' | i18next | firstUppercase"
        [labelFor]="'location' + componentId">
        <input
          ui-library-text-input
          [id]="'location' + componentId"
          [placeholder]="'location' | i18next"
          formControlName="location"
          focusTracker="location"
          type="text" />
      </ui-library-form-field>
      <ui-library-form-field
        class="md:uj-col-start-1 md:uj-col-end-5"
        fullWidth
        required
        [label]="'country' | i18next | firstUppercase"
        [labelFor]="'country' + componentId">
        <ui-library-simple-select
          size="M"
          design="outline"
          [searchable]="true"
          backgroundColor="white"
          [placeholderText]="'choose' | i18next"
          [options]="countryOptions"
          formControlName="country" />
      </ui-library-form-field>

      <div
        class="md:uj-col-start-1 md:uj-col-end-5 uj-flex uj-flex-col md:uj-flex-row uj-gap-c uj-mt-d">
        <ng-container
          *ngIf="address.controls.isNewEntry.value; else addressToUpdate">
          <button
            class="!uj-w-full"
            ui-library-button-outline
            corner="rounded"
            color="primary"
            (click)="cancelEditAddress()">
            {{ "cancel" | i18next | firstUppercase }}
          </button>
          <button
            [disabled]="!address.valid"
            class="!uj-w-full"
            ui-library-button-solid
            iconLeft="plus"
            color="primary"
            (click)="addAddress()">
            {{ "add" | i18next | firstUppercase }}
          </button>
        </ng-container>
        <ng-template #addressToUpdate>
          <button
            class="!uj-w-full"
            ui-library-button-outline
            iconLeft="trash"
            color="primary"
            (click)="modals.open(deleteConfirmationModalId)">
            {{ "delete" | i18next | firstUppercase }}
          </button>
          <button
            class="!uj-w-full"
            ui-library-button-solid
            iconLeft="check"
            color="primary"
            (click)="updateAddressEntry()">
            {{ "save" | i18next | firstUppercase }}
          </button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #preview>
  <div class="uj-flex uj-flex-col">
    <ui-library-avatar
      class="uj-display-inline-block"
      borderColor="grey"
      content="icon"
      icon="pin_location"
      size="s"
      shape="round"
      tagType="tagAvatar">
    </ui-library-avatar>
  </div>
  <div class="uj-flex-grow text-m">
    <div class="text-m-b">
      {{ address.controls.streetAndNumber.value }}
    </div>
    <div>
      {{ address.controls.zip.value }} {{ address.controls.location.value }}
      {{ address.controls.country.value }}
    </div>
  </div>
  <a
    *ngIf="addressForGoogleMaps"
    ui-library-button-text
    target="_blank"
    [href]="addressForGoogleMaps | addressToGoogleMapUrl"
    class="uj-pointer-events-auto"
    iconOnly="map"
    color="dark">
  </a>
  <button
    ui-library-button-text
    iconOnly="edit_pen_1"
    (click)="editAddress()"></button>
</ng-template>

<ui-library-modal
  [id]="deleteConfirmationModalId"
  size="S">
  <ng-container slot="header">
    {{ "deleteEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "areYouSureYouWantToDeleteThisEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="modals.close(deleteConfirmationModalId)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deleteAddress()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button"
        iconLeft="trash">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>
