import { Component, model } from '@angular/core';
import { SimpleSelectComponent } from '@intemp/unijob-ui2';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18NextModule } from 'angular-i18next';
import { getSupportedLanguageOptions } from '../../modules/vacancy-detail/helpers/getSupportedLanguageOptions';
import { SupportedLanguage } from '../../../graphql/generated';

@Component({
  standalone: true,
  selector: 'app-supported-language-selector',
  templateUrl: './supported-language-selector.component.html',
  imports: [
    SimpleSelectComponent,
    FormsModule,
    I18NextModule,
    ReactiveFormsModule,
  ],
})
export class SupportedLanguageSelectorComponent {
  languageInput = model.required<FormControl<SupportedLanguage | null>>();
  languageOptions = getSupportedLanguageOptions(true);
  constructor() {}
}
