import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { Chart, ChartConfiguration } from 'chart.js';
import 'chartjs-adapter-date-fns';
import Annotation, { AnnotationPluginOptions } from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';
import {
  BehaviorSubject,
  combineLatest,
  map,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import {
  TimelineResolutionEnum,
  TrackedActionTypeEnum,
  VacancyDetailFragment,
  VacancyStatsSumOfActionPerSourceTimelineGQL,
  VacancyStatsSumOfActionPerSourceTimelineQuery,
  VacancyStatusEnum,
  VacancyTrackedActionTotalsGQL,
} from '../../../graphql/generated';
import { ChannelsService } from '../../../models/unibase/channels/channels.service';
import { sourceToPlatformMap } from '../../constants/source-to-platform-map';
import { statsColorPalette } from '../../constants/stats-color-palette';
import { statsDefaultLinechartOptions } from '../../constants/stats-default-linechart-options';
import { rxFilterIsNotEmpty } from '../../helpers/functions/rxFilterIsNotEmpty';
import { TextOption } from '@intemp/unijob-ui2';

@Component({
  selector: 'app-vacancy-stats',
  templateUrl: './vacancy-stats.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyStatsComponent implements AfterViewInit, OnDestroy {
  constructor(
    private statsGql: VacancyStatsSumOfActionPerSourceTimelineGQL,
    private totalsGql: VacancyTrackedActionTotalsGQL,
    private i18nPipe: I18NextPipe,
    private channelsService: ChannelsService,
    private cd: ChangeDetectorRef,
  ) {
    Chart.register(Annotation);
  }

  private subscription = new Subscription();

  async ngAfterViewInit(): Promise<void> {
    this.subscription.add(
      this.dataObservable$.subscribe((data) => this.updateChartData(data)),
    );
    this.subscription.add(
      this.dataTotals$.subscribe((data) => {
        this.viewsTotal = data.totals.VACANCY_VIEWED ?? 0;
        this.applicationsTotal = data.totals.VACANCY_APPLICATION ?? 0;
        this.allViewsTotal = data.allVacancies.VACANCY_VIEWED ?? 0;
        this.allApplicationsTotal = data.allVacancies.VACANCY_APPLICATION ?? 0;
        this.cd.markForCheck();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public vacancyNullable$ = new BehaviorSubject<VacancyDetailFragment | null>(
    null,
  );
  public vacancy$ = this.vacancyNullable$.pipe(rxFilterIsNotEmpty());

  public selectedAction$ = new BehaviorSubject<TrackedActionTypeEnum>(
    TrackedActionTypeEnum.VACANCY_VIEWED,
  );

  public applicationsTotal = 0;
  public viewsTotal = 0;
  public allApplicationsTotal = 0;
  public allViewsTotal = 0;

  public dataObservable$ = combineLatest([
    this.channelsService.channelsSubscription$,
    this.vacancy$,
    this.selectedAction$,
  ]).pipe(
    switchMap(([availableChannels, vacancy, selectedAction]) => {
      const end =
        vacancy.status === VacancyStatusEnum.LIVE
          ? new Date()
          : new Date(vacancy.archivedAt ?? '');
      const start = new Date(vacancy.derivedFields.firstPublishedAt ?? '');
      if (
        !vacancy?.public?.uuid ||
        !end ||
        !vacancy.derivedFields.firstPublishedAt
      ) {
        return of(undefined);
      }

      // add annotations (only channels with prerequisites for now (aka. addons))
      const annotations: AnnotationPluginOptions['annotations'] = [];
      if (vacancy.uniBaseX?.configuredChannels?.length) {
        vacancy.uniBaseX.configuredChannels.forEach((conf) => {
          const channel = availableChannels.items?.find(
            (c) => c.uuid === conf.channelReference.uuid,
          );
          if (
            conf.runtimes?.[0]?.start &&
            channel &&
            !channel.internal // only addons for now
          ) {
            const startSpanValue = new Date(conf.runtimes[0].start);
            const annotationEnd = conf.runtimes?.[0]?.end ?? end.toISOString();
            const endSpanValue = new Date(annotationEnd);

            // if on same day ignore
            if (startSpanValue.toDateString() === endSpanValue.toDateString()) {
              return;
            }

            const name =
              channel.name +
              (conf.exportSettings?.universaljobSearch?.plan
                ? ' - ' +
                  this.i18nPipe.transform(
                    conf.exportSettings?.universaljobSearch?.plan ?? '',
                  )
                : '');
            // Start date line with label
            annotations.push({
              type: 'line',
              value: startSpanValue.toISOString(),
              scaleID: 'x',
              borderColor: !channel?.prerequisiteChannel
                ? '#e6007e'
                : '#281E76',
              borderWidth: 1,
              borderDash: [2, 2],
              label: {
                content: name,
                rotation: 'auto',
                position: '0%',
                backgroundColor: !channel?.prerequisiteChannel
                  ? '#e6007e'
                  : '#281E76',
                callout: {
                  display: false,
                },
                borderRadius: {
                  topLeft: 0,
                  topRight: 3,
                  bottomLeft: 0,
                  bottomRight: 0,
                },
                display: true,
                yAdjust: -3,
                xAdjust: 9.5,
                padding: { top: 3, right: 7, bottom: 3, left: 7 },
                textAlign: 'start',
                color: 'white',
                font: {
                  weight: 'normal',
                  size: 12,
                },
                z: 100,
              },
            });
            // Area between start and end
            annotations.push({
              type: 'box',
              xMin: startSpanValue.toISOString(),
              xMax: endSpanValue.toISOString(),
              xScaleID: 'x',
              yScaleID: 'y',
              yMin: '50%',
              backgroundColor: !channel?.prerequisiteChannel
                ? '#e6007e14'
                : '#281E7614',
              borderColor: !channel?.prerequisiteChannel
                ? '#e6007e'
                : '#281E76',
              borderWidth: 0,
              borderDash: [2, 2],
              borderRadius: 0,
            });
          }
        });
      }

      this.lineChartOptions = {
        ...this.lineChartOptions,
        plugins: {
          ...this.lineChartOptions.plugins,
          legend: { display: true },
          annotation: {
            annotations: annotations,
          },
        },
      };

      return this.statsGql.fetch({
        args: {
          publicVacancyIds: vacancy.public.uuid,
          action: selectedAction,
          from: start.toISOString(),
          to: end.toISOString(),
          resolution: TimelineResolutionEnum.DAY,
        },
      });
    }),
    rxFilterIsNotEmpty(),
    map((d) => d.data?.vacancyStatsSumOfActionPerSourceTimeline),
  );

  public dataTotals$ = combineLatest([this.vacancy$]).pipe(
    switchMap(([vacancy]) => {
      const end =
        vacancy.status === VacancyStatusEnum.LIVE
          ? new Date()
          : new Date(vacancy.archivedAt ?? '');
      const start = new Date(vacancy.derivedFields.firstPublishedAt ?? '');
      if (
        !vacancy?.public?.uuid ||
        !end ||
        !vacancy.derivedFields.firstPublishedAt
      ) {
        return of(undefined);
      }
      end.setUTCHours(23, 59, 59, 999);
      start.setUTCHours(0, 0, 0, 0);
      return this.totalsGql.fetch({
        args: {
          publicVacancyIds: vacancy.public.uuid,
          actions: [
            TrackedActionTypeEnum.VACANCY_VIEWED,
            TrackedActionTypeEnum.VACANCY_APPLICATION,
          ],
          from: start.toISOString(),
          to: end.toISOString(),
        },
      });
    }),
    rxFilterIsNotEmpty(),
    map((d) => d.data?.vacancyTrackedActionTotals),
  );

  @Input({ required: true })
  set vacancy(value: VacancyDetailFragment) {
    this.vacancyNullable$.next(value);
  }

  public actionOptions: readonly TextOption[] = [
    {
      value: 'VACANCY_APPLICATION',
      label: this.i18nPipe.transform('trackedActionType.VACANCY_APPLICATION'),
    },
    {
      value: 'VACANCY_VIEWED',
      label: this.i18nPipe.transform('trackedActionType.VACANCY_VIEWED'),
    },
  ] as const;

  public platformsMap = sourceToPlatformMap;

  public lineChartData: ChartConfiguration<
    'line',
    readonly number[],
    Date
  >['data'] = {
    labels: [],
    datasets: [],
  };
  public datasets: VacancyStatsSumOfActionPerSourceTimelineQuery['vacancyStatsSumOfActionPerSourceTimeline']['datasets'] =
    [];
  public lineChartOptions = statsDefaultLinechartOptions;

  public lineChartLegend = false;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  private updateChartData(
    data: VacancyStatsSumOfActionPerSourceTimelineQuery['vacancyStatsSumOfActionPerSourceTimeline'],
  ): void {
    this.datasets = data.datasets;
    this.lineChartData = {
      labels:
        data.labels.map((date) => {
          return new Date(date);
        }) || [],
      datasets: data.datasets.map((dataset, index) => {
        let color = statsColorPalette?.[index] || '#C2C2C2';
        if (dataset.label === 'universaljob-others') {
          color = '#281E76';
        }
        const source = dataset.label.split('/')[0];
        const campaign = dataset.label.split('/')?.[1];
        return {
          hidden: index > 3,
          data: dataset.data,
          label: `${sourceToPlatformMap?.[source]?.label || source}${campaign ? ` (${campaign[0].toUpperCase()}${campaign.slice(1)})` : ''}`,
          fill: false,
          total: dataset.total,
          tension: 0.1,
          borderColor: color,
          backgroundColor: color + '33',
          pointBorderWidth: 0,
          pointBackgroundColor: color,
        };
      }),
    };
    this.cd.markForCheck();
  }

  public onDatasetVisibilityToggle(datasetIndex: number) {
    const dataset = this.lineChartData.datasets[datasetIndex];
    dataset.hidden = !dataset.hidden;
    this.chart?.update();
  }
}
