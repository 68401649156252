import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { ReligiousDenominationEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getReligiousDenominationOptions = (
  i18next: I18NextPipe,
): SelectOption<ReligiousDenominationEnum>[] => {
  return [
    {
      value: ReligiousDenominationEnum.BUDDHIST,
      name: i18next.transform('ReligiousDenominationEnum.BUDDHIST'),
    },
    {
      value: ReligiousDenominationEnum.EVANGELICAL_REFORMED,
      name: i18next.transform('ReligiousDenominationEnum.EVANGELICAL_REFORMED'),
    },
    {
      value: ReligiousDenominationEnum.HINDU,
      name: i18next.transform('ReligiousDenominationEnum.HINDU'),
    },
    {
      value: ReligiousDenominationEnum.JEWISH,
      name: i18next.transform('ReligiousDenominationEnum.JEWISH'),
    },
    {
      value: ReligiousDenominationEnum.MUSLIM,
      name: i18next.transform('ReligiousDenominationEnum.MUSLIM'),
    },
    {
      value: ReligiousDenominationEnum.NON_RELIGIOUS,
      name: i18next.transform('ReligiousDenominationEnum.NON_RELIGIOUS'),
    },
    {
      value: ReligiousDenominationEnum.ROMAN_CATHOLIC,
      name: i18next.transform('ReligiousDenominationEnum.ROMAN_CATHOLIC'),
    },
  ];
};
