<ng-container *ngIf="uploadHeaders$ | async as headers">
  <ui-library-avatar-input
    [uploadUrl]="mediaUrl + '/unlinked-document/upload'"
    [uploadHeaders]="headers"
    [avatarOptions]="this.avatarOptions"
    [transformOptions]="this.stagedTranformations"
    [imageToCrop]="this.imageToCrop"
    (uploadDone)="uploadDone($event)"
    (save)="save()"
    (remove)="remove()"
    (cancel)="cancel()"
    (transformOptionsChange)="
      onTransformOptionsChange($event)
    "></ui-library-avatar-input>
</ng-container>
