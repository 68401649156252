import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';

export function getFirstNonUuidFieldKey<T extends { uuid: string }>(input: T) {
  const keys = getObjectKeys(input).filter((key) => !['uuid'].includes(key));
  if (keys.length === 0 || keys.length > 1) {
    const typeName = input.constructor.name || 'UnknownType';
    throw new Error(`could not determine fieldKey for ${typeName}` + keys);
  }
  return getObjectKeys(input).filter(
    (key): key is Extract<keyof Omit<T, 'uuid'>, string> =>
      !['uuid'].includes(key),
  )[0];
}
