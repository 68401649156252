import { I18NextPipe } from 'angular-i18next';

export const getEmploymentTermTextOptions = (i18next: I18NextPipe) => {
  return [
    {
      value: true,
      label: i18next.transform('EmploymentTermEnum.FIXED_TERM'),
    },
    {
      value: false,
      label: i18next.transform('EmploymentTermEnum.FULL_TERM'),
    },
  ] as const;
};
