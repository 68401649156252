import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DocumentEventListenerService, setTimeout$ } from '@intemp/unijob-ui';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type AnchorPoint = {
  name: string;
  id: string;
  beforeScrollFunction?: (anchor: AnchorPoint) => Promise<void>;
};

@Component({
  selector: 'app-anchor-navigation',
  templateUrl: './anchor-navigation.component.html',
  styleUrls: ['./anchor-navigation.component.scss'],
})
export class AnchorNavigationComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  destroyed$ = new Subject<void>();

  @Input() anchorList: AnchorPoint[] = [];
  @Input() customScrollEventName?: string;
  @Input() scrollContainer?: HTMLElement | null;
  @Input() thresholdMultiplier = 0;
  @Input() thresholdNumber = 0;

  @Output() anchorClicked = new EventEmitter<AnchorPoint>();

  activeAnchor?: string;

  constructor(
    private documentEventListenerService: DocumentEventListenerService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['anchorList']) {
      this.checkActiveAnchor();
    }
  }

  ngAfterViewInit() {
    setTimeout$(() => {
      this.checkActiveAnchor();
    });

    if (this.customScrollEventName) {
      window.addEventListener(this.customScrollEventName, () => {
        this.checkActiveAnchor();
      });
    } else {
      this.documentEventListenerService.scrollEmitter
        .pipe(takeUntil(this.destroyed$), debounceTime(50))
        .subscribe(() => {
          this.checkActiveAnchor();
        });
    }
  }

  async scrollToAnchor(id: string) {
    const anchor = this.anchorList.find((anchor) => anchor.id === id);
    if (anchor?.beforeScrollFunction) {
      await anchor.beforeScrollFunction(anchor);
    }

    const element = document.getElementById(id);
    if (!element) return;
    const scrollContainer = this.scrollContainer
      ? this.scrollContainer
      : window;
    const scrollAreaHeight = this.scrollContainer
      ? this.scrollContainer.clientHeight
      : window.innerHeight;
    const topPosition =
      element.offsetTop -
      this.thresholdNumber -
      scrollAreaHeight * this.thresholdMultiplier;
    scrollContainer?.scroll({ top: topPosition, behavior: 'smooth' });
  }

  public checkActiveAnchor() {
    if (!this.anchorList.length) return;
    const scrollAreaHeight = this.scrollContainer
      ? this.scrollContainer.clientHeight
      : window.innerHeight;
    const scrollThreshold =
      scrollAreaHeight * this.thresholdMultiplier + this.thresholdNumber;
    let activeAnchor = this.anchorList[0].id;
    this.anchorList.forEach((anchor) => {
      const anchorElement = document.getElementById(anchor.id);
      const activeAnchorElement = document.getElementById(activeAnchor);

      if (!anchorElement || !activeAnchorElement) return;

      const elementTop = Math.round(anchorElement.getBoundingClientRect().top);
      const activeAnchorTop = Math.round(
        activeAnchorElement.getBoundingClientRect().top,
      );
      if (elementTop <= scrollThreshold && elementTop >= activeAnchorTop) {
        activeAnchor = anchor.id;
      }
    });
    this.activeAnchor = activeAnchor;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
