export function findMismatches<T extends { [key: string]: any }>(
  obj1: T,
  obj2: T,
  path = '',
  differences: string[] = [],
): string[] {
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        const nestedPath = path ? `${path}.${key}` : key;
        findMismatches(obj1[key], obj2[key], nestedPath, differences);
      } else if (obj1[key] !== obj2[key]) {
        const diffPath = path ? `${path}.${key}` : key;
        differences.push(diffPath);
      }
    } else {
      const missingPath = path ? `${path}.${key}` : key;
      differences.push(missingPath);
    }
  }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      const extraPath = path ? `${path}.${key}` : key;
      differences.push(extraPath);
    }
  }

  return differences;
}
