import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AvatarInputComponent } from '@intemp/unijob-ui2';
import {
  ImageToCrop,
  TransformOptions,
} from '@intemp/unijob-ui2/atom/cropper/cropper.component';
import { AvatarOptions } from '@intemp/unijob-ui2/molecule/avatar/avatar.component';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { MediaObjectFragment } from '../../../../../graphql/generated';
import { applyToken } from '../../../../helpers/functions/applyToken';
import { UserService } from '../../../../../models/shared/user/user.service';

@Component({
  selector: 'app-talent-avatar-input',
  standalone: true,
  imports: [AvatarInputComponent, CommonModule],
  templateUrl: 'talent-avatar.component.html',
  styles: [``],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TalentAvatarInputComponent),
      multi: true,
    },
  ],
})
export class TalentAvatarInputComponent implements ControlValueAccessor {
  @Input()
  public talentUuid = ':talentUuid';
  protected mediaUrl = environment.mediaUrl;

  // saved state
  protected commitedDocument: MediaObjectFragment | undefined = undefined;
  protected commitedTransformations: TransformOptions | undefined = undefined;

  // staged but not saved yet
  protected stagedDocument: MediaObjectFragment | undefined = undefined;
  protected stagedTranformations: TransformOptions | undefined = undefined;

  // Subject to hold upload headers with an initial empty object
  private uploadHeadersSubject = new BehaviorSubject<{ [key: string]: string }>(
    {},
  );
  uploadHeaders$ = this.uploadHeadersSubject.asObservable();

  constructor(private userService: UserService) {
    this.loadUploadHeaders();
  }

  protected avatarOptions: AvatarOptions = this.getAvatarOptions();

  // describe how the avatar should be rendered. Shows the currently saved state
  protected getAvatarOptions(): AvatarOptions {
    const thumb = this.commitedDocument?.sizes?.find(
      (size) => size.name === 'thumb',
    );
    const xl = this.commitedDocument?.sizes?.find(
      (size) => size.name === 'avatarXL',
    );
    if (thumb) {
      // if there is an image to preview
      return {
        content: 'image',
        imageUrl:
          environment.mediaUrl +
          (xl || thumb).src.replace(':talentUuid', this.talentUuid),
        icon: 'user',
        size: 'xl',
        shape: 'round',
        backgroundColor: 'white',
        borderColor: 'grey',
        avatarIndicator: false,
        tagType: 'tagAvatar',
      };
    }
    // if not show a fallback
    return {
      content: 'icon',
      icon: 'user',
      size: 'xl',
      shape: 'round',
      backgroundColor: 'white',
      borderColor: 'grey',
      avatarIndicator: false,
      tagType: 'tagAvatar',
    };
  }

  private async loadUploadHeaders() {
    const newToken = await this.userService.getAuthToken();
    this.uploadHeadersSubject.next({
      context: 'talentAvatar',
      cache: 'no-store',
      Authorization: `Bearer ${applyToken(newToken)}`,
    });
  }

  protected imageToCrop: ImageToCrop | undefined = undefined;

  // describe what the cropper should be cropping, Either the already commitedDocument or the newly uploaded one
  protected getImageToCrop(): ImageToCrop | undefined {
    // we generate and use a proxy image and never the original.
    const proxy = this.stagedDocument?.sizes?.find(
      (size) => size.name === 'proxy',
    );
    // if there is a staged document provide the proxy image
    if (proxy) {
      // we will calculate actual width and height of proxy by using the ration of the original document width and height
      // proxies are fitted "inside" using sharp, and will not grow larger than the original
      const origWidth = this.stagedDocument?.metadata.width || 2000;
      const origHeight = this.stagedDocument?.metadata.height || 2000;
      const proxyWidth = proxy.width || 2000;
      const proxyHeight = proxy.height || 2000;
      const downScaledWidth = Math.min(origWidth, proxyWidth);
      const downScaledHeight = Math.min(origHeight, proxyHeight);

      let realWidth, realHeight;
      if (origWidth > origHeight) {
        realWidth = downScaledWidth;
        realHeight = (downScaledWidth / origWidth) * origHeight;
      } else {
        realHeight = downScaledHeight;
        realWidth = (downScaledHeight / origHeight) * origWidth;
      }

      return {
        src:
          environment.mediaUrl +
          proxy.src.replace(':talentUuid', this.talentUuid),
        width: realWidth,
        height: realHeight,
      };
    }

    // if not the uploader will be shown by default
    return undefined;
  }

  // User clicked the remove button disabling the stagedDocument but he has not saved yet!
  protected remove() {
    this.stagedDocument = undefined;
    this.imageToCrop = undefined;
    this.stagedTranformations = undefined;
  }

  // new file uploaded but not saved yet
  protected uploadDone($event: MediaObjectFragment) {
    this.stagedDocument = $event;
    this.imageToCrop = this.getImageToCrop();
    this.stagedTranformations = undefined; // reset the transformations
  }

  // When the user changes the transformOptions, the stagedTranformations should be updated
  protected onTransformOptionsChange(
    transformOptions: TransformOptions | undefined,
  ) {
    this.stagedTranformations = transformOptions;
  }

  // When a user clicks the cancel button, the stagedDocument should be reset to the commitedDocument restoring the previous state
  protected cancel() {
    this.stagedDocument = this.commitedDocument;
    this.imageToCrop = this.getImageToCrop();
    this.stagedTranformations = this.commitedTransformations;
  }

  // here you should commit the document (and transformations) for saving
  protected save() {
    this.commitedDocument = this.stagedDocument;
    this.avatarOptions = this.getAvatarOptions();
    this.commitedTransformations = this.stagedTranformations;
    this.onChange({
      mediaObject: this.commitedDocument,
      transformOptions: this.commitedTransformations,
    });
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(value: any): void {
    if (value) {
      this.commitedDocument = value.mediaObject;
      this.avatarOptions = this.getAvatarOptions();
      this.stagedDocument = value.mediaObject;
      this.imageToCrop = this.getImageToCrop();
      this.commitedTransformations = value.transformOptions;
      this.stagedTranformations = value.transformOptions;
    } else {
      this.commitedDocument = undefined;
      this.avatarOptions = this.getAvatarOptions();
      this.stagedDocument = undefined;
      this.imageToCrop = undefined;
      this.commitedTransformations = undefined;
      this.stagedTranformations = undefined;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // console.error('disabled state not implemented in talent avatar');
  }
}
