import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import {
  RoutePermissionKeyEnum,
  UserService,
} from '../../models/shared/user/user.service';
import { firstValueFrom } from 'rxjs';
import { InitialVacanciesListStateLoaderService } from '../../state/vacancies-list/vacancies-list.reducer';
import { AuthService } from '@auth0/auth0-angular';
import { InitialTalentsListStateLoaderService } from '../../state/talents-list/talents-list.reducer';
import { InitialMatchesListStateLoaderService } from '../../state/matches-list/matches-list.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    public userService: UserService,
    public router: Router,
    public initialVacanciesStateLoader: InitialVacanciesListStateLoaderService,
    public initialTalentsListStateLoader: InitialTalentsListStateLoaderService,
    public initialMatchesListStateLoader: InitialMatchesListStateLoaderService,
    private authService: AuthService,
  ) {}

  async canMatch(
    route: Route,
    segments: UrlSegment[],
  ): Promise<boolean | undefined> {
    let redirectUrl = null;

    const link = this.router.getCurrentNavigation()?.extractedUrl.toString();
    if (link && link !== '/') {
      redirectUrl = encodeURIComponent(link);
    }
    const isAuthenticated = await firstValueFrom(
      this.authService.isAuthenticated$,
    );
    if (!isAuthenticated) {
      // if not logged in go to login
      this.router
        .navigate(['/login'], { queryParams: { redirectTo: redirectUrl } })
        .then();
      return;
    }
    const authUser = await firstValueFrom(this.userService.authUser$);
    if (authUser.isVerified) {
      const loadablePages = await this.userService.getLoadablePages();
      const routePermissionKey: RoutePermissionKeyEnum =
        route?.data?.routePermissionKey;
      if (routePermissionKey && loadablePages) {
        if (loadablePages[routePermissionKey]) {
          if (routePermissionKey === RoutePermissionKeyEnum.VACANCIES) {
            return this.initialVacanciesStateLoader.bootstrap();
          } else if (routePermissionKey === RoutePermissionKeyEnum.TALENTS) {
            return this.initialTalentsListStateLoader.bootstrap();
          } else if (routePermissionKey === RoutePermissionKeyEnum.MATCHES) {
            return this.initialMatchesListStateLoader.bootstrap();
          }
          return true;
        } else if (loadablePages[RoutePermissionKeyEnum.DASHBOARD]) {
          this.router.navigate(['dashboard']).then();
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    if (authUser && !authUser.isVerified) {
      this.router.navigate(['login']).then();
      return false;
    }
    console.log('this breaks the redirect');
    this.router
      .navigate(['/login'], { queryParams: { redirectTo: redirectUrl } })
      .then();
    return false;
  }
}
