import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  DocumentEventListenerService,
  ResponsiveService,
  setTimeout$,
  SheetService,
} from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { differenceInDays } from 'date-fns';
import {
  audit,
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  interval,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import {
  filter,
  first,
  map,
  shareReplay,
  startWith,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomerBranchListItemFragment,
  UniBaseXVacancyChannelConfigurationStatusEnum,
  UniBaseXVacancyChannelFragment,
  UniBaseXVacancyUpdateInput,
  UserSelfFragment,
  VacancyDetailFragment,
  VacancyEmploymentTypeEnum,
  VacancyPublishableFieldsEnum,
  VacancyStatusEnum,
  VacancyStatusTransitionEnum,
} from '../../../graphql/generated';
import { UserService } from '../../../models/shared/user/user.service';
import { MappedCustomerBranch } from '../../../models/types';
import { ChannelsService } from '../../../models/unibase/channels/channels.service';
import { CustomerBranchService } from '../../../models/unibase/customer-branch/customer-branch.service';
import { VacanciesService } from '../../../models/unibase/vacancies/vacancies.service';
import {
  SubmitVacancyUpdateInput,
  VacancyAdvertisementFormData,
  VacancyAllowedAction,
  VacancyCriteriaItemFormData,
  VacancyCustomerFormData,
  VacancyInternalFormData,
  VacancyResponsibilityFormData,
  VacancyTabEnum,
} from '../../../pages/vacancies/vacancy.types';
import { filterIsNotEmpty } from '../../helpers/functions/filterIsNotEmpty';
import { logCustomError } from '../../helpers/functions/logError';
import { randomId } from '../../helpers/functions/randomId';
import { getDisplayFieldNames } from '../../helpers/functions/vacancy/getDisplayFieldNames';
import { AnchorPoint } from '../anchor-navigation/anchor-navigation.component';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../global-sheets/global-sheets.service';
import { findInvalidControls } from './helpers/findInvalidControls';
import { VacancyDetailAdvertisementComponent } from './vacancy-detail-advertisement/vacancy-detail-advertisement.component';
import { VacancyDetailCriteriaComponent } from './vacancy-detail-criteria/vacancy-detail-criteria.component';
import { VacancyDetailCustomerFormComponent } from './vacancy-detail-customer-form/vacancy-detail-customer-form.component';
import { VacancyDetailInternComponent } from './vacancy-detail-intern/vacancy-detail-intern.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  AccordionComponent,
  ModalService,
  ToastService,
} from '@intemp/unijob-ui2';
import { getLastEditTime } from '../../helpers/functions/getLastEditTime';
import { sleep } from '@libs/shared/helpers/sleep';

@Component({
  selector: 'app-vacancy-detail',
  templateUrl: './vacancy-detail.component.html',
  styleUrls: ['./vacancy-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyDetailComponent implements AfterViewInit, OnDestroy {
  destroyed$ = new Subject<void>();
  protected configuredChannels: readonly UniBaseXVacancyChannelFragment[] = [];

  @Input() set vacancyId(value: string) {
    if (value) {
      this.vacancyId$.next(value);
    }
  }

  @Input() sheetId = 'vacancyEdit' + randomId();
  @Input() set action(value: string | undefined) {
    if (!value) {
      return;
    }
    // need to wait for vacancy to be loaded
    if (
      [
        'publishVacancy',
        'publishChanges',
        'duplicateVacancy',
        'unschedule',
      ].includes(value)
    ) {
      this.vacancy$
        .pipe(
          filter((value) => value !== null && value !== undefined),
          first(),
        )
        .subscribe(() => {
          // need to wait for validator
          setTimeout$(() => {
            this.handleAction(value);
          }, 0);
        });
    } else {
      // need to wait for sheet order
      setTimeout$(() => {
        this.handleAction(value);
      }, 100);
    }
  }

  $inlinePreviewEnabled =
    this.debouncedResizeEmitter.debouncedResizeEmitter.pipe(
      startWith(window.innerWidth),
      map((width) => width > 1316),
      tap((enabled) => {
        if (!enabled && this.showPreviewVacancy) {
          this.hidePreviewAction();
        }
      }),
      shareReplay(1),
    );

  @Output() sheetClosed = new EventEmitter<string>();

  @ViewChild(VacancyDetailCustomerFormComponent)
  customerFormComponent?: VacancyDetailCustomerFormComponent;

  @ViewChild(VacancyDetailAdvertisementComponent)
  advertisementComponent?: VacancyDetailAdvertisementComponent;

  @ViewChild(VacancyDetailCriteriaComponent)
  criteriaComponent?: VacancyDetailCriteriaComponent;

  @ViewChild(VacancyDetailInternComponent)
  internComponent?: VacancyDetailInternComponent;

  vacancy?: VacancyDetailFragment;
  vacancyStatusEnum = VacancyStatusEnum;

  vacancyId$ = new BehaviorSubject<string>('');
  // TODO: Fix new subscriptions to this cause "subscribeVacancy$" to be re-initiated currently
  // Changes in the form currently cause a re-subscribe to the vacancy
  vacancy$ = this.vacancyId$.pipe(
    switchMap((vacancyId) => {
      return this.vacanciesService.subscribeVacancy$(vacancyId);
    }),
    shareReplay(1),
  );

  vacancyPreviewUrl$ = this.vacancy$.pipe(
    map((v) => v),
    filter(filterIsNotEmpty),
    map((vacancy) => {
      if (!vacancy.uniBaseX)
        throw new Error('missing uniBaseX property for vacancy preview');
      return this.vacanciesService.getVacancyPreviewUrl(
        vacancy.uuid,
        vacancy.uniBaseX.previewSecret,
      );
    }),
    distinctUntilChanged(),
  );

  vacancyPreviewSafeUrl$ = this.vacancyPreviewUrl$.pipe(
    map((url) => this.domSanitizer.bypassSecurityTrustResourceUrl(url)),
  );

  modals: ModalService;
  isLoading = false;
  customerBranches: MappedCustomerBranch[] = [];
  lastEditTime?: string;
  lastEditTimeMobile?: string;
  lastEditTimeDebounce: any = setTimeout(() => {});
  tabEnum = VacancyTabEnum;
  activeTab: VacancyTabEnum = VacancyTabEnum.VACANCY;
  onScrollEventName = 'vacancyDetailScroll';

  vacancyForm = new FormGroup({});

  // base data is not in a category / accordion
  baseFormData?: VacancyResponsibilityFormData;
  customerFormData?: VacancyCustomerFormData;
  advertisementFormData?: VacancyAdvertisementFormData;
  criteriaFormData?: VacancyCriteriaItemFormData;
  internalFormData?: VacancyInternalFormData;

  publishVacancySheetId = 'publishVacancy' + randomId();
  archiveVacancySheetId = 'archiveVacancy' + randomId();

  invalidFields: string[] = [];
  primaryAction: VacancyAllowedAction | undefined = undefined;
  primaryActions: VacancyAllowedAction[] = [];
  secondaryActions: VacancyAllowedAction[] = [];
  sheetActions: VacancyAllowedAction[] = [];
  hasActionsSeperator = false;

  changesCustomerFormData: Partial<
    Record<VacancyPublishableFieldsEnum, boolean>
  > = {};
  changesAdvertisementFormData: Partial<
    Record<VacancyPublishableFieldsEnum, boolean>
  > = {};
  changesCriteriaFormData: Partial<
    Record<VacancyPublishableFieldsEnum, boolean>
  > = {};
  changesInternalFormData: Partial<
    Record<VacancyPublishableFieldsEnum, boolean>
  > = {};
  allUnpublishedChanges: Partial<
    Record<VacancyPublishableFieldsEnum, boolean>
  > = {};

  formsAreDisabled = false;

  showPreviewVacancy = false;

  isLoadingPreview = false;

  responsibleEmployeeBranch?: CustomerBranchListItemFragment;

  loggedInUserBranchesNames: string[] = [];
  loggedInUser?: UserSelfFragment;

  publishedSinceDays = 0;

  deleteConfirmationModalId = 'deleteConfirmation' + randomId();

  isSaving: WritableSignal<boolean> = signal(false);
  isSavingIndicator: Signal<boolean> = toSignal(
    toObservable(this.isSaving).pipe(
      distinctUntilChanged(),
      // Show the saving indicator for a minimal amount (interval), but do not delay showing the saving indicator
      audit((isSaving) => (isSaving ? of(undefined) : interval(400))),
    ),
    {
      initialValue: false,
    },
  );

  protected readonly VacancyStatusEnum = VacancyStatusEnum;

  isSmDown$ = this.responsiveService.isSmDown$;

  @ViewChildren(AccordionComponent) accordions?: AccordionComponent[];

  private lastAutosaveInput?: SubmitVacancyUpdateInput;

  constructor(
    private vacanciesService: VacanciesService,
    private channelsService: ChannelsService,
    private toastService: ToastService,
    public sheets: SheetService,
    private modalService: ModalService,
    public userService: UserService,
    private i18nPipe: I18NextPipe,
    public sheetService: SheetService,
    private globalSheetsService: GlobalSheetsService,
    private customerBranchService: CustomerBranchService,
    private responsiveService: ResponsiveService,
    private debouncedResizeEmitter: DocumentEventListenerService,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: any,
    private clipboard: Clipboard,
    private cd: ChangeDetectorRef,
  ) {
    this.modals = modalService;
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.vacancyId$.value) {
      logCustomError('missing VacancyId on vacancy-edit sheet');
    }
    setTimeout$(() => {
      this.openSheet().then();
    });
    this.vacancy$.pipe().subscribe(() => this.isSaving.update(() => false));
  }

  handleAction(action: string): void {
    switch (action) {
      case 'publishVacancy':
        this.openActionSheet();
        break;
      case 'publishChanges':
        this.openActionSheet();
        break;
      case 'duplicateVacancy':
        this.duplicateVacancy();
        break;
      case 'preview':
        this.openPreview();
        break;
      case 'hide-preview':
        this.closePreview();
        break;
      case 'delete':
        this.modalService.open(this.deleteConfirmationModalId);
        break;
      case 'endVacancy':
        this.sheetService.open(this.archiveVacancySheetId);
        break;
      case 'restore':
        this.restoreVacancy();
        break;
      case 'unschedule':
        this.unscheduleVacancy();
        break;
      case 'promote':
        this.activeTab = VacancyTabEnum.PROMOTE;
        this.triggerAnchorScroll();
        break;
      case 'stats':
        this.activeTab = VacancyTabEnum.STATS;
        this.triggerAnchorScroll();
        break;
      default:
        break;
    }
  }

  openChangelogSheet(): void {
    if (this.vacancyId$.value) {
      this.globalSheetsService.openGlobalSheet({
        type: GlobalSheetTypeEnum.VACANCIES_CHANGELOG,
        uuid: this.vacancyId$.value,
      });
    } else {
      console.error('vacancyId not provided');
    }
  }

  subscribeBranches() {
    this.customerBranchService.customerBranches$
      .pipe(
        combineLatestWith(this.vacancy$),
        map(([branches, vacancy]) =>
          branches.find(
            (branch) => branch.uuid === vacancy?.uniBaseX?.customerBranch?.uuid,
          ),
        ),
      )
      .subscribe((branch) => {
        this.responsibleEmployeeBranch = branch;
      });
    this.userService.readyUser$
      .pipe(
        combineLatestWith(this.customerBranchService.customerBranches$),
        map(([user, branches]) =>
          branches
            .filter((branch) => user.customerBranches?.includes(branch.uuid))
            .map((branch) => branch.name),
        ),
      )
      .subscribe((branches) => {
        this.loggedInUserBranchesNames = branches;
      });
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
    this.isLoading = true;
    await this.loadVacancy(true);
  }

  async loadVacancy(initialLoad = false) {
    try {
      await this.subscribeVacancy(initialLoad);
      this.subscribeBranches();
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      });
    }
  }

  async subscribeVacancy(initialLoad = false) {
    combineLatest([
      this.vacancy$.pipe(filter(filterIsNotEmpty)),
      this.userService.readyUser$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async ([vacancy, currentUser]) => {
        this.vacancy = vacancy;
        this.loggedInUser = currentUser;
        this.disableForms();
        this.updateLastEditTime();
        this.setPublishedSinceDays();
        this.subscribeBranches();
        this.formsAreDisabled = !vacancy.permissions?.canEdit;
        if (initialLoad) {
          await this.setVacancy();
          initialLoad = false;
        } else {
          this.patchForm(vacancy);
        }
        if (
          [VacancyStatusEnum.LIVE, VacancyStatusEnum.SCHEDULED].includes(
            this.vacancy.status,
          )
        ) {
          if (
            vacancy.uniBaseX?.unpublishedChanges &&
            vacancy.uniBaseX?.unpublishedChanges?.length > 0
          ) {
            this.setLastEditTime();
          }
        }
        this.setAllowedActions();
        this.cd.markForCheck();
      });
  }

  setPublishedSinceDays() {
    const publishedAt = this.vacancy?.uniBaseX?.currentPublication?.publishedAt;
    if (publishedAt) {
      this.publishedSinceDays = differenceInDays(
        new Date(),
        new Date(publishedAt),
      );
    }
  }

  disableForms() {
    this.formsAreDisabled = this.vacancy?.status === VacancyStatusEnum.ARCHIVED;
  }

  async setVacancy(): Promise<void> {
    await this.getCustomerBranches();
    this.prepareFormData();
  }

  patchForm(vacancy: VacancyDetailFragment) {
    this.vacancy = vacancy;
    this.prepareFormData();
  }

  prepareFormData() {
    if (!this.vacancy) {
      return;
    }
    this.baseFormData = {
      vacancyUuid: this.vacancy.uuid,
      branchId: this.vacancy.uniBaseX?.customerBranch.uuid,
      consultant: this.vacancy.uniBaseX?.responsibleUser || undefined,
    };
    this.customerFormData = {
      publishExactJobLocation:
        this.vacancy.uniBaseX?.publishExactJobLocation || undefined,
      street: this.vacancy.uniBaseX?.jobLocationAddress?.street ?? '',
      zip: this.vacancy.uniBaseX?.jobLocationAddress?.zip ?? '',
      location: this.vacancy.uniBaseX?.jobLocationAddress?.location ?? '',
      jobLocationAddressReplacement:
        this.vacancy.uniBaseX?.jobLocationAddressReplacement || undefined,
      companyLocation: this.vacancy.uniBaseX?.companyLocation
        ? {
            companyName:
              this.vacancy.uniBaseX.companyLocation.companyReference.legalName,
            address: this.vacancy.uniBaseX.companyLocation.address || {},
            contacts: [],
            uuid: this.vacancy.uniBaseX.companyLocation.uuid,
          }
        : undefined,
    };

    if (this.vacancy.uniBaseX?.companyContact?.uuid) {
      this.customerFormData.companyContact = {
        ...this.vacancy.uniBaseX?.companyContact,
      };
    }

    this.advertisementFormData = {
      title: this.vacancy.uniBaseX?.title || undefined,
      functionText: this.vacancy.uniBaseX?.functionText ?? null,
      requirementsText: this.vacancy.uniBaseX?.requirementsText ?? null,
      benefitsText: this.vacancy.uniBaseX?.benefitsText ?? null,
      employerDescriptionText:
        this.vacancy.uniBaseX?.employerDescriptionText ?? null,
    };

    this.criteriaFormData = {
      workloadFlexible: this.vacancy.uniBaseX?.workloadFlexible ?? false,
      employmentType:
        this.vacancy.uniBaseX?.employmentType ??
        VacancyEmploymentTypeEnum.PERMANENT,
      fixedTerm: this.vacancy.uniBaseX?.fixedTerm ?? false,
      workloadPercentageMin:
        this.vacancy.uniBaseX?.workloadPercentageMin || undefined,
      workloadPercentageMax:
        this.vacancy.uniBaseX?.workloadPercentageMax || undefined,
      workloadPercentage:
        this.vacancy.uniBaseX?.workloadPercentage || undefined,
      positionType: this.vacancy.uniBaseX?.positionType || undefined,
      salaryFlexible: this.vacancy.uniBaseX?.salaryFlexible || undefined,
      salaryUnit: this.vacancy.uniBaseX?.salaryUnit || undefined,
      salaryMin: this.vacancy.uniBaseX?.salaryMin || undefined,
      salaryMax: this.vacancy.uniBaseX?.salaryMax || undefined,
      salary: this.vacancy.uniBaseX?.salary || undefined,
      salaryHoursPerWeek:
        this.vacancy.uniBaseX?.salaryHoursPerWeek || undefined,
      salaryCurrency: this.vacancy.uniBaseX?.salaryCurrency || undefined,
      salaryPaymentsPerYear:
        this.vacancy.uniBaseX?.salaryPaymentsPerYear || undefined,
      publishSalary: this.vacancy.uniBaseX?.publishSalary || undefined,
      languageRequirements:
        this.vacancy.uniBaseX?.languageRequirements || undefined,
    };

    if (this.vacancy.uniBaseX?.employmentStart) {
      this.criteriaFormData.employmentStart = new Date(
        this.vacancy.uniBaseX.employmentStart,
      );
    } else {
      this.criteriaFormData.employmentStart = undefined;
    }

    if (this.vacancy.uniBaseX?.employmentEnd) {
      this.criteriaFormData.employmentEnd = new Date(
        this.vacancy.uniBaseX.employmentEnd,
      );
    } else {
      this.criteriaFormData.employmentEnd = undefined;
    }

    this.internalFormData = {
      originalUrl: this.vacancy.uniBaseX?.originalUrl || undefined,
      agePreferenceMin: this.vacancy.uniBaseX?.agePreferenceMin || undefined,
      agePreferenceMax: this.vacancy.uniBaseX?.agePreferenceMax || undefined,
      notes: this.vacancy.uniBaseX?.notes ?? null,
      archiveReason: this.vacancy.uniBaseX?.archiveReason || undefined,
    };
  }

  async getCustomerBranches(): Promise<void> {
    this.customerBranches = [];
    if (
      this.vacancy?.uniBaseX &&
      !this.customerBranches.some(
        (customerBranch) =>
          customerBranch.uuid === this.vacancy?.uniBaseX?.customerBranch?.uuid,
      )
    ) {
      this.customerBranches.push({
        uuid: this.vacancy.uniBaseX.customerBranch.uuid,
        name: this.vacancy.uniBaseX.customerBranch.name,
      });
    }
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
    delete this.vacancy;
  }

  updateLastEditTime(): void {
    this.setLastEditTime();
    clearTimeout(this.lastEditTimeDebounce);
    // interval to update the wording of" updated a few seconds ago" to "updated one minute ago" etc
    this.lastEditTimeDebounce = setTimeout(() => {
      this.setLastEditTime();
      this.updateLastEditTime();
    }, 15000);
  }

  setLastEditTime(): void {
    if (!this.vacancy?.uniBaseX?.updatedAtForUser) {
      return;
    }
    const lastUpdatedAt = getLastEditTime(
      this.vacancy?.uniBaseX?.updatedAtForUser,
    );

    this.lastEditTime = lastUpdatedAt.lastEditTime;
    this.lastEditTimeMobile = lastUpdatedAt.lastEditTimeMobile;
  }

  triggerAnchorScroll() {
    window.dispatchEvent(new CustomEvent(this.onScrollEventName));
  }

  clearSheetActionParam() {
    if (!this.vacancyId$.value) {
      return;
    }
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.VACANCY_EDIT,
        uuid: this.vacancyId$.value,
      },
      'action',
      null,
    );
  }

  autoSave(input: SubmitVacancyUpdateInput) {
    this.isSaving.update(() => true);
    if (!this.vacancy) {
      console.error('Missing Vacancy to save');
      return;
    }
    if (JSON.stringify(input) === JSON.stringify(this.lastAutosaveInput)) {
      return;
    }
    this.lastAutosaveInput = input;
    const inputData: UniBaseXVacancyUpdateInput = {
      ...input,
      vacancyUuid: this.vacancy.uuid,
    };

    this.updateVacancy(inputData);
  }

  updateVacancy(input: UniBaseXVacancyUpdateInput) {
    const savingId = `savingToast${randomId()}`;
    if (input.transition?.length) {
      this.toastService.makeToast({
        type: 'INFO',
        message: this.i18nPipe.transform('saving'),
        id: savingId,
        duration: 10000,
      });
    }
    this.vacanciesService.queueVacancyUpdate(input).subscribe({
      next: (res) => {
        if (input.transition?.length && res.data) {
          this.createToastBasedOnTransitionEnum(input.transition);
        }
        this.toastService.removeToastByIdGently(savingId);
      },
      error: () => {
        this.toastService.removeToastByIdGently(savingId);
      },
    });
  }

  createToastBasedOnTransitionEnum(transition: VacancyStatusTransitionEnum) {
    switch (transition) {
      case VacancyStatusTransitionEnum.PUBLISH:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('vacancyPublishedSuccessfully'),
          duration: 6000,
        });
        break;
      case VacancyStatusTransitionEnum.RESTORE:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('restoredSuccessfully'),
          duration: 6000,
        });
        break;
      case VacancyStatusTransitionEnum.CORRECT_OR_REPUBLISH:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('changesPublished'),
          duration: 6000,
        });
        break;
      case VacancyStatusTransitionEnum.ARCHIVE:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('vacancyArchived'),
          duration: 6000,
        });
        break;
      case VacancyStatusTransitionEnum.DELETE:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('vacancyDeleted'),
          duration: 6000,
        });
        break;
      default:
        break;
    }
  }

  async validateVacancyForms() {
    this.customerFormComponent?.enablePublishedValidation();
    this.customerFormComponent?.validateForm();
    this.advertisementComponent?.enablePublishedValidation();
    this.advertisementComponent?.validateForm();
    this.criteriaComponent?.enablePublishedValidation();
    this.criteriaComponent?.validateForm();

    // async validations
    //await this.customerFormComponent?.validateFormAsync(); //TODO: temporarily disabled, waiting for complete data source

    const invalidControls = findInvalidControls(this.vacancyForm);
    this.invalidFields = [];
    if (invalidControls.length > 0) {
      this.invalidFields = getDisplayFieldNames(invalidControls);
    }
    this.cd.markForCheck();
  }

  async openActionSheet() {
    await this.validateVacancyForms();
    if (
      this.vacancy?.status === VacancyStatusEnum.LIVE ||
      this.vacancy?.status === VacancyStatusEnum.SCHEDULED
    ) {
      if (
        !this.vacancy.uniBaseX?.unpublishedChanges ||
        this.vacancy.uniBaseX?.unpublishedChanges?.length === 0
      ) {
        this.toastService.makeToast({
          type: 'INFO',
          message: this.i18nPipe.transform('noChangesFound'),
          duration: 6000,
        });
        return;
      }
    }
    this.sheetService.open(this.publishVacancySheetId);
  }

  deleteVacancy() {
    this.modalService.close(this.deleteConfirmationModalId);
    this.autoSave({
      transition: VacancyStatusTransitionEnum.DELETE,
    });
    this.sheetService.close(this.sheetId);
    this.clearSheetActionParam();
  }

  restoreVacancy() {
    this.autoSave({
      transition: VacancyStatusTransitionEnum.RESTORE,
    });
    this.clearSheetActionParam();
  }

  unscheduleVacancy() {
    this.autoSave({
      transition: VacancyStatusTransitionEnum.UNSCHEDULE,
    });
    this.clearSheetActionParam();
  }

  duplicateVacancy() {
    if (!this.vacancy?.uuid) {
      console.error('Missing Vacancy-ID to duplicate');
      return;
    }

    const customerBranchUuid = this.vacancy.uniBaseX?.customerBranch.uuid;
    const responsibleUserId = this.vacancy.uniBaseX?.responsibleUser.userId;
    if (!customerBranchUuid || !responsibleUserId) {
      console.error('Missing customerBranchUuid or responsibleUserId');
      return;
    }

    this.vacanciesService
      .createVacancy$({
        copyFromVacancyUuid: this.vacancy.uuid,
        customerBranchUuid,
        responsibleUserId,
      })
      .subscribe(async (vacancy) => {
        this.globalSheetsService.openGlobalSheet({
          type: GlobalSheetTypeEnum.VACANCY_EDIT,
          uuid: vacancy.uuid,
        });
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('vacancyDuplicatedSuccessfully'),
          duration: 6000,
        });
      });
    this.clearSheetActionParam();
  }

  hidePreviewAction = () => {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.VACANCY_EDIT,
        uuid: this.vacancyId$.value || '',
      },
      'action',
      'hide-preview',
    );
  };

  showPreviewAction = () => {
    this.$inlinePreviewEnabled.pipe(take(1)).subscribe((enabled) => {
      if (enabled) {
        this.globalSheetsService.updateParam(
          {
            type: GlobalSheetTypeEnum.VACANCY_EDIT,
            uuid: this.vacancyId$.value || '',
          },
          'action',
          'preview',
        );
      } else {
        this.openPreviewUrl();
      }
    });
  };

  overrideAction(action: VacancyAllowedAction): VacancyAllowedAction {
    if (action.label === 'preview' || action.label === 'hide-preview') {
      return {
        ...action,
        label: this.showPreviewVacancy ? 'hide-preview' : 'preview',
        theme: this.showPreviewVacancy ? 'primary' : 'secondary',
        action: this.showPreviewVacancy
          ? this.hidePreviewAction
          : this.showPreviewAction,
      };
    }
    return {
      ...action,
      action: () => {
        action.inVacancyDetail
          ? this.globalSheetsService.updateParam(
              {
                type: GlobalSheetTypeEnum.VACANCY_EDIT,
                uuid: this.vacancyId$.value || '',
              },
              'action',
              action.label,
            )
          : action?.action();
      },
    };
  }

  setAllowedActions() {
    if (!this.vacancy) return;

    const vacancyId = this.vacancyId$.value;
    if (!vacancyId) {
      console.error('Missing Vacancy-ID to set allowed actions');
      return;
    }

    const { primaryActions, secondaryActions, sheetActions } =
      this.vacanciesService.getVacancyActionOptions(
        this.vacancy,
        this.loggedInUser,
      );

    this.primaryAction = primaryActions?.[0]
      ? this.overrideAction(primaryActions?.[0])
      : undefined;

    this.primaryActions = primaryActions.slice(1).map((action) => {
      return this.overrideAction(action);
    });
    this.secondaryActions = secondaryActions.map((action) => {
      return this.overrideAction(action);
    });

    this.sheetActions = sheetActions.map((action) => {
      return this.overrideAction(action);
    });

    if (this.primaryActions.length > 0 && this.secondaryActions.length > 0) {
      this.hasActionsSeperator = true;
    } else {
      this.hasActionsSeperator = false;
    }

    // special disabled reasons for endVacancy
    this.primaryActions = this.primaryActions.map((action) => {
      if (action.label === 'endVacancy') {
        return {
          ...action,
          disabledReason$: this.setDisabledReasonForEndVacancy(),
        };
      }
      return action;
    });
    if (this.primaryAction?.label === 'endVacancy') {
      this.primaryAction.disabledReason$ =
        this.setDisabledReasonForEndVacancy();
    }
  }

  private setDisabledReasonForEndVacancy() {
    return this.channelsService.channelsSubscription$.pipe(
      map((availableChannels) =>
        availableChannels.items
          ?.filter((availableChannel) => availableChannel.preventsUnpublish)
          .some(
            (availableChannel) =>
              this.configuredChannels.filter(
                (configuredChannel) =>
                  configuredChannel.uuid === availableChannel.uuid &&
                  configuredChannel.status ===
                    UniBaseXVacancyChannelConfigurationStatusEnum.RUNNING,
              ).length != 0,
          )
          ? 'vacancyHasBlockingPromotion'
          : undefined,
      ),
    );
  }

  openAccordionIfCollapsed = async (anchor: AnchorPoint) => {
    console.log('openAccordionIfCollapsed');
    const accordion = this.accordions?.find(
      (a: AccordionComponent) => a.label === anchor.name,
    );
    if (accordion && !accordion._open) {
      accordion._open = true;
      await sleep(150);
    }
  };

  openPreview() {
    this.showPreviewVacancy = true;
    this.setAllowedActions();
    this.startLoadingPreview();
    this.cd.markForCheck();
  }

  closePreview() {
    this.showPreviewVacancy = false;
    this.setAllowedActions();
    this.cd.markForCheck();
  }

  startLoadingPreview() {
    // this must be done in the next render after the preview is shown
    // because the iframe always emits a loaded event before it even gets the src should load
    setTimeout$(() => {
      this.isLoadingPreview = true;
    });
  }

  iframeLoadingCompleted() {
    // adds a bit of loadingSpinner time because it takes a bit until angular is actually ready
    setTimeout$(() => {
      this.isLoadingPreview = false;
      this.cd.markForCheck();
    }, 200);
    // this.isLoadingPreview = false;
  }

  openPreviewUrl() {
    this.vacancyPreviewUrl$.pipe(take(1)).subscribe((url) => {
      window.open(url, '_blank');
    });
  }

  showLinkCopied = false;
  copyLink() {
    this.showLinkCopied = true;
    setTimeout$(() => {
      this.showLinkCopied = false;
      this.cd.markForCheck();
    }, 1200);
    this.vacancyPreviewUrl$.pipe(take(1)).subscribe((url) => {
      this.clipboard.copy(url);
    });
  }

  switchActiveTabTo(tab: VacancyTabEnum) {
    this.activeTab = tab;
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.VACANCY_EDIT,
        uuid: this.vacancyId$.value,
      },
      'action',
      tab.toLowerCase(),
    );
  }

  ngOnDestroy() {
    clearTimeout(this.lastEditTimeDebounce);
    this.vacancyId$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected getResponsibleUserInfoMessage(
    vacancy: VacancyDetailFragment | undefined,
  ) {
    if (!vacancy) {
      return;
    }
    if (
      this.formsAreDisabled ||
      vacancy.status === VacancyStatusEnum.ARCHIVED ||
      vacancy.status === VacancyStatusEnum.DELETED
    ) {
      return;
    }
    const prioBoostActive = vacancy.uniBaseX?.configuredChannels?.some(
      (config) =>
        config.exportSettings?.prioBoost?.enabled &&
        ['RUNNING', 'PENDING'].includes(config.status),
    );
    if (prioBoostActive) {
      return this.i18nPipe.transform(
        'warningChannelsWithMaxRunningAndPendingSlotsPerUserWillBeDeactivated',
      );
    }
  }
}
