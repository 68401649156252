import { inject, Injectable } from '@angular/core';
import { TalentFormEditService } from './talentFormEdit.service';
import { ArrayActionEnum } from '../../../../graphql/generated';
import { ToastService } from '@intemp/unijob-ui2';
import { I18NextPipe } from 'angular-i18next';

@Injectable()
export class TalentSharedService {
  formService = inject(TalentFormEditService);
  toastService = inject(ToastService);
  i18next = inject(I18NextPipe);

  createToast(arrayActionEnum: ArrayActionEnum) {
    switch (arrayActionEnum) {
      case ArrayActionEnum.REMOVED:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18next.transform('successfullyRemoved'),
          duration: 6000,
        });
        return;
    }
  }
}
