<div
  [formGroup]="jobLocationAddress"
  class="uj-flex uj-flex-col uj-gap-f">
  <div [formGroup]="jobCompanyAndContactFormGroup">
    <ui-library-form-field
      [label]="'companies' | i18next | firstUppercase"
      [labelFor]="'companyLocation'"
      required
      fullWidth>
      <app-vacancy-company-location-select
        id="companyLocation"
        formControlName="companyLocation"
        [hasError]="
          jobCompanyAndContactFormGroup.controls.companyLocation.invalid
        "
        [attr.data-cy]="
          'company-location-select-' +
          (jobCompanyAndContactFormGroup.controls.companyLocation?.value
            ?.uuid || '')
        ">
      </app-vacancy-company-location-select>
    </ui-library-form-field>
  </div>
  <div [formGroup]="jobCompanyAndContactFormGroup">
    <ui-library-form-field
      [label]="'responsibleContact' | i18next | firstUppercase"
      [labelFor]="'companyContact'"
      required
      fullWidth>
      <app-vacancy-company-contact-select
        [hasError]="
          jobCompanyAndContactFormGroup.controls.companyContact.invalid
        "
        [companyLocationUuid]="
          jobCompanyAndContactFormGroup.controls.companyLocation?.value?.uuid
        "
        id="companyContact"
        formControlName="companyContact"
        [attr.data-cy]="
          'company-contact-select-' +
          (jobCompanyAndContactFormGroup.controls.companyContact?.value?.uuid ||
            '')
        ">
      </app-vacancy-company-contact-select>
    </ui-library-form-field>
  </div>
  <ui-library-form-field
    [label]="'exactWorkPlace' | i18next | firstUppercase"
    [dot]="hasChanges(VacancyPublishableFieldsEnum.jobLocationAddress)"
    required
    fullWidth>
    <div class="uj-flex uj-flex-col uj-gap-c">
      <input
        ui-library-text-input
        focusTracker="street"
        formControlName="street"
        [placeholder]="'streetPlusNumber' | i18next"
        type="text" />
      <div class="uj-flex uj-gap-c">
        <input
          ui-library-text-input
          class="uj-max-w-[142px]"
          focusTracker="zip"
          formControlName="zip"
          [placeholder]="'zip' | i18next"
          [feedback]="zipControl.errors ? 'invalid' : 'none'"
          type="text" />
        <input
          ui-library-text-input
          class="uj-flex-1"
          focusTracker="location"
          formControlName="location"
          [feedback]="locationControl.errors ? 'invalid' : 'none'"
          [placeholder]="'city' | i18next"
          type="text" />
      </div>
      <strong
        *ngIf="zipControl.value && zipControl.errors"
        class="uj-text-ui-negative uj-flex uj-items-center">
        <unijob-icon
          icon="alert_triangle"
          size="12">
        </unijob-icon>
        <span class="text-s uj-ml-b">{{ "zipCodeIsInvalid" | i18next }}</span>
      </strong>
      <div [formGroup]="jobLocationAlternateSpecificityFormGroup">
        <unijob-switch
          focusTracker="publishExactJobLocation"
          formControlName="publishExactJobLocation"
          [label]="'publishExactJobLocationInsteadOfReplacement' | i18next"
          round></unijob-switch>

        <ui-library-form-field
          *ngIf="
            !publishExactJobLocationControl ||
            !publishExactJobLocationControl?.value
          "
          [label]="'workRegion' | i18next | firstUppercase"
          labelFor="jobLocationAddressReplacement"
          [dot]="
            hasChanges(VacancyPublishableFieldsEnum.publishExactJobLocation)
          "
          required
          fullWidth
          class="uj-mt-e">
          <input
            ui-library-text-input
            id="jobLocationAddressReplacement"
            focusTracker="jobLocationAddressReplacement"
            formControlName="jobLocationAddressReplacement"
            [placeholder]="'regionOrAproximateLocation' | i18next"
            [feedback]="
              jobLocationAddressReplacementControl.errors ? 'invalid' : 'none'
            "
            data-cy="vacancy-region-input"
            type="text" />
        </ui-library-form-field>
      </div>
    </div>
  </ui-library-form-field>
</div>
