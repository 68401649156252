import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';
import { Boolean, ReadonlyArray } from 'effect';
import { findMismatches } from '../../helpers/functions/findMismatches';
import { patchFormControl } from '../../helpers/functions/patchFormControl';
import { filter, map, shareReplay } from 'rxjs/operators';
import {
  SubmitVacancyUpdateInput,
  VacancyResponsibilityFormData,
} from '../../../pages/vacancies/vacancy.types';
import { CustomerBranchService } from '../../../models/unibase/customer-branch/customer-branch.service';
import { ConsultantSelectComponent } from '../consultant-select/consultant-select.component';
import { UserService } from '../../../models/shared/user/user.service';
import { customerBranchToSelectOption2 } from '../../helpers/functions/customerBranch/customerBranchToSelectOption';
import { VacancyResponsibilityForm } from './vacancy-responsibility-form';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { Writable } from 'type-fest';
import { TextOption } from '@intemp/unijob-ui2';
import { setFormControlEnabledState } from '../../helpers/functions/setFormControlEnabledState';
import { ConsultantService } from '../../../core/services/consultant.service';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-vacancy-responsibility',
  templateUrl: './vacancy-responsibility.component.html',
  styleUrls: ['./vacancy-responsibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyResponsibilityComponent
  implements OnInit, OnChanges, OnDestroy
{
  destroyed$ = new Subject<void>();

  private _formIsDisabled = new ReplaySubject<boolean>(1);

  responsibilityData = input.required<VacancyResponsibilityFormData>();

  formRef = input<FormGroup>();
  isNewVacancy = input<boolean>(false);

  formIsDisabled = input<boolean>(false);
  formIsDisabledWritable = signal(this.formIsDisabled());

  formIsDisabled$ = toObservable(this.formIsDisabledWritable);

  responsibleUserInfoMessage = input<string>();

  loggedInUser = this.userService.user$?.value;

  consultantFromVacancy$ = toObservable(this.responsibilityData).pipe(
    map((data) => data.consultant),
  );

  consultantIdControl = new FormControl<string | null>(null);
  branchIdControl = new FormControl<string | null>(null);

  formGroup: VacancyResponsibilityForm = new FormGroup({
    consultantId: this.consultantIdControl,
    branchId: this.branchIdControl,
  });

  setBaseDataEffect = effect(() => {
    this.formRef()?.setControl('baseData', this.formGroup);
  });
  updateBranchesEffect = effect(() => {
    if (this.isNewVacancy()) {
      if (this.loggedInUser) {
        this.programmaticUpdate.next(true);
        this.consultantIdControl.patchValue(this.loggedInUser._id, {
          emitEvent: false,
        });
        const firstBranch =
          this.loggedInUser.organisationUnit?.branches?.[0]?.uuid;
        if (firstBranch) {
          setTimeout(() => {
            // setTimeout is ExpressionChangedAfterItHasBeenCheckedError workaround
            this.branchIdControl.patchValue(firstBranch, {
              emitEvent: false,
            });
          });
        }
        this.programmaticUpdate.next(false);
      }
    }
  });

  @ViewChild('consultantSelect') consultantSelect?: ConsultantSelectComponent;

  @Output() triggerAutoSave = new EventEmitter<SubmitVacancyUpdateInput>();
  programmaticUpdate = new BehaviorSubject<boolean>(false);

  allConsultants$ = combineLatest([
    this.consultantFromVacancy$,
    this.formIsDisabled$,
  ]).pipe(
    switchMap(([consultantFromVacancy, isDisabled]) =>
      (isDisabled
        ? this.consultantService.consultantUsers$
        : this.consultantService.consultantsICanAssign$
      ).pipe(
        map((users) =>
          users.map((user) =>
            user._id === consultantFromVacancy?.userId
              ? {
                  ...user,
                  profile: { ...user.profile, ...consultantFromVacancy },
                }
              : user,
          ),
        ),
      ),
    ),
  );

  branchOptions$: Observable<TextOption[]> = this.formIsDisabled$.pipe(
    switchMap((isDisabled) =>
      isDisabled
        ? this.customerBranchService.customerBranches$
        : this.customerBranchService.branchesICanAssign$,
    ),
    map((branches) => branches.map(customerBranchToSelectOption2)),
    shareReplay(1),
  );

  constructor(
    private customerBranchService: CustomerBranchService,
    public userService: UserService,
    private consultantService: ConsultantService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.allConsultants$.pipe(map((consultants) => consultants.length === 1)),
      this.formIsDisabled$,
    ])
      .pipe(map(Boolean.some), map(Boolean.not))
      .subscribe(
        setFormControlEnabledState(this.formGroup.controls.consultantId),
      );
    combineLatest([
      this.branchOptions$.pipe(map((consultants) => consultants.length === 1)),
      this.formIsDisabled$,
    ])
      .pipe(map(Boolean.some), map(Boolean.not))
      .subscribe(setFormControlEnabledState(this.formGroup.controls.branchId));
    this.consultantIdControl.setValue(
      this.responsibilityData().consultant?.userId || null,
    );
    this.branchIdControl.setValue(this.responsibilityData().branchId || null);
    this.formGroup.valueChanges
      .pipe(filter(() => !this.programmaticUpdate.value))
      .subscribe((controls) => {
        getObjectKeys(controls).forEach((key) => {
          const control = this.formGroup.controls[key];
          if (control.dirty) {
            const submitDataInput: Writable<SubmitVacancyUpdateInput> = {};
            if (key === 'branchId') {
              submitDataInput.customerBranchUuid = control.value;
            }
            if (key === 'consultantId') {
              submitDataInput.responsibleUserId = control.value;
            }
            control.markAsPristine();
            this.triggerAutoSave.emit(submitDataInput);
          }
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.responsibilityData?.firstChange &&
      changes.responsibilityData?.previousValue &&
      changes.responsibilityData?.currentValue
    ) {
      const differences = findMismatches(
        changes.responsibilityData?.previousValue,
        changes.responsibilityData?.currentValue,
      );
      this.programmaticUpdate.next(true);
      for (const key of ReadonlyArray.intersection(
        differences,
        Object.keys(this.formGroup.controls),
      )) {
        const control = this.formGroup.get(key);
        if (
          control &&
          control.value !==
            (changes.responsibilityData?.currentValue)[
              key as keyof VacancyResponsibilityFormData
            ]
        ) {
          patchFormControl(
            control,
            (changes.responsibilityData?.currentValue)[
              key as keyof VacancyResponsibilityFormData
            ],
          );
        }
      }
      this.programmaticUpdate.next(false);
    }
    if (changes.formIsDisabled) {
      this.formIsDisabledWritable.set(changes.formIsDisabled.currentValue);
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
