<div class="uj-flex uj-flex-col">
  <div class="uj-flex uj-flex-col uj-gap-c">
    <div
      class="uj-grid uj-grid-cols-1 sm:uj-grid-cols-7 uj-relative"
      [class.uj-gap-c]="isSmDown$ | async">
      <div
        [class.!uj-col-span-full]="isNewEntry"
        [style.padding-left]="(isSmDown$ | async) ? '11px' : '0'"
        class="sm:uj-col-span-4 uj-flex uj-flex-col">
        <ui-library-form-field
          fullWidth
          [label]="
            isFirstEntry && !(isSmDown$ | async)
              ? ('preferredLocation' | i18next | firstUppercase)
              : ''
          "
          [labelFor]="'addLocation' + componentId">
          <input
            ui-library-text-input
            [id]="'addLocation' + componentId"
            [positionInGroup]="
              (isSmDown$ | async) || isNewEntry ? 'default' : 'start'
            "
            [placeholder]="'addLocation' | i18next"
            [formControl]="preferredLocationGroup.controls.location"
            (change)="onChange()"
            type="text" />
        </ui-library-form-field>
      </div>
      <div
        *ngIf="!isNewEntry"
        [style.padding-left]="(isSmDown$ | async) ? '11px' : '0'"
        class="uj-flex uj-flex-col sm:uj-col-span-3">
        <ui-library-form-field
          fullWidth
          [label]="
            isFirstEntry && !(isSmDown$ | async)
              ? ('enterRoute' | i18next | firstUppercase)
              : ''
          "
          [labelFor]="'enterRoute' + componentId">
          <div class="uj-flex">
            <input
              fullWidth
              ui-library-text-input
              [id]="'enterRoute' + componentId"
              [positionInGroup]="
                (isSmDown$ | async) ? 'start-seamless' : 'center-seamless'
              "
              [placeholder]="'enterRoute' | i18next"
              [formControl]="preferredLocationGroup.controls.radiusKm"
              (change)="onChange()"
              type="text" />
            <div
              [class.uj-text-text-grey]="preferredLocationGroup.disabled"
              [class.uj-bg-surface-grape]="preferredLocationGroup.disabled"
              class="uj-px-d uj-py-c uj-border uj-border-l-0 uj-rounded-tr uj-rounded-r-b uj-border-border uj-text-text-54 uj-flex uj-items-center">
              km
            </div>
            <button
              (click)="deletePreferredLocation()"
              ui-library-button-outline
              type="button"
              color="dark"
              iconOnly="trash"
              class="hover:uj-text-ui-negative uj-ml-c"></button>
          </div>
        </ui-library-form-field>
      </div>
      <div
        *ngIf="isSmDown$ | async"
        class="uj-absolute uj-w-[3px] uj-bg-violet-290/54 uj-rounded uj-h-full"></div>
    </div>
  </div>
</div>
