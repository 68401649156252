<ng-container *ngIf="this.vacancy$ | async as vacancy">
  <div class="uj-mb-[16px]">
    <h3 class="uj-text-text-m/[20px] uj-font-bold">{{ "Total" | i18next }}</h3>
    <div class="uj-flex uj-items-center uj-gap-e">
      <div class="uj-flex uj-items-center uj-gap-b">
        <unijob-icon icon="visability_eye"></unijob-icon>
        <div class="uj-text-[20px]/[28px] uj-font-bold uj-text-text-accent">
          {{
            viewsTotal === 0 ? "-" : (viewsTotal | number: "0.0-0" : "de-CH")
          }}
        </div>
        <div class="uj-text-text-s/[16px] uj-text-text">
          {{ "trackedActionType.VACANCY_VIEWED" | i18next }}
        </div>
      </div>
      <div class="uj-flex uj-items-center uj-gap-b">
        <unijob-icon icon="send"></unijob-icon>
        <div class="uj-text-[20px]/[28px] uj-font-bold uj-text-text-accent">
          {{ applicationsTotal === 0 ? "-" : applicationsTotal }}
        </div>
        <div class="uj-text-text-s/[16px] uj-text-text">
          {{ "trackedActionType.VACANCY_APPLICATION" | i18next }}
        </div>
      </div>
      <div class="uj-flex uj-items-center uj-gap-b">
        <div class="uj-text-[20px]/[28px] uj-font-bold uj-text-text-accent">
          {{
            (applicationsTotal / viewsTotal) * 100 | number: "0.1-1" : "de-CH"
          }}
          %
        </div>
        <div>
          <div
            *ngIf="
              applicationsTotal / viewsTotal -
              allApplicationsTotal / allViewsTotal as differenceToAvarage
            "
            style="font-size: 8px; line-height: 8px"
            class="uj-text-text-s/[16px] uj-text-text-positive"
            [class.uj-text-text-warning-2]="differenceToAvarage < 0">
            {{ differenceToAvarage > 0 ? "⬆" : "⬇" }}
            {{ differenceToAvarage * 100 | number: "0.1-1" : "de-CH" }}
            %
          </div>
          <div
            class="uj-text-text-m/[20px] uj-font-bold uj-leading-3 uj-text-text-light-grey uj-border-r-">
            Ø
            {{
              (allApplicationsTotal / allViewsTotal) * 100
                | number: "0.1-1" : "de-CH"
            }}%
          </div>
        </div>
        <div class="uj-text-text-s/[16px] uj-text-text">
          {{ "applicationToViewRatio" | i18next }}
        </div>
      </div>
    </div>
  </div>
  <div
    class="uj-p-e uj-mb-[16px] uj-border uj-border-solid uj-rounded-lg uj-border-border">
    <div class="uj-flex uj-mb-[16px]">
      <h3
        class="uj-flex-grow uj-flex-shrink uj-text-text-xl/[28px] uj-font-bold">
        {{ "trackedActionType." + selectedAction$.value | i18next }}
        {{ "perChannel" | i18next }}
      </h3>
      <ui-library-simple-select
        class="uj-flex-grow-0 uj-flex-shrink-0 md:uj-w-1/4"
        [ngModel]="selectedAction$.value"
        (ngModelChange)="selectedAction$.next($event)"
        [options]="actionOptions"
        [placeholderText]="'chooseStatistic' | i18next"
        size="S"
        design="outline"
        backgroundColor="white" />
    </div>
    <div class="uj-flex uj-flex-col uj-gap-c lg:uj-flex-row">
      <div
        class="uj-flex-grow uj-flex-shrink uj-text-text-xl/[28px] uj-font-bold lg:uj-w-a">
        <div class="uj-relative uj-w-full uj-h-[50vh]">
          <canvas
            baseChart
            [type]="'line'"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [legend]="lineChartLegend">
          </canvas>
        </div>
      </div>
      <div class="lg:uj-flex-grow-0 lg:uj-flex-shrink-0 lg:uj-w-1/4">
        <ul
          class="uj-flex uj-flex-col uj-justify-center uj-gap-c uj-flex-nowrap sm:uj-flex-row sm:uj-flex-wrap lg:uj-flex-col lg:uj-justify-start">
          <li
            class="uj-max-w-full"
            *ngFor="let dataset of datasets; index as datasetKey">
            <div
              (click)="onDatasetVisibilityToggle(datasetKey)"
              class="uj-flex uj-items-center uj-flex-grow-0 uj-flex-shrink-0 uj-gap-b uj-px-d uj-py-c uj-bg-surface-main uj-border uj-border-solid uj-rounded uj-cursor-pointer uj-select-none uj-border-border hover:uj-drop-shadow-sm hover:uj-opacity-100"
              [class.uj-opacity-60]="lineChartData.datasets[datasetKey].hidden">
              <div
                class="uj-flex uj-items-center uj-justify-center uj-w-[16px] uj-h-[16px] uj-p-a uj-text-surface-main uj-rounded-sm uj-flex-nowrap"
                [style.backgroundColor]="
                  lineChartData.datasets[datasetKey].hidden
                    ? 'transparent'
                    : lineChartData.datasets[datasetKey].borderColor
                "
                [style.border]="
                  lineChartData.datasets[datasetKey].hidden
                    ? '1px solid ' +
                      lineChartData.datasets[datasetKey].borderColor
                    : '0px solid transparent'
                ">
                <unijob-icon
                  [class.uj-opacity-0]="
                    lineChartData.datasets[datasetKey].hidden
                  "
                  size="12"
                  icon="check" />
              </div>
              <img
                *ngIf="platformsMap[dataset.label.split('/')[0]]"
                [src]="platformsMap[dataset.label.split('/')[0]].iconPath"
                alt="Icon Description"
                class="uj-w-[16px] uj-h-[16px] uj-ml-a uj-mr-a" />
              <img
                *ngIf="!platformsMap[dataset.label.split('/')[0]]"
                src="assets/platform-favicons/unknown.svg"
                alt="Icon Description"
                class="uj-w-[16px] uj-h-[16px] uj-ml-a uj-mr-a" />
              <div
                *ngIf="lineChartData.datasets[datasetKey].label as label"
                class="uj-min-w-0 uj-text-text-m/[20px] uj-truncate"
                [title]="label">
                {{ label }}
              </div>
              <div class="uj-ml-auto uj-text-text-m/[20px]">
                {{ dataset.total }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
