import { Component, inject } from '@angular/core';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { I18NextModule } from 'angular-i18next';
import { ResponsiveService } from '@intemp/unijob-ui';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TargetJobTitleFormGroup } from '../../talent-sheet.types';
import { randomId } from '../../../../helpers/functions/randomId';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { ArrayActionEnum } from '../../../../../graphql/generated';
import { TalentTargetJobEntryComponent } from './talent-target-job-entry/talent-target-job-entry.component';

@Component({
  standalone: true,
  selector: 'app-talent-target-job-title',
  templateUrl: 'talent-target-job-title.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    NgForOf,
    NgIf,
    SharedDefaultModule,
    TalentTargetJobEntryComponent,
    ReactiveFormsModule,
  ],
})
export class TalentTargetJobTitleComponent {
  formService = inject(TalentFormEditService);
  responsiveService = inject(ResponsiveService);

  newTitleEntry = new FormGroup<TargetJobTitleFormGroup>({
    uuid: new FormControl<string | null>(randomId()),
    type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.ADDED),
    title: new FormControl<string | null>(null),
  });

  trackByUUID(index: number, item: FormGroup<TargetJobTitleFormGroup>) {
    return item.value.uuid;
  }
}
