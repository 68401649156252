import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { OverlayComponent } from './overlay.component';

@NgModule({
  declarations: [OverlayComponent],
  exports: [OverlayComponent],
  imports: [CommonModule, SharedDefaultModule],
})
export class OverlayModule {}
