import { TalentSourceTypeEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getSourceTypeTextOptions = () => {
  return [
    {
      value: TalentSourceTypeEnum.WALK_IN,
      label: i18next.t('TalentSourceTypeEnum.WALK_IN'),
    },
    {
      value: TalentSourceTypeEnum.EMAIL,
      label: i18next.t('TalentSourceTypeEnum.EMAIL'),
    },
    {
      value: TalentSourceTypeEnum.PHONE,
      label: i18next.t('TalentSourceTypeEnum.PHONE'),
    },
    {
      value: TalentSourceTypeEnum.SOCIAL_MEDIA,
      label: i18next.t('TalentSourceTypeEnum.SOCIAL_MEDIA'),
    },
    {
      value: TalentSourceTypeEnum.JOB_APPLICATION,
      label: i18next.t('TalentSourceTypeEnum.JOB_APPLICATION'),
    },
  ] as const;
};
