import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  DatePickerModule,
  InfoBoxModule,
  InputTextModule,
  randomId,
  ResponsiveService,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { SimpleSelectComponent } from '@intemp/unijob-ui2';
import { TalentPhonesEntryComponent } from './talent-phones-entry/talent-phones-entry.component';
import {
  ArrayActionEnum,
  ContactPhoneTypeEnum,
} from '../../../../../graphql/generated';
import { PhonesFormGroup } from '../../talent-sheet.types';

@Component({
  standalone: true,
  selector: 'app-talent-phones',
  templateUrl: 'talent-phones.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    UjSelectInputModule,
    DatePickerModule,
    SimpleSelectComponent,
    InputTextModule,
    InfoBoxModule,
    TalentPhonesEntryComponent,
  ],
})
export class TalentPhonesComponent {
  formService = inject(TalentFormEditService);

  responsiveService = inject(ResponsiveService);

  isSmDown$ = this.responsiveService.isSmDown$;

  newPhoneEntry = new FormGroup<PhonesFormGroup>({
    uuid: new FormControl<string | null>(randomId()),
    type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.ADDED),
    phone: new FormControl<string | null>(null),
    isPrimary: new FormControl<boolean | null>(false),
    phoneType: new FormControl<ContactPhoneTypeEnum | null>(
      ContactPhoneTypeEnum.PRIVATE,
    ),
  });

  trackByUUID(index: number, item: FormGroup<PhonesFormGroup>) {
    return item.value.uuid;
  }
}
