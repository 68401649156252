import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { randomId } from '../../helpers/functions/randomId';
import { logCustomError } from '../../helpers/functions/logError';
import { ResponsiveService, setTimeout$, SheetModule } from '@intemp/unijob-ui';
import { MatchSheetService } from './services/match-sheet.service';
import { MatchSheetHeaderComponent } from './match-sheet-header/match-sheet-header.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { MatchResponsibilityComponent } from './match-responsibility/match-responsibility.component';
import { LetDirective } from '@ngrx/component';
import { MatchContentComponent } from './match-detail/match-detail.component';
import { MatchSheetFormService } from './services/match-sheet-form.service';
import { ConsultantService } from '../../../core/services/consultant.service';
import { TalentPersonalDataComponent } from '../talent-sheet/talent-personal-data/talent-personal-data.component';
import {
  AccordionComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
} from '@intemp/unijob-ui2';

import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, combineLatest, EMPTY, map, switchMap } from 'rxjs';
import { GlobalSheetsService } from '../global-sheets/global-sheets.service';
import { TalentSubscribeGQL } from '../../../graphql/generated';
import { GraphQLService } from '../../../core/services/graphql.service';
import { TalentDocumentPreviewComponent } from '../talent-sheet/talent-document-preview/talent-document-preview.component';

@Component({
  standalone: true,
  selector: 'app-match-sheet',
  templateUrl: './match-sheet.component.html',
  imports: [
    SheetModule,
    MatchSheetHeaderComponent,
    AsyncPipe,
    NgIf,
    SharedDefaultModule,
    MatchResponsibilityComponent,
    LetDirective,
    MatchContentComponent,
    AccordionComponent,
    TalentPersonalDataComponent,
    TalentDocumentPreviewComponent,
    ButtonSolidComponent,
    ButtonOutlineComponent,
  ],
  providers: [MatchSheetService, MatchSheetFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchSheetComponent implements AfterViewInit {
  //services
  service = inject(MatchSheetService);
  consultantService = inject(ConsultantService);
  formService = inject(MatchSheetFormService);
  responsiveService = inject(ResponsiveService);
  globalSheetsService = inject(GlobalSheetsService);
  talentSubscribeGQL = inject(TalentSubscribeGQL);
  graphQLService = inject(GraphQLService);

  //inputs
  sheetId = input<string>('match-edit' + randomId());
  matchId = input.required<string>();

  sheetClosed = output<string>();

  isSmDown$ = this.responsiveService.isSmDown$;

  //overlay
  match = toSignal(this.service.match$, { initialValue: null });
  talentUuid = computed(() => this.match()?.talent?.uuid);
  openSheets = toSignal(this.globalSheetsService.openSheets$, {
    initialValue: [],
  });
  openSheetOverlayAction = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId(),
    );
    return sheet?.overlayAction;
  });

  talentObservable = combineLatest([
    toObservable(this.talentUuid),
    toObservable(this.openSheetOverlayAction),
  ]).pipe(
    switchMap(([talentUuid, openSheetOverlayAction]) => {
      if (!talentUuid || !openSheetOverlayAction) {
        return EMPTY;
      } else {
        return this.talentSubscribeGQL
          .subscribe({ uuid: talentUuid }, { fetchPolicy: 'no-cache' })
          .pipe(
            map((res) => {
              return res.data?.talentSubscribe;
            }),
            catchError((err) => {
              this.graphQLService.handleError(err);
              return [];
            }),
          );
      }
    }),
  );
  talent = toSignal(this.talentObservable, {
    initialValue: undefined,
  });
  openPreview() {
    this.globalSheetsService.updateParam(
      this.sheetId(),
      'overlayAction',
      'active',
    );
  }
  closePreview() {
    this.globalSheetsService.updateParam(this.sheetId(), 'overlayAction', null);
  }

  openSheetOverlayUuid = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId(),
    );
    return sheet?.overlayUuid;
  });

  constructor() {
    effect(() => {
      this.service.matchId$.next(this.matchId());
    });
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.matchId()) {
      logCustomError('missing MatchId on match-edit sheet');
    }
    setTimeout$(() => {
      this.openSheet();
    });
  }

  openSheet() {
    this.service.open(this.sheetId());
    this.service.subscribeMatch();
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }
}
