import { ContactEmailTypeEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getEmailOptions = () => {
  return [
    {
      value: ContactEmailTypeEnum.PRIVATE,
      label: i18next.t('ContactEmailTypeEnum.PRIVATE'),
    },
    {
      value: ContactEmailTypeEnum.BUSINESS,
      label: i18next.t('ContactEmailTypeEnum.BUSINESS'),
    },
  ];
};
