<div class="uj-flex uj-flex-col uj-gap-f">
  <ng-container *ngIf="!environment.hideBetaTalentFields">
    <div class="uj-flex uj-flex-col uj-gap-b">
      <label class="uj-block text-m-b uj-relative">{{
        "talentLifeCycle" | i18next | firstUppercase
      }}</label>
      <unijob-info-box
        [message]="
          'availability_until'
            | i18next
              : {
                  date: service.talentDataAvailableUntil().date,
                  days: service.talentDataAvailableUntil().days,
                }
        "
        [type]="
          service.talentDataAvailableUntil().days <= 30 ? 'WARNING' : 'SUCCESS'
        " />
      <span class="text-s">
        {{
          "data_deletion_notice"
            | i18next
              : {
                  firstName:
                    service.talent()?.firstName || ("firstName" | i18next),
                  lastName:
                    service.talent()?.lastName || ("lastName" | i18next),
                }
        }}
        <br />
        <br />
        {{
          "data_deletion_notice_notification_message"
            | i18next
              : {
                  firstName:
                    service.talent()?.firstName || ("firstName" | i18next),
                  lastName:
                    service.talent()?.lastName || ("lastName" | i18next),
                }
        }}
      </span>
    </div>
  </ng-container>
  <app-talent-data-privacy-policy />
</div>
