<app-user-avatar
  *ngIf="userService.readyUser$ | async as avatarUser"
  [user]="avatarUser"
  size="s"
  [contextMenu]="userAvatarTemplate"
  interactive>
  <ng-container slot="tag">
    <span class="uj-border unijob-dot tag-icon color-green"> </span>
  </ng-container>
</app-user-avatar>

<ng-template #userAvatarTemplate>
  <ng-container *ngIf="userService.readyUser$ | async as avatarUser">
    <app-user-info-context-menu
      [loggedInUserBranchesNames]="loggedInUserBranchesNames()"
      [user]="avatarUser"></app-user-info-context-menu>
  </ng-container>
</ng-template>
