<div
  class="backdrop"
  (click)="closeMobileNav()"></div>
<button
  ui-library-button-outline
  elevation="elevated"
  iconOnly="chevron_left"
  corner="round"
  class="nav-collapse-button"
  size="S"
  [class.rotate]="navCollapsed"
  (click)="toggleNavCollapsed()"></button>
<nav [class.collapsed]="navCollapsed">
  <a
    *ngIf="!navCollapsed"
    routerLink="/dashboard"
    (click)="closeMobileNav()"
    class="uj-px-d uj-pb-e md:uj-hidden">
    <img
      src="/assets/logo.svg"
      alt="Logo"
      class="uj-max-h-[20px]" />
  </a>
  <ul class="primary-nav">
    <li *ngIf="canLoadPages?.DASHBOARD">
      <a
        routerLink="/dashboard"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <ui-library-icon icon="home"></ui-library-icon>
        <div class="collapsible-nav-item-content">
          {{ "dashboard" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
    <li *ngIf="canLoadPages?.MATCHES">
      <a
        routerLink="/matches"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <ui-library-icon icon="match_circle"></ui-library-icon>
        <div class="collapsible-nav-item-content">
          {{ "matches" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
    <li *ngIf="canLoadPages?.VACANCIES">
      <a
        routerLink="/vacancies"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <ui-library-icon icon="suitcase"></ui-library-icon>
        <div class="collapsible-nav-item-content">
          {{ "vacancies" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
    <li *ngIf="canLoadPages?.TALENTS">
      <a
        routerLink="/talents"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <ui-library-icon icon="user_candidate"></ui-library-icon>
        <div class="collapsible-nav-item-content">
          {{ "talents" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
    <li *ngIf="canLoadPages?.USER_MANAGEMENT">
      <a
        routerLink="/user-management"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <ui-library-icon icon="user"></ui-library-icon>
        <div class="collapsible-nav-item-content">
          {{ "userManagement" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
  </ul>
  <div
    class="build-infos"
    *ngIf="!navCollapsed && buildInfo">
    <div
      class="uj-w-fit"
      [tooltipTemplate]="buildInfoTooltip">
      {{ mainBuildInfo }}
      <ng-container *ngIf="buildInfo.env !== 'prod'">
        <span class="env-tag">{{ buildInfo.env }}</span>
      </ng-container>
    </div>
  </div>
  <ng-template #buildInfoTooltip>
    <div class="build-info-tooltip">
      <p *ngIf="buildInfo.commit">Commit: {{ buildInfo.commit }}</p>
      <p *ngIf="buildInfo.tag">Tag: {{ buildInfo.tag }}</p>
      <p *ngIf="buildInfo.branch">Branch: {{ buildInfo.branch }}</p>
      <p *ngIf="buildInfo.env">Env: {{ buildInfo.env }}</p>
    </div>
  </ng-template>
</nav>
