export const getTranslatedVacancyLocale = (
  language: string,
  targetLanguage: string,
): string | undefined => {
  const displayName = new Intl.DisplayNames([language], {
    type: 'language',
    fallback: 'code',
  });
  return displayName.of(targetLanguage);
};
