<ul>
  <li *ngFor="let anchor of anchorList">
    <unijob-tab
      [name]="anchor.name"
      [sideAligned]="true"
      size="m"
      [active]="activeAnchor === anchor.id"
      (tabSelected)="scrollToAnchor(anchor.id)"></unijob-tab>
  </li>
</ul>
