import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { Component, inject } from '@angular/core';
import { WysiwygModule } from '@intemp/unijob-ui';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-talent-internal-note',
  templateUrl: './talent-internal-note.component.html',
  imports: [
    WysiwygModule,
    I18NextModule,
    SharedDefaultModule,
    ReactiveFormsModule,
  ],
})
export class TalentInternalNoteComponent {
  formService = inject(TalentFormEditService);
}
