import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePermissionKeyEnum } from './models/shared/user/user.service';
import { LanguageSwitchComponent } from './pages/language-switch/language-switch.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { ErrorComponent } from './pages/error/error.component';
import { TalentDataPrivacyConfirmPageComponent } from './pages/talent-data-privacy-confirm-page/talent-data-privacy-confirm-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.DASHBOARD,
    },
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'debug',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.DASHBOARD,
    },
    loadChildren: () =>
      import('./pages/debug/debug.module').then((m) => m.DebugModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    data: { hideAppWrapper: true },
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'register',
    redirectTo: '/login?register=1',
    pathMatch: 'full',
    data: { hideAppWrapper: true },
  },
  {
    path: 'language',
    canMatch: [AuthGuardService],
    component: LanguageSwitchComponent,
    pathMatch: 'full',
  },
  {
    path: 'vacancies',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.VACANCIES,
    },
    loadChildren: () =>
      import('./pages/vacancies/vacancies.module').then(
        (m) => m.VacanciesModule,
      ),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'user-management',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.USER_MANAGEMENT,
    },
    loadChildren: () =>
      import('./pages/user-management/user-management.module').then(
        (m) => m.UserManagementModule,
      ),
  },
  {
    path: 'talents',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.TALENTS,
    },
    loadChildren: () =>
      import('./pages/talents/talents.module').then((m) => m.TalentsModule),
  },
  {
    path: 'matches',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.MATCHES,
    },
    loadChildren: () =>
      import('./pages/matches/matches.module').then((m) => m.MatchesModule),
  },
  // Public route
  {
    path: 'data-privacy-confirmation/:uuid/:token',
    component: TalentDataPrivacyConfirmPageComponent,
    data: { hideAppWrapper: true },
  },
  {
    path: 'hrflow-iframe/:vacancyUuid',
    loadChildren: () =>
      import('./hrflow-iframe/hrflow-iframe.module').then(
        (m) => m.HrflowIframeModule,
      ),
  },
  {
    path: 'hrflow-iframe',
    loadChildren: () =>
      import('./hrflow-iframe/hrflow-iframe.module').then(
        (m) => m.HrflowIframeModule,
      ),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
