import { createReducer, on } from '@ngrx/store';
import { CurrentUserApiActions } from './current-user.actions';
import { Option } from 'effect';

import { CurrentUserState } from './current-user-state.types';

export const initialState: CurrentUserState = {
  user: Option.none(),
};

export const currentUserReducer = createReducer(
  initialState,
  on(
    CurrentUserApiActions.userLoadedSuccess,
    (_state: CurrentUserState, userLoadedProps): CurrentUserState => ({
      ..._state,
      ...{ user: Option.some(userLoadedProps.user) },
    }),
  ),
);
