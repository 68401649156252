<ng-container *ngrxLet="routeData$ as routeData">
  <header
    *ngIf="!routeData.hideAppWrapper"
    class="app-header"
    [class.collapsed]="lastScrollDirection === verticalDirection.DOWN">
    <div class="header-backdrop"></div>
    <button
      ui-library-button-text
      iconOnly="menu_left"
      variant="tertiary"
      size="M"
      color="dark"
      class="collapse-nav-button"
      (click)="navToggleEmitter.emit()"></button>
    <a
      routerLink="/dashboard"
      class="main-logo-wrapper">
      <img
        src="/assets/logo.svg"
        alt="Logo"
        class="main-logo" />
    </a>
    <div class="uj-ml-auto uj-flex">
      <ng-container *ngIf="userService.readyUser$ | async as readyUser">
        <app-user-avatar
          [user]="readyUser"
          size="s"
          [contextMenu]="profile"
          [closeOnClick]="false"
          interactive>
          <ng-container slot="tag">
            <span class="unijob-dot tag-icon color-green uj-border"> </span>
          </ng-container>
        </app-user-avatar>
        <ng-template #profile>
          <div
            class="uj-flex uj-flex-col uj-gap-e uj-p-e uj-w-[280px] sm:uj-w-[320px]">
            <div class="uj-flex uj-gap-c uj-items-center">
              <ui-library-avatar
                [content]="readyUser.profile.image ? 'image' : 'icon'"
                [icon]="readyUser.profile.image ? undefined : 'user'"
                [imageUrl]="
                  readyUser.profile.image
                    ? (readyUser.profile.image | storedDocumentUrl)
                    : null
                "
                size="m">
              </ui-library-avatar>
              <div>
                <span class="text-m-b">
                  {{
                    readyUser.profile.firstName +
                      " " +
                      readyUser.profile.lastName
                  }}
                </span>
              </div>
            </div>
            <div>
              <p class="text-m-b uj-pb-b">{{ "language" | i18next }}</p>
              <ui-library-simple-select
                (click)="$event.stopPropagation()"
                [(ngModel)]="selectedLang"
                [options]="languageOptions"
                (ngModelChange)="setLanguage(selectedLang)"
                class="uj-grow sort-select"
                size="M"
                backgroundColor="white"
                name="languageSelect" />
            </div>
            <button
              *ngIf="canLoadPages?.PROFILE"
              ui-library-button-outline
              iconLeft="user_circle"
              color="dark"
              (click)="openProfile()">
              <div class="collapsible-nav-item-content">
                {{ "profile" | i18next | firstUppercase }}
              </div>
            </button>
            <a
              routerLink="/logout"
              ui-library-button-outline
              iconLeft="log_out"
              color="dark">
              <div class="collapsible-nav-item-content">
                {{ "logout" | i18next | firstUppercase }}
              </div>
            </a>
          </div></ng-template
        >
      </ng-container>
      <app-global-add
        class="uj-ml-e"
        data-cy="global-add-button"
        *ngIf="allowedGlobalAddActions.size"
        [allowedActions]="allowedGlobalAddActions"></app-global-add>
    </div>
  </header>
  <div
    class="app-body"
    [class.app-wrapper-visible]="!routeData.hideAppWrapper">
    <app-nav
      *ngIf="!routeData.hideAppWrapper"
      [navToggle]="navToggleEmitter"
      (navToggled)="navToggled($event)"></app-nav>
    <div class="app-content-wrapper">
      <div class="sticky-bg"></div>
      <div class="app-content flex-grow-pass-down">
        <div class="app-content-container flex-grow-pass-down">
          <ui-library-toaster />
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-scroll-to-top></app-scroll-to-top>
  <app-global-sheets></app-global-sheets>
  <app-overlay></app-overlay>
</ng-container>
