import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DatePickerModule,
  InputTextModule,
  SwitchModule,
} from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import { ArrayActionEnum } from '../../../../../../graphql/generated';
import { YearToAgePipe } from '../../../../../helpers/pipes/yearToAge.pipe';
import { randomId } from '../../../../../helpers/functions/randomId';
import { RecommendationsFormGroup } from '../../../talent-sheet.types';
import { TalentSharedService } from '../../../services/talent-shared.service';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import {
  AvatarComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  FormFieldComponent,
  ModalComponent,
  ModalService,
  TextInputComponent,
} from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-recommendations-entry',
  templateUrl: './talent-recommendations-entry.component.html',
  imports: [
    AvatarComponent,
    DatePickerModule,
    I18NextModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    SwitchModule,
    YearToAgePipe,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    ButtonTextComponent,
    ModalComponent,
    FormFieldComponent,
    TextInputComponent,
  ],
})
export class TalentRecommendationsEntryComponent implements OnChanges {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentSharedService);
  modals = inject(ModalService);

  @Input({ required: true })
  recommendation!: FormGroup<RecommendationsFormGroup>;

  @Output() isInEditMode = new EventEmitter<boolean>();

  isEditingRecommendation = false;

  deleteConfirmationModalId = 'deleteConfirmation' + randomId();
  componentId = randomId();

  ngOnChanges(changes: SimpleChanges) {
    const isNewEntry = changes.recommendation.currentValue.value.isNewEntry;
    if (isNewEntry) {
      this.setEditingRecommendation(true);
    }
  }

  deleteRecommendation() {
    this.updateRecommendation(this.recommendation, ArrayActionEnum.REMOVED);
    this.formService.recommendations.removeAt(
      this.formService.recommendations.controls.indexOf(this.recommendation),
    );
    this.setEditingRecommendation(false);
  }

  editRecommendation() {
    this.setEditingRecommendation(true);
  }

  cancelEditRecommendation() {
    const recommendations = this.formService.recommendations;
    recommendations.removeAt(
      recommendations.controls.indexOf(this.recommendation),
    );
    this.setEditingRecommendation(false);
  }

  addRecommendation() {
    this.updateRecommendation(this.recommendation, ArrayActionEnum.ADDED);
    this.recommendation.controls.isNewEntry.setValue(false);
    this.setEditingRecommendation(false);
  }

  updateRecommendationEntry() {
    this.updateRecommendation(this.recommendation, ArrayActionEnum.CHANGED);
    this.setEditingRecommendation(false);
  }

  setEditingRecommendation(value: boolean) {
    this.isEditingRecommendation = value;
    this.isInEditMode.emit(value);
  }

  updateRecommendation(
    recommendationControl: FormGroup<RecommendationsFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const recommendationData = getFormData(recommendationControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !recommendationData) {
      console.error('Talent uuid is not valid');
      return;
    }
    delete recommendationData.isNewEntry;
    recommendationData.type = arrayAction;

    this.formService
      .updateTalentApi({
        recommendedBy: [recommendationData],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }
}
