<div (window:scroll)="setScrollPosistion()">
  <div
    (click)="scrollToToday()"
    class="day-scroll-link"
    *ngIf="scrollPosition">
    <div class="hint">
      {{ "scrollTop" | i18next | firstUppercase }}
    </div>
    <button
      class="day-scroll-button"
      ui-library-button-outline
      iconOnly="arrow_up"
      corner="round"
      color="dark"></button>
  </div>
</div>
