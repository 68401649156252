<div
  class="uj-flex uj-items-center uj-p-d uj-border uj-rounded-b uj-border-border uj-gap-d">
  <ng-container *ngIf="isEditingRecommendation; else preview">
    <div
      class="uj-w-full uj-grid uj-grid-cols-1 md:uj-grid-cols-2 uj-gap-c uj-items-end fist:uj-mt-d"
      [formGroup]="recommendation">
      <ui-library-form-field
        fullWidth
        required
        [label]="'firstName' | i18next | firstUppercase"
        [labelFor]="'firstName' + componentId">
        <input
          ui-library-text-input
          [id]="'firstName' + componentId"
          [placeholder]="'firstName' | i18next"
          formControlName="firstName"
          focusTracker="firstName"
          type="text" />
      </ui-library-form-field>
      <ui-library-form-field
        fullWidth
        required
        [label]="'lastName' | i18next | firstUppercase"
        [labelFor]="'lastName' + componentId">
        <input
          ui-library-text-input
          [id]="'lastName' + componentId"
          [placeholder]="'lastName' | i18next"
          formControlName="lastName"
          focusTracker="lastName"
          type="text" />
      </ui-library-form-field>

      <ui-library-form-field
        class="uj-col-span-full"
        fullWidth
        required
        [label]="'email' | i18next | firstUppercase"
        [labelFor]="'email' + componentId">
        <input
          ui-library-text-input
          [id]="'email' + componentId"
          [placeholder]="'email' | i18next"
          formControlName="email"
          focusTracker="email"
          type="text" />
      </ui-library-form-field>

      <div
        class="uj-col-span-full uj-flex uj-flex-col md:uj-flex-row uj-gap-c uj-mt-d">
        <ng-container
          *ngIf="recommendation.controls.isNewEntry.value; else childToUpdate">
          <button
            class="!uj-w-full"
            ui-library-button-outline
            color="primary"
            (click)="cancelEditRecommendation()">
            {{ "cancel" | i18next | firstUppercase }}
          </button>
          <button
            [disabled]="!recommendation.valid"
            class="!uj-w-full"
            ui-library-button-solid
            iconLeft="plus"
            size="M"
            corner="rounded"
            btnColor="bg-violet"
            (click)="addRecommendation()">
            {{ "add" | i18next | firstUppercase }}
          </button>
        </ng-container>
        <ng-template #childToUpdate>
          <button
            class="!uj-w-full"
            ui-library-button-outline
            color="primary"
            (click)="modals.open(deleteConfirmationModalId)">
            {{ "delete" | i18next | firstUppercase }}
          </button>
          <button
            class="!uj-w-full"
            ui-library-button-solid
            iconLeft="check"
            color="primary"
            (click)="updateRecommendationEntry()">
            {{ "save" | i18next | firstUppercase }}
          </button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #preview>
  <div class="uj-flex uj-flex-col">
    <ui-library-avatar
      content="letters"
      [letters]="
        recommendation.controls.firstName.value?.slice(0, 1) +
        ' ' +
        recommendation.controls.lastName.value?.slice(0, 1)
      "
      size="s"
      theme="outline"
      backgroundColor="white"></ui-library-avatar>
  </div>
  <div class="uj-flex-grow">
    <div class="text-m-b">
      {{
        recommendation.controls.firstName.value +
          " " +
          recommendation.controls.lastName.value
      }}
    </div>
    <div class="text-m">
      {{ recommendation.controls.email.value }}
    </div>
  </div>
  <button
    ui-library-button-text
    iconOnly="edit_pen_1"
    (click)="editRecommendation()"></button>
</ng-template>

<ui-library-modal
  [id]="deleteConfirmationModalId"
  size="S">
  <ng-container slot="header">
    {{ "deleteEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "areYouSureYouWantToDeleteThisEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="modals.close(deleteConfirmationModalId)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deleteRecommendation()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button"
        iconLeft="trash">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>
