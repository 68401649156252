import { ChartOptions } from 'chart.js';

export const statsDefaultLinechartOptions: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'dd.MM',
        },
        tooltipFormat: 'dd.MM.yyyy',
      },
      border: {
        display: false,
      },
      stacked: false,
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      stacked: false,
      grid: {
        display: true,
        tickBorderDash: [5, 5],
      },
      ticks: { stepSize: 1 },
    },
  },
  interaction: {
    mode: 'index',
    axis: 'x',
  },
  plugins: {
    tooltip: {
      borderColor: '#3232321F',
      borderWidth: 1,
      boxWidth: 12,
      boxHeight: 5,
      boxPadding: 10,
      padding: 8,
      backgroundColor: 'white',
      titleColor: '#323232',
      titleFont: {
        size: 12,
      },
      bodyColor: '#323232',
      bodyFont: {
        size: 12,
      },
      footerColor: '#323232',
      footerFont: {
        size: 12,
      },
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        color: '#323232',
        usePointStyle: true,
        pointStyle: 'rectRounded',
      },
    },
  },
};
