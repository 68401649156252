import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { RouterModule } from '@angular/router';
import { SharedDefaultModule } from '../shared/modules/shared-default/shared-default.module';
import { IconModule, TooltipModule } from '@intemp/unijob-ui';
import { ButtonOutlineComponent, IconComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [NavComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedDefaultModule,
    IconModule,
    TooltipModule,
    ButtonOutlineComponent,
    IconComponent,
  ],
  exports: [NavComponent, RouterModule],
})
export class CoreModule {}
