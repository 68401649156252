import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  ContactsQueryGQL,
  ContactsQueryVariables,
} from '../../../graphql/generated';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private contactsQueryGQL: ContactsQueryGQL) {}

  contactsQuery(variables: ContactsQueryVariables) {
    const result = this.contactsQueryGQL
      .fetch(variables, {
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return res.data[`contactsQuery`];
        }),
      );
    return result;
  }
}
