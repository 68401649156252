import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, debounceTime, firstValueFrom } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import {
  CompanyLocation,
  Contact,
  IsValidPostalCodeGQL,
  VacancyDetailUniBaseXVacancyFragment,
  VacancyPublishableFieldsEnum,
} from '../../../../graphql/generated';
import { disableFormGroup } from '../../../helpers/functions/disableFormGroup';
import { findMismatches } from '../../../helpers/functions/findMismatches';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { patchFormControl } from '../../../helpers/functions/patchFormControl';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import { customValidators } from '../../shared-forms/customValidators';
import {
  SubmitVacancyUpdateInput,
  VacancyCustomerFormData,
} from '../../../../pages/vacancies/vacancy.types';
import { FocusTrackerDirective } from '../focusTracker.directive';
import { validateFormControl } from '../helpers/validateFormControl';
import { Writable } from 'type-fest';

@Component({
  selector: 'app-vacancy-detail-customer-form',
  templateUrl: './vacancy-detail-customer-form.component.html',
})
export class VacancyDetailCustomerFormComponent implements OnInit, OnChanges {
  @Input({ required: true }) customerData!: VacancyCustomerFormData;
  @Input({ required: true }) formRef!: FormGroup;
  @Input() formIsDisabled = false;
  @Input() unibaseXVacancy?: VacancyDetailUniBaseXVacancyFragment;

  isValidPostalCodeGQL = inject(IsValidPostalCodeGQL);

  titleControl = new FormControl<string | null>(null, [Validators.required]);
  streetControl = new FormControl<string | null>(null);
  zipControl = new FormControl<string | null>(null, [
    Validators.pattern(/^\d{4}$/),
  ]);
  locationControl = new FormControl<string | null>(null);

  publishExactJobLocationControl = new FormControl<boolean>(false);
  jobLocationAddressReplacementControl = new FormControl<string | null>(null);

  companyLocationControl = new FormControl<CompanyLocation | null>(null);
  companyContactControl = new FormControl<Contact | null>(null);

  VacancyPublishableFieldsEnum = VacancyPublishableFieldsEnum;
  hasChanges(field: VacancyPublishableFieldsEnum) {
    return this.unibaseXVacancy?.unpublishedChanges?.includes(field);
  }

  jobLocationAddress = new FormGroup({
    street: this.streetControl,
    zip: this.zipControl,
    location: this.locationControl,
  });

  jobLocationAlternateSpecificityFormGroup = new FormGroup({
    publishExactJobLocation: this.publishExactJobLocationControl,
    jobLocationAddressReplacement: this.jobLocationAddressReplacementControl,
  });

  jobCompanyAndContactFormGroup = new FormGroup({
    companyLocation: this.companyLocationControl,
    companyContact: this.companyContactControl,
  });

  formGroup = new FormGroup({
    jobLocationAddress: this.jobLocationAddress,
    jobCompanyAndContactFormGroup: this.jobCompanyAndContactFormGroup,
    jobLocationAlternateSpecificityFormGroup:
      this.jobLocationAlternateSpecificityFormGroup,
  });

  @Output() triggerAutoSave = new EventEmitter<SubmitVacancyUpdateInput>();

  programmaticUpdate = new BehaviorSubject<boolean>(false);

  autoSaved: { [key in keyof any]?: boolean } = {};

  @ViewChildren(FocusTrackerDirective)
  focusTrackers!: QueryList<FocusTrackerDirective>;

  ngOnInit(): void {
    this.updateFormValue();
    this.subscribeFormChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.firstChange &&
      changes.customerData.previousValue &&
      changes.customerData.currentValue
    ) {
      this.patchFormValuesOnChange(
        changes.customerData.previousValue,
        changes.customerData.currentValue,
      );
    }
    if (changes.formIsDisabled) {
      if (changes.formIsDisabled.currentValue) {
        disableFormGroup(this.formGroup);
      } else {
        this.formGroup.enable({ emitEvent: false });
      }
    }
  }
  patchFormValuesOnChange(
    previous: VacancyCustomerFormData,
    current: VacancyCustomerFormData,
  ) {
    let differences = findMismatches(previous, current);
    if (!differences.length) return;

    this.programmaticUpdate.next(true);
    const formGroups = [
      this.jobLocationAddress,
      this.jobCompanyAndContactFormGroup,
      this.jobLocationAlternateSpecificityFormGroup,
    ];
    differences = differences.map((differenceKey) => {
      if (differenceKey === 'companyLocation.uuid') {
        return 'companyLocation';
      }
      if (differenceKey === 'companyContact.uuid') {
        return 'companyContact';
      }
      return differenceKey;
    });

    for (const key of differences) {
      let control: AbstractControl | null = null;

      for (const formGroup of formGroups as FormGroup[]) {
        if (Object.keys(formGroup.controls).includes(key)) {
          control = formGroup.get(key);
          break;
        }
      }

      const isFocused = this.focusTrackers.find((f) => f.targetID === key)
        ?.isFocused$.value;

      if (isFocused) continue;

      if (
        control &&
        control.value !== current[key as keyof VacancyCustomerFormData]
      ) {
        if (key === 'companyLocation') {
          this.patchJobCompanyAndContactFormGroupByValue(
            'companyLocation',
            current['companyLocation'],
          );
        } else if (key === 'companyContact') {
          this.patchJobCompanyAndContactFormGroupByValue(
            'companyContact',
            current['companyContact'],
          );
        } else {
          patchFormControl(
            control,
            current[key as keyof VacancyCustomerFormData],
          );
        }
      }
    }
    this.programmaticUpdate.next(false);
  }

  updateFormValue(): void {
    const {
      publishExactJobLocation,
      jobLocationAddressReplacement,
      companyLocation,
      companyContact,
      street,
      zip,
      location,
    } = this.customerData;

    this.publishExactJobLocationControl.setValue(!!publishExactJobLocation);

    this.jobLocationAddressReplacementControl.setValue(
      jobLocationAddressReplacement || null,
    );
    this.streetControl.setValue(street || null);
    this.zipControl.setValue(zip || null);
    this.locationControl.setValue(location || null);

    if (companyLocation?.uuid) {
      this.patchJobCompanyAndContactFormGroupByValue(
        'companyLocation',
        companyLocation,
      );
    }

    if (companyContact) {
      this.patchJobCompanyAndContactFormGroupByValue(
        'companyContact',
        companyContact,
      );
    }
    this.formRef.setControl('customer', this.formGroup);
  }

  patchJobCompanyAndContactFormGroupByValue(key: string, value: any) {
    this.jobCompanyAndContactFormGroup
      .get(key)
      ?.patchValue(value, { emitEvent: false });
  }

  patchJobAddress(companyLocation: CompanyLocation) {
    this.zipControl.setValue(companyLocation.address?.zip || null);
    this.streetControl.setValue(companyLocation.address?.street || null);
    this.locationControl.setValue(companyLocation.address?.location || null);
    this.titleControl.setValue(companyLocation.companyName || null);
  }
  subscribeFormChanges() {
    this.subscribeCompanyLocationChanges();
    this.subscribeJobLocationAddressChanges();
    this.subscribePublishExactJobLocationChanges();
  }

  subscribeCompanyLocationChanges() {
    this.companyLocationControl.valueChanges
      .pipe(
        startWith(this.companyLocationControl.value),
        filter(() => !this.formIsDisabled),
      )
      .subscribe((value) => {
        if (value) {
          this.companyContactControl.enable();
        } else {
          this.companyContactControl.disable();
        }
      });
    this.companyLocationControl.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((companyLocation) => {
        if (!companyLocation) {
          throw new Error(
            'got null as CompanyLocation, even though the field should not be clearable',
          );
        }
        this.programmaticUpdate.next(true);
        this.companyContactControl.setValue(null, { emitEvent: false });
        this.patchJobAddress(companyLocation);
        this.programmaticUpdate.next(false);

        const submitDataInput: Writable<SubmitVacancyUpdateInput> = {
          companyLocationUuid: companyLocation.uuid,
          companyContactUuid: null,
        };

        submitDataInput.jobLocationAddress = {
          street: companyLocation.address?.street,
          zip: companyLocation.address?.zip,
          location: companyLocation.address?.location,
          country: 'CH',
        };
        this.triggerSave(submitDataInput);
      });

    this.companyContactControl.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((companyContact) => {
        this.triggerSave({
          companyContactUuid: companyContact?.uuid || null,
        });
      });
  }

  subscribePublishExactJobLocationChanges() {
    this.publishExactJobLocationControl.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((value) => {
        this.triggerSave({
          publishExactJobLocation: value,
        });
      });

    this.jobLocationAddressReplacementControl.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((value) => {
        this.triggerSave({
          jobLocationAddressReplacement: value,
        });
      });
  }

  subscribeJobLocationAddressChanges() {
    this.jobLocationAddress.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((controls) => {
        getObjectKeys(controls).forEach((key) => {
          const control = this.jobLocationAddress.controls[key];
          if (control.dirty) {
            this.submitInputFormControl(control, key);
          }
        });
      });
  }

  submitInputFormControl(control: FormControl, key: string) {
    const submitDataInput = {
      jobLocationAddress: {
        [key]: control.value,
      },
    };
    control.markAsPristine();
    this.triggerSave(submitDataInput);
  }

  triggerSave(submitDataInput: SubmitVacancyUpdateInput) {
    this.triggerAutoSave.emit(submitDataInput);
    this.markAsAutoSaved(submitDataInput);
  }

  markAsAutoSaved(submitDataInput: SubmitVacancyUpdateInput) {
    this.autoSaved = {};
    Object.keys(submitDataInput).forEach((key) => {
      this.autoSaved[key] = true;
    });
  }

  public enablePublishedValidation() {
    const { isEmpty } = customValidators;
    //jobLocationAddress
    this.titleControl.addValidators([isEmpty]);
    this.zipControl.addValidators([isEmpty]);
    this.locationControl.addValidators([isEmpty]);

    this.companyLocationControl.addValidators([isEmpty]);
    this.companyContactControl.addValidators([isEmpty]);

    if (this.publishExactJobLocationControl.value !== true) {
      this.jobLocationAddressReplacementControl.addValidators([isEmpty]);
    } else {
      this.jobLocationAddressReplacementControl.setValidators([]);
    }
  }

  async validateAsyncZipCode() {
    if (this.zipControl.value === null) return;
    const isValid = await firstValueFrom(
      this.isValidPostalCodeGQL
        .fetch({ postalCode: this.zipControl.value })
        .pipe(map((result) => result.data?.isValidPostalCode)),
    );
    if (!isValid) {
      this.zipControl.setErrors({
        message: {
          key: 'zipCodeIsInvalid',
        },
      });
    } else {
      this.zipControl.setErrors(null);
    }
    return isValid;
  }

  public async validateFormAsync() {
    return await this.validateAsyncZipCode();
  }

  public validateForm() {
    Object.keys(this.formGroup.controls).forEach((key) => {
      const formGroup = this.formGroup.get(key) as FormGroup;
      for (const controlKey of Object.keys(formGroup.controls)) {
        const control = formGroup.get(controlKey);
        if (!control || (control.validator === null && control.valid)) continue;
        validateFormControl(control);
      }
    });
  }
}
