import {
  ChangelogFieldDisplayType,
  TalentHistoryEntryFragment,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { TalentBooleanFields } from '../../talent-sheet/talent-sheet.types';
import {
  getChangelogFieldLabel,
  getChangelogGroupLabel,
} from './getChangelogFieldLabel';

export function prepareTalentChangLogForTypeBoolean(
  entry: TalentHistoryEntryFragment,
  fieldName: keyof TalentBooleanFields,
): PreparedTalentChangeLog {
  return {
    ...entry,
    fieldName: fieldName,
    fieldLabel: getChangelogFieldLabel(fieldName),
    groupLabel: getChangelogGroupLabel(fieldName),
    revertInput: entry.revertInput,
    mutationFieldType: ChangelogFieldDisplayType.BOOLEAN,
    action: entry.mutation?.input?.[fieldName] ? 'activated' : 'deactivated',
  };
}
