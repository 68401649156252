<ng-container
  *ngFor="
    let openSheet of globalSheetsService.openSheets$ | async;
    trackBy: trackBySheetId
  ">
  <ng-container [ngSwitch]="openSheet?.type">
    <app-vacancy-detail
      *ngSwitchCase="globalSheetTypeEnum.VACANCY_EDIT"
      [sheetId]="openSheet.sheetId"
      [vacancyId]="openSheet.uuid"
      [action]="openSheet.action"
      (sheetClosed)="removeSheet($event)"></app-vacancy-detail>
    <app-vacancy-changelog
      *ngSwitchCase="globalSheetTypeEnum.VACANCIES_CHANGELOG"
      [sheetId]="openSheet.sheetId"
      [vacancyId]="openSheet.uuid"
      (sheetClosed)="removeSheet($event)"></app-vacancy-changelog>
    <app-channel-slots
      *ngSwitchCase="globalSheetTypeEnum.CHANNEL_SLOTS"
      [sheetId]="openSheet.sheetId"
      [channelUuid]="openSheet.uuid"
      (sheetClosed)="removeSheet($event)"></app-channel-slots>
    <app-talent-sheet
      *ngSwitchCase="globalSheetTypeEnum.TALENT_EDIT"
      [sheetId]="openSheet.sheetId"
      [talentId]="openSheet.uuid"
      (sheetClosed)="removeSheet($event)"></app-talent-sheet>
    <app-talent-changelog
      *ngSwitchCase="globalSheetTypeEnum.TALENT_CHANGELOG"
      [sheetId]="openSheet.sheetId"
      [talentId]="openSheet.uuid"
      (sheetClosed)="removeSheet($event)"></app-talent-changelog>
    <!-- match -->
    <app-match-sheet
      *ngSwitchCase="globalSheetTypeEnum.MATCH_EDIT"
      [sheetId]="openSheet.sheetId"
      [matchId]="openSheet.uuid"
      (sheetClosed)="removeSheet($event)"></app-match-sheet>
  </ng-container>
</ng-container>
