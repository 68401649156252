import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockAuthService {
  user$ = new BehaviorSubject(true);
  isAuthenticated$ = of(true);
  error$ = of();
  getAccessTokenSilently() {
    return of(
      'this token should be overridden by cypress and not sent to the back-end',
    );
  }
  appState$ = of(null);
}
