{
  "tooManyAttempts": "Too many attempts. Please try again later.",
  "documentNotFound": "Was unable to find document",
  "notifySyncError": "Was unable to synchronise data",
  "userNotFound": "User not found",
  "employmentNotFound": "Employment not found",
  "serverError": "Server error",
  "featureNotImplemented": "",
  "couldNotUpdateUser": "",
  "customerNotFound": "",
  "customerBranchNotFound": "",
  "missingRequiredFieldX": "",
  "updateMadeNoChanges": "",
  "tabSortError": "",
  "deleteTabError": "",
  "dayNotFound": "",
  "entryNotFound": "",
  "missingPhoneNumber": "",
  "notImplemented": "",
  "couldNotCreateJobSubscription": "",
  "consultantNotFound": "",
  "employeeNotFound": "",
  "permissionDenied": ""
}
