import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BigSelectComponent } from './big-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ContextMenuModule,
  IconModule,
  InputTextModule,
  TooltipModule,
  UnijobModule,
} from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import {
  AvatarComponent,
  ButtonOutlineComponent,
  ButtonTextComponent,
} from '@intemp/unijob-ui2';

@NgModule({
  declarations: [BigSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    InputTextModule,
    FormsModule,
    UnijobModule,
    SharedDefaultModule,
    ContextMenuModule,
    TooltipModule,
    ButtonTextComponent,
    ButtonOutlineComponent,
    AvatarComponent,
  ],
  exports: [BigSelectComponent],
})
export class BigSelectModule {}
