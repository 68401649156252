export const sourceToPlatformMap: {
  [k: string]: { label: string; iconPath: string };
} = {
  'universaljob-others': {
    label: 'universaljob',
    iconPath: 'assets/platform-favicons/universaljob.svg',
  },
  'tv-screen': {
    label: 'TV Screen',
    iconPath: 'assets/platform-favicons/universaljob.svg',
  },
  'jobs.ch': {
    label: 'Jobs.ch',
    iconPath: 'assets/platform-favicons/jobs-ch.svg',
  },
  jobshot: {
    label: 'Jobshot',
    iconPath: 'assets/platform-favicons/jobshot.svg',
  },
  linkedin: {
    label: 'LinkedIn',
    iconPath: 'assets/platform-favicons/linkedin.svg',
  },
  indeed: {
    label: 'Indeed',
    iconPath: 'assets/platform-favicons/indeed.svg',
  },
  Talent: {
    label: 'Talent',
    iconPath: 'assets/platform-favicons/talent.svg',
  },
  jobup: {
    label: 'Jobup',
    iconPath: 'assets/platform-favicons/jobup.svg',
  },
  ostjob: {
    label: 'Ostjob',
    iconPath: 'assets/platform-favicons/ostjob.svg',
  },
  zentraljob: {
    label: 'Zentraljob',
    iconPath: 'assets/platform-favicons/ostjob.svg',
  },
  myjob: {
    label: 'Myjob',
    iconPath: 'assets/platform-favicons/myjob.svg',
  },
  jobchannel: {
    label: 'Jobchannel',
    iconPath: 'assets/platform-favicons/jobchannel.svg',
  },
  mapmeo: {
    label: 'Mapmeo',
    iconPath: 'assets/platform-favicons/mapmeo.svg',
  },
  google: {
    label: 'Google',
    iconPath: 'assets/platform-favicons/google.svg',
  },
  instagram: {
    label: 'Instagram',
    iconPath: 'assets/platform-favicons/instagram.svg',
  },
  facebook: {
    label: 'Facebook',
    iconPath: 'assets/platform-favicons/facebook.svg',
  },
  'facebook-instagram': {
    label: 'Facebook / Instagram',
    iconPath: 'assets/platform-favicons/meta.svg',
  },
  tiktok: {
    label: 'TikTok',
    iconPath: 'assets/platform-favicons/tiktok.svg',
  },
  jooble: {
    label: 'Jooble',
    iconPath: 'assets/platform-favicons/jooble.svg',
  },
  youtube: {
    label: 'YouTube',
    iconPath: 'assets/platform-favicons/youtube.svg',
  },
};
