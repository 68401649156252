import { CurrencyEnum } from '../../../../graphql/generated';

export const getSalaryCurrencyTextOptions = () => {
  return [
    {
      value: CurrencyEnum.CHF,
      label: 'CHF',
    },
    {
      value: CurrencyEnum.EUR,
      label: '€',
    },
    {
      value: CurrencyEnum.USD,
      label: '$',
    },
  ] as const;
};
