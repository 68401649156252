import { ChangelogState } from '../../../../pages/talents/talent.types';

export function resolveChangelogAction(
  currentValue?: string,
  previousValue?: string,
): ChangelogState {
  if (currentValue && !previousValue) {
    return 'added';
  } else if (!currentValue && previousValue) {
    return 'removed';
  } else {
    return 'changed';
  }
}
