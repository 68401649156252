import { CompanyLocationFragment } from '../../../../graphql/generated';
import { Equal, Hash } from 'effect';
import { TitleAndTextValueWithActionsAndAvatar } from './avatar-selected-item/avatar-selected-item.component';
import { BigSelectAction } from '../../big-select/big-select.component';
import { AddressToGoogleMapUrl } from '../../../helpers/pipes/addressToGoogleMapUrl.pipe';
import { TemplateRef } from '@angular/core';

// TODO: Implement SelectableData instead
export class CompanyLocationAvatar
  implements TitleAndTextValueWithActionsAndAvatar, Equal.Equal
{
  get key(): string {
    return this.location.uuid;
  }

  get title(): string {
    return this.companyName;
  }

  get text(): string {
    return (
      this.location.address?.street +
      ', ' +
      this.location.address?.zip +
      ' ' +
      this.location.address?.location
    );
  }

  private computeActions(): BigSelectAction[] {
    if (this.location.address.street) {
      return [
        {
          icon: 'map',
          labelTranslationKey: 'openMap',
          onClick: () => {
            window.open(
              this.addressToGoogleMapUrl.transform(this.location.address),
            );
          },
        },
      ];
    } else {
      return [] as BigSelectAction[];
    }
  }

  private _cachedActions: BigSelectAction[] | undefined;
  get actions(): BigSelectAction[] {
    this._cachedActions ??= this.computeActions();
    return this._cachedActions;
  }

  [Equal.symbol](that: Equal.Equal): boolean {
    if (that instanceof CompanyLocationAvatar) {
      return (
        Equal.equals(this.key, that.key) &&
        Equal.equals(this.title, that.title) &&
        Equal.equals(this.text, that.text)
      );
    }
    return false;
  }

  [Hash.symbol](): number {
    return Hash.hash(this.text && this.key && this.title);
  }

  constructor(
    private companyName: string,
    private location: CompanyLocationFragment,
    private addressToGoogleMapUrl: AddressToGoogleMapUrl,
    public avatarTemplateRef: TemplateRef<any>,
  ) {}
}
