import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../environments/environment';
import { UserService } from '../models/shared/user/user.service';
import { OverlayService } from '../shared/modules/overlay/overlay.service';
import { ToastService } from '@intemp/unijob-ui2';
import { GraphQLService } from './services/graphql.service';
import { filter } from 'rxjs';
import { filterIsNotEmpty } from '../shared/helpers/functions/filterIsNotEmpty';

@NgModule({
  imports: [ApolloModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class UniBaseXGraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    userService: UserService,
    toastService: ToastService,
    i18nPipe: I18NextPipe,
    graphQlService: GraphQLService,
    overlayService: OverlayService,
  ) {
    let initialized = false;

    userService.currentToken$
      .pipe(filter(filterIsNotEmpty))
      .subscribe((token) => {
        if (initialized) {
          // This causes retryWait to activate!
          // graphQlService.wsClient?.terminate();
          apollo.client.stop();
          apollo.client.clearStore();
          apollo.removeClient();
        } else {
          initialized = true;
        }
        apollo.create(
          graphQlService.getApolloFactory(
            environment.gqlApiXUrl,
            environment.gqlApiXWsUrl,
          )(
            httpLink,
            userService,
            toastService,
            i18nPipe,
            graphQlService,
            overlayService,
          ),
        );
      });
  }
}
