import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { ResidencePermitEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getResidencePermitOptions =
  (): SelectOption<ResidencePermitEnum>[] => {
    return [
      {
        value: ResidencePermitEnum.NONE,
        name: i18next.t('ResidencePermitEnum.NONE'),
      },
      {
        value: ResidencePermitEnum.REPORTING_PROCEDURE,
        name: i18next.t('ResidencePermitEnum.REPORTING_PROCEDURE'),
      },
      {
        value: ResidencePermitEnum.C,
        name: i18next.t('ResidencePermitEnum.C'),
      },
      {
        value: ResidencePermitEnum.Ci,
        name: i18next.t('ResidencePermitEnum.Ci'),
      },
      {
        value: ResidencePermitEnum.B,
        name: i18next.t('ResidencePermitEnum.B'),
      },
      {
        value: ResidencePermitEnum.G,
        name: i18next.t('ResidencePermitEnum.G'),
      },
      {
        value: ResidencePermitEnum.L,
        name: i18next.t('ResidencePermitEnum.L'),
      },
      {
        value: ResidencePermitEnum.N,
        name: i18next.t('ResidencePermitEnum.N'),
      },
      {
        value: ResidencePermitEnum.F,
        name: i18next.t('ResidencePermitEnum.F'),
      },
      {
        value: ResidencePermitEnum.S,
        name: i18next.t('ResidencePermitEnum.S'),
      },
      {
        value: ResidencePermitEnum.GMB,
        name: i18next.t('ResidencePermitEnum.GMB'),
      },
    ];
  };
