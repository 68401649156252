<ui-library-form-field
  fullWidth
  [label]="'responsibleContact' | i18next | firstUppercase">
  <ui-library-card
    padding="8px"
    class="uj-flex uj-gap-d">
    <div
      class="uj-flex uj-gap-d uj-items-center"
      *ngrxLet="{
        firstName: companyContact()?.firstName ?? 'FirstName Missing',
        lastName: companyContact()?.lastName ?? 'LastName Missing',
        email: companyContact()?.email,
        phone: companyContact()?.phoneFix || companyContact()?.phoneMobile,
      } as companyContact">
      <ui-library-avatar
        size="s"
        content="letters"
        [letters]="companyContactLetters()"
        icon="user"></ui-library-avatar>
      <div class="uj-flex-1">
        <span class="text-m-b"
          >{{ companyContact.firstName }} {{ companyContact.lastName }}</span
        >
      </div>
      <div class="uj-flex uj-gap-c uj-items-center">
        <a
          *ngIf="companyContact.phone"
          target="_blank"
          [href]="'tel:' + companyContact.phone"
          [tooltip]="'call' | i18next"
          ui-library-button-text
          iconOnly="phone"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"></a>
        <a
          *ngIf="companyContact.email"
          target="_blank"
          [href]="'mailto:' + companyContact.email"
          [tooltip]="'writeEmail' | i18next"
          ui-library-button-text
          iconOnly="mail"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"></a>
      </div>
    </div>
  </ui-library-card>
</ui-library-form-field>
