import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import {
  UnibaseContactImportGQL,
  UnibaseContactsQueryGQL,
} from '../../../graphql/generated';
import { QueryUnibaseContactsQueryArgs } from '../../../graphql/generated';

@Injectable({
  providedIn: 'root',
})
export class UnibaseContactsService {
  constructor(
    private unibaseContactsQueryGQL: UnibaseContactsQueryGQL,
    private unibaseContactImportGQL: UnibaseContactImportGQL,
    private apollo: Apollo,
  ) {}

  unibaseContactsQuery(variables: QueryUnibaseContactsQueryArgs) {
    return this.unibaseContactsQueryGQL
      .fetch(variables, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data.unibaseContactsQuery;
        }),
      );
  }

  unibaseContactImport(unibaseContactId: number, companyLocationUuid: string) {
    return this.unibaseContactImportGQL
      .mutate({
        companyLocationUuid: companyLocationUuid,
        unibaseContactId,
      })
      .pipe(
        map((res) => {
          return res.data?.unibaseContactImport;
        }),
      );
  }
}
