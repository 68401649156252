import { Component, inject, signal } from '@angular/core';
import {
  ContextMenuModule,
  DatePickerModule,
  IconModule,
  InputTextModule,
  OverflowGradientModule,
  randomId,
  SheetModule,
  SwitchModule,
  TabModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { VacanciesStatusIndicatorModule } from '../../../vacancies-status-indicator/vacancies-status-indicator.module';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { UserAvatarModule } from '../../../user-avatar/user-avatar.module';
import { LetDirective } from '@ngrx/component';
import { UserInfoContextMenuModule } from '../../../user/user-info-context-menu/user-Info-context-menu.module';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { ArrayActionEnum } from '../../../../../graphql/generated';
import { TalentChildrenChildComponent } from './talent-children-child/talent-children-child.component';
import { ChildrenFormGroup } from '../../talent-sheet.types';
import { AvatarComponent, ButtonOutlineComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-children',
  templateUrl: './talent-children.component.html',
  imports: [
    SheetModule,
    AsyncPipe,
    AvatarComponent,
    I18NextModule,
    IconModule,
    NgIf,
    OverflowGradientModule,
    SharedDefaultModule,
    TabModule,
    UserAvatarModule,
    VacanciesStatusIndicatorModule,
    LetDirective,
    UserInfoContextMenuModule,
    ContextMenuModule,
    FormsModule,
    ReactiveFormsModule,
    UjSelectInputModule,
    InputTextModule,
    DatePickerModule,
    SwitchModule,
    TalentChildrenChildComponent,
    ButtonOutlineComponent,
  ],
})
export class TalentChildrenComponent {
  formService = inject(TalentFormEditService);
  childrenIsInEditMode = signal(false);

  addChild() {
    const newChild = new FormGroup<ChildrenFormGroup>({
      uuid: new FormControl<string>(randomId()),
      type: new FormControl<ArrayActionEnum>(ArrayActionEnum.ADDED),
      firstName: new FormControl<string | null>('', [Validators.required]),
      lastName: new FormControl<string | null>('', [Validators.required]),
      yearOfBirth: new FormControl<Date[] | null>(null, [Validators.required]),
      hasChildAllowance: new FormControl<boolean>(false),
      isNewEntry: new FormControl<boolean>(true),
    });
    this.formService.children.push(newChild);
    this.childrenIsInEditMode.set(true);
  }

  trackByUUID(index: number, item: FormGroup<ChildrenFormGroup>) {
    return item.value.uuid;
  }

  toggleChildrenIsInEditMode($event: boolean) {
    this.childrenIsInEditMode.set($event);
  }
}
