export const getDisplayFieldNames = (validationFields: string[]): string[] => {
  const fields = validationFields.map((field) => {
    switch (field) {
      case 'title':
        return 'jobTitle';
      case 'companyContact':
        return 'contact';
      case 'functionText':
        return 'whatIsMyFunctionTitle';
      case 'requirementsText':
        return 'whatDoINeedTitle';
      case 'employmentType':
        return 'employment';
      case 'workloadPercentage':
        return 'workload';
      case 'workloadPercentageMin':
        return 'minValue';
      case 'workloadPercentageMax':
        return 'maxValue';
      case 'languageToAdd':
        return 'languages';
      case 'publishExactJobLocation':
        return 'publishExactJobLocation';
      case 'originalUrl':
        return 'originalAd';
      case 'jobLocationAddress':
        return 'workPlace';
      case 'requiresReportingPriorToPublication':
        return 'jobNotificationRequired';
      case 'companyLocationUuid':
        return 'companyLocation';
      case 'companyContactUuid':
        return 'responsibleContact';
      case 'positionType':
        return 'position';
      case 'salary':
        return 'salary';
      case 'salaryMin':
      case 'agePreferenceMin':
        return 'minValue';
      case 'salaryMax':
      case 'agePreferenceMax':
        return 'maxValue';
      case 'benefitsText':
        return 'whatAreMyBenefits';
      case 'employerDescriptionText':
        return 'whatMakesTheCompanyTitle';
      case 'customerBranchUuid':
        return 'branch';
      case 'responsibleUserId':
        return 'responsibleEmployee';
      case 'salaryFlexible':
        return 'salaryRangeFromTo';
      case 'workloadFlexible':
        return 'useFromTo';
      default:
        return field;
    }
  });
  return Array.from(new Set(fields));
};

export const getDisplayFieldName = (field: string): string => {
  return getDisplayFieldNames([field])[0];
};
