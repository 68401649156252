import { VacancyEmploymentTypeEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getEmploymentTypeTextOptions = (i18next: I18NextPipe) => {
  return [
    {
      value: VacancyEmploymentTypeEnum.PERMANENT,
      label: i18next.transform('VacancyEmploymentTypeEnum.PERMANENT'),
    },
    {
      value: VacancyEmploymentTypeEnum.TEMPORARY,
      label: i18next.transform('VacancyEmploymentTypeEnum.TEMPORARY'),
    },
  ] as const;
};
