<div class="input-wrap !uj-mb-0 !uj-flex uj-flex-col uj-gap-b">
  <label class="input-label uj-relative uj-flex uj-items-center"
    >{{ "salaryExpectation" | i18next | firstUppercase }}
  </label>
  <div class="input-wrap !uj-mb-0 !uj-flex uj-flex-col uj-gap-c">
    <div class="uj-flex uj-flex-col sm:uj-flex-row uj-gap-c">
      <div class="uj-flex uj-gap-c uj-items-center uj-min-w-[120px]">
        <ui-library-simple-select
          class="uj-grow"
          size="M"
          [options]="salaryUnitOptions"
          [placeholderText]="'month' | i18next"
          design="outline"
          [formControl]="salaryExpectationUnit"
          backgroundColor="white" />
        <ng-container *ngIf="isXsDown$ | async">
          <ng-container *ngTemplateOutlet="salaryPaymentsCount"></ng-container>
        </ng-container>
      </div>
      <div class="uj-flex uj-justify-center uj-flex-1">
        <ui-library-form-group
          class="uj-flex uj-justify-center uj-flex-1"
          [ngClass]="{
            'uj-outline uj-outline-1 uj-outline-offset-2 uj-outline-ui-negative uj-rounded-sm uj-mt-a':
              hintErrorMessages.salary,
          }">
          <ng-container
            *ngIf="!salaryExpectationFlexible.value; else flexibleSalary">
            <input
              class="uj-flex-1 sm:uj-min-w-[96px]"
              ui-library-text-input
              positionInGroup="start"
              focusTracker="salaryExpectation"
              [placeholder]="'enterAmount' | i18next"
              [formControl]="salaryExpectation"
              (keydown)="isValidSalaryInput($event)"
              (paste)="filterClipboardValue($event)"
              type="number" />
          </ng-container>
          <ng-template #flexibleSalary>
            <input
              class="uj-flex-1 sm:uj-min-w-[96px]"
              ui-library-text-input
              positionInGroup="start-seamless"
              focusTracker="salaryExpectationMin"
              [placeholder]="'min' | i18next"
              [formControl]="salaryExpectationMin"
              (keydown)="isValidSalaryInput($event)"
              (paste)="filterClipboardValue($event)"
              type="number" />
            <div
              class="uj-px-d uj-py-c uj-border uj-border-x-0 uj-border-border uj-cursor-default">
              —
            </div>
            <input
              class="uj-flex-1 sm:uj-min-w-[96px]"
              ui-library-text-input
              positionInGroup="center-seamless"
              focusTracker="salaryExpectationMax"
              [placeholder]="'max' | i18next"
              [formControl]="salaryExpectationMax"
              (keydown)="isValidSalaryInput($event)"
              (paste)="filterClipboardValue($event)"
              type="number" />
          </ng-template>
          <ui-library-simple-select
            [options]="salaryCurrencyOptions"
            [placeholderText]="'chf' | i18next"
            [formControl]="salaryExpectationCurrency"
            positionInGroup="end"
            size="M"
            design="outline"
            backgroundColor="white"
            class="uj-w-[88px]" />
          <ng-container *ngIf="!(isXsDown$ | async)">
            <ng-container
              *ngTemplateOutlet="salaryPaymentsCount"></ng-container>
          </ng-container>
        </ui-library-form-group>
      </div>
    </div>
    <unijob-switch
      [formControl]="salaryExpectationFlexible"
      [label]="'salaryRangeFromTo' | i18next"
      round></unijob-switch>
    <div
      class="uj-flex uj-text-ui-negative uj-items-center uj-gap-a"
      *ngIf="hintErrorMessages.salary">
      <unijob-icon
        icon="alert_triangle"
        size="12"></unijob-icon>
      <span class="text-s">{{ hintErrorMessages.salary | i18next }}</span>
    </div>
    <ng-template #salaryPaymentsCount>
      <div
        *ngIf="salaryExpectationUnit.value !== SalaryUnitEnum.YEAR"
        class="sm:uj-px-c sm:uj-py-c sm:uj-h-full sm:uj-py-54 uj-flex uj-items-center">
        x
      </div>
      <ui-library-simple-select
        *ngIf="salaryExpectationUnit.value === SalaryUnitEnum.MONTH"
        [options]="salaryPaymentsPerYearOptions"
        class="uj-w-[88px]"
        size="M"
        placeholderText="12"
        design="outline"
        [formControl]="salaryExpectationPaymentsPerYear"
        backgroundColor="white" />
      <input
        *ngIf="salaryExpectationUnit.value === SalaryUnitEnum.HOUR"
        class="!uj-w-[88px]"
        ui-library-text-input
        focusTracker="salaryExpectationHoursPerWeek"
        placeholder="42"
        [formControl]="salaryExpectationHoursPerWeek"
        type="number" />
    </ng-template>
  </div>
</div>
