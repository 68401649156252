{
  "absencesEnum": {
    "ACCIDENT": "accident",
    "BIRTH_OR_WEDDING_OF_CHILD": "naissance ou mariage d'un enfant",
    "CARING_FOR_SICK_CHILD": "soins d'un enfant malade",
    "DEATH_IN_HOUSEHOLD": "décés dans le ménage",
    "DEATH_OF_CLOSE_RELATIVE": "décés d'un parent proche",
    "FULFILLMENT_OF_LEGAL_OBLIGATIONS": "accomplir des obligations légales",
    "MATERNITY_LEAVE": "congé de matérnité",
    "MILITARY_OR_CIVIL_SERVICE": "service militaire / service civil / défense civile",
    "MILITARY_RECRUITMENT_OR_INSPECTION": "recrutement / inspection militaire",
    "RESIDENCE_MOVING": "déménagememnt",
    "SICKNESS": "maladie",
    "VACATION_PAID": "vacances payées",
    "VACATION_UNPAID": "vacances non payées",
    "WEDDING": "mariage"
  },
  "dayStatusEnum": {
    "APPROVED": "Approuvé",
    "HAS_REPORT": "Heures soumises en tant que document",
    "OPEN": "Ouvert",
    "TO_BE_APPROVED": "Soumis"
  },
  "documentTypesEnum": {
    "AHV": "carte AVS",
    "CORRESPONDENCE": "correspondance",
    "CRIMINAL_RECORD": "extrait du casier judicaire",
    "CV": "CV",
    "DEPT_ENFORCEMENT_REGISTER_REPORT": "extrait du re­gist­re des pour­sui­tes en ligne",
    "EMPLOYER_STATEMENT": "attestations de l'employeur",
    "FAMILY_ALLOWANCES": "documents pour allocations familiales",
    "FOREIGNER_PERMIT": "Livret pour étrangers",
    "HOURS_REPORT": "rapport horaire",
    "INVOICE": "facture",
    "MEDICAL_CERTIFICATE": "certificat médical",
    "OTHER": "autres",
    "PAY_SLIP": "fiche de salaire",
    "RECEIPT": "quittance",
    "WAGE_STATEMENT": "déclaration de salaire",
    "WORK_PERMIT": "permis de travail"
  },
  "DriversLicenseEnum": {
    "A": "A",
    "A1": "A1",
    "B": "B",
    "B1": "B1",
    "BE": "BE",
    "BPT": "BPT",
    "C": "C",
    "C1": "C1",
    "C1E": "C1E",
    "CE": "CE",
    "CZV": "CZV",
    "D": "D",
    "D1": "D1",
    "D1E": "D1E",
    "DE": "DE",
    "F": "F",
    "G": "G",
    "M": "M",
    "NEGATIVE_A": "-A"
  },
  "EmploymentTypeEnum": {
    "PERMANENT": "Emploi permanent",
    "TEMPORARY": "Emploi temporaire"
  },
  "expensesEnum": {
    "ACCOMMODATION": "accommodation",
    "MEAL": "alimentation",
    "OTHER": "autres",
    "RIDE_COST": "transport"
  },
  "LanguageProficiencyEnum": {
    "A1": "A1 – Introductif ou Découverte",
    "A2": "A2 – Intermédiaire ou de Survie",
    "B1": "B1 – Seuil",
    "B2": "B2 – Avancé ou Indépendant",
    "C1": "C1 – Autonome",
    "C2": "C2 – Maîtrise",
    "Advantageous": "Avantageux"
  },
  "ChannelExportUniversaljobSearchPlanEnum": {
    "PUSH": "Push",
    "TARGET": "Target",
    "MAX": "Max"
  },
  "ResidencePermitEnum": {
    "NONE": "None",
    "REPORTING_PROCEDURE": "Procédure de déclaration",
    "B": "B - autorisation de séjour",
    "C": "C - autorisation d’établissement",
    "Ci": "Ci - autorisation de séjour avec activité lucrative pour les internationaux",
    "F": "F - pour étrangers admis provisoirement",
    "G": "G - autorisation frontalière",
    "GMB": "GMB - Confirmation de déclaration des frontaliers FL",
    "L": "L - autorisation de courte durée",
    "N": "N - pour requérants d‘asile",
    "S": "S - protection provisoire aux personnes à protéger"
  },
  "sexEnum": {
    "FEMALE": "Femme",
    "MALE": "Homme",
    "OTHER": "Autre"
  },
  "Salutation": {
    "FEMALE": "Chère",
    "MALE": "Cher",
    "OTHER": "Salut"
  },
  "supportedLanguageEnum": {
    "de": "allemand",
    "en": "anglais",
    "fr": "français",
    "it": "italien"
  },
  "VacancyArchiveReasonEnum": {
    "COMPANY_FOUND_PLACEMENT": "Placé par l'entreprise elle-même",
    "COMPANY_RECALLED": "Plus nécessaire pour l'entreprise",
    "ENOUGH_APPLICANTS_RECEIVED": "Assez de candidats reçus",
    "FALSE_ACTIVATION_OR_DUPLICATE": "Activation erronée / Dupliqué",
    "OTHER_FOUND_PLACEMENT": "Placé par une autre agence de placement",
    "WE_FOUND_PLACEMENT": "Placé avec succès par nous",
    "OTHER": "Autre"
  },
  "VacancyEmploymentTypeEnum": {
    "APPRENTICESHIP": "Formation",
    "FREELANCE": "Travailleur indépendant",
    "FULL_TIME": "À plein temps",
    "INTERNSHIP": "Stage",
    "PART_TIME": "À temps partiel",
    "PER_DAY": "À la journée",
    "PERMANENT": "À durée indéterminée",
    "TEMPORARY": "Temporaire",
    "VOLUNTEER": "Volontaire"
  },
  "vacancyStatusEnum": {
    "ARCHIVED": "Archivé",
    "DELETED": "Supprimé",
    "DRAFT": "Brouillon",
    "LIVE": "Actif",
    "SCHEDULED": "Prévu"
  },
  "WorkArrangementEnum": {
    "FIELD_SERVICE": "Service externe",
    "ON_CALL_DUTY": "Service de garde",
    "ON_SITE": "Sur site",
    "POOL": "Pool",
    "REMOTE": "Télétravail",
    "SHIFT_WORK": "Travail posté"
  },
  "WorkAvailabilityEnum": {
    "BLACKLISTED": "Liste noire",
    "IN_EMPLOYMENT": "En mission",
    "NOT_OPEN_TO_WORK": "Non disponible pour le travail / Passif",
    "OPEN_TO_WORK": "Disponible pour le travail",
    "PLACED": "Placé"
  },
  "EmploymentTermEnum": {
    "FIXED_TERM": "déterminée",
    "FULL_TERM": "indéterminée"
  },
  "ReligiousDenominationEnum": {
    "BUDDHIST": "Bouddhiste",
    "EVANGELICAL_REFORMED": "Évangélique réformé",
    "HINDU": "Hindou",
    "JEWISH": "Juif",
    "MUSLIM": "Musulman",
    "NON_RELIGIOUS": "Non religieux",
    "ROMAN_CATHOLIC": "Catholique romain"
  },
  "MaritalStatusEnum": {
    "DIVORCED": "Divorcé",
    "MARRIED": "Marié",
    "SINGLE": "Célibataire",
    "WIDOWED": "Veuf/veuve",
    "IN_REGISTERED_PARTNERSHIP": "En partenariat enregistré",
    "DISSOLVED_PARTNERSHIP": "Partenariat dissous"
  },
  "ContactPhoneTypeEnum": {
    "PRIVATE": "Privé",
    "BUSINESS": "Professionnel"
  },
  "ContactEmailTypeEnum": {
    "PRIVATE": "Privé",
    "BUSINESS": "Professionnel"
  },
  "TalentSourceTypeEnum": {
    "WALK_IN": "Walk-in",
    "EMAIL": "E-mail",
    "PHONE": "Téléphone",
    "SOCIAL_MEDIA": "Réseaux sociaux",
    "JOB_APPLICATION": "Candidature"
  },
  "TalentSourcePlatformEnum": {
    "UNIVERSALJOB": "universalJob.ch",
    "JOBS_CH": "Jobs.ch",
    "LINKED_IN": "LinkedIn",
    "JOB_SHOT": "JobShot"
  },
  "MatchProcessStepEnum": {
    "ENTRY": "Entrée",
    "PREPARATION": "Préparation",
    "RECOMMENDATION": "Recommandation",
    "EXAMINATION": "Examen",
    "CONCLUSION": "Conclusion"
  },
  "MatchStatusEnum": {
    "NEW": "Non lu",
    "ACTIVE": "Actif",
    "ARCHIVED": "Archivé"
  },
  "StaffRoleEnum": {
    "BRANCH_LEADER": "Chef de succursale",
    "REGIONAL_LEADER": "Chef régional",
    "ASSISTANT": "Assistant",
    "CONSULTANT": "Consultant",
    "MARKETING": "Marketing",
    "ADMIN": "Admin"
  }
}
