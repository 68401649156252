import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InputTextComponent, SheetService } from '@intemp/unijob-ui';
import { debounceTime, Subject } from 'rxjs';
import { UnibaseCompaniesService } from '../../../../../models/unibase/unibase-companies/unibase-companies.service';
import { UnibaseCompanyNormalized } from '../../../../../graphql/generated';
import { randomId } from '../../../../helpers/functions/randomId';
import { I18NextPipe } from 'angular-i18next';
import { takeUntil } from 'rxjs/operators';
import { CompanyLocationOption } from '../vacancy-company-location-select.component';
import { ToastService } from '@intemp/unijob-ui2';

@Component({
  selector: 'app-vacancy-unibase-company-import',
  templateUrl: './unibase-company-import.component.html',
})
export class VacancyUnibaseCompanyImportComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();

  @Input() sheetId = randomId();
  @Output() importComplete = new EventEmitter<CompanyLocationOption>();
  @Output() sheetClosed = new EventEmitter<string>();

  @ViewChild(InputTextComponent, { static: false })
  searchElement?: InputTextComponent;

  public currentUnibaseCompanies: UnibaseCompanyNormalized[] = [];
  public currentUnibaseCompanyOptions: { value: string }[] = [];
  public currentUnibaseCompaniesAlreadyImported: UnibaseCompanyNormalized[] =
    [];
  private searchFieldChangeEmitter = new Subject<string>();
  public selectedCompanyValue = '';

  public isLoading = false;
  public searchTerm = '';
  public loadingSearchTerm = '';

  constructor(
    public sheetService: SheetService,
    private unibaseCompaniesService: UnibaseCompaniesService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe,
  ) {}

  setSearchTerm(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.searchCompanies(searchTerm);
  }

  focusSearchField(): void {
    setTimeout(() => {
      // Don't know why delay is required, could be related to animation?
      this.searchElement?.focus();
    }, 300);
  }

  ngOnInit(): void {
    this.searchFieldChangeEmitter
      .pipe(takeUntil(this.destroyed$), debounceTime(300))
      .subscribe((searchTerm) => {
        this.searchCompanies(searchTerm);
      });
  }

  searchFieldInputChanged(searchTerm: string): void {
    if (this.loadingSearchTerm === searchTerm) {
      return;
    }
    if (searchTerm.length > 0) {
      this.searchFieldChangeEmitter.next(searchTerm);
    } else {
      this.currentUnibaseCompanies = [];
      this.currentUnibaseCompaniesAlreadyImported = [];
      this.currentUnibaseCompanyOptions = [];
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
  }

  async closeSheet(): Promise<void> {
    this.sheetService.close(this.sheetId);
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }

  searchCompanies(searchTerm: string) {
    this.isLoading = true;
    this.loadingSearchTerm = searchTerm;

    this.unibaseCompaniesService
      .unibaseCompaniesQuery({
        criteria: {
          search: searchTerm,
        },
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.currentUnibaseCompanies =
          result.companies?.filter((company) => {
            return !company.importedCompanyUuid;
          }) || [];
        this.currentUnibaseCompaniesAlreadyImported =
          result.companies?.filter((company) => {
            return !!company.importedCompanyUuid;
          }) || [];
        this.currentUnibaseCompanyOptions = this.currentUnibaseCompanies.map(
          (company) => {
            return {
              value: company.CMPIDs.join(''),
            };
          },
        );
        this.isLoading = false;
      });
  }

  getCompanyByValue(value: string) {
    return this.currentUnibaseCompanies.find((company) => {
      return company.CMPIDs.join('') === value;
    });
  }

  handleImportCompanyClick(): void {
    if (!this.selectedCompanyValue) {
      return;
    }
    const company = this.getCompanyByValue(this.selectedCompanyValue);
    if (!company) {
      console.error(
        'Internal error in company-import component: Company not found by ID ' +
          this.selectedCompanyValue,
      );
      return;
    }
    this.isLoading = true;
    const importCMPID = company.CMPIDs[0];
    this.unibaseCompaniesService
      .unibaseCompanyImport(importCMPID)
      .subscribe((importedCompany) => {
        this.isLoading = false;
        if (!importedCompany) {
          this.toastService.makeToast({
            type: 'ERROR',
            message: this.i18nPipe.transform('failedToImportCompany'),
          });
          return;
        }
        const companyLocation = importedCompany.locations.find((l) =>
          l.unibaseCompanyNormalized?.CMPIDs.includes(importCMPID),
        );
        if (!companyLocation) {
          this.toastService.makeToast({
            type: 'ERROR',
            message: this.i18nPipe.transform('serverError'),
          });
          return;
        }
        const companyLocationOption: CompanyLocationOption = {
          ...companyLocation,
          companyName: company.companyName || company.CMPIDs.join(','),
        };
        this.importComplete.emit(companyLocationOption);
        this.selectedCompanyValue = '';
      });
  }
}
