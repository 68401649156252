import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  // this combines all levels of route data into one object
  // route data is set in the routing module / route config
  routeData$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => {
      let route = this.route;
      const routeData: Record<string, any> = {};
      while (route.firstChild) {
        route = route.firstChild;
        const data = route.snapshot.data;
        Object.assign(routeData, data);
      }
      return routeData;
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}
}
