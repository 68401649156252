import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { MaritalStatus } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getMaritalStatusOptions = (
  i18next: I18NextPipe,
): SelectOption<MaritalStatus>[] => {
  return [
    {
      value: MaritalStatus.SINGLE,
      name: i18next.transform('MaritalStatusEnum.SINGLE'),
    },
    {
      value: MaritalStatus.MARRIED,
      name: i18next.transform('MaritalStatusEnum.MARRIED'),
    },
    {
      value: MaritalStatus.DIVORCED,
      name: i18next.transform('MaritalStatusEnum.DIVORCED'),
    },
    {
      value: MaritalStatus.WIDOWED,
      name: i18next.transform('MaritalStatusEnum.WIDOWED'),
    },
    {
      value: MaritalStatus.IN_REGISTERED_PARTNERSHIP,
      name: i18next.transform('MaritalStatusEnum.IN_REGISTERED_PARTNERSHIP'),
    },
    {
      value: MaritalStatus.DISSOLVED_PARTNERSHIP,
      name: i18next.transform('MaritalStatusEnum.DISSOLVED_PARTNERSHIP'),
    },
  ];
};
