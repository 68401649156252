import {
  AfterViewInit,
  Component,
  computed,
  effect,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  GlobalRoleEnum,
  StaffRoleEnum,
  WorkAvailabilityEnum,
} from '../../../../graphql/generated';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { TalentFormEditService } from '../services/talentFormEdit.service';
import { FocusTrackerDirective } from '../../vacancy-detail/focusTracker.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  FormFieldComponent,
  ModalComponent,
  ModalService,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';
import {
  CheckboxModule,
  DatePickerModule,
  InfoBoxModule,
  InputTextareaModule,
  InputTextModule,
  randomId,
} from '@intemp/unijob-ui';
import { getAvailabilityOptions } from '../helpers/getAvailabilityOptions';
import { debounceTime } from 'rxjs';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../models/shared/user/user.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'app-talent-availability',
  templateUrl: './talent-availability.component.html',
  imports: [
    ReactiveFormsModule,
    I18NextModule,
    SharedDefaultModule,
    SimpleSelectComponent,
    InfoBoxModule,
    DatePickerModule,
    InputTextareaModule,
    CheckboxModule,
    InputTextModule,
    ModalComponent,
    FormFieldComponent,
    TextInputComponent,
    ButtonOutlineComponent,
    ButtonSolidComponent,
  ],
  providers: [],
})
export class TalentAvailabilityComponent implements AfterViewInit {
  i18next = inject(I18NextPipe);
  formService = inject(TalentFormEditService);
  userService = inject(UserService);
  modals = inject(ModalService);

  availabilityDataForm = this.formService.availability;
  blacklistConfirmationModalId = 'blacklistConfirmationModal' + randomId();

  readyUser = toSignal(this.userService.readyUser$);
  canRemoveBlacklist = computed(() => {
    const user = this.readyUser();
    if (!user) return false;
    return (
      user.organisationUnit?.globalRoles?.includes(
        GlobalRoleEnum.SUPER_ADMIN,
      ) ||
      user.organisationUnit?.branches?.some((branch) =>
        branch.staffRoles?.includes(StaffRoleEnum.REGIONAL_LEADER),
      )
    );
  });

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  availabilityOptions = getAvailabilityOptions();
  componentId = randomId();

  constructor() {
    effect(() => {
      if (
        !this.canRemoveBlacklist() &&
        this.availabilityDataForm.value.workAvailability ===
          WorkAvailabilityEnum.BLACKLISTED
      ) {
        this.availabilityDataForm.disable();
      }
    });
  }

  ngAfterViewInit() {
    this.listenToFocusTrackerChange();
    this.subscribeAvailabilityFormChanges();
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }

  subscribeAvailabilityFormChanges() {
    this.availabilityDataForm.valueChanges
      .pipe(debounceTime(saveDebounceMs))
      .subscribe((value) => {
        getObjectKeys(value).forEach((key) => {
          const control = this.availabilityDataForm.controls[key];
          if (!control.dirty) return;

          if (
            key === 'workAvailability' &&
            control.value === WorkAvailabilityEnum.BLACKLISTED
          ) {
            this.modals.open(this.blacklistConfirmationModalId);
            return;
          } else if (key === 'blackListedReason') {
            return;
          }

          this.formService.submitFieldUpdate(
            key,
            this.formService.transformControlValue(control, key),
          );
          control.markAsPristine();
        });
      });
  }

  confirmBlackListing() {
    this.modals.close(this.blacklistConfirmationModalId);
    this.formService.submitFieldUpdate(
      'workAvailability',
      this.formService.availability.controls.workAvailability.value,
    );
    this.formService.submitFieldUpdate(
      'blackListedReason',
      this.formService.availability.controls.blackListedReason.value,
    );
  }

  cancelChanges() {
    this.modals.close(this.blacklistConfirmationModalId);

    this.availabilityDataForm.controls.workAvailability.patchValue(
      this.formService.talent()?.workAvailability || null,
      { emitEvent: false },
    );
  }

  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;
  protected readonly environment = environment;
}
