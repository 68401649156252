import { Component, inject, signal } from '@angular/core';
import {
  ContextMenuModule,
  DatePickerModule,
  IconModule,
  InputTextModule,
  OverflowGradientModule,
  randomId,
  SheetModule,
  SwitchModule,
  TabModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { VacanciesStatusIndicatorModule } from '../../../vacancies-status-indicator/vacancies-status-indicator.module';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { UserAvatarModule } from '../../../user-avatar/user-avatar.module';
import { LetDirective } from '@ngrx/component';
import { UserInfoContextMenuModule } from '../../../user/user-info-context-menu/user-Info-context-menu.module';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { ArrayActionEnum } from '../../../../../graphql/generated';
import { TalentAddressesAddressComponent } from './talent-addresses-address/talent-addresses-address.component';
import { AddressFormGroup } from '../../talent-sheet.types';
import { AvatarComponent, ButtonOutlineComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-addresses',
  templateUrl: './talent-addresses.component.html',
  imports: [
    SheetModule,
    AsyncPipe,
    AvatarComponent,
    I18NextModule,
    IconModule,
    NgIf,
    OverflowGradientModule,
    SharedDefaultModule,
    TabModule,
    UserAvatarModule,
    VacanciesStatusIndicatorModule,
    LetDirective,
    UserInfoContextMenuModule,
    ContextMenuModule,
    FormsModule,
    ReactiveFormsModule,
    UjSelectInputModule,
    InputTextModule,
    DatePickerModule,
    SwitchModule,
    TalentAddressesAddressComponent,
    ButtonOutlineComponent,
  ],
})
export class TalentAddressesComponent {
  formService = inject(TalentFormEditService);
  isInEditMode = signal(false);

  addAddress() {
    const newAddress = new FormGroup<AddressFormGroup>({
      uuid: new FormControl<string>(randomId()),
      type: new FormControl<ArrayActionEnum>(ArrayActionEnum.ADDED),
      streetAndNumber: new FormControl<string | null>('', [
        Validators.required,
      ]),
      zip: new FormControl<string | null>('', [Validators.required]),
      location: new FormControl<string | null>('', [Validators.required]),
      country: new FormControl<string | null>('', [Validators.required]),
      isNewEntry: new FormControl<boolean>(true),
    });
    this.formService.addressesFormArray.push(newAddress);
    this.isInEditMode.set(true);
  }

  trackByUUID(index: number, item: FormGroup<AddressFormGroup>) {
    return item.value.uuid;
  }

  toggleAddressIsInEditMode($event: boolean) {
    this.isInEditMode.set($event);
  }
}
