import { Component, computed, input } from '@angular/core';
import { TalentSheetService } from '../services/talent-sheet.service';
import {
  TalentFragment,
  WorkAvailabilityEnum,
} from '../../../../graphql/generated';
import { I18NextModule } from 'angular-i18next';
import { UserService } from '../../../../models/shared/user/user.service';
import { TalentTabEnum } from '../../../../pages/talents/talent.types';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../global-sheets/global-sheets.service';
import { TabModule } from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { CurrentUserAvatarComponent } from '../../../components/current-user-avatar/current-user-avatar.component';
import { TalentStatusIndicatorComponent } from '../talent-status-indicator/talent-status-indicator.component';
import {
  ButtonLinkComponent,
  DotComponent,
  IconTagComponent,
} from '@intemp/unijob-ui2';
import { getLastEditTime } from '../../../helpers/functions/getLastEditTime';

@Component({
  standalone: true,
  selector: 'app-talent-sheet-header',
  templateUrl: './talent-sheet-header.component.html',
  imports: [
    TabModule,
    I18NextModule,
    SharedDefaultModule,
    CurrentUserAvatarComponent,
    TalentStatusIndicatorComponent,
    DotComponent,
    ButtonLinkComponent,
    IconTagComponent,
  ],
  providers: [],
})
export class TalentSheetHeaderComponent {
  talent = input.required<TalentFragment>();

  readonly inactivePages = computed(() =>
    this.talent().pages.filter((page) => !page.active),
  );

  lastUpdatedAt = computed(() => {
    return getLastEditTime(this.talent().updatedAt ?? new Date().toISOString());
  });

  get activeTab(): TalentTabEnum {
    const sheet = this.globalSheetsService.openSheets.find(
      (s) => s.uuid === this.talent().uuid,
    );
    return sheet?.action as TalentTabEnum;
  }

  constructor(
    public service: TalentSheetService,
    public userService: UserService,
    private globalSheetsService: GlobalSheetsService,
  ) {}

  openChangelogSheet() {
    //TODO: open vacancy-changelog sheet
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.TALENT_CHANGELOG,
      uuid: this.talent().uuid,
    });
  }

  switchActiveTabTo(tab: TalentTabEnum) {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.TALENT_EDIT,
        uuid: this.talent().uuid,
      },
      'action',
      tab.toLowerCase(),
    );
  }

  protected readonly tabEnum = TalentTabEnum;
  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;
}
