<div class="uj-grow uj-flex uj-items-center uj-gap-d uj-mr-0 lg:uj-mr-d">
  <ng-container *ngTemplateOutlet="firstItem.avatarTemplateRef"></ng-container>
  <div class="uj-flex-grow uj-min-w-0">
    <div class="text-m-b uj-truncate">{{ firstItem.title }}</div>
    <div class="text-m uj-truncate">{{ firstItem.text }}</div>
  </div>

  <!-- Desktop actions -->
  <ng-container *ngIf="firstItem.actions">
    <div class="actions-wrapper uj-hidden lg:uj-flex">
      <button
        *ngFor="let action of firstItem.actions"
        ui-library-button-text
        type="button"
        [iconOnly]="action.icon"
        [tooltip]="action.labelTranslationKey | i18next"
        (click)="$event.stopPropagation(); action.onClick()"
        color="dark"></button>
    </div>
    <div
      class="actions-divider uj-h-e uj-w-[1px] uj-bg-border uj-mx-b uj-hidden lg:uj-block"></div>
  </ng-container>

  <!-- mobile actions -->
  <div
    class="uj-block lg:uj-hidden"
    *ngIf="firstItem.actions">
    <button
      ui-library-button-text
      *ngIf="moreActionsContextMenu"
      type="button"
      iconOnly="menu_dots_vertical"
      [contextMenu]="moreActionsContextMenu"
      (click)="$event.stopPropagation()"></button>
  </div>
</div>
<ng-template #moreActionsContextMenu>
  <button
    *ngFor="let action of firstItem.actions"
    ui-library-button-text
    type="button"
    color="dark"
    [iconLeft]="action.icon"
    (click)="$event.stopPropagation(); action.onClick()">
    {{ action.labelTranslationKey | i18next }}
  </button>
  <!-- Toggle options currently not implemented
  <div class="uj-mx-d uj-border-t uj-border-border"></div>
  <button
    ui-library-button-text
    type="button"
    color="dark"
    iconLeft="flexibility_arrows"
    (click)="toggleOptions()">
    {{ "changeSelection" | i18next }}
  </button> -->
</ng-template>
