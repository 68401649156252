import { inject, Injectable } from '@angular/core';
import { ConsultantService } from '../../../../core/services/consultant.service';
import { UserService } from '../../../../models/shared/user/user.service';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import {
  TalentFragment,
  TalentHistoryEntryFragment,
  TalentRevertChangesGQL,
  TalentUpdateInput,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { talentChangelogFieldTypesConstants } from '../constants/talent-changelog-field-types.constants';
import { TalentsService } from '../../../../core/services/talents/talents.service';
import { shareReplay } from 'rxjs/operators';
import { ChangelogTalentHelperService } from './changelog-talent-helper.service';
import { CountriesService } from '../../../../core/services/countries.service';
import i18next from 'i18next';
import { getFirstNonUuidFieldKey } from '@libs/shared/helpers/getFirstNonUuidFieldKey';

@Injectable()
export class TalentChangelogService {
  talentRevertChangesGQL = inject(TalentRevertChangesGQL);
  consultantService = inject(ConsultantService);
  userService = inject(UserService);
  talentsService = inject(TalentsService);
  helperService = inject(ChangelogTalentHelperService);
  countriesService = inject(CountriesService);

  consultants$ = this.consultantService.consultantsById$;

  talentId$ = new BehaviorSubject<string>('');

  talent: TalentFragment | undefined;

  consultantsAndCurrentUser$ = combineLatest([
    this.consultantService.consultantsById$,
    this.userService.readyUser$,
  ]);

  reFetchHistory$ = new BehaviorSubject<void>(undefined);

  talentHistoryList$ = this.reFetchHistory$.pipe(
    switchMap(() =>
      combineLatest([
        this.talentsService.getTalent$(this.talentId$.value),
        this.talentsService.getTalentHistory$(this.talentId$.value),
      ]).pipe(
        map(([talent, historyList]) => {
          if (!talent || !historyList) return [];
          return historyList.map((entry) => {
            return this.prepareTalentHistory(entry, talent);
          });
        }),
      ),
    ),
    shareReplay(1),
  );

  revertChanges(historyId: string) {
    this.talentRevertChangesGQL
      .mutate({ historyId })
      .subscribe(() => this.reFetchHistory$.next());
  }

  prepareTalentHistory(
    entry: TalentHistoryEntryFragment,
    talent: TalentFragment,
  ): PreparedTalentChangeLog {
    if (entry.customEntry) {
      return this.customEntryDisplayFn(entry);
    }
    return this.resolveMutationInput(entry, talent);
  }

  resolveMutationInput(
    entry: TalentHistoryEntryFragment,
    talent: TalentFragment,
  ): PreparedTalentChangeLog {
    const input = entry.mutation?.input as TalentUpdateInput | undefined;
    if (!input) {
      return {
        ...entry,
      };
    }

    const fieldName = getFirstNonUuidFieldKey(input);
    const fieldType = talentChangelogFieldTypesConstants[fieldName];

    if (!fieldType) {
      console.error(
        fieldName + ' not found in field talentChangelogFieldTypesConstants',
      );
      return {
        ...entry,
      };
    }

    const fieldHandler = this.helperService.talentFields[fieldName];

    if (!fieldHandler) {
      throw new Error(fieldName + ' not found in field talentFields');
    }

    return fieldHandler(
      entry,
      //@ts-ignore (not sure why this ts-ignore is necessary)
      fieldName,
      talent,
      input,
      this.countriesService,
    );
  }

  customEntryDisplayFn(
    entry: TalentHistoryEntryFragment,
  ): PreparedTalentChangeLog {
    const translationKey =
      'talentChangelogEntry.customEntry.' + entry.customEntry?.type;
    const record: PreparedTalentChangeLog = {
      ...entry,
      fieldLabel: i18next.t(translationKey),
    };

    if (!entry.customEntry?.data) return record;

    if ('sentTo' in entry.customEntry.data) {
      record.fieldLabel = i18next.t(translationKey, {
        sentTo: entry.customEntry.data.sentTo,
      });
    } else {
      record.fieldLabel = i18next.t(
        'Unhandled custom entry for type ' + entry.customEntry.type,
      );
    }
    return record;
  }
}
