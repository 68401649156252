<div class="uj-flex uj-items-center">
  <div
    *ngFor="let status of talentStatus(); let i = index"
    class="uj-flex uj-gap-b uj-items-center">
    <ng-container
      *ngTemplateOutlet="DataPrivacyPolicyNotAccepted"></ng-container>
    <span
      *ngIf="isPositionedInHeader() || (!isPositionedInHeader() && i !== 0)"
      class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
  </div>
  <span
    class="text-s"
    *ngIf="!isPositionedInHeader() && !talentStatus().length">
    —
  </span>
</div>

<ng-template #DataPrivacyPolicyNotAccepted>
  @if (isPositionedInHeader()) {
    <span
      ui-library-dot
      size="S"
      [color]="'negative'">
    </span>
  } @else {
    <span
      class="!uj-p-0"
      ui-library-tag
      size="S"
      color="negative">
      <unijob-icon
        [icon]="'close'"
        size="12">
      </unijob-icon>
    </span>
  }
  <span class="uj-text-text text-s-b">{{
    "dataPrivacyPolicyNotAccepted" | i18next
  }}</span>
</ng-template>
