<unijob-sheet
  [id]="sheetId"
  size="s"
  (sheetClosed)="handleSheetClosed(sheetId)">
  <ng-container slot="header">
    <h2 class="title-m">{{ "importContact" | i18next }}</h2>
  </ng-container>
  <ng-container slot="content">
    <div class="uj-flex uj-flex-col uj-gap-e">
      <div
        class="unijob-notification-inline uj-bg-ui-info-light uj-p-d uj-text-text-m/[20px] uj-rounded-md">
        <div class="uj-flex">
          <unijob-icon
            icon="info_circle"
            size="20"
            class="uj-mr-c uj-text-text-secondary-light"></unijob-icon>
          <p
            class="uj-text-text"
            [innerHTML]="'importContactInstruction' | i18next"></p>
        </div>
      </div>
      <ui-library-input-container
        isClearable
        icon="search">
        <input
          fullWidth
          [(ngModel)]="searchTerm"
          (ngModelChange)="searchFieldInputChanged($event)"
          name="search-unibase-contact"
          ui-library-text-input
          focusTracker="firstName"
          [placeholder]="'searchCompanyContactPlaceholder' | i18next"
          type="text" />
      </ui-library-input-container>
      <div class="uj-flex uj-flex-col uj-gap-c uj-relative">
        <div
          *ngIf="isLoading"
          class="uj-flex uj-justify-center uj-py-f uj-absolute uj-w-full">
          <app-loading-spinner position="static"></app-loading-spinner>
        </div>
        <div
          *ngIf="
            loadingSearchTerm &&
            !isLoading &&
            searchTerm.length > 2 &&
            currentUnibaseContacts.length === 0
          "
          class="uj-rounded-sm uj-flex uj-flex-row uj-gap-d uj-p-b uj-items-center">
          <ui-library-avatar
            content="letters"
            letters="🙈"
            size="s"
            shape="round"
            theme="outline"
            tagType="tagAvatar"
            [interactive]="false"
            backgroundColor="white" />
          <div class="uj-flex uj-flex-col">
            <p class="uj-text-text uj-font-bold uj-text-text-m/[20px]">
              {{ "noContactFound" | i18next }}
            </p>
            <p class="uj-text-text uj-text-text-m/[20px]">
              {{ "checkYourSearchInput" | i18next }}
            </p>
          </div>
        </div>
        <unijob-radio-button
          [options]="currentUnibaseContactOptions"
          [optionTemplate]="label"
          [(ngModel)]="selectedContactValue">
          <ng-template
            #label
            let-option="option"
            let-index="index">
            <div
              class="uj-rounded-sm uj-flex uj-flex-row uj-gap-d uj-p-b uj-items-center uj-ml-d"
              [ngClass]="{
                'uj-opacity-50': isLoading,
              }">
              <ui-library-avatar
                content="icon"
                icon="user"
                size="s"
                shape="round"
                theme="outline"
                tagType="tagAvatar"
                [interactive]="false"
                backgroundColor="white" />
              <div class="uj-flex uj-flex-col">
                <p
                  class="uj-text-text uj-font-bold uj-text-text-m/[20px]"
                  [ngClass]="{
                    'uj-text-text-accent':
                      currentUnibaseContacts[index]?.CSTIDs?.join() ===
                      selectedContactValue,
                  }"
                  [innerHTML]="
                    currentUnibaseContacts[index]?.firstName +
                      ' ' +
                      currentUnibaseContacts[index]?.lastName
                      | highlightSearch: searchTerm
                  "></p>
                <p
                  class="uj-text-text uj-text-text-m/[20px]"
                  [innerHTML]="
                    ((currentUnibaseContacts[index]?.email | noZero) ??
                      (currentUnibaseContacts[index]?.phoneFix | noZero) ??
                      (currentUnibaseContacts[index]?.phoneMobile | noZero) ??
                      '' | noZero) || '' | highlightSearch: searchTerm
                  "></p>
              </div>
            </div>
          </ng-template>
        </unijob-radio-button>
        <div *ngIf="currentUnibaseContactsAlreadyImported.length > 0">
          <span class="unijob-divider undefined border-color-grey">
            <span class="caption uj-text-text-m/[20px] uj-text-text-grey">
              {{ "alreadyImported" | i18next }}
            </span>
          </span>
        </div>
        <div
          *ngFor="let contact of currentUnibaseContactsAlreadyImported"
          class="uj-opacity-50">
          <div
            class="uj-rounded-sm uj-flex uj-flex-row uj-gap-d uj-p-b uj-items-center uj-ml-d"
            [ngClass]="{
              'uj-opacity-50': isLoading,
            }">
            <ui-library-avatar
              content="icon"
              icon="user"
              size="s"
              shape="round"
              theme="outline"
              tagType="tagAvatar"
              [interactive]="false"
              backgroundColor="white" />
            <div class="uj-flex uj-flex-col">
              <p
                class="uj-text-text uj-font-bold uj-text-text-m/[20px]"
                [ngClass]="{
                  'uj-text-text-accent':
                    contact?.CSTIDs?.join() === selectedContactValue,
                }"
                [innerHTML]="
                  contact?.firstName + ' ' + contact?.lastName
                    | highlightSearch: searchTerm
                "></p>
              <p
                class="uj-text-text uj-text-text-m/[20px]"
                [innerHTML]="
                  ((contact?.email | noZero) ??
                    (contact?.phoneFix | noZero) ??
                    (contact?.phoneMobile | noZero) ??
                    '' | noZero) || '' | highlightSearch: searchTerm
                "></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <button
      class="uj-w-full"
      ui-library-button-solid
      type="button"
      iconLeft="download_arrow"
      [disabled]="isLoading || !selectedContactValue"
      (click)="handleImportContactClick()">
      {{ "import" | i18next | firstUppercase }}
    </button>
  </ng-container>
</unijob-sheet>
