import { Component, computed, ElementRef, inject, input } from '@angular/core';
import {
  MatchFragment,
  WorkAvailabilityEnum,
} from '../../../../../graphql/generated';
import { intervalToDuration } from 'date-fns';
import { getDuration } from '../../../../helpers/functions/getDuration';
import {
  AvatarComponent,
  ButtonTextComponent,
  CardComponent,
  DotComponent,
  FormFieldComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../../global-sheets/global-sheets.service';
import { TooltipModule } from '@intemp/unijob-ui';

@Component({
  standalone: true,
  selector: 'app-match-talent-card',
  templateUrl: './match-talent-card.component.html',
  imports: [
    CardComponent,
    AvatarComponent,
    I18NextModule,
    SharedDefaultModule,
    DotComponent,
    ButtonTextComponent,
    TooltipModule,
    FormFieldComponent,
  ],
  providers: [],
})
export class MatchTalentCardComponent {
  globalSheetsService = inject(GlobalSheetsService);
  match = input.required<MatchFragment>();
  constructor(public hostElement: ElementRef) {}
  availableForWorkAt = computed(() => {
    const availableForWorkAt = this.match().talent.availableForWorkAt;
    if (!availableForWorkAt) return;

    const date = new Date(availableForWorkAt);
    const now = new Date();
    const durationSincePublished = intervalToDuration({
      start: date,
      end: now,
    });
    const { duration, durationUnit } = getDuration(durationSincePublished);

    return {
      date,
      duration,
      durationUnit,
    };
  });

  openTalent(uuid: string) {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.TALENT_EDIT,
      uuid: uuid,
      action: 'profile',
    });
  }

  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;

  openTalentPreview(uuid: string) {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.MATCH_EDIT,
        uuid: this.match().uuid,
      },
      'overlayAction',
      'active',
    );
  }
}
