import { Injectable } from '@angular/core';
import { SheetService } from '@intemp/unijob-ui';
import { BehaviorSubject, switchMap } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { GraphQLService } from '../../../../core/services/graphql.service';
import { TalentSubscribeGQL } from '../../../../graphql/generated';
import { rxFilterIsNotEmpty } from '../../../helpers/functions/rxFilterIsNotEmpty';
import { TalentFormEditService } from './talentFormEdit.service';

@Injectable()
export class TalentSheetService {
  talentId$ = new BehaviorSubject<string>('');

  onScrollEventName = 'talentSheetScroll';

  talent$ = this.talentId$.pipe(
    switchMap((uuid) => {
      return this.talentSubscribeGQL
        .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
        .pipe(
          map((res) => {
            return res.data?.talentSubscribe;
          }),
          catchError((err) => {
            this.graphQLService.handleError(err);
            return [];
          }),
        );
    }),
    shareReplay(1),
  );

  constructor(
    private sheetService: SheetService,
    private talentSubscribeGQL: TalentSubscribeGQL,
    private graphQLService: GraphQLService,
    private formService: TalentFormEditService,
  ) {}

  //TODO Check out why this is needed and look if we can do it in a more "angular" way.
  triggerAnchorScroll() {
    window.dispatchEvent(new CustomEvent(this.onScrollEventName));
  }

  open(sheetId: string): void {
    this.sheetService.open(sheetId);
  }

  subscribeTalent() {
    this.talent$.pipe(rxFilterIsNotEmpty()).subscribe((talent) => {
      this.formService.patchForm(talent);
    });
  }
}
