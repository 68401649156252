<unijob-sheet
  [id]="sheetId()"
  size="m"
  (sheetScrolled)="service.triggerAnchorScroll()"
  (sheetClosed)="handleSheetClosed(sheetId())"
  contentFlex>
  <ng-container slot="overlay">
    @if (openSheetOverlayAction()) {
      @if (talent(); as talent) {
        <app-talent-document-preview
          class="uj-relative uj-flex uj-items-stretch uj-overflow-hidden uj-grow"
          [talent]="talent"
          [mode]="openSheetOverlayAction()"
          [uuid]="openSheetOverlayUuid()" />
      } @else {
        <app-loading-spinner></app-loading-spinner>
      }
    }
  </ng-container>
  <ng-container slot="nav">
    @if (openSheetOverlayAction()) {
      <button
        ui-library-button-solid
        iconOnly="close_preview_sidebar_4"
        corner="round"
        (click)="this.closePreview()"></button>
    } @else {
      <button
        ui-library-button-outline
        iconOnly="open_preview_sidebar_4"
        corner="round"
        (click)="this.openPreview()"></button>
    }
  </ng-container>
  <ng-container slot="header">
    <app-match-sheet-header
      *ngIf="service.match$ | async as match; else loading"
      [match]="match"></app-match-sheet-header>
  </ng-container>
  <ng-container slot="content">
    <ng-container
      *ngrxLet="
        {
          consultants: consultantService.consultantUsers$,
          match: service.match$,
        } as data;
        suspenseTpl: loading
      ">
      <div class="uj-flex uj-flex-col uj-gap-f uj-w-full">
        <app-match-responsibility
          [allConsultants]="data.consultants"
          [consultantIdControl]="
            formService.responsibleUserId
          "></app-match-responsibility>

        <ui-library-accordion
          id="matchDetailsAccordion"
          class="uj-block uj-w-full uj-mb-g"
          type="left-outside"
          [label]="'matchDetails' | i18next"
          [open]="true"
          [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
          <app-match-detail />
        </ui-library-accordion>
      </div>
    </ng-container>
  </ng-container>
</unijob-sheet>

<ng-template #loading>
  <div><app-loading-spinner></app-loading-spinner></div>
</ng-template>
