<div class="uj-flex uj-flex-col uj-gap-b">
  <label class="uj-block text-m-b uj-relative">{{
    "dataPrivacyPolicy" | i18next | firstUppercase
  }}</label>

  <unijob-info-box
    [type]="
      service.hasNoEmail()
        ? 'ERROR'
        : service.hasAcceptedPrivacyPolicy()
          ? 'SUCCESS'
          : 'WARNING'
    ">
    <ng-container slot="message">
      @if (service.hasNoEmail()) {
        <ng-container [ngTemplateOutlet]="EmailNoteFound"></ng-container>
      } @else if (service.hasAcceptedPrivacyPolicy()) {
        <ng-container [ngTemplateOutlet]="Accepted"></ng-container>
      } @else {
        <ng-container [ngTemplateOutlet]="NotAcceptedYet"></ng-container>
      }
    </ng-container>
  </unijob-info-box>
</div>

<ng-template #Accepted>
  <span class="text-m">
    {{
      "privacy_policy_accepted"
        | i18next: { date: service.hasAcceptedPrivacyPolicy() }
    }}
  </span>
</ng-template>

<ng-template #EmailNoteFound>
  <span class="text-m">
    {{ "no_email_for_privacy_policy" | i18next }} <br /><br />
    <span
      [innerHTML]="
        'privacy_policy_not_accepted_will_be_deleted'
          | i18next
            : {
                date: service.talentDataAvailableUntil().date,
                days: service.talentDataAvailableUntil().days,
              }
      ">
    </span>
  </span>
</ng-template>

<ng-template #NotAcceptedYet>
  <div class="uj-flex uj-flex-col uj-gap-d uj-items-start">
    <span class="text-m">
      {{ "privacy_policy_not_accepted" | i18next }} <br /><br />
      <span
        [innerHTML]="
          'privacy_policy_not_accepted_will_be_deleted'
            | i18next
              : {
                  date: service.talentDataAvailableUntil().date,
                  days: service.talentDataAvailableUntil().days,
                }
        ">
      </span>
    </span>
    <button
      ui-library-button-outline
      color="dark"
      iconLeft="send"
      (click)="service.sendEmail()">
      <span>{{ "send_privacy_policy_email" | i18next }}</span>
    </button>
    <div class="uj-flex uj-flex-col text-m">
      <div
        class="uj-flex uj-flex-col uj-gap-b uj-py-c"
        *ngFor="let entry of service.sentMails()">
        <span class="text-s">{{ entry.date }}</span>
        <span
          [innerHTML]="
            'privacy_policy_email_sent_log'
              | i18next: { name: entry.sentBy, email: entry.sentTo }
          ">
        </span>
      </div>
    </div>
  </div>
</ng-template>
