import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { combineLatest } from 'rxjs';
import {
  UserBotFragment,
  UserListItemFragment,
  VacancyChangelog,
} from '../../../../graphql/generated';
import { ConsultantService } from '../../../../core/services/consultant.service';
import { UserService } from '../../../../models/shared/user/user.service';
import { DATE_TIME_FORMAT } from '../../../../app.constants';

@Component({
  selector: 'app-vacancy-changelog-status-entry',
  templateUrl: './vacancy-changelog-status-entry.component.html',
  styleUrls: ['./vacancy-changelog-status-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyChangelogStatusEntryComponent implements OnInit {
  @Input({ required: true }) record!: VacancyChangelog;
  user?: UserListItemFragment | UserBotFragment;
  isCurrentUser = false;
  constructor(
    public consultantService: ConsultantService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {}
  public DATE_TIME_FORMAT = DATE_TIME_FORMAT;

  ngOnInit() {
    this.consultantService.consultantsById$.subscribe((users) => {
      if (!this.record?.userId) return;
      this.user = users[this.record?.userId];
    });
    combineLatest([
      this.consultantService.consultantsById$,
      this.userService.readyUser$,
    ]).subscribe(([users, currentUser]) => {
      this.isCurrentUser = users[this.record?.userId]?._id === currentUser?._id;
      this.cd.markForCheck();
    });
  }
}
