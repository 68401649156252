import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CanLoadPages,
  UserService,
} from '../../models/shared/user/user.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DocumentEventListenerService } from '@intemp/unijob-ui';
import { takeUntil } from 'rxjs/operators';
import { BuildInfo, BuildInfoService } from '../services/build-infos.service';

export type NavCollapsedClass = 'expanded' | 'collapsed';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit, OnDestroy, AfterViewInit {
  destroyed$ = new Subject<void>();
  navCollapsed = false;
  navBreakpoint = 992;
  canLoadPages?: CanLoadPages;

  @Input({ required: true }) navToggle!: EventEmitter<void>;
  @Output() navToggled = new EventEmitter<boolean>();

  @HostBinding('class') navCollapsedClass: NavCollapsedClass = 'expanded';

  constructor(
    public userService: UserService,
    public router: Router,
    private documentEventListenerService: DocumentEventListenerService,
    private buildInfoService: BuildInfoService,
    private cd: ChangeDetectorRef,
  ) {}

  get isMobile(): boolean {
    return window.innerWidth < this.navBreakpoint;
  }

  public buildInfo: BuildInfo = {
    commit: '',
    tag: '',
    branch: '',
    env: '',
  };
  get mainBuildInfo(): string {
    if (this.buildInfo.tag && this.buildInfo.tag !== 'no tag') {
      return this.buildInfo.tag;
    }
    if (this.buildInfo.commit) {
      return this.buildInfo.commit;
    }
    if (this.buildInfo.branch) {
      return this.buildInfo.branch;
    }
    return '?';
  }

  async ngOnInit(): Promise<void> {
    this.canLoadPages = await this.userService.getLoadablePages();
    this.navCollapsed = this.isMobile;
    this.buildInfoService.getBuildInfo().subscribe((info) => {
      this.buildInfo = Object.assign(this.buildInfo, info);
      this.cd.detectChanges();
    });
    this.updateNavCollapsedClass();

    this.navToggle.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.toggleNavCollapsed();
    });
    this.closeMobileNav();
    this.checkWindowSize();
  }

  ngAfterViewInit(): void {
    this.documentEventListenerService.debouncedResizeEmitter
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.checkWindowSize();
        this.enableScroll();
      });
  }

  closeMobileNav(): void {
    if (this.isMobile) {
      this.toggleNavCollapsed(true);
      (document.activeElement as HTMLElement).blur();
    }
  }

  toggleNavCollapsed(collapsed?: boolean): void {
    if (collapsed) {
      this.navCollapsed = collapsed;
    } else {
      this.navCollapsed = !this.navCollapsed;
    }
    if (this.isMobile) {
      this.toggleScrollLock();
    }
    this.updateNavCollapsedClass();
  }

  toggleScrollLock(): void {
    if (this.navCollapsed) {
      this.enableScroll();
    } else {
      this.disableScroll();
    }
  }

  checkWindowSize(): void {
    this.navCollapsed = window.innerWidth < this.navBreakpoint;
    this.updateNavCollapsedClass();
  }

  updateNavCollapsedClass(): void {
    this.navCollapsedClass = this.navCollapsed ? 'collapsed' : 'expanded';
    this.navToggled.emit(this.navCollapsed);
  }

  disableScroll(): void {
    document.body.classList.add('scroll-lock');
    document.ontouchmove = (e) => e.preventDefault();
  }

  enableScroll(): void {
    document.body.classList.remove('scroll-lock');
    document.ontouchmove = () => true;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
