<unijob-sheet
  [id]="sheetId"
  size="m"
  (sheetClosed)="handleSheetClosed(sheetId)">
  <ng-container slot="header">{{ "changeHistory" | i18next }}</ng-container>
  <ng-container slot="content">
    <ng-container *ngrxLet="vacancyHistoryList$ as vacancyHistoryList">
      <div
        class="uj-flex uj-flex-col uj-gap-c uj-relative"
        *ngIf="vacancyHistoryList.entries.length; else emptyHistory">
        <div
          class="uj-absolute uj-z-0 uj-w-[1px] uj-left-[24px] uj-bg-grey-940 uj-h-full uj-top-[30px]"
          style="height: calc(100% - 60px)"></div>
        <ng-container *ngFor="let history of vacancyHistoryList.entries">
          <app-vacancy-changelog-entry
            *ngIf="history"
            (triggerRevertRecord)="revertRecord($event)"
            [vacancyHistory]="history"
            [vacancy]="
              vacancyHistoryList.vacancy
            "></app-vacancy-changelog-entry>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</unijob-sheet>

<ng-template #emptyHistory>
  <div class="uj-text-text-grey">
    {{ "historyEntriesWillBeDisplayHere" | i18next }}
  </div>
</ng-template>
