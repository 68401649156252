export const statsColorPalette = [
  '#E97661',
  '#F18D4B',
  '#F4AA38',
  '#CEB73A',
  '#A9C04D',
  '#87C46A',
  '#67C58D',
  '#56C3AC',
  '#54C0C5',
  '#53BAD4',
  '#828090',
  '#95879E',
  '#AD8BA6',
  '#C88FA5',
  '#DF959D',
  '#F09F90',
  '#F5AF81',
  '#F7C474',
  '#DDCD75',
  '#C3D382',
  '#ABD697',
  '#95D6AF',
  '#89D5C5',
  '#87D3D6',
  '#87CFE1',
  '#4C4A61',
  '#675375',
  '#8A5980',
  '#B05F7F',
  '#D16773',
];
