<unijob-sheet
  [id]="sheetId"
  size="s"
  (sheetClosed)="sheetClosed.emit()">
  <ng-container slot="header">
    <h2 class="title-m">
      {{ "endVacancy" | i18next | firstUppercase }}
    </h2>
  </ng-container>
  <ng-container slot="content">
    <div
      class="uj-flex uj-flex-col uj-gap-f"
      [formGroup]="formGroup">
      <div class="uj-flex uj-flex-col uj-gap-c">
        <span class="title-xs">{{ "reason" | i18next }}</span>
        <span class="text-m">
          {{ "pleaseSelectOneOfTheFollowingReasons" | i18next }}</span
        >
        <app-vacancy-select-archive
          (selectChange)="updateArchiveReason($event)"
          [archiveReason]="archiveReason.value"></app-vacancy-select-archive>
      </div>
    </div>
    <div
      *ngIf="activePrioBoostConfigs?.length || activeSearchConfigs?.length"
      class="uj-mt-f">
      <span class="title-xs">{{ "Promotion" | i18next }}</span>
      <div
        *ngIf="activePrioBoostConfigs?.length"
        class="uj-mt-d">
        <unijob-info-box
          type="INFO"
          [title]="'prioBoost' | i18next"
          [message]="
            'pioBoostWarningVacancyArchiveMessage' | i18next
          "></unijob-info-box>
      </div>
      <div
        *ngIf="activeSearchConfigs?.length"
        class="uj-mt-d">
        <unijob-info-box
          type="WARNING"
          [title]="'searchAddonWarningVacancyArchiveTitle' | i18next"
          [message]="
            'searchAddonWarningVacancyArchiveMessage'
              | i18next
                : {
                    until: activeSearchLastUntil | date: 'dd.MM.yyyy',
                    untilDays: activeSearchLastUntilDays,
                  }
          ">
        </unijob-info-box>
      </div>
      <div
        *ngIf="activeSearchConfigs?.length"
        class="uj-mt-d">
        <label
          for="deactivationNotes"
          class="uj-mb-b text-m-b"
          >{{ "deactivationNotes" | i18next }}</label
        >
        <unijob-textarea
          id="deactivationNotes"
          placeholder="{{ 'deactivationNotesPlaceholder' | i18next }}"
          elevation="outline"
          theme="white"
          name="deactivationNotes"
          [(ngModel)]="deactivationNotes"
          [footer]="false"
          [clearable]="false"
          [showValidState]="false"
          [showInvalidState]="true"
          [required]="false"
          [maxChars]="0"
          [displayAutoSaveIcon]="false"
          [rows]="3"
          errorMessage=""
          size="size-m"></unijob-textarea>
      </div>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <button
      (click)="archiveVacancy()"
      [disabled]="!archiveReason.value"
      class="uj-w-full"
      ui-library-button-solid
      type="button"
      iconLeft="archive">
      {{ "endVacancy" | i18next | firstUppercase }}
    </button>
  </ng-container>
</unijob-sheet>
