import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  public shown$ = new BehaviorSubject(false);
  public text$ = new BehaviorSubject('');
  show(text: string) {
    this.shown$.next(true);
    this.text$.next(text);
  }
  hide() {
    this.shown$.next(false);
  }
}
