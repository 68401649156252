import { Component, inject, Input } from '@angular/core';
import { InputTextModule } from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { randomId } from '../../../../../helpers/functions/randomId';
import { TargetJobTitleFormGroup } from '../../../talent-sheet.types';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { ArrayActionEnum } from '../../../../../../graphql/generated';
import { TalentSharedService } from '../../../services/talent-shared.service';
import { NgIf } from '@angular/common';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import {
  ButtonOutlineComponent,
  FormFieldComponent,
  ModalService,
  TextInputComponent,
  ToastService,
} from '@intemp/unijob-ui2';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';

@Component({
  standalone: true,
  selector: 'app-talent-target-job-entry',
  templateUrl: './talent-target-job-entry.component.html',
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    I18NextModule,
    NgIf,
    ButtonOutlineComponent,
    FormFieldComponent,
    SharedDefaultModule,
    TextInputComponent,
  ],
})
export class TalentTargetJobEntryComponent {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentSharedService);
  i18next = inject(I18NextPipe);
  toastService = inject(ToastService);
  modals = inject(ModalService);

  targetJobTitleFormArray = this.formService.targetJobTitleFormArray;

  @Input({ required: true }) titleGroup!: FormGroup<TargetJobTitleFormGroup>;
  @Input() isNewEntry = false;
  componentId = randomId();

  onChange() {
    if (this.isNewEntry) {
      this.updateJobTitle(this.titleGroup, ArrayActionEnum.ADDED);
      // Reset the form to add a new entry after the current one
      this.titleGroup.reset({
        uuid: randomId(),
        type: ArrayActionEnum.ADDED,
        title: null,
      });
    } else {
      this.updateJobTitle(this.titleGroup, ArrayActionEnum.CHANGED);
    }
  }

  deleteJobTitle() {
    this.updateJobTitle(this.titleGroup, ArrayActionEnum.REMOVED);
    this.targetJobTitleFormArray.removeAt(
      this.targetJobTitleFormArray.controls.indexOf(this.titleGroup),
    );
  }

  updateJobTitle(
    childControl: FormGroup<TargetJobTitleFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const targetJobTitle = getFormData(childControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !targetJobTitle) {
      console.error('Talent uuid is not valid');
      return;
    }

    if (arrayAction === ArrayActionEnum.ADDED) {
      this.formService.addNewTargetJobTitle({ ...targetJobTitle });
    }

    targetJobTitle.type = arrayAction;

    this.formService
      .updateTalentApi({
        targetJobTitle: [targetJobTitle],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }
}
