<div
  class="uj-flex uj-relative uj-gap-c uj-px-d uj-py-c uj-rounded"
  *ngIf="record">
  <div class="uj-flex-1">
    <div class="uj-bg-surface-main">
      <span class="text-s uj-text-text-grey">{{
        record.mutatedAt | date: DATE_TIME_FORMAT
      }}</span>
    </div>
    <div class="uj-flex uj-flex-col">
      <div class="uj-flex uj-gap-c uj-items-center">
        <div class="uj-self-start">
          <ng-container *ngIf="user">
            <app-user-avatar
              [user]="user"
              [contextMenu]="userAvatarTemplate"
              interactive
              size="xs">
            </app-user-avatar>
          </ng-container>
        </div>
        <div class="uj-flex uj-gap-b uj-flex-1 uj-flex-wrap uj-pt-a">
          <span
            *ngIf="user"
            [contextMenu]="userAvatarTemplate"
            class="text-m-b hover:uj-text-text-accent-hover uj-cursor-pointer"
            >{{ user.profile.firstName + " " + user.profile.lastName + " "
            }}<ng-container *ngIf="isCurrentUser"
              >({{ "me" | i18next }})</ng-container
            ></span
          >
          <ng-container *ngIf="record && record.fieldName">
            <span
              class="text-m"
              [innerHTML]="
                'vacancyChangelogActions.' + record.action
                  | i18next
                    : {
                        entryName: record.fieldName | i18next,
                      }
              "></span>
          </ng-container>
        </div>
      </div>
      <div class="uj-flex uj-gap-c uj-items-center">
        <div class="uj-self-start uj-min-w-[24px]"></div>
        <ng-container *ngIf="record?.displayedPreviousValue">
          <div>
            <span
              class="input-text-s uj-bg-ui-warning-2-light uj-px-b uj-py-a uj-rounded"
              >{{
                "vacancyStatusEnum." + record?.displayedPreviousValue | i18next
              }}</span
            >
          </div>
          <span
            *ngIf="record.displayedCurrentValue"
            id="arrow-icon-right"
            class="uj-min-h-[14px] uj-flex uj-items-center">
            <svg
              width="11"
              height="9"
              viewBox="0 0 11 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.52699 9L5.76136 8.24432L8.91335 5.09233H0.75V3.99858H8.91335L5.76136 0.856534L6.52699 0.0909092L10.9815 4.54545L6.52699 9Z"
                fill="#323232" />
            </svg>
          </span>
        </ng-container>
        <div *ngIf="record.displayedCurrentValue">
          <span
            class="input-text-s uj-bg-ui-positive-light uj-px-b uj-py-a uj-rounded">
            <ng-container
              *ngIf="record.fieldName === 'vacancyNumber'; else other"
              >{{ record.displayedCurrentValue | i18next }}</ng-container
            >
            <ng-template #other>{{
              "vacancyStatusEnum." + record.displayedCurrentValue | i18next
            }}</ng-template>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #userAvatarTemplate>
  <app-user-info-context-menu [user]="user"></app-user-info-context-menu>
</ng-template>
