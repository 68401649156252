<div
  class="uj-flex uj-relative uj-gap-c uj-px-d uj-py-c uj-rounded"
  *ngIf="record">
  <div class="uj-flex-1">
    <div class="uj-bg-surface-main uj-relative">
      <ng-container *ngIf="isUnpublishedChange">
        <span
          class="unijob-dot color-pink uj-absolute -uj-left-d uj-top-[7px]"></span>
      </ng-container>
      <span class="text-s uj-text-text-grey">{{
        record.mutatedAt | date: DATE_TIME_FORMAT
      }}</span>
    </div>
    <div class="uj-flex uj-flex-col">
      <div class="uj-flex uj-gap-c uj-items-center">
        <div class="uj-self-start">
          <app-user-avatar
            *ngIf="user"
            [user]="user"
            [contextMenu]="userAvatarTemplate"
            interactive
            size="xs">
          </app-user-avatar>
        </div>
        <div class="uj-flex uj-gap-b uj-flex-1 uj-flex-wrap uj-pt-a">
          <span
            *ngIf="user"
            [contextMenu]="userAvatarTemplate"
            class="text-m-b hover:uj-text-text-accent-hover uj-cursor-pointer"
            >{{ user.profile.firstName + " " + user.profile.lastName + " "
            }}<ng-container *ngIf="isCurrentUser"
              >({{ "me" | i18next }})</ng-container
            ></span
          >
          <ng-container *ngIf="record.groupNameI18n && record?.fieldNameI18n">
            <span
              class="text-m"
              [innerHTML]="
                'vacancyChangelogEntry.nestedEntry'
                  | i18next
                    : {
                        entryName: record.fieldNameI18n | i18next,
                        parentName: record.groupNameI18n | i18next,
                        action: 'changelogActions.' + record.action | i18next,
                      }
              "></span>
          </ng-container>
          <ng-container *ngIf="!record?.groupNameI18n && record?.fieldNameI18n">
            <span
              class="text-m"
              [innerHTML]="
                'vacancyChangelogEntry.singleEntry'
                  | i18next
                    : {
                        entryName: record.fieldNameI18n | i18next,
                        action: 'changelogActions.' + record.action | i18next,
                      }
              "></span>
          </ng-container>
        </div>
      </div>
      <div class="uj-flex uj-gap-c uj-items-center">
        <div class="uj-self-start uj-min-w-[24px]"></div>
        <div
          class="uj-pt-b uj-flex uj-items-center uj-gap-b uj-flex-wrap"
          *ngIf="
            record.mutationFieldType !== DisplayType.PROSE_MIRROR;
            else proseMirrorContent
          ">
          <ng-container *ngIf="record.displayedPreviousValue">
            <div>
              <span
                class="input-text-s uj-bg-ui-warning-2-light uj-px-b uj-py-a uj-rounded"
                >{{ record.displayedPreviousValue }}</span
              >
            </div>
            <span
              *ngIf="record.displayedCurrentValue"
              id="arrow-icon-right"
              class="uj-min-h-[14px] uj-flex uj-items-center">
              <unijob-icon
                size="12"
                icon="arrow_right" />
            </span>
          </ng-container>
          <div *ngIf="record.displayedCurrentValue">
            <span
              class="input-text-s uj-bg-ui-positive-light uj-px-b uj-py-a uj-rounded"
              >{{ record.displayedCurrentValue }}</span
            >
          </div>
        </div>
        <ng-template #proseMirrorContent>
          <div class="uj-pt-b uj-flex uj-flex-col uj-gap-b uj-w-full">
            <ng-container *ngIf="record.displayedPreviousValue">
              <div
                class="input-text-s uj-bg-ui-warning-2-light uj-px-b uj-py-a uj-rounded">
                <span
                  class="ProseMirror-editor-style-m [&>ul]:uj-m-0"
                  [innerHTML]="record.displayedPreviousValue"></span>
              </div>
              <span
                *ngIf="record.displayedCurrentValue"
                class="uj-w-full uj-min-h-[20px] uj-items-center uj-flex uj-justify-center">
                <unijob-icon
                  size="12"
                  icon="arrow_down" />
              </span>
            </ng-container>
            <div
              *ngIf="record.displayedCurrentValue"
              class="input-text-s uj-bg-ui-positive-light uj-px-b uj-py-a uj-rounded">
              <span
                class="ProseMirror-editor-style-m [&>ul]:uj-m-0"
                [innerHTML]="record.displayedCurrentValue"></span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="canRevertRecord">
    <button
      class="uj-mt-e"
      ui-library-button-outline
      iconOnly="undo"
      (click)="openModal(revertChangesModalId)"
      color="dark"></button>
  </div>
</div>

<ui-library-modal
  [id]="revertChangesModalId"
  size="S">
  <ng-container slot="header">
    {{ "revertChange" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <ng-container *ngIf="contentIsIdentical">
      {{
        "changeCanNotBeRevertedAsContentIsIdentical" | i18next | firstUppercase
      }}
    </ng-container>
    <ng-container *ngIf="!contentIsIdentical">
      {{ "doYouWantToRevertThisChange" | i18next | firstUppercase }}
    </ng-container>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-c uj-justify-end">
      <button
        (click)="closeModal(revertChangesModalId)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{
          (contentIsIdentical ? "close" : "cancel") | i18next | firstUppercase
        }}
      </button>
      <button
        *ngIf="!contentIsIdentical"
        (click)="revertChanges(record)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button">
        {{ "revert" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>

<ng-template #userAvatarTemplate>
  <app-user-info-context-menu [user]="user"></app-user-info-context-menu>
</ng-template>
