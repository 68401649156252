import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-label.component.html',
  styles: [
    `
      label {
        font: var(--font-text-m);
        font-weight: var(--font-weight-bold);
      }
      .mandatory-indicator {
        font: var(--font-text-s);
        color: var(--color-text-negative);
      }
    `,
  ],
})
export class InputLabelComponent {
  // the input id that the label is for
  @Input()
  htmlFor!: string;

  @Input()
  required?: boolean;
}
