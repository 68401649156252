import { Component, Input } from '@angular/core';
import { AvatarComponent } from '@intemp/unijob-ui2';

@Component({
  selector: 'app-avatar-empty-item',
  standalone: true,
  templateUrl: './avatar-empty-item.component.html',
  styleUrl: './avatar-empty-item.component.scss',
  imports: [AvatarComponent],
})
export class AvatarEmptyItemComponent {
  @Input({ required: true }) title!: string;

  @Input({ required: true }) description!: string;

  @Input({ required: true }) letters!: string;
}
