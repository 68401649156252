import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import {
  InputTextModule,
  OverflowGradientModule,
  RadioButtonModule,
  SheetModule,
} from '@intemp/unijob-ui';
import { VacancyCompanyContactSelectComponent } from './vacancy-company-contact-select.component';
import { FormsModule } from '@angular/forms';
import { BigSelectModule } from '../../big-select/big-select.module';
import { VacancyUnibaseContactImportModule } from './unibase-contact-import/unibase-contact-import.module';
import { AvatarListItemComponent } from '../company-location-select/avatar-list-item/avatar-list-item.component';
import {
  ButtonOutlineComponent,
  SelectComponent,
  SelectionListComponent,
  TextboxListItemComponent,
} from '@intemp/unijob-ui2';

@NgModule({
  declarations: [VacancyCompanyContactSelectComponent],
  exports: [VacancyCompanyContactSelectComponent],
  imports: [
    SharedDefaultModule,
    SheetModule,
    OverflowGradientModule,
    FormsModule,
    InputTextModule,
    RadioButtonModule,
    BigSelectModule,
    VacancyUnibaseContactImportModule,
    AvatarListItemComponent,
    SelectComponent,
    SelectionListComponent,
    TextboxListItemComponent,
    ButtonOutlineComponent,
  ],
})
export class VacancyCompanyContactSelectModule {}
