import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import {
  IDecoratable,
  ISelectable,
  ListItemComponent,
} from '@intemp/unijob-ui2';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  Subject,
} from 'rxjs';
import {
  DecoratableProvider,
  SelectableProvider,
} from '../../../consultant-select/consultant-select-list-item/consultant-select-list-item.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { UserAvatarModule } from '../../../user-avatar/user-avatar.module';
import { TitleAndTextValueWithActionsAndAvatar } from '../avatar-selected-item/avatar-selected-item.component';

// TODO: Move these to UI library
export type TitleTextValue = {
  readonly title: string;
  readonly text: string;
};
export type SelectableData<TKey, TValue> = {
  readonly key: TKey;
  readonly value: TValue;
};

@Component({
  selector: 'app-avatar-list-item',
  standalone: true,
  imports: [
    AsyncPipe,
    ListItemComponent,
    NgIf,
    SharedDefaultModule,
    UserAvatarModule,
  ],
  templateUrl: './avatar-list-item.component.html',
  styleUrl: './avatar-list-item.component.scss',
  providers: [
    SelectableProvider(AvatarListItemComponent),
    DecoratableProvider(AvatarListItemComponent),
  ],
})
export class AvatarListItemComponent
  implements
    AfterViewInit,
    ISelectable<string, TitleAndTextValueWithActionsAndAvatar>,
    IDecoratable<string>
{
  @Input({ required: true })
  _value!: string;
  @Input({ required: true })
  label!: TitleAndTextValueWithActionsAndAvatar;

  @ViewChild(ListItemComponent, {
    static: true,
  })
  listItem!: ListItemComponent<string, TitleAndTextValueWithActionsAndAvatar>;

  _selected = new BehaviorSubject(false);
  isSelected: Observable<boolean> = this._selected.pipe(distinctUntilChanged());

  // To loop ng model through - TODO: Keep the last write value within the select to avoid this
  onChange = new Subject<void>();

  isVisible = signal(false);

  decoration?: string;

  set isEnabled(isEnabled: boolean) {
    this.listItem.setEnabled(isEnabled);
  }

  get isEnabled() {
    return this.listItem.isEnabled;
  }

  get decorationNonNull() {
    return this.decoration ?? '';
  }

  constructor(
    private _elementRef: ElementRef,
    // private userService: UserService,
  ) {}

  elementRef(): ElementRef<any> {
    return this._elementRef;
  }

  ngAfterViewInit(): void {
    this.listItem._selected
      .pipe(distinctUntilChanged())
      .subscribe((value: boolean) => this._selected.next(value));
    this.listItem.onChange.subscribe(this.onChange);
    this.isVisible = this.listItem.isVisible;
  }

  select(): void {
    this.listItem.select();
  }
  deselect(): void {
    this.listItem.deselect();
  }
  toggle(): void {
    this.listItem.toggle();
  }
  hide(): void {
    this.listItem.hide();
  }
  show(): void {
    this.listItem.show();
  }
  value(): string {
    return this._value;
  }
  content(): TitleAndTextValueWithActionsAndAvatar {
    return this.label;
  }

  disable() {
    this.isEnabled = false;
  }

  enable() {
    this.isEnabled = true;
  }

  toggleEnabled() {
    this.isEnabled = !this.isEnabled;
  }
}
