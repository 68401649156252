{
  "employeeNotFound": "Mitarbeiter nicht gefunden",
  "serverError": "erreur du serveur",
  "permissionDenied": "Zugriff verweigert!",
  "featureNotImplemented": "Feature noch nicht verfügbar",
  "userNotFound": "Benutzer nicht gefunden",
  "couldNotUpdateUser": "Benutzer konnte nicht aktualisiert werden",
  "customerNotFound": "Kunde nicht gefunden",
  "customerBranchNotFound": "Filiale nicht gefunden",
  "missingRequiredFieldX": "Fehlendes Pflichtfeld {{field}}",
  "updateMadeNoChanges": "Die gewünschte aktualisierung verursacht keine Veränderung",
  "tabSortError": "Die Tabs konnten nicht neu sortiert werden",
  "deleteTabError": "Der Tab konnte nicht gelöscht werden.",
  "tooManyAttempts": "Trop de tentatives. Veuillez réessayer plus tard.",
  "dayNotFound": "Der Tag Existiert auf dem Einsatz noch nicht.",
  "entryNotFound": "Der Eintrag existiert auf dem entsprechenden Tag noch nicht.",
  "missingPhoneNumber": "Fehlende Telefonnummer",
  "notImplemented": "Diese Funktion wurde noch nicht vollständig implementiert",
  "documentNotFound": "Le document n'a pas été trouvé.",
  "notifySyncError": "Les données n'ont pas pu être synchronisées",
  "couldNotCreateJobSubscription": "Der Job-Alarm konnte nicht abonniert werden",
  "employmentNotFound": "",
  "consultantNotFound": ""
}
