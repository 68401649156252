export const clampValue = (
  inputValue: string | number | null,
  min = 0,
  max = 100,
  allowNull = false,
) => {
  if (!inputValue) {
    if (allowNull) {
      return null;
    }
    return min;
  }

  let value = parseFloat(inputValue.toString());

  if (isNaN(value)) {
    if (allowNull) {
      return null;
    }
    return min;
  }

  const signedPossible = min < 0 || max < 0;
  if (!signedPossible) {
    // to conserve (at least some of) user input
    value = Math.abs(value);
  }

  if (value < min) {
    value = min;
  } else if (value > max) {
    value = max;
  }

  return value;
};
