{
  "AF": {
    "flag": "🇦🇫",
    "code": "AF",
    "isoCode": "AFG",
    "continent": "Asien",
    "DE": {
      "name": "Afghanistan",
      "shortName": "Afghanistan",
      "officialName": "Islamische Republik Afghanistan",
      "genderNeutralWriting": "Afghane/Afghanin",
      "residentsMaleWriting": "der Afghane",
      "residentsFemaleWriting": "die Afghanin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Afghanistan",
      "shortName": "Afghanistan",
      "officialName": "République islamique d'Afghanistan",
      "genderNeutralWriting": "Afghan / Afghane",
      "residentsMaleWriting": "L'Afghan",
      "residentsFemaleWriting": "L'Afghane",
      "continent": "Asie"
    },
    "IT": {
      "name": "Afghanistan",
      "shortName": "Afghanistan",
      "officialName": "Repubblica Islamica dell'Afghanistan",
      "genderNeutralWriting": "Afgano / Afgana",
      "residentsMaleWriting": "L'afgano",
      "residentsFemaleWriting": "L'afgana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Afghanistan",
      "shortName": "Afghanistan",
      "officialName": "Islamic Republic of Afghanistan",
      "genderNeutralWriting": "Afghan",
      "residentsMaleWriting": "The Afghan",
      "residentsFemaleWriting": "The Afghan Woman",
      "continent": "Asia"
    }
  },
  "EG": {
    "flag": "🇪🇬",
    "code": "EG",
    "isoCode": "EGY",
    "continent": "Africa",
    "DE": {
      "name": "Ägypten",
      "shortName": "Ägypten",
      "officialName": "Arabische Republik Ägypten",
      "genderNeutralWriting": "Ägypter:in",
      "residentsMaleWriting": "der Ägypter",
      "residentsFemaleWriting": "die Ägypterin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Égypte",
      "shortName": "Égypte",
      "officialName": "République arabe d'Égypte",
      "genderNeutralWriting": "Égyptien / Égyptienne",
      "residentsMaleWriting": "L'Égyptien",
      "residentsFemaleWriting": "L'Égyptienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Egitto",
      "shortName": "Egitto",
      "officialName": "Repubblica Araba d'Egitto",
      "genderNeutralWriting": "Egiziano / Egiziana",
      "residentsMaleWriting": "L'egiziano",
      "residentsFemaleWriting": "L'egiziana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Egypt",
      "shortName": "Egypt",
      "officialName": "Arab Republic of Egypt",
      "genderNeutralWriting": "Egyptian",
      "residentsMaleWriting": "The Egyptian",
      "residentsFemaleWriting": "The Egyptian Woman",
      "continent": "Africa"
    }
  },
  "AX": {
    "flag": "🇦🇽",
    "code": "AX",
    "isoCode": "ALA",
    "continent": "Europe",
    "DE": {
      "name": "Alandinseln",
      "shortName": "Alandinseln",
      "genderNeutralWriting": "Alandinseln",
      "continent": "Europa"
    },
    "FR": {
      "name": "Îles Åland",
      "shortName": "Îles Åland",
      "genderNeutralWriting": "Ålandais / Ålandaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Isole Åland",
      "shortName": "Isole Åland",
      "genderNeutralWriting": "Ålandese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Åland Islands",
      "shortName": "Åland Islands",
      "officialName": "Åland Islands",
      "genderNeutralWriting": "Åland Islander",
      "continent": "Europe"
    }
  },
  "AL": {
    "flag": "🇦🇱",
    "code": "AL",
    "isoCode": "ALB",
    "continent": "Europe",
    "DE": {
      "name": "Albanien",
      "shortName": "Albanien",
      "officialName": "Republik Albanien",
      "genderNeutralWriting": "Albaner:in",
      "residentsMaleWriting": "der Albaner",
      "residentsFemaleWriting": "die Albanerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Albanie",
      "shortName": "Albanie",
      "officialName": "République d'Albanie",
      "genderNeutralWriting": "Albanais / Albanaise",
      "residentsMaleWriting": "L'Albanais",
      "residentsFemaleWriting": "L'Albanaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Albania",
      "shortName": "Albania",
      "officialName": "Repubblica d'Albania",
      "genderNeutralWriting": "Albanese",
      "residentsMaleWriting": "L'albanese",
      "residentsFemaleWriting": "L'albanese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Albania",
      "shortName": "Albania",
      "officialName": "Republic of Albania",
      "genderNeutralWriting": "Albanian",
      "residentsMaleWriting": "The Albanian",
      "residentsFemaleWriting": "The Albanian Woman",
      "continent": "Europe"
    }
  },
  "DZ": {
    "flag": "🇩🇿",
    "code": "DZ",
    "isoCode": "DZA",
    "continent": "Africa",
    "DE": {
      "name": "Algerien",
      "shortName": "Algerien",
      "officialName": "Demokratische Volksrepublik Algerien",
      "genderNeutralWriting": "Algerier:in",
      "residentsMaleWriting": "der Algerier",
      "residentsFemaleWriting": "die Algerierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Algérie",
      "shortName": "Algérie",
      "officialName": "République algérienne démocratique et populaire",
      "genderNeutralWriting": "Algérien / Algérienne",
      "residentsMaleWriting": "L'Algérien",
      "residentsFemaleWriting": "L'Algérienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Algeria",
      "shortName": "Algeria",
      "officialName": "Repubblica Democratica Popolare di Algeria",
      "genderNeutralWriting": "Algerino / Algerina",
      "residentsMaleWriting": "L'algerino",
      "residentsFemaleWriting": "L'algerina",
      "continent": "Africa"
    },
    "EN": {
      "name": "Algeria",
      "shortName": "Algeria",
      "officialName": "People's Democratic Republic of Algeria",
      "genderNeutralWriting": "Algerian",
      "residentsMaleWriting": "The Algerian",
      "residentsFemaleWriting": "The Algerian Woman",
      "continent": "Africa"
    }
  },
  "AS": {
    "flag": "🇦🇸",
    "code": "AS",
    "isoCode": "ASM",
    "continent": "Oceania",
    "DE": {
      "name": "Amerikanisch-Samoa",
      "shortName": "Amerikanisch-Samoa",
      "genderNeutralWriting": "Amerikanisch-Samoa",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Samoa américaines",
      "shortName": "Samoa américaines",
      "genderNeutralWriting": "Samoan américain / Samoane américaine",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Samoa Americane",
      "shortName": "Samoa Americane",
      "genderNeutralWriting": "Samoano americano / Samoana americana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "American Samoa",
      "shortName": "American Samoa",
      "officialName": "Territory of American Samoa",
      "genderNeutralWriting": "American Samoan",
      "continent": "Oceania"
    }
  },
  "AD": {
    "flag": "🇦🇩",
    "code": "AD",
    "isoCode": "AND",
    "continent": "Europe",
    "DE": {
      "name": "Andorra",
      "shortName": "Andorra",
      "officialName": "Fürstentum Andorra",
      "genderNeutralWriting": "Andorraner:in",
      "residentsMaleWriting": "der Andorraner",
      "residentsFemaleWriting": "die Andorranerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Andorre",
      "shortName": "Andorre",
      "officialName": "Principauté d'Andorre",
      "genderNeutralWriting": "Andorran / Andorrane",
      "residentsMaleWriting": "L'Andorran",
      "residentsFemaleWriting": "L'Andorrane",
      "continent": "Europe"
    },
    "IT": {
      "name": "Andorra",
      "shortName": "Andorra",
      "officialName": "Principato di Andorra",
      "genderNeutralWriting": "Andorrano / Andorrana",
      "residentsMaleWriting": "L'andorrano",
      "residentsFemaleWriting": "L'andorrana",
      "continent": "Europa"
    },
    "EN": {
      "name": "Andorra",
      "shortName": "Andorra",
      "officialName": "Principality of Andorra",
      "genderNeutralWriting": "Andorran",
      "residentsMaleWriting": "The Andorran",
      "residentsFemaleWriting": "The Andorran Woman",
      "continent": "Europe"
    }
  },
  "AO": {
    "flag": "🇦🇴",
    "code": "AO",
    "isoCode": "AGO",
    "continent": "Africa",
    "DE": {
      "name": "Angola",
      "shortName": "Angola",
      "officialName": "Republik Angola",
      "genderNeutralWriting": "Angolaner:in",
      "residentsMaleWriting": "der Angolaner",
      "residentsFemaleWriting": "die Angolanerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Angola",
      "shortName": "Angola",
      "officialName": "République d'Angola",
      "genderNeutralWriting": "Angolais / Angolaise",
      "residentsMaleWriting": "L'Angolais",
      "residentsFemaleWriting": "L'Angolaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Angola",
      "shortName": "Angola",
      "officialName": "Repubblica dell'Angola",
      "genderNeutralWriting": "Angolano / Angolana",
      "residentsMaleWriting": "L'angolano",
      "residentsFemaleWriting": "L'angolana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Angola",
      "shortName": "Angola",
      "officialName": "Republic of Angola",
      "genderNeutralWriting": "Angolan",
      "residentsMaleWriting": "The Angolan",
      "residentsFemaleWriting": "The Angolan Woman",
      "continent": "Africa"
    }
  },
  "AI": {
    "flag": "🇦🇮",
    "code": "AI",
    "isoCode": "AIA",
    "continent": "America",
    "DE": {
      "name": "Anguilla",
      "shortName": "Anguilla",
      "genderNeutralWriting": "Anguilla",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Anguilla",
      "shortName": "Anguilla",
      "genderNeutralWriting": "Anguillais / Anguillaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Anguilla",
      "shortName": "Anguilla",
      "genderNeutralWriting": "Anguillano / Anguillana",
      "continent": "America"
    },
    "EN": {
      "name": "Anguilla",
      "shortName": "Anguilla",
      "officialName": "Anguilla",
      "genderNeutralWriting": "Anguillian",
      "continent": "America"
    }
  },
  "AQ": {
    "flag": "🇦🇶",
    "code": "AQ",
    "isoCode": "ATA",
    "continent": "Antarctica",
    "DE": {
      "name": "Antarktis",
      "shortName": "Antarktis",
      "genderNeutralWriting": "Antarktis",
      "continent": "Antarktis"
    },
    "FR": {
      "name": "Antarctique",
      "shortName": "Antarctique",
      "genderNeutralWriting": "Antarctique",
      "continent": "Antarctique"
    },
    "IT": {
      "name": "Antartide",
      "shortName": "Antartide",
      "genderNeutralWriting": "Antartico",
      "continent": "Antartide"
    },
    "EN": {
      "name": "Antarctica",
      "shortName": "Antarctica",
      "officialName": "Antarctica",
      "genderNeutralWriting": "Antarctic",
      "continent": "Antarctica"
    }
  },
  "AG": {
    "flag": "🇦🇬",
    "code": "AG",
    "isoCode": "ATG",
    "continent": "America",
    "DE": {
      "name": "Antigua und Barbuda",
      "shortName": "Antigua und Barbuda",
      "officialName": "Antigua und Barbuda",
      "genderNeutralWriting": "Antiguaner:in",
      "residentsMaleWriting": "der Antiguaner",
      "residentsFemaleWriting": "die Antiguanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Antigua-et-Barbuda",
      "shortName": "Antigua-et-Barbuda",
      "officialName": "Antigua-et-Barbuda",
      "genderNeutralWriting": "Antiguais / Antiguaise",
      "residentsMaleWriting": "L'Antiguais",
      "residentsFemaleWriting": "L'Antiguaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Antigua e Barbuda",
      "shortName": "Antigua e Barbuda",
      "officialName": "Antigua e Barbuda",
      "genderNeutralWriting": "Antiguano / Antiguana",
      "residentsMaleWriting": "L'antiguano",
      "residentsFemaleWriting": "L'antiguana",
      "continent": "America"
    },
    "EN": {
      "name": "Antigua and Barbuda",
      "shortName": "Antigua and Barbuda",
      "officialName": "Antigua and Barbuda",
      "genderNeutralWriting": "Antiguan",
      "residentsMaleWriting": "The Antiguan",
      "residentsFemaleWriting": "The Antiguan Woman",
      "continent": "America"
    }
  },
  "AR": {
    "flag": "🇦🇷",
    "code": "AR",
    "isoCode": "ARG",
    "continent": "America",
    "DE": {
      "name": "Argentinien",
      "shortName": "Argentinien",
      "officialName": "Argentinische Republik",
      "genderNeutralWriting": "Argentinier:in",
      "residentsMaleWriting": "der Argentinier",
      "residentsFemaleWriting": "die Argentinierin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Argentine",
      "shortName": "Argentine",
      "officialName": "République argentine",
      "genderNeutralWriting": "Argentin / Argentine",
      "residentsMaleWriting": "L'Argentin",
      "residentsFemaleWriting": "L'Argentine",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Argentina",
      "shortName": "Argentina",
      "officialName": "Repubblica Argentina",
      "genderNeutralWriting": "Argentino / Argentina",
      "residentsMaleWriting": "L'argentino",
      "residentsFemaleWriting": "L'argentina",
      "continent": "America"
    },
    "EN": {
      "name": "Argentina",
      "shortName": "Argentina",
      "officialName": "Argentine Republic",
      "genderNeutralWriting": "Argentinian",
      "residentsMaleWriting": "The Argentinian",
      "residentsFemaleWriting": "The Argentinian Woman",
      "continent": "America"
    }
  },
  "AM": {
    "flag": "🇦🇲",
    "code": "AM",
    "isoCode": "ARM",
    "continent": "Asien",
    "DE": {
      "name": "Armenien",
      "shortName": "Armenien",
      "officialName": "Republik Armenien",
      "genderNeutralWriting": "Armenier:in",
      "residentsMaleWriting": "der Armenier",
      "residentsFemaleWriting": "die Armenierin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Arménie",
      "shortName": "Arménie",
      "officialName": "République d'Arménie",
      "genderNeutralWriting": "Arménien / Arménienne",
      "residentsMaleWriting": "L'Arménien",
      "residentsFemaleWriting": "L'Arménienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Armenia",
      "shortName": "Armenia",
      "officialName": "Repubblica di Armenia",
      "genderNeutralWriting": "Armeno / Armena",
      "residentsMaleWriting": "L'armeno",
      "residentsFemaleWriting": "L'armena",
      "continent": "Asia"
    },
    "EN": {
      "name": "Armenia",
      "shortName": "Armenia",
      "officialName": "Republic of Armenia",
      "genderNeutralWriting": "Armenian",
      "residentsMaleWriting": "The Armenian",
      "residentsFemaleWriting": "The Armenian Woman",
      "continent": "Asia"
    }
  },
  "AW": {
    "flag": "🇦🇼",
    "code": "AW",
    "isoCode": "ABW",
    "continent": "America",
    "DE": {
      "name": "Aruba",
      "shortName": "Aruba",
      "genderNeutralWriting": "Aruba",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Aruba",
      "shortName": "Aruba",
      "genderNeutralWriting": "Arubais / Arubaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Aruba",
      "shortName": "Aruba",
      "genderNeutralWriting": "Arubano / Arubana",
      "continent": "America"
    },
    "EN": {
      "name": "Aruba",
      "shortName": "Aruba",
      "officialName": "Aruba",
      "genderNeutralWriting": "Aruban",
      "continent": "America"
    }
  },
  "AZ": {
    "flag": "🇦🇿",
    "code": "AZ",
    "isoCode": "AZE",
    "continent": "Asien",
    "DE": {
      "name": "Aserbaidschan",
      "shortName": "Aserbaidschan",
      "officialName": "Republik Aserbaidschan",
      "genderNeutralWriting": "Aserbaidschaner:in",
      "residentsMaleWriting": "der Aserbaidschaner",
      "residentsFemaleWriting": "die Aserbaidschanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Azerbaïdjan",
      "shortName": "Azerbaïdjan",
      "officialName": "République d'Azerbaïdjan",
      "genderNeutralWriting": "Azerbaïdjanais / Azerbaïdjanaise",
      "residentsMaleWriting": "L'Azerbaïdjanais",
      "residentsFemaleWriting": "L'Azerbaïdjanaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Azerbaigian",
      "shortName": "Azerbaigian",
      "officialName": "Repubblica dell'Azerbaigian",
      "genderNeutralWriting": "Azero / Azera",
      "residentsMaleWriting": "L'azero",
      "residentsFemaleWriting": "L'azera",
      "continent": "Asia"
    },
    "EN": {
      "name": "Azerbaijan",
      "shortName": "Azerbaijan",
      "officialName": "Republic of Azerbaijan",
      "genderNeutralWriting": "Azerbaijani",
      "residentsMaleWriting": "The Azerbaijani",
      "residentsFemaleWriting": "The Azerbaijani Woman",
      "continent": "Asia"
    }
  },
  "ET": {
    "flag": "🇪🇹",
    "code": "ET",
    "isoCode": "ETH",
    "continent": "Africa",
    "DE": {
      "name": "Äthiopien",
      "shortName": "Äthiopien",
      "officialName": "Demokratische Bundesrepublik Äthiopien",
      "genderNeutralWriting": "Äthiopier:in",
      "residentsMaleWriting": "der Äthiopier",
      "residentsFemaleWriting": "die Äthiopierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Éthiopie",
      "shortName": "Éthiopie",
      "officialName": "République fédérale démocratique d'Éthiopie",
      "genderNeutralWriting": "Éthiopien / Éthiopienne",
      "residentsMaleWriting": "L'Éthiopien",
      "residentsFemaleWriting": "L'Éthiopienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Etiopia",
      "shortName": "Etiopia",
      "officialName": "Repubblica Federale Democratica d'Etiopia",
      "genderNeutralWriting": "Etiope",
      "residentsMaleWriting": "L'etiope",
      "residentsFemaleWriting": "L'etiope",
      "continent": "Africa"
    },
    "EN": {
      "name": "Ethiopia",
      "shortName": "Ethiopia",
      "officialName": "Federal Democratic Republic of Ethiopia",
      "genderNeutralWriting": "Ethiopian",
      "residentsMaleWriting": "The Ethiopian",
      "residentsFemaleWriting": "The Ethiopian Woman",
      "continent": "Africa"
    }
  },
  "AU": {
    "flag": "🇦🇺",
    "code": "AU",
    "isoCode": "AUS",
    "continent": "Oceania",
    "DE": {
      "name": "Australien",
      "shortName": "Australien",
      "officialName": "Australien",
      "genderNeutralWriting": "Australier:in",
      "residentsMaleWriting": "der Australier",
      "residentsFemaleWriting": "die Australierin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Australie",
      "shortName": "Australie",
      "officialName": "Commonwealth d'Australie",
      "genderNeutralWriting": "Australien / Australienne",
      "residentsMaleWriting": "L'Australien",
      "residentsFemaleWriting": "L'Australienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Australia",
      "shortName": "Australia",
      "officialName": "Commonwealth dell'Australia",
      "genderNeutralWriting": "Australiano / Australiana",
      "residentsMaleWriting": "L'australiano",
      "residentsFemaleWriting": "L'australiana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Australia",
      "shortName": "Australia",
      "officialName": "Commonwealth of Australia",
      "genderNeutralWriting": "Australian",
      "residentsMaleWriting": "The Australian",
      "residentsFemaleWriting": "The Australian Woman",
      "continent": "Oceania"
    }
  },
  "BS": {
    "flag": "🇧🇸",
    "code": "BS",
    "isoCode": "BHS",
    "continent": "America",
    "DE": {
      "name": "Bahamas",
      "shortName": "Bahamas",
      "officialName": "Commonwealth der Bahamas",
      "genderNeutralWriting": "Bahamaer:in",
      "residentsMaleWriting": "der Bahamaer",
      "residentsFemaleWriting": "die Bahamaerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Bahamas",
      "shortName": "Bahamas",
      "officialName": "Commonwealth des Bahamas",
      "genderNeutralWriting": "Bahamien / Bahamienne",
      "residentsMaleWriting": "Le Bahamien",
      "residentsFemaleWriting": "La Bahamienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Bahamas",
      "shortName": "Bahamas",
      "officialName": "Commonwealth delle Bahamas",
      "genderNeutralWriting": "Bahamiano / Bahamiana",
      "residentsMaleWriting": "Il bahamiano",
      "residentsFemaleWriting": "La bahamiana",
      "continent": "America"
    },
    "EN": {
      "name": "Bahamas",
      "shortName": "Bahamas",
      "officialName": "Commonwealth of the Bahamas",
      "genderNeutralWriting": "Bahamian",
      "residentsMaleWriting": "The Bahamian",
      "residentsFemaleWriting": "The Bahamian Woman",
      "continent": "America"
    }
  },
  "BH": {
    "flag": "🇧🇭",
    "code": "BH",
    "isoCode": "BHR",
    "continent": "Asien",
    "DE": {
      "name": "Bahrain",
      "shortName": "Bahrain",
      "officialName": "Königreich Bahrain",
      "genderNeutralWriting": "Bahrainer:in",
      "residentsMaleWriting": "der Bahrainer",
      "residentsFemaleWriting": "die Bahrainerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Bahreïn",
      "shortName": "Bahreïn",
      "officialName": "Royaume de Bahreïn",
      "genderNeutralWriting": "Bahreïnien / Bahreïnienne",
      "residentsMaleWriting": "Le Bahreïnien",
      "residentsFemaleWriting": "La Bahreïnienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Bahrein",
      "shortName": "Bahrein",
      "officialName": "Regno del Bahrein",
      "genderNeutralWriting": "Bahreinita",
      "residentsMaleWriting": "Il bahreinita",
      "residentsFemaleWriting": "La bahreinita",
      "continent": "Asia"
    },
    "EN": {
      "name": "Bahrain",
      "shortName": "Bahrain",
      "officialName": "Kingdom of Bahrain",
      "genderNeutralWriting": "Bahraini",
      "residentsMaleWriting": "The Bahraini",
      "residentsFemaleWriting": "The Bahraini Woman",
      "continent": "Asia"
    }
  },
  "BD": {
    "flag": "🇧🇩",
    "code": "BD",
    "isoCode": "BGD",
    "continent": "Asien",
    "DE": {
      "name": "Bangladesch",
      "shortName": "Bangladesch",
      "officialName": "Volksrepublik Bangladesch",
      "genderNeutralWriting": "Bangladescher:in",
      "residentsMaleWriting": "der Bangladescher",
      "residentsFemaleWriting": "die Bangladescherin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Bangladesh",
      "shortName": "Bangladesh",
      "officialName": "République populaire du Bangladesh",
      "genderNeutralWriting": "Bangladais / Bangladaise",
      "residentsMaleWriting": "Le Bangladais",
      "residentsFemaleWriting": "La Bangladaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Bangladesh",
      "shortName": "Bangladesh",
      "officialName": "Repubblica Popolare del Bangladesh",
      "genderNeutralWriting": "Bengalese",
      "residentsMaleWriting": "Il bengalese",
      "residentsFemaleWriting": "La bengalese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Bangladesh",
      "shortName": "Bangladesh",
      "officialName": "People's Republic of Bangladesh",
      "genderNeutralWriting": "Bangladeshi",
      "residentsMaleWriting": "The Bangladeshi",
      "residentsFemaleWriting": "The Bangladeshi Woman",
      "continent": "Asia"
    }
  },
  "BB": {
    "flag": "🇧🇧",
    "code": "BB",
    "isoCode": "BRB",
    "continent": "America",
    "DE": {
      "name": "Barbados",
      "shortName": "Barbados",
      "officialName": "Barbados",
      "genderNeutralWriting": "Barbadier:in",
      "residentsMaleWriting": "der Barbadier",
      "residentsFemaleWriting": "die Barbadierin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Barbade",
      "shortName": "Barbade",
      "officialName": "Barbade",
      "genderNeutralWriting": "Barbadien / Barbadienne",
      "residentsMaleWriting": "Le Barbadien",
      "residentsFemaleWriting": "La Barbadienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Barbados",
      "shortName": "Barbados",
      "officialName": "Barbados",
      "genderNeutralWriting": "Barbadiano / Barbadiana",
      "residentsMaleWriting": "Il barbadiano",
      "residentsFemaleWriting": "La barbadiana",
      "continent": "America"
    },
    "EN": {
      "name": "Barbados",
      "shortName": "Barbados",
      "officialName": "Barbados",
      "genderNeutralWriting": "Barbadian",
      "residentsMaleWriting": "The Barbadian",
      "residentsFemaleWriting": "The Barbadian Woman",
      "continent": "America"
    }
  },
  "BY": {
    "flag": "🇧🇾",
    "code": "BY",
    "isoCode": "BLR",
    "continent": "Europe",
    "DE": {
      "name": "Belarus",
      "shortName": "Belarus",
      "officialName": "Republik Belarus",
      "genderNeutralWriting": "Belarusse/Belarussin",
      "residentsMaleWriting": "der Belarusse",
      "residentsFemaleWriting": "die Belarussin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Biélorussie",
      "shortName": "Biélorussie",
      "officialName": "République de Biélorussie",
      "genderNeutralWriting": "Biélorusse",
      "residentsMaleWriting": "Le Biélorusse",
      "residentsFemaleWriting": "La Biélorusse",
      "continent": "Europe"
    },
    "IT": {
      "name": "Bielorussia",
      "shortName": "Bielorussia",
      "officialName": "Repubblica di Bielorussia",
      "genderNeutralWriting": "Bielorusso / Bielorussa",
      "residentsMaleWriting": "Il bielorusso",
      "residentsFemaleWriting": "La bielorussa",
      "continent": "Europa"
    },
    "EN": {
      "name": "Belarus",
      "shortName": "Belarus",
      "officialName": "Republic of Belarus",
      "genderNeutralWriting": "Belarusian",
      "residentsMaleWriting": "The Belarusian",
      "residentsFemaleWriting": "The Belarusian Woman",
      "continent": "Europe"
    }
  },
  "BE": {
    "flag": "🇧🇪",
    "code": "BE",
    "isoCode": "BEL",
    "continent": "Europe",
    "DE": {
      "name": "Belgien",
      "shortName": "Belgien",
      "officialName": "Königreich Belgien",
      "genderNeutralWriting": "Belgier:in",
      "residentsMaleWriting": "der Belgier",
      "residentsFemaleWriting": "die Belgierin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Belgique",
      "shortName": "Belgique",
      "officialName": "Royaume de Belgique",
      "genderNeutralWriting": "Belge",
      "residentsMaleWriting": "Le Belge",
      "residentsFemaleWriting": "La Belge",
      "continent": "Europe"
    },
    "IT": {
      "name": "Belgio",
      "shortName": "Belgio",
      "officialName": "Regno del Belgio",
      "genderNeutralWriting": "Belga",
      "residentsMaleWriting": "Il belga",
      "residentsFemaleWriting": "La belga",
      "continent": "Europa"
    },
    "EN": {
      "name": "Belgium",
      "shortName": "Belgium",
      "officialName": "Kingdom of Belgium",
      "genderNeutralWriting": "Belgian",
      "residentsMaleWriting": "The Belgian",
      "residentsFemaleWriting": "The Belgian Woman",
      "continent": "Europe"
    }
  },
  "BZ": {
    "flag": "🇧🇿",
    "code": "BZ",
    "isoCode": "BLZ",
    "continent": "America",
    "DE": {
      "name": "Belize",
      "shortName": "Belize",
      "officialName": "Belize",
      "genderNeutralWriting": "Belizer:in",
      "residentsMaleWriting": "der Belizer",
      "residentsFemaleWriting": "die Belizerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Belize",
      "shortName": "Belize",
      "officialName": "Belize",
      "genderNeutralWriting": "Bélizien / Bélizienne",
      "residentsMaleWriting": "Le Bélizien",
      "residentsFemaleWriting": "La Bélizienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Belize",
      "shortName": "Belize",
      "officialName": "Belize",
      "genderNeutralWriting": "Beliziano / Beliziana",
      "residentsMaleWriting": "Il beliziano",
      "residentsFemaleWriting": "La beliziana",
      "continent": "America"
    },
    "EN": {
      "name": "Belize",
      "shortName": "Belize",
      "officialName": "Belize",
      "genderNeutralWriting": "Belizean",
      "residentsMaleWriting": "The Belizean",
      "residentsFemaleWriting": "The Belizean Woman",
      "continent": "America"
    }
  },
  "BJ": {
    "flag": "🇧🇯",
    "code": "BJ",
    "isoCode": "BEN",
    "continent": "Africa",
    "DE": {
      "name": "Benin",
      "shortName": "Benin",
      "officialName": "Republik Benin",
      "genderNeutralWriting": "Beniner:in",
      "residentsMaleWriting": "der Beniner",
      "residentsFemaleWriting": "die Beninerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Bénin",
      "shortName": "Bénin",
      "officialName": "République du Bénin",
      "genderNeutralWriting": "Béninois / Béninoise",
      "residentsMaleWriting": "Le Béninois",
      "residentsFemaleWriting": "La Béninoise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Benin",
      "shortName": "Benin",
      "officialName": "Repubblica del Benin",
      "genderNeutralWriting": "Beninese",
      "residentsMaleWriting": "Il beninese",
      "residentsFemaleWriting": "La beninese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Benin",
      "shortName": "Benin",
      "officialName": "Republic of Benin",
      "genderNeutralWriting": "Beninese",
      "residentsMaleWriting": "The Beninese",
      "residentsFemaleWriting": "The Beninese Woman",
      "continent": "Africa"
    }
  },
  "BM": {
    "flag": "🇧🇲",
    "code": "BM",
    "isoCode": "BMU",
    "continent": "America",
    "DE": {
      "name": "Bermuda",
      "shortName": "Bermuda",
      "genderNeutralWriting": "Bermuda",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Bermudes",
      "shortName": "Bermudes",
      "genderNeutralWriting": "Bermudien / Bermudienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Bermuda",
      "shortName": "Bermuda",
      "genderNeutralWriting": "Bermudiano / Bermudiana",
      "continent": "America"
    },
    "EN": {
      "name": "Bermuda",
      "shortName": "Bermuda",
      "officialName": "Bermuda",
      "genderNeutralWriting": "Bermudian",
      "continent": "America"
    }
  },
  "BT": {
    "flag": "🇧🇹",
    "code": "BT",
    "isoCode": "BTN",
    "continent": "Asien",
    "DE": {
      "name": "Bhutan",
      "shortName": "Bhutan",
      "officialName": "Königreich Bhutan",
      "genderNeutralWriting": "Bhutaner:in",
      "residentsMaleWriting": "der Bhutaner",
      "residentsFemaleWriting": "die Bhutanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Bhoutan",
      "shortName": "Bhoutan",
      "officialName": "Royaume du Bhoutan",
      "genderNeutralWriting": "Bhoutanais / Bhoutanaise",
      "residentsMaleWriting": "Le Bhoutanais",
      "residentsFemaleWriting": "La Bhoutanaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Bhutan",
      "shortName": "Bhutan",
      "officialName": "Regno del Bhutan",
      "genderNeutralWriting": "Bhutanese",
      "residentsMaleWriting": "Il bhutanese",
      "residentsFemaleWriting": "La bhutanese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Bhutan",
      "shortName": "Bhutan",
      "officialName": "Kingdom of Bhutan",
      "genderNeutralWriting": "Bhutanese",
      "residentsMaleWriting": "The Bhutanese",
      "residentsFemaleWriting": "The Bhutanese Woman",
      "continent": "Asia"
    }
  },
  "BO": {
    "flag": "🇧🇴",
    "code": "BO",
    "isoCode": "BOL",
    "continent": "America",
    "DE": {
      "name": "Bolivien",
      "shortName": "Bolivien",
      "officialName": "Plurinationaler Staat Bolivien",
      "genderNeutralWriting": "Bolivianer:in",
      "residentsMaleWriting": "der Bolivianer",
      "residentsFemaleWriting": "die Bolivianerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Bolivie",
      "shortName": "Bolivie",
      "officialName": "État plurinational de Bolivie",
      "genderNeutralWriting": "Bolivien / Bolivienne",
      "residentsMaleWriting": "Le Bolivien",
      "residentsFemaleWriting": "La Bolivienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Bolivia",
      "shortName": "Bolivia",
      "officialName": "Stato Plurinazionale della Bolivia",
      "genderNeutralWriting": "Boliviano / Boliviana",
      "residentsMaleWriting": "Il boliviano",
      "residentsFemaleWriting": "La boliviana",
      "continent": "America"
    },
    "EN": {
      "name": "Bolivia",
      "shortName": "Bolivia",
      "officialName": "Plurinational State of Bolivia",
      "genderNeutralWriting": "Bolivian",
      "residentsMaleWriting": "The Bolivian",
      "residentsFemaleWriting": "The Bolivian Woman",
      "continent": "America"
    }
  },
  "BQ": {
    "flag": "🏳️",
    "code": "BQ",
    "isoCode": "BES",
    "continent": "America",
    "DE": {
      "name": "Bonaire, Saint Eustatius und Saba",
      "shortName": "Bonaire, Saint Eustatius und Saba",
      "genderNeutralWriting": "Bonaire, Saint Eustatius und Saba",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Bonaire, Saint-Eustache et Saba",
      "shortName": "Bonaire, Saint-Eustache et Saba",
      "genderNeutralWriting": "Habitant de Bonaire, Saint-Eustache et Saba",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Bonaire, Sint Eustatius e Saba",
      "shortName": "Bonaire, Sint Eustatius e Saba",
      "genderNeutralWriting": "Abitante di Bonaire, Sint Eustatius e Saba",
      "continent": "America"
    },
    "EN": {
      "name": "Bonaire, Sint Eustatius and Saba",
      "shortName": "Bonaire, Sint Eustatius and Saba",
      "officialName": "Bonaire, Sint Eustatius and Saba",
      "genderNeutralWriting": "Bonaire, Sint Eustatius and Saba resident",
      "continent": "America"
    }
  },
  "BA": {
    "flag": "🇧🇦",
    "code": "BA",
    "isoCode": "BIH",
    "continent": "Europe",
    "DE": {
      "name": "Bosnien und Herzegowina",
      "shortName": "Bosnien und Herzegowina",
      "officialName": "Bosnien und Herzegowina",
      "genderNeutralWriting": "Bosnier:in",
      "residentsMaleWriting": "der Bosnier",
      "residentsFemaleWriting": "die Bosnierin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Bosnie-Herzégovine",
      "shortName": "Bosnie-Herzégovine",
      "officialName": "Bosnie-Herzégovine",
      "genderNeutralWriting": "Bosniaque",
      "residentsMaleWriting": "Le Bosniaque",
      "residentsFemaleWriting": "La Bosniaque",
      "continent": "Europe"
    },
    "IT": {
      "name": "Bosnia ed Erzegovina",
      "shortName": "Bosnia ed Erzegovina",
      "officialName": "Bosnia ed Erzegovina",
      "genderNeutralWriting": "Bosniaco / Bosniaca",
      "residentsMaleWriting": "Il Bosniaco",
      "residentsFemaleWriting": "La Bosniaca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Bosnia and Herzegovina",
      "shortName": "Bosnia and Herzegovina",
      "officialName": "Bosnia and Herzegovina",
      "genderNeutralWriting": "Bosnian",
      "residentsMaleWriting": "The Bosnian",
      "residentsFemaleWriting": "The Bosnian Woman",
      "continent": "Europe"
    }
  },
  "BW": {
    "flag": "🇧🇼",
    "code": "BW",
    "isoCode": "BWA",
    "continent": "Africa",
    "DE": {
      "name": "Botsuana",
      "shortName": "Botsuana",
      "officialName": "Republik Botsuana",
      "genderNeutralWriting": "Botsuaner:in",
      "residentsMaleWriting": "der Botsuaner",
      "residentsFemaleWriting": "die Botsuanerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Botswana",
      "shortName": "Botswana",
      "officialName": "République du Botswana",
      "genderNeutralWriting": "Botswanais / Botswanaise",
      "residentsMaleWriting": "Le Botswanais",
      "residentsFemaleWriting": "La Botswanaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Botswana",
      "shortName": "Botswana",
      "officialName": "Repubblica del Botswana",
      "genderNeutralWriting": "Botswano / Botswana",
      "residentsMaleWriting": "Il Botswano",
      "residentsFemaleWriting": "La Botswana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Botswana",
      "shortName": "Botswana",
      "officialName": "Republic of Botswana",
      "genderNeutralWriting": "Motswana",
      "residentsMaleWriting": "The Motswana",
      "residentsFemaleWriting": "The Motswana Woman",
      "continent": "Africa"
    }
  },
  "BV": {
    "flag": "🇧🇻",
    "code": "BV",
    "isoCode": "BVT",
    "continent": "Antarctica",
    "DE": {
      "name": "Bouvetinsel",
      "shortName": "Bouvetinsel",
      "genderNeutralWriting": "Bouvetinsel",
      "continent": "Antarktis"
    },
    "FR": {
      "name": "Île Bouvet",
      "shortName": "Île Bouvet",
      "genderNeutralWriting": "Île Bouvet",
      "continent": "Antarctique"
    },
    "IT": {
      "name": "Isola Bouvet",
      "shortName": "Isola Bouvet",
      "genderNeutralWriting": "Isola Bouvet",
      "continent": "Antartide"
    },
    "EN": {
      "name": "Bouvet Island",
      "shortName": "Bouvet Island",
      "officialName": "Bouvet Island",
      "genderNeutralWriting": "Bouvet Island",
      "continent": "Antarctica"
    }
  },
  "BR": {
    "flag": "🇧🇷",
    "code": "BR",
    "isoCode": "BRA",
    "continent": "America",
    "DE": {
      "name": "Brasilien",
      "shortName": "Brasilien",
      "officialName": "Föderative Republik Brasilien",
      "genderNeutralWriting": "Brasilianer:in",
      "residentsMaleWriting": "der Brasilianer",
      "residentsFemaleWriting": "die Brasilianerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Brésil",
      "shortName": "Brésil",
      "officialName": "République fédérative du Brésil",
      "genderNeutralWriting": "Brésilien / Brésilienne",
      "residentsMaleWriting": "Le Brésilien",
      "residentsFemaleWriting": "La Brésilienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Brasile",
      "shortName": "Brasile",
      "officialName": "Repubblica Federativa del Brasile",
      "genderNeutralWriting": "Brasiliano / Brasiliana",
      "residentsMaleWriting": "Il Brasiliano",
      "residentsFemaleWriting": "La Brasiliana",
      "continent": "America"
    },
    "EN": {
      "name": "Brazil",
      "shortName": "Brazil",
      "officialName": "Federative Republic of Brazil",
      "genderNeutralWriting": "Brazilian",
      "residentsMaleWriting": "The Brazilian",
      "residentsFemaleWriting": "The Brazilian Woman",
      "continent": "America"
    }
  },
  "IO": {
    "flag": "🇮🇴",
    "code": "IO",
    "isoCode": "IOT",
    "continent": "Africa",
    "DE": {
      "name": "Britisches Territorium im Indischen Ozean",
      "shortName": "Britische Territorien im Indischen Ozean",
      "genderNeutralWriting": "Britische Territorien im Indischen Ozean",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Territoire britannique de l'océan Indien",
      "shortName": "Territoire britannique de l'océan Indien",
      "genderNeutralWriting": "Habitant du Territoire britannique de l'océan Indien",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Territorio britannico dell'Oceano Indiano",
      "shortName": "Territorio britannico dell'Oceano Indiano",
      "genderNeutralWriting": "Abitante del Territorio britannico dell'Oceano Indiano",
      "continent": "Africa"
    },
    "EN": {
      "name": "British Indian Ocean Territory",
      "shortName": "British Indian Ocean Territory",
      "officialName": "British Indian Ocean Territory",
      "genderNeutralWriting": "British Indian Ocean Territory resident",
      "continent": "Africa"
    }
  },
  "BN": {
    "flag": "🇧🇳",
    "code": "BN",
    "isoCode": "BRN",
    "continent": "Asien",
    "DE": {
      "name": "Brunei",
      "shortName": "Brunei Darussalam",
      "officialName": "Staat Brunei Darussalam",
      "genderNeutralWriting": "Bruneier:in",
      "residentsMaleWriting": "der Bruneier",
      "residentsFemaleWriting": "die Bruneierin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Brunéi",
      "shortName": "Brunéi Darussalam",
      "officialName": "État de Brunéi Darussalam",
      "genderNeutralWriting": "Brunéien / Brunéienne",
      "residentsMaleWriting": "Le Brunéien",
      "residentsFemaleWriting": "La Brunéienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Brunei",
      "shortName": "Brunei Darussalam",
      "officialName": "Stato del Brunei Darussalam",
      "genderNeutralWriting": "Bruneiano / Bruneiana",
      "residentsMaleWriting": "Il Bruneiano",
      "residentsFemaleWriting": "La Bruneiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Brunei",
      "shortName": "Brunei",
      "officialName": "Nation of Brunei, the Abode of Peace",
      "genderNeutralWriting": "Bruneian",
      "residentsMaleWriting": "The Bruneian",
      "residentsFemaleWriting": "The Bruneian Woman",
      "continent": "Asia"
    }
  },
  "BG": {
    "flag": "🇧🇬",
    "code": "BG",
    "isoCode": "BGR",
    "continent": "Europe",
    "DE": {
      "name": "Bulgarien",
      "shortName": "Bulgarien",
      "officialName": "Republik Bulgarien",
      "genderNeutralWriting": "Bulgare/Bulgarin",
      "residentsMaleWriting": "der Bulgare",
      "residentsFemaleWriting": "die Bulgarin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Bulgarie",
      "shortName": "Bulgarie",
      "officialName": "République de Bulgarie",
      "genderNeutralWriting": "Bulgare",
      "residentsMaleWriting": "Le Bulgare",
      "residentsFemaleWriting": "La Bulgare",
      "continent": "Europe"
    },
    "IT": {
      "name": "Bulgaria",
      "shortName": "Bulgaria",
      "officialName": "Repubblica di Bulgaria",
      "genderNeutralWriting": "Bulgaro / Bulgara",
      "residentsMaleWriting": "Il Bulgaro",
      "residentsFemaleWriting": "La Bulgara",
      "continent": "Europa"
    },
    "EN": {
      "name": "Bulgaria",
      "shortName": "Bulgaria",
      "officialName": "Republic of Bulgaria",
      "genderNeutralWriting": "Bulgarian",
      "residentsMaleWriting": "The Bulgarian",
      "residentsFemaleWriting": "The Bulgarian Woman",
      "continent": "Europe"
    }
  },
  "BF": {
    "flag": "🇧🇫",
    "code": "BF",
    "isoCode": "BFA",
    "continent": "Africa",
    "DE": {
      "name": "Burkina Faso",
      "shortName": "Burkina Faso",
      "officialName": "Burkina Faso",
      "genderNeutralWriting": "Burkiner:in",
      "residentsMaleWriting": "der Burkiner",
      "residentsFemaleWriting": "die Burkinerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Burkina Faso",
      "shortName": "Burkina Faso",
      "officialName": "Burkina Faso",
      "genderNeutralWriting": "Burkinabé",
      "residentsMaleWriting": "Le Burkinabé",
      "residentsFemaleWriting": "La Burkinabé",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Burkina Faso",
      "shortName": "Burkina Faso",
      "officialName": "Burkina Faso",
      "genderNeutralWriting": "Burkinabè",
      "residentsMaleWriting": "Il Burkinabè",
      "residentsFemaleWriting": "La Burkinabè",
      "continent": "Africa"
    },
    "EN": {
      "name": "Burkina Faso",
      "shortName": "Burkina Faso",
      "officialName": "Burkina Faso",
      "genderNeutralWriting": "Burkinabé",
      "residentsMaleWriting": "The Burkinabé",
      "residentsFemaleWriting": "The Burkinabé Woman",
      "continent": "Africa"
    }
  },
  "BI": {
    "flag": "🇧🇮",
    "code": "BI",
    "isoCode": "BDI",
    "continent": "Africa",
    "DE": {
      "name": "Burundi",
      "shortName": "Burundi",
      "officialName": "Republik Burundi",
      "genderNeutralWriting": "Burunder:in",
      "residentsMaleWriting": "der Burunder",
      "residentsFemaleWriting": "die Burunderin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Burundi",
      "shortName": "Burundi",
      "officialName": "République du Burundi",
      "genderNeutralWriting": "Burundais / Burundaise",
      "residentsMaleWriting": "Le Burundais",
      "residentsFemaleWriting": "La Burundaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Burundi",
      "shortName": "Burundi",
      "officialName": "Repubblica del Burundi",
      "genderNeutralWriting": "Burundese",
      "residentsMaleWriting": "Il Burundese",
      "residentsFemaleWriting": "La Burundese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Burundi",
      "shortName": "Burundi",
      "officialName": "Republic of Burundi",
      "genderNeutralWriting": "Burundian",
      "residentsMaleWriting": "The Burundian",
      "residentsFemaleWriting": "The Burundian Woman",
      "continent": "Africa"
    }
  },
  "CV": {
    "flag": "🇨🇻",
    "code": "CV",
    "isoCode": "CPV",
    "continent": "Africa",
    "DE": {
      "name": "Cabo Verde",
      "shortName": "Cabo Verde",
      "officialName": "Republik Cabo Verde",
      "genderNeutralWriting": "Cabo-Verdianer:in",
      "residentsMaleWriting": "der Cabo-Verdianer",
      "residentsFemaleWriting": "die Cabo-Verdianerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Cap-Vert",
      "shortName": "Cap-Vert",
      "officialName": "République du Cap-Vert",
      "genderNeutralWriting": "Cap-Verdien / Cap-Verdienne",
      "residentsMaleWriting": "Le Cap-Verdien",
      "residentsFemaleWriting": "La Cap-Verdienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Capo Verde",
      "shortName": "Capo Verde",
      "officialName": "Repubblica di Capo Verde",
      "genderNeutralWriting": "Capoverdiano / Capoverdiana",
      "residentsMaleWriting": "Il Capoverdiano",
      "residentsFemaleWriting": "La Capoverdiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Cabo Verde",
      "shortName": "Cabo Verde",
      "officialName": "Republic of Cabo Verde",
      "genderNeutralWriting": "Cabo Verdean",
      "residentsMaleWriting": "The Cabo Verdean",
      "residentsFemaleWriting": "The Cabo Verdean Woman",
      "continent": "Africa"
    }
  },
  "CL": {
    "flag": "🇨🇱",
    "code": "CL",
    "isoCode": "CHL",
    "continent": "America",
    "DE": {
      "name": "Chile",
      "shortName": "Chile",
      "officialName": "Republik Chile",
      "genderNeutralWriting": "Chilene/Chilenin",
      "residentsMaleWriting": "der Chilene",
      "residentsFemaleWriting": "die Chilenin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Chili",
      "shortName": "Chili",
      "officialName": "République du Chili",
      "genderNeutralWriting": "Chilien / Chilienne",
      "residentsMaleWriting": "Le Chilien",
      "residentsFemaleWriting": "La Chilienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Cile",
      "shortName": "Cile",
      "officialName": "Repubblica del Cile",
      "genderNeutralWriting": "Cileno / Cilena",
      "residentsMaleWriting": "Il Cileno",
      "residentsFemaleWriting": "La Cilena",
      "continent": "America"
    },
    "EN": {
      "name": "Chile",
      "shortName": "Chile",
      "officialName": "Republic of Chile",
      "genderNeutralWriting": "Chilean",
      "residentsMaleWriting": "The Chilean",
      "residentsFemaleWriting": "The Chilean Woman",
      "continent": "America"
    }
  },
  "CN": {
    "flag": "🇨🇳",
    "code": "CN",
    "isoCode": "CHN",
    "continent": "Asien",
    "DE": {
      "name": "China",
      "shortName": "China",
      "officialName": "Volksrepublik China",
      "genderNeutralWriting": "Chinese/Chinesin",
      "residentsMaleWriting": "der Chinese",
      "residentsFemaleWriting": "die Chinesin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Chine",
      "shortName": "Chine",
      "officialName": "République populaire de Chine",
      "genderNeutralWriting": "Chinois / Chinoise",
      "residentsMaleWriting": "Le Chinois",
      "residentsFemaleWriting": "La Chinoise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Cina",
      "shortName": "Cina",
      "officialName": "Repubblica Popolare Cinese",
      "genderNeutralWriting": "Cinese",
      "residentsMaleWriting": "Il Cinese",
      "residentsFemaleWriting": "La Cinese",
      "continent": "Asia"
    },
    "EN": {
      "name": "China",
      "shortName": "China",
      "officialName": "People's Republic of China",
      "genderNeutralWriting": "Chinese",
      "residentsMaleWriting": "The Chinese",
      "residentsFemaleWriting": "The Chinese Woman",
      "continent": "Asia"
    }
  },
  "CK": {
    "flag": "🇨🇰",
    "code": "CK",
    "isoCode": "COK",
    "continent": "Oceania",
    "DE": {
      "name": "Cookinseln",
      "shortName": "Cookinseln",
      "officialName": "Cookinseln",
      "genderNeutralWriting": "Cookinseln",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Îles Cook",
      "shortName": "Îles Cook",
      "officialName": "Îles Cook",
      "genderNeutralWriting": "Cookien / Cookienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isole Cook",
      "shortName": "Isole Cook",
      "officialName": "Isole Cook",
      "genderNeutralWriting": "Cookiano / Cookiana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Cook Islands",
      "shortName": "Cook Islands",
      "officialName": "Cook Islands",
      "genderNeutralWriting": "Cook Islander",
      "continent": "Oceania"
    }
  },
  "CR": {
    "flag": "🇨🇷",
    "code": "CR",
    "isoCode": "CRI",
    "continent": "America",
    "DE": {
      "name": "Costa Rica",
      "shortName": "Costa Rica",
      "officialName": "Republik Costa Rica",
      "genderNeutralWriting": "Costa-Ricaner:in",
      "residentsMaleWriting": "der Costa-Ricaner",
      "residentsFemaleWriting": "die Costa-Ricanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Costa Rica",
      "shortName": "Costa Rica",
      "officialName": "République du Costa Rica",
      "genderNeutralWriting": "Costaricien / Costaricienne",
      "residentsMaleWriting": "Le Costaricien",
      "residentsFemaleWriting": "La Costaricienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Costa Rica",
      "shortName": "Costa Rica",
      "officialName": "Repubblica di Costa Rica",
      "genderNeutralWriting": "Costaricano / Costaricana",
      "residentsMaleWriting": "Il Costaricano",
      "residentsFemaleWriting": "La Costaricana",
      "continent": "America"
    },
    "EN": {
      "name": "Costa Rica",
      "shortName": "Costa Rica",
      "officialName": "Republic of Costa Rica",
      "genderNeutralWriting": "Costa Rican",
      "residentsMaleWriting": "The Costa Rican",
      "residentsFemaleWriting": "The Costa Rican Woman",
      "continent": "America"
    }
  },
  "CI": {
    "flag": "🇨🇮",
    "code": "CI",
    "isoCode": "CIV",
    "continent": "Africa",
    "DE": {
      "name": "Côte d'Ivoire",
      "shortName": "Côte d'Ivoire",
      "officialName": "Republik Côte d'Ivoire",
      "genderNeutralWriting": "Ivorer:in",
      "residentsMaleWriting": "der Ivorer",
      "residentsFemaleWriting": "die Ivorerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Côte d'Ivoire",
      "shortName": "Côte d'Ivoire",
      "officialName": "République de Côte d'Ivoire",
      "genderNeutralWriting": "Ivoirien / Ivoirienne",
      "residentsMaleWriting": "L'Ivoirien",
      "residentsFemaleWriting": "L'Ivoirienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Costa d'Avorio",
      "shortName": "Costa d'Avorio",
      "officialName": "Repubblica della Costa d'Avorio",
      "genderNeutralWriting": "Ivoriano / Ivoriana",
      "residentsMaleWriting": "L'Ivoriano",
      "residentsFemaleWriting": "L'Ivoriana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Côte d'Ivoire",
      "shortName": "Côte d'Ivoire",
      "officialName": "Republic of Côte d'Ivoire",
      "genderNeutralWriting": "Ivorian",
      "residentsMaleWriting": "The Ivorian",
      "residentsFemaleWriting": "The Ivorian Woman",
      "continent": "Africa"
    }
  },
  "CW": {
    "flag": "🇨🇼",
    "code": "CW",
    "isoCode": "CUW",
    "continent": "America",
    "DE": {
      "name": "Curaçao",
      "shortName": "Curaçao",
      "genderNeutralWriting": "Curaçao",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Curaçao",
      "shortName": "Curaçao",
      "genderNeutralWriting": "Curaçaois / Curaçaoise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Curaçao",
      "shortName": "Curaçao",
      "genderNeutralWriting": "Curacaoano / Curacaoana",
      "continent": "America"
    },
    "EN": {
      "name": "Curaçao",
      "shortName": "Curaçao",
      "officialName": "Country of Curaçao",
      "genderNeutralWriting": "Curaçaoan",
      "continent": "America"
    }
  },
  "DK": {
    "flag": "🇩🇰",
    "code": "DK",
    "isoCode": "DNK",
    "continent": "Europe",
    "DE": {
      "name": "Dänemark",
      "shortName": "Dänemark",
      "officialName": "Königreich Dänemark",
      "genderNeutralWriting": "Däne/Dänin",
      "residentsMaleWriting": "der Däne",
      "residentsFemaleWriting": "die Dänin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Danemark",
      "shortName": "Danemark",
      "officialName": "Royaume du Danemark",
      "genderNeutralWriting": "Danois / Danoise",
      "residentsMaleWriting": "Le Danois",
      "residentsFemaleWriting": "La Danoise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Danimarca",
      "shortName": "Danimarca",
      "officialName": "Regno di Danimarca",
      "genderNeutralWriting": "Danese",
      "residentsMaleWriting": "Il Danese",
      "residentsFemaleWriting": "La Danese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Denmark",
      "shortName": "Denmark",
      "officialName": "Kingdom of Denmark",
      "genderNeutralWriting": "Danish",
      "residentsMaleWriting": "The Dane",
      "residentsFemaleWriting": "The Danish Woman",
      "continent": "Europe"
    }
  },
  "DE": {
    "flag": "🇩🇪",
    "code": "DE",
    "isoCode": "DEU",
    "continent": "Europe",
    "DE": {
      "name": "Deutschland",
      "shortName": "Deutschland",
      "officialName": "Bundesrepublik Deutschland",
      "genderNeutralWriting": "Deutsche",
      "residentsMaleWriting": "der Deutsche",
      "residentsFemaleWriting": "die Deutsche",
      "continent": "Europa"
    },
    "FR": {
      "name": "Allemagne",
      "shortName": "Allemagne",
      "officialName": "République fédérale d'Allemagne",
      "genderNeutralWriting": "Allemand / Allemande",
      "residentsMaleWriting": "L'Allemand",
      "residentsFemaleWriting": "L'Allemande",
      "continent": "Europe"
    },
    "IT": {
      "name": "Germania",
      "shortName": "Germania",
      "officialName": "Repubblica Federale di Germania",
      "genderNeutralWriting": "Tedesco / Tedesca",
      "residentsMaleWriting": "Il Tedesco",
      "residentsFemaleWriting": "La Tedesca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Germany",
      "shortName": "Germany",
      "officialName": "Federal Republic of Germany",
      "genderNeutralWriting": "German",
      "residentsMaleWriting": "The German",
      "residentsFemaleWriting": "The German Woman",
      "continent": "Europe"
    }
  },
  "DM": {
    "flag": "🇩🇲",
    "code": "DM",
    "isoCode": "DMA",
    "continent": "America",
    "DE": {
      "name": "Dominica",
      "shortName": "Dominica",
      "officialName": "Commonwealth Dominica",
      "genderNeutralWriting": "Dominicaner:in",
      "residentsMaleWriting": "der Dominicaner",
      "residentsFemaleWriting": "die Dominicanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Dominique",
      "shortName": "Dominique",
      "officialName": "Commonwealth de la Dominique",
      "genderNeutralWriting": "Dominiquais / Dominiquaise",
      "residentsMaleWriting": "Le Dominiquais",
      "residentsFemaleWriting": "La Dominiquaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Dominica",
      "shortName": "Dominica",
      "officialName": "Commonwealth di Dominica",
      "genderNeutralWriting": "Dominicano / Dominicana",
      "residentsMaleWriting": "Il Dominicano",
      "residentsFemaleWriting": "La Dominicana",
      "continent": "America"
    },
    "EN": {
      "name": "Dominica",
      "shortName": "Dominica",
      "officialName": "Commonwealth of Dominica",
      "genderNeutralWriting": "Dominican",
      "residentsMaleWriting": "The Dominican",
      "residentsFemaleWriting": "The Dominican Woman",
      "continent": "America"
    }
  },
  "DO": {
    "flag": "🇩🇴",
    "code": "DO",
    "isoCode": "DOM",
    "continent": "America",
    "DE": {
      "name": "Dominikanische Republik",
      "shortName": "Dominikanische Republik",
      "officialName": "Dominikanische Republik",
      "genderNeutralWriting": "Dominikaner:in",
      "residentsMaleWriting": "der Dominikaner",
      "residentsFemaleWriting": "die Dominikanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "République dominicaine",
      "shortName": "République dominicaine",
      "officialName": "République dominicaine",
      "genderNeutralWriting": "Dominicain / Dominicaine",
      "residentsMaleWriting": "Le Dominicain",
      "residentsFemaleWriting": "La Dominicaine",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Repubblica Dominicana",
      "shortName": "Repubblica Dominicana",
      "officialName": "Repubblica Dominicana",
      "genderNeutralWriting": "Dominicano / Dominicana",
      "residentsMaleWriting": "Il Dominicano",
      "residentsFemaleWriting": "La Dominicana",
      "continent": "America"
    },
    "EN": {
      "name": "Dominican Republic",
      "shortName": "Dominican Republic",
      "officialName": "Dominican Republic",
      "genderNeutralWriting": "Dominican",
      "residentsMaleWriting": "The Dominican",
      "residentsFemaleWriting": "The Dominican Woman",
      "continent": "America"
    }
  },
  "DJ": {
    "flag": "🇩🇯",
    "code": "DJ",
    "isoCode": "DJI",
    "continent": "Africa",
    "DE": {
      "name": "Dschibuti",
      "shortName": "Dschibuti",
      "officialName": "Republik Dschibuti",
      "genderNeutralWriting": "Dschibutier:in",
      "residentsMaleWriting": "der Dschibutier",
      "residentsFemaleWriting": "die Dschibutierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Djibouti",
      "shortName": "Djibouti",
      "officialName": "République de Djibouti",
      "genderNeutralWriting": "Djiboutien / Djiboutienne",
      "residentsMaleWriting": "Le Djiboutien",
      "residentsFemaleWriting": "La Djiboutienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Gibuti",
      "shortName": "Gibuti",
      "officialName": "Repubblica di Gibuti",
      "genderNeutralWriting": "Gibutiano / Gibutiana",
      "residentsMaleWriting": "Il Gibutiano",
      "residentsFemaleWriting": "La Gibutiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Djibouti",
      "shortName": "Djibouti",
      "officialName": "Republic of Djibouti",
      "genderNeutralWriting": "Djiboutian",
      "residentsMaleWriting": "The Djiboutian",
      "residentsFemaleWriting": "The Djiboutian Woman",
      "continent": "Africa"
    }
  },
  "EC": {
    "flag": "🇪🇨",
    "code": "EC",
    "isoCode": "ECU",
    "continent": "America",
    "DE": {
      "name": "Ecuador",
      "shortName": "Ecuador",
      "officialName": "Republik Ecuador",
      "genderNeutralWriting": "Ecuadorianer:in",
      "residentsMaleWriting": "der Ecuadorianer",
      "residentsFemaleWriting": "die Ecuadorianerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Équateur",
      "shortName": "Équateur",
      "officialName": "République de l'Équateur",
      "genderNeutralWriting": "Équatorien / Équatorienne",
      "residentsMaleWriting": "L'Équatorien",
      "residentsFemaleWriting": "L'Équatorienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Ecuador",
      "shortName": "Ecuador",
      "officialName": "Repubblica dell'Ecuador",
      "genderNeutralWriting": "Ecuadoriano / Ecuadoriana",
      "residentsMaleWriting": "L'Ecuadoriano",
      "residentsFemaleWriting": "L'Ecuadoriana",
      "continent": "America"
    },
    "EN": {
      "name": "Ecuador",
      "shortName": "Ecuador",
      "officialName": "Republic of Ecuador",
      "genderNeutralWriting": "Ecuadorian",
      "residentsMaleWriting": "The Ecuadorian",
      "residentsFemaleWriting": "The Ecuadorian Woman",
      "continent": "America"
    }
  },
  "SV": {
    "flag": "🇸🇻",
    "code": "SV",
    "isoCode": "SLV",
    "continent": "America",
    "DE": {
      "name": "El Salvador",
      "shortName": "El Salvador",
      "officialName": "Republik El Salvador",
      "genderNeutralWriting": "Salvadorianer:in",
      "residentsMaleWriting": "der Salvadorianer",
      "residentsFemaleWriting": "die Salvadorianerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Salvador",
      "shortName": "Salvador",
      "officialName": "République du Salvador",
      "genderNeutralWriting": "Salvadorien / Salvadorienne",
      "residentsMaleWriting": "Le Salvadorien",
      "residentsFemaleWriting": "La Salvadorienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "El Salvador",
      "shortName": "El Salvador",
      "officialName": "Repubblica di El Salvador",
      "genderNeutralWriting": "Salvadoregno / Salvadoregna",
      "residentsMaleWriting": "Il Salvadoregno",
      "residentsFemaleWriting": "La Salvadoregna",
      "continent": "America"
    },
    "EN": {
      "name": "El Salvador",
      "shortName": "El Salvador",
      "officialName": "Republic of El Salvador",
      "genderNeutralWriting": "Salvadoran",
      "residentsMaleWriting": "The Salvadoran",
      "residentsFemaleWriting": "The Salvadoran Woman",
      "continent": "America"
    }
  },
  "ER": {
    "flag": "🇪🇷",
    "code": "ER",
    "isoCode": "ERI",
    "continent": "Africa",
    "DE": {
      "name": "Eritrea",
      "shortName": "Eritrea",
      "officialName": "Staat Eritrea",
      "genderNeutralWriting": "Eritreer:in",
      "residentsMaleWriting": "der Eritreer",
      "residentsFemaleWriting": "die Eritreerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Érythrée",
      "shortName": "Érythrée",
      "officialName": "État d'Érythrée",
      "genderNeutralWriting": "Érythréen / Érythréenne",
      "residentsMaleWriting": "L'Érythréen",
      "residentsFemaleWriting": "L'Érythréenne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Eritrea",
      "shortName": "Eritrea",
      "officialName": "Stato di Eritrea",
      "genderNeutralWriting": "Eritreo / Eritrea",
      "residentsMaleWriting": "L'Eritreo",
      "residentsFemaleWriting": "L'Eritrea",
      "continent": "Africa"
    },
    "EN": {
      "name": "Eritrea",
      "shortName": "Eritrea",
      "officialName": "State of Eritrea",
      "genderNeutralWriting": "Eritrean",
      "residentsMaleWriting": "The Eritrean",
      "residentsFemaleWriting": "The Eritrean Woman",
      "continent": "Africa"
    }
  },
  "EE": {
    "flag": "🇪🇪",
    "code": "EE",
    "isoCode": "EST",
    "continent": "Europe",
    "DE": {
      "name": "Estland",
      "shortName": "Estland",
      "officialName": "Republik Estland",
      "genderNeutralWriting": "Este/Estin",
      "residentsMaleWriting": "der Este",
      "residentsFemaleWriting": "die Estin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Estonie",
      "shortName": "Estonie",
      "officialName": "République d'Estonie",
      "genderNeutralWriting": "Estonien / Estonienne",
      "residentsMaleWriting": "L'Estonien",
      "residentsFemaleWriting": "L'Estonienne",
      "continent": "Europe"
    },
    "IT": {
      "name": "Estonia",
      "shortName": "Estonia",
      "officialName": "Repubblica di Estonia",
      "genderNeutralWriting": "Estone",
      "residentsMaleWriting": "L'Estone",
      "residentsFemaleWriting": "L'Estone",
      "continent": "Europa"
    },
    "EN": {
      "name": "Estonia",
      "shortName": "Estonia",
      "officialName": "Republic of Estonia",
      "genderNeutralWriting": "Estonian",
      "residentsMaleWriting": "The Estonian",
      "residentsFemaleWriting": "The Estonian Woman",
      "continent": "Europe"
    }
  },
  "SZ": {
    "flag": "",
    "code": "SZ",
    "isoCode": "SWZ",
    "continent": "Africa",
    "DE": {
      "name": "Eswatini",
      "shortName": "Eswatini",
      "officialName": "Königreich Eswatini",
      "genderNeutralWriting": "Swasi",
      "residentsMaleWriting": "der Swasi",
      "residentsFemaleWriting": "die Swasi",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Eswatini",
      "shortName": "Eswatini",
      "officialName": "Royaume d'Eswatini",
      "genderNeutralWriting": "Swazi",
      "residentsMaleWriting": "Le Swazi",
      "residentsFemaleWriting": "La Swazi",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Eswatini",
      "shortName": "Eswatini",
      "officialName": "Regno di Eswatini",
      "genderNeutralWriting": "Swazi",
      "residentsMaleWriting": "Lo Swazi",
      "residentsFemaleWriting": "La Swazi",
      "continent": "Africa"
    },
    "EN": {
      "name": "Eswatini",
      "shortName": "Eswatini",
      "officialName": "Kingdom of Eswatini",
      "genderNeutralWriting": "Swazi",
      "residentsMaleWriting": "The Swazi",
      "residentsFemaleWriting": "The Swazi Woman",
      "continent": "Africa"
    }
  },
  "FK": {
    "flag": "🇫🇰",
    "code": "FK",
    "isoCode": "FLK",
    "continent": "America",
    "DE": {
      "name": "Falklandinseln",
      "shortName": "Falklandinseln",
      "genderNeutralWriting": "Falklandinsulaner:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Îles Malouines",
      "shortName": "Îles Malouines",
      "genderNeutralWriting": "Malouin(e)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Isole Falkland",
      "shortName": "Isole Falkland",
      "genderNeutralWriting": "Falklandese",
      "continent": "America"
    },
    "EN": {
      "name": "Falkland Islands",
      "shortName": "Falkland Islands",
      "officialName": "Falkland Islands",
      "genderNeutralWriting": "Falkland Islander",
      "continent": "America"
    }
  },
  "FO": {
    "flag": "🇫🇴",
    "code": "FO",
    "isoCode": "FRO",
    "continent": "Europe",
    "DE": {
      "name": "Färöer-Inseln",
      "shortName": "Färöer",
      "genderNeutralWriting": "Färöer",
      "continent": "Europa"
    },
    "FR": {
      "name": "Îles Féroé",
      "shortName": "Îles Féroé",
      "genderNeutralWriting": "Féroïen(ne)",
      "continent": "Europe"
    },
    "IT": {
      "name": "Isole Fær Øer",
      "shortName": "Isole Fær Øer",
      "genderNeutralWriting": "Faroese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Faroe Islands",
      "shortName": "Faroe Islands",
      "officialName": "Faroe Islands",
      "genderNeutralWriting": "Faroese",
      "continent": "Europe"
    }
  },
  "FJ": {
    "flag": "🇫🇯",
    "code": "FJ",
    "isoCode": "FJI",
    "continent": "Oceania",
    "DE": {
      "name": "Fidschi",
      "shortName": "Fidschi",
      "officialName": "Republik Fidschi",
      "genderNeutralWriting": "Fidschianer:in",
      "residentsMaleWriting": "der Fidschianer",
      "residentsFemaleWriting": "die Fidschianerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Fidji",
      "shortName": "Fidji",
      "officialName": "République des Fidji",
      "genderNeutralWriting": "Fidjien(ne)",
      "residentsMaleWriting": "Le Fidjien",
      "residentsFemaleWriting": "La Fidjienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Figi",
      "shortName": "Figi",
      "officialName": "Repubblica delle Figi",
      "genderNeutralWriting": "Figiano/Figiana",
      "residentsMaleWriting": "Il Figiano",
      "residentsFemaleWriting": "La Figiana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Fiji",
      "shortName": "Fiji",
      "officialName": "Republic of Fiji",
      "genderNeutralWriting": "Fijian",
      "residentsMaleWriting": "The Fijian",
      "residentsFemaleWriting": "The Fijian Woman",
      "continent": "Oceania"
    }
  },
  "FI": {
    "flag": "🇫🇮",
    "code": "FI",
    "isoCode": "FIN",
    "continent": "Europe",
    "DE": {
      "name": "Finnland",
      "shortName": "Finnland",
      "officialName": "Republik Finnland",
      "genderNeutralWriting": "Finne/Finnin",
      "residentsMaleWriting": "der Finne",
      "residentsFemaleWriting": "die Finnin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Finlande",
      "shortName": "Finlande",
      "officialName": "République de Finlande",
      "genderNeutralWriting": "Finlandais(e)",
      "residentsMaleWriting": "Le Finlandais",
      "residentsFemaleWriting": "La Finlandaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Finlandia",
      "shortName": "Finlandia",
      "officialName": "Repubblica di Finlandia",
      "genderNeutralWriting": "Finlandese",
      "residentsMaleWriting": "Il Finlandese",
      "residentsFemaleWriting": "La Finlandese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Finland",
      "shortName": "Finland",
      "officialName": "Republic of Finland",
      "genderNeutralWriting": "Finnish",
      "residentsMaleWriting": "The Finn",
      "residentsFemaleWriting": "The Finnish Woman",
      "continent": "Europe"
    }
  },
  "FR": {
    "flag": "🇫🇷",
    "code": "FR",
    "isoCode": "FRA",
    "continent": "Europe",
    "DE": {
      "name": "Frankreich",
      "shortName": "Frankreich",
      "officialName": "Französische Republik",
      "genderNeutralWriting": "Franzose/Französin",
      "residentsMaleWriting": "der Franzose",
      "residentsFemaleWriting": "die Französin",
      "continent": "Europa"
    },
    "FR": {
      "name": "France",
      "shortName": "France",
      "officialName": "République française",
      "genderNeutralWriting": "Français(e)",
      "residentsMaleWriting": "Le Français",
      "residentsFemaleWriting": "La Française",
      "continent": "Europe"
    },
    "IT": {
      "name": "Francia",
      "shortName": "Francia",
      "officialName": "Repubblica francese",
      "genderNeutralWriting": "Francese",
      "residentsMaleWriting": "Il Francese",
      "residentsFemaleWriting": "La Francese",
      "continent": "Europa"
    },
    "EN": {
      "name": "France",
      "shortName": "France",
      "officialName": "French Republic",
      "genderNeutralWriting": "French",
      "residentsMaleWriting": "The Frenchman",
      "residentsFemaleWriting": "The French Woman",
      "continent": "Europe"
    }
  },
  "GF": {
    "flag": "🇬🇫",
    "code": "GF",
    "isoCode": "GUF",
    "continent": "America",
    "DE": {
      "name": "Französisch-Guayana",
      "shortName": "Französisch-Guayana",
      "genderNeutralWriting": "Französisch-Guayaner:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Guyane française",
      "shortName": "Guyane française",
      "genderNeutralWriting": "Guyanais(e)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Guyana francese",
      "shortName": "Guyana francese",
      "genderNeutralWriting": "Guianese",
      "continent": "America"
    },
    "EN": {
      "name": "French Guiana",
      "shortName": "French Guiana",
      "officialName": "French Guiana",
      "genderNeutralWriting": "French Guianese",
      "continent": "America"
    }
  },
  "PF": {
    "flag": "🇵🇫",
    "code": "PF",
    "isoCode": "PYF",
    "continent": "Oceania",
    "DE": {
      "name": "Französisch-Polynesien",
      "shortName": "Französisch-Polynesien",
      "genderNeutralWriting": "Französisch-Polynesier:in",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Polynésie française",
      "shortName": "Polynésie française",
      "genderNeutralWriting": "Polynésien(ne)",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Polinesia francese",
      "shortName": "Polinesia francese",
      "genderNeutralWriting": "Polinesiano/Polinesiana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "French Polynesia",
      "shortName": "French Polynesia",
      "officialName": "French Polynesia",
      "genderNeutralWriting": "French Polynesian",
      "continent": "Oceania"
    }
  },
  "TF": {
    "flag": "🇹🇫",
    "code": "TF",
    "isoCode": "ATF",
    "continent": "Antarctica",
    "DE": {
      "name": "Französische Süd- und Antarktisgebiete",
      "shortName": "Französische Süd- und Antarktisgebiete",
      "genderNeutralWriting": "Französisch-Südpolarische",
      "continent": "Antarktis"
    },
    "FR": {
      "name": "Terres australes et antarctiques françaises",
      "shortName": "Terres australes et antarctiques françaises",
      "genderNeutralWriting": "Habitant(e) des Terres australes et antarctiques françaises",
      "continent": "Antarctique"
    },
    "IT": {
      "name": "Terre australi e antartiche francesi",
      "shortName": "Terre australi e antartiche francesi",
      "genderNeutralWriting": "Abitante delle Terre australi e antartiche francesi",
      "continent": "Antartide"
    },
    "EN": {
      "name": "French Southern and Antarctic Lands",
      "shortName": "French Southern and Antarctic Lands",
      "officialName": "French Southern and Antarctic Lands",
      "genderNeutralWriting": "French Southern and Antarctic Lands resident",
      "continent": "Antarctica"
    }
  },
  "GA": {
    "flag": "🇬🇦",
    "code": "GA",
    "isoCode": "GAB",
    "continent": "Africa",
    "DE": {
      "name": "Gabun",
      "shortName": "Gabun",
      "officialName": "Gabunische Republik",
      "genderNeutralWriting": "Gabuner:in",
      "residentsMaleWriting": "der Gabuner",
      "residentsFemaleWriting": "die Gabunerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Gabon",
      "shortName": "Gabon",
      "officialName": "République gabonaise",
      "genderNeutralWriting": "Gabonais(e)",
      "residentsMaleWriting": "Le Gabonais",
      "residentsFemaleWriting": "La Gabonaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Gabon",
      "shortName": "Gabon",
      "officialName": "Repubblica gabonese",
      "genderNeutralWriting": "Gabonese",
      "residentsMaleWriting": "Il Gabonese",
      "residentsFemaleWriting": "La Gabonese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Gabon",
      "shortName": "Gabon",
      "officialName": "Gabonese Republic",
      "genderNeutralWriting": "Gabonese",
      "residentsMaleWriting": "The Gabonese",
      "residentsFemaleWriting": "The Gabonese Woman",
      "continent": "Africa"
    }
  },
  "GM": {
    "flag": "🇬🇲",
    "code": "GM",
    "isoCode": "GMB",
    "continent": "Africa",
    "DE": {
      "name": "Gambia",
      "shortName": "Gambia",
      "officialName": "Republik Gambia",
      "genderNeutralWriting": "Gambier:in",
      "residentsMaleWriting": "der Gambier",
      "residentsFemaleWriting": "die Gambierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Gambie",
      "shortName": "Gambie",
      "officialName": "République de Gambie",
      "genderNeutralWriting": "Gambien(ne)",
      "residentsMaleWriting": "Le Gambien",
      "residentsFemaleWriting": "La Gambienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Gambia",
      "shortName": "Gambia",
      "officialName": "Repubblica del Gambia",
      "genderNeutralWriting": "Gambiano(a)",
      "residentsMaleWriting": "Il Gambiano",
      "residentsFemaleWriting": "La Gambiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Gambia",
      "shortName": "Gambia",
      "officialName": "Republic of The Gambia",
      "genderNeutralWriting": "Gambian",
      "residentsMaleWriting": "The Gambian",
      "residentsFemaleWriting": "The Gambian Woman",
      "continent": "Africa"
    }
  },
  "GE": {
    "flag": "🇬🇪",
    "code": "GE",
    "isoCode": "GEO",
    "continent": "Asia",
    "DE": {
      "name": "Georgien",
      "shortName": "Georgien",
      "officialName": "Georgien",
      "genderNeutralWriting": "Georgier:in",
      "residentsMaleWriting": "der Georgier",
      "residentsFemaleWriting": "die Georgierin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Géorgie",
      "shortName": "Géorgie",
      "officialName": "Géorgie",
      "genderNeutralWriting": "Géorgien(ne)",
      "residentsMaleWriting": "Le Géorgien",
      "residentsFemaleWriting": "La Géorgienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Georgia",
      "shortName": "Georgia",
      "officialName": "Georgia",
      "genderNeutralWriting": "Georgiano(a)",
      "residentsMaleWriting": "Il Georgiano",
      "residentsFemaleWriting": "La Georgiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Georgia",
      "shortName": "Georgia",
      "officialName": "Georgia",
      "genderNeutralWriting": "Georgian",
      "residentsMaleWriting": "The Georgian",
      "residentsFemaleWriting": "The Georgian Woman",
      "continent": "Asia"
    }
  },
  "GH": {
    "flag": "🇬🇭",
    "code": "GH",
    "isoCode": "GHA",
    "continent": "Africa",
    "DE": {
      "name": "Ghana",
      "shortName": "Ghana",
      "officialName": "Republik Ghana",
      "genderNeutralWriting": "Ghanaer:in",
      "residentsMaleWriting": "der Ghanaer",
      "residentsFemaleWriting": "die Ghanaerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Ghana",
      "shortName": "Ghana",
      "officialName": "République du Ghana",
      "genderNeutralWriting": "Ghanéen(ne)",
      "residentsMaleWriting": "Le Ghanéen",
      "residentsFemaleWriting": "La Ghanéenne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Ghana",
      "shortName": "Ghana",
      "officialName": "Repubblica del Ghana",
      "genderNeutralWriting": "Ghanese",
      "residentsMaleWriting": "Il Ghanese",
      "residentsFemaleWriting": "La Ghanese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Ghana",
      "shortName": "Ghana",
      "officialName": "Republic of Ghana",
      "genderNeutralWriting": "Ghanaian",
      "residentsMaleWriting": "The Ghanaian",
      "residentsFemaleWriting": "The Ghanaian Woman",
      "continent": "Africa"
    }
  },
  "GI": {
    "flag": "🇬🇮",
    "code": "GI",
    "isoCode": "GIB",
    "continent": "Europe",
    "DE": {
      "name": "Gibraltar",
      "shortName": "Gibraltar",
      "genderNeutralWriting": "Gibraltarer:in",
      "continent": "Europa"
    },
    "FR": {
      "name": "Gibraltar",
      "shortName": "Gibraltar",
      "genderNeutralWriting": "Gibraltarien(ne)",
      "continent": "Europe"
    },
    "IT": {
      "name": "Gibilterra",
      "shortName": "Gibilterra",
      "genderNeutralWriting": "Gibilterrino(a)",
      "continent": "Europa"
    },
    "EN": {
      "name": "Gibraltar",
      "shortName": "Gibraltar",
      "officialName": "Gibraltar",
      "genderNeutralWriting": "Gibraltarian",
      "continent": "Europe"
    }
  },
  "GD": {
    "flag": "🇬🇩",
    "code": "GD",
    "isoCode": "GRD",
    "continent": "America",
    "DE": {
      "name": "Grenada",
      "shortName": "Grenada",
      "officialName": "Grenada",
      "genderNeutralWriting": "Grenader:in",
      "residentsMaleWriting": "der Grenader",
      "residentsFemaleWriting": "die Grenaderin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Grenade",
      "shortName": "Grenade",
      "officialName": "Grenade",
      "genderNeutralWriting": "Grenadien(ne)",
      "residentsMaleWriting": "Le Grenadien",
      "residentsFemaleWriting": "La Grenadienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Grenada",
      "shortName": "Grenada",
      "officialName": "Grenada",
      "genderNeutralWriting": "Grenadino(a)",
      "residentsMaleWriting": "Il Grenadino",
      "residentsFemaleWriting": "La Grenadina",
      "continent": "America"
    },
    "EN": {
      "name": "Grenada",
      "shortName": "Grenada",
      "officialName": "Grenada",
      "genderNeutralWriting": "Grenadian",
      "residentsMaleWriting": "The Grenadian",
      "residentsFemaleWriting": "The Grenadian Woman",
      "continent": "America"
    }
  },
  "GR": {
    "flag": "🇬🇷",
    "code": "GR",
    "isoCode": "GRC",
    "continent": "Europe",
    "DE": {
      "name": "Griechenland",
      "shortName": "Griechenland",
      "officialName": "Hellenische Republik",
      "genderNeutralWriting": "Grieche/Griechin",
      "residentsMaleWriting": "der Grieche",
      "residentsFemaleWriting": "die Griechin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Grèce",
      "shortName": "Grèce",
      "officialName": "République hellénique",
      "genderNeutralWriting": "Grec/Grecque",
      "residentsMaleWriting": "Le Grec",
      "residentsFemaleWriting": "La Grecque",
      "continent": "Europe"
    },
    "IT": {
      "name": "Grecia",
      "shortName": "Grecia",
      "officialName": "Repubblica Ellenica",
      "genderNeutralWriting": "Greco(a)",
      "residentsMaleWriting": "Il Greco",
      "residentsFemaleWriting": "La Greca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Greece",
      "shortName": "Greece",
      "officialName": "Hellenic Republic",
      "genderNeutralWriting": "Greek",
      "residentsMaleWriting": "The Greek",
      "residentsFemaleWriting": "The Greek Woman",
      "continent": "Europe"
    }
  },
  "GL": {
    "flag": "🇬🇱",
    "code": "GL",
    "isoCode": "GRL",
    "continent": "America",
    "DE": {
      "name": "Grönland",
      "shortName": "Grönland",
      "genderNeutralWriting": "Grönländer:in",
      "residentsMaleWriting": "der Grönländer",
      "residentsFemaleWriting": "die Grönländerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Groenland",
      "shortName": "Groenland",
      "genderNeutralWriting": "Groenlandais(e)",
      "residentsMaleWriting": "Le Groenlandais",
      "residentsFemaleWriting": "La Groenlandaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Groenlandia",
      "shortName": "Groenlandia",
      "genderNeutralWriting": "Groenlandese",
      "residentsMaleWriting": "Il Groenlandese",
      "residentsFemaleWriting": "La Groenlandese",
      "continent": "America"
    },
    "EN": {
      "name": "Greenland",
      "shortName": "Greenland",
      "officialName": "Greenland",
      "genderNeutralWriting": "Greenlander",
      "residentsMaleWriting": "The Greenlander",
      "residentsFemaleWriting": "The Greenlander Woman",
      "continent": "America"
    }
  },
  "GP": {
    "flag": "🇬🇵",
    "code": "GP",
    "isoCode": "GLP",
    "continent": "America",
    "DE": {
      "name": "Guadeloupe",
      "shortName": "Guadeloupe",
      "genderNeutralWriting": "Guadelouper:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Guadeloupe",
      "shortName": "Guadeloupe",
      "genderNeutralWriting": "Guadeloupéen(ne)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Guadalupa",
      "shortName": "Guadalupa",
      "genderNeutralWriting": "Guadalupano(a)",
      "continent": "America"
    },
    "EN": {
      "name": "Guadeloupe",
      "shortName": "Guadeloupe",
      "officialName": "Guadeloupe",
      "genderNeutralWriting": "Guadeloupean",
      "continent": "America"
    }
  },
  "GU": {
    "flag": "🇬🇺",
    "code": "GU",
    "isoCode": "GUM",
    "continent": "Oceania",
    "DE": {
      "name": "Guam",
      "shortName": "Guam",
      "genderNeutralWriting": "Guamer:in",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Guam",
      "shortName": "Guam",
      "genderNeutralWriting": "Guamanien(ne)",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Guam",
      "shortName": "Guam",
      "genderNeutralWriting": "Guamano(a)",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Guam",
      "shortName": "Guam",
      "officialName": "Guam",
      "genderNeutralWriting": "Guamanian",
      "continent": "Oceania"
    }
  },
  "GT": {
    "flag": "🇬🇹",
    "code": "GT",
    "isoCode": "GTM",
    "continent": "America",
    "DE": {
      "name": "Guatemala",
      "shortName": "Guatemala",
      "officialName": "Republik Guatemala",
      "genderNeutralWriting": "Guatemalteke/Guatemaltekin",
      "residentsMaleWriting": "der Guatemalteke",
      "residentsFemaleWriting": "die Guatemaltekin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Guatemala",
      "shortName": "Guatemala",
      "officialName": "République du Guatemala",
      "genderNeutralWriting": "Guatémaltèque",
      "residentsMaleWriting": "Le Guatémaltèque",
      "residentsFemaleWriting": "La Guatémaltèque",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Guatemala",
      "shortName": "Guatemala",
      "officialName": "Repubblica del Guatemala",
      "genderNeutralWriting": "Guatemalteco(a)",
      "residentsMaleWriting": "Il Guatemalteco",
      "residentsFemaleWriting": "La Guatemalteca",
      "continent": "America"
    },
    "EN": {
      "name": "Guatemala",
      "shortName": "Guatemala",
      "officialName": "Republic of Guatemala",
      "genderNeutralWriting": "Guatemalan",
      "residentsMaleWriting": "The Guatemalan",
      "residentsFemaleWriting": "The Guatemalan Woman",
      "continent": "America"
    }
  },
  "GG": {
    "flag": "🇬🇬",
    "code": "GG",
    "isoCode": "GGY",
    "continent": "Europe",
    "DE": {
      "name": "Guernsey",
      "shortName": "Guernsey",
      "genderNeutralWriting": "Guernser:in",
      "continent": "Europa"
    },
    "FR": {
      "name": "Guernesey",
      "shortName": "Guernesey",
      "genderNeutralWriting": "Guernesiais(e)",
      "continent": "Europe"
    },
    "IT": {
      "name": "Guernsey",
      "shortName": "Guernsey",
      "genderNeutralWriting": "Guernesiaco(a)",
      "continent": "Europa"
    },
    "EN": {
      "name": "Guernsey",
      "shortName": "Guernsey",
      "officialName": "Bailiwick of Guernsey",
      "genderNeutralWriting": "Guernsey resident",
      "continent": "Europe"
    }
  },
  "GN": {
    "flag": "🇬🇳",
    "code": "GN",
    "isoCode": "GIN",
    "continent": "Africa",
    "DE": {
      "name": "Guinea",
      "shortName": "Guinea",
      "officialName": "Republik Guinea",
      "genderNeutralWriting": "Guineer:in",
      "residentsMaleWriting": "der Guineer",
      "residentsFemaleWriting": "die Guineerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Guinée",
      "shortName": "Guinée",
      "officialName": "République de Guinée",
      "genderNeutralWriting": "Guinéen(ne)",
      "residentsMaleWriting": "Le Guinéen",
      "residentsFemaleWriting": "La Guinéenne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Guinea",
      "shortName": "Guinea",
      "officialName": "Repubblica di Guinea",
      "genderNeutralWriting": "Guineano(a)",
      "residentsMaleWriting": "Il Guineano",
      "residentsFemaleWriting": "La Guineana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Guinea",
      "shortName": "Guinea",
      "officialName": "Republic of Guinea",
      "genderNeutralWriting": "Guinean",
      "residentsMaleWriting": "The Guinean",
      "residentsFemaleWriting": "The Guinean Woman",
      "continent": "Africa"
    }
  },
  "GW": {
    "flag": "🇬🇼",
    "code": "GW",
    "isoCode": "GNB",
    "continent": "Africa",
    "DE": {
      "name": "Guinea-Bissau",
      "shortName": "Guinea-Bissau",
      "officialName": "Republik Guinea-Bissau",
      "genderNeutralWriting": "Guinea-Bissauer:in",
      "residentsMaleWriting": "der Guinea-Bissauer",
      "residentsFemaleWriting": "die Guinea-Bissauerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Guinée-Bissau",
      "shortName": "Guinée-Bissau",
      "officialName": "République de Guinée-Bissau",
      "genderNeutralWriting": "Bissau-Guinéen(ne)",
      "residentsMaleWriting": "Le Bissau-Guinéen",
      "residentsFemaleWriting": "La Bissau-Guinéenne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Guinea-Bissau",
      "shortName": "Guinea-Bissau",
      "officialName": "Repubblica di Guinea-Bissau",
      "genderNeutralWriting": "Guineano-bissauense",
      "residentsMaleWriting": "Il Guineano-bissauese",
      "residentsFemaleWriting": "La Guineano-bissauese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Guinea-Bissau",
      "shortName": "Guinea-Bissau",
      "officialName": "Republic of Guinea-Bissau",
      "genderNeutralWriting": "Bissau-Guinean",
      "residentsMaleWriting": "The Bissau-Guinean",
      "residentsFemaleWriting": "The Bissau-Guinean Woman",
      "continent": "Africa"
    }
  },
  "GY": {
    "flag": "🇬🇾",
    "code": "GY",
    "isoCode": "GUY",
    "continent": "America",
    "DE": {
      "name": "Guyana",
      "shortName": "Guyana",
      "officialName": "Kooperative Republik Guyana",
      "genderNeutralWriting": "Guyaner:in",
      "residentsMaleWriting": "der Guyaner",
      "residentsFemaleWriting": "die Guyanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Guyana",
      "shortName": "Guyana",
      "officialName": "République coopérative du Guyana",
      "genderNeutralWriting": "Guyanien(ne)",
      "residentsMaleWriting": "Le Guyanien",
      "residentsFemaleWriting": "La Guyanienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Guyana",
      "shortName": "Guyana",
      "officialName": "Repubblica Cooperativa di Guyana",
      "genderNeutralWriting": "Guyanese",
      "residentsMaleWriting": "Il Guyanese",
      "residentsFemaleWriting": "La Guyanese",
      "continent": "America"
    },
    "EN": {
      "name": "Guyana",
      "shortName": "Guyana",
      "officialName": "Co-operative Republic of Guyana",
      "genderNeutralWriting": "Guyanese",
      "residentsMaleWriting": "The Guyanese",
      "residentsFemaleWriting": "The Guyanese Woman",
      "continent": "America"
    }
  },
  "HT": {
    "flag": "🇭🇹",
    "code": "HT",
    "isoCode": "HTI",
    "continent": "America",
    "DE": {
      "name": "Haiti",
      "shortName": "Haiti",
      "officialName": "Republik Haiti",
      "genderNeutralWriting": "Haitianer:in",
      "residentsMaleWriting": "der Haitianer",
      "residentsFemaleWriting": "die Haitianerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Haïti",
      "shortName": "Haïti",
      "officialName": "République d'Haïti",
      "genderNeutralWriting": "Haïtien(ne)",
      "residentsMaleWriting": "L'Haïtien",
      "residentsFemaleWriting": "L'Haïtienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Haiti",
      "shortName": "Haiti",
      "officialName": "Repubblica di Haiti",
      "genderNeutralWriting": "Haitiano(a)",
      "residentsMaleWriting": "L'Haitiano",
      "residentsFemaleWriting": "L'Haitiana",
      "continent": "America"
    },
    "EN": {
      "name": "Haiti",
      "shortName": "Haiti",
      "officialName": "Republic of Haiti",
      "genderNeutralWriting": "Haitian",
      "residentsMaleWriting": "The Haitian",
      "residentsFemaleWriting": "The Haitian Woman",
      "continent": "America"
    }
  },
  "HM": {
    "flag": "🇭🇲",
    "code": "HM",
    "isoCode": "HMD",
    "continent": "Oceania",
    "DE": {
      "name": "Heard und McDonaldinseln",
      "shortName": "Heard und McDonaldinseln",
      "genderNeutralWriting": "Heard und McDonaldinseln",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Îles Heard-et-MacDonald",
      "shortName": "Îles Heard-et-MacDonald",
      "genderNeutralWriting": "Îles Heard-et-MacDonald",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isole Heard e McDonald",
      "shortName": "Isole Heard e McDonald",
      "genderNeutralWriting": "Isole Heard e McDonald",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Heard Island and McDonald Islands",
      "shortName": "Heard Island and McDonald Islands",
      "officialName": "Heard Island and McDonald Islands",
      "genderNeutralWriting": "Heard Island and McDonald Islands resident",
      "continent": "Oceania"
    }
  },
  "HN": {
    "flag": "🇭🇳",
    "code": "HN",
    "isoCode": "HND",
    "continent": "America",
    "DE": {
      "name": "Honduras",
      "shortName": "Honduras",
      "officialName": "Republik Honduras",
      "genderNeutralWriting": "Honduraner:in",
      "residentsMaleWriting": "der Honduraner",
      "residentsFemaleWriting": "die Honduranerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Honduras",
      "shortName": "Honduras",
      "officialName": "République du Honduras",
      "genderNeutralWriting": "Hondurien(ne)",
      "residentsMaleWriting": "L'Hondurien",
      "residentsFemaleWriting": "L'Hondurienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Honduras",
      "shortName": "Honduras",
      "officialName": "Repubblica dell'Honduras",
      "genderNeutralWriting": "Honduregno(a)",
      "residentsMaleWriting": "L'Honduregno",
      "residentsFemaleWriting": "L'Honduregna",
      "continent": "America"
    },
    "EN": {
      "name": "Honduras",
      "shortName": "Honduras",
      "officialName": "Republic of Honduras",
      "genderNeutralWriting": "Honduran",
      "residentsMaleWriting": "The Honduran",
      "residentsFemaleWriting": "The Honduran Woman",
      "continent": "America"
    }
  },
  "HK": {
    "flag": "🇭🇰",
    "code": "HK",
    "isoCode": "HKG",
    "continent": "Asia",
    "DE": {
      "name": "Hongkong",
      "shortName": "Hongkong",
      "genderNeutralWriting": "Hongkonger:in",
      "residentsMaleWriting": "der Hongkonger",
      "residentsFemaleWriting": "die Hongkongerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Hong Kong",
      "shortName": "Hong Kong",
      "genderNeutralWriting": "Hongkongais(e)",
      "residentsMaleWriting": "Le Hongkongais",
      "residentsFemaleWriting": "La Hongkongaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Hong Kong",
      "shortName": "Hong Kong",
      "genderNeutralWriting": "Hongkonghese",
      "residentsMaleWriting": "L'Hongkonghese",
      "residentsFemaleWriting": "L'Hongkonghese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Hong Kong",
      "shortName": "Hong Kong",
      "officialName": "Hong Kong Special Administrative Region of the People's Republic of China",
      "genderNeutralWriting": "Hong Konger",
      "residentsMaleWriting": "The Hong Konger",
      "residentsFemaleWriting": "The Hong Konger Woman",
      "continent": "Asia"
    }
  },
  "IN": {
    "flag": "🇮🇳",
    "code": "IN",
    "isoCode": "IND",
    "continent": "Asia",
    "DE": {
      "name": "Indien",
      "shortName": "Indien",
      "officialName": "Republik Indien",
      "genderNeutralWriting": "Inder:in",
      "residentsMaleWriting": "der Inder",
      "residentsFemaleWriting": "die Inderin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Inde",
      "shortName": "Inde",
      "officialName": "République de l'Inde",
      "genderNeutralWriting": "Indien(ne)",
      "residentsMaleWriting": "L'Indien",
      "residentsFemaleWriting": "L'Indienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "India",
      "shortName": "India",
      "officialName": "Repubblica dell'India",
      "genderNeutralWriting": "Indiano(a)",
      "residentsMaleWriting": "L'Indiano",
      "residentsFemaleWriting": "L'Indiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "India",
      "shortName": "India",
      "officialName": "Republic of India",
      "genderNeutralWriting": "Indian",
      "residentsMaleWriting": "The Indian",
      "residentsFemaleWriting": "The Indian Woman",
      "continent": "Asia"
    }
  },
  "ID": {
    "flag": "🇮🇩",
    "code": "ID",
    "isoCode": "IDN",
    "continent": "Asia",
    "DE": {
      "name": "Indonesien",
      "shortName": "Indonesien",
      "officialName": "Republik Indonesien",
      "genderNeutralWriting": "Indonesier:in",
      "residentsMaleWriting": "der Indonesier",
      "residentsFemaleWriting": "die Indonesierin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Indonésie",
      "shortName": "Indonésie",
      "officialName": "République d'Indonésie",
      "genderNeutralWriting": "Indonésien(ne)",
      "residentsMaleWriting": "L'Indonésien",
      "residentsFemaleWriting": "L'Indonésienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Indonesia",
      "shortName": "Indonesia",
      "officialName": "Repubblica di Indonesia",
      "genderNeutralWriting": "Indonesiano(a)",
      "residentsMaleWriting": "L'Indonesiano",
      "residentsFemaleWriting": "L'Indonesiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Indonesia",
      "shortName": "Indonesia",
      "officialName": "Republic of Indonesia",
      "genderNeutralWriting": "Indonesian",
      "residentsMaleWriting": "The Indonesian",
      "residentsFemaleWriting": "The Indonesian Woman",
      "continent": "Asia"
    }
  },
  "IR": {
    "flag": "🇮🇷",
    "code": "IR",
    "isoCode": "IRN",
    "continent": "Asia",
    "DE": {
      "name": "Iran",
      "shortName": "Iran",
      "officialName": "Islamische Republik Iran",
      "genderNeutralWriting": "Iraner:in",
      "residentsMaleWriting": "der Iraner",
      "residentsFemaleWriting": "die Iranerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Iran",
      "shortName": "Iran",
      "officialName": "République islamique d'Iran",
      "genderNeutralWriting": "Iranien(ne)",
      "residentsMaleWriting": "L'Iranien",
      "residentsFemaleWriting": "L'Iranienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Iran",
      "shortName": "Iran",
      "officialName": "Repubblica Islamica dell'Iran",
      "genderNeutralWriting": "Iraniano(a)",
      "residentsMaleWriting": "L'Iraniano",
      "residentsFemaleWriting": "L'Iraniana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Iran",
      "shortName": "Iran",
      "officialName": "Islamic Republic of Iran",
      "genderNeutralWriting": "Iranian",
      "residentsMaleWriting": "The Iranian",
      "residentsFemaleWriting": "The Iranian Woman",
      "continent": "Asia"
    }
  },
  "IQ": {
    "flag": "🇮🇶",
    "code": "IQ",
    "isoCode": "IRQ",
    "continent": "Asia",
    "DE": {
      "name": "Irak",
      "shortName": "Irak",
      "officialName": "Republik Irak",
      "genderNeutralWriting": "Iraker:in",
      "residentsMaleWriting": "der Iraker",
      "residentsFemaleWriting": "die Irakerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Irak",
      "shortName": "Irak",
      "officialName": "République d'Irak",
      "genderNeutralWriting": "Irakien(ne)",
      "residentsMaleWriting": "L'Irakien",
      "residentsFemaleWriting": "L'Irakienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Iraq",
      "shortName": "Iraq",
      "officialName": "Repubblica dell'Iraq",
      "genderNeutralWriting": "Iracheno(a)",
      "residentsMaleWriting": "L'Iracheno",
      "residentsFemaleWriting": "L'Irachena",
      "continent": "Asia"
    },
    "EN": {
      "name": "Iraq",
      "shortName": "Iraq",
      "officialName": "Republic of Iraq",
      "genderNeutralWriting": "Iraqi",
      "residentsMaleWriting": "The Iraqi",
      "residentsFemaleWriting": "The Iraqi Woman",
      "continent": "Asia"
    }
  },
  "IE": {
    "flag": "🇮🇪",
    "code": "IE",
    "isoCode": "IRL",
    "continent": "Europe",
    "DE": {
      "name": "Irland",
      "shortName": "Irland",
      "officialName": "Irland",
      "genderNeutralWriting": "Ire/Irin",
      "residentsMaleWriting": "der Ire",
      "residentsFemaleWriting": "die Irin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Irlande",
      "shortName": "Irlande",
      "officialName": "Irlande",
      "genderNeutralWriting": "Irlandais(e)",
      "residentsMaleWriting": "L'Irlandais",
      "residentsFemaleWriting": "L'Irlandaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Irlanda",
      "shortName": "Irlanda",
      "officialName": "Irlanda",
      "genderNeutralWriting": "Irlandese",
      "residentsMaleWriting": "L'Irlandese",
      "residentsFemaleWriting": "L'Irlandese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Ireland",
      "shortName": "Ireland",
      "officialName": "Ireland",
      "genderNeutralWriting": "Irish",
      "residentsMaleWriting": "The Irish",
      "residentsFemaleWriting": "The Irish Woman",
      "continent": "Europe"
    }
  },
  "IM": {
    "flag": "🇮🇲",
    "code": "IM",
    "isoCode": "IMN",
    "continent": "Europe",
    "DE": {
      "name": "Insel Man",
      "shortName": "Insel Man",
      "genderNeutralWriting": "Insel Man",
      "continent": "Europa"
    },
    "FR": {
      "name": "Île de Man",
      "shortName": "Île de Man",
      "genderNeutralWriting": "Mannois(e)",
      "continent": "Europe"
    },
    "IT": {
      "name": "Isola di Man",
      "shortName": "Isola di Man",
      "genderNeutralWriting": "Mannese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Isle of Man",
      "shortName": "Isle of Man",
      "officialName": "Isle of Man",
      "genderNeutralWriting": "Manx",
      "continent": "Europe"
    }
  },
  "IL": {
    "flag": "🇮🇱",
    "code": "IL",
    "isoCode": "ISR",
    "continent": "Asia",
    "DE": {
      "name": "Israel",
      "shortName": "Israel",
      "officialName": "Staat Israel",
      "genderNeutralWriting": "Israeli",
      "residentsMaleWriting": "der Israeli",
      "residentsFemaleWriting": "die Israeli",
      "continent": "Asien"
    },
    "FR": {
      "name": "Israël",
      "shortName": "Israël",
      "officialName": "État d'Israël",
      "genderNeutralWriting": "Israélien(ne)",
      "residentsMaleWriting": "L'Israélien",
      "residentsFemaleWriting": "L'Israélienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Israele",
      "shortName": "Israele",
      "officialName": "Stato di Israele",
      "genderNeutralWriting": "Israeliano(a)",
      "residentsMaleWriting": "L'Israeliano",
      "residentsFemaleWriting": "L'Israeliana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Israel",
      "shortName": "Israel",
      "officialName": "State of Israel",
      "genderNeutralWriting": "Israeli",
      "residentsMaleWriting": "The Israeli",
      "residentsFemaleWriting": "The Israeli Woman",
      "continent": "Asia"
    }
  },
  "IT": {
    "flag": "🇮🇹",
    "code": "IT",
    "isoCode": "ITA",
    "continent": "Europe",
    "DE": {
      "name": "Italien",
      "shortName": "Italien",
      "officialName": "Italienische Republik",
      "genderNeutralWriting": "Italiener:in",
      "residentsMaleWriting": "der Italiener",
      "residentsFemaleWriting": "die Italienerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Italie",
      "shortName": "Italie",
      "officialName": "République italienne",
      "genderNeutralWriting": "Italien(ne)",
      "residentsMaleWriting": "L'Italien",
      "residentsFemaleWriting": "L'Italienne",
      "continent": "Europe"
    },
    "IT": {
      "name": "Italia",
      "shortName": "Italia",
      "officialName": "Repubblica Italiana",
      "genderNeutralWriting": "Italiano(a)",
      "residentsMaleWriting": "L'Italiano",
      "residentsFemaleWriting": "L'Italiana",
      "continent": "Europa"
    },
    "EN": {
      "name": "Italy",
      "shortName": "Italy",
      "officialName": "Italian Republic",
      "genderNeutralWriting": "Italian",
      "residentsMaleWriting": "The Italian",
      "residentsFemaleWriting": "The Italian Woman",
      "continent": "Europe"
    }
  },
  "JM": {
    "flag": "🇯🇲",
    "code": "JM",
    "isoCode": "JAM",
    "continent": "America",
    "DE": {
      "name": "Jamaika",
      "shortName": "Jamaika",
      "officialName": "Jamaika",
      "genderNeutralWriting": "Jamaikaner:in",
      "residentsMaleWriting": "der Jamaikaner",
      "residentsFemaleWriting": "die Jamaikanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Jamaïque",
      "shortName": "Jamaïque",
      "officialName": "Jamaïque",
      "genderNeutralWriting": "Jamaïcain(e)",
      "residentsMaleWriting": "Le Jamaïcain",
      "residentsFemaleWriting": "La Jamaïcaine",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Giamaica",
      "shortName": "Giamaica",
      "officialName": "Giamaica",
      "genderNeutralWriting": "Giamaicano(a)",
      "residentsMaleWriting": "Il Giamaicano",
      "residentsFemaleWriting": "La Giamaicana",
      "continent": "America"
    },
    "EN": {
      "name": "Jamaica",
      "shortName": "Jamaica",
      "officialName": "Jamaica",
      "genderNeutralWriting": "Jamaican",
      "residentsMaleWriting": "The Jamaican",
      "residentsFemaleWriting": "The Jamaican Woman",
      "continent": "America"
    }
  },
  "JP": {
    "flag": "🇯🇵",
    "code": "JP",
    "isoCode": "JPN",
    "continent": "Asia",
    "DE": {
      "name": "Japan",
      "shortName": "Japan",
      "officialName": "Japan",
      "genderNeutralWriting": "Japaner:in",
      "residentsMaleWriting": "der Japaner",
      "residentsFemaleWriting": "die Japanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Japon",
      "shortName": "Japon",
      "officialName": "Japon",
      "genderNeutralWriting": "Japonais(e)",
      "residentsMaleWriting": "Le Japonais",
      "residentsFemaleWriting": "La Japonaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Giappone",
      "shortName": "Giappone",
      "officialName": "Giappone",
      "genderNeutralWriting": "Giapponese",
      "residentsMaleWriting": "Il Giapponese",
      "residentsFemaleWriting": "La Giapponese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Japan",
      "shortName": "Japan",
      "officialName": "Japan",
      "genderNeutralWriting": "Japanese",
      "residentsMaleWriting": "The Japanese",
      "residentsFemaleWriting": "The Japanese Woman",
      "continent": "Asia"
    }
  },
  "YE": {
    "flag": "🇾🇪",
    "code": "YE",
    "isoCode": "YEM",
    "continent": "Asia",
    "DE": {
      "name": "Jemen",
      "shortName": "Jemen",
      "officialName": "Republik Jemen",
      "genderNeutralWriting": "Jemenit:in",
      "residentsMaleWriting": "der Jemenit",
      "residentsFemaleWriting": "die Jemenitin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Yémen",
      "shortName": "Yémen",
      "officialName": "République du Yémen",
      "genderNeutralWriting": "Yéménite",
      "residentsMaleWriting": "le Yéménite",
      "residentsFemaleWriting": "la Yéménite",
      "continent": "Asie"
    },
    "IT": {
      "name": "Yemen",
      "shortName": "Yemen",
      "officialName": "Repubblica dello Yemen",
      "genderNeutralWriting": "Yemenita",
      "residentsMaleWriting": "lo Yemenita",
      "residentsFemaleWriting": "la Yemenita",
      "continent": "Asia"
    },
    "EN": {
      "name": "Yemen",
      "shortName": "Yemen",
      "officialName": "Republic of Yemen",
      "genderNeutralWriting": "Yemeni",
      "residentsMaleWriting": "the Yemeni",
      "residentsFemaleWriting": "the Yemeni Woman",
      "continent": "Asia"
    }
  },
  "JE": {
    "flag": "🇯🇪",
    "code": "JE",
    "isoCode": "JEY",
    "continent": "Europe",
    "DE": {
      "name": "Jersey",
      "shortName": "Jersey",
      "genderNeutralWriting": "Jersey",
      "continent": "Europa"
    },
    "FR": {
      "name": "Jersey",
      "shortName": "Jersey",
      "genderNeutralWriting": "Jersiais(e)",
      "continent": "Europe"
    },
    "IT": {
      "name": "Jersey",
      "shortName": "Jersey",
      "genderNeutralWriting": "Jerseyano(a)",
      "continent": "Europa"
    },
    "EN": {
      "name": "Jersey",
      "shortName": "Jersey",
      "officialName": "Bailiwick of Jersey",
      "genderNeutralWriting": "Jersey resident",
      "continent": "Europe"
    }
  },
  "UM": {
    "flag": "🏳️",
    "code": "UM",
    "isoCode": "UMI",
    "continent": "Oceania",
    "DE": {
      "name": "Wakeinsel",
      "shortName": "Wakeinsel",
      "genderNeutralWriting": "Wakeinsel",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Wakeinsel",
      "shortName": "Île Wake",
      "genderNeutralWriting": "Île Wake",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Wakeinsel",
      "shortName": "Isola Wake",
      "genderNeutralWriting": "Isola Wake",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Wakeinsel",
      "shortName": "Wake Island",
      "officialName": "Wake Island",
      "genderNeutralWriting": "Wake Island",
      "continent": "Oceania"
    }
  },
  "JO": {
    "flag": "🇯🇴",
    "code": "JO",
    "isoCode": "JOR",
    "continent": "Asia",
    "DE": {
      "name": "Jordanien",
      "shortName": "Jordanien",
      "officialName": "Haschemitisches Königreich Jordanien",
      "genderNeutralWriting": "Jordanier:in",
      "residentsMaleWriting": "der Jordanier",
      "residentsFemaleWriting": "die Jordanierin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Jordanie",
      "shortName": "Jordanie",
      "officialName": "Royaume hachémite de Jordanie",
      "genderNeutralWriting": "Jordanien(ne)",
      "residentsMaleWriting": "le Jordanien",
      "residentsFemaleWriting": "la Jordanienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Giordania",
      "shortName": "Giordania",
      "officialName": "Regno hashemita di Giordania",
      "genderNeutralWriting": "Giordano/a",
      "residentsMaleWriting": "il Giordano",
      "residentsFemaleWriting": "la Giordana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Jordan",
      "shortName": "Jordan",
      "officialName": "Hashemite Kingdom of Jordan",
      "genderNeutralWriting": "Jordanian",
      "residentsMaleWriting": "the Jordanian",
      "residentsFemaleWriting": "the Jordanian woman",
      "continent": "Asia"
    }
  },
  "VG": {
    "flag": "🇻🇬",
    "code": "VG",
    "isoCode": "VGB",
    "continent": "America",
    "DE": {
      "name": "Britische Jungferninseln",
      "shortName": "Jungferninseln (UK)",
      "genderNeutralWriting": "Jungferninseln (UK)",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Îles Vierges britanniques",
      "shortName": "Îles Vierges britanniques",
      "genderNeutralWriting": "Îles Vierges britanniques",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Isole Vergini britanniche",
      "shortName": "Isole Vergini britanniche",
      "genderNeutralWriting": "Isole Vergini britanniche",
      "continent": "America"
    },
    "EN": {
      "name": "British Virgin Islands",
      "shortName": "British Virgin Islands",
      "officialName": "British Virgin Islands",
      "genderNeutralWriting": "British Virgin Islands",
      "continent": "America"
    }
  },
  "VI": {
    "flag": "🇻🇮",
    "code": "VI",
    "isoCode": "VIR",
    "continent": "America",
    "DE": {
      "name": "Amerikanische Jungferninseln",
      "shortName": "Jungferninseln (USA)",
      "genderNeutralWriting": "Jungferninseln (USA)",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Îles Vierges américaines",
      "shortName": "Îles Vierges américaines",
      "genderNeutralWriting": "Îles Vierges américaines",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Isole Vergini americane",
      "shortName": "Isole Vergini americane",
      "genderNeutralWriting": "Isole Vergini americane",
      "continent": "America"
    },
    "EN": {
      "name": "U.S. Virgin Islands",
      "shortName": "U.S. Virgin Islands",
      "officialName": "U.S. Virgin Islands",
      "genderNeutralWriting": "U.S. Virgin Islands",
      "continent": "America"
    }
  },
  "KY": {
    "flag": "🇰🇾",
    "code": "KY",
    "isoCode": "CYM",
    "continent": "America",
    "DE": {
      "name": "Kaimaninseln",
      "shortName": "Kaimaninseln",
      "genderNeutralWriting": "Kaimaninseln",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Îles Caïmans",
      "shortName": "Îles Caïmans",
      "genderNeutralWriting": "Îles Caïmans",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Isole Cayman",
      "shortName": "Isole Cayman",
      "genderNeutralWriting": "Isole Cayman",
      "continent": "America"
    },
    "EN": {
      "name": "Cayman Islands",
      "shortName": "Cayman Islands",
      "officialName": "Cayman Islands",
      "genderNeutralWriting": "Cayman Islands",
      "continent": "America"
    }
  },
  "KH": {
    "flag": "🇰🇭",
    "code": "KH",
    "isoCode": "KHM",
    "continent": "Asia",
    "DE": {
      "name": "Kambodscha",
      "shortName": "Kambodscha",
      "officialName": "Königreich Kambodscha",
      "genderNeutralWriting": "Kambodschaner:in",
      "residentsMaleWriting": "der Kambodschaner",
      "residentsFemaleWriting": "die Kambodschanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Cambodge",
      "shortName": "Cambodge",
      "officialName": "Royaume du Cambodge",
      "genderNeutralWriting": "Cambodgien(ne)",
      "residentsMaleWriting": "le Cambodgien",
      "residentsFemaleWriting": "la Cambodgienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Cambogia",
      "shortName": "Cambogia",
      "officialName": "Regno di Cambogia",
      "genderNeutralWriting": "Cambogiano/a",
      "residentsMaleWriting": "il Cambogiano",
      "residentsFemaleWriting": "la Cambogiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Cambodia",
      "shortName": "Cambodia",
      "officialName": "Kingdom of Cambodia",
      "genderNeutralWriting": "Cambodian",
      "residentsMaleWriting": "the Cambodian",
      "residentsFemaleWriting": "the Cambodian woman",
      "continent": "Asia"
    }
  },
  "CM": {
    "flag": "🇨🇲",
    "code": "CM",
    "isoCode": "CMR",
    "continent": "Africa",
    "DE": {
      "name": "Kamerun",
      "shortName": "Kamerun",
      "officialName": "Republik Kamerun",
      "genderNeutralWriting": "Kameruner:in",
      "residentsMaleWriting": "der Kameruner",
      "residentsFemaleWriting": "die Kamerunerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Cameroun",
      "shortName": "Cameroun",
      "officialName": "République du Cameroun",
      "genderNeutralWriting": "Camerounais(e)",
      "residentsMaleWriting": "le Camerounais",
      "residentsFemaleWriting": "la Camerounaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Camerun",
      "shortName": "Camerun",
      "officialName": "Repubblica del Camerun",
      "genderNeutralWriting": "Camerunese",
      "residentsMaleWriting": "il Camerunese",
      "residentsFemaleWriting": "la Camerunese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Cameroon",
      "shortName": "Cameroon",
      "officialName": "Republic of Cameroon",
      "genderNeutralWriting": "Cameroonian",
      "residentsMaleWriting": "the Cameroonian",
      "residentsFemaleWriting": "the Cameroonian woman",
      "continent": "Africa"
    }
  },
  "CA": {
    "flag": "🇨🇦",
    "code": "CA",
    "isoCode": "CAN",
    "continent": "America",
    "DE": {
      "name": "Kanada",
      "shortName": "Kanada",
      "officialName": "Kanada",
      "genderNeutralWriting": "Kanadier:in",
      "residentsMaleWriting": "der Kanadier",
      "residentsFemaleWriting": "die Kanadierin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Canada",
      "shortName": "Canada",
      "officialName": "Canada",
      "genderNeutralWriting": "Canadien(ne)",
      "residentsMaleWriting": "le Canadien",
      "residentsFemaleWriting": "la Canadienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Canada",
      "shortName": "Canada",
      "officialName": "Canada",
      "genderNeutralWriting": "Canadese",
      "residentsMaleWriting": "il Canadese",
      "residentsFemaleWriting": "la Canadese",
      "continent": "America"
    },
    "EN": {
      "name": "Canada",
      "shortName": "Canada",
      "officialName": "Canada",
      "genderNeutralWriting": "Canadian",
      "residentsMaleWriting": "the Canadian",
      "residentsFemaleWriting": "the Canadian woman",
      "continent": "America"
    }
  },
  "KZ": {
    "flag": "🇰🇿",
    "code": "KZ",
    "isoCode": "KAZ",
    "continent": "Asia",
    "DE": {
      "name": "Kasachstan",
      "shortName": "Kasachstan",
      "officialName": "Republik Kasachstan",
      "genderNeutralWriting": "Kasache/Kasachin",
      "residentsMaleWriting": "der Kasache",
      "residentsFemaleWriting": "die Kasachin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Kazakhstan",
      "shortName": "Kazakhstan",
      "officialName": "République du Kazakhstan",
      "genderNeutralWriting": "Kazakh(e)",
      "residentsMaleWriting": "le Kazakh",
      "residentsFemaleWriting": "la Kazakhe",
      "continent": "Asie"
    },
    "IT": {
      "name": "Kazakistan",
      "shortName": "Kazakistan",
      "officialName": "Repubblica del Kazakistan",
      "genderNeutralWriting": "Kazako/Kazaka",
      "residentsMaleWriting": "il Kazako",
      "residentsFemaleWriting": "la Kazaka",
      "continent": "Asia"
    },
    "EN": {
      "name": "Kazakhstan",
      "shortName": "Kazakhstan",
      "officialName": "Republic of Kazakhstan",
      "genderNeutralWriting": "Kazakh",
      "residentsMaleWriting": "the Kazakh",
      "residentsFemaleWriting": "the Kazakh woman",
      "continent": "Asia"
    }
  },
  "QA": {
    "flag": "🇶🇦",
    "code": "QA",
    "isoCode": "QAT",
    "continent": "Asia",
    "DE": {
      "name": "Katar",
      "shortName": "Katar",
      "officialName": "Staat Katar",
      "genderNeutralWriting": "Katarer:in",
      "residentsMaleWriting": "der Katarer",
      "residentsFemaleWriting": "die Katarerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Qatar",
      "shortName": "Qatar",
      "officialName": "État du Qatar",
      "genderNeutralWriting": "Qatarien(ne)",
      "residentsMaleWriting": "le Qatarien",
      "residentsFemaleWriting": "la Qatarienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Qatar",
      "shortName": "Qatar",
      "officialName": "Stato del Qatar",
      "genderNeutralWriting": "Qatariota",
      "residentsMaleWriting": "il Qatariota",
      "residentsFemaleWriting": "la Qatariota",
      "continent": "Asia"
    },
    "EN": {
      "name": "Qatar",
      "shortName": "Qatar",
      "officialName": "State of Qatar",
      "genderNeutralWriting": "Qatari",
      "residentsMaleWriting": "the Qatari",
      "residentsFemaleWriting": "the Qatari woman",
      "continent": "Asia"
    }
  },
  "KE": {
    "flag": "🇰🇪",
    "code": "KE",
    "isoCode": "KEN",
    "continent": "Africa",
    "DE": {
      "name": "Kenia",
      "shortName": "Kenia",
      "officialName": "Republik Kenia",
      "genderNeutralWriting": "Kenianer:in",
      "residentsMaleWriting": "der Kenianer",
      "residentsFemaleWriting": "die Kenianerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Kenya",
      "shortName": "Kenya",
      "officialName": "République du Kenya",
      "genderNeutralWriting": "Kényan(e)",
      "residentsMaleWriting": "le Kényan",
      "residentsFemaleWriting": "la Kényane",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Kenya",
      "shortName": "Kenya",
      "officialName": "Repubblica del Kenya",
      "genderNeutralWriting": "Keniota",
      "residentsMaleWriting": "il Keniota",
      "residentsFemaleWriting": "la Keniota",
      "continent": "Africa"
    },
    "EN": {
      "name": "Kenya",
      "shortName": "Kenya",
      "officialName": "Republic of Kenya",
      "genderNeutralWriting": "Kenyan",
      "residentsMaleWriting": "the Kenyan",
      "residentsFemaleWriting": "the Kenyan woman",
      "continent": "Africa"
    }
  },
  "KG": {
    "flag": "🇰🇬",
    "code": "KG",
    "isoCode": "KGZ",
    "continent": "Asia",
    "DE": {
      "name": "Kirgisistan",
      "shortName": "Kirgisistan",
      "officialName": "Kirgisische Republik",
      "genderNeutralWriting": "Kirgise/Kirgisin",
      "residentsMaleWriting": "der Kirgise",
      "residentsFemaleWriting": "die Kirgisin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Kirghizistan",
      "shortName": "Kirghizistan",
      "officialName": "République kirghize",
      "genderNeutralWriting": "Kirghiz(e)",
      "residentsMaleWriting": "le Kirghiz",
      "residentsFemaleWriting": "la Kirghize",
      "continent": "Asie"
    },
    "IT": {
      "name": "Kirghizistan",
      "shortName": "Kirghizistan",
      "officialName": "Repubblica del Kirghizistan",
      "genderNeutralWriting": "Kirghiso/Kirghisa",
      "residentsMaleWriting": "il Kirghiso",
      "residentsFemaleWriting": "la Kirghisa",
      "continent": "Asia"
    },
    "EN": {
      "name": "Kyrgyzstan",
      "shortName": "Kyrgyzstan",
      "officialName": "Kyrgyz Republic",
      "genderNeutralWriting": "Kyrgyz",
      "residentsMaleWriting": "the Kyrgyz",
      "residentsFemaleWriting": "the Kyrgyz woman",
      "continent": "Asia"
    }
  },
  "KI": {
    "flag": "🇰🇮",
    "code": "KI",
    "isoCode": "KIR",
    "continent": "Oceania",
    "DE": {
      "name": "Kiribati",
      "shortName": "Kiribati",
      "officialName": "Republik Kiribati",
      "genderNeutralWriting": "Kiribatier:in",
      "residentsMaleWriting": "der Kiribatier",
      "residentsFemaleWriting": "die Kiribatierin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Kiribati",
      "shortName": "Kiribati",
      "officialName": "République de Kiribati",
      "genderNeutralWriting": "Kiribatien(ne)",
      "residentsMaleWriting": "le Kiribatien",
      "residentsFemaleWriting": "la Kiribatienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Kiribati",
      "shortName": "Kiribati",
      "officialName": "Repubblica di Kiribati",
      "genderNeutralWriting": "Kiribatiano/a",
      "residentsMaleWriting": "il Kiribatiano",
      "residentsFemaleWriting": "la Kiribatiana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Kiribati",
      "shortName": "Kiribati",
      "officialName": "Republic of Kiribati",
      "genderNeutralWriting": "I-Kiribati",
      "residentsMaleWriting": "the I-Kiribati",
      "residentsFemaleWriting": "the I-Kiribati woman",
      "continent": "Oceania"
    }
  },
  "CC": {
    "flag": "🇨🇨",
    "code": "CC",
    "isoCode": "CCK",
    "continent": "Oceania",
    "DE": {
      "name": "Kokosinseln",
      "shortName": "Kokosinseln",
      "genderNeutralWriting": "Kokosinseln",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Îles Cocos (Keeling)",
      "shortName": "Îles Cocos (Keeling)",
      "genderNeutralWriting": "Îles Cocos (Keeling)",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isole Cocos (Keeling)",
      "shortName": "Isole Cocos (Keeling)",
      "genderNeutralWriting": "Isole Cocos (Keeling)",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Cocos (Keeling) Islands",
      "shortName": "Cocos (Keeling) Islands",
      "officialName": "Territory of Cocos (Keeling) Islands",
      "genderNeutralWriting": "Cocos (Keeling) Islands",
      "continent": "Oceania"
    }
  },
  "CO": {
    "flag": "🇨🇴",
    "code": "CO",
    "isoCode": "COL",
    "continent": "America",
    "DE": {
      "name": "Kolumbien",
      "shortName": "Kolumbien",
      "officialName": "Republik Kolumbien",
      "genderNeutralWriting": "Kolumbianer:in",
      "residentsMaleWriting": "der Kolumbianer",
      "residentsFemaleWriting": "die Kolumbianerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Colombie",
      "shortName": "Colombie",
      "officialName": "République de Colombie",
      "genderNeutralWriting": "Colombien(ne)",
      "residentsMaleWriting": "le Colombien",
      "residentsFemaleWriting": "la Colombienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Colombia",
      "shortName": "Colombia",
      "officialName": "Repubblica di Colombia",
      "genderNeutralWriting": "Colombiano/a",
      "residentsMaleWriting": "il Colombiano",
      "residentsFemaleWriting": "la Colombiana",
      "continent": "America"
    },
    "EN": {
      "name": "Colombia",
      "shortName": "Colombia",
      "officialName": "Republic of Colombia",
      "genderNeutralWriting": "Colombian",
      "residentsMaleWriting": "the Colombian",
      "residentsFemaleWriting": "the Colombian woman",
      "continent": "America"
    }
  },
  "KM": {
    "flag": "🇰🇲",
    "code": "KM",
    "isoCode": "COM",
    "continent": "Africa",
    "DE": {
      "name": "Komoren",
      "shortName": "Komoren",
      "officialName": "Union der Komoren",
      "genderNeutralWriting": "Komorer:in",
      "residentsMaleWriting": "der Komorer",
      "residentsFemaleWriting": "die Komorerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Comores",
      "shortName": "Comores",
      "officialName": "Union des Comores",
      "genderNeutralWriting": "Comorien(ne)",
      "residentsMaleWriting": "le Comorien",
      "residentsFemaleWriting": "la Comorienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Comore",
      "shortName": "Comore",
      "officialName": "Unione delle Comore",
      "genderNeutralWriting": "Comoriano/a",
      "residentsMaleWriting": "il Comoriano",
      "residentsFemaleWriting": "la Comoriana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Comoros",
      "shortName": "Comoros",
      "officialName": "Union of the Comoros",
      "genderNeutralWriting": "Comorian",
      "residentsMaleWriting": "the Comorian",
      "residentsFemaleWriting": "the Comorian woman",
      "continent": "Africa"
    }
  },
  "CG": {
    "flag": "🇨🇬",
    "code": "CG",
    "isoCode": "COG",
    "continent": "Africa",
    "DE": {
      "name": "Kongo",
      "shortName": "Kongo (Brazzaville)",
      "officialName": "Republik Kongo",
      "genderNeutralWriting": "Kongolese:in",
      "residentsMaleWriting": "der Kongolese",
      "residentsFemaleWriting": "die Kongolesin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Congo",
      "shortName": "Congo (Brazzaville)",
      "officialName": "République du Congo",
      "genderNeutralWriting": "Congolais(e)",
      "residentsMaleWriting": "le Congolais",
      "residentsFemaleWriting": "la Congolaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Congo",
      "shortName": "Congo (Brazzaville)",
      "officialName": "Repubblica del Congo",
      "genderNeutralWriting": "Congolese",
      "residentsMaleWriting": "il Congolese",
      "residentsFemaleWriting": "la Congolese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Congo",
      "shortName": "Congo (Brazzaville)",
      "officialName": "Republic of the Congo",
      "genderNeutralWriting": "Congolese",
      "residentsMaleWriting": "the Congolese",
      "residentsFemaleWriting": "the Congolese woman",
      "continent": "Africa"
    }
  },
  "CD": {
    "flag": "🇨🇩",
    "code": "CD",
    "isoCode": "COD",
    "continent": "Africa",
    "DE": {
      "name": "Demokratische Republik Kongo",
      "shortName": "Kongo (Kinshasa)",
      "officialName": "Demokratische Republik Kongo",
      "genderNeutralWriting": "Kongolese:in",
      "residentsMaleWriting": "der Kongolese",
      "residentsFemaleWriting": "die Kongolesin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "République démocratique du Congo",
      "shortName": "Congo (Kinshasa)",
      "officialName": "République démocratique du Congo",
      "genderNeutralWriting": "Congolais(e)",
      "residentsMaleWriting": "le Congolais",
      "residentsFemaleWriting": "la Congolaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Repubblica Democratica del Congo",
      "shortName": "Congo (Kinshasa)",
      "officialName": "Repubblica Democratica del Congo",
      "genderNeutralWriting": "Congolese",
      "residentsMaleWriting": "il Congolese",
      "residentsFemaleWriting": "la Congolese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Democratic Republic of the Congo",
      "shortName": "Congo (Kinshasa)",
      "officialName": "Democratic Republic of the Congo",
      "genderNeutralWriting": "Congolese",
      "residentsMaleWriting": "the Congolese",
      "residentsFemaleWriting": "the Congolese woman",
      "continent": "Africa"
    }
  },
  "KP": {
    "flag": "🇰🇵",
    "code": "KP",
    "isoCode": "PRK",
    "continent": "Asien",
    "DE": {
      "name": "Nordkorea",
      "shortName": "Korea (Nord-)",
      "officialName": "Demokratische Volksrepublik Korea",
      "genderNeutralWriting": "Nordkoreaner:in",
      "residentsMaleWriting": "der Nordkoreaner",
      "residentsFemaleWriting": "die Nordkoreanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Corée du Nord",
      "shortName": "Corée (Nord)",
      "officialName": "République populaire démocratique de Corée",
      "genderNeutralWriting": "Nord-Coréen(ne)",
      "residentsMaleWriting": "le Nord-Coréen",
      "residentsFemaleWriting": "la Nord-Coréenne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Corea del Nord",
      "shortName": "Corea (Nord)",
      "officialName": "Repubblica Popolare Democratica di Corea",
      "genderNeutralWriting": "Nordcoreano/a",
      "residentsMaleWriting": "il Nordcoreano",
      "residentsFemaleWriting": "la Nordcoreana",
      "continent": "Asia"
    },
    "EN": {
      "name": "North Korea",
      "shortName": "North Korea",
      "officialName": "Democratic People's Republic of Korea",
      "genderNeutralWriting": "North Korean",
      "residentsMaleWriting": "the North Korean",
      "residentsFemaleWriting": "the North Korean woman",
      "continent": "Asia"
    }
  },
  "KR": {
    "flag": "🇰🇷",
    "code": "KR",
    "isoCode": "KOR",
    "continent": "Asien",
    "DE": {
      "name": "Südkorea",
      "shortName": "Korea (Süd-)",
      "officialName": "Republik Korea",
      "genderNeutralWriting": "Südkoreaner:in",
      "residentsMaleWriting": "der Südkoreaner",
      "residentsFemaleWriting": "die Südkoreanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Corée du Sud",
      "shortName": "Corée (Sud)",
      "officialName": "République de Corée",
      "genderNeutralWriting": "Sud-Coréen(ne)",
      "residentsMaleWriting": "le Sud-Coréen",
      "residentsFemaleWriting": "la Sud-Coréenne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Corea del Sud",
      "shortName": "Corea (Sud)",
      "officialName": "Repubblica di Corea",
      "genderNeutralWriting": "Sudcoreano/a",
      "residentsMaleWriting": "il Sudcoreano",
      "residentsFemaleWriting": "la Sudcoreana",
      "continent": "Asia"
    },
    "EN": {
      "name": "South Korea",
      "shortName": "South Korea",
      "officialName": "Republic of Korea",
      "genderNeutralWriting": "South Korean",
      "residentsMaleWriting": "the South Korean",
      "residentsFemaleWriting": "the South Korean woman",
      "continent": "Asia"
    }
  },
  "HR": {
    "flag": "🇭🇷",
    "code": "HR",
    "isoCode": "HRV",
    "continent": "Europe",
    "DE": {
      "name": "Kroatien",
      "shortName": "Kroatien",
      "officialName": "Republik Kroatien",
      "genderNeutralWriting": "Kroate/Kroatin",
      "residentsMaleWriting": "der Kroate",
      "residentsFemaleWriting": "die Kroatin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Croatie",
      "shortName": "Croatie",
      "officialName": "République de Croatie",
      "genderNeutralWriting": "Croate",
      "residentsMaleWriting": "le Croate",
      "residentsFemaleWriting": "la Croate",
      "continent": "Europe"
    },
    "IT": {
      "name": "Croazia",
      "shortName": "Croazia",
      "officialName": "Repubblica di Croazia",
      "genderNeutralWriting": "Croato/Croata",
      "residentsMaleWriting": "il Croato",
      "residentsFemaleWriting": "la Croata",
      "continent": "Europa"
    },
    "EN": {
      "name": "Croatia",
      "shortName": "Croatia",
      "officialName": "Republic of Croatia",
      "genderNeutralWriting": "Croatian",
      "residentsMaleWriting": "the Croatian",
      "residentsFemaleWriting": "the Croatian woman",
      "continent": "Europe"
    }
  },
  "CU": {
    "flag": "🇨🇺",
    "code": "CU",
    "isoCode": "CUB",
    "continent": "America",
    "DE": {
      "name": "Kuba",
      "shortName": "Kuba",
      "officialName": "Republik Kuba",
      "genderNeutralWriting": "Kubaner:in",
      "residentsMaleWriting": "der Kubaner",
      "residentsFemaleWriting": "die Kubanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Cuba",
      "shortName": "Cuba",
      "officialName": "République de Cuba",
      "genderNeutralWriting": "Cubain(e)",
      "residentsMaleWriting": "le Cubain",
      "residentsFemaleWriting": "la Cubaine",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Cuba",
      "shortName": "Cuba",
      "officialName": "Repubblica di Cuba",
      "genderNeutralWriting": "Cubano/a",
      "residentsMaleWriting": "il Cubano",
      "residentsFemaleWriting": "la Cubana",
      "continent": "America"
    },
    "EN": {
      "name": "Cuba",
      "shortName": "Cuba",
      "officialName": "Republic of Cuba",
      "genderNeutralWriting": "Cuban",
      "residentsMaleWriting": "the Cuban",
      "residentsFemaleWriting": "the Cuban woman",
      "continent": "America"
    }
  },
  "KW": {
    "flag": "🇰🇼",
    "code": "KW",
    "isoCode": "KWT",
    "continent": "Asien",
    "DE": {
      "name": "Kuwait",
      "shortName": "Kuwait",
      "officialName": "Staat Kuwait",
      "genderNeutralWriting": "Kuwaiter:in",
      "residentsMaleWriting": "der Kuwaiter",
      "residentsFemaleWriting": "die Kuwaiterin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Koweït",
      "shortName": "Koweït",
      "officialName": "État du Koweït",
      "genderNeutralWriting": "Koweïtien(ne)",
      "residentsMaleWriting": "le Koweïtien",
      "residentsFemaleWriting": "la Koweïtienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Kuwait",
      "shortName": "Kuwait",
      "officialName": "Stato del Kuwait",
      "genderNeutralWriting": "Kuwaitiano/a",
      "residentsMaleWriting": "il Kuwaitiano",
      "residentsFemaleWriting": "la Kuwaitiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Kuwait",
      "shortName": "Kuwait",
      "officialName": "State of Kuwait",
      "genderNeutralWriting": "Kuwaiti",
      "residentsMaleWriting": "the Kuwaiti",
      "residentsFemaleWriting": "the Kuwaiti woman",
      "continent": "Asia"
    }
  },
  "LA": {
    "flag": "🇱🇦",
    "code": "LA",
    "isoCode": "LAO",
    "continent": "Asien",
    "DE": {
      "name": "Laos",
      "shortName": "Laos",
      "officialName": "Demokratische Volksrepublik Laos",
      "genderNeutralWriting": "Laote/Laotin",
      "residentsMaleWriting": "der Laote",
      "residentsFemaleWriting": "die Laotin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Laos",
      "shortName": "Laos",
      "officialName": "République démocratique populaire lao",
      "genderNeutralWriting": "Laotien(ne)",
      "residentsMaleWriting": "le Laotien",
      "residentsFemaleWriting": "la Laotienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Laos",
      "shortName": "Laos",
      "officialName": "Repubblica democratica popolare del Laos",
      "genderNeutralWriting": "Laotiano/a",
      "residentsMaleWriting": "il Laotiano",
      "residentsFemaleWriting": "la Laotiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Laos",
      "shortName": "Laos",
      "officialName": "Lao People's Democratic Republic",
      "genderNeutralWriting": "Laotian",
      "residentsMaleWriting": "the Laotian",
      "residentsFemaleWriting": "the Laotian woman",
      "continent": "Asia"
    }
  },
  "LS": {
    "flag": "🇱🇸",
    "code": "LS",
    "isoCode": "LSO",
    "continent": "Africa",
    "DE": {
      "name": "Lesotho",
      "shortName": "Lesotho",
      "officialName": "Königreich Lesotho",
      "genderNeutralWriting": "Lesother:in",
      "residentsMaleWriting": "der Lesother",
      "residentsFemaleWriting": "die Lesotherin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Lesotho",
      "shortName": "Lesotho",
      "officialName": "Royaume du Lesotho",
      "genderNeutralWriting": "Lesothan(e)",
      "residentsMaleWriting": "le Lesothan",
      "residentsFemaleWriting": "la Lesothane",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Lesotho",
      "shortName": "Lesotho",
      "officialName": "Regno del Lesotho",
      "genderNeutralWriting": "Lesothiano/a",
      "residentsMaleWriting": "il Lesothiano",
      "residentsFemaleWriting": "la Lesothiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Lesotho",
      "shortName": "Lesotho",
      "officialName": "Kingdom of Lesotho",
      "genderNeutralWriting": "Lesothan",
      "residentsMaleWriting": "the Lesothan",
      "residentsFemaleWriting": "the Lesothan woman",
      "continent": "Africa"
    }
  },
  "LV": {
    "flag": "🇱🇻",
    "code": "LV",
    "isoCode": "LVA",
    "continent": "Europe",
    "DE": {
      "name": "Lettland",
      "shortName": "Lettland",
      "officialName": "Republik Lettland",
      "genderNeutralWriting": "Lette/Lettin",
      "residentsMaleWriting": "der Lette",
      "residentsFemaleWriting": "die Lettin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Lettonie",
      "shortName": "Lettonie",
      "officialName": "République de Lettonie",
      "genderNeutralWriting": "Letton(ne)",
      "residentsMaleWriting": "le Letton",
      "residentsFemaleWriting": "la Lettonne",
      "continent": "Europe"
    },
    "IT": {
      "name": "Lettonia",
      "shortName": "Lettonia",
      "officialName": "Repubblica di Lettonia",
      "genderNeutralWriting": "Lettone",
      "residentsMaleWriting": "il Lettone",
      "residentsFemaleWriting": "la Lettone",
      "continent": "Europa"
    },
    "EN": {
      "name": "Latvia",
      "shortName": "Latvia",
      "officialName": "Republic of Latvia",
      "genderNeutralWriting": "Latvian",
      "residentsMaleWriting": "the Latvian",
      "residentsFemaleWriting": "the Latvian woman",
      "continent": "Europe"
    }
  },
  "LB": {
    "flag": "🇱🇧",
    "code": "LB",
    "isoCode": "LBN",
    "continent": "Asien",
    "DE": {
      "name": "Libanon",
      "shortName": "Libanon",
      "officialName": "Libanesische Republik",
      "genderNeutralWriting": "Libanese:in",
      "residentsMaleWriting": "der Libanese",
      "residentsFemaleWriting": "die Libanesin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Liban",
      "shortName": "Liban",
      "officialName": "République libanaise",
      "genderNeutralWriting": "Libanais(e)",
      "residentsMaleWriting": "le Libanais",
      "residentsFemaleWriting": "la Libanaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Libano",
      "shortName": "Libano",
      "officialName": "Repubblica libanese",
      "genderNeutralWriting": "Libanese",
      "residentsMaleWriting": "il Libanese",
      "residentsFemaleWriting": "la Libanese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Lebanon",
      "shortName": "Lebanon",
      "officialName": "Lebanese Republic",
      "genderNeutralWriting": "Lebanese",
      "residentsMaleWriting": "the Lebanese",
      "residentsFemaleWriting": "the Lebanese woman",
      "continent": "Asia"
    }
  },
  "LR": {
    "flag": "🇱🇷",
    "code": "LR",
    "isoCode": "LBR",
    "continent": "Africa",
    "DE": {
      "name": "Liberia",
      "shortName": "Liberia",
      "officialName": "Republik Liberia",
      "genderNeutralWriting": "Liberianer:in",
      "residentsMaleWriting": "der Liberianer",
      "residentsFemaleWriting": "die Liberianerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Liberia",
      "shortName": "Libéria",
      "officialName": "République du Libéria",
      "genderNeutralWriting": "Libérien(ne)",
      "residentsMaleWriting": "le Libérien",
      "residentsFemaleWriting": "la Libérienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Liberia",
      "shortName": "Liberia",
      "officialName": "Repubblica di Liberia",
      "genderNeutralWriting": "Liberiano/a",
      "residentsMaleWriting": "il Liberiano",
      "residentsFemaleWriting": "la Liberiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Liberia",
      "shortName": "Liberia",
      "officialName": "Republic of Liberia",
      "genderNeutralWriting": "Liberian",
      "residentsMaleWriting": "the Liberian",
      "residentsFemaleWriting": "the Liberian woman",
      "continent": "Africa"
    }
  },
  "LY": {
    "flag": "🇱🇾",
    "code": "LY",
    "isoCode": "LBY",
    "continent": "Africa",
    "DE": {
      "name": "Libyen",
      "shortName": "Libyen",
      "officialName": "Staat Libyen",
      "genderNeutralWriting": "Libyer:in",
      "residentsMaleWriting": "der Libyer",
      "residentsFemaleWriting": "die Libyerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Libye",
      "shortName": "Libye",
      "officialName": "État de Libye",
      "genderNeutralWriting": "Libyen(ne)",
      "residentsMaleWriting": "le Libyen",
      "residentsFemaleWriting": "la Libyenne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Libia",
      "shortName": "Libia",
      "officialName": "Stato della Libia",
      "genderNeutralWriting": "Libico/a",
      "residentsMaleWriting": "il Libico",
      "residentsFemaleWriting": "la Libica",
      "continent": "Africa"
    },
    "EN": {
      "name": "Libya",
      "shortName": "Libya",
      "officialName": "State of Libya",
      "genderNeutralWriting": "Libyan",
      "residentsMaleWriting": "the Libyan",
      "residentsFemaleWriting": "the Libyan woman",
      "continent": "Africa"
    }
  },
  "LI": {
    "flag": "🇱🇮",
    "code": "LI",
    "isoCode": "LIE",
    "continent": "Europe",
    "DE": {
      "name": "Liechtenstein",
      "shortName": "Liechtenstein",
      "officialName": "Fürstentum Liechtenstein",
      "genderNeutralWriting": "Liechtensteiner:in",
      "residentsMaleWriting": "der Liechtensteiner",
      "residentsFemaleWriting": "die Liechtensteinerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Liechtenstein",
      "shortName": "Liechtenstein",
      "officialName": "Principauté de Liechtenstein",
      "genderNeutralWriting": "Liechtensteinois(e)",
      "residentsMaleWriting": "le Liechtensteinois",
      "residentsFemaleWriting": "la Liechtensteinoise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Liechtenstein",
      "shortName": "Liechtenstein",
      "officialName": "Principato del Liechtenstein",
      "genderNeutralWriting": "Liechtensteinese",
      "residentsMaleWriting": "il Liechtensteinese",
      "residentsFemaleWriting": "la Liechtensteinese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Liechtenstein",
      "shortName": "Liechtenstein",
      "officialName": "Principality of Liechtenstein",
      "genderNeutralWriting": "Liechtensteiner",
      "residentsMaleWriting": "the Liechtensteiner",
      "residentsFemaleWriting": "the Liechtensteiner woman",
      "continent": "Europe"
    }
  },
  "LT": {
    "flag": "🇱🇹",
    "code": "LT",
    "isoCode": "LTU",
    "continent": "Europe",
    "DE": {
      "name": "Litauen",
      "shortName": "Litauen",
      "officialName": "Republik Litauen",
      "genderNeutralWriting": "Litauer:in",
      "residentsMaleWriting": "der Litauer",
      "residentsFemaleWriting": "die Litauerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Lituanie",
      "shortName": "Lituanie",
      "officialName": "République de Lituanie",
      "genderNeutralWriting": "Lituanien(ne)",
      "residentsMaleWriting": "le Lituanien",
      "residentsFemaleWriting": "la Lituanienne",
      "continent": "Europe"
    },
    "IT": {
      "name": "Lituania",
      "shortName": "Lituania",
      "officialName": "Repubblica di Lituania",
      "genderNeutralWriting": "Lituano/a",
      "residentsMaleWriting": "il Lituano",
      "residentsFemaleWriting": "la Lituana",
      "continent": "Europa"
    },
    "EN": {
      "name": "Lithuania",
      "shortName": "Lithuania",
      "officialName": "Republic of Lithuania",
      "genderNeutralWriting": "Lithuanian",
      "residentsMaleWriting": "the Lithuanian",
      "residentsFemaleWriting": "the Lithuanian woman",
      "continent": "Europe"
    }
  },
  "LU": {
    "flag": "🇱🇺",
    "code": "LU",
    "isoCode": "LUX",
    "continent": "Europe",
    "DE": {
      "name": "Luxemburg",
      "shortName": "Luxemburg",
      "officialName": "Großherzogtum Luxemburg",
      "genderNeutralWriting": "Luxemburger:in",
      "residentsMaleWriting": "der Luxemburger",
      "residentsFemaleWriting": "die Luxemburgerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Luxembourg",
      "shortName": "Luxembourg",
      "officialName": "Grand-Duché de Luxembourg",
      "genderNeutralWriting": "Luxembourgeois(e)",
      "residentsMaleWriting": "le Luxembourgeois",
      "residentsFemaleWriting": "la Luxembourgeoise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Lussemburgo",
      "shortName": "Lussemburgo",
      "officialName": "Granducato di Lussemburgo",
      "genderNeutralWriting": "Lussemburghese",
      "residentsMaleWriting": "il Lussemburghese",
      "residentsFemaleWriting": "la Lussemburghese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Luxembourg",
      "shortName": "Luxembourg",
      "officialName": "Grand Duchy of Luxembourg",
      "genderNeutralWriting": "Luxembourger",
      "residentsMaleWriting": "the Luxembourger",
      "residentsFemaleWriting": "the Luxembourger woman",
      "continent": "Europe"
    }
  },
  "MO": {
    "flag": "🇲🇴",
    "code": "MO",
    "isoCode": "MAC",
    "continent": "Asien",
    "DE": {
      "name": "Macau",
      "shortName": "Macau",
      "officialName": "Sonderverwaltungsregion Macau der Volksrepublik China",
      "genderNeutralWriting": "Macauer:in",
      "residentsMaleWriting": "der Macauer",
      "residentsFemaleWriting": "die Macauerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Macao",
      "shortName": "Macao",
      "officialName": "Région administrative spéciale de Macao de la République populaire de Chine",
      "genderNeutralWriting": "Macanais(e)",
      "residentsMaleWriting": "le Macanais",
      "residentsFemaleWriting": "la Macanaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Macao",
      "shortName": "Macao",
      "officialName": "Regione amministrativa speciale di Macao della Repubblica Popolare Cinese",
      "genderNeutralWriting": "Macanese",
      "residentsMaleWriting": "il Macanese",
      "residentsFemaleWriting": "la Macanese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Macau",
      "shortName": "Macau",
      "officialName": "Macao Special Administrative Region of the People's Republic of China",
      "genderNeutralWriting": "Macanese",
      "residentsMaleWriting": "the Macanese",
      "residentsFemaleWriting": "the Macanese woman",
      "continent": "Asia"
    }
  },
  "MG": {
    "flag": "🇲🇬",
    "code": "MG",
    "isoCode": "MDG",
    "continent": "Africa",
    "DE": {
      "name": "Madagaskar",
      "shortName": "Madagaskar",
      "officialName": "Republik Madagaskar",
      "genderNeutralWriting": "Madagasse:in",
      "residentsMaleWriting": "der Madagasse",
      "residentsFemaleWriting": "die Madagassin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Madagascar",
      "shortName": "Madagascar",
      "officialName": "République de Madagascar",
      "genderNeutralWriting": "Malgache",
      "residentsMaleWriting": "le Malgache",
      "residentsFemaleWriting": "la Malgache",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Madagascar",
      "shortName": "Madagascar",
      "officialName": "Repubblica del Madagascar",
      "genderNeutralWriting": "Malgascio/a",
      "residentsMaleWriting": "il Malgascio",
      "residentsFemaleWriting": "la Malgascia",
      "continent": "Africa"
    },
    "EN": {
      "name": "Madagascar",
      "shortName": "Madagascar",
      "officialName": "Republic of Madagascar",
      "genderNeutralWriting": "Malagasy",
      "residentsMaleWriting": "the Malagasy",
      "residentsFemaleWriting": "the Malagasy woman",
      "continent": "Africa"
    }
  },
  "MW": {
    "flag": "🇲🇼",
    "code": "MW",
    "isoCode": "MWI",
    "continent": "Africa",
    "DE": {
      "name": "Malawi",
      "shortName": "Malawi",
      "officialName": "Republik Malawi",
      "genderNeutralWriting": "Malawier:in",
      "residentsMaleWriting": "der Malawier",
      "residentsFemaleWriting": "die Malawierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Malawi",
      "shortName": "Malawi",
      "officialName": "République du Malawi",
      "genderNeutralWriting": "Malawien(ne)",
      "residentsMaleWriting": "le Malawien",
      "residentsFemaleWriting": "la Malawienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Malawi",
      "shortName": "Malawi",
      "officialName": "Repubblica del Malawi",
      "genderNeutralWriting": "Malawiano/a",
      "residentsMaleWriting": "il Malawiano",
      "residentsFemaleWriting": "la Malawiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Malawi",
      "shortName": "Malawi",
      "officialName": "Republic of Malawi",
      "genderNeutralWriting": "Malawian",
      "residentsMaleWriting": "the Malawian",
      "residentsFemaleWriting": "the Malawian woman",
      "continent": "Africa"
    }
  },
  "MY": {
    "flag": "🇲🇾",
    "code": "MY",
    "isoCode": "MYS",
    "continent": "Asien",
    "DE": {
      "name": "Malaysia",
      "shortName": "Malaysia",
      "officialName": "Malaysia",
      "genderNeutralWriting": "Malaysier:in",
      "residentsMaleWriting": "der Malaysier",
      "residentsFemaleWriting": "die Malaysierin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Malaisie",
      "shortName": "Malaisie",
      "officialName": "Malaisie",
      "genderNeutralWriting": "Malaisien(ne)",
      "residentsMaleWriting": "le Malaisien",
      "residentsFemaleWriting": "la Malaisienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Malesia",
      "shortName": "Malesia",
      "officialName": "Malesia",
      "genderNeutralWriting": "Malese",
      "residentsMaleWriting": "il Malese",
      "residentsFemaleWriting": "la Malese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Malaysia",
      "shortName": "Malaysia",
      "officialName": "Malaysia",
      "genderNeutralWriting": "Malaysian",
      "residentsMaleWriting": "the Malaysian",
      "residentsFemaleWriting": "the Malaysian woman",
      "continent": "Asia"
    }
  },
  "MV": {
    "flag": "🇲🇻",
    "code": "MV",
    "isoCode": "MDV",
    "continent": "Asien",
    "DE": {
      "name": "Malediven",
      "shortName": "Malediven",
      "officialName": "Republik Malediven",
      "genderNeutralWriting": "Malediver:in",
      "residentsMaleWriting": "der Malediver",
      "residentsFemaleWriting": "die Malediverin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Maldives",
      "shortName": "Maldives",
      "officialName": "République des Maldives",
      "genderNeutralWriting": "Maldivien(ne)",
      "residentsMaleWriting": "le Maldivien",
      "residentsFemaleWriting": "la Maldivienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Maldive",
      "shortName": "Maldive",
      "officialName": "Repubblica delle Maldive",
      "genderNeutralWriting": "Maldiviano/a",
      "residentsMaleWriting": "il Maldiviano",
      "residentsFemaleWriting": "la Maldiviana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Maldives",
      "shortName": "Maldives",
      "officialName": "Republic of Maldives",
      "genderNeutralWriting": "Maldivian",
      "residentsMaleWriting": "the Maldivian",
      "residentsFemaleWriting": "the Maldivian woman",
      "continent": "Asia"
    }
  },
  "ML": {
    "flag": "🇲🇱",
    "code": "ML",
    "isoCode": "MLI",
    "continent": "Africa",
    "DE": {
      "name": "Mali",
      "shortName": "Mali",
      "officialName": "Republik Mali",
      "genderNeutralWriting": "Malier:in",
      "residentsMaleWriting": "der Malier",
      "residentsFemaleWriting": "die Malierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Mali",
      "shortName": "Mali",
      "officialName": "République du Mali",
      "genderNeutralWriting": "Malien(ne)",
      "residentsMaleWriting": "le Malien",
      "residentsFemaleWriting": "la Malienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Mali",
      "shortName": "Mali",
      "officialName": "Repubblica del Mali",
      "genderNeutralWriting": "Maliano/a",
      "residentsMaleWriting": "il Maliano",
      "residentsFemaleWriting": "la Maliana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Mali",
      "shortName": "Mali",
      "officialName": "Republic of Mali",
      "genderNeutralWriting": "Malian",
      "residentsMaleWriting": "the Malian",
      "residentsFemaleWriting": "the Malian woman",
      "continent": "Africa"
    }
  },
  "MT": {
    "flag": "🇲🇹",
    "code": "MT",
    "isoCode": "MLT",
    "continent": "Europe",
    "DE": {
      "name": "Malta",
      "shortName": "Malta",
      "officialName": "Republik Malta",
      "genderNeutralWriting": "Malteser:in",
      "residentsMaleWriting": "der Malteser",
      "residentsFemaleWriting": "die Malteserin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Malte",
      "shortName": "Malte",
      "officialName": "République de Malte",
      "genderNeutralWriting": "Maltais(e)",
      "residentsMaleWriting": "le Maltais",
      "residentsFemaleWriting": "la Maltaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Malta",
      "shortName": "Malta",
      "officialName": "Repubblica di Malta",
      "genderNeutralWriting": "Maltese",
      "residentsMaleWriting": "il Maltese",
      "residentsFemaleWriting": "la Maltese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Malta",
      "shortName": "Malta",
      "officialName": "Republic of Malta",
      "genderNeutralWriting": "Maltese",
      "residentsMaleWriting": "the Maltese",
      "residentsFemaleWriting": "the Maltese woman",
      "continent": "Europe"
    }
  },
  "MA": {
    "flag": "🇲🇦",
    "code": "MA",
    "isoCode": "MAR",
    "continent": "Africa",
    "DE": {
      "name": "Marokko",
      "shortName": "Marokko",
      "officialName": "Königreich Marokko",
      "genderNeutralWriting": "Marokkaner:in",
      "residentsMaleWriting": "der Marokkaner",
      "residentsFemaleWriting": "die Marokkanerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Maroc",
      "shortName": "Maroc",
      "officialName": "Royaume du Maroc",
      "genderNeutralWriting": "Marocain(e)",
      "residentsMaleWriting": "le Marocain",
      "residentsFemaleWriting": "la Marocaine",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Marocco",
      "shortName": "Marocco",
      "officialName": "Regno del Marocco",
      "genderNeutralWriting": "Marocchino/a",
      "residentsMaleWriting": "il Marocchino",
      "residentsFemaleWriting": "la Marocchina",
      "continent": "Africa"
    },
    "EN": {
      "name": "Morocco",
      "shortName": "Morocco",
      "officialName": "Kingdom of Morocco",
      "genderNeutralWriting": "Moroccan",
      "residentsMaleWriting": "the Moroccan",
      "residentsFemaleWriting": "the Moroccan woman",
      "continent": "Africa"
    }
  },
  "MH": {
    "flag": "🇲🇭",
    "code": "MH",
    "isoCode": "MHL",
    "continent": "Oceania",
    "DE": {
      "name": "Marshallinseln",
      "shortName": "Marshallinseln",
      "officialName": "Republik Marshallinseln",
      "genderNeutralWriting": "Marshaller:in",
      "residentsMaleWriting": "der Marshaller",
      "residentsFemaleWriting": "die Marshallerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Îles Marshall",
      "shortName": "Îles Marshall",
      "officialName": "République des Îles Marshall",
      "genderNeutralWriting": "Marshallais(e)",
      "residentsMaleWriting": "le Marshallais",
      "residentsFemaleWriting": "la Marshallaise",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isole Marshall",
      "shortName": "Isole Marshall",
      "officialName": "Repubblica delle Isole Marshall",
      "genderNeutralWriting": "Marshallese",
      "residentsMaleWriting": "il Marshallese",
      "residentsFemaleWriting": "la Marshallese",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Marshall Islands",
      "shortName": "Marshall Islands",
      "officialName": "Republic of the Marshall Islands",
      "genderNeutralWriting": "Marshallese",
      "residentsMaleWriting": "the Marshallese",
      "residentsFemaleWriting": "the Marshallese woman",
      "continent": "Oceania"
    }
  },
  "MQ": {
    "flag": "🇲🇶",
    "code": "MQ",
    "isoCode": "MTQ",
    "continent": "America",
    "DE": {
      "name": "Martinique",
      "shortName": "Martinique",
      "officialName": "Martinique",
      "genderNeutralWriting": "Martiniqer:in",
      "residentsMaleWriting": "der Martiniqer",
      "residentsFemaleWriting": "die Martiniqerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Martinique",
      "shortName": "Martinique",
      "officialName": "Martinique",
      "genderNeutralWriting": "Martiniquais(e)",
      "residentsMaleWriting": "le Martiniquais",
      "residentsFemaleWriting": "la Martiniquaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Martinica",
      "shortName": "Martinica",
      "officialName": "Martinica",
      "genderNeutralWriting": "Martinicano/a",
      "residentsMaleWriting": "il Martinicano",
      "residentsFemaleWriting": "la Martinicana",
      "continent": "America"
    },
    "EN": {
      "name": "Martinique",
      "shortName": "Martinique",
      "officialName": "Martinique",
      "genderNeutralWriting": "Martinican",
      "residentsMaleWriting": "the Martinican",
      "residentsFemaleWriting": "the Martinican woman",
      "continent": "America"
    }
  },
  "MR": {
    "flag": "🇲🇷",
    "code": "MR",
    "isoCode": "MRT",
    "continent": "Africa",
    "DE": {
      "name": "Mauretanien",
      "shortName": "Mauretanien",
      "officialName": "Islamische Republik Mauretanien",
      "genderNeutralWriting": "Mauretanier:in",
      "residentsMaleWriting": "der Mauretanier",
      "residentsFemaleWriting": "die Mauretanierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Mauritanie",
      "shortName": "Mauritanie",
      "officialName": "République islamique de Mauritanie",
      "genderNeutralWriting": "Mauritanien(ne)",
      "residentsMaleWriting": "le Mauritanien",
      "residentsFemaleWriting": "la Mauritanienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Mauritania",
      "shortName": "Mauritania",
      "officialName": "Repubblica islamica di Mauritania",
      "genderNeutralWriting": "Mauritano/a",
      "residentsMaleWriting": "il Mauritano",
      "residentsFemaleWriting": "la Mauritana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Mauritania",
      "shortName": "Mauritania",
      "officialName": "Islamic Republic of Mauritania",
      "genderNeutralWriting": "Mauritanian",
      "residentsMaleWriting": "the Mauritanian",
      "residentsFemaleWriting": "the Mauritanian woman",
      "continent": "Africa"
    }
  },
  "MU": {
    "flag": "🇲🇺",
    "code": "MU",
    "isoCode": "MUS",
    "continent": "Africa",
    "DE": {
      "name": "Mauritius",
      "shortName": "Mauritius",
      "officialName": "Republik Mauritius",
      "genderNeutralWriting": "Mauritier:in",
      "residentsMaleWriting": "der Mauritier",
      "residentsFemaleWriting": "die Mauritierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Maurice",
      "shortName": "Maurice",
      "officialName": "République de Maurice",
      "genderNeutralWriting": "Mauricien(ne)",
      "residentsMaleWriting": "le Mauricien",
      "residentsFemaleWriting": "la Mauricienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Mauritius",
      "shortName": "Mauritius",
      "officialName": "Repubblica di Mauritius",
      "genderNeutralWriting": "Mauriziano/a",
      "residentsMaleWriting": "il Mauriziano",
      "residentsFemaleWriting": "la Mauriziana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Mauritius",
      "shortName": "Mauritius",
      "officialName": "Republic of Mauritius",
      "genderNeutralWriting": "Mauritian",
      "residentsMaleWriting": "the Mauritian",
      "residentsFemaleWriting": "the Mauritian woman",
      "continent": "Africa"
    }
  },
  "YT": {
    "flag": "🇾🇹",
    "code": "YT",
    "isoCode": "MYT",
    "continent": "Africa",
    "DE": {
      "name": "Mayotte",
      "shortName": "Mayotte",
      "officialName": "Übersee-Département Mayotte",
      "genderNeutralWriting": "Mayotter:in",
      "residentsMaleWriting": "der Mayotter",
      "residentsFemaleWriting": "die Mayotterin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Mayotte",
      "shortName": "Mayotte",
      "officialName": "Département de Mayotte",
      "genderNeutralWriting": "Mahorais(e)",
      "residentsMaleWriting": "le Mahorais",
      "residentsFemaleWriting": "la Mahoraise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Mayotte",
      "shortName": "Mayotte",
      "officialName": "Dipartimento di Mayotte",
      "genderNeutralWriting": "Mahorese",
      "residentsMaleWriting": "il Mahorese",
      "residentsFemaleWriting": "la Mahorese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Mayotte",
      "shortName": "Mayotte",
      "officialName": "Department of Mayotte",
      "genderNeutralWriting": "Mahoran",
      "residentsMaleWriting": "the Mahoran",
      "residentsFemaleWriting": "the Mahoran woman",
      "continent": "Africa"
    }
  },
  "MK": {
    "flag": "🇲🇰",
    "code": "MK",
    "isoCode": "MKD",
    "continent": "Europe",
    "DE": {
      "name": "Nordmazedonien",
      "shortName": "Nordmazedonien",
      "officialName": "Republik Nordmazedonien",
      "genderNeutralWriting": "Nordmazedonier:in",
      "residentsMaleWriting": "der Nordmazedonier",
      "residentsFemaleWriting": "die Nordmazedonierin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Macédoine du Nord",
      "shortName": "Macédoine du Nord",
      "officialName": "République de Macédoine du Nord",
      "genderNeutralWriting": "Macédonien(ne) du Nord",
      "residentsMaleWriting": "le Macédonien du Nord",
      "residentsFemaleWriting": "la Macédonienne du Nord",
      "continent": "Europe"
    },
    "IT": {
      "name": "Macedonia del Nord",
      "shortName": "Macedonia del Nord",
      "officialName": "Repubblica della Macedonia del Nord",
      "genderNeutralWriting": "Macedone del Nord",
      "residentsMaleWriting": "il Macedone del Nord",
      "residentsFemaleWriting": "la Macedone del Nord",
      "continent": "Europa"
    },
    "EN": {
      "name": "North Macedonia",
      "shortName": "North Macedonia",
      "officialName": "Republic of North Macedonia",
      "genderNeutralWriting": "North Macedonian",
      "residentsMaleWriting": "the North Macedonian",
      "residentsFemaleWriting": "the North Macedonian woman",
      "continent": "Europe"
    }
  },
  "MX": {
    "flag": "🇲🇽",
    "code": "MX",
    "isoCode": "MEX",
    "continent": "America",
    "DE": {
      "name": "Mexiko",
      "shortName": "Mexiko",
      "officialName": "Vereinigte Mexikanische Staaten",
      "genderNeutralWriting": "Mexikaner:in",
      "residentsMaleWriting": "der Mexikaner",
      "residentsFemaleWriting": "die Mexikanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Mexique",
      "shortName": "Mexique",
      "officialName": "États-Unis mexicains",
      "genderNeutralWriting": "Mexicain(e)",
      "residentsMaleWriting": "le Mexicain",
      "residentsFemaleWriting": "la Mexicaine",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Messico",
      "shortName": "Messico",
      "officialName": "Stati Uniti del Messico",
      "genderNeutralWriting": "Messicano/a",
      "residentsMaleWriting": "il Messicano",
      "residentsFemaleWriting": "la Messicana",
      "continent": "America"
    },
    "EN": {
      "name": "Mexico",
      "shortName": "Mexico",
      "officialName": "United Mexican States",
      "genderNeutralWriting": "Mexican",
      "residentsMaleWriting": "the Mexican",
      "residentsFemaleWriting": "the Mexican woman",
      "continent": "America"
    }
  },
  "FM": {
    "flag": "🇫🇲",
    "code": "FM",
    "isoCode": "FSM",
    "continent": "Oceania",
    "DE": {
      "name": "Mikronesien",
      "shortName": "Mikronesien",
      "officialName": "Föderierte Staaten von Mikronesien",
      "genderNeutralWriting": "Mikronesier:in",
      "residentsMaleWriting": "der Mikronesier",
      "residentsFemaleWriting": "die Mikronesierin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Micronésie",
      "shortName": "Micronésie",
      "officialName": "États fédérés de Micronésie",
      "genderNeutralWriting": "Micronésien(ne)",
      "residentsMaleWriting": "le Micronésien",
      "residentsFemaleWriting": "la Micronésienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Micronesia",
      "shortName": "Micronesia",
      "officialName": "Stati Federati di Micronesia",
      "genderNeutralWriting": "Micronesiano/a",
      "residentsMaleWriting": "il Micronesiano",
      "residentsFemaleWriting": "la Micronesiana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Micronesia",
      "shortName": "Micronesia",
      "officialName": "Federated States of Micronesia",
      "genderNeutralWriting": "Micronesian",
      "residentsMaleWriting": "the Micronesian",
      "residentsFemaleWriting": "the Micronesian woman",
      "continent": "Oceania"
    }
  },
  "MD": {
    "flag": "🇲🇩",
    "code": "MD",
    "isoCode": "MDA",
    "continent": "Europe",
    "DE": {
      "name": "Moldau",
      "shortName": "Moldau",
      "officialName": "Republik Moldau",
      "genderNeutralWriting": "Moldauer:in",
      "residentsMaleWriting": "der Moldauer",
      "residentsFemaleWriting": "die Moldauerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Moldavie",
      "shortName": "Moldavie",
      "officialName": "République de Moldavie",
      "genderNeutralWriting": "Moldave",
      "residentsMaleWriting": "le Moldave",
      "residentsFemaleWriting": "la Moldave",
      "continent": "Europe"
    },
    "IT": {
      "name": "Moldavia",
      "shortName": "Moldavia",
      "officialName": "Repubblica di Moldova",
      "genderNeutralWriting": "Moldavo/a",
      "residentsMaleWriting": "il Moldavo",
      "residentsFemaleWriting": "la Moldava",
      "continent": "Europa"
    },
    "EN": {
      "name": "Moldova",
      "shortName": "Moldova",
      "officialName": "Republic of Moldova",
      "genderNeutralWriting": "Moldovan",
      "residentsMaleWriting": "the Moldovan",
      "residentsFemaleWriting": "the Moldovan woman",
      "continent": "Europe"
    }
  },
  "MC": {
    "flag": "🇲🇨",
    "code": "MC",
    "isoCode": "MCO",
    "continent": "Europe",
    "DE": {
      "name": "Monaco",
      "shortName": "Monaco",
      "officialName": "Fürstentum Monaco",
      "genderNeutralWriting": "Monegasse/Monegassin",
      "residentsMaleWriting": "der Monegasse",
      "residentsFemaleWriting": "die Monegassin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Monaco",
      "shortName": "Monaco",
      "officialName": "Principauté de Monaco",
      "genderNeutralWriting": "Monégasque",
      "residentsMaleWriting": "le Monégasque",
      "residentsFemaleWriting": "la Monégasque",
      "continent": "Europe"
    },
    "IT": {
      "name": "Monaco",
      "shortName": "Monaco",
      "officialName": "Principato di Monaco",
      "genderNeutralWriting": "Monegasco/a",
      "residentsMaleWriting": "il Monegasco",
      "residentsFemaleWriting": "la Monegasca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Monaco",
      "shortName": "Monaco",
      "officialName": "Principality of Monaco",
      "genderNeutralWriting": "Monégasque",
      "residentsMaleWriting": "the Monégasque",
      "residentsFemaleWriting": "the Monégasque woman",
      "continent": "Europe"
    }
  },
  "MN": {
    "flag": "🇲🇳",
    "code": "MN",
    "isoCode": "MNG",
    "continent": "Asien",
    "DE": {
      "name": "Mongolei",
      "shortName": "Mongolei",
      "officialName": "Mongolei",
      "genderNeutralWriting": "Mongole/Mongolin",
      "residentsMaleWriting": "der Mongole",
      "residentsFemaleWriting": "die Mongolin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Mongolie",
      "shortName": "Mongolie",
      "officialName": "Mongolie",
      "genderNeutralWriting": "Mongol(e)",
      "residentsMaleWriting": "le Mongol",
      "residentsFemaleWriting": "la Mongole",
      "continent": "Asie"
    },
    "IT": {
      "name": "Mongolia",
      "shortName": "Mongolia",
      "officialName": "Mongolia",
      "genderNeutralWriting": "Mongolo/a",
      "residentsMaleWriting": "il Mongolo",
      "residentsFemaleWriting": "la Mongola",
      "continent": "Asia"
    },
    "EN": {
      "name": "Mongolia",
      "shortName": "Mongolia",
      "officialName": "Mongolia",
      "genderNeutralWriting": "Mongolian",
      "residentsMaleWriting": "the Mongolian",
      "residentsFemaleWriting": "the Mongolian woman",
      "continent": "Asia"
    }
  },
  "ME": {
    "flag": "🇲🇪",
    "code": "ME",
    "isoCode": "MNE",
    "continent": "Europe",
    "DE": {
      "name": "Montenegro",
      "shortName": "Montenegro",
      "officialName": "Montenegro",
      "genderNeutralWriting": "Montenegriner:in",
      "residentsMaleWriting": "der Montenegriner",
      "residentsFemaleWriting": "die Montenegrinerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Monténégro",
      "shortName": "Monténégro",
      "officialName": "Monténégro",
      "genderNeutralWriting": "Monténégrin(e)",
      "residentsMaleWriting": "le Monténégrin",
      "residentsFemaleWriting": "la Monténégrine",
      "continent": "Europe"
    },
    "IT": {
      "name": "Montenegro",
      "shortName": "Montenegro",
      "officialName": "Montenegro",
      "genderNeutralWriting": "Montenegrino/a",
      "residentsMaleWriting": "il Montenegrino",
      "residentsFemaleWriting": "la Montenegrina",
      "continent": "Europa"
    },
    "EN": {
      "name": "Montenegro",
      "shortName": "Montenegro",
      "officialName": "Montenegro",
      "genderNeutralWriting": "Montenegrin",
      "residentsMaleWriting": "the Montenegrin",
      "residentsFemaleWriting": "the Montenegrin woman",
      "continent": "Europe"
    }
  },
  "MS": {
    "flag": "🇲🇸",
    "code": "MS",
    "isoCode": "MSR",
    "continent": "America",
    "DE": {
      "name": "Montserrat",
      "shortName": "Montserrat",
      "officialName": "Montserrat",
      "genderNeutralWriting": "Montserrat-Bewohner:in",
      "residentsMaleWriting": "der Montserrat-Bewohner",
      "residentsFemaleWriting": "die Montserrat-Bewohnerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Montserrat",
      "shortName": "Montserrat",
      "officialName": "Montserrat",
      "genderNeutralWriting": "Montserratien(ne)",
      "residentsMaleWriting": "le Montserratien",
      "residentsFemaleWriting": "la Montserratienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Montserrat",
      "shortName": "Montserrat",
      "officialName": "Montserrat",
      "genderNeutralWriting": "Montserratiano/a",
      "residentsMaleWriting": "il Montserratiano",
      "residentsFemaleWriting": "la Montserratiana",
      "continent": "America"
    },
    "EN": {
      "name": "Montserrat",
      "shortName": "Montserrat",
      "officialName": "Montserrat",
      "genderNeutralWriting": "Montserratian",
      "residentsMaleWriting": "the Montserratian",
      "residentsFemaleWriting": "the Montserratian woman",
      "continent": "America"
    }
  },
  "MZ": {
    "flag": "🇲🇿",
    "code": "MZ",
    "isoCode": "MOZ",
    "continent": "Africa",
    "DE": {
      "name": "Mosambik",
      "shortName": "Mosambik",
      "officialName": "Republik Mosambik",
      "genderNeutralWriting": "Mosambikaner:in",
      "residentsMaleWriting": "der Mosambikaner",
      "residentsFemaleWriting": "die Mosambikanerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Mozambique",
      "shortName": "Mozambique",
      "officialName": "République du Mozambique",
      "genderNeutralWriting": "Mozambicain(e)",
      "residentsMaleWriting": "le Mozambicain",
      "residentsFemaleWriting": "la Mozambicaine",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Mozambico",
      "shortName": "Mozambico",
      "officialName": "Repubblica del Mozambico",
      "genderNeutralWriting": "Mozambicano/a",
      "residentsMaleWriting": "il Mozambicano",
      "residentsFemaleWriting": "la Mozambicana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Mozambique",
      "shortName": "Mozambique",
      "officialName": "Republic of Mozambique",
      "genderNeutralWriting": "Mozambican",
      "residentsMaleWriting": "the Mozambican",
      "residentsFemaleWriting": "the Mozambican woman",
      "continent": "Africa"
    }
  },
  "MM": {
    "flag": "🇲🇲",
    "code": "MM",
    "isoCode": "MMR",
    "continent": "Asia",
    "DE": {
      "name": "Myanmar",
      "shortName": "Myanmar",
      "officialName": "Republik der Union Myanmar",
      "genderNeutralWriting": "Myanmar-Bewohner:in",
      "residentsMaleWriting": "der Myanmar-Bewohner",
      "residentsFemaleWriting": "die Myanmar-Bewohnerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Myanmar",
      "shortName": "Myanmar",
      "officialName": "République de l'Union du Myanmar",
      "genderNeutralWriting": "Birman(e)",
      "residentsMaleWriting": "le Birman",
      "residentsFemaleWriting": "la Birmane",
      "continent": "Asie"
    },
    "IT": {
      "name": "Myanmar",
      "shortName": "Myanmar",
      "officialName": "Repubblica dell'Unione di Myanmar",
      "genderNeutralWriting": "Birmano/a",
      "residentsMaleWriting": "il Birmano",
      "residentsFemaleWriting": "la Birmana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Myanmar",
      "shortName": "Myanmar",
      "officialName": "Republic of the Union of Myanmar",
      "genderNeutralWriting": "Myanmar",
      "residentsMaleWriting": "the Myanmar person",
      "residentsFemaleWriting": "the Myanmar woman",
      "continent": "Asia"
    }
  },
  "NA": {
    "flag": "🇳🇦",
    "code": "NA",
    "isoCode": "NAM",
    "continent": "Africa",
    "DE": {
      "name": "Namibia",
      "shortName": "Namibia",
      "officialName": "Republik Namibia",
      "genderNeutralWriting": "Namibier:in",
      "residentsMaleWriting": "der Namibier",
      "residentsFemaleWriting": "die Namibierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Namibie",
      "shortName": "Namibie",
      "officialName": "République de Namibie",
      "genderNeutralWriting": "Namibien(ne)",
      "residentsMaleWriting": "le Namibien",
      "residentsFemaleWriting": "la Namibienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Namibia",
      "shortName": "Namibia",
      "officialName": "Repubblica della Namibia",
      "genderNeutralWriting": "Namibiano/a",
      "residentsMaleWriting": "il Namibiano",
      "residentsFemaleWriting": "la Namibiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Namibia",
      "shortName": "Namibia",
      "officialName": "Republic of Namibia",
      "genderNeutralWriting": "Namibian",
      "residentsMaleWriting": "Namibian man",
      "residentsFemaleWriting": "Namibian woman",
      "continent": "Africa"
    }
  },
  "NR": {
    "flag": "🇳🇷",
    "code": "NR",
    "isoCode": "NRU",
    "continent": "Oceania",
    "DE": {
      "name": "Nauru",
      "shortName": "Nauru",
      "officialName": "Republik Nauru",
      "genderNeutralWriting": "Nauruer/Nauruerin",
      "residentsMaleWriting": "der Nauruer",
      "residentsFemaleWriting": "die Nauruerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Nauru",
      "shortName": "Nauru",
      "officialName": "République de Nauru",
      "genderNeutralWriting": "Nauruan(e)",
      "residentsMaleWriting": "le Nauruan",
      "residentsFemaleWriting": "la Nauruane",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Nauru",
      "shortName": "Nauru",
      "officialName": "Repubblica di Nauru",
      "genderNeutralWriting": "Nauruano/a",
      "residentsMaleWriting": "il Nauruano",
      "residentsFemaleWriting": "la Nauruana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Nauru",
      "shortName": "Nauru",
      "officialName": "Republic of Nauru",
      "genderNeutralWriting": "Nauruan",
      "residentsMaleWriting": "Nauruan man",
      "residentsFemaleWriting": "Nauruan woman",
      "continent": "Oceania"
    }
  },
  "NP": {
    "flag": "🇳🇵",
    "code": "NP",
    "isoCode": "NPL",
    "continent": "Asia",
    "DE": {
      "name": "Nepal",
      "shortName": "Nepal",
      "officialName": "Demokratische Bundesrepublik Nepal",
      "genderNeutralWriting": "Nepalese/Nepalesin",
      "residentsMaleWriting": "der Nepalese",
      "residentsFemaleWriting": "die Nepalesin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Népal",
      "shortName": "Népal",
      "officialName": "République fédérale démocratique du Népal",
      "genderNeutralWriting": "Népalais(e)",
      "residentsMaleWriting": "le Népalais",
      "residentsFemaleWriting": "la Népalaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Nepal",
      "shortName": "Nepal",
      "officialName": "Repubblica federale democratica del Nepal",
      "genderNeutralWriting": "Nepalese",
      "residentsMaleWriting": "il Nepalese",
      "residentsFemaleWriting": "la Nepalese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Nepal",
      "shortName": "Nepal",
      "officialName": "Federal Democratic Republic of Nepal",
      "genderNeutralWriting": "Nepalese",
      "residentsMaleWriting": "Nepalese man",
      "residentsFemaleWriting": "Nepalese woman",
      "continent": "Asia"
    }
  },
  "NC": {
    "flag": "🇳🇨",
    "code": "NC",
    "isoCode": "NCL",
    "continent": "Oceania",
    "DE": {
      "name": "Neukaledonien",
      "shortName": "Neukaledonien",
      "genderNeutralWriting": "Neukaledonier:in",
      "residentsMaleWriting": "der Neukaledonier",
      "residentsFemaleWriting": "die Neukaledonierin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Nouvelle-Calédonie",
      "shortName": "Nouvelle-Calédonie",
      "genderNeutralWriting": "Calédonien(ne)",
      "residentsMaleWriting": "le Calédonien",
      "residentsFemaleWriting": "la Calédonienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Nuova Caledonia",
      "shortName": "Nuova Caledonia",
      "genderNeutralWriting": "Neocaledoniano/a",
      "residentsMaleWriting": "il Neocaledoniano",
      "residentsFemaleWriting": "la Neocaledoniana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "New Caledonia",
      "shortName": "New Caledonia",
      "officialName": "New Caledonia",
      "genderNeutralWriting": "New Caledonian",
      "residentsMaleWriting": "New Caledonian man",
      "residentsFemaleWriting": "New Caledonian woman",
      "continent": "Oceania"
    }
  },
  "NZ": {
    "flag": "🇳🇿",
    "code": "NZ",
    "isoCode": "NZL",
    "continent": "Oceania",
    "DE": {
      "name": "Neuseeland",
      "shortName": "Neuseeland",
      "officialName": "Neuseeland",
      "genderNeutralWriting": "Neuseeländer:in",
      "residentsMaleWriting": "der Neuseeländer",
      "residentsFemaleWriting": "die Neuseeländerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Nouvelle-Zélande",
      "shortName": "Nouvelle-Zélande",
      "officialName": "Nouvelle-Zélande",
      "genderNeutralWriting": "Néo-Zélandais(e)",
      "residentsMaleWriting": "le Néo-Zélandais",
      "residentsFemaleWriting": "la Néo-Zélandaise",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Nuova Zelanda",
      "shortName": "Nuova Zelanda",
      "officialName": "Nuova Zelanda",
      "genderNeutralWriting": "Neozelandese",
      "residentsMaleWriting": "il Neozelandese",
      "residentsFemaleWriting": "la Neozelandese",
      "continent": "Oceania"
    },
    "EN": {
      "name": "New Zealand",
      "shortName": "New Zealand",
      "officialName": "New Zealand",
      "genderNeutralWriting": "New Zealander",
      "residentsMaleWriting": "New Zealand man",
      "residentsFemaleWriting": "New Zealand woman",
      "continent": "Oceania"
    }
  },
  "NI": {
    "flag": "🇳🇮",
    "code": "NI",
    "isoCode": "NIC",
    "continent": "America",
    "DE": {
      "name": "Nicaragua",
      "shortName": "Nicaragua",
      "officialName": "Republik Nicaragua",
      "genderNeutralWriting": "Nicaraguaner:in",
      "residentsMaleWriting": "der Nicaraguaner",
      "residentsFemaleWriting": "die Nicaraguanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Nicaragua",
      "shortName": "Nicaragua",
      "officialName": "République du Nicaragua",
      "genderNeutralWriting": "Nicaraguayen(ne)",
      "residentsMaleWriting": "le Nicaraguayen",
      "residentsFemaleWriting": "la Nicaraguayenne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Nicaragua",
      "shortName": "Nicaragua",
      "officialName": "Repubblica del Nicaragua",
      "genderNeutralWriting": "Nicaraguense",
      "residentsMaleWriting": "il Nicaraguense",
      "residentsFemaleWriting": "la Nicaraguense",
      "continent": "America"
    },
    "EN": {
      "name": "Nicaragua",
      "shortName": "Nicaragua",
      "officialName": "Republic of Nicaragua",
      "genderNeutralWriting": "Nicaraguan",
      "residentsMaleWriting": "Nicaraguan man",
      "residentsFemaleWriting": "Nicaraguan woman",
      "continent": "America"
    }
  },
  "NL": {
    "flag": "🇳🇱",
    "code": "NL",
    "isoCode": "NLD",
    "continent": "Europe",
    "DE": {
      "name": "Niederlande",
      "shortName": "Niederlande",
      "officialName": "Königreich der Niederlande",
      "genderNeutralWriting": "Niederländer:in",
      "residentsMaleWriting": "der Niederländer",
      "residentsFemaleWriting": "die Niederländerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Pays-Bas",
      "shortName": "Pays-Bas",
      "officialName": "Royaume des Pays-Bas",
      "genderNeutralWriting": "Néerlandais(e)",
      "residentsMaleWriting": "le Néerlandais",
      "residentsFemaleWriting": "la Néerlandaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Paesi Bassi",
      "shortName": "Paesi Bassi",
      "officialName": "Regno dei Paesi Bassi",
      "genderNeutralWriting": "Olandese",
      "residentsMaleWriting": "l'Olandese",
      "residentsFemaleWriting": "l'Olandese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Netherlands",
      "shortName": "Netherlands",
      "officialName": "Kingdom of the Netherlands",
      "genderNeutralWriting": "Dutch",
      "residentsMaleWriting": "Dutch man",
      "residentsFemaleWriting": "Dutch woman",
      "continent": "Europe"
    }
  },
  "NE": {
    "flag": "🇳🇪",
    "code": "NE",
    "isoCode": "NER",
    "continent": "Africa",
    "DE": {
      "name": "Niger",
      "shortName": "Niger",
      "officialName": "Republik Niger",
      "genderNeutralWriting": "Nigrer:in",
      "residentsMaleWriting": "der Nigrer",
      "residentsFemaleWriting": "die Nigrerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Niger",
      "shortName": "Niger",
      "officialName": "République du Niger",
      "genderNeutralWriting": "Nigérien(ne)",
      "residentsMaleWriting": "le Nigérien",
      "residentsFemaleWriting": "la Nigérienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Niger",
      "shortName": "Niger",
      "officialName": "Repubblica del Niger",
      "genderNeutralWriting": "Nigerino/a",
      "residentsMaleWriting": "il Nigerino",
      "residentsFemaleWriting": "la Nigerina",
      "continent": "Africa"
    },
    "EN": {
      "name": "Niger",
      "shortName": "Niger",
      "officialName": "Republic of Niger",
      "genderNeutralWriting": "Nigerien",
      "residentsMaleWriting": "Nigerien man",
      "residentsFemaleWriting": "Nigerien woman",
      "continent": "Africa"
    }
  },
  "NG": {
    "flag": "🇳🇬",
    "code": "NG",
    "isoCode": "NGA",
    "continent": "Africa",
    "DE": {
      "name": "Nigeria",
      "shortName": "Nigeria",
      "officialName": "Bundesrepublik Nigeria",
      "genderNeutralWriting": "Nigerianer:in",
      "residentsMaleWriting": "der Nigerianer",
      "residentsFemaleWriting": "die Nigerianerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Nigeria",
      "shortName": "Nigeria",
      "officialName": "République fédérale du Nigeria",
      "genderNeutralWriting": "Nigérian(e)",
      "residentsMaleWriting": "le Nigérian",
      "residentsFemaleWriting": "la Nigériane",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Nigeria",
      "shortName": "Nigeria",
      "officialName": "Repubblica federale della Nigeria",
      "genderNeutralWriting": "Nigeriano/a",
      "residentsMaleWriting": "il Nigeriano",
      "residentsFemaleWriting": "la Nigeriana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Nigeria",
      "shortName": "Nigeria",
      "officialName": "Federal Republic of Nigeria",
      "genderNeutralWriting": "Nigerian",
      "residentsMaleWriting": "Nigerian man",
      "residentsFemaleWriting": "Nigerian woman",
      "continent": "Africa"
    }
  },
  "NU": {
    "flag": "🇳🇺",
    "code": "NU",
    "isoCode": "NIU",
    "continent": "Oceania",
    "DE": {
      "name": "Niue",
      "shortName": "Niue",
      "genderNeutralWriting": "Niue",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Niué",
      "shortName": "Niué",
      "genderNeutralWriting": "Niué",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Niue",
      "shortName": "Niue",
      "genderNeutralWriting": "Niue",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Niue",
      "shortName": "Niue",
      "officialName": "Niue",
      "genderNeutralWriting": "Niuean",
      "continent": "Oceania"
    }
  },
  "MP": {
    "flag": "🇲🇵",
    "code": "MP",
    "isoCode": "MNP",
    "continent": "Oceania",
    "DE": {
      "name": "Nördliche Marianen",
      "shortName": "Nördliche Marianen",
      "genderNeutralWriting": "Nördliche Marianen",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Îles Mariannes du Nord",
      "shortName": "Îles Mariannes du Nord",
      "genderNeutralWriting": "Mariannes du Nord",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isole Marianne Settentrionali",
      "shortName": "Isole Marianne Settentrionali",
      "genderNeutralWriting": "Marianne Settentrionali",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Northern Mariana Islands",
      "shortName": "Northern Mariana Islands",
      "officialName": "Commonwealth of the Northern Mariana Islands",
      "genderNeutralWriting": "Northern Marianan",
      "continent": "Oceania"
    }
  },
  "NF": {
    "flag": "🇳🇫",
    "code": "NF",
    "isoCode": "NFK",
    "continent": "Oceania",
    "DE": {
      "name": "Norfolkinsel",
      "shortName": "Norfolkinsel",
      "genderNeutralWriting": "Norfolkinsel",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Île Norfolk",
      "shortName": "Île Norfolk",
      "genderNeutralWriting": "Île Norfolk",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isola Norfolk",
      "shortName": "Isola Norfolk",
      "genderNeutralWriting": "Isola Norfolk",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Norfolk Island",
      "shortName": "Norfolk Island",
      "officialName": "Territory of Norfolk Island",
      "genderNeutralWriting": "Norfolk Islander",
      "continent": "Oceania"
    }
  },
  "NO": {
    "flag": "🇳🇴",
    "code": "NO",
    "isoCode": "NOR",
    "continent": "Europe",
    "DE": {
      "name": "Norwegen",
      "shortName": "Norwegen",
      "officialName": "Königreich Norwegen",
      "genderNeutralWriting": "Norweger:in",
      "residentsMaleWriting": "der Norweger",
      "residentsFemaleWriting": "die Norwegerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Norvège",
      "shortName": "Norvège",
      "officialName": "Royaume de Norvège",
      "genderNeutralWriting": "Norvégien(ne)",
      "residentsMaleWriting": "le Norvégien",
      "residentsFemaleWriting": "la Norvégienne",
      "continent": "Europe"
    },
    "IT": {
      "name": "Norvegia",
      "shortName": "Norvegia",
      "officialName": "Regno di Norvegia",
      "genderNeutralWriting": "Norvegese",
      "residentsMaleWriting": "il Norvegese",
      "residentsFemaleWriting": "la Norvegese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Norway",
      "shortName": "Norway",
      "officialName": "Kingdom of Norway",
      "genderNeutralWriting": "Norwegian",
      "residentsMaleWriting": "Norwegian man",
      "residentsFemaleWriting": "Norwegian woman",
      "continent": "Europe"
    }
  },
  "OM": {
    "flag": "🇴🇲",
    "code": "OM",
    "isoCode": "OMN",
    "continent": "Asia",
    "DE": {
      "name": "Oman",
      "shortName": "Oman",
      "officialName": "Sultanat Oman",
      "genderNeutralWriting": "Omaner:in",
      "residentsMaleWriting": "der Omaner",
      "residentsFemaleWriting": "die Omanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Oman",
      "shortName": "Oman",
      "officialName": "Sultanat d'Oman",
      "genderNeutralWriting": "Omanais(e)",
      "residentsMaleWriting": "l'Omanais",
      "residentsFemaleWriting": "l'Omanaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Oman",
      "shortName": "Oman",
      "officialName": "Sultanato dell'Oman",
      "genderNeutralWriting": "Omanita",
      "residentsMaleWriting": "l'Omanita",
      "residentsFemaleWriting": "l'Omanita",
      "continent": "Asia"
    },
    "EN": {
      "name": "Oman",
      "shortName": "Oman",
      "officialName": "Sultanate of Oman",
      "genderNeutralWriting": "Omani",
      "residentsMaleWriting": "Omani man",
      "residentsFemaleWriting": "Omani woman",
      "continent": "Asia"
    }
  },
  "AT": {
    "flag": "🇦🇹",
    "code": "AT",
    "isoCode": "AUT",
    "continent": "Europe",
    "DE": {
      "name": "Österreich",
      "shortName": "Österreich",
      "officialName": "Republik Österreich",
      "genderNeutralWriting": "Österreicher:in",
      "residentsMaleWriting": "der Österreicher",
      "residentsFemaleWriting": "die Österreicherin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Autriche",
      "shortName": "Autriche",
      "officialName": "République d'Autriche",
      "genderNeutralWriting": "Autrichien(ne)",
      "residentsMaleWriting": "l'Autrichien",
      "residentsFemaleWriting": "l'Autrichienne",
      "continent": "Europe"
    },
    "IT": {
      "name": "Austria",
      "shortName": "Austria",
      "officialName": "Repubblica d'Austria",
      "genderNeutralWriting": "Austriaco/a",
      "residentsMaleWriting": "l'Austriaco",
      "residentsFemaleWriting": "l'Austriaca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Austria",
      "shortName": "Austria",
      "officialName": "Republic of Austria",
      "genderNeutralWriting": "Austrian",
      "residentsMaleWriting": "Austrian man",
      "residentsFemaleWriting": "Austrian woman",
      "continent": "Europe"
    }
  },
  "PK": {
    "flag": "🇵🇰",
    "code": "PK",
    "isoCode": "PAK",
    "continent": "Asia",
    "DE": {
      "name": "Pakistan",
      "shortName": "Pakistan",
      "officialName": "Islamische Republik Pakistan",
      "genderNeutralWriting": "Pakistani",
      "residentsMaleWriting": "der Pakistani",
      "residentsFemaleWriting": "die Pakistani",
      "continent": "Asien"
    },
    "FR": {
      "name": "Pakistan",
      "shortName": "Pakistan",
      "officialName": "République islamique du Pakistan",
      "genderNeutralWriting": "Pakistanais(e)",
      "residentsMaleWriting": "le Pakistanais",
      "residentsFemaleWriting": "la Pakistanaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Pakistan",
      "shortName": "Pakistan",
      "officialName": "Repubblica islamica del Pakistan",
      "genderNeutralWriting": "Pakistano/a",
      "residentsMaleWriting": "il Pakistano",
      "residentsFemaleWriting": "la Pakistana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Pakistan",
      "shortName": "Pakistan",
      "officialName": "Islamic Republic of Pakistan",
      "genderNeutralWriting": "Pakistani",
      "residentsMaleWriting": "Pakistani man",
      "residentsFemaleWriting": "Pakistani woman",
      "continent": "Asia"
    }
  },
  "PS": {
    "flag": "🇵🇸",
    "code": "PS",
    "isoCode": "PSE",
    "continent": "Asia",
    "DE": {
      "name": "Palästina",
      "shortName": "Palästina",
      "genderNeutralWriting": "Palästinenser:in",
      "residentsMaleWriting": "der Palästinenser",
      "residentsFemaleWriting": "die Palästinenserin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Palestine",
      "shortName": "Palestine",
      "genderNeutralWriting": "Palestinien(ne)",
      "residentsMaleWriting": "le Palestinien",
      "residentsFemaleWriting": "la Palestinienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Palestina",
      "shortName": "Palestina",
      "genderNeutralWriting": "Palestinese",
      "residentsMaleWriting": "il Palestinese",
      "residentsFemaleWriting": "la Palestinese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Palestine",
      "shortName": "Palestine",
      "officialName": "State of Palestine",
      "genderNeutralWriting": "Palestinian",
      "residentsMaleWriting": "Palestinian man",
      "residentsFemaleWriting": "Palestinian woman",
      "continent": "Asia"
    }
  },
  "PW": {
    "flag": "🇵🇼",
    "code": "PW",
    "isoCode": "PLW",
    "continent": "Oceania",
    "DE": {
      "name": "Palau",
      "shortName": "Palau",
      "officialName": "Republik Palau",
      "genderNeutralWriting": "Palauer:in",
      "residentsMaleWriting": "der Palauer",
      "residentsFemaleWriting": "die Palauerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Palaos",
      "shortName": "Palaos",
      "officialName": "République des Palaos",
      "genderNeutralWriting": "Palaosien(ne)",
      "residentsMaleWriting": "le Palaosien",
      "residentsFemaleWriting": "la Palaosienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Palau",
      "shortName": "Palau",
      "officialName": "Repubblica di Palau",
      "genderNeutralWriting": "Palauano/a",
      "residentsMaleWriting": "il Palauano",
      "residentsFemaleWriting": "la Palauana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Palau",
      "shortName": "Palau",
      "officialName": "Republic of Palau",
      "genderNeutralWriting": "Palauan",
      "residentsMaleWriting": "Palauan man",
      "residentsFemaleWriting": "Palauan woman",
      "continent": "Oceania"
    }
  },
  "PA": {
    "flag": "🇵🇦",
    "code": "PA",
    "isoCode": "PAN",
    "continent": "America",
    "DE": {
      "name": "Panama",
      "shortName": "Panama",
      "officialName": "Republik Panama",
      "genderNeutralWriting": "Panamaer:in",
      "residentsMaleWriting": "der Panamaer",
      "residentsFemaleWriting": "die Panamaerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Panama",
      "shortName": "Panama",
      "officialName": "République du Panama",
      "genderNeutralWriting": "Panaméen(ne)",
      "residentsMaleWriting": "le Panaméen",
      "residentsFemaleWriting": "la Panaméenne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Panama",
      "shortName": "Panama",
      "officialName": "Repubblica di Panama",
      "genderNeutralWriting": "Panamense",
      "residentsMaleWriting": "il Panamense",
      "residentsFemaleWriting": "la Panamense",
      "continent": "America"
    },
    "EN": {
      "name": "Panama",
      "shortName": "Panama",
      "officialName": "Republic of Panama",
      "genderNeutralWriting": "Panamanian",
      "residentsMaleWriting": "Panamanian man",
      "residentsFemaleWriting": "Panamanian woman",
      "continent": "America"
    }
  },
  "PG": {
    "flag": "🇵🇬",
    "code": "PG",
    "isoCode": "PNG",
    "continent": "Oceania",
    "DE": {
      "name": "Papua-Neuguinea",
      "shortName": "Papua-Neuguinea",
      "officialName": "Unabhängiger Staat Papua-Neuguinea",
      "genderNeutralWriting": "Papua-Neuguineer:in",
      "residentsMaleWriting": "der Papua-Neuguineer",
      "residentsFemaleWriting": "die Papua-Neuguineerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Papouasie-Nouvelle-Guinée",
      "shortName": "Papouasie-Nouvelle-Guinée",
      "officialName": "État indépendant de Papouasie-Nouvelle-Guinée",
      "genderNeutralWriting": "Papou(e)-néo-guinéen(ne)",
      "residentsMaleWriting": "le Papou-néo-guinéen",
      "residentsFemaleWriting": "la Papoue-néo-guinéenne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Papua Nuova Guinea",
      "shortName": "Papua Nuova Guinea",
      "officialName": "Stato indipendente di Papua Nuova Guinea",
      "genderNeutralWriting": "Papuano/a",
      "residentsMaleWriting": "il Papuano",
      "residentsFemaleWriting": "la Papuana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Papua New Guinea",
      "shortName": "Papua New Guinea",
      "officialName": "Independent State of Papua New Guinea",
      "genderNeutralWriting": "Papua New Guinean",
      "residentsMaleWriting": "Papua New Guinean man",
      "residentsFemaleWriting": "Papua New Guinean woman",
      "continent": "Oceania"
    }
  },
  "PY": {
    "flag": "🇵🇾",
    "code": "PY",
    "isoCode": "PRY",
    "continent": "America",
    "DE": {
      "name": "Paraguay",
      "shortName": "Paraguay",
      "officialName": "Republik Paraguay",
      "genderNeutralWriting": "Paraguayer:in",
      "residentsMaleWriting": "der Paraguayer",
      "residentsFemaleWriting": "die Paraguayerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Paraguay",
      "shortName": "Paraguay",
      "officialName": "République du Paraguay",
      "genderNeutralWriting": "Paraguayen(ne)",
      "residentsMaleWriting": "le Paraguayen",
      "residentsFemaleWriting": "la Paraguayenne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Paraguay",
      "shortName": "Paraguay",
      "officialName": "Repubblica del Paraguay",
      "genderNeutralWriting": "Paraguaiano/a",
      "residentsMaleWriting": "il Paraguaiano",
      "residentsFemaleWriting": "la Paraguaiana",
      "continent": "America"
    },
    "EN": {
      "name": "Paraguay",
      "shortName": "Paraguay",
      "officialName": "Republic of Paraguay",
      "genderNeutralWriting": "Paraguayan",
      "residentsMaleWriting": "Paraguayan man",
      "residentsFemaleWriting": "Paraguayan woman",
      "continent": "America"
    }
  },
  "PE": {
    "flag": "🇵🇪",
    "code": "PE",
    "isoCode": "PER",
    "continent": "America",
    "DE": {
      "name": "Peru",
      "shortName": "Peru",
      "officialName": "Republik Peru",
      "genderNeutralWriting": "Peruaner:in",
      "residentsMaleWriting": "der Peruaner",
      "residentsFemaleWriting": "die Peruanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Pérou",
      "shortName": "Pérou",
      "officialName": "République du Pérou",
      "genderNeutralWriting": "Péruvien(ne)",
      "residentsMaleWriting": "le Péruvien",
      "residentsFemaleWriting": "la Péruvienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Perù",
      "shortName": "Perù",
      "officialName": "Repubblica del Perù",
      "genderNeutralWriting": "Peruviano/a",
      "residentsMaleWriting": "il Peruviano",
      "residentsFemaleWriting": "la Peruviana",
      "continent": "America"
    },
    "EN": {
      "name": "Peru",
      "shortName": "Peru",
      "officialName": "Republic of Peru",
      "genderNeutralWriting": "Peruvian",
      "residentsMaleWriting": "Peruvian man",
      "residentsFemaleWriting": "Peruvian woman",
      "continent": "America"
    }
  },
  "PH": {
    "flag": "🇵🇭",
    "code": "PH",
    "isoCode": "PHL",
    "continent": "Asia",
    "DE": {
      "name": "Philippinen",
      "shortName": "Philippinen",
      "officialName": "Republik der Philippinen",
      "genderNeutralWriting": "Filipino/Filipina",
      "residentsMaleWriting": "der Filipino",
      "residentsFemaleWriting": "die Filipina",
      "continent": "Asien"
    },
    "FR": {
      "name": "Philippines",
      "shortName": "Philippines",
      "officialName": "République des Philippines",
      "genderNeutralWriting": "Philippin(e)",
      "residentsMaleWriting": "le Philippin",
      "residentsFemaleWriting": "la Philippine",
      "continent": "Asie"
    },
    "IT": {
      "name": "Filippine",
      "shortName": "Filippine",
      "officialName": "Repubblica delle Filippine",
      "genderNeutralWriting": "Filippino/a",
      "residentsMaleWriting": "il Filippino",
      "residentsFemaleWriting": "la Filippina",
      "continent": "Asia"
    },
    "EN": {
      "name": "Philippines",
      "shortName": "Philippines",
      "officialName": "Republic of the Philippines",
      "genderNeutralWriting": "Filipino",
      "residentsMaleWriting": "Filipino man",
      "residentsFemaleWriting": "Filipino woman",
      "continent": "Asia"
    }
  },
  "PN": {
    "flag": "🇵🇳",
    "code": "PN",
    "isoCode": "PCN",
    "continent": "Oceania",
    "DE": {
      "name": "Pitcairninseln",
      "shortName": "Pitcairninseln",
      "genderNeutralWriting": "Pitcairner:in",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Îles Pitcairn",
      "shortName": "Îles Pitcairn",
      "genderNeutralWriting": "Pitcairnais(e)",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isole Pitcairn",
      "shortName": "Isole Pitcairn",
      "genderNeutralWriting": "Pitcairnese",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Pitcairn Islands",
      "shortName": "Pitcairn Islands",
      "officialName": "Pitcairn Group of Islands",
      "genderNeutralWriting": "Pitcairn Islander",
      "continent": "Oceania"
    }
  },
  "PL": {
    "flag": "🇵🇱",
    "code": "PL",
    "isoCode": "POL",
    "continent": "Europe",
    "DE": {
      "name": "Polen",
      "shortName": "Polen",
      "officialName": "Republik Polen",
      "genderNeutralWriting": "Pole/Polin",
      "residentsMaleWriting": "der Pole",
      "residentsFemaleWriting": "die Polin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Pologne",
      "shortName": "Pologne",
      "officialName": "République de Pologne",
      "genderNeutralWriting": "Polonais(e)",
      "residentsMaleWriting": "le Polonais",
      "residentsFemaleWriting": "la Polonaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Polonia",
      "shortName": "Polonia",
      "officialName": "Repubblica di Polonia",
      "genderNeutralWriting": "Polacco/a",
      "residentsMaleWriting": "il Polacco",
      "residentsFemaleWriting": "la Polacca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Poland",
      "shortName": "Poland",
      "officialName": "Republic of Poland",
      "genderNeutralWriting": "Polish",
      "residentsMaleWriting": "Polish man",
      "residentsFemaleWriting": "Polish woman",
      "continent": "Europe"
    }
  },
  "PT": {
    "flag": "🇵🇹",
    "code": "PT",
    "isoCode": "PRT",
    "continent": "Europe",
    "DE": {
      "name": "Portugal",
      "shortName": "Portugal",
      "officialName": "Portugiesische Republik",
      "genderNeutralWriting": "Portugiese/Portugiesin",
      "residentsMaleWriting": "der Portugiese",
      "residentsFemaleWriting": "die Portugiesin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Portugal",
      "shortName": "Portugal",
      "officialName": "République portugaise",
      "genderNeutralWriting": "Portugais(e)",
      "residentsMaleWriting": "le Portugais",
      "residentsFemaleWriting": "la Portugaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Portogallo",
      "shortName": "Portogallo",
      "officialName": "Repubblica portoghese",
      "genderNeutralWriting": "Portoghese",
      "residentsMaleWriting": "il Portoghese",
      "residentsFemaleWriting": "la Portoghese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Portugal",
      "shortName": "Portugal",
      "officialName": "Portuguese Republic",
      "genderNeutralWriting": "Portuguese",
      "residentsMaleWriting": "Portuguese man",
      "residentsFemaleWriting": "Portuguese woman",
      "continent": "Europe"
    }
  },
  "PR": {
    "flag": "🇵🇷",
    "code": "PR",
    "isoCode": "PRI",
    "continent": "America",
    "DE": {
      "name": "Puerto Rico",
      "shortName": "Puerto Rico",
      "genderNeutralWriting": "Puerto-Ricaner:in",
      "residentsMaleWriting": "der Puerto-Ricaner",
      "residentsFemaleWriting": "die Puerto-Ricanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Porto Rico",
      "shortName": "Porto Rico",
      "genderNeutralWriting": "Portoricain(e)",
      "residentsMaleWriting": "le Portoricain",
      "residentsFemaleWriting": "la Portoricaine",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Porto Rico",
      "shortName": "Porto Rico",
      "genderNeutralWriting": "Portoricano/a",
      "residentsMaleWriting": "il Portoricano",
      "residentsFemaleWriting": "la Portoricana",
      "continent": "America"
    },
    "EN": {
      "name": "Puerto Rico",
      "shortName": "Puerto Rico",
      "officialName": "Commonwealth of Puerto Rico",
      "genderNeutralWriting": "Puerto Rican",
      "residentsMaleWriting": "Puerto Rican man",
      "residentsFemaleWriting": "Puerto Rican woman",
      "continent": "America"
    }
  },
  "RE": {
    "flag": "🇷🇪",
    "code": "RE",
    "isoCode": "REU",
    "continent": "Africa",
    "DE": {
      "name": "Réunion",
      "shortName": "Réunion",
      "genderNeutralWriting": "Réunionese:in",
      "continent": "Afrika"
    },
    "FR": {
      "name": "La Réunion",
      "shortName": "La Réunion",
      "genderNeutralWriting": "Réunionnais(e)",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Riunione",
      "shortName": "Riunione",
      "genderNeutralWriting": "Riunionese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Réunion",
      "shortName": "Réunion",
      "officialName": "Réunion Island",
      "genderNeutralWriting": "Réunionese",
      "continent": "Africa"
    }
  },
  "RW": {
    "flag": "🇷🇼",
    "code": "RW",
    "isoCode": "RWA",
    "continent": "Africa",
    "DE": {
      "name": "Ruanda",
      "shortName": "Ruanda",
      "officialName": "Republik Ruanda",
      "genderNeutralWriting": "Ruander:in",
      "residentsMaleWriting": "der Ruander",
      "residentsFemaleWriting": "die Ruanderin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Rwanda",
      "shortName": "Rwanda",
      "officialName": "République du Rwanda",
      "genderNeutralWriting": "Rwandais(e)",
      "residentsMaleWriting": "le Rwandais",
      "residentsFemaleWriting": "la Rwandaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Ruanda",
      "shortName": "Ruanda",
      "officialName": "Repubblica del Ruanda",
      "genderNeutralWriting": "Ruandese",
      "residentsMaleWriting": "il Ruandese",
      "residentsFemaleWriting": "la Ruandese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Rwanda",
      "shortName": "Rwanda",
      "officialName": "Republic of Rwanda",
      "genderNeutralWriting": "Rwandan",
      "residentsMaleWriting": "Rwandan man",
      "residentsFemaleWriting": "Rwandan woman",
      "continent": "Africa"
    }
  },
  "RO": {
    "flag": "🇷🇴",
    "code": "RO",
    "isoCode": "ROU",
    "continent": "Europe",
    "DE": {
      "name": "Rumänien",
      "shortName": "Rumänien",
      "officialName": "Rumänien",
      "genderNeutralWriting": "Rumäne/Rumänin",
      "residentsMaleWriting": "der Rumäne",
      "residentsFemaleWriting": "die Rumänin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Roumanie",
      "shortName": "Roumanie",
      "officialName": "Roumanie",
      "genderNeutralWriting": "Roumain(e)",
      "residentsMaleWriting": "le Roumain",
      "residentsFemaleWriting": "la Roumaine",
      "continent": "Europe"
    },
    "IT": {
      "name": "Romania",
      "shortName": "Romania",
      "officialName": "Romania",
      "genderNeutralWriting": "Rumeno/a",
      "residentsMaleWriting": "il Rumeno",
      "residentsFemaleWriting": "la Rumena",
      "continent": "Europa"
    },
    "EN": {
      "name": "Romania",
      "shortName": "Romania",
      "officialName": "Romania",
      "genderNeutralWriting": "Romanian",
      "residentsMaleWriting": "Romanian man",
      "residentsFemaleWriting": "Romanian woman",
      "continent": "Europe"
    }
  },
  "RU": {
    "flag": "🇷🇺",
    "code": "RU",
    "isoCode": "RUS",
    "continent": "Europe",
    "DE": {
      "name": "Russland",
      "shortName": "Russland",
      "officialName": "Russische Föderation",
      "genderNeutralWriting": "Russe/Russin",
      "residentsMaleWriting": "der Russe",
      "residentsFemaleWriting": "die Russin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Russie",
      "shortName": "Russie",
      "officialName": "Fédération de Russie",
      "genderNeutralWriting": "Russe",
      "residentsMaleWriting": "le Russe",
      "residentsFemaleWriting": "la Russe",
      "continent": "Europe"
    },
    "IT": {
      "name": "Russia",
      "shortName": "Russia",
      "officialName": "Federazione Russa",
      "genderNeutralWriting": "Russo/a",
      "residentsMaleWriting": "il Russo",
      "residentsFemaleWriting": "la Russa",
      "continent": "Europa"
    },
    "EN": {
      "name": "Russia",
      "shortName": "Russia",
      "officialName": "Russian Federation",
      "genderNeutralWriting": "Russian",
      "residentsMaleWriting": "Russian man",
      "residentsFemaleWriting": "Russian woman",
      "continent": "Europe"
    }
  },
  "BL": {
    "flag": "🇧🇱",
    "code": "BL",
    "isoCode": "BLM",
    "continent": "America",
    "DE": {
      "name": "Saint-Barthélemy",
      "shortName": "Saint-Barthélemy",
      "genderNeutralWriting": "Saint-Barthélemy-Bewohner:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Saint-Barthélemy",
      "shortName": "Saint-Barthélemy",
      "genderNeutralWriting": "Barthélemois(e)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Saint-Barthélemy",
      "shortName": "Saint-Barthélemy",
      "genderNeutralWriting": "Abitante di Saint-Barthélemy",
      "continent": "America"
    },
    "EN": {
      "name": "Saint Barthélemy",
      "shortName": "Saint Barthélemy",
      "officialName": "Collectivity of Saint Barthélemy",
      "genderNeutralWriting": "Saint Barthélemy resident",
      "continent": "America"
    }
  },
  "MF": {
    "flag": "🇲🇫",
    "code": "MF",
    "isoCode": "MAF",
    "continent": "America",
    "DE": {
      "name": "Saint-Martin",
      "shortName": "Saint-Martin (Frankreich)",
      "genderNeutralWriting": "Saint-Martin-Bewohner:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Saint-Martin",
      "shortName": "Saint-Martin (France)",
      "genderNeutralWriting": "Saint-Martinois(e)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Saint-Martin",
      "shortName": "Saint-Martin (Francia)",
      "genderNeutralWriting": "Abitante di Saint-Martin",
      "continent": "America"
    },
    "EN": {
      "name": "Saint Martin",
      "shortName": "Saint Martin (France)",
      "officialName": "Collectivity of Saint Martin",
      "genderNeutralWriting": "Saint Martin resident",
      "continent": "America"
    }
  },
  "SB": {
    "flag": "🇸🇧",
    "code": "SB",
    "isoCode": "SLB",
    "continent": "Oceania",
    "DE": {
      "name": "Salomonen",
      "shortName": "Salomonen",
      "officialName": "Salomonen",
      "genderNeutralWriting": "Salomoner:in",
      "residentsMaleWriting": "der Salomoner",
      "residentsFemaleWriting": "die Salomonerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Îles Salomon",
      "shortName": "Îles Salomon",
      "officialName": "Îles Salomon",
      "genderNeutralWriting": "Salomonien(ne)",
      "residentsMaleWriting": "le Salomonien",
      "residentsFemaleWriting": "la Salomonienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isole Salomone",
      "shortName": "Isole Salomone",
      "officialName": "Isole Salomone",
      "genderNeutralWriting": "Salomonese",
      "residentsMaleWriting": "il Salomonese",
      "residentsFemaleWriting": "la Salomonese",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Solomon Islands",
      "shortName": "Solomon Islands",
      "officialName": "Solomon Islands",
      "genderNeutralWriting": "Solomon Islander",
      "residentsMaleWriting": "Solomon Islander man",
      "residentsFemaleWriting": "Solomon Islander woman",
      "continent": "Oceania"
    }
  },
  "ZM": {
    "flag": "🇿🇲",
    "code": "ZM",
    "isoCode": "ZMB",
    "continent": "Africa",
    "DE": {
      "name": "Sambia",
      "shortName": "Sambia",
      "officialName": "Republik Sambia",
      "genderNeutralWriting": "Sambier:in",
      "residentsMaleWriting": "der Sambier",
      "residentsFemaleWriting": "die Sambierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Zambie",
      "shortName": "Zambie",
      "officialName": "République de Zambie",
      "genderNeutralWriting": "Zambien(ne)",
      "residentsMaleWriting": "le Zambien",
      "residentsFemaleWriting": "la Zambienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Zambia",
      "shortName": "Zambia",
      "officialName": "Repubblica dello Zambia",
      "genderNeutralWriting": "Zambiano/a",
      "residentsMaleWriting": "lo Zambiano",
      "residentsFemaleWriting": "la Zambiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Zambia",
      "shortName": "Zambia",
      "officialName": "Republic of Zambia",
      "genderNeutralWriting": "Zambian",
      "residentsMaleWriting": "Zambian man",
      "residentsFemaleWriting": "Zambian woman",
      "continent": "Africa"
    }
  },
  "WS": {
    "flag": "🇼🇸",
    "code": "WS",
    "isoCode": "WSM",
    "continent": "Oceania",
    "DE": {
      "name": "Samoa",
      "shortName": "Samoa",
      "officialName": "Unabhängiger Staat Samoa",
      "genderNeutralWriting": "Samoaner:in",
      "residentsMaleWriting": "der Samoaner",
      "residentsFemaleWriting": "die Samoanerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Samoa",
      "shortName": "Samoa",
      "officialName": "État indépendant du Samoa",
      "genderNeutralWriting": "Samoan(e)",
      "residentsMaleWriting": "le Samoan",
      "residentsFemaleWriting": "la Samoane",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Samoa",
      "shortName": "Samoa",
      "officialName": "Stato indipendente di Samoa",
      "genderNeutralWriting": "Samoano/a",
      "residentsMaleWriting": "il Samoano",
      "residentsFemaleWriting": "la Samoana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Samoa",
      "shortName": "Samoa",
      "officialName": "Independent State of Samoa",
      "genderNeutralWriting": "Samoan",
      "residentsMaleWriting": "Samoan man",
      "residentsFemaleWriting": "Samoan woman",
      "continent": "Oceania"
    }
  },
  "SM": {
    "flag": "🇸🇲",
    "code": "SM",
    "isoCode": "SMR",
    "continent": "Europe",
    "DE": {
      "name": "San Marino",
      "shortName": "San Marino",
      "officialName": "Republik San Marino",
      "genderNeutralWriting": "San-Marineser:in",
      "residentsMaleWriting": "der San-Marineser",
      "residentsFemaleWriting": "die San-Marineserin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Saint-Marin",
      "shortName": "Saint-Marin",
      "officialName": "République de Saint-Marin",
      "genderNeutralWriting": "Saint-Marinais(e)",
      "residentsMaleWriting": "le Saint-Marinais",
      "residentsFemaleWriting": "la Saint-Marinaise",
      "continent": "Europe"
    },
    "IT": {
      "name": "San Marino",
      "shortName": "San Marino",
      "officialName": "Repubblica di San Marino",
      "genderNeutralWriting": "Sammarinese",
      "residentsMaleWriting": "il Sammarinese",
      "residentsFemaleWriting": "la Sammarinese",
      "continent": "Europa"
    },
    "EN": {
      "name": "San Marino",
      "shortName": "San Marino",
      "officialName": "Republic of San Marino",
      "genderNeutralWriting": "Sammarinese",
      "residentsMaleWriting": "Sammarinese man",
      "residentsFemaleWriting": "Sammarinese woman",
      "continent": "Europe"
    }
  },
  "ST": {
    "flag": "🇸🇹",
    "code": "ST",
    "isoCode": "STP",
    "continent": "Africa",
    "DE": {
      "name": "São Tomé und Príncipe",
      "shortName": "São Tomé und Príncipe",
      "officialName": "Demokratische Republik São Tomé und Príncipe",
      "genderNeutralWriting": "São-Toméer:in",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Sao Tomé-et-Principe",
      "shortName": "Sao Tomé-et-Principe",
      "officialName": "République démocratique de Sao Tomé-et-Principe",
      "genderNeutralWriting": "São-Toméen(ne)",
      "continent": "Afrique"
    },
    "IT": {
      "name": "São Tomé e Príncipe",
      "shortName": "São Tomé e Príncipe",
      "officialName": "Repubblica democratica di São Tomé e Príncipe",
      "genderNeutralWriting": "Santomense",
      "continent": "Africa"
    },
    "EN": {
      "name": "São Tomé and Príncipe",
      "shortName": "São Tomé and Príncipe",
      "officialName": "Democratic Republic of São Tomé and Príncipe",
      "genderNeutralWriting": "São Toméan",
      "continent": "Africa"
    }
  },
  "SA": {
    "flag": "🇸🇦",
    "code": "SA",
    "isoCode": "SAU",
    "continent": "Asia",
    "DE": {
      "name": "Saudi-Arabien",
      "shortName": "Saudi-Arabien",
      "officialName": "Königreich Saudi-Arabien",
      "genderNeutralWriting": "Saudi-Araber:in",
      "residentsMaleWriting": "der Saudi-Araber",
      "residentsFemaleWriting": "die Saudi-Araberin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Arabie saoudite",
      "shortName": "Arabie saoudite",
      "officialName": "Royaume d'Arabie saoudite",
      "genderNeutralWriting": "Saoudien(ne)",
      "residentsMaleWriting": "le Saoudien",
      "residentsFemaleWriting": "la Saoudienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Arabia Saudita",
      "shortName": "Arabia Saudita",
      "officialName": "Regno dell'Arabia Saudita",
      "genderNeutralWriting": "Saudita",
      "residentsMaleWriting": "il Saudita",
      "residentsFemaleWriting": "la Saudita",
      "continent": "Asia"
    },
    "EN": {
      "name": "Saudi Arabia",
      "shortName": "Saudi Arabia",
      "officialName": "Kingdom of Saudi Arabia",
      "genderNeutralWriting": "Saudi",
      "residentsMaleWriting": "Saudi man",
      "residentsFemaleWriting": "Saudi woman",
      "continent": "Asia"
    }
  },
  "SE": {
    "flag": "🇸🇪",
    "code": "SE",
    "isoCode": "SWE",
    "continent": "Europe",
    "DE": {
      "name": "Schweden",
      "shortName": "Schweden",
      "officialName": "Königreich Schweden",
      "genderNeutralWriting": "Schwede/Schwedin",
      "residentsMaleWriting": "der Schwede",
      "residentsFemaleWriting": "die Schwedin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Suède",
      "shortName": "Suède",
      "officialName": "Royaume de Suède",
      "genderNeutralWriting": "Suédois(e)",
      "residentsMaleWriting": "le Suédois",
      "residentsFemaleWriting": "la Suédoise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Svezia",
      "shortName": "Svezia",
      "officialName": "Regno di Svezia",
      "genderNeutralWriting": "Svedese",
      "residentsMaleWriting": "lo Svedese",
      "residentsFemaleWriting": "la Svedese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Sweden",
      "shortName": "Sweden",
      "officialName": "Kingdom of Sweden",
      "genderNeutralWriting": "Swedish",
      "residentsMaleWriting": "Swedish man",
      "residentsFemaleWriting": "Swedish woman",
      "continent": "Europe"
    }
  },
  "CH": {
    "flag": "🇨🇭",
    "code": "CH",
    "isoCode": "CHE",
    "continent": "Europe",
    "DE": {
      "name": "Schweiz",
      "shortName": "Schweiz",
      "officialName": "Schweizerische Eidgenossenschaft",
      "genderNeutralWriting": "Schweizer:in",
      "residentsMaleWriting": "der Schweizer",
      "residentsFemaleWriting": "die Schweizerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Suisse",
      "shortName": "Suisse",
      "officialName": "Confédération suisse",
      "genderNeutralWriting": "Suisse",
      "residentsMaleWriting": "le Suisse",
      "residentsFemaleWriting": "la Suisse",
      "continent": "Europe"
    },
    "IT": {
      "name": "Svizzera",
      "shortName": "Svizzera",
      "officialName": "Confederazione Svizzera",
      "genderNeutralWriting": "Svizzero/a",
      "residentsMaleWriting": "lo Svizzero",
      "residentsFemaleWriting": "la Svizzera",
      "continent": "Europa"
    },
    "EN": {
      "name": "Switzerland",
      "shortName": "Switzerland",
      "officialName": "Swiss Confederation",
      "genderNeutralWriting": "Swiss",
      "residentsMaleWriting": "Swiss man",
      "residentsFemaleWriting": "Swiss woman",
      "continent": "Europe"
    }
  },
  "SN": {
    "flag": "🇸🇳",
    "code": "SN",
    "isoCode": "SEN",
    "continent": "Africa",
    "DE": {
      "name": "Senegal",
      "shortName": "Senegal",
      "officialName": "Republik Senegal",
      "genderNeutralWriting": "Senegalese/Senegalesin",
      "residentsMaleWriting": "der Senegalese",
      "residentsFemaleWriting": "die Senegalesin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Sénégal",
      "shortName": "Sénégal",
      "officialName": "République du Sénégal",
      "genderNeutralWriting": "Sénégalais(e)",
      "residentsMaleWriting": "le Sénégalais",
      "residentsFemaleWriting": "la Sénégalaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Senegal",
      "shortName": "Senegal",
      "officialName": "Repubblica del Senegal",
      "genderNeutralWriting": "Senegalese",
      "residentsMaleWriting": "il Senegalese",
      "residentsFemaleWriting": "la Senegalese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Senegal",
      "shortName": "Senegal",
      "officialName": "Republic of Senegal",
      "genderNeutralWriting": "Senegalese",
      "residentsMaleWriting": "Senegalese man",
      "residentsFemaleWriting": "Senegalese woman",
      "continent": "Africa"
    }
  },
  "RS": {
    "flag": "🇷🇸",
    "code": "RS",
    "isoCode": "SRB",
    "continent": "Europe",
    "DE": {
      "name": "Serbien",
      "shortName": "Serbien",
      "officialName": "Republik Serbien",
      "genderNeutralWriting": "Serbe/Serbin",
      "residentsMaleWriting": "der Serbe",
      "residentsFemaleWriting": "die Serbin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Serbie",
      "shortName": "Serbie",
      "officialName": "République de Serbie",
      "genderNeutralWriting": "Serbe",
      "residentsMaleWriting": "le Serbe",
      "residentsFemaleWriting": "la Serbe",
      "continent": "Europe"
    },
    "IT": {
      "name": "Serbia",
      "shortName": "Serbia",
      "officialName": "Repubblica di Serbia",
      "genderNeutralWriting": "Serbo/a",
      "residentsMaleWriting": "il Serbo",
      "residentsFemaleWriting": "la Serba",
      "continent": "Europa"
    },
    "EN": {
      "name": "Serbia",
      "shortName": "Serbia",
      "officialName": "Republic of Serbia",
      "genderNeutralWriting": "Serbian",
      "residentsMaleWriting": "Serbian man",
      "residentsFemaleWriting": "Serbian woman",
      "continent": "Europe"
    }
  },
  "SC": {
    "flag": "🇸🇨",
    "code": "SC",
    "isoCode": "SYC",
    "continent": "Africa",
    "DE": {
      "name": "Seychellen",
      "shortName": "Seychellen",
      "officialName": "Republik Seychellen",
      "genderNeutralWriting": "Seycheller:in",
      "residentsMaleWriting": "der Seycheller",
      "residentsFemaleWriting": "die Seychellerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Seychelles",
      "shortName": "Seychelles",
      "officialName": "République des Seychelles",
      "genderNeutralWriting": "Seychellois(e)",
      "residentsMaleWriting": "le Seychellois",
      "residentsFemaleWriting": "la Seychelloise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Seychelles",
      "shortName": "Seychelles",
      "officialName": "Repubblica delle Seychelles",
      "genderNeutralWriting": "Seychellese",
      "residentsMaleWriting": "il Seychellese",
      "residentsFemaleWriting": "la Seychellese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Seychelles",
      "shortName": "Seychelles",
      "officialName": "Republic of Seychelles",
      "genderNeutralWriting": "Seychellois",
      "residentsMaleWriting": "Seychellois man",
      "residentsFemaleWriting": "Seychellois woman",
      "continent": "Africa"
    }
  },
  "SL": {
    "flag": "🇸🇱",
    "code": "SL",
    "isoCode": "SLE",
    "continent": "Africa",
    "DE": {
      "name": "Sierra Leone",
      "shortName": "Sierra Leone",
      "officialName": "Republik Sierra Leone",
      "genderNeutralWriting": "Sierra-Leoner:in",
      "residentsMaleWriting": "der Sierra-Leoner",
      "residentsFemaleWriting": "die Sierra-Leonerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Sierra Leone",
      "shortName": "Sierra Leone",
      "officialName": "République de Sierra Leone",
      "genderNeutralWriting": "Sierra-Léonais(e)",
      "residentsMaleWriting": "le Sierra-Léonais",
      "residentsFemaleWriting": "la Sierra-Léonaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Sierra Leone",
      "shortName": "Sierra Leone",
      "officialName": "Repubblica della Sierra Leone",
      "genderNeutralWriting": "Sierraleonese",
      "residentsMaleWriting": "il Sierraleonese",
      "residentsFemaleWriting": "la Sierraleonese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Sierra Leone",
      "shortName": "Sierra Leone",
      "officialName": "Republic of Sierra Leone",
      "genderNeutralWriting": "Sierra Leonean",
      "residentsMaleWriting": "Sierra Leonean man",
      "residentsFemaleWriting": "Sierra Leonean woman",
      "continent": "Africa"
    }
  },
  "ZW": {
    "flag": "🇿🇼",
    "code": "ZW",
    "isoCode": "ZWE",
    "continent": "Africa",
    "DE": {
      "name": "Simbabwe",
      "shortName": "Simbabwe",
      "officialName": "Republik Simbabwe",
      "genderNeutralWriting": "Simbabwer:in",
      "residentsMaleWriting": "der Simbabwer",
      "residentsFemaleWriting": "die Simbabwerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Zimbabwe",
      "shortName": "Zimbabwe",
      "officialName": "République du Zimbabwe",
      "genderNeutralWriting": "Zimbabwéen(ne)",
      "residentsMaleWriting": "le Zimbabwéen",
      "residentsFemaleWriting": "la Zimbabwéenne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Zimbabwe",
      "shortName": "Zimbabwe",
      "officialName": "Repubblica dello Zimbabwe",
      "genderNeutralWriting": "Zimbabwese",
      "residentsMaleWriting": "lo Zimbabwese",
      "residentsFemaleWriting": "la Zimbabwese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Zimbabwe",
      "shortName": "Zimbabwe",
      "officialName": "Republic of Zimbabwe",
      "genderNeutralWriting": "Zimbabwean",
      "residentsMaleWriting": "Zimbabwean man",
      "residentsFemaleWriting": "Zimbabwean woman",
      "continent": "Africa"
    }
  },
  "SG": {
    "flag": "🇸🇬",
    "code": "SG",
    "isoCode": "SGP",
    "continent": "Asia",
    "DE": {
      "name": "Singapur",
      "shortName": "Singapur",
      "officialName": "Republik Singapur",
      "genderNeutralWriting": "Singapurer:in",
      "residentsMaleWriting": "der Singapurer",
      "residentsFemaleWriting": "die Singapurerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Singapour",
      "shortName": "Singapour",
      "officialName": "République de Singapour",
      "genderNeutralWriting": "Singapourien(ne)",
      "residentsMaleWriting": "le Singapourien",
      "residentsFemaleWriting": "la Singapourienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Singapore",
      "shortName": "Singapore",
      "officialName": "Repubblica di Singapore",
      "genderNeutralWriting": "Singaporiano/a",
      "residentsMaleWriting": "il Singaporiano",
      "residentsFemaleWriting": "la Singaporiana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Singapore",
      "shortName": "Singapore",
      "officialName": "Republic of Singapore",
      "genderNeutralWriting": "Singaporean",
      "residentsMaleWriting": "Singaporean man",
      "residentsFemaleWriting": "Singaporean woman",
      "continent": "Asia"
    }
  },
  "SX": {
    "flag": "🇸🇽",
    "code": "SX",
    "isoCode": "SXM",
    "continent": "America",
    "DE": {
      "name": "Sint Maarten",
      "shortName": "Sint Maarten (Niederlande)",
      "genderNeutralWriting": "Sint-Maartener:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Saint-Martin",
      "shortName": "Saint-Martin (Pays-Bas)",
      "genderNeutralWriting": "Saint-Martinois(e)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Sint Maarten",
      "shortName": "Sint Maarten (Paesi Bassi)",
      "genderNeutralWriting": "Abitante di Sint Maarten",
      "continent": "America"
    },
    "EN": {
      "name": "Sint Maarten",
      "shortName": "Sint Maarten (Dutch part)",
      "officialName": "Sint Maarten",
      "genderNeutralWriting": "Sint Maarten resident",
      "continent": "America"
    }
  },
  "SK": {
    "flag": "🇸🇰",
    "code": "SK",
    "isoCode": "SVK",
    "continent": "Europe",
    "DE": {
      "name": "Slowakei",
      "shortName": "Slowakei",
      "officialName": "Slowakische Republik",
      "genderNeutralWriting": "Slowake/Slowakin",
      "residentsMaleWriting": "der Slowake",
      "residentsFemaleWriting": "die Slowakin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Slovaquie",
      "shortName": "Slovaquie",
      "officialName": "République slovaque",
      "genderNeutralWriting": "Slovaque",
      "residentsMaleWriting": "le Slovaque",
      "residentsFemaleWriting": "la Slovaque",
      "continent": "Europe"
    },
    "IT": {
      "name": "Slovacchia",
      "shortName": "Slovacchia",
      "officialName": "Repubblica Slovacca",
      "genderNeutralWriting": "Slovacco/a",
      "residentsMaleWriting": "lo Slovacco",
      "residentsFemaleWriting": "la Slovacca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Slovakia",
      "shortName": "Slovakia",
      "officialName": "Slovak Republic",
      "genderNeutralWriting": "Slovak",
      "residentsMaleWriting": "Slovak man",
      "residentsFemaleWriting": "Slovak woman",
      "continent": "Europe"
    }
  },
  "SI": {
    "flag": "🇸🇮",
    "code": "SI",
    "isoCode": "SVN",
    "continent": "Europe",
    "DE": {
      "name": "Slowenien",
      "shortName": "Slowenien",
      "officialName": "Republik Slowenien",
      "genderNeutralWriting": "Slowene/Slowenin",
      "residentsMaleWriting": "der Slowene",
      "residentsFemaleWriting": "die Slowenin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Slovénie",
      "shortName": "Slovénie",
      "officialName": "République de Slovénie",
      "genderNeutralWriting": "Slovène",
      "residentsMaleWriting": "le Slovène",
      "residentsFemaleWriting": "la Slovène",
      "continent": "Europe"
    },
    "IT": {
      "name": "Slovenia",
      "shortName": "Slovenia",
      "officialName": "Repubblica di Slovenia",
      "genderNeutralWriting": "Sloveno/a",
      "residentsMaleWriting": "lo Sloveno",
      "residentsFemaleWriting": "la Slovena",
      "continent": "Europa"
    },
    "EN": {
      "name": "Slovenia",
      "shortName": "Slovenia",
      "officialName": "Republic of Slovenia",
      "genderNeutralWriting": "Slovenian",
      "residentsMaleWriting": "Slovenian man",
      "residentsFemaleWriting": "Slovenian woman",
      "continent": "Europe"
    }
  },
  "SO": {
    "flag": "🇸🇴",
    "code": "SO",
    "isoCode": "SOM",
    "continent": "Africa",
    "DE": {
      "name": "Somalia",
      "shortName": "Somalia",
      "officialName": "Bundesrepublik Somalia",
      "genderNeutralWriting": "Somalier:in",
      "residentsMaleWriting": "der Somalier",
      "residentsFemaleWriting": "die Somalierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Somalie",
      "shortName": "Somalie",
      "officialName": "République fédérale de Somalie",
      "genderNeutralWriting": "Somalien(ne)",
      "residentsMaleWriting": "le Somalien",
      "residentsFemaleWriting": "la Somalienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Somalia",
      "shortName": "Somalia",
      "officialName": "Repubblica Federale di Somalia",
      "genderNeutralWriting": "Somalo/a",
      "residentsMaleWriting": "il Somalo",
      "residentsFemaleWriting": "la Somala",
      "continent": "Africa"
    },
    "EN": {
      "name": "Somalia",
      "shortName": "Somalia",
      "officialName": "Federal Republic of Somalia",
      "genderNeutralWriting": "Somali",
      "residentsMaleWriting": "Somali man",
      "residentsFemaleWriting": "Somali woman",
      "continent": "Africa"
    }
  },
  "ES": {
    "flag": "🇪🇸",
    "code": "ES",
    "isoCode": "ESP",
    "continent": "Europe",
    "DE": {
      "name": "Spanien",
      "shortName": "Spanien",
      "officialName": "Königreich Spanien",
      "genderNeutralWriting": "Spanier:in",
      "residentsMaleWriting": "der Spanier",
      "residentsFemaleWriting": "die Spanierin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Espagne",
      "shortName": "Espagne",
      "officialName": "Royaume d'Espagne",
      "genderNeutralWriting": "Espagnol(e)",
      "residentsMaleWriting": "l'Espagnol",
      "residentsFemaleWriting": "l'Espagnole",
      "continent": "Europe"
    },
    "IT": {
      "name": "Spagna",
      "shortName": "Spagna",
      "officialName": "Regno di Spagna",
      "genderNeutralWriting": "Spagnolo/a",
      "residentsMaleWriting": "lo Spagnolo",
      "residentsFemaleWriting": "la Spagnola",
      "continent": "Europa"
    },
    "EN": {
      "name": "Spain",
      "shortName": "Spain",
      "officialName": "Kingdom of Spain",
      "genderNeutralWriting": "Spanish",
      "residentsMaleWriting": "Spanish man",
      "residentsFemaleWriting": "Spanish woman",
      "continent": "Europe"
    }
  },
  "LK": {
    "flag": "🇱🇰",
    "code": "LK",
    "isoCode": "LKA",
    "continent": "Asia",
    "DE": {
      "name": "Sri Lanka",
      "shortName": "Sri Lanka",
      "officialName": "Demokratische Sozialistische Republik Sri Lanka",
      "genderNeutralWriting": "Sri-Lanker:in",
      "residentsMaleWriting": "der Sri-Lanker",
      "residentsFemaleWriting": "die Sri-Lankerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Sri Lanka",
      "shortName": "Sri Lanka",
      "officialName": "République socialiste démocratique du Sri Lanka",
      "genderNeutralWriting": "Sri-Lankais(e)",
      "residentsMaleWriting": "le Sri-Lankais",
      "residentsFemaleWriting": "la Sri-Lankaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Sri Lanka",
      "shortName": "Sri Lanka",
      "officialName": "Repubblica Democratica Socialista dello Sri Lanka",
      "genderNeutralWriting": "Srilankese",
      "residentsMaleWriting": "lo Srilankese",
      "residentsFemaleWriting": "la Srilankese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Sri Lanka",
      "shortName": "Sri Lanka",
      "officialName": "Democratic Socialist Republic of Sri Lanka",
      "genderNeutralWriting": "Sri Lankan",
      "residentsMaleWriting": "Sri Lankan man",
      "residentsFemaleWriting": "Sri Lankan woman",
      "continent": "Asia"
    }
  },
  "KN": {
    "flag": "🇰🇳",
    "code": "KN",
    "isoCode": "KNA",
    "continent": "America",
    "DE": {
      "name": "St. Kitts und Nevis",
      "shortName": "St. Kitts und Nevis",
      "officialName": "Föderation St. Kitts und Nevis",
      "genderNeutralWriting": "Kittitier:in und Nevianer:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Saint-Kitts-et-Nevis",
      "shortName": "Saint-Kitts-et-Nevis",
      "officialName": "Fédération de Saint-Christophe-et-Niévès",
      "genderNeutralWriting": "Kittitien(ne)-et-Névicien(ne)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Saint Kitts e Nevis",
      "shortName": "Saint Kitts e Nevis",
      "officialName": "Federazione di Saint Christopher e Nevis",
      "genderNeutralWriting": "Kittitiano/a e Nevisiano/a",
      "continent": "America"
    },
    "EN": {
      "name": "Saint Kitts and Nevis",
      "shortName": "Saint Kitts and Nevis",
      "officialName": "Federation of Saint Christopher and Nevis",
      "genderNeutralWriting": "Kittitian and Nevisian",
      "continent": "America"
    }
  },
  "LC": {
    "flag": "🇱🇨",
    "code": "LC",
    "isoCode": "LCA",
    "continent": "America",
    "DE": {
      "name": "St. Lucia",
      "shortName": "St. Lucia",
      "officialName": "St. Lucia",
      "genderNeutralWriting": "Lucier:in",
      "residentsMaleWriting": "der Lucier",
      "residentsFemaleWriting": "die Lucierin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Sainte-Lucie",
      "shortName": "Sainte-Lucie",
      "officialName": "Sainte-Lucie",
      "genderNeutralWriting": "Saint-Lucien(ne)",
      "residentsMaleWriting": "le Saint-Lucien",
      "residentsFemaleWriting": "la Saint-Lucienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Santa Lucia",
      "shortName": "Santa Lucia",
      "officialName": "Santa Lucia",
      "genderNeutralWriting": "Santaluciano/a",
      "residentsMaleWriting": "il Santaluciano",
      "residentsFemaleWriting": "la Santaluciana",
      "continent": "America"
    },
    "EN": {
      "name": "Saint Lucia",
      "shortName": "Saint Lucia",
      "officialName": "Saint Lucia",
      "genderNeutralWriting": "Saint Lucian",
      "residentsMaleWriting": "Saint Lucian man",
      "residentsFemaleWriting": "Saint Lucian woman",
      "continent": "America"
    }
  },
  "PM": {
    "flag": "🇵🇲",
    "code": "PM",
    "isoCode": "SPM",
    "continent": "America",
    "DE": {
      "name": "St. Pierre und Miquelon",
      "shortName": "St. Pierre und Miquelon",
      "genderNeutralWriting": "St. Pierre und Miquelon-Bewohner:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Saint-Pierre-et-Miquelon",
      "shortName": "Saint-Pierre-et-Miquelon",
      "genderNeutralWriting": "Saint-Pierrais(e) et Miquelonnais(e)",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Saint-Pierre e Miquelon",
      "shortName": "Saint-Pierre e Miquelon",
      "genderNeutralWriting": "Abitante di Saint-Pierre e Miquelon",
      "continent": "America"
    },
    "EN": {
      "name": "Saint Pierre and Miquelon",
      "shortName": "Saint Pierre and Miquelon",
      "officialName": "Saint Pierre and Miquelon",
      "genderNeutralWriting": "Saint Pierre and Miquelon resident",
      "continent": "America"
    }
  },
  "VC": {
    "flag": "🇻🇨",
    "code": "VC",
    "isoCode": "VCT",
    "continent": "America",
    "DE": {
      "name": "St. Vincent und die Grenadinen",
      "shortName": "St. Vincent und die Grenadinen",
      "officialName": "St. Vincent und die Grenadinen",
      "genderNeutralWriting": "Vincenter:in",
      "residentsMaleWriting": "der Vincenter",
      "residentsFemaleWriting": "die Vincenterin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Saint-Vincent-et-les-Grenadines",
      "shortName": "Saint-Vincent-et-les-Grenadines",
      "officialName": "Saint-Vincent-et-les-Grenadines",
      "genderNeutralWriting": "Vincentais(e)",
      "residentsMaleWriting": "le Vincentais",
      "residentsFemaleWriting": "la Vincentaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Saint Vincent e Grenadine",
      "shortName": "Saint Vincent e Grenadine",
      "officialName": "Saint Vincent e Grenadine",
      "genderNeutralWriting": "Vincentino/a",
      "residentsMaleWriting": "il Vincentino",
      "residentsFemaleWriting": "la Vincentina",
      "continent": "America"
    },
    "EN": {
      "name": "Saint Vincent and the Grenadines",
      "shortName": "Saint Vincent and the Grenadines",
      "officialName": "Saint Vincent and the Grenadines",
      "genderNeutralWriting": "Vincentian",
      "residentsMaleWriting": "Vincentian man",
      "residentsFemaleWriting": "Vincentian woman",
      "continent": "America"
    }
  },
  "ZA": {
    "flag": "🇿🇦",
    "code": "ZA",
    "isoCode": "ZAF",
    "continent": "Africa",
    "DE": {
      "name": "Südafrika",
      "shortName": "Südafrika",
      "officialName": "Republik Südafrika",
      "genderNeutralWriting": "Südafrikaner:in",
      "residentsMaleWriting": "der Südafrikaner",
      "residentsFemaleWriting": "die Südafrikanerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Afrique du Sud",
      "shortName": "Afrique du Sud",
      "officialName": "République d'Afrique du Sud",
      "genderNeutralWriting": "Sud-Africain(e)",
      "residentsMaleWriting": "le Sud-Africain",
      "residentsFemaleWriting": "la Sud-Africaine",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Sudafrica",
      "shortName": "Sudafrica",
      "officialName": "Repubblica del Sudafrica",
      "genderNeutralWriting": "Sudafricano/a",
      "residentsMaleWriting": "il Sudafricano",
      "residentsFemaleWriting": "la Sudafricana",
      "continent": "Africa"
    },
    "EN": {
      "name": "South Africa",
      "shortName": "South Africa",
      "officialName": "Republic of South Africa",
      "genderNeutralWriting": "South African",
      "residentsMaleWriting": "South African man",
      "residentsFemaleWriting": "South African woman",
      "continent": "Africa"
    }
  },
  "SD": {
    "flag": "🇸🇩",
    "code": "SD",
    "isoCode": "SDN",
    "continent": "Africa",
    "DE": {
      "name": "Sudan",
      "shortName": "Sudan",
      "officialName": "Republik Sudan",
      "genderNeutralWriting": "Sudanese/Sudanesin",
      "residentsMaleWriting": "der Sudanese",
      "residentsFemaleWriting": "die Sudanesin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Soudan",
      "shortName": "Soudan",
      "officialName": "République du Soudan",
      "genderNeutralWriting": "Soudanais(e)",
      "residentsMaleWriting": "le Soudanais",
      "residentsFemaleWriting": "la Soudanaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Sudan",
      "shortName": "Sudan",
      "officialName": "Repubblica del Sudan",
      "genderNeutralWriting": "Sudanese",
      "residentsMaleWriting": "il Sudanese",
      "residentsFemaleWriting": "la Sudanese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Sudan",
      "shortName": "Sudan",
      "officialName": "Republic of the Sudan",
      "genderNeutralWriting": "Sudanese",
      "residentsMaleWriting": "Sudanese man",
      "residentsFemaleWriting": "Sudanese woman",
      "continent": "Africa"
    }
  },
  "GS": {
    "flag": "🇬🇸",
    "code": "GS",
    "isoCode": "SGS",
    "continent": "America",
    "DE": {
      "name": "Südgeorgien und die Südlichen Sandwichinseln",
      "shortName": "Südgeorgien und Südliche Sandwichinseln",
      "genderNeutralWriting": "Südgeorgien und Südliche Sandwichinseln-Bewohner:in",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Géorgie du Sud-et-les Îles Sandwich du Sud",
      "shortName": "Géorgie du Sud et les Îles Sandwich du Sud",
      "genderNeutralWriting": "Habitant(e) de la Géorgie du Sud et des Îles Sandwich du Sud",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Georgia del Sud e Isole Sandwich Australi",
      "shortName": "Georgia del Sud e Isole Sandwich Australi",
      "genderNeutralWriting": "Abitante della Georgia del Sud e delle Isole Sandwich Australi",
      "continent": "America"
    },
    "EN": {
      "name": "South Georgia and the South Sandwich Islands",
      "shortName": "South Georgia and the South Sandwich Islands",
      "officialName": "South Georgia and the South Sandwich Islands",
      "genderNeutralWriting": "South Georgia and South Sandwich Islands resident",
      "continent": "America"
    }
  },
  "SS": {
    "flag": "🇸🇸",
    "code": "SS",
    "isoCode": "SSD",
    "continent": "Africa",
    "DE": {
      "name": "Südsudan",
      "shortName": "Südsudan",
      "officialName": "Republik Südsudan",
      "genderNeutralWriting": "Südsudanese/Südsudanesin",
      "residentsMaleWriting": "der Südsudanese",
      "residentsFemaleWriting": "die Südsudanesin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Soudan du Sud",
      "shortName": "Soudan du Sud",
      "officialName": "République du Soudan du Sud",
      "genderNeutralWriting": "Sud-Soudanais(e)",
      "residentsMaleWriting": "le Sud-Soudanais",
      "residentsFemaleWriting": "la Sud-Soudanaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Sudan del Sud",
      "shortName": "Sudan del Sud",
      "officialName": "Repubblica del Sudan del Sud",
      "genderNeutralWriting": "Sudsudanese",
      "residentsMaleWriting": "il Sudsudanese",
      "residentsFemaleWriting": "la Sudsudanese",
      "continent": "Africa"
    },
    "EN": {
      "name": "South Sudan",
      "shortName": "South Sudan",
      "officialName": "Republic of South Sudan",
      "genderNeutralWriting": "South Sudanese",
      "residentsMaleWriting": "South Sudanese man",
      "residentsFemaleWriting": "South Sudanese woman",
      "continent": "Africa"
    }
  },
  "SR": {
    "flag": "🇸🇷",
    "code": "SR",
    "isoCode": "SUR",
    "continent": "America",
    "DE": {
      "name": "Suriname",
      "shortName": "Suriname",
      "officialName": "Republik Suriname",
      "genderNeutralWriting": "Surinamer:in",
      "residentsMaleWriting": "der Surinamer",
      "residentsFemaleWriting": "die Surinamerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Suriname",
      "shortName": "Suriname",
      "officialName": "République du Suriname",
      "genderNeutralWriting": "Surinamais(e)",
      "residentsMaleWriting": "le Surinamais",
      "residentsFemaleWriting": "la Surinamaise",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Suriname",
      "shortName": "Suriname",
      "officialName": "Repubblica del Suriname",
      "genderNeutralWriting": "Surinamese",
      "residentsMaleWriting": "il Surinamese",
      "residentsFemaleWriting": "la Surinamese",
      "continent": "America"
    },
    "EN": {
      "name": "Suriname",
      "shortName": "Suriname",
      "officialName": "Republic of Suriname",
      "genderNeutralWriting": "Surinamese",
      "residentsMaleWriting": "Surinamese man",
      "residentsFemaleWriting": "Surinamese woman",
      "continent": "America"
    }
  },
  "SJ": {
    "flag": "🏳️",
    "code": "SJ",
    "isoCode": "SJM",
    "continent": "Europe",
    "DE": {
      "name": "Svalbard und Jan Mayen",
      "shortName": "Svalbard und Jan Mayen",
      "genderNeutralWriting": "Svalbard und Jan Mayen-Bewohner:in",
      "continent": "Europa"
    },
    "FR": {
      "name": "Svalbard et Île Jan Mayen",
      "shortName": "Svalbard et Île Jan Mayen",
      "genderNeutralWriting": "Habitant(e) du Svalbard et de l'Île Jan Mayen",
      "continent": "Europe"
    },
    "IT": {
      "name": "Svalbard e Jan Mayen",
      "shortName": "Svalbard e Jan Mayen",
      "genderNeutralWriting": "Abitante delle Svalbard e di Jan Mayen",
      "continent": "Europa"
    },
    "EN": {
      "name": "Svalbard and Jan Mayen",
      "shortName": "Svalbard and Jan Mayen",
      "officialName": "Svalbard and Jan Mayen",
      "genderNeutralWriting": "Svalbard and Jan Mayen resident",
      "continent": "Europe"
    }
  },
  "SY": {
    "flag": "🇸🇾",
    "code": "SY",
    "isoCode": "SYR",
    "continent": "Asia",
    "DE": {
      "name": "Syrien",
      "shortName": "Syrien",
      "officialName": "Arabische Republik Syrien",
      "genderNeutralWriting": "Syrer:in",
      "residentsMaleWriting": "der Syrer",
      "residentsFemaleWriting": "die Syrerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Syrie",
      "shortName": "Syrie",
      "officialName": "République arabe syrienne",
      "genderNeutralWriting": "Syrien(ne)",
      "residentsMaleWriting": "le Syrien",
      "residentsFemaleWriting": "la Syrienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Siria",
      "shortName": "Siria",
      "officialName": "Repubblica Araba di Siria",
      "genderNeutralWriting": "Siriano/a",
      "residentsMaleWriting": "il Siriano",
      "residentsFemaleWriting": "la Siriana",
      "continent": "Asia"
    },
    "EN": {
      "name": "Syria",
      "shortName": "Syria",
      "officialName": "Syrian Arab Republic",
      "genderNeutralWriting": "Syrian",
      "residentsMaleWriting": "Syrian man",
      "residentsFemaleWriting": "Syrian woman",
      "continent": "Asia"
    }
  },
  "TJ": {
    "flag": "🇹🇯",
    "code": "TJ",
    "isoCode": "TJK",
    "continent": "Asia",
    "DE": {
      "name": "Tadschikistan",
      "shortName": "Tadschikistan",
      "officialName": "Republik Tadschikistan",
      "genderNeutralWriting": "Tadschike/Tadschik:in",
      "residentsMaleWriting": "der Tadschike",
      "residentsFemaleWriting": "die Tadschik:in",
      "continent": "Asien"
    },
    "FR": {
      "name": "Tadjikistan",
      "shortName": "Tadjikistan",
      "officialName": "République du Tadjikistan",
      "genderNeutralWriting": "Tadjik(e)",
      "residentsMaleWriting": "le Tadjik",
      "residentsFemaleWriting": "la Tadjike",
      "continent": "Asie"
    },
    "IT": {
      "name": "Tagikistan",
      "shortName": "Tagikistan",
      "officialName": "Repubblica del Tagikistan",
      "genderNeutralWriting": "Tagiko/a",
      "residentsMaleWriting": "il Tagiko",
      "residentsFemaleWriting": "la Tagika",
      "continent": "Asia"
    },
    "EN": {
      "name": "Tajikistan",
      "shortName": "Tajikistan",
      "officialName": "Republic of Tajikistan",
      "genderNeutralWriting": "Tajik",
      "residentsMaleWriting": "Tajik man",
      "residentsFemaleWriting": "Tajik woman",
      "continent": "Asia"
    }
  },
  "TW": {
    "flag": "🇹🇼",
    "code": "TW",
    "isoCode": "TWN",
    "continent": "Asia",
    "DE": {
      "name": "Taiwan",
      "shortName": "Taiwan",
      "officialName": "Republik China (Taiwan)",
      "genderNeutralWriting": "Taiwaner:in",
      "residentsMaleWriting": "der Taiwaner",
      "residentsFemaleWriting": "die Taiwanerin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Taïwan",
      "shortName": "Taïwan",
      "officialName": "République de Chine (Taïwan)",
      "genderNeutralWriting": "Taïwanais(e)",
      "residentsMaleWriting": "le Taïwanais",
      "residentsFemaleWriting": "la Taïwanaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Taiwan",
      "shortName": "Taiwan",
      "officialName": "Repubblica di Cina (Taiwan)",
      "genderNeutralWriting": "Taiwanese",
      "residentsMaleWriting": "il Taiwanese",
      "residentsFemaleWriting": "la Taiwanese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Taiwan",
      "shortName": "Taiwan",
      "officialName": "Republic of China (Taiwan)",
      "genderNeutralWriting": "Taiwanese",
      "residentsMaleWriting": "Taiwanese man",
      "residentsFemaleWriting": "Taiwanese woman",
      "continent": "Asia"
    }
  },
  "TZ": {
    "flag": "🇹🇿",
    "code": "TZ",
    "isoCode": "TZA",
    "continent": "Africa",
    "DE": {
      "name": "Tansania",
      "shortName": "Tansania",
      "officialName": "Vereinigte Republik Tansania",
      "genderNeutralWriting": "Tansanier:in",
      "residentsMaleWriting": "der Tansanier",
      "residentsFemaleWriting": "die Tansanierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Tanzanie",
      "shortName": "Tanzanie",
      "officialName": "République-Unie de Tanzanie",
      "genderNeutralWriting": "Tanzanien(ne)",
      "residentsMaleWriting": "le Tanzanien",
      "residentsFemaleWriting": "la Tanzanienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Tanzania",
      "shortName": "Tanzania",
      "officialName": "Repubblica Unita di Tanzania",
      "genderNeutralWriting": "Tanzaniano/a",
      "residentsMaleWriting": "il Tanzaniano",
      "residentsFemaleWriting": "la Tanzaniana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Tanzania",
      "shortName": "Tanzania",
      "officialName": "United Republic of Tanzania",
      "genderNeutralWriting": "Tanzanian",
      "residentsMaleWriting": "Tanzanian man",
      "residentsFemaleWriting": "Tanzanian woman",
      "continent": "Africa"
    }
  },
  "TH": {
    "flag": "🇹🇭",
    "code": "TH",
    "isoCode": "THA",
    "continent": "Asia",
    "DE": {
      "name": "Thailand",
      "shortName": "Thailand",
      "officialName": "Königreich Thailand",
      "genderNeutralWriting": "Thailänder:in",
      "residentsMaleWriting": "der Thailänder",
      "residentsFemaleWriting": "die Thailänderin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Thaïlande",
      "shortName": "Thaïlande",
      "officialName": "Royaume de Thaïlande",
      "genderNeutralWriting": "Thaïlandais(e)",
      "residentsMaleWriting": "le Thaïlandais",
      "residentsFemaleWriting": "la Thaïlandaise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Thailandia",
      "shortName": "Thailandia",
      "officialName": "Regno di Thailandia",
      "genderNeutralWriting": "Thailandese",
      "residentsMaleWriting": "il Thailandese",
      "residentsFemaleWriting": "la Thailandese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Thailand",
      "shortName": "Thailand",
      "officialName": "Kingdom of Thailand",
      "genderNeutralWriting": "Thai",
      "residentsMaleWriting": "Thai man",
      "residentsFemaleWriting": "Thai woman",
      "continent": "Asia"
    }
  },
  "TL": {
    "flag": "🏳️",
    "code": "TL",
    "isoCode": "TLS",
    "continent": "Asia",
    "DE": {
      "name": "Timor-Leste",
      "shortName": "Timor-Leste",
      "officialName": "Demokratische Republik Timor-Leste",
      "genderNeutralWriting": "Osttimorese/Osttimoresin",
      "residentsMaleWriting": "der Osttimorese",
      "residentsFemaleWriting": "die Osttimoresin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Timor oriental",
      "shortName": "Timor oriental",
      "officialName": "République démocratique du Timor oriental",
      "genderNeutralWriting": "Timorais(e)",
      "residentsMaleWriting": "le Timorais",
      "residentsFemaleWriting": "la Timoraise",
      "continent": "Asie"
    },
    "IT": {
      "name": "Timor Est",
      "shortName": "Timor Est",
      "officialName": "Repubblica Democratica di Timor Est",
      "genderNeutralWriting": "Timorese",
      "residentsMaleWriting": "il Timorese",
      "residentsFemaleWriting": "la Timorese",
      "continent": "Asia"
    },
    "EN": {
      "name": "Timor-Leste",
      "shortName": "Timor-Leste",
      "officialName": "Democratic Republic of Timor-Leste",
      "genderNeutralWriting": "Timorese",
      "residentsMaleWriting": "Timorese man",
      "residentsFemaleWriting": "Timorese woman",
      "continent": "Asia"
    }
  },
  "TG": {
    "flag": "🇹🇬",
    "code": "TG",
    "isoCode": "TGO",
    "continent": "Africa",
    "DE": {
      "name": "Togo",
      "shortName": "Togo",
      "officialName": "Republik Togo",
      "genderNeutralWriting": "Togoer:in",
      "residentsMaleWriting": "der Togoer",
      "residentsFemaleWriting": "die Togoerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Togo",
      "shortName": "Togo",
      "officialName": "République togolaise",
      "genderNeutralWriting": "Togolais(e)",
      "residentsMaleWriting": "le Togolais",
      "residentsFemaleWriting": "la Togolaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Togo",
      "shortName": "Togo",
      "officialName": "Repubblica Togolese",
      "genderNeutralWriting": "Togolese",
      "residentsMaleWriting": "il Togolese",
      "residentsFemaleWriting": "la Togolese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Togo",
      "shortName": "Togo",
      "officialName": "Togolese Republic",
      "genderNeutralWriting": "Togolese",
      "residentsMaleWriting": "Togolese man",
      "residentsFemaleWriting": "Togolese woman",
      "continent": "Africa"
    }
  },
  "TK": {
    "flag": "🇹🇰",
    "code": "TK",
    "isoCode": "TKL",
    "continent": "Oceania",
    "DE": {
      "name": "Tokelau",
      "shortName": "Tokelau",
      "genderNeutralWriting": "Tokelauer:in",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Tokelau",
      "shortName": "Tokelau",
      "genderNeutralWriting": "Tokelauan(e)",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Tokelau",
      "shortName": "Tokelau",
      "genderNeutralWriting": "Tokelauana/o",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Tokelau",
      "shortName": "Tokelau",
      "officialName": "Tokelau",
      "genderNeutralWriting": "Tokelauan",
      "continent": "Oceania"
    }
  },
  "TO": {
    "flag": "🇹🇴",
    "code": "TO",
    "isoCode": "TON",
    "continent": "Oceania",
    "DE": {
      "name": "Tonga",
      "shortName": "Tonga",
      "officialName": "Königreich Tonga",
      "genderNeutralWriting": "Tongaer:in",
      "residentsMaleWriting": "der Tongaer",
      "residentsFemaleWriting": "die Tongaerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Tonga",
      "shortName": "Tonga",
      "officialName": "Royaume des Tonga",
      "genderNeutralWriting": "Tongien(ne)",
      "residentsMaleWriting": "le Tongien",
      "residentsFemaleWriting": "la Tongienne",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Tonga",
      "shortName": "Tonga",
      "officialName": "Regno di Tonga",
      "genderNeutralWriting": "Tongano/a",
      "residentsMaleWriting": "il Tongano",
      "residentsFemaleWriting": "la Tongana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Tonga",
      "shortName": "Tonga",
      "officialName": "Kingdom of Tonga",
      "genderNeutralWriting": "Tongan",
      "residentsMaleWriting": "Tongan man",
      "residentsFemaleWriting": "Tongan woman",
      "continent": "Oceania"
    }
  },
  "TT": {
    "flag": "🇹🇹",
    "code": "TT",
    "isoCode": "TTO",
    "continent": "America",
    "DE": {
      "name": "Trinidad und Tobago",
      "shortName": "Trinidad und Tobago",
      "officialName": "Republik Trinidad und Tobago",
      "genderNeutralWriting": "Trinidader:in und Tobagonier:in",
      "residentsMaleWriting": "der Trinidader und Tobagonier",
      "residentsFemaleWriting": "die Trinidaderin und Tobagonierin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Trinité-et-Tobago",
      "shortName": "Trinité-et-Tobago",
      "officialName": "République de Trinité-et-Tobago",
      "genderNeutralWriting": "Trinidadien(ne)",
      "residentsMaleWriting": "le Trinidadien",
      "residentsFemaleWriting": "la Trinidadienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Trinidad e Tobago",
      "shortName": "Trinidad e Tobago",
      "officialName": "Repubblica di Trinidad e Tobago",
      "genderNeutralWriting": "Trinidadiano/a",
      "residentsMaleWriting": "il Trinidadiano",
      "residentsFemaleWriting": "la Trinidadiana",
      "continent": "America"
    },
    "EN": {
      "name": "Trinidad and Tobago",
      "shortName": "Trinidad and Tobago",
      "officialName": "Republic of Trinidad and Tobago",
      "genderNeutralWriting": "Trinidadian",
      "residentsMaleWriting": "Trinidadian man",
      "residentsFemaleWriting": "Trinidadian woman",
      "continent": "America"
    }
  },
  "TD": {
    "flag": "🇹🇩",
    "code": "TD",
    "isoCode": "TCD",
    "continent": "Africa",
    "DE": {
      "name": "Tschad",
      "shortName": "Tschad",
      "officialName": "Republik Tschad",
      "genderNeutralWriting": "Tschader:in",
      "residentsMaleWriting": "der Tschader",
      "residentsFemaleWriting": "die Tschaderin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Tchad",
      "shortName": "Tchad",
      "officialName": "République du Tchad",
      "genderNeutralWriting": "Tchadien(ne)",
      "residentsMaleWriting": "le Tchadien",
      "residentsFemaleWriting": "la Tchadienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Ciad",
      "shortName": "Ciad",
      "officialName": "Repubblica del Ciad",
      "genderNeutralWriting": "Ciadiano/a",
      "residentsMaleWriting": "il Ciadiano",
      "residentsFemaleWriting": "la Ciadiana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Chad",
      "shortName": "Chad",
      "officialName": "Republic of Chad",
      "genderNeutralWriting": "Chadian",
      "residentsMaleWriting": "Chadian man",
      "residentsFemaleWriting": "Chadian woman",
      "continent": "Africa"
    }
  },
  "CZ": {
    "flag": "🇨🇿",
    "code": "CZ",
    "isoCode": "CZE",
    "continent": "Europe",
    "DE": {
      "name": "Tschechien",
      "shortName": "Tschechien",
      "officialName": "Tschechische Republik",
      "genderNeutralWriting": "Tschech:in",
      "residentsMaleWriting": "der Tscheche",
      "residentsFemaleWriting": "die Tschechin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Tchéquie",
      "shortName": "Tchéquie",
      "officialName": "République tchèque",
      "genderNeutralWriting": "Tchèque",
      "residentsMaleWriting": "le Tchèque",
      "residentsFemaleWriting": "la Tchèque",
      "continent": "Europe"
    },
    "IT": {
      "name": "Repubblica Ceca",
      "shortName": "Repubblica Ceca",
      "officialName": "Repubblica Ceca",
      "genderNeutralWriting": "Ceco/a",
      "residentsMaleWriting": "il Ceco",
      "residentsFemaleWriting": "la Ceca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Czechia",
      "shortName": "Czechia",
      "officialName": "Czech Republic",
      "genderNeutralWriting": "Czech",
      "residentsMaleWriting": "Czech man",
      "residentsFemaleWriting": "Czech woman",
      "continent": "Europe"
    }
  },
  "TN": {
    "flag": "🇹🇳",
    "code": "TN",
    "isoCode": "TUN",
    "continent": "Africa",
    "DE": {
      "name": "Tunesien",
      "shortName": "Tunesien",
      "officialName": "Tunesische Republik",
      "genderNeutralWriting": "Tunesier:in",
      "residentsMaleWriting": "der Tunesier",
      "residentsFemaleWriting": "die Tunesierin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Tunisie",
      "shortName": "Tunisie",
      "officialName": "République tunisienne",
      "genderNeutralWriting": "Tunisien(ne)",
      "residentsMaleWriting": "le Tunisien",
      "residentsFemaleWriting": "la Tunisienne",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Tunisia",
      "shortName": "Tunisia",
      "officialName": "Repubblica tunisina",
      "genderNeutralWriting": "Tunisino/a",
      "residentsMaleWriting": "il Tunisino",
      "residentsFemaleWriting": "la Tunisina",
      "continent": "Africa"
    },
    "EN": {
      "name": "Tunisia",
      "shortName": "Tunisia",
      "officialName": "Republic of Tunisia",
      "genderNeutralWriting": "Tunisian",
      "residentsMaleWriting": "Tunisian man",
      "residentsFemaleWriting": "Tunisian woman",
      "continent": "Africa"
    }
  },
  "TR": {
    "flag": "🇹🇷",
    "code": "TR",
    "isoCode": "TUR",
    "continent": "Europe",
    "DE": {
      "name": "Türkei",
      "shortName": "Türkei",
      "officialName": "Republik Türkei",
      "genderNeutralWriting": "Türk:in",
      "residentsMaleWriting": "der Türke",
      "residentsFemaleWriting": "die Türkin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Turquie",
      "shortName": "Turquie",
      "officialName": "République de Turquie",
      "genderNeutralWriting": "Turc/Turque",
      "residentsMaleWriting": "le Turc",
      "residentsFemaleWriting": "la Turque",
      "continent": "Europe"
    },
    "IT": {
      "name": "Turchia",
      "shortName": "Turchia",
      "officialName": "Repubblica di Turchia",
      "genderNeutralWriting": "Turco/a",
      "residentsMaleWriting": "il Turco",
      "residentsFemaleWriting": "la Turca",
      "continent": "Europa"
    },
    "EN": {
      "name": "Turkey",
      "shortName": "Turkey",
      "officialName": "Republic of Turkey",
      "genderNeutralWriting": "Turkish",
      "residentsMaleWriting": "Turkish man",
      "residentsFemaleWriting": "Turkish woman",
      "continent": "Europe"
    }
  },
  "TM": {
    "flag": "🇹🇲",
    "code": "TM",
    "isoCode": "TKM",
    "continent": "Asia",
    "DE": {
      "name": "Turkmenistan",
      "shortName": "Turkmenistan",
      "officialName": "Turkmenistan",
      "genderNeutralWriting": "Turkmene/Turkmenin",
      "residentsMaleWriting": "der Turkmene",
      "residentsFemaleWriting": "die Turkmenin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Turkménistan",
      "shortName": "Turkménistan",
      "officialName": "Turkménistan",
      "genderNeutralWriting": "Turkmène",
      "residentsMaleWriting": "le Turkmène",
      "residentsFemaleWriting": "la Turkmène",
      "continent": "Asie"
    },
    "IT": {
      "name": "Turkmenistan",
      "shortName": "Turkmenistan",
      "officialName": "Turkmenistan",
      "genderNeutralWriting": "Turkmeno/a",
      "residentsMaleWriting": "il Turkmeno",
      "residentsFemaleWriting": "la Turkmena",
      "continent": "Asia"
    },
    "EN": {
      "name": "Turkmenistan",
      "shortName": "Turkmenistan",
      "officialName": "Turkmenistan",
      "genderNeutralWriting": "Turkmen",
      "residentsMaleWriting": "Turkmen man",
      "residentsFemaleWriting": "Turkmen woman",
      "continent": "Asia"
    }
  },
  "TC": {
    "flag": "🇹🇨",
    "code": "TC",
    "isoCode": "TCA",
    "continent": "America",
    "DE": {
      "name": "Turks- und Caicosinseln",
      "shortName": "Turks- und Caicosinseln",
      "genderNeutralWriting": "Bewohner:in der Turks- und Caicosinseln",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Îles Turques-et-Caïques",
      "shortName": "Îles Turques-et-Caïques",
      "genderNeutralWriting": "Habitant(e) des Îles Turques-et-Caïques",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Isole Turks e Caicos",
      "shortName": "Isole Turks e Caicos",
      "genderNeutralWriting": "Abitante delle Isole Turks e Caicos",
      "continent": "America"
    },
    "EN": {
      "name": "Turks and Caicos Islands",
      "shortName": "Turks and Caicos Islands",
      "officialName": "Turks and Caicos Islands",
      "genderNeutralWriting": "Turks and Caicos Islander",
      "continent": "America"
    }
  },
  "TV": {
    "flag": "🇹🇻",
    "code": "TV",
    "isoCode": "TUV",
    "continent": "Oceania",
    "DE": {
      "name": "Tuvalu",
      "shortName": "Tuvalu",
      "officialName": "Tuvalu",
      "genderNeutralWriting": "Tuvaluer:in",
      "residentsMaleWriting": "der Tuvaluer",
      "residentsFemaleWriting": "die Tuvaluerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Tuvalu",
      "shortName": "Tuvalu",
      "officialName": "Tuvalu",
      "genderNeutralWriting": "Tuvaluan(e)",
      "residentsMaleWriting": "le Tuvaluan",
      "residentsFemaleWriting": "la Tuvaluane",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Tuvalu",
      "shortName": "Tuvalu",
      "officialName": "Tuvalu",
      "genderNeutralWriting": "Tuvaluano/a",
      "residentsMaleWriting": "il Tuvaluano",
      "residentsFemaleWriting": "la Tuvaluana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Tuvalu",
      "shortName": "Tuvalu",
      "officialName": "Tuvalu",
      "genderNeutralWriting": "Tuvaluan",
      "residentsMaleWriting": "Tuvaluan man",
      "residentsFemaleWriting": "Tuvaluan woman",
      "continent": "Oceania"
    }
  },
  "UG": {
    "flag": "🇺🇬",
    "code": "UG",
    "isoCode": "UGA",
    "continent": "Africa",
    "DE": {
      "name": "Uganda",
      "shortName": "Uganda",
      "officialName": "Republik Uganda",
      "genderNeutralWriting": "Ugander:in",
      "residentsMaleWriting": "der Ugander",
      "residentsFemaleWriting": "die Uganderin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Ouganda",
      "shortName": "Ouganda",
      "officialName": "République de l'Ouganda",
      "genderNeutralWriting": "Ougandais(e)",
      "residentsMaleWriting": "l'Ougandais",
      "residentsFemaleWriting": "l'Ougandaise",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Uganda",
      "shortName": "Uganda",
      "officialName": "Repubblica dell'Uganda",
      "genderNeutralWriting": "Ugandese",
      "residentsMaleWriting": "l'Ugandese",
      "residentsFemaleWriting": "l'Ugandese",
      "continent": "Africa"
    },
    "EN": {
      "name": "Uganda",
      "shortName": "Uganda",
      "officialName": "Republic of Uganda",
      "genderNeutralWriting": "Ugandan",
      "residentsMaleWriting": "Ugandan man",
      "residentsFemaleWriting": "Ugandan woman",
      "continent": "Africa"
    }
  },
  "UA": {
    "flag": "🇺🇦",
    "code": "UA",
    "isoCode": "UKR",
    "continent": "Europe",
    "DE": {
      "name": "Ukraine",
      "shortName": "Ukraine",
      "officialName": "Ukraine",
      "genderNeutralWriting": "Ukrainer:in",
      "residentsMaleWriting": "der Ukrainer",
      "residentsFemaleWriting": "die Ukrainerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Ukraine",
      "shortName": "Ukraine",
      "officialName": "Ukraine",
      "genderNeutralWriting": "Ukrainien(ne)",
      "residentsMaleWriting": "l'Ukrainien",
      "residentsFemaleWriting": "l'Ukrainienne",
      "continent": "Europe"
    },
    "IT": {
      "name": "Ucraina",
      "shortName": "Ucraina",
      "officialName": "Ucraina",
      "genderNeutralWriting": "Ucraino/a",
      "residentsMaleWriting": "l'Ucraino",
      "residentsFemaleWriting": "l'Ucraina",
      "continent": "Europa"
    },
    "EN": {
      "name": "Ukraine",
      "shortName": "Ukraine",
      "officialName": "Ukraine",
      "genderNeutralWriting": "Ukrainian",
      "residentsMaleWriting": "Ukrainian man",
      "residentsFemaleWriting": "Ukrainian woman",
      "continent": "Europe"
    }
  },
  "HU": {
    "flag": "🇭🇺",
    "code": "HU",
    "isoCode": "HUN",
    "continent": "Europe",
    "DE": {
      "name": "Ungarn",
      "shortName": "Ungarn",
      "officialName": "Ungarn",
      "genderNeutralWriting": "Ungar:in",
      "residentsMaleWriting": "der Ungar",
      "residentsFemaleWriting": "die Ungarin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Hongrie",
      "shortName": "Hongrie",
      "officialName": "Hongrie",
      "genderNeutralWriting": "Hongrois(e)",
      "residentsMaleWriting": "le Hongrois",
      "residentsFemaleWriting": "la Hongroise",
      "continent": "Europe"
    },
    "IT": {
      "name": "Ungheria",
      "shortName": "Ungheria",
      "officialName": "Ungheria",
      "genderNeutralWriting": "Ungherese",
      "residentsMaleWriting": "l'Ungherese",
      "residentsFemaleWriting": "l'Ungherese",
      "continent": "Europa"
    },
    "EN": {
      "name": "Hungary",
      "shortName": "Hungary",
      "officialName": "Hungary",
      "genderNeutralWriting": "Hungarian",
      "residentsMaleWriting": "Hungarian man",
      "residentsFemaleWriting": "Hungarian woman",
      "continent": "Europe"
    }
  },
  "UY": {
    "flag": "🇺🇾",
    "code": "UY",
    "isoCode": "URY",
    "continent": "America",
    "DE": {
      "name": "Uruguay",
      "shortName": "Uruguay",
      "officialName": "Republik Östlich des Uruguay",
      "genderNeutralWriting": "Uruguayer:in",
      "residentsMaleWriting": "der Uruguayer",
      "residentsFemaleWriting": "die Uruguayerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Uruguay",
      "shortName": "Uruguay",
      "officialName": "République orientale de l'Uruguay",
      "genderNeutralWriting": "Uruguayen(ne)",
      "residentsMaleWriting": "l'Uruguayen",
      "residentsFemaleWriting": "l'Uruguayenne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Uruguay",
      "shortName": "Uruguay",
      "officialName": "Repubblica Orientale dell'Uruguay",
      "genderNeutralWriting": "Uruguaiano/a",
      "residentsMaleWriting": "l'Uruguaiano",
      "residentsFemaleWriting": "l'Uruguaiana",
      "continent": "America"
    },
    "EN": {
      "name": "Uruguay",
      "shortName": "Uruguay",
      "officialName": "Oriental Republic of Uruguay",
      "genderNeutralWriting": "Uruguayan",
      "residentsMaleWriting": "Uruguayan man",
      "residentsFemaleWriting": "Uruguayan woman",
      "continent": "America"
    }
  },
  "UZ": {
    "flag": "🇺🇿",
    "code": "UZ",
    "isoCode": "UZB",
    "continent": "Asia",
    "DE": {
      "name": "Usbekistan",
      "shortName": "Usbekistan",
      "officialName": "Republik Usbekistan",
      "genderNeutralWriting": "Usbek:in",
      "residentsMaleWriting": "der Usbeke",
      "residentsFemaleWriting": "die Usbekin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Ouzbékistan",
      "shortName": "Ouzbékistan",
      "officialName": "République d'Ouzbékistan",
      "genderNeutralWriting": "Ouzbek/Ouzbèke",
      "residentsMaleWriting": "l'Ouzbek",
      "residentsFemaleWriting": "l'Ouzbèke",
      "continent": "Asie"
    },
    "IT": {
      "name": "Uzbekistan",
      "shortName": "Uzbekistan",
      "officialName": "Repubblica dell'Uzbekistan",
      "genderNeutralWriting": "Uzbeko/a",
      "residentsMaleWriting": "l'Uzbeko",
      "residentsFemaleWriting": "l'Uzbeka",
      "continent": "Asia"
    },
    "EN": {
      "name": "Uzbekistan",
      "shortName": "Uzbekistan",
      "officialName": "Republic of Uzbekistan",
      "genderNeutralWriting": "Uzbek",
      "residentsMaleWriting": "Uzbek man",
      "residentsFemaleWriting": "Uzbek woman",
      "continent": "Asia"
    }
  },
  "VU": {
    "flag": "🇻🇺",
    "code": "VU",
    "isoCode": "VUT",
    "continent": "Oceania",
    "DE": {
      "name": "Vanuatu",
      "shortName": "Vanuatu",
      "officialName": "Republik Vanuatu",
      "genderNeutralWriting": "Vanuatuer:in",
      "residentsMaleWriting": "der Vanuatuer",
      "residentsFemaleWriting": "die Vanuatuerin",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Vanuatu",
      "shortName": "Vanuatu",
      "officialName": "République de Vanuatu",
      "genderNeutralWriting": "Vanuatuan(e)",
      "residentsMaleWriting": "le Vanuatuan",
      "residentsFemaleWriting": "la Vanuatuane",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Vanuatu",
      "shortName": "Vanuatu",
      "officialName": "Repubblica di Vanuatu",
      "genderNeutralWriting": "Vanuatuano/a",
      "residentsMaleWriting": "il Vanuatuano",
      "residentsFemaleWriting": "la Vanuatuana",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Vanuatu",
      "shortName": "Vanuatu",
      "officialName": "Republic of Vanuatu",
      "genderNeutralWriting": "Ni-Vanuatu",
      "residentsMaleWriting": "Ni-Vanuatu man",
      "residentsFemaleWriting": "Ni-Vanuatu woman",
      "continent": "Oceania"
    }
  },
  "VA": {
    "flag": "🇻🇦",
    "code": "VA",
    "isoCode": "VAT",
    "continent": "Europe",
    "DE": {
      "name": "Vatikanstadt",
      "shortName": "Vatikanstadt",
      "officialName": "Staat Vatikanstadt",
      "genderNeutralWriting": "Vatikanstadtbewohner:in",
      "continent": "Europa"
    },
    "FR": {
      "name": "Cité du Vatican",
      "shortName": "Cité du Vatican",
      "officialName": "État de la Cité du Vatican",
      "genderNeutralWriting": "Citoyen(ne) du Vatican",
      "continent": "Europe"
    },
    "IT": {
      "name": "Città del Vaticano",
      "shortName": "Città del Vaticano",
      "officialName": "Stato della Città del Vaticano",
      "genderNeutralWriting": "Cittadino/a Vaticano/a",
      "continent": "Europa"
    },
    "EN": {
      "name": "Vatican City",
      "shortName": "Vatican City",
      "officialName": "Vatican City State",
      "genderNeutralWriting": "Vatican citizen",
      "continent": "Europe"
    }
  },
  "VE": {
    "flag": "🇻🇪",
    "code": "VE",
    "isoCode": "VEN",
    "continent": "America",
    "DE": {
      "name": "Venezuela",
      "shortName": "Venezuela",
      "officialName": "Bolivarische Republik Venezuela",
      "genderNeutralWriting": "Venezolaner:in",
      "residentsMaleWriting": "der Venezolaner",
      "residentsFemaleWriting": "die Venezolanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "Venezuela",
      "shortName": "Venezuela",
      "officialName": "République bolivarienne du Venezuela",
      "genderNeutralWriting": "Vénézuélien(ne)",
      "residentsMaleWriting": "le Vénézuélien",
      "residentsFemaleWriting": "la Vénézuélienne",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Venezuela",
      "shortName": "Venezuela",
      "officialName": "Repubblica Bolivariana del Venezuela",
      "genderNeutralWriting": "Venezuelano/a",
      "residentsMaleWriting": "il Venezuelano",
      "residentsFemaleWriting": "la Venezuelana",
      "continent": "America"
    },
    "EN": {
      "name": "Venezuela",
      "shortName": "Venezuela",
      "officialName": "Bolivarian Republic of Venezuela",
      "genderNeutralWriting": "Venezuelan",
      "residentsMaleWriting": "Venezuelan man",
      "residentsFemaleWriting": "Venezuelan woman",
      "continent": "America"
    }
  },
  "AE": {
    "flag": "🇦🇪",
    "code": "AE",
    "isoCode": "ARE",
    "continent": "Asia",
    "DE": {
      "name": "Vereinigte Arabische Emirate",
      "shortName": "Vereinigte Arabische Emirate",
      "officialName": "Vereinigte Arabische Emirate",
      "genderNeutralWriting": "Emirati",
      "residentsMaleWriting": "der Emirati",
      "residentsFemaleWriting": "die Emirati",
      "continent": "Asien"
    },
    "FR": {
      "name": "Émirats arabes unis",
      "shortName": "Émirats arabes unis",
      "officialName": "Émirats arabes unis",
      "genderNeutralWriting": "Émirati(e)",
      "residentsMaleWriting": "l'Émirati",
      "residentsFemaleWriting": "l'Émiratie",
      "continent": "Asie"
    },
    "IT": {
      "name": "Emirati Arabi Uniti",
      "shortName": "Emirati Arabi Uniti",
      "officialName": "Emirati Arabi Uniti",
      "genderNeutralWriting": "Emiratino/a",
      "residentsMaleWriting": "l'Emiratino",
      "residentsFemaleWriting": "l'Emiratina",
      "continent": "Asia"
    },
    "EN": {
      "name": "United Arab Emirates",
      "shortName": "United Arab Emirates",
      "officialName": "United Arab Emirates",
      "genderNeutralWriting": "Emirati",
      "residentsMaleWriting": "Emirati man",
      "residentsFemaleWriting": "Emirati woman",
      "continent": "Asia"
    }
  },
  "US": {
    "flag": "🇺🇸",
    "code": "US",
    "isoCode": "USA",
    "continent": "America",
    "DE": {
      "name": "Vereinigte Staaten",
      "shortName": "Vereinigte Staaten",
      "officialName": "Vereinigte Staaten von Amerika",
      "genderNeutralWriting": "US-Amerikaner:in",
      "residentsMaleWriting": "der US-Amerikaner",
      "residentsFemaleWriting": "die US-Amerikanerin",
      "continent": "Amerika"
    },
    "FR": {
      "name": "États-Unis",
      "shortName": "États-Unis",
      "officialName": "États-Unis d'Amérique",
      "genderNeutralWriting": "Américain(e)",
      "residentsMaleWriting": "l'Américain",
      "residentsFemaleWriting": "l'Américaine",
      "continent": "Amérique"
    },
    "IT": {
      "name": "Stati Uniti",
      "shortName": "Stati Uniti",
      "officialName": "Stati Uniti d'America",
      "genderNeutralWriting": "Statunitense",
      "residentsMaleWriting": "lo Statunitense",
      "residentsFemaleWriting": "la Statunitense",
      "continent": "America"
    },
    "EN": {
      "name": "United States",
      "shortName": "United States",
      "officialName": "United States of America",
      "genderNeutralWriting": "American",
      "residentsMaleWriting": "American man",
      "residentsFemaleWriting": "American woman",
      "continent": "America"
    }
  },
  "GB": {
    "flag": "🇬🇧",
    "code": "GB",
    "isoCode": "GBR",
    "continent": "Europe",
    "DE": {
      "name": "Vereinigtes Königreich",
      "shortName": "Vereinigtes Königreich",
      "officialName": "Vereinigtes Königreich Großbritannien und Nordirland",
      "genderNeutralWriting": "Brite/Britin",
      "residentsMaleWriting": "der Brite",
      "residentsFemaleWriting": "die Britin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Royaume-Uni",
      "shortName": "Royaume-Uni",
      "officialName": "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
      "genderNeutralWriting": "Britannique",
      "residentsMaleWriting": "le Britannique",
      "residentsFemaleWriting": "la Britannique",
      "continent": "Europe"
    },
    "IT": {
      "name": "Regno Unito",
      "shortName": "Regno Unito",
      "officialName": "Regno Unito di Gran Bretagna e Irlanda del Nord",
      "genderNeutralWriting": "Britannico/a",
      "residentsMaleWriting": "il Britannico",
      "residentsFemaleWriting": "la Britannica",
      "continent": "Europa"
    },
    "EN": {
      "name": "United Kingdom",
      "shortName": "United Kingdom",
      "officialName": "United Kingdom of Great Britain and Northern Ireland",
      "genderNeutralWriting": "British",
      "residentsMaleWriting": "British man",
      "residentsFemaleWriting": "British woman",
      "continent": "Europe"
    }
  },
  "VN": {
    "flag": "🇻🇳",
    "code": "VN",
    "isoCode": "VNM",
    "continent": "Asia",
    "DE": {
      "name": "Vietnam",
      "shortName": "Vietnam",
      "officialName": "Sozialistische Republik Vietnam",
      "genderNeutralWriting": "Vietnamese/Vietnamesin",
      "residentsMaleWriting": "der Vietnamese",
      "residentsFemaleWriting": "die Vietnamesin",
      "continent": "Asien"
    },
    "FR": {
      "name": "Viêt Nam",
      "shortName": "Viêt Nam",
      "officialName": "République socialiste du Viêt Nam",
      "genderNeutralWriting": "Vietnamien(ne)",
      "residentsMaleWriting": "le Vietnamien",
      "residentsFemaleWriting": "la Vietnamienne",
      "continent": "Asie"
    },
    "IT": {
      "name": "Vietnam",
      "shortName": "Vietnam",
      "officialName": "Repubblica Socialista del Vietnam",
      "genderNeutralWriting": "Vietnamita",
      "residentsMaleWriting": "il Vietnamita",
      "residentsFemaleWriting": "la Vietnamita",
      "continent": "Asia"
    },
    "EN": {
      "name": "Vietnam",
      "shortName": "Vietnam",
      "officialName": "Socialist Republic of Vietnam",
      "genderNeutralWriting": "Vietnamese",
      "residentsMaleWriting": "Vietnamese man",
      "residentsFemaleWriting": "Vietnamese woman",
      "continent": "Asia"
    }
  },
  "WF": {
    "flag": "🇼🇫",
    "code": "WF",
    "isoCode": "WLF",
    "continent": "Oceania",
    "DE": {
      "name": "Wallis und Futuna",
      "shortName": "Wallis und Futuna",
      "genderNeutralWriting": "Wallis-und-Futuna-Bewohner:in",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Wallis-et-Futuna",
      "shortName": "Wallis-et-Futuna",
      "genderNeutralWriting": "Wallisien(ne)-et-Futunien(ne)",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Wallis e Futuna",
      "shortName": "Wallis e Futuna",
      "genderNeutralWriting": "Abitante di Wallis e Futuna",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Wallis and Futuna",
      "shortName": "Wallis and Futuna",
      "officialName": "Territory of the Wallis and Futuna Islands",
      "genderNeutralWriting": "Wallisian and Futunan",
      "continent": "Oceania"
    }
  },
  "CX": {
    "flag": "🇨🇽",
    "code": "CX",
    "isoCode": "CXR",
    "continent": "Oceania",
    "DE": {
      "name": "Weihnachtsinsel",
      "shortName": "Weihnachtsinsel",
      "genderNeutralWriting": "Weihnachtsinsel-Bewohner:in",
      "continent": "Ozeanien"
    },
    "FR": {
      "name": "Île Christmas",
      "shortName": "Île Christmas",
      "genderNeutralWriting": "Habitant(e) de l'île Christmas",
      "continent": "Océanie"
    },
    "IT": {
      "name": "Isola di Natale",
      "shortName": "Isola di Natale",
      "genderNeutralWriting": "Abitante dell'Isola di Natale",
      "continent": "Oceania"
    },
    "EN": {
      "name": "Christmas Island",
      "shortName": "Christmas Island",
      "officialName": "Territory of Christmas Island",
      "genderNeutralWriting": "Christmas Islander",
      "continent": "Oceania"
    }
  },
  "EH": {
    "flag": "🇪🇭",
    "code": "EH",
    "isoCode": "ESH",
    "continent": "Africa",
    "DE": {
      "name": "Westsahara",
      "shortName": "Westsahara",
      "genderNeutralWriting": "Westsaharaui",
      "continent": "Afrika"
    },
    "FR": {
      "name": "Sahara occidental",
      "shortName": "Sahara occidental",
      "genderNeutralWriting": "Sahraoui(e)",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Sahara Occidentale",
      "shortName": "Sahara Occidentale",
      "genderNeutralWriting": "Saharawi",
      "continent": "Africa"
    },
    "EN": {
      "name": "Western Sahara",
      "shortName": "Western Sahara",
      "officialName": "Sahrawi Arab Democratic Republic",
      "genderNeutralWriting": "Sahrawi",
      "continent": "Africa"
    }
  },
  "CF": {
    "flag": "🇨🇫",
    "code": "CF",
    "isoCode": "CAF",
    "continent": "Africa",
    "DE": {
      "name": "Zentralafrikanische Republik",
      "shortName": "Zentralafrikanische Republik",
      "officialName": "Zentralafrikanische Republik",
      "genderNeutralWriting": "Zentralafrikaner:in",
      "residentsMaleWriting": "der Zentralafrikaner",
      "residentsFemaleWriting": "die Zentralafrikanerin",
      "continent": "Afrika"
    },
    "FR": {
      "name": "République centrafricaine",
      "shortName": "République centrafricaine",
      "officialName": "République centrafricaine",
      "genderNeutralWriting": "Centrafricain(e)",
      "residentsMaleWriting": "le Centrafricain",
      "residentsFemaleWriting": "la Centrafricaine",
      "continent": "Afrique"
    },
    "IT": {
      "name": "Repubblica Centrafricana",
      "shortName": "Repubblica Centrafricana",
      "officialName": "Repubblica Centrafricana",
      "genderNeutralWriting": "Centrafricano/a",
      "residentsMaleWriting": "il Centrafricano",
      "residentsFemaleWriting": "la Centrafricana",
      "continent": "Africa"
    },
    "EN": {
      "name": "Central African Republic",
      "shortName": "Central African Republic",
      "officialName": "Central African Republic",
      "genderNeutralWriting": "Central African",
      "residentsMaleWriting": "Central African man",
      "residentsFemaleWriting": "Central African woman",
      "continent": "Africa"
    }
  },
  "CY": {
    "flag": "🇨🇾",
    "code": "CY",
    "isoCode": "CYP",
    "continent": "Europe",
    "DE": {
      "name": "Zypern",
      "shortName": "Zypern",
      "officialName": "Republik Zypern",
      "genderNeutralWriting": "Zyprer:in",
      "residentsMaleWriting": "der Zyprer",
      "residentsFemaleWriting": "die Zyprerin",
      "continent": "Europa"
    },
    "FR": {
      "name": "Chypre",
      "shortName": "Chypre",
      "officialName": "République de Chypre",
      "genderNeutralWriting": "Chypriote",
      "residentsMaleWriting": "le Chypriote",
      "residentsFemaleWriting": "la Chypriote",
      "continent": "Europe"
    },
    "IT": {
      "name": "Cipro",
      "shortName": "Cipro",
      "officialName": "Repubblica di Cipro",
      "genderNeutralWriting": "Cipriota",
      "residentsMaleWriting": "il Cipriota",
      "residentsFemaleWriting": "la Cipriota",
      "continent": "Europa"
    },
    "EN": {
      "name": "Cyprus",
      "shortName": "Cyprus",
      "officialName": "Republic of Cyprus",
      "genderNeutralWriting": "Cypriot",
      "residentsMaleWriting": "Cypriot man",
      "residentsFemaleWriting": "Cypriot woman",
      "continent": "Europe"
    }
  }
}
