import { Component, inject, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  DatePickerModule,
  InfoBoxModule,
  InputTextModule,
  randomId,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { CountriesService } from '../../../../../core/services/countries.service';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { getResidencePermitOptions } from '../../helpers/getResidencePermitOptions';
import {
  FormFieldComponent,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';
import { ResidencePermitEnum } from '../../../../../graphql/generated';

@Component({
  standalone: true,
  selector: 'app-talent-nationality',
  templateUrl: 'talent-nationality.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    UjSelectInputModule,
    DatePickerModule,
    SimpleSelectComponent,
    InputTextModule,
    InfoBoxModule,
    FormFieldComponent,
    TextInputComponent,
  ],
})
export class TalentNationalityComponent implements OnInit {
  formService = inject(TalentFormEditService);
  personalDataForm = this.formService.personalData;
  countriesService = inject(CountriesService);
  nationalityOptions = this.countriesService.getNationalities();
  residencePermitOptions = getResidencePermitOptions();
  residencePermitControl = this.personalDataForm.controls.residencePermit;
  civilOriginControl = this.personalDataForm.controls.civilOrigin;

  nationalityControl = this.personalDataForm.controls.nationality;
  residencePermitValidUntilControl =
    this.personalDataForm.controls.residencePermitValidUntil;

  protected readonly ResidencePermitEnum = ResidencePermitEnum;
  componentId = randomId();

  ngOnInit() {
    this.enableOrDisableResidentValidUntil(this.residencePermitControl.value);
    this.residencePermitControl.valueChanges.subscribe((value) => {
      this.enableOrDisableResidentValidUntil(value);
      this.unsetResidencePermitValidUntil(value);
    });
  }

  unsetResidencePermitValidUntil(value: ResidencePermitEnum | null) {
    if (
      (this.residencePermitValidUntilControl.value !== null &&
        value === ResidencePermitEnum.NONE) ||
      value === ResidencePermitEnum.REPORTING_PROCEDURE
    ) {
      this.residencePermitValidUntilControl.setValue([]);
      this.residencePermitValidUntilControl.markAsDirty(); // inorder to trigger save
    }
  }

  enableOrDisableResidentValidUntil(value: ResidencePermitEnum | null) {
    if (
      value === ResidencePermitEnum.NONE ||
      value === ResidencePermitEnum.REPORTING_PROCEDURE ||
      value === ResidencePermitEnum.C
    ) {
      this.residencePermitValidUntilControl.disable();
    } else {
      this.residencePermitValidUntilControl.enable();
    }
  }
}
