<div
  class="file-preview"
  (scroll)="onScrollPages($event)">
  <div class="header">
    <div class="header-content">
      <div class="header-left">
        <span
          color="warning-1"
          ui-library-tag
          fontWeight="bold"
          >{{ "preview" | i18next }}</span
        >
        @if (mode() === "inactive") {
          <span
            color="grey"
            ui-library-tag
            fontWeight="bold"
            >{{ "inactive" | i18next }}</span
          >
        }
      </div>
      <div class="header-center">
        <div
          class="file-preview-controls"
          *ngIf="false">
          <button
            ui-library-button-solid
            positionInGroup="start"
            corner="round"
            iconOnly="zoom_minus"
            color="secondary-dark"></button>
          <button
            ui-library-button-solid
            positionInGroup="center"
            corner="round"
            iconOnly="full_page"
            color="secondary-dark"></button>
          <button
            ui-library-button-solid
            positionInGroup="end"
            corner="round"
            iconOnly="zoom_plus"
            color="secondary-dark"></button>
        </div>
      </div>
      <div class="header-right"></div>
    </div>
  </div>
  <div class="pages">
    <div
      *ngFor="let page of shownPages(); trackBy: trackByPage"
      [id]="page.uuid"
      class="page">
      <div class="document-preview__group">
        <div class="document-preview__document">
          <pdf-viewer
            *ngIf="loadedPdfs().has(page.uuid)"
            [original-size]="false"
            [fit-to-page]="true"
            [show-all]="false"
            [src]="getDocumentDownloadSrc(page)"
            [render-text]="true"
            [zoom-scale]="'page-width'"
            [autoresize]="true"
            class="pdf-viewer"></pdf-viewer>
          <img
            #lazyImage
            [src]="getDocumentPreviewSrc(page)"
            [attr.data-page-uuid]="page.uuid"
            loading="lazy" />
          <button
            *ngIf="!loadedPdfs().has(page.uuid)"
            size="S"
            class="pdf-loader-button"
            ui-library-button-solid
            color="secondary-dark"
            iconLeft="file_document_eye_preview"
            (click)="loadPdf(page)">
            {{ "loadPdf" | i18next }}
          </button>
          <app-loading-spinner position="static"></app-loading-spinner>
          <div
            *ngIf="page.favorite"
            class="document-preview__favorite">
            <ui-library-icon
              icon="star_filled"
              size="16"
              color="warning-1"></ui-library-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
