import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../models/shared/user/user.service';
import { rxFilterIsNotEmpty } from '../../shared/helpers/functions/rxFilterIsNotEmpty';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  private registrationPromise: Promise<ServiceWorkerRegistration | undefined>;
  private tokenSubject = new BehaviorSubject<string | null>(null);
  private tokenQueue: string[] = [];
  private serviceWorkerReady = false;

  constructor(private userService: UserService) {
    this.registrationPromise = this.registerServiceWorker();
    this.registrationPromise.then((registration) => {
      registration?.active?.postMessage({
        type: 'SET_MEDIA_URL',
        mediaUrl: environment.mediaUrl,
      });
    });
    this.setupTokenManagement();
    this.waitForServiceWorkerController();
  }

  private async registerServiceWorker(): Promise<
    ServiceWorkerRegistration | undefined
  > {
    if ('serviceWorker' in navigator) {
      try {
        const registration =
          await navigator.serviceWorker.register('/service-worker.js');

        // This will reload the page if the service controller has no control after registration.
        // Special behavior when hard-refreshing.
        // https://stackoverflow.com/questions/51597231/register-service-worker-after-hard-refresh
        navigator.serviceWorker.getRegistration().then(function (reg) {
          if (reg && reg.active && !navigator.serviceWorker.controller) {
            window.location.reload();
          }
        });
        return registration;
      } catch (error) {
        console.error('Service Worker registration failed:', error);
        return undefined;
      }
    }
    return undefined;
  }

  private setupTokenManagement() {
    // trigger first token retrieval
    this.userService.getAuthToken().then();
    this.userService.currentToken$
      .pipe(distinctUntilChanged(), rxFilterIsNotEmpty())
      .subscribe((token) => {
        this.tokenSubject.next(token);
      });

    this.tokenSubject.pipe(distinctUntilChanged()).subscribe((token) => {
      // console.log('Token changed');
      if (token) {
        this.queueOrSendToken(token);
      }
    });
  }

  private async waitForServiceWorkerController() {
    if (navigator.serviceWorker.controller) {
      this.serviceWorkerReady = true;
      this.processTokenQueue();
    } else {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (navigator.serviceWorker.controller) {
          this.serviceWorkerReady = true;
          this.processTokenQueue();
        }
      });
    }
  }

  private queueOrSendToken(token: string) {
    if (this.serviceWorkerReady && navigator.serviceWorker.controller) {
      this.sendAuthTokenToServiceWorker(token);
    } else {
      this.tokenQueue.push(token);
    }
  }

  private processTokenQueue() {
    if (navigator.serviceWorker.controller) {
      while (this.tokenQueue.length > 0) {
        const token = this.tokenQueue.shift();
        if (token) {
          this.sendAuthTokenToServiceWorker(token);
        }
      }
    } else {
      console.error(
        'No active service worker controller found. [processTokenQueue]',
      );
    }
  }

  private sendAuthTokenToServiceWorker(token: string) {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'SET_AUTH_TOKEN',
        token: token,
      });
      // console.log('Sent token to service worker:', token);
    } else {
      console.error('No active service worker controller found.');
    }
  }

  get registration(): Promise<ServiceWorkerRegistration | undefined> {
    return this.registrationPromise;
  }
}
