import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  DatePickerModule,
  InfoBoxModule,
  InputTextModule,
  randomId,
  ResponsiveService,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { SimpleSelectComponent } from '@intemp/unijob-ui2';
import { TalentEmailsEntryComponent } from './talent-emails-entry/talent-emails-entry.component';
import {
  ArrayActionEnum,
  ContactEmailTypeEnum,
} from '../../../../../graphql/generated';
import { TalentPhonesEntryComponent } from '../talent-phones/talent-phones-entry/talent-phones-entry.component';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { EmailFormGroup } from '../../talent-sheet.types';

@Component({
  standalone: true,
  selector: 'app-talent-emails',
  templateUrl: 'talent-emails.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    UjSelectInputModule,
    DatePickerModule,
    SimpleSelectComponent,
    InputTextModule,
    InfoBoxModule,
    TalentEmailsEntryComponent,
    TalentPhonesEntryComponent,
  ],
})
export class TalentEmailsComponent {
  formService = inject(TalentFormEditService);
  responsiveService = inject(ResponsiveService);
  isSmDown$ = this.responsiveService.isSmDown$;

  newEmailEntry = new FormGroup<EmailFormGroup>({
    uuid: new FormControl<string | null>(randomId()),
    type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.ADDED),
    email: new FormControl<string | null>(null),
    isPrimary: new FormControl<boolean | null>(false),
    emailType: new FormControl<ContactEmailTypeEnum | null>(
      ContactEmailTypeEnum.PRIVATE,
    ),
  });

  trackByUUID(index: number, item?: FormGroup<EmailFormGroup>) {
    return item?.value?.uuid;
  }
}
