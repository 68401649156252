// Compare export functions
import {
  TalentAddressInput,
  TalentChildrenInput,
  TalentEmailsInput,
  TalentFileInput,
  TalentFragment,
  TalentLinksInput,
  TalentPageInput,
  TalentPhonesInput,
  TalentRecommendedByInput,
  TalentSourcesInput,
} from '../../../../graphql/generated';

export function childIsUnchanged(
  item: TalentFragment['children'][number],
  revertItem: TalentChildrenInput,
) {
  return (
    item.firstName === revertItem.firstName &&
    item.lastName === revertItem.lastName &&
    item.yearOfBirth === revertItem.yearOfBirth &&
    item.hasChildAllowance === revertItem.hasChildAllowance
  );
}

export function addressIsUnchanged(
  item: TalentFragment['addresses'][number],
  revertItem: TalentAddressInput,
) {
  return (
    item.streetAndNumber === revertItem.streetAndNumber &&
    item.zip === revertItem.zip &&
    item.location === revertItem.location &&
    item.country === revertItem.country
  );
}

export function phoneIsUnchanged(
  item: TalentFragment['phones'][number],
  revertItem: TalentPhonesInput,
) {
  return (
    item.phone === revertItem.phone &&
    item.phoneType === revertItem.phoneType &&
    item.isPrimary === revertItem.isPrimary
  );
}

export function emailIsUnchanged(
  item: TalentFragment['emails'][number],
  revertItem: TalentEmailsInput,
) {
  return (
    item.email === revertItem.email &&
    item.emailType === revertItem.emailType &&
    item.isPrimary === revertItem.isPrimary
  );
}

export function linkIsUnchanged(
  item: TalentFragment['links'][number],
  revertItem: TalentLinksInput,
) {
  return item.url === revertItem.url;
}

export function sourceIsUnchanged(
  item: TalentFragment['sources'][number],
  revertItem: TalentSourcesInput,
) {
  return (
    item.platform === revertItem.platform &&
    item.contactAt === revertItem.contactAt &&
    item.sourceType === revertItem.sourceType
  );
}

export function recommendedByIsUnchanged(
  item: TalentFragment['recommendedBy'][number],
  revertItem: TalentRecommendedByInput,
) {
  return (
    item.firstName === revertItem.firstName &&
    item.lastName === revertItem.lastName &&
    item.email === revertItem.email
  );
}

export function fileIsUnchanged(
  item: TalentFragment['files'][number],
  revertItem: TalentFileInput,
) {
  return item.title === revertItem.title;
}

export function pageIsUnchanged(
  item: TalentFragment['pages'][number],
  revertItem: TalentPageInput,
) {
  return (
    item.title === revertItem.title &&
    item.favorite === revertItem.favorite &&
    item.active === revertItem.active
  );
}
