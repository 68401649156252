import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { IconModule } from '@intemp/unijob-ui';

@Component({
  standalone: true,
  selector: 'app-talent-data-privacy-confirm-page',
  templateUrl: './talent-data-privacy-confirm-page.component.html',
  imports: [NgIf, IconModule, I18NextModule],
})
export class TalentDataPrivacyConfirmPageComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  i18next = inject(I18NextPipe);
  message = signal<'isLoading' | 'privacyPolicyAccepted' | string>('isLoading');
  hasError = signal(false);

  ngOnInit() {
    this.confirmDataPrivacy();
  }

  async confirmDataPrivacy() {
    const uuid = this.route.snapshot.params.uuid;
    const token = this.route.snapshot.params.token;

    try {
      const res = await fetch(
        `${environment.restApiUrl}/talent/${uuid}/${token}`,
      ).then((res) => res.json());

      if (res.status === 200) {
        this.message.set('privacyPolicyAccepted');
      }
      if (res.status === 400) {
        this.message.set(res.error);
        this.hasError.set(true);
      }
    } catch (e: any) {
      this.message.set(e.message);
      console.error(e);
    }
  }
}
