<label class="uj-block uj-mb-b text-m-b">
  {{ "responsibleEmployee" | i18next | firstUppercase
  }}<span class="red-text">*</span>
</label>
<ng-container *ngrxLet="allConsultants$ as allConsultants">
  <app-consultant-select
    #consultantSelect
    [formControl]="consultantIdControl"
    [selectedBranchId]="branchIdControl.value || undefined"
    [allConsultants]="allConsultants"
    class="uj-mb-e"
    [attr.data-cy]="
      'responsible-user-select-' + (consultantIdControl.value || '')
    ">
  </app-consultant-select>
</ng-container>
<div
  class="uj-mb-e"
  *ngIf="responsibleUserInfoMessage()"
  data-cy="responsible-user-info-message">
  <unijob-info-box
    title=""
    message="{{ responsibleUserInfoMessage() }}"
    type="INFO"
    [closable]="false"></unijob-info-box>
</div>
<label class="uj-block uj-mb-b text-m-b">
  {{ "branch" | i18next | firstUppercase }}<span class="red-text">*</span>
</label>
<ui-library-simple-select
  size="M"
  design="outline"
  backgroundColor="white"
  [placeholderText]="'selectBranch' | i18next"
  [options]="(branchOptions$ | async) || []"
  [formControl]="branchIdControl"
  [attr.data-cy]="'branch-select-' + (branchIdControl.value || '')" />
