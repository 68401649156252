export function stringContainsSearch(
  sourceString: string,
  searchString: string,
): boolean {
  sourceString = sourceString.toLowerCase();
  searchString = searchString.toLowerCase();
  sourceString = sourceString.replace(new RegExp(' ', 'g'), '');
  searchString = searchString.replace(new RegExp(' ', 'g'), '');
  return sourceString.indexOf(searchString) !== -1;
}
