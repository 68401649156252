import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { LanguageService } from '../../core/services/language.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: class ReuseStrategy {
        // without this, the user language is not loaded correctly
        shouldReuseRoute() {
          return false;
        }
      },
    },
  ],
})
export class LanguageSwitchComponent implements OnInit {
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    const isoCode = this.route.snapshot.queryParams[`language`];
    const redirect = this.route.snapshot.queryParams[`redirect`];
    const refreshNav = this.route.snapshot.queryParams[`refreshNav`];

    this.setLanguageAndRedirect(isoCode, redirect, refreshNav);
  }

  async setLanguageAndRedirect(
    isoCode: string,
    redirect: string,
    refreshNav: boolean,
  ) {
    if (isoCode) {
      await this.languageService.applyLanguage(isoCode);
    }

    if (redirect && !refreshNav) {
      this.router.navigated = false;
      // this.router.navigate(['language'], {
      //   queryParams: {
      //     redirect,
      //     refreshNav: true,
      //   },
      // });
      // This is just a hack please change this!
      window.location.href = redirect;
    }
    if (redirect && refreshNav) {
      this.router.navigated = false;
      this.router.navigateByUrl(redirect);
    }
  }
}
