<div class="uj-flex uj-flex-col uj-gap-y-c">
  <div
    *ngFor="let config of configs"
    [class.uj-bg-surface-grape]="isDisabled()"
    class="uj-flex uj-flex-col uj-p-d uj-mb-d uj-border uj-rounded uj-border-border">
    <div
      class="uj-flex uj-flex-row uj-items-center uj-justify-between uj-gap-b uj-mb-b">
      <div class="uj-text-base uj-font-bold">
        {{ title(config) }}
      </div>
      <div
        *ngIf="switchVisible(config)"
        class="uj-flex uj-flex-row uj-items-center">
        <unijob-switch
          [ngModel]="switchState"
          disabled
          round></unijob-switch>
      </div>
    </div>
    <div
      *ngIf="configs?.length === 0"
      class="uj-text-text-s/[16px]">
      <ng-container>
        <div
          *ngIf="showConfigLights()"
          class="uj-inline-block uj-pr-a">
          <span
            [class.color-grey]="true"
            class="uj-inline-block unijob-dot color-green"></span>
        </div>
      </ng-container>
      {{ neverConfiguredWording() }}
    </div>
    <div
      class="uj-flex uj-flex-row uj-items-center uj-gap-b uj-mb-b uj-text-text-s/[16px]"
      *ngFor="let option of config.exportSettings?.universaljobSearch?.options">
      <ui-library-icon
        [icon]="getFromOptionInfoMap('icon', option)"
        size="12"></ui-library-icon>
      <span class="uj-font-bold"
        >{{ getFromOptionInfoMap("label", option) }}
        {{ "Option" | i18next }}</span
      >
    </div>
    <div class="uj-text-text-s/[16px]">
      <ng-container>
        <div
          *ngIf="showConfigLights()"
          class="uj-inline-block uj-pr-a">
          <span
            [class.color-green]="light(config) === 'green'"
            [class.color-yellow]="light(config) === 'yellow'"
            [class.color-grey]="light(config) === 'grey'"
            class="uj-inline-block unijob-dot color-green"></span>
        </div>
      </ng-container>
      {{ stateWording(config) }}
    </div>

    <div
      *ngIf="
        config.exportSettings?.universaljobSearch?.enabled &&
        config.status === ConfigStatus.REQUESTED &&
        userCanAcceptSearchChannelRequests
      ">
      <div class="uj-flex uj-w-full uj-mt-c uj-mb-c">
        <unijob-date-picker
          [dayDisabledFunctions]="[dayIsDisabled]"
          [ngModel]="startDate | async"
          (ngModelChange)="startDate.next($event)"
          class="uj-flex-grow !uj-rounded-r-none"
          label="Datum"
          fontWeight="font-normal"
          [hasLastDay]="true"
          mode="single"
          language="de"
          [placeholder]="'startDate' | i18next"
          errorMessage=""
          [showInvalidState]="true"
          [showValidState]="false"></unijob-date-picker>

        <ng-container
          *ngIf="
            startDate | async as startDateValue;
            then activeAcceptButton;
            else disabledAcceptButton
          ">
        </ng-container>

        <ng-template #activeAcceptButton>
          <button
            ui-library-button-outline
            type="button"
            color="dark"
            size="S"
            iconLeft="check"
            positionInGroup="start"
            (click)="acceptRequest(config)">
            <span class="display-text">{{
              "addonAcceptRequest" | i18next
            }}</span>
          </button>
        </ng-template>
        <ng-template #disabledAcceptButton>
          <button
            ui-library-button-outline
            disabled
            type="button"
            color="dark"
            size="S"
            iconLeft="check"
            positionInGroup="end">
            <span class="display-text">{{
              "addonAcceptRequest" | i18next
            }}</span>
          </button>
        </ng-template>
      </div>
    </div>
    <div
      class="uj-flex uj-flex-row uj-items-baseline uj-justify-end uj-gap-b uj-mt-b">
      <div class="font-normal uj-text-text-s/[16px]">
        {{ "totalPriceExclTax" | i18next }}
      </div>
      <div class="uj-text-text-m/[20px] uj-font-bold">
        {{ price(config) | chfCurrency }}
      </div>
    </div>
  </div>
  <div *ngIf="vacancy.status === vacancyStatusEnum.DRAFT && !channel.draftable">
    <div class="uj-mb-e uj-rounded-b uj-overflow-hidden">
      <unijob-info-box
        title=""
        message="{{ 'onlyAfterPublishing' | i18next }}"
        type="INFO"
        [closable]="false"></unijob-info-box>
    </div>
  </div>
  <div
    *ngIf="
      recentConfig() &&
      (recentConfig()?.status === configStatusEnum.RUNNING ||
        recentConfig()?.status === configStatusEnum.REQUESTED)
    ">
    <div class="uj-mb-e uj-rounded-b uj-overflow-hidden">
      <unijob-info-box
        title=""
        message="{{ 'cannotBeCombined' | i18next }}"
        type="INFO"
        [closable]="false"></unijob-info-box>
    </div>
  </div>
  <div>
    <!-- universaljobSearch -->
    <div
      *ngIf="channel.exportSettings.universaljobSearch?.enabled"
      class="uj-flex uj-flex-col uj-gap-c sm:uj-flex-row">
      <button
        *ngIf="
          ![vacancyStatusEnum.ARCHIVED, vacancyStatusEnum.DELETED].includes(
            vacancy.status
          )
        "
        [disabled]="
          !!(
            recentConfig() &&
            [
              configStatusEnum.RUNNING,
              configStatusEnum.PENDING,
              configStatusEnum.REQUESTED,
            ].includes(recentConfig()?.status ?? configStatusEnum.PENDING)
          ) || !userMayEditAddons
        "
        ui-library-button-solid
        type="button"
        size="S"
        iconLeft="plus"
        color="primary"
        positionInGroup="default"
        [tooltip]="addAddonTooltip"
        (click)="searchAddonSheet.openSheet()">
        <span class="display-text">{{ "addAddon" | i18next }}</span>
      </button>
      <!-- [disabled]="recentConfig()?.status === configStatusEnum.RUNNING" -->
      <button
        *ngIf="
          recentConfig() &&
          ![vacancyStatusEnum.ARCHIVED, vacancyStatusEnum.DELETED].includes(
            vacancy.status
          )
        "
        [tooltip]="extendAddonTooltip"
        [disabled]="
          !userMayEditAddons ||
          recentConfig()?.status === configStatusEnum.REQUESTED
        "
        ui-library-button-solid
        type="button"
        size="S"
        corner="rounded"
        iconLeft="clock_plus"
        color="primary"
        positionInGroup="default"
        (click)="searchAddonSheetExtension.openSheet()">
        <span class="display-text">{{ "extendSearchAddon" | i18next }}</span>
      </button>
    </div>
  </div>
</div>
<app-vacancy-promote-search-addon-sheet
  #searchAddonSheet
  [vacancyId]="this.vacancy.uuid">
</app-vacancy-promote-search-addon-sheet>
<app-vacancy-promote-search-addon-sheet
  #searchAddonSheetExtension
  [recentConfig]="recentConfig()"
  [vacancyId]="this.vacancy.uuid">
</app-vacancy-promote-search-addon-sheet>
