{
  "absencesEnum": {
    "ACCIDENT": "Accident",
    "BIRTH_OR_WEDDING_OF_CHILD": "Birth or Wedding of a Child",
    "CARING_FOR_SICK_CHILD": "Caring for a Sick Child",
    "DEATH_IN_HOUSEHOLD": "Death in Household",
    "DEATH_OF_CLOSE_RELATIVE": "Death of a Close Relative",
    "FULFILLMENT_OF_LEGAL_OBLIGATIONS": "Fulfillment of Legal Obligations",
    "MATERNITY_LEAVE": "Maternity Leave",
    "MILITARY_OR_CIVIL_SERVICE": "Military/Civil Service/Civil Protection",
    "MILITARY_RECRUITMENT_OR_INSPECTION": "Military Recruitment/Inspection",
    "RESIDENCE_MOVING": "Moving Residence",
    "SICKNESS": "Sickness",
    "VACATION_PAID": "Paid Vacation",
    "VACATION_UNPAID": "Unpaid Vacation",
    "WEDDING": "Wedding"
  },
  "dayStatusEnum": {
    "APPROVED": "Approved",
    "HAS_REPORT": "Hours submitted as document",
    "OPEN": "Open",
    "TO_BE_APPROVED": "Submitted"
  },
  "documentTypesEnum": {
    "AHV": "Social Security Card",
    "CORRESPONDENCE": "Correspondence",
    "CRIMINAL_RECORD": "Criminal Record Extract",
    "CV": "Resume",
    "DEPT_ENFORCEMENT_REGISTER_REPORT": "Enforcement Register Extract",
    "EMPLOYER_STATEMENT": "Employer Statement",
    "FAMILY_ALLOWANCES": "Family Allowance Documents",
    "FOREIGNER_PERMIT": "Foreigner Permit",
    "HOURS_REPORT": "Hours Report",
    "INVOICE": "Invoice",
    "MEDICAL_CERTIFICATE": "Medical Certificate",
    "OTHER": "Other",
    "PAY_SLIP": "Pay Slip",
    "RECEIPT": "Receipt",
    "WAGE_STATEMENT": "Wage Statement",
    "WORK_PERMIT": "Work Permit"
  },
  "DriversLicenseEnum": {
    "A": "A",
    "A1": "A1",
    "B": "B",
    "B1": "B1",
    "BE": "BE",
    "BPT": "BPT",
    "C": "C",
    "C1": "C1",
    "C1E": "C1E",
    "CE": "CE",
    "CZV": "CZV",
    "D": "D",
    "D1": "D1",
    "D1E": "D1E",
    "DE": "DE",
    "F": "F",
    "G": "G",
    "M": "M",
    "NEGATIVE_A": "-A"
  },
  "EmploymentTypeEnum": {
    "PERMANENT": "Permanent",
    "TEMPORARY": "Temporary"
  },
  "expensesEnum": {
    "ACCOMMODATION": "Accommodation",
    "MEAL": "Meal",
    "OTHER": "Other",
    "RIDE_COST": "Transport"
  },
  "LanguageProficiencyEnum": {
    "A1": "A1 - Beginner",
    "A2": "A2 - Basic knowledge",
    "B1": "B1 - Intermediate / Good",
    "B2": "B2 - Very good",
    "C1": "C1 - Fluent",
    "C2": "C2 - Native level",
    "Advantageous": "Advantageous"
  },
  "ChannelExportUniversaljobSearchPlanEnum": {
    "PUSH": "Push",
    "TARGET": "Target",
    "MAX": "Max"
  },
  "ResidencePermitEnum": {
    "NONE": "None",
    "REPORTING_PROCEDURE": "Reporting procedure",
    "B": "B - residence permit",
    "C": "C - settlement permit",
    "Ci": "Ci - residence permit with gainful employment",
    "F": "F - for temporarily admitted foreigners",
    "G": "G - cross-border commuter permit",
    "GMB": "GMB - Cross-border commuter registration confirmation FL",
    "L": "L - short-stay permit",
    "N": "N - for asylum seekers",
    "S": "S - provisional status for persons in need of protection"
  },
  "sexEnum": {
    "FEMALE": "Female",
    "MALE": "Male",
    "OTHER": "Other"
  },
  "Salutation": {
    "FEMALE": "Dear",
    "MALE": "Dear",
    "OTHER": "Hi"
  },
  "supportedLanguageEnum": {
    "de": "German",
    "en": "English",
    "fr": "French",
    "it": "Italian"
  },
  "VacancyArchiveReasonEnum": {
    "COMPANY_FOUND_PLACEMENT": "Filled by the client themselves",
    "COMPANY_RECALLED": "No longer needed by the client",
    "ENOUGH_APPLICANTS_RECEIVED": "Enough applicants received",
    "FALSE_ACTIVATION_OR_DUPLICATE": "Incorrectly activated / Duplicate",
    "OTHER_FOUND_PLACEMENT": "Filled by another recruitment agency",
    "WE_FOUND_PLACEMENT": "Successfully filled by us",
    "OTHER": "Other"
  },
  "VacancyEmploymentTypeEnum": {
    "APPRENTICESHIP": "Apprenticeship",
    "FREELANCE": "Self-employed",
    "FULL_TIME": "Full-time",
    "INTERNSHIP": "Internship",
    "PART_TIME": "Part-time",
    "PER_DAY": "By the day",
    "PERMANENT": "Permanent position",
    "TEMPORARY": "Temporary position",
    "VOLUNTEER": "Voluntary"
  },
  "VacancyPositionTypeEnum": {
    "EMPLOYEE": "Employee",
    "LEAD": "Leadership position",
    "OFFICER": "Specialist responsibility"
  },
  "vacancyStatusEnum": {
    "ARCHIVED": "Archived",
    "DELETED": "Deleted",
    "DRAFT": "Draft",
    "LIVE": "Live",
    "SCHEDULED": "Scheduled"
  },
  "WorkArrangementEnum": {
    "FIELD_SERVICE": "Field service",
    "ON_CALL_DUTY": "On-call duty",
    "ON_SITE": "On-site",
    "POOL": "Pool",
    "REMOTE": "Home office",
    "SHIFT_WORK": "Shift work"
  },
  "WorkAvailabilityEnum": {
    "BLACKLISTED": "Blacklisted",
    "IN_EMPLOYMENT": "In employment",
    "NOT_OPEN_TO_WORK": "Not open to work / Passive",
    "OPEN_TO_WORK": "Open to work",
    "PLACED": "Placed"
  },
  "EmploymentTermEnum": {
    "FIXED_TERM": "limited",
    "FULL_TERM": "open-ended"
  },
  "ReligiousDenominationEnum": {
    "BUDDHIST": "Buddhist",
    "EVANGELICAL_REFORMED": "Evangelical Reformed",
    "HINDU": "Hindu",
    "JEWISH": "Jewish",
    "MUSLIM": "Muslim",
    "NON_RELIGIOUS": "Non-religious",
    "ROMAN_CATHOLIC": "Roman Catholic"
  },
  "MaritalStatusEnum": {
    "DIVORCED": "Divorced",
    "MARRIED": "Married",
    "SINGLE": "Single",
    "WIDOWED": "Widowed",
    "IN_REGISTERED_PARTNERSHIP": "In registered partnership",
    "DISSOLVED_PARTNERSHIP": "Dissolved partnership"
  },
  "ContactPhoneTypeEnum": {
    "PRIVATE": "Private",
    "BUSINESS": "Business"
  },
  "ContactEmailTypeEnum": {
    "PRIVATE": "Private",
    "BUSINESS": "Business"
  },
  "TalentSourceTypeEnum": {
    "WALK_IN": "Walk-in",
    "EMAIL": "Email",
    "PHONE": "Phone",
    "SOCIAL_MEDIA": "Social Media",
    "JOB_APPLICATION": "Job Application"
  },
  "TalentSourcePlatformEnum": {
    "UNIVERSALJOB": "universalJob.ch",
    "JOBS_CH": "Jobs.ch",
    "LINKED_IN": "LinkedIn",
    "JOB_SHOT": "JobShot"
  },
  "MatchProcessStepEnum": {
    "ENTRY": "Entry",
    "PREPARATION": "Preparation",
    "RECOMMENDATION": "Recommendation",
    "EXAMINATION": "Examination",
    "CONCLUSION": "Conclusion"
  },
  "MatchStatusEnum": {
    "NEW": "Unread",
    "ACTIVE": "Active",
    "ARCHIVED": "Archived"
  },
  "StaffRoleEnum": {
    "BRANCH_LEADER": "Branch Leader",
    "REGIONAL_LEADER": "Regional Leader",
    "ASSISTANT": "Assistant",
    "CONSULTANT": "Consultant",
    "MARKETING": "Marketing",
    "ADMIN": "Admin"
  }
}
