<div>
  <div class="uj-flex uj-items-center uj-justify-between">
    <div
      class="uj-flex uj-flex-2 uj-w-0 uj-flex-1 uj-min-h-[36px] uj-items-center">
      <h2 class="uj-truncate title-m">
        {{
          (talent().firstName
            ? talent().firstName
            : ("firstName" | i18next | firstUppercase)) +
            " " +
            (talent().lastName
              ? talent().lastName
              : ("lastName" | i18next | firstUppercase))
        }}
      </h2>
    </div>
    <div
      class="uj-items-center uj-justify-end uj-hidden uj-ml-d md:uj-flex"
      *ngIf="userService.readyUser$ | async">
      <div>
        <app-current-user-avatar />
      </div>
    </div>
  </div>
  <div class="uj-flex uj-mt-b uj-center md:uj-justify-between">
    <div class="uj-flex uj-items-center">
      @if (inactivePages().length > 0) {
        <div class="uj-flex uj-items-baseline uj-gap-b">
          <span
            ui-library-dot
            color="accent"
            flashing></span>
          <span class="uj-text-text-xs">
            {{ "newDocuments" | i18next }}
          </span>
        </div>
        <span class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
      }
      <app-talent-status-indicator
        [isPositionedInHeader]="true"
        class="uj-mr-b" />
      <ng-container *ngTemplateOutlet="WorkAvailability"></ng-container>
      <ng-container *ngIf="talent().talentNumber as talentNumber">
        <span class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
        <span class="text-s">{{ talentNumber }} </span>
      </ng-container>
    </div>
    <div
      *ngIf="talent().updatedAt"
      class="uj-flex uj-flex-row-reverse uj-items-center uj-ml-c md:uj-flex-row">
      <div class="uj-flex uj-flex-row-reverse uj-items-center md:uj-flex-row">
        <span class="uj-hidden text-s sm:uj-block">
          {{ lastUpdatedAt().lastEditTime }}
        </span>
        <span class="uj-block text-s sm:uj-hidden">
          {{ lastUpdatedAt().lastEditTimeMobile }}
        </span>
        <i
          ui-library-icon-tag
          icon="check"
          class="uj-mr-[6px] md:uj-mr-0 md:uj-ml-[6px]"
          color="positive"></i>
        <span class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
        <button
          (click)="openChangelogSheet()"
          ui-library-button-link
          size="S"
          color="dark"
          fontWeight="bold">
          {{ "history" | i18next | firstUppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="uj-z-10 uj-w-full uj-pt-e uj-bg-surface-main">
  <div
    scrollOverflowGradient
    class="uj-tab-group">
    <unijob-tab
      [name]="'profile' | i18next | firstUppercase"
      [active]="activeTab === tabEnum.PROFILE"
      (tabSelected)="switchActiveTabTo(tabEnum.PROFILE)"></unijob-tab>
    <unijob-tab
      [name]="'documents' | i18next | firstUppercase"
      [active]="activeTab === tabEnum.DOCUMENTS"
      [highlight]="inactivePages().length > 0"
      (tabSelected)="switchActiveTabTo(tabEnum.DOCUMENTS)"></unijob-tab>
  </div>
</div>

<ng-template #WorkAvailability>
  <div
    *ngIf="talent().workAvailability as workAvailability"
    class="uj-flex uj-gap-b uj-items-center">
    <span
      ui-library-dot
      size="S"
      corner="round"
      [color]="
        workAvailability === WorkAvailabilityEnum.OPEN_TO_WORK
          ? 'positive'
          : workAvailability === WorkAvailabilityEnum.PLACED
            ? 'grey'
            : workAvailability === WorkAvailabilityEnum.BLACKLISTED
              ? 'dark'
              : workAvailability === WorkAvailabilityEnum.IN_EMPLOYMENT
                ? 'warning-1'
                : 'grey'
      ">
    </span>
    <span class="uj-text-text text-s-b">{{
      "WorkAvailabilityEnum." + talent()?.workAvailability | i18next
    }}</span>
  </div>
</ng-template>
