<div class="uj-flex uj-flex-col uj-gap-f">
  <app-talent-sources></app-talent-sources>

  <ng-container *ngIf="!environment.hideBetaTalentFields">
    <app-talent-recommendations></app-talent-recommendations>
    <div>
      <label class="uj-block text-m-b uj-relative uj-mb-b">{{
        "status" | i18next | firstUppercase
      }}</label>

      <app-talent-status-indicator></app-talent-status-indicator>
    </div>
  </ng-container>
  <div *ngIf="formService.talent() as talent">
    <label class="uj-block text-m-b uj-relative uj-mb-b">{{
      "talentNumber" | i18next | firstUppercase
    }}</label>
    <div class="uj-flex uj-gap-c rounded-sm">
      <div class="uj-py-c uj-px-d uj-bg-surface-grape uj-flex-1 uj-text-text">
        <span class="uj-text-text">{{ talent.talentNumber }}</span>
      </div>
      <button
        (click)="copyTalentNumberToClipboard(talent.talentNumber)"
        ui-library-button-outline
        color="dark"
        iconOnly="copy_plus_duplicate"
        [tooltip]="'copyToClipboard' | i18next"></button>
    </div>
  </div>
  <app-talent-internal-note></app-talent-internal-note>
</div>
