import {
  TalentFragment,
  TalentUpdateInput,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { TalentArrayFields } from '../../talent-sheet/talent-sheet.types';

export function revertResultTypeIsIdentical(
  fieldName: keyof TalentUpdateInput,
  changelog: PreparedTalentChangeLog,
  talent: TalentFragment,
): boolean {
  const key = fieldName as keyof Omit<
    TalentUpdateInput,
    keyof TalentArrayFields | 'isRevertInput' | 'revertInputHistoryId'
  >;
  const currentValue = talent[key];
  const revertValue = changelog.revertInput?.[key];
  return currentValue === revertValue;
}
