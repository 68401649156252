import { SupportedLanguage } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getSupportedLanguageOptions = (showFlag = false) => {
  return [
    {
      value: SupportedLanguage.DE,
      label: showFlag
        ? '🇩🇪 ' + i18next.t('SupportedLanguageEnum.DE')
        : i18next.t('SupportedLanguageEnum.DE'),
    },
    {
      value: SupportedLanguage.FR,
      label: showFlag
        ? '🇫🇷 ' + i18next.t('SupportedLanguageEnum.FR')
        : i18next.t('SupportedLanguageEnum.FR'),
    },
    {
      value: SupportedLanguage.EN,
      label: showFlag
        ? '🇬🇧 ' + i18next.t('SupportedLanguageEnum.EN')
        : i18next.t('SupportedLanguageEnum.EN'),
    },
  ] as const;
};
