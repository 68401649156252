import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  InputTextModule,
  OverflowGradientModule,
  RadioButtonModule,
  SheetModule,
} from '@intemp/unijob-ui';
import { VacancyUnibaseCompanyImportComponent } from './unibase-company-import.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonSolidComponent,
  InputContainerComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';

@NgModule({
  declarations: [VacancyUnibaseCompanyImportComponent],
  exports: [VacancyUnibaseCompanyImportComponent],
  imports: [
    SharedDefaultModule,
    SheetModule,
    OverflowGradientModule,
    FormsModule,
    InputTextModule,
    RadioButtonModule,
    ButtonSolidComponent,
    ReactiveFormsModule,
    TextInputComponent,
    InputContainerComponent,
  ],
})
export class VacancyUnibaseCompanyImportModule {}
