<div class="uj-flex uj-flex-col uj-gap-d">
  <ui-library-form-field
    fullWidth
    [hint]="ahvNumberControl.errors?.message.key | i18next | firstUppercase"
    [label]="'ahvNumber' | i18next | firstUppercase"
    [labelFor]="'ahvNumber' + componentId">
    <input
      ui-library-text-input
      [id]="'ahvNumber' + componentId"
      [imask]="ahvMask"
      [formControl]="ahvNumberControl"
      [feedback]="ahvNumberControl.errors ? 'invalid' : 'none'"
      focusTracker="ahvNumber"
      placeholder="XXX.XXXX.XXXX.XX"
      type="text" />
  </ui-library-form-field>
</div>
