<div>
  <label class="uj-flex uj-items-center uj-gap-b text-m-b uj-mb-b uj-relative"
    >{{ "leadsOrSources" | i18next | firstUppercase }}
    <span
      ><unijob-icon
        [tooltip]="'leadsOrSourcesTooltip' | i18next"
        size="16"
        color="grey"
        icon="info_circle"></unijob-icon
    ></span>
  </label>
  <div class="uj-flex uj-flex-col uj-gap-b">
    <div
      *ngFor="
        let formGroup of sortedSources();
        let i = index;
        trackBy: trackByUUID
      ">
      <app-talent-sources-entry
        (isInEditMode)="toggleSourcesIsInEditMode($event)"
        [source]="formGroup"></app-talent-sources-entry>
    </div>
    <!--    <button-->
    <!--      *ngIf="!sourcesIsInEditMode()"-->
    <!--      class="!uj-w-full uj-mt-c"-->
    <!--      ui-library-button-outline-->
    <!--      iconLeft="plus"-->
    <!--      color="dark"-->
    <!--      (click)="addSource()">-->
    <!--      {{ "addLeadsAndSource" | i18next | firstUppercase }}-->
    <!--    </button>-->
  </div>
</div>
