import { Component, inject, input } from '@angular/core';
import { UserService } from '../../../models/shared/user/user.service';
import { UserAvatarModule } from '../../modules/user-avatar/user-avatar.module';
import { ContextMenuModule } from '@intemp/unijob-ui';
import { UserInfoContextMenuModule } from '../../modules/user/user-info-context-menu/user-Info-context-menu.module';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-current-user-avatar',
  templateUrl: './current-user-avatar.component.html',
  imports: [
    UserAvatarModule,
    ContextMenuModule,
    UserInfoContextMenuModule,
    AsyncPipe,
    NgIf,
  ],
})
export class CurrentUserAvatarComponent {
  userService = inject(UserService);
  loggedInUserBranchesNames = input<string[]>([]);
}
