import { TalentArrayFields } from '../../talent-sheet/talent-sheet.types';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { ArrayActionEnum, TalentFragment } from '../../../../graphql/generated';
import { arrayItemHasChangedHandler } from './arrayItemHasChangedHandler';

export function revertArrayResultIsIdentical<T extends keyof TalentArrayFields>(
  fieldName: T,
  changelog: PreparedTalentChangeLog,
  array: TalentFragment[T],
): boolean {
  if (['files', 'pages'].includes(fieldName)) {
    return false;
  }
  const revertItem = changelog.revertInput?.[fieldName]?.at(0);
  const revertAction = revertItem?.type;
  const uuid = changelog.mutation?.input?.[fieldName]?.at(0)?.uuid;
  if (!uuid || !revertAction) {
    return false;
  }
  const item = array.find((c) => c.uuid === uuid);

  switch (revertAction) {
    case ArrayActionEnum.REMOVED:
      return !item;
    case ArrayActionEnum.ADDED:
      return item
        ? arrayItemHasChangedHandler[fieldName](item, revertItem)
        : false;
    case ArrayActionEnum.CHANGED:
      return !item
        ? false
        : arrayItemHasChangedHandler[fieldName](item, revertItem);
  }
}
