<div>
  <div>
    <label class="uj-block text-m-b uj-mb-b uj-relative"
      >{{ "targetJob" | i18next | firstUppercase }}
    </label>
    <div
      class="uj-mb-d"
      *ngFor="
        let titleGroup of formService.targetJobTitleFormArray.controls;
        let i = index;
        trackBy: trackByUUID
      "
      [formGroup]="titleGroup">
      <app-talent-target-job-entry [titleGroup]="titleGroup">
      </app-talent-target-job-entry>
    </div>
  </div>
  <app-talent-target-job-entry
    *ngIf="newTitleEntry"
    [isNewEntry]="true"
    [titleGroup]="newTitleEntry">
  </app-talent-target-job-entry>
</div>
