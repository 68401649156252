<div class="uj-mt-f uj-flex uj-flex-col uj-gap-d">
  <div>
    <label
      class="uj-block text-m-b uj-mb-b uj-relative"
      [for]="'nationality'"
      >{{ "nationality" | i18next | firstUppercase }}
    </label>
    <ui-library-simple-select
      size="M"
      design="outline"
      [searchable]="true"
      backgroundColor="white"
      [placeholderText]="'choose' | i18next"
      [options]="nationalityOptions"
      [formControl]="nationalityControl" />
  </div>

  <ng-container
    *ngIf="nationalityControl.value === 'CH'; else noneSwissCountry">
    <ui-library-form-field
      fullWidth
      [label]="'civilOrigin' | i18next | firstUppercase"
      [labelFor]="'civilOrigin' + componentId">
      <input
        ui-library-text-input
        [id]="'civilOrigin' + componentId"
        [formControl]="civilOriginControl"
        focusTracker="civilOrigin"
        [placeholder]="'city' | i18next"
        type="text" />
    </ui-library-form-field>
  </ng-container>

  <ng-template #noneSwissCountry>
    <div class="uj-grid uj-grid-cols-1 md:uj-grid-cols-3 uj-gap-c uj-mt-d">
      <div class="md:uj-col-span-2">
        <label
          class="uj-block text-m-b uj-mb-b uj-relative"
          [for]="'residencePermit'"
          >{{ "approval" | i18next | firstUppercase }}
        </label>
        <unijob-select
          showInvalidState="true"
          [options]="residencePermitOptions"
          size="size-m"
          [placeholder]="'choose' | i18next"
          elevation="box-outline"
          [formControl]="residencePermitControl"
          focusTracker="residencePermit"
          bgColor="bg-white">
        </unijob-select>
      </div>
      <div class="md:uj-col-span-1">
        <label
          class="uj-block text-m-b uj-mb-b uj-relative"
          [for]="'residencePermitValidUntil'"
          >{{ "validUntil" | i18next | firstUppercase }}
        </label>
        <unijob-date-picker
          [formControl]="residencePermitValidUntilControl"
          focusTracker="residencePermitValidUntil"
          mode="single"
          format="d.m.Y"
          [showInvalidState]="true">
        </unijob-date-picker>
      </div>
      <unijob-info-box
        class="md:uj-col-span-3"
        *ngIf="residencePermitControl.value === ResidencePermitEnum.NONE"
        type="WARNING"
        [message]="
          'talentStatusWillChangeAsSoonPermitPresent' | i18next
        "></unijob-info-box>
    </div>
  </ng-template>
</div>
