import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  Event,
  Router,
  RouterEvent,
  RoutesRecognized,
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CanLoadPages, UserService } from './models/shared/user/user.service';
import { VerticalDirection } from './models/types';
import {
  AllowedGlobalAddActions,
  GlobalAddActions,
} from './shared/modules/global-add/global-add.component';
import { hasAnyPermissionOnAction } from './shared/helpers/functions/hasAnyPermissionOnCustomerAction';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { LanguageService } from './core/services/language.service';
import { AuthService } from '@auth0/auth0-angular';
import { GenericError } from '@auth0/auth0-spa-js';
import { Title } from '@angular/platform-browser';
import i18next from 'i18next';
import {
  DocumentEventListenerService,
  getScrollbarWidth,
} from '@intemp/unijob-ui';
import { randomId } from './shared/helpers/functions/randomId';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from './shared/modules/global-sheets/global-sheets.service';
import { UserSelfFragment } from './graphql/generated';
import { RouteDataService } from './core/services/route-data/route-data.service';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';
import { OverlayService } from './shared/modules/overlay/overlay.service';
import { ServiceWorkerService } from './core/services/service-worker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  isBrowser = false;
  activePath?: string;
  navCollapsed = false;
  navToggleEmitter = new EventEmitter();

  networkOffline = false;
  networkMessage?: string;

  userIsLoggedIn = false;
  userIsVerified = false;

  // scrolling up means header is visible
  lastScrollDirection: VerticalDirection = VerticalDirection.UP;
  verticalDirection = VerticalDirection;

  user?: UserSelfFragment;

  canLoadPages?: CanLoadPages;

  allowedGlobalAddActions: AllowedGlobalAddActions = new Set();

  selectedLang = i18next.language.toUpperCase();

  languageOptions = [
    { label: 'Francais', value: 'FR' },
    { label: 'English', value: 'EN' },
    { label: 'Deutsch', value: 'DE' },
  ] as const;

  routeData$ = this.routeDataService.routeData$;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    private documentEventListenerService: DocumentEventListenerService,
    private authService: AuthService,
    private languageService: LanguageService,
    private globalSheetsService: GlobalSheetsService,
    private title: Title,
    private routeDataService: RouteDataService,
    @Inject(PLATFORM_ID) private platformId: any,
    private i18nPipe: I18NextPipe,
    private overlayService: OverlayService,
    private serviceWorkerService: ServiceWorkerService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.updateTitle();
    this.router.events
      .pipe(
        filter(
          (e: Event | RouterEvent): e is RoutesRecognized =>
            e instanceof RoutesRecognized,
        ),
      )
      .subscribe((event) => {
        this.activePath = event.urlAfterRedirects;
      });
  }

  // Listen for keydown events
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'd') {
      this.router.navigate(['/debug']);
    }
  }

  serviceWorkerRegistration: Promise<ServiceWorkerRegistration | undefined> =
    Promise.resolve(undefined);

  async ngOnInit(): Promise<void> {
    this.setCssScrollbarWidthVar();
    this.subscribeNetworkStatus();
    this.serviceWorkerService.registration.then();
    let isAlreadyLoggedIn = false;
    this.authService.user$.subscribe(async (profile) => {
      const isLoggedIn = profile !== null;
      if (isLoggedIn && isLoggedIn !== isAlreadyLoggedIn) {
        isAlreadyLoggedIn = true;
        this.userIsLoggedIn = true;
        this.userService.authUser$.subscribe(async (authUser) => {
          if (authUser && authUser.isVerified) {
            this.userIsVerified = true;
            await this.getUser();
            this.canLoadPages = await this.userService.getLoadablePages();
            this.languageService.getUserLanguage();
          }
        });
      }
    });
    this.authService.error$
      .pipe(
        filter((e) => {
          // debug problem with force reload
          console.log('auth Error:', e);
          return e instanceof GenericError && e?.error === 'login_required';
        }),
        mergeMap(() => {
          return this.router.navigate(['logout']);
        }),
      )
      .subscribe();

    if (this.route.snapshot.queryParams[`profile`]) {
      this.openProfile();
    }
  }

  ngAfterViewInit(): void {
    this.documentEventListenerService.scrollUpEmitter.subscribe(() => {
      if (this.lastScrollDirection === VerticalDirection.DOWN) {
        this.lastScrollDirection = VerticalDirection.UP;
      }
    });
    this.documentEventListenerService.scrollDownEmitter.subscribe(() => {
      if (
        this.lastScrollDirection === VerticalDirection.UP &&
        this.navCollapsed
      ) {
        this.lastScrollDirection = VerticalDirection.DOWN;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateTitle() {
    if (this.isBrowser) {
      // check if document hostUrls contains "hub.universaljob.ch" and if it does, set "hub.universaljob.ch" as title
      if (document.location.host.includes('hub.universaljob.ch')) {
        this.title.setTitle('hub.universaljob.ch');
      }
    }
  }

  setCssScrollbarWidthVar(): void {
    const width = getScrollbarWidth();
    // set on body
    document.body.style.setProperty('--scrollbar-width', `${width}px`);
  }

  get currentLang(): string {
    return i18next.language.toUpperCase();
  }

  setLanguage(isoCode: string): void {
    if (this.currentLang !== isoCode) {
      this.languageService.setLanguage(isoCode);
    }
  }

  async getUser(): Promise<void> {
    this.user = await this.userService.getUser();
    await this.updateComponentPermissions(this.user);
  }

  async updateComponentPermissions(user: UserSelfFragment): Promise<void> {
    const permissions = user.permissions;
    if (permissions) {
      if (hasAnyPermissionOnAction(permissions, 'canEditVacancy')) {
        this.allowedGlobalAddActions.add(GlobalAddActions.CREATE_VACANCY);
      }
      if (hasAnyPermissionOnAction(permissions, 'canEditTalent')) {
        this.allowedGlobalAddActions.add(GlobalAddActions.CREATE_TALENT);
      }
    }
  }

  subscribeNetworkStatus() {
    if (!this.isBrowser) return;
    this.networkOffline = !navigator.onLine;
    merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(
        takeUntil(this.destroyed$),
        map(() => !navigator.onLine),
      )
      .subscribe((isOffline) => {
        const hasBecomeOffline = !this.networkOffline && isOffline;
        const hasBecomeOnline = this.networkOffline && !isOffline;
        this.networkOffline = isOffline;
        if (hasBecomeOffline) {
          this.overlayService.show(this.i18nPipe.transform('youAreOffline'));
        } else if (hasBecomeOnline) {
          this.overlayService.show(this.i18nPipe.transform('pageIsReloading'));
          location.reload();
        }
      });
  }

  navToggled(navCollapsed: boolean): void {
    this.lastScrollDirection = VerticalDirection.UP;
    this.navCollapsed = navCollapsed;
  }

  openProfile() {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.PROFILE_EDIT,
      uuid: randomId(),
    });
  }
}
