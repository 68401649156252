import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ChangelogEnum,
  VacancyDetailFragment,
} from '../../../../graphql/generated';
import {
  SubmitVacancyUpdateInput,
  VacancyHistoryEntryFragmentForLocal,
} from '../../../../pages/vacancies/vacancy.types';

@Component({
  selector: 'app-vacancy-changelog-entry',
  templateUrl: './vacancy-changelog-entry.component.html',
})
export class VacancyChangelogEntryComponent {
  @Input({ required: true }) vacancyHistory!:
    | VacancyHistoryEntryFragmentForLocal
    | undefined;
  @Input({ required: true }) vacancy!: VacancyDetailFragment;

  @Output() triggerRevertRecord = new EventEmitter<SubmitVacancyUpdateInput>();
  constructor() {}
  // type cast to the correct type because union type narrowing does not work otherwise
  recordEntry<T>(): T {
    return this.vacancyHistory?.changelogEntry as T;
  }

  protected readonly ChangelogEnum = ChangelogEnum;
}
