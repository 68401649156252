import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DatePickerModule,
  InputTextModule,
  SwitchModule,
} from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import { ArrayActionEnum } from '../../../../../../graphql/generated';
import { YearToAgePipe } from '../../../../../helpers/pipes/yearToAge.pipe';
import { randomId } from '../../../../../helpers/functions/randomId';
import { ChildrenFormGroup } from '../../../talent-sheet.types';
import { TalentSharedService } from '../../../services/talent-shared.service';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import {
  AvatarComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  FormFieldComponent,
  ModalComponent,
  ModalService,
  TextInputComponent,
} from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-children-child',
  templateUrl: './talent-children-child.component.html',
  imports: [
    AvatarComponent,
    DatePickerModule,
    I18NextModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    SwitchModule,
    YearToAgePipe,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    ButtonTextComponent,
    ModalComponent,
    FormFieldComponent,
    TextInputComponent,
  ],
})
export class TalentChildrenChildComponent implements OnChanges {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentSharedService);
  modals = inject(ModalService);

  @Input({ required: true }) child!: FormGroup<ChildrenFormGroup>;

  @Output() isInEditMode = new EventEmitter<boolean>();

  isEditingChild = false;

  deleteConfirmationModalId = 'deleteConfirmation' + randomId();
  componentId = randomId();

  ngOnChanges(changes: SimpleChanges) {
    const isNewEntry = changes.child.currentValue.value.isNewEntry;
    if (isNewEntry) {
      this.setEditingChild(true);
    }
  }

  deleteChild() {
    this.updateChild(this.child, ArrayActionEnum.REMOVED);
    this.formService.children.removeAt(
      this.formService.children.controls.indexOf(this.child),
    );
    this.setEditingChild(false);
  }

  editChild() {
    this.setEditingChild(true);
  }

  cancelEditChild() {
    const children = this.formService.children;
    children.removeAt(children.controls.indexOf(this.child));
    this.setEditingChild(false);
  }

  addChild() {
    this.updateChild(this.child, ArrayActionEnum.ADDED);
    this.child.controls.isNewEntry.setValue(false);
    this.setEditingChild(false);
  }

  updateChildEntry() {
    this.updateChild(this.child, ArrayActionEnum.CHANGED);
    this.setEditingChild(false);
  }

  setEditingChild(value: boolean) {
    this.isEditingChild = value;
    this.isInEditMode.emit(value);
  }

  updateChild(
    childControl: FormGroup<ChildrenFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const childrenData = getFormData(childControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !childrenData) {
      console.error('Talent uuid is not valid');
      return;
    }
    delete childrenData.isNewEntry;
    childrenData.yearOfBirth = childrenData.yearOfBirth[0].getFullYear();
    childrenData.type = arrayAction;

    this.formService
      .updateTalentApi({
        children: [childrenData],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }
}
