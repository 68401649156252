import { Component, inject, Input } from '@angular/core';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import {
  CheckboxModule,
  InfoBoxModule,
  InputTextModule,
  ModalService,
  ResponsiveService,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { randomId } from '../../../../../helpers/functions/randomId';
import { PreferredLocationsFormGroup } from '../../../talent-sheet.types';
import { ArrayActionEnum } from '../../../../../../graphql/generated';
import { TalentSharedService } from '../../../services/talent-shared.service';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import {
  ButtonOutlineComponent,
  FormFieldComponent,
  TextInputComponent,
  ToastService,
} from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-preferred-location-entry',
  templateUrl: 'talent-preferred-location-entry.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    NgForOf,
    NgIf,
    SharedDefaultModule,
    ReactiveFormsModule,
    InfoBoxModule,
    InputTextModule,
    UjSelectInputModule,
    CheckboxModule,
    FormFieldComponent,
    TextInputComponent,
    ButtonOutlineComponent,
  ],
})
export class TalentPreferredLocationEntryComponent {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentSharedService);
  i18next = inject(I18NextPipe);
  toastService = inject(ToastService);
  modals = inject(ModalService);
  responsiveService = inject(ResponsiveService);

  @Input({ required: true })
  preferredLocationGroup!: FormGroup<PreferredLocationsFormGroup>;
  @Input() isNewEntry = false;
  @Input() isFirstEntry = false;

  preferredLocations = this.formService.preferredLocations;

  isSmDown$ = this.responsiveService.isSmDown$;
  componentId = randomId();

  onChange() {
    if (this.isNewEntry) {
      this.updatePreferredLocation(
        this.preferredLocationGroup,
        ArrayActionEnum.ADDED,
      );
      this.preferredLocationGroup.reset({
        uuid: randomId(),
        type: ArrayActionEnum.ADDED,
        location: null,
        radiusKm: null,
      });
    } else {
      this.updatePreferredLocation(
        this.preferredLocationGroup,
        ArrayActionEnum.CHANGED,
      );
    }
  }

  deletePreferredLocation() {
    this.updatePreferredLocation(
      this.preferredLocationGroup,
      ArrayActionEnum.REMOVED,
    );
    this.preferredLocations.removeAt(
      this.preferredLocations.controls.indexOf(this.preferredLocationGroup),
    );
  }

  updatePreferredLocation(
    childControl: FormGroup<PreferredLocationsFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const preferredLocation = getFormData(childControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !preferredLocation) {
      console.error(
        `talentUuid or preferredLocation is not valid - Should not happen!`,
      );
      return;
    }

    if (arrayAction === ArrayActionEnum.ADDED) {
      this.formService.addNewPreferredLocation({ ...preferredLocation });
    }

    preferredLocation.type = arrayAction;

    if (preferredLocation.radiusKm) {
      preferredLocation.radiusKm = parseFloat(preferredLocation.radiusKm);
    }

    this.formService
      .updateTalentApi({
        preferredLocations: [preferredLocation],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }
}
