import { Component, inject } from '@angular/core';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { I18NextModule } from 'angular-i18next';
import { ResponsiveService } from '@intemp/unijob-ui';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LinksFormGroup } from '../../talent-sheet.types';
import { randomId } from '../../../../helpers/functions/randomId';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { ArrayActionEnum } from '../../../../../graphql/generated';
import { TalentLinksEntryComponent } from './talent-links-entry/talent-links-entry.component';

@Component({
  standalone: true,
  selector: 'app-talent-links',
  templateUrl: 'talent-links.component.html',
  imports: [
    AsyncPipe,
    I18NextModule,
    NgForOf,
    NgIf,
    SharedDefaultModule,
    TalentLinksEntryComponent,
    ReactiveFormsModule,
  ],
})
export class TalentLinksComponent {
  formService = inject(TalentFormEditService);
  responsiveService = inject(ResponsiveService);

  newURLEntry = new FormGroup<LinksFormGroup>({
    uuid: new FormControl<string | null>(randomId()),
    type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.ADDED),
    url: new FormControl<string | null>(null),
  });

  trackByUUID(index: number, item: FormGroup<LinksFormGroup>) {
    return item.value.uuid;
  }
}
