import { differenceInMinutes, format, parseISO } from 'date-fns';
import i18next from 'i18next';

export function getLastEditTime(updatedAt: string): {
  lastEditTime: string;
  lastEditTimeMobile: string;
} {
  let lastEditTime = '';
  let lastEditTimeMobile = '';

  const minutes = differenceInMinutes(new Date(), new Date(updatedAt));
  if (minutes < 1) {
    lastEditTime = i18next.t('just_now');
    lastEditTimeMobile = lastEditTime;
  } else if (minutes < 60) {
    lastEditTime = i18next.t('last_changes_minutes_ago', {
      timePast: minutes,
    });
    lastEditTimeMobile = i18next.t('last_changes_minutes_ago_short', {
      timePast: minutes,
    });
  } else if (minutes * 60 < 24) {
    lastEditTime = i18next.t('last_changes_hours_ago', {
      timePast: Math.floor(minutes / 60),
    });
    lastEditTimeMobile = i18next.t('last_changes_hours_ago_short', {
      timePast: Math.floor(minutes / 60),
    });
  } else {
    lastEditTime = i18next.t('last_changes_at', {
      timePast: format(parseISO(updatedAt), 'dd.MM.yy'),
    });
    lastEditTimeMobile = lastEditTime;
  }

  return {
    lastEditTime: lastEditTime,
    lastEditTimeMobile: lastEditTimeMobile,
  };
}
