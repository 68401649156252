import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { customValidators } from '../shared-forms/customValidators';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { stringContainsSearch } from '../../helpers/functions/stringContainsSearch';
import { BehaviorSubject } from 'rxjs';
import {
  UserListItemFragment,
  UserSelfFragment,
} from '../../../graphql/generated';

@Component({
  selector: 'app-commission-input',
  templateUrl: './commission-input.component.html',
  styleUrls: ['./commission-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommissionInputComponent implements OnInit {
  @Input() options: (UserListItemFragment | UserSelfFragment)[] = [];
  @Input() selectedOption?: UserListItemFragment | UserSelfFragment;
  @Input() commissionTotal?: number | null;
  @Input()
  get otherCommissions(): number {
    return this._otherCommissions;
  }
  set otherCommissions(value: number | null) {
    if (value && this._otherCommissions !== value) {
      this._otherCommissions = value;
      this.onCommissionChange();
    }
  }
  _otherCommissions = 0;

  @Input() loggedInUser?: UserSelfFragment;

  private commission$ = new BehaviorSubject<number | undefined>(undefined);

  @Input() set commission(value: number) {
    this.commission$.next(value);
  }

  @Input()
  get selectClearable() {
    return this._selectClearable;
  }
  set selectClearable(value: BooleanInput) {
    this._selectClearable = coerceBooleanProperty(value);
  }
  _selectClearable = false;

  @Input() set splitCommission(value: BooleanInput) {
    if (coerceBooleanProperty(value)) {
      this.splitCommissionEvenly();
    }
  }

  @Output() selectedUserChanged = new EventEmitter<
    (UserListItemFragment | UserSelfFragment) | undefined
  >();
  @Output() commissionChanged = new EventEmitter<number>();

  commissionCHFControl = new FormControl(0, [customValidators.required]);

  commissionForm = new FormGroup({
    commissionCHF: this.commissionCHFControl,
  });

  searchTerm = '';
  filteredOptions: (UserListItemFragment | UserSelfFragment)[] = [];

  constructor() {}

  ngOnInit() {
    this.commission$.subscribe((value) =>
      this.commissionForm.controls.commissionCHF.setValue(value ?? 0),
    );
    this.commissionForm.valueChanges.subscribe(() => {
      this.onCommissionChange();
    });
    if (this.selectClearable) {
      this.commissionForm.controls.commissionCHF.disable();
    }
  }

  onCommissionChange() {
    this.commissionChanged.emit(Number(this.commissionCHFControl.value));
    this.validateCommissions();
  }

  validateCommissions() {
    const commissionCHF = Number(this.commissionCHFControl.value);
    if (
      this.commissionTotal &&
      commissionCHF + this.otherCommissions > this.commissionTotal
    ) {
      this.commissionCHFControl.setErrors({ overLimit: true });
    } else if (commissionCHF < 0 || this.otherCommissions < 0) {
      this.commissionCHFControl.setErrors({ negativeCommission: true });
    } else {
      this.commissionCHFControl.setErrors(null);
    }
  }

  trackOptionsByFn = (
    index: number,
    item: UserListItemFragment | UserSelfFragment,
  ) => item._id;

  changeSelectedUser(
    selectedUser?: (UserListItemFragment | UserSelfFragment) | null,
  ) {
    this.selectedUserChanged.emit(selectedUser || undefined);
    if (this.selectClearable) {
      if (selectedUser) {
        this.commissionForm.controls.commissionCHF.enable();
      } else {
        this.commissionForm.controls.commissionCHF.setValue(0);
        this.commissionForm.controls.commissionCHF.disable();
      }
    }
  }

  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.searchContacts().then();
  }

  onToggledOpen(open: boolean) {
    if (open) {
      this.searchTerm = '';
      this.searchContacts().then();
    }
  }

  async searchContacts() {
    const searchTerm = this.searchTerm;
    if (!searchTerm) {
      this.filteredOptions = this.options;
      return;
    }
    this.filteredOptions = this.options.filter((user) =>
      stringContainsSearch(
        user.profile.firstName + user.profile.lastName,
        searchTerm,
      ),
    );
  }

  splitCommissionEvenly() {
    //ExpressionChangedAfterItHasBeenCheckedError without setTimeout
    setTimeout(() => {
      if (this.commissionTotal) {
        this.commissionForm.controls.commissionCHF.setValue(
          this.commissionTotal / 2,
        );
      }
    });
  }
}
