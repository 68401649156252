<div>
  <label class="uj-block text-m-b uj-mb-b uj-relative"
    >{{ "internalNotes" | i18next | firstUppercase }}
  </label>
  <unijob-wysiwyg
    class="uj-mb-b"
    focusTracker="internalNotes"
    [formControl]="formService.internalNotes"
    [linkPlaceholder]="'addNotes' | i18next"></unijob-wysiwyg>
</div>
