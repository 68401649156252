<unijob-sheet
  [id]="sheetId"
  size="s"
  (sheetClosed)="handleSheetClosed(sheetId)">
  <ng-container
    slot="header"
    *ngIf="channel$ | async as channel; else loadingChannelHeader"
    >{{ channel.data.channelQuery.name }} {{ "Slots" | i18next }}</ng-container
  >
  <ng-container
    slot="content"
    *ngIf="channel$ | async as channel; else loadingChannelContent">
    <!-- Prio Boost description -->
    <ng-container
      *ngIf="channel.data.channelQuery.exportSettings.prioBoost?.enabled">
      <p class="uj-mb-[16px]">
        {{
          "prioBoostDescription"
            | i18next
              : {
                  max: channel.data.channelQuery
                    .maxRunningAndPendingSlotsPerUser,
                }
        }}
      </p>
      <p class="uj-mb-[16px]">{{ "prioBoostSlotGuidance" | i18next }}</p>
    </ng-container>
    <h2 class="uj-flex uj-gap-c uj-mb-c text-l-b">
      {{ "Slots" | i18next }}
      <mark
        ui-library-tag
        color="accent"
        size="S">
        <ng-container
          *ngIf="vacancies$ | async as vacancies; else loadingVacanciesCount"
          >{{ vacancies.totalCount ?? 0 }}</ng-container
        >/{{
          channel.data.channelQuery.maxRunningAndPendingSlotsPerUser ??
            channel.data.channelQuery.maxRunningAndPendingSlotsTotal
        }}</mark
      >
    </h2>
    <div
      class="uj-flex uj-flex-col uj-items-center uj-justify-between uj-gap-c uj-mb-b"
      *ngIf="vacancies$ | async as vacancies; else loadingVacanciesList"
      [@vacancyListAnimation]="vacancies.items.length">
      <div
        *ngFor="let vacancy of vacancies.items"
        class="uj-flex uj-items-start uj-w-full uj-gap-b uj-flex-nowrap"
        [class.uj-opacity-50]="isRemoving(vacancy)"
        [class.uj-pointer-events-none]="isRemoving(vacancy)">
        <div
          role="button"
          class="uj-flex uj-items-center uj-w-full uj-min-w-0 uj-gap-c uj-p-d uj-border uj-rounded uj-border-border uj-flex-nowrap hover:uj-cursor-pointer hover:uj-opacity-80"
          (click)="openVacancy(vacancy)">
          <ui-library-avatar
            [title]="'vacancyStatusEnum.' + vacancy?.status | i18next"
            class="uj-display-inline-block"
            borderColor="grey"
            content="icon"
            icon="suitcase"
            size="s"
            shape="round"
            tagType="tagAvatar">
          </ui-library-avatar>
          <div class="uj-flex uj-flex-col uj-min-w-0 uj-flex-nowrap">
            <div class="uj-min-w-0 uj-truncate text-m-b">
              {{ vacancy.uniBaseX?.publicTitle }}
            </div>
            <div class="uj-min-w-0 uj-truncate">
              <ng-container
                *ngIf="this.getChannelConfig(vacancy) as channelConfig">
                <div class="uj-flex uj-items-center uj-gap-a">
                  <div
                    class="uj-mr-b unijob-dot uj-shrink-0"
                    [class]="{
                      'color-green': channelConfig.status === 'RUNNING',
                      'color-grey': ['COMPLETED', 'EXPIRED'].includes(
                        channelConfig.status
                      ),
                      'color-yellow': channelConfig.status === 'PENDING',
                    }"></div>
                  <div class="overflow-ellipsis">
                    <span
                      class="text-s-b"
                      *ngIf="channel$ | async as channel"
                      >{{ channel.data.channelQuery.name }}</span
                    >
                    <span class="text-s">
                      {{
                        "UniBaseXVacancyChannelConfigurationStatusEnum." +
                          channelConfig.status | i18next
                      }}
                      <ng-container
                        class="text-m"
                        *ngIf="
                          channelConfig.status === 'RUNNING' &&
                          channelConfig.runtimes?.[0]?.start as startDate
                        ">
                        {{
                          "sinceRelativeDate"
                            | i18next
                              : {
                                  date: startDate | date: "dd.MM.yyyy",
                                  relativeDate: startDate | relativeTime,
                                }
                        }}
                      </ng-container>
                      <ng-container
                        *ngIf="
                          (channelConfig.status === 'EXPIRED' ||
                            channelConfig.status === 'COMPLETED') &&
                          channelConfig.runtimes?.[0]?.end as endDate
                        ">
                        {{
                          "sinceRelativeDate"
                            | i18next
                              : {
                                  date: endDate | date: "dd.MM.yyyy",
                                  relativeDate: endDate | relativeTime,
                                }
                        }}
                      </ng-container>
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
            <div
              *ngIf="
                vacancy.uniBaseX?.companyLocation?.companyReference?.legalName
              "
              class="uj-min-w-0 uj-mt-a uj-truncate">
              <div class="uj-flex uj-items-center uj-gap-b text-s">
                <unijob-icon
                  icon="company_2"
                  size="12"></unijob-icon>
                {{
                  vacancy.uniBaseX?.companyLocation?.companyReference?.legalName
                }}
              </div>
            </div>
          </div>
        </div>
        <button
          ui-library-button-outline
          type="button"
          iconOnly="trash"
          color="dark"
          positionInGroup="default"
          (click)="
            completeChannel(vacancy, channel.data.channelQuery)
          "></button>
      </div>
    </div>
  </ng-container>
</unijob-sheet>

<ng-template #loadingChannelHeader>
  <ng-container slot="header">Loading channel...</ng-container>
</ng-template>

<ng-template #loadingChannelContent>
  <ng-container slot="content">...</ng-container>
</ng-template>

<ng-template #loadingVacanciesCount> ... </ng-template>

<ng-template #loadingVacanciesList> ... </ng-template>
