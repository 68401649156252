<div class="uj-flex uj-flex-col">
  <ui-library-form-field
    *ngIf="isSmDown$ | async"
    fullWidth
    [label]="
      ('preferredLocation' | i18next | firstUppercase) +
      ' & ' +
      ('radius' | i18next | firstUppercase)
    "></ui-library-form-field>
  <div class="uj-flex uj-flex-col uj-gap-c">
    <app-talent-preferred-location-entry
      *ngFor="
        let preferredLocationGroup of formService.preferredLocations.controls;
        let i = index;
        trackBy: trackByUUID
      "
      [preferredLocationGroup]="preferredLocationGroup"
      [isFirstEntry]="i === 0">
    </app-talent-preferred-location-entry>

    <app-talent-preferred-location-entry
      *ngIf="newLocationEntry"
      [isNewEntry]="true"
      [isFirstEntry]="formService.preferredLocations.controls.length === 0"
      [preferredLocationGroup]="newLocationEntry">
    </app-talent-preferred-location-entry>
  </div>
</div>
