<div
  class="uj-flex uj-flex-col uj-gap-f"
  *ngIf="matchSheetService.match$ | async as match">
  <div>
    <app-match-parties [match]="match" />
  </div>
  <!--
  <ui-library-form-field
    fullWidth
    [label]="'uniBotRating' | i18next">
    <app-rating-stars [rating]="match.rating ?? 0"></app-rating-stars>
  </ui-library-form-field>
  -->

  <ui-library-form-field
    fullWidth
    [label]="'internalRemark' | i18next"
    labelFor="internalRemark">
    <unijob-wysiwyg
      class="uj-mb-b"
      title="internalRemark"
      focusTracker="internalNotes"
      [formControl]="formService.internalRemarks"
      [linkPlaceholder]="'addNotes' | i18next"></unijob-wysiwyg>
  </ui-library-form-field>
</div>
