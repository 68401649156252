import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  VacancyDetailUniBaseXVacancyFragment,
  VacancyPublishableFieldsEnum,
} from '../../../../graphql/generated';
import { Writable } from 'type-fest';
import {
  SubmitVacancyUpdateInput,
  VacancyAdvertisementFormData,
} from '../../../../pages/vacancies/vacancy.types';
import { disableFormGroup } from '../../../helpers/functions/disableFormGroup';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { patchFormControl } from '../../../helpers/functions/patchFormControl';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import { customValidators } from '../../shared-forms/customValidators';
import { FocusTrackerDirective } from '../focusTracker.directive';
import { validateFormControl } from '../helpers/validateFormControl';

@Component({
  selector: 'app-vacancy-detail-advertisement',
  templateUrl: './vacancy-detail-advertisement.component.html',
})
export class VacancyDetailAdvertisementComponent implements OnInit, OnChanges {
  @Input() uniBaseXVacancy?: VacancyDetailUniBaseXVacancyFragment;
  @Input({ required: true }) advertisementData!: VacancyAdvertisementFormData;
  @Input({ required: true }) formRef!: FormGroup;
  @Input({ required: true })
  @Input()
  formIsDisabled = false;

  maxTitleLength = 40;
  titleControl = new FormControl<string>('');
  functionTextControl = new FormControl<any>(null);
  requirementsTextControl = new FormControl<any>(null);
  benefitsTextControl = new FormControl<any>(null);
  employerDescriptionTextControl = new FormControl<any>(null);

  formGroup = new FormGroup({
    title: this.titleControl,
    functionText: this.functionTextControl,
    requirementsText: this.requirementsTextControl,
    benefitsText: this.benefitsTextControl,
    employerDescriptionText: this.employerDescriptionTextControl,
  });

  VacancyPublishableFieldsEnum = VacancyPublishableFieldsEnum;
  hasChanges(field: VacancyPublishableFieldsEnum) {
    return this.uniBaseXVacancy?.unpublishedChanges?.includes(field);
  }

  @Output() triggerAutoSave = new EventEmitter<SubmitVacancyUpdateInput>();
  autoSaved: { [key in keyof VacancyAdvertisementFormData]?: boolean } = {};

  showEmployerDescriptionTextHint = false;

  programmaticUpdate = new BehaviorSubject<boolean>(false);

  @ViewChildren(FocusTrackerDirective)
  focusTrackers!: QueryList<FocusTrackerDirective>;
  constructor() {}

  ngOnInit(): void {
    this.updateFormValue();
    this.subscribeFormChanges();

    this.employerDescriptionTextControl.valueChanges.subscribe((value) => {
      this.validateInput(JSON.stringify(value));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.firstChange &&
      changes.advertisementData?.previousValue &&
      changes.advertisementData?.currentValue
    ) {
      this.patchFormValuesOnChange(
        changes.advertisementData.previousValue,
        changes.advertisementData.currentValue,
      );
    }

    if (changes.formIsDisabled) {
      if (changes.formIsDisabled.currentValue) {
        disableFormGroup(this.formGroup);
      } else {
        this.formGroup.enable({ emitEvent: false });
      }
    }
  }

  isFieldFocused(field: keyof VacancyAdvertisementFormData) {
    return this.focusTrackers.find((tracker) => tracker.targetID === field)
      ?.isFocused$.value;
  }

  patchFormValuesOnChange(
    previous: VacancyAdvertisementFormData,
    current: VacancyAdvertisementFormData,
  ) {
    this.programmaticUpdate.next(true);
    const titleFocused = this.isFieldFocused('title');
    const functionTextFocused = this.isFieldFocused('functionText');
    const requirementsTextFocused = this.isFieldFocused('requirementsText');
    const benefitsTextFocused = this.isFieldFocused('benefitsText');
    const employerDescriptionTextFocused = this.isFieldFocused(
      'employerDescriptionText',
    );

    if (
      !functionTextFocused &&
      JSON.stringify(previous.functionText) !==
        JSON.stringify(current.functionText)
    ) {
      patchFormControl(this.functionTextControl, current.functionText);
    }
    if (
      !requirementsTextFocused &&
      JSON.stringify(previous.requirementsText) !==
        JSON.stringify(current.requirementsText)
    ) {
      patchFormControl(this.requirementsTextControl, current.requirementsText);
    }

    if (
      !benefitsTextFocused &&
      JSON.stringify(previous.benefitsText) !==
        JSON.stringify(current.benefitsText)
    ) {
      patchFormControl(this.benefitsTextControl, current.benefitsText);
    }

    if (
      !employerDescriptionTextFocused &&
      JSON.stringify(previous.employerDescriptionText) !==
        JSON.stringify(current.employerDescriptionText)
    ) {
      patchFormControl(
        this.employerDescriptionTextControl,
        current.employerDescriptionText,
      );
    }

    if (!titleFocused && previous.title !== current.title) {
      patchFormControl(this.titleControl, current.title);
    }

    this.programmaticUpdate.next(false);
  }

  updateFormValue() {
    const {
      title,
      functionText,
      requirementsText,
      benefitsText,
      employerDescriptionText,
    } = this.advertisementData;

    this.validateInput(JSON.stringify(employerDescriptionText));

    this.titleControl.setValue(title ?? '');
    this.functionTextControl.setValue(functionText ?? '');
    this.requirementsTextControl.setValue(requirementsText ?? '');
    this.benefitsTextControl.setValue(benefitsText ?? '');
    this.employerDescriptionTextControl.setValue(employerDescriptionText ?? '');

    this.formRef.setControl('advertisement', this.formGroup);
  }

  subscribeFormChanges() {
    this.formGroup.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((controls) => {
        const submitDataInput: Writable<SubmitVacancyUpdateInput> = {};
        getObjectKeys(controls).forEach((key) => {
          const control = this.formGroup.controls[key];
          if (control?.dirty) {
            this.autoSaved[key] = true;
            submitDataInput[key] = control.value;
            control.markAsPristine();
          }
        });
        this.triggerAutoSave.emit(submitDataInput);
      });
  }

  validateInput(value: string) {
    const emailRegex = new RegExp(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
    );
    const phoneRegex = new RegExp(/(\+?\d{1,3}[- ]?)?\d{10}/);
    const emailMatch = emailRegex.test(value);
    const phoneMatch = phoneRegex.test(value);
    this.showEmployerDescriptionTextHint = !!(emailMatch || phoneMatch);
  }

  public enablePublishedValidation() {
    const { isEmpty, hasPostMirrorContent } = customValidators;
    this.titleControl.setValidators([
      isEmpty,
      customValidators.vacancyTitleDoubledTermsProvidedByOtherFields(),
      customValidators.vacancyTitleBannedFillerWords(),
      customValidators.vacancyTitleBannedCharacters(),
    ]);

    this.functionTextControl.setValidators([isEmpty, hasPostMirrorContent]);
    this.requirementsTextControl.setValidators([isEmpty, hasPostMirrorContent]);
  }

  public validateForm() {
    Object.keys(this.formGroup.controls).forEach((key) => {
      const control = this.formGroup.get(key);
      if (control) {
        validateFormControl(control);
      }
    });
  }

  titleIsTooLong() {
    return (
      this.formGroup.controls.title.value?.length &&
      this.formGroup.controls.title.value.length >= this.maxTitleLength
    );
  }
}
