import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import {
  InputTextModule,
  OverflowGradientModule,
  RadioButtonModule,
  SheetModule,
} from '@intemp/unijob-ui';
import { VacancyCompanyLocationSelectComponent } from './vacancy-company-location-select.component';
import { FormsModule } from '@angular/forms';
import { BigSelectModule } from '../../big-select/big-select.module';
import { VacancyUnibaseCompanyImportModule } from './unibase-company-import/unibase-company-import.module';
import { ConsultantSelectListItemComponent } from '../../consultant-select/consultant-select-list-item/consultant-select-list-item.component';
import { LetDirective } from '@ngrx/component';
import {
  ButtonOutlineComponent,
  SelectComponent,
  SelectionListComponent,
  TextboxListItemComponent,
} from '@intemp/unijob-ui2';
import { AvatarListItemComponent } from './avatar-list-item/avatar-list-item.component';
import { AvatarEmptyItemComponent } from './avatar-empty-item/avatar-empty-item.component';

@NgModule({
  declarations: [VacancyCompanyLocationSelectComponent],
  exports: [VacancyCompanyLocationSelectComponent],
  imports: [
    SharedDefaultModule,
    SheetModule,
    OverflowGradientModule,
    FormsModule,
    InputTextModule,
    RadioButtonModule,
    BigSelectModule,
    VacancyUnibaseCompanyImportModule,
    ConsultantSelectListItemComponent,
    LetDirective,
    SelectComponent,
    SelectionListComponent,
    TextboxListItemComponent,
    AvatarListItemComponent,
    AvatarEmptyItemComponent,
    ButtonOutlineComponent,
  ],
})
export class VacancyCompanyLocationSelectModule {}
