import {
  AfterViewInit,
  Component,
  input,
  signal,
  ViewChild,
} from '@angular/core';
import { MatchFragment } from '../../../../graphql/generated';
import { MatchTalentCardComponent } from './match-talent-card/match-talent-card.component';
import { MatchVacancyCardComponent } from './match-vacancy-card/match-vacancy-card.component';
import { MatchCompanyCardComponent } from './match-company-card/match-company-card.component';
import { MatchResponsibleContactCardComponent } from './match-responsible-contact-card/match-responsible-contact-card.component';

@Component({
  standalone: true,
  selector: 'app-match-parties',
  templateUrl: './match-parties.component.html',
  styleUrls: ['./match-parties.component.scss'],
  imports: [
    MatchTalentCardComponent,
    MatchVacancyCardComponent,
    MatchCompanyCardComponent,
    MatchResponsibleContactCardComponent,
  ],
  providers: [],
})
export class MatchPartiesComponent implements AfterViewInit {
  match = input.required<MatchFragment>();

  talentCardMeasurement = signal<{
    offsetTop: number;
    height: number;
  }>({ offsetTop: 0, height: 0 });

  @ViewChild(MatchVacancyCardComponent) vacancyCard!: MatchVacancyCardComponent;
  vacancyCardHeight = signal<number>(0);

  ngAfterViewInit() {
    const matchTalentCardElement = document.getElementById('match-talent-card');
    if (matchTalentCardElement) {
      this.talentCardMeasurement.set({
        offsetTop: matchTalentCardElement.offsetTop,
        height: matchTalentCardElement.clientHeight,
      });
    }

    this.vacancyCardHeight.set(
      this.vacancyCard.hostElement.nativeElement.clientHeight,
    );
  }
}
