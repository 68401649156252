import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { SheetService } from '@intemp/unijob-ui';
import {
  MatchFragment,
  MatchSubscribeGQL,
} from '../../../../graphql/generated';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { GraphQLService } from '../../../../core/services/graphql.service';
import { rxFilterIsNotEmpty } from '../../../helpers/functions/rxFilterIsNotEmpty';
import { MatchSheetFormService } from './match-sheet-form.service';

@Injectable()
export class MatchSheetService {
  matchId$ = new BehaviorSubject<string>('');

  onScrollEventName = 'matchSheetScroll';

  isLoading$ = new BehaviorSubject<boolean>(false);

  match$: Observable<MatchFragment | undefined> = this.matchId$.pipe(
    switchMap((uuid) => {
      return this.matchSubscribeGQL
        .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
        .pipe(
          map((res) => {
            return res.data?.matchSubscribe;
          }),
          catchError((err) => {
            this.graphQLService.handleError(err);
            return [];
          }),
        );
    }),
    shareReplay(1),
  );

  constructor(
    private sheetService: SheetService,
    private matchSubscribeGQL: MatchSubscribeGQL,
    private graphQLService: GraphQLService,
    private formService: MatchSheetFormService,
  ) {}

  //TODO Check out why this is needed and look if we can do it in a more "angular" way.
  triggerAnchorScroll() {
    window.dispatchEvent(new CustomEvent(this.onScrollEventName));
  }

  open(sheetId: string): void {
    this.sheetService.open(sheetId);
  }

  subscribeMatch() {
    this.match$.pipe(rxFilterIsNotEmpty()).subscribe((match) => {
      this.formService.patchForm(match);
    });
  }
}
