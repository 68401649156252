import { FormGroup } from '@angular/forms';

export const findInvalidControls = (form: FormGroup): string[] => {
  const invalid = [];
  const controls = form.controls;
  for (const name in controls) {
    const control = controls[name];
    if (control instanceof FormGroup) {
      const invalidControls = findInvalidControls(control);
      invalid.push(...invalidControls);
    } else {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }
  return invalid;
};
