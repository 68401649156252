import { VacancyPositionTypeEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getPositionTypeTextOptions = (i18next: I18NextPipe) => {
  return [
    {
      value: VacancyPositionTypeEnum.OFFICER,
      label: i18next.transform('VacancyPositionTypeEnum.OFFICER'),
    },
    {
      value: VacancyPositionTypeEnum.LEAD,
      label: i18next.transform('VacancyPositionTypeEnum.LEAD'),
    },
    {
      value: VacancyPositionTypeEnum.EMPLOYEE,
      label: i18next.transform('VacancyPositionTypeEnum.EMPLOYEE'),
    },
  ] as const;
};
