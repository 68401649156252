import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VacancySalaryInputComponent } from './vacancy-salary-input.component';
import { IMaskModule } from 'angular-imask';
import { I18NextModule } from 'angular-i18next';
import {
  InputTextModule,
  SwitchModule,
  UjSelectInputModule,
  UnijobModule,
} from '@intemp/unijob-ui';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { SharedFormsModule } from '../shared-forms/shared-forms.module';
import {
  FormFieldComponent,
  IconComponent,
  InputContainerComponent,
  PseudoInputComponent,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';

@NgModule({
  declarations: [VacancySalaryInputComponent],
  exports: [VacancySalaryInputComponent],
  imports: [
    CommonModule,
    I18NextModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    SwitchModule,
    UjSelectInputModule,
    UnijobModule,
    SharedFormsModule,
    SimpleSelectComponent,
    TextInputComponent,
    PseudoInputComponent,
    InputContainerComponent,
    FormFieldComponent,
    IMaskModule,
    IconComponent,
  ],
})
export class VacancySalaryInputModule {}
