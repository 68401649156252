import {
  AfterViewInit,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { TalentFormEditService } from '../services/talentFormEdit.service';
import { FocusTrackerDirective } from '../../vacancy-detail/focusTracker.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { TalentPhonesComponent } from './talent-phones/talent-phones.component';
import { TalentEmailsComponent } from './talent-emails/talent-emails.component';
import { TalentAddressesComponent } from './talent-addresses/talent-addresses.component';
import { TalentLinksComponent } from './talent-links/talent-links.component';
import { SupportedLanguageSelectorComponent } from '../../../components/supported-language-selector/supported-language-selector.component';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { InputLabelComponent } from '../../../components/input-label/input-label.component';
import { randomId } from '@intemp/unijob-ui';
import { environment } from '../../../../../environments/environment';

@Component({
  standalone: true,
  selector: 'app-talent-contact',
  templateUrl: './talent-contact.component.html',
  imports: [
    ReactiveFormsModule,
    TalentPhonesComponent,
    TalentEmailsComponent,
    TalentAddressesComponent,
    TalentLinksComponent,
    SupportedLanguageSelectorComponent,
    I18NextModule,
    SharedDefaultModule,
    InputLabelComponent,
  ],
  providers: [],
})
export class TalentContactComponent implements AfterViewInit {
  i18next = inject(I18NextPipe);
  formService = inject(TalentFormEditService);
  componentId = randomId();
  communicationLanguageControl = this.formService.communicationLanguage;

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  ngAfterViewInit() {
    this.listenToFocusTrackerChange();
    this.communicationLanguageControl.valueChanges.subscribe((value) => {
      this.formService.submitFieldUpdate('communicationLanguage', value);
    });
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }

  protected readonly environment = environment;
}
