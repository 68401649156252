import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { ResponsiveService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import {
  VacancyDetailFragment,
  VacancyStatusEnum,
} from '../../../graphql/generated';
import { ChannelsService } from '../../../models/unibase/channels/channels.service';
import { environment } from '../../../../environments/environment';
import { rxFilterIsNotEmpty } from '../../helpers/functions/rxFilterIsNotEmpty';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../global-sheets/global-sheets.service';
import { ToastService } from '@intemp/unijob-ui2';

@Component({
  selector: 'app-vacancy-promote',
  templateUrl: './vacancy-promote.component.html',
})
export class VacancyPromoteComponent {
  constructor(
    private responsiveService: ResponsiveService,
    private globalSheetsService: GlobalSheetsService,
    private channelsService: ChannelsService,
    private toastService: ToastService,
    private clipboard: Clipboard,
    private i18nPipe: I18NextPipe,
  ) {}

  public vacancyNullable$ = new BehaviorSubject<VacancyDetailFragment | null>(
    null,
  );
  public vacancy$ = this.vacancyNullable$.pipe(rxFilterIsNotEmpty());

  @Input({ required: true })
  set vacancy(value: VacancyDetailFragment) {
    this.vacancyNullable$.next(value);
  }

  isSmDown$ = this.responsiveService.isSmDown$;
  vacancyStatusEnum = VacancyStatusEnum;

  clearSheetActionParam(vacancy: VacancyDetailFragment) {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.VACANCY_EDIT,
        uuid: vacancy.uuid,
      },
      'action',
      null,
    );
  }

  public availableInternalChannels$ = combineLatest([
    this.channelsService.channelsSubscription$,
    this.vacancy$,
  ]).pipe(
    map(([channels, vacancy]) =>
      channels.items?.filter((channel) =>
        ChannelsService.isChannelAvailableForThisVacancy(channel, vacancy),
      ),
    ),
    map((channels) =>
      channels?.filter(
        (channel) => channel.internal && !channel.prerequisiteChannel,
      ),
    ),
  );

  public availableExternalChannels$ = combineLatest([
    this.channelsService.channelsSubscription$,
    this.vacancy$,
  ]).pipe(
    map(([channels, vacancy]) =>
      channels.items?.filter((channel) =>
        ChannelsService.isChannelAvailableForThisVacancy(channel, vacancy),
      ),
    ),
    map((channels) =>
      channels?.filter(
        (channel) => !channel.internal && !channel.prerequisiteChannel,
      ),
    ),
  );

  availableAddonChannels$ = combineLatest([
    this.channelsService.channelsSubscription$,
    this.vacancy$,
  ]).pipe(
    map(([channels, vacancy]) =>
      channels.items?.filter((channel) =>
        ChannelsService.isChannelAvailableForThisVacancy(channel, vacancy),
      ),
    ),
    map((channels) =>
      channels?.filter((channel) => channel.prerequisiteChannel),
    ),
  );

  configurationsOfChannel(channelId: string, vacancy: VacancyDetailFragment) {
    return (
      vacancy.uniBaseX?.configuredChannels?.filter(
        (conf) => conf.channelReference.uuid === channelId,
      ) ?? []
    );
  }

  copyLinkToClipboard(
    vacancy: VacancyDetailFragment,
    utmSource: string,
    utmCampaign: string,
    utmMedium: string,
    linkToApplication = false,
  ) {
    if (!vacancy) {
      this.toastService.makeToast({
        type: 'ERROR',
        message: 'missingVacancyForLink',
      });
      return;
    }
    let url = `${environment.universaljobHost}/de/job-frame/${vacancy.public?.uuid[0]}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}`;
    if (linkToApplication) {
      url += '&showApplicationForm=true';
    }
    this.clipboard.copy(url);
    this.toastService.makeToast({
      type: 'SUCCESS',
      message: this.i18nPipe.transform('linkCopied'),
      duration: 6000,
    });
  }
}
