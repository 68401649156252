import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { setTimeout$, SheetModule, SheetService } from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { randomId } from '../../helpers/functions/randomId';
import { I18NextPipe } from 'angular-i18next';
import { LetDirective } from '@ngrx/component';
import { TalentChangelogService } from './services/changelog-talent.service';
import { PreparedTalentChangeLog } from '../../../pages/talents/talent.types';
import { TalentChangelogRecordComponent } from './talent-changelog-record/talent-changelog-record.component';
import { ChangelogTalentHelperService } from './services/changelog-talent-helper.service';
import { ToastService } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-changelog',
  templateUrl: './talent-changelog.component.html',
  imports: [
    SheetModule,
    SharedDefaultModule,
    LetDirective,
    TalentChangelogRecordComponent,
  ],
  providers: [TalentChangelogService, ChangelogTalentHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentChangelogComponent implements AfterViewInit {
  sheetService = inject(SheetService);
  toastService = inject(ToastService);
  i18nPipe = inject(I18NextPipe);
  service = inject(TalentChangelogService);

  @Input() sheetId = 'talent-changelog' + randomId();
  @Output() sheetClosed = new EventEmitter<string>();
  @Input({ required: true })
  set talentId(talentId: string) {
    this.service.talentId$.next(talentId);
  }

  ngAfterViewInit() {
    setTimeout$(() => {
      this.openSheet().then();
    });
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }

  trackById(index: number, item: PreparedTalentChangeLog): any {
    return item._id;
  }
}
