import { ArrayActionEnum } from '../../../../graphql/generated';
import { ArrayUpdateObjectType } from '../../talent-sheet/talent-sheet.types';

export function getCurrentPreviousValue<T extends ArrayUpdateObjectType>(
  newInput: T,
  revertInput: T | undefined | null,
  displayFn: (value: T) => string,
) {
  switch (newInput.type) {
    case ArrayActionEnum.REMOVED: {
      return {
        previousValue: revertInput
          ? displayFn(revertInput)
          : 'MISSING PREVIOUS VALUE',
        currentValue: '',
      };
    }
    case ArrayActionEnum.ADDED: {
      return {
        previousValue: '',
        currentValue: displayFn(newInput),
      };
    }
    case ArrayActionEnum.CHANGED: {
      return {
        previousValue: revertInput
          ? displayFn(revertInput)
          : 'MISSING PREVIOUS VALUE',
        currentValue: displayFn(newInput),
      };
    }
  }
}
