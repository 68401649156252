import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { setTimeout$, SheetService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UniBaseXVacancyUpdateInput,
  VacancyChangelog,
  VacancyDetailFragment,
  VacancyPublishableFieldsEnum,
  VacancyStatusEnum,
} from '../../../graphql/generated';
import { VacanciesService } from '../../../models/unibase/vacancies/vacancies.service';
import { randomId } from '../../helpers/functions/randomId';
import {
  SubmitVacancyUpdateInput,
  VacancyHistoryEntryFragmentForLocal,
} from '../../../pages/vacancies/vacancy.types';
import { Writable } from 'type-fest';
import { ToastService } from '@intemp/unijob-ui2';

@Component({
  selector: 'app-vacancy-changelog',
  templateUrl: './vacancy-changelog.component.html',
  styleUrls: ['./vacancy-changelog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyChangelogComponent implements AfterViewInit, OnDestroy {
  @Input() sheetId = 'vacancy-changelog' + randomId();
  @Output() sheetClosed = new EventEmitter<string>();
  @Input({ required: true }) vacancyId!: string;

  destroyed$ = new Subject<void>();
  constructor(
    public sheetService: SheetService,
    private vacanciesService: VacanciesService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe,
  ) {}

  reFetchVacancy$ = new BehaviorSubject<void>(undefined);

  vacancyHistoryList$: Observable<{
    entries: readonly VacancyHistoryEntryFragmentForLocal[];
    vacancy: VacancyDetailFragment;
  }> = this.reFetchVacancy$.pipe(
    takeUntil(this.destroyed$),
    switchMap(() =>
      combineLatest([
        this.vacanciesService.getVacancy$(this.vacancyId),
        this.vacanciesService.getVacancyHistory(this.vacancyId),
      ]).pipe(
        map(([vacancy, vacancyHistory]) => {
          if (
            !vacancy.uniBaseX?.unpublishedChanges ||
            ![VacancyStatusEnum.LIVE, VacancyStatusEnum.SCHEDULED].includes(
              vacancy.status,
            )
          ) {
            return { entries: vacancyHistory, vacancy };
          }

          const alreadyHandledFields: string[] = [];
          const updatedHistoryList = vacancyHistory.map(
            (entry): VacancyHistoryEntryFragmentForLocal => {
              if (
                !vacancy.uniBaseX?.unpublishedChanges ||
                !entry.changelogEntry?.fieldName
              ) {
                return entry;
              } else if (
                hasFieldNameOrGroupName(
                  vacancy.uniBaseX?.unpublishedChanges,
                  entry.changelogEntry,
                ) &&
                !alreadyHandledFields.includes(entry.changelogEntry.fieldName)
              ) {
                alreadyHandledFields.push(entry.changelogEntry.fieldName);
                return {
                  ...entry,
                  isUnpublishedChange: true,
                };
              } else {
                return entry;
              }
            },
          );
          return { entries: updatedHistoryList, vacancy };
        }),
      ),
    ),
  );

  ngAfterViewInit() {
    setTimeout$(() => {
      this.openSheet().then();
    });
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }

  revertRecord(input: SubmitVacancyUpdateInput) {
    const inputData: Writable<UniBaseXVacancyUpdateInput> = {
      ...input,
      vacancyUuid: this.vacancyId,
    };
    if (inputData.companyLocationUuid) {
      // reset contact & adress if the company is changed
      inputData.companyContactUuid = null;
      inputData.jobLocationAddress = {
        street: null,
        zip: null,
        location: null,
      };
    }

    this.vacanciesService.queueVacancyUpdate(inputData).subscribe((result) => {
      if (result) {
        this.reFetchVacancy$.next();
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('changeReverted'),
          duration: 6000,
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

function hasFieldNameOrGroupName(
  unpublishedChanges: readonly VacancyPublishableFieldsEnum[],
  changelogEntry: VacancyChangelog,
): boolean {
  return (
    unpublishedChanges.includes(
      changelogEntry.fieldName as VacancyPublishableFieldsEnum,
    ) ||
    unpublishedChanges.includes(
      changelogEntry.groupName as VacancyPublishableFieldsEnum,
    )
  );
}
