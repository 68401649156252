import {
  AfterViewInit,
  Component,
  Input,
  Optional,
  Self,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  ContactCriteriaFieldEnum,
  ContactFragment,
  FilterCriteriaConditionEnum,
} from '../../../../graphql/generated';
import { ContactsService } from '../../../../models/unibase/contacts/contacts.service';
import { NoZeroPipe } from '../../../../shared/helpers/pipes/noZero.pipe';
import {
  BigSelectAction,
  BigSelectComponent,
} from '../../big-select/big-select.component';
import { VacancyUnibaseContactImportComponent } from './unibase-contact-import/unibase-contact-import.component';
import {
  ISelectedItem,
  NewStateGenerator,
  Select2State,
  SelectComponent,
  singleSelectBehavior,
  TextboxListItemComponent,
} from '@intemp/unijob-ui2';
import { AvatarSelectedItemComponent } from '../company-location-select/avatar-selected-item/avatar-selected-item.component';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { TitleTextValue } from '../company-location-select/avatar-list-item/avatar-list-item.component';
import { CompanyLocationAvatar } from '../company-location-select/CompanyLocationAvatar';
import { flow, HashMap, identity, pipe, ReadonlyArray, String } from 'effect';
import { CompanyContactAvatar } from './CompanyContactAvatar';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-vacancy-company-contact-select',
  templateUrl: './vacancy-company-contact-select.component.html',
})
export class VacancyCompanyContactSelectComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() id = 'contact';
  @Input() companyLocationUuid: string | undefined;
  @Input() hasError = false;
  @ViewChild(BigSelectComponent) bigSelectComponent?: BigSelectComponent;
  @ViewChild(VacancyUnibaseContactImportComponent)
  unibaseContactImportComponent?: VacancyUnibaseContactImportComponent;

  @ViewChild(SelectComponent)
  select2!: SelectComponent<
    string,
    CompanyLocationAvatar,
    string,
    ISelectedItem<string, CompanyLocationAvatar>,
    ISelectedItem<string, CompanyLocationAvatar>
  >;

  @ViewChild(TextboxListItemComponent)
  searchListItem!: TextboxListItemComponent<
    string,
    CompanyLocationAvatar,
    string
  >;

  @ViewChild('placeholderAvatar', { read: TemplateRef, static: true })
  avatar!: TemplateRef<any>;

  chosenOption?: ContactFragment;
  /**
   * TODO: Loading state is not currently implemented
   */
  loading = false;
  disabled = false;

  chosenKey: Subject<string | undefined> = new BehaviorSubject<
    undefined | string
  >(undefined);

  importUnibaseContactSheet = {
    opened: false,
    open: () => {
      this.unibaseContactImportComponent?.openSheet();
      this.unibaseContactImportComponent?.focusSearchField();
      this.searchListItem.valueUpdated$
        .pipe(take(1))
        .subscribe((searchTerm) => {
          this.unibaseContactImportComponent?.setSearchTerm(searchTerm);
        });
    },
    close: () => {
      this.importUnibaseContactSheet.opened = false;
    },
    complete: (contact: ContactFragment) => {
      this.writeValue(contact);
      this.onChange(contact);
      this.unibaseContactImportComponent?.closeSheet();
    },
  };
  private contactWithData$: Subject<
    HashMap.HashMap<
      string,
      // TODO: Extract this type and document it (-> also in vacancy company location select)
      {
        contactFragment: ContactFragment;
        contactLocationAvatar: CompanyContactAvatar;
      }
    >
  > = new ReplaySubject(1);
  options: Observable<CompanyContactAvatar[]> = this.contactWithData$.pipe(
    map(
      flow(
        HashMap.values,
        ReadonlyArray.fromIterable,
        ReadonlyArray.map(({ contactLocationAvatar }) => contactLocationAvatar),
      ),
    ),
  );
  searchTermProvided$ = new ReplaySubject<boolean>(1);

  constructor(
    private contactsService: ContactsService,
    private noZeroPipe: NoZeroPipe,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get invalid(): boolean {
    return this.ngControl?.invalid ?? false;
  }

  get showError(): boolean {
    if (!this.ngControl) {
      return false;
    }
    const { dirty, touched } = this.ngControl;

    return this.invalid ? (dirty ?? false) || (touched ?? false) : false;
  }

  get selectValidation() {
    return this.showError && this.invalid ? 'invalid' : 'unvalidated';
  }

  ngAfterViewInit(): void {
    this.searchListItem.valueUpdated$
      .pipe(
        map((searchTerm) => searchTerm !== ''),
        distinctUntilChanged(),
      )
      .subscribe(this.searchTermProvided$);

    this.select2._isExpanded.pipe(filter(identity<boolean>)).subscribe(() => {
      this.searchContacts('');
    });
    this.searchListItem.valueUpdated$.subscribe((searchTerm: string) => {
      this.searchContacts(searchTerm);
    });
  }

  public onChange = (value: ContactFragment | null): ContactFragment | null =>
    value;

  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * TODO: Find out possible values of the contactFragment (null and undefined, meaning?)
   * @param contactFragment
   */
  async writeValue(contactFragment?: ContactFragment | null): Promise<void> {
    this.chosenKey.next(contactFragment?.uuid);
    if (contactFragment !== null && contactFragment !== undefined) {
      this.contactWithData$.next(
        HashMap.make([
          contactFragment.uuid,
          {
            contactFragment,
            contactLocationAvatar: new CompanyContactAvatar(
              contactFragment,
              this.noZeroPipe,
              this.avatar,
            ),
          },
        ]),
      );
    }
  }

  actions: BigSelectAction[] = [];

  newKeyChosen = (key?: string) => {
    if (key !== undefined) {
      this.contactWithData$
        .pipe(take(1))
        .subscribe((contactData) =>
          this.onChange(HashMap.unsafeGet(contactData, key).contactFragment),
        );
    } else {
      this.onChange(null);
    }
  };

  // TODO: Extract a function to simplify this and only need to supply the function within HashMap.filter
  readonly searchFilter: (
    value: string,
  ) => NewStateGenerator<string, TitleTextValue, string> =
    (searchValue: string) =>
    (state: Select2State<string, TitleTextValue, string>) => ({
      ...state,
      allItems: state.allItems,
      hiddenItems: pipe(
        state.allItems,
        HashMap.filter<string, TitleTextValue>(
          (value) =>
            !String.includes(String.toLowerCase(searchValue))(
              String.toLowerCase(value.title),
            ) &&
            !String.includes(String.toLowerCase(searchValue))(
              String.toLowerCase(value.text),
            ),
        ),
        HashMap.keys,
        ReadonlyArray.fromIterable,
      ),
      decoration: searchValue,
    });

  async searchContacts(searchTerm: string): Promise<void> {
    if (!this.companyLocationUuid) {
      return;
    }
    this.loading = true;

    const observable = await this.contactsService.contactsQuery({
      filter: {
        criteria: [
          {
            field: ContactCriteriaFieldEnum.SEARCH,
            condition: FilterCriteriaConditionEnum.CONTAINS,
            values: searchTerm.split(' '),
          },
          {
            field: ContactCriteriaFieldEnum.COMPANY_LOCATION_UUID,
            condition: FilterCriteriaConditionEnum.EQ,
            values: [this.companyLocationUuid],
          },
        ],
      },
    });
    observable.subscribe((result) => {
      this.loading = false;
      this.contactWithData$.next(
        pipe(
          result.items ?? [],
          ReadonlyArray.map(
            (contactFragment) =>
              [
                contactFragment.uuid,
                {
                  contactFragment,
                  contactLocationAvatar: new CompanyContactAvatar(
                    contactFragment,
                    this.noZeroPipe,
                    this.avatar,
                  ),
                },
              ] as const,
          ),
          HashMap.fromIterable,
        ),
      );
    });
  }

  protected readonly singleSelectBehavior = singleSelectBehavior;
  protected readonly selectedComponent: Type<
    ISelectedItem<string, TitleTextValue>
  > = AvatarSelectedItemComponent;

  trackByKey(index: number, hasKey: CompanyContactAvatar) {
    return hasKey.key;
  }
}
