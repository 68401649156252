// Display  functions

import {
  ArrayActionEnum,
  TalentAddressInput,
  TalentChildrenInput,
  TalentDriversLicenseInput,
  TalentEmailsInput,
  TalentPageInput,
  TalentPhonesInput,
  TalentPreferredLocationsInput,
  TalentRecommendedByInput,
  TalentSourcesInput,
  TalentUpdateInput,
  TalentWorkArrangementInput,
} from '../../../../graphql/generated';
import i18next from 'i18next';
import { CountriesService } from '../../../../core/services/countries.service';
import { TalentArrayFields } from '../../talent-sheet/talent-sheet.types';
import { format } from 'date-fns';

export function childrenDisplayFn(child: TalentChildrenInput) {
  return child.firstName + ' ' + child.lastName + ', ' + child?.yearOfBirth;
}

export function getAddressDisplayFn(countriesService: CountriesService) {
  return (address: TalentAddressInput) => {
    return `${address.streetAndNumber}, ${address.zip} ${address.location}, ${countriesService.getCountryByCode(address.country)?.label}`;
  };
}

export function phoneDisplayFn(item: TalentPhonesInput) {
  const type = i18next.t('ContactPhoneTypeEnum.' + item.phoneType);
  return `${item.phone} - ${type} ${item.isPrimary ? '- ' + i18next.t('primary') : ''}`;
}

export function emailDisplayFn(item: TalentEmailsInput) {
  const type = i18next.t('ContactEmailTypeEnum.' + item.emailType);
  return `${item.email} - ${type} ${item.isPrimary ? '- ' + i18next.t('primary') : ''}`;
}

export function sourceDisplayFn(item: TalentSourcesInput) {
  if (item.platform) {
    return (
      i18next.t('TalentSourcePlatformEnum.' + item.platform) +
      ', ' +
      format(new Date(item.contactAt), 'dd.MM.yyyy  - HH:mm')
    );
  } else {
    return (
      i18next.t('TalentSourceTypeEnum.' + item.sourceType) +
      ', ' +
      format(new Date(item.contactAt), 'dd.MM.yyyy')
    );
  }
}

export function recommendedByDisplayFn(item: TalentRecommendedByInput) {
  return `${item.firstName} ${item.lastName} - ${item.email}`;
}

export function preferredLocationsDisplayFn(
  item: TalentPreferredLocationsInput,
) {
  return `${item.location} ${item.radiusKm ? '- ' + item.radiusKm + ' km' : ''}`;
}

export function driversLicenseDisplayFn(item: TalentDriversLicenseInput) {
  return i18next.t('DriversLicenseEnum.' + item.license);
}

export function workArrangementDisplayFn(item: TalentWorkArrangementInput) {
  return i18next.t('WorkArrangementEnum.' + item.arrangement);
}

export function pageDisplayFn(input: TalentPageInput) {
  if (input.type === ArrayActionEnum.REMOVED) {
    return input.title ?? '';
  }
  if (input.title !== null && input.title !== undefined) {
    return input.title ?? '';
  }
  if (input.active !== null && input.active !== undefined) {
    return input.active ? 'Ungeprüft' : 'Geprüft';
  }
  if (input.favorite !== null && input.favorite !== undefined) {
    return input.favorite ? 'Favorit' : 'Nicht Favorit';
  }
  if (input.moveToIndex !== null && input.moveToIndex !== undefined) {
    return `Rang ${input.moveToIndex}`;
  }
  return '...';
}

export function fileDisplayFn(input: TalentPageInput) {
  if (input.title !== null && input.title !== undefined) {
    return input.title ?? '';
  }
  return '...';
}

export function getChangelogDisplayFunctions(
  countriesService: CountriesService,
): {
  [key in keyof TalentArrayFields]: (
    input: NonNullable<TalentUpdateInput[key]>[number],
    countriesService: CountriesService,
  ) => string;
} {
  return {
    children: childrenDisplayFn,
    addresses: getAddressDisplayFn(countriesService),
    phones: phoneDisplayFn,
    emails: emailDisplayFn,
    links: (item) => item.url,
    sources: sourceDisplayFn,
    recommendedBy: recommendedByDisplayFn,
    driversLicense: driversLicenseDisplayFn,
    targetJobTitle: (item) => item.title,
    workArrangement: workArrangementDisplayFn,
    preferredLocations: preferredLocationsDisplayFn,
    pages: pageDisplayFn,
    files: fileDisplayFn,
  };
}
