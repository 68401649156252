import { Component, Input, TemplateRef } from '@angular/core';
import {
  ButtonTextComponent,
  ISelectedItem,
  SelectedItem,
} from '@intemp/unijob-ui2';
import { filter } from 'rxjs/operators';
import { filterIsNotEmpty } from '../../../../helpers/functions/filterIsNotEmpty';
import { UserService } from '../../../../../models/shared/user/user.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContextMenuModule, TooltipModule } from '@intemp/unijob-ui';
import { UserAvatarModule } from '../../../user-avatar/user-avatar.module';
import { TitleTextValue } from '../avatar-list-item/avatar-list-item.component';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { BigSelectAction } from '../../../big-select/big-select.component';

// TODO: Move this somewhere else
export type TitleAndTextValueWithActionsAndAvatar = TitleTextValue & {
  avatarTemplateRef: TemplateRef<any>;
  actions: BigSelectAction[];
};

@Component({
  selector: 'app-avatar-selected-item',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    UserAvatarModule,
    I18NextModule,
    SharedDefaultModule,
    TooltipModule,
    ContextMenuModule,
    ButtonTextComponent,
  ],
  templateUrl: './avatar-selected-item.component.html',
  styleUrl: './avatar-selected-item.component.scss',
})
export class AvatarSelectedItemComponent
  implements ISelectedItem<string, TitleAndTextValueWithActionsAndAvatar>
{
  @Input()
  size = 'M';
  get firstItem(): TitleAndTextValueWithActionsAndAvatar {
    return this.selectedItems[0].label;
  }

  @Input({ required: true })
  selectedItems!: [
    SelectedItem<string, TitleAndTextValueWithActionsAndAvatar>,
    ...SelectedItem<string, TitleAndTextValueWithActionsAndAvatar>[],
  ];

  loggedInUser$ = this.userService.user$.pipe(filter(filterIsNotEmpty));

  constructor(private userService: UserService) {}
}
