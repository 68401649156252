import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DatePickerModule,
  InputTextModule,
  SwitchModule,
} from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import { ArrayActionEnum } from '../../../../../../graphql/generated';
import { YearToAgePipe } from '../../../../../helpers/pipes/yearToAge.pipe';
import { randomId } from '../../../../../helpers/functions/randomId';
import { SourcesFormGroup } from '../../../talent-sheet.types';
import { TalentSharedService } from '../../../services/talent-shared.service';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import {
  AvatarComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  ModalComponent,
  ModalService,
  SimpleSelectComponent,
} from '@intemp/unijob-ui2';
import { getSourceTypeTextOptions } from '../../../../vacancy-detail/helpers/getSourceTypeTextOptions';
import { sourceToPlatformMap } from '../../../../../constants/source-to-platform-map';
import { platformIcons } from '../../../../../constants/platformIcons.constants';

@Component({
  standalone: true,
  selector: 'app-talent-sources-entry',
  templateUrl: './talent-source-entry.component.html',
  imports: [
    AvatarComponent,
    DatePickerModule,
    I18NextModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    SwitchModule,
    YearToAgePipe,
    SimpleSelectComponent,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    ButtonTextComponent,
    ModalComponent,
  ],
})
export class TalentSourceEntryComponent implements OnChanges {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentSharedService);
  modals = inject(ModalService);

  @Input({ required: true }) source!: FormGroup<SourcesFormGroup>;

  @Output() isInEditMode = new EventEmitter<boolean>();

  isEditingSource = false;

  deleteConfirmationModalId = 'deleteConfirmation' + randomId();
  sourceTypeOptions = getSourceTypeTextOptions();

  public platformsIconMap = platformIcons;

  isSourcePlatform = false;

  ngOnChanges(changes: SimpleChanges) {
    const isNewEntry = changes.source.currentValue.value.isNewEntry;
    if (isNewEntry) {
      this.setEditingSource(true);
    } else {
      this.isSourcePlatform = this.source.controls.platform.value !== null;
    }
  }

  deleteSource() {
    this.updateSource(this.source, ArrayActionEnum.REMOVED);
    this.formService.sources.removeAt(
      this.formService.sources.controls.indexOf(this.source),
    );
    this.setEditingSource(false);
  }

  editSource() {
    this.setEditingSource(true);
  }

  cancelEditSource() {
    const sources = this.formService.sources;
    sources.removeAt(sources.controls.indexOf(this.source));
    this.setEditingSource(false);
  }

  addSource() {
    this.updateSource(this.source, ArrayActionEnum.ADDED);
    this.source.controls.isNewEntry.setValue(false);
    this.setEditingSource(false);
  }

  updateSourceEntry() {
    this.updateSource(this.source, ArrayActionEnum.CHANGED);
    this.setEditingSource(false);
  }

  setEditingSource(value: boolean) {
    this.isEditingSource = value;
    this.isInEditMode.emit(value);
  }

  updateSource(
    sourceControl: FormGroup<SourcesFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const sourcesData = getFormData(sourceControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !sourcesData) {
      console.error('Talent uuid is not valid');
      return;
    }
    delete sourcesData.isNewEntry;
    sourcesData.contactAt = sourcesData.contactAt[0];
    sourcesData.type = arrayAction;

    this.formService
      .updateTalentApi({
        sources: [sourcesData],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }

  protected readonly platformsMap = sourceToPlatformMap;
}
