import {
  AfterViewInit,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  ModalService,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';
import {
  CheckboxModule,
  DatePickerModule,
  InfoBoxModule,
  InputTextareaModule,
  InputTextModule,
  ResponsiveService,
  SwitchModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { TalentTargetJobTitleComponent } from '../talent-target-job-title/talent-target-job-title.component';
import { TalentPreferencesEmploymentComponent } from '../talent-preferences-employment/talent-preferences-employment.component';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { SalaryUnitEnum } from '../../../../../graphql/generated';
import { FocusTrackerDirective } from '../../../vacancy-detail/focusTracker.directive';
import { getSalaryUnitTextOptions } from '../../../vacancy-detail/helpers/getSalaryUnitTextOptions';
import { allowedSalaryInput } from '../../../../helpers/functions/allowedSalaryInput';
import { parseValueToNumber } from '../../../../helpers/functions/parseValueToNumber';
import { getSalaryCurrencyTextOptions } from '../../../vacancy-detail/helpers/getSalaryCurrencyTextOptions';
import { salaryPaymentsPerYearOptions } from '../../../../constants/salaryPaymentsPerYearOptions';

@Component({
  standalone: true,
  selector: 'app-talent-salary-input',
  templateUrl: './talent-salary-input.component.html',
  imports: [
    ReactiveFormsModule,
    I18NextModule,
    SharedDefaultModule,
    SimpleSelectComponent,
    InfoBoxModule,
    DatePickerModule,
    InputTextareaModule,
    CheckboxModule,
    InputTextModule,
    TalentTargetJobTitleComponent,
    TalentPreferencesEmploymentComponent,
    UjSelectInputModule,
    SwitchModule,
    TextInputComponent,
  ],
  providers: [],
})
export class TalentSalaryInputComponent implements AfterViewInit {
  i18next = inject(I18NextPipe);
  formService = inject(TalentFormEditService);
  responsiveService = inject(ResponsiveService);
  modals = inject(ModalService);

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  salaryExpectation = this.formService.salaryExpectation;
  salaryExpectationMin = this.formService.salaryExpectationMin;
  salaryExpectationMax = this.formService.salaryExpectationMax;
  salaryExpectationCurrency = this.formService.salaryExpectationCurrency;
  salaryExpectationFlexible = this.formService.salaryExpectationFlexible;
  salaryExpectationUnit = this.formService.salaryExpectationUnit;
  salaryExpectationPaymentsPerYear =
    this.formService.salaryExpectationPaymentsPerYear;
  salaryExpectationHoursPerWeek =
    this.formService.salaryExpectationHoursPerWeek;

  hintErrorMessages: Record<string, string> = {};
  salaryUnitOptions = getSalaryUnitTextOptions(this.i18next);
  salaryCurrencyOptions = getSalaryCurrencyTextOptions();

  isXsDown$ = this.responsiveService.isXsDown$;

  ngAfterViewInit() {
    this.listenToFocusTrackerChange();
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }

  filterClipboardValue(event: ClipboardEvent) {
    const value = event.clipboardData?.getData('text');
    const hasForbiddenChar = value?.match('-');
    if (!value || !hasForbiddenChar) return;

    const focusedInputControlName = this.focusTrackers?.find(
      (f) => f.isFocused$.value,
    )?.targetID as keyof TalentSalaryInputComponent;
    if (focusedInputControlName) {
      const componentFormControl = this[
        focusedInputControlName
      ] as AbstractControl;
      if (!componentFormControl || !componentFormControl.setValue) {
        console.error(
          `targetId ${focusedInputControlName} not found as formControl of component`,
        );
        return;
      }
      const parsedValue = value.replace('-', '');
      componentFormControl.setValue(parseValueToNumber(parsedValue));
    }
    event.preventDefault();
  }

  protected readonly isValidSalaryInput = allowedSalaryInput;
  protected readonly SalaryUnitEnum = SalaryUnitEnum;
  protected readonly salaryPaymentsPerYearOptions =
    salaryPaymentsPerYearOptions;
}
