import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ToastService } from '@intemp/unijob-ui2';
import { I18NextPipe } from 'angular-i18next';
import { DATE_FORMAT } from '../../../../app.constants';
import {
  ChannelFragment,
  UniBaseXVacancyChannelConfigurationStatusEnum,
  UniBaseXVacancyChannelFragment,
  VacancyDetailFragment,
  VacancyStatusEnum,
} from '../../../../graphql/generated';
import { ChannelsService } from '../../../../models/unibase/channels/channels.service';
import { VacanciesService } from '../../../../models/unibase/vacancies/vacancies.service';
import { format } from 'date-fns';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../global-sheets/global-sheets.service';

@Component({
  selector: 'app-channel-box',
  templateUrl: './channel-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelBoxComponent {
  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService,
    private vacanciesService: VacanciesService,
    private i18nPipe: I18NextPipe,
    private channelsService: ChannelsService,
    private cd: ChangeDetectorRef,
    private globalSheetsService: GlobalSheetsService,
  ) {}

  @Input({ required: true }) public channel!: NonNullable<ChannelFragment>;
  public ConfigStatus = UniBaseXVacancyChannelConfigurationStatusEnum;

  @Input({ required: true })
  public vacancy!: VacancyDetailFragment;

  // optimistic toggle state of switch
  private _switchState = false;
  get switchState(): boolean {
    return this._switchState;
  }
  // to disable switch while switch-action is running
  public isSwitching = false;

  private _configs: readonly UniBaseXVacancyChannelFragment[] = [];
  @Input({ required: true })
  set configs(value: UniBaseXVacancyChannelFragment[]) {
    this._configs = value ?? [];
    // set switch state based on most recent configuration
    this._switchState = ['PENDING', 'RUNNING'].includes(
      this.recentConfig()?.status || '',
    );
  }
  get configs(): readonly UniBaseXVacancyChannelFragment[] {
    return this._configs;
  }

  protected isBoxDisabled() {
    return [VacancyStatusEnum.ARCHIVED, VacancyStatusEnum.DELETED].includes(
      this.vacancy.status,
    );
  }

  protected channelType() {
    return ChannelsService.getChannelType(this.channel);
  }

  protected recentConfig() {
    return ChannelsService.getMostRecentConfig(this._configs);
  }

  protected hasExternalLink() {
    if (this.channelType() === 'universaljobScreen') {
      return true;
    }
    if (this.channelType() === 'universaljobInternal') {
      return this.channel.url !== null;
    }
    if (this.channelType() === 'universaljob') {
      return (
        this.recentConfig()?.status ===
        UniBaseXVacancyChannelConfigurationStatusEnum.RUNNING
      );
    }
    return false;
  }

  protected copyExternalLinkToClipboard() {
    if (!this.hasExternalLink()) {
      return;
    }
    switch (this.channelType()) {
      case 'universaljob':
        this.clipboard.copy(
          this.vacanciesService.getVacancyUniversaljobUrl(
            this.vacancy?.public?.uuid?.[0],
          ),
        );
        break;
      case 'universaljobScreen':
        this.clipboard.copy(
          ChannelsService.getScreenUrl(
            this.vacancy?.public?.uuid?.[0],
            this.channel.uuid,
          ),
        );
        break;
    }

    this.toastService.makeToast({
      type: 'SUCCESS',
      message: this.i18nPipe.transform('linkCopied'),
      duration: 6000,
    });
  }

  protected openExternalLinkInNewTab() {
    if (!this.hasExternalLink()) {
      return;
    }
    switch (this.channelType()) {
      case 'universaljob':
        window.open(
          this.vacanciesService.getVacancyUniversaljobUrl(
            this.vacancy?.public?.uuid?.[0],
          ),
          '_blank',
        );
        break;
      case 'universaljobInternal':
        window.open(this.channel?.url ?? '?', '_blank');
        break;
      case 'universaljobScreen':
        window.open(
          ChannelsService.getScreenUrl(
            this.vacancy?.public?.uuid?.[0],
            this.channel.uuid,
          ),
          '_blank',
        );
        break;
    }
  }

  protected switchVisible() {
    return !this.channel.forcedActivation;
  }

  protected switchDisabledReason = '';

  protected settingsDisabled() {
    return (
      !this.vacancy.iAmResponsible ||
      [
        VacancyStatusEnum.ARCHIVED,
        VacancyStatusEnum.DELETED,
        VacancyStatusEnum.SCHEDULED,
      ].includes(this.vacancy.status)
    );
  }

  get settingsDisabledReason() {
    if (
      !this.vacancy.iAmResponsible &&
      ![
        VacancyStatusEnum.ARCHIVED,
        VacancyStatusEnum.DELETED,
        VacancyStatusEnum.SCHEDULED,
      ].includes(this.vacancy.status)
    ) {
      return this.i18nPipe.transform('onlyResponsibleCanChangeChannelConfig');
    }
    return '';
  }

  protected switchDisabled() {
    if (this.channel.forcedActivation) {
      return true;
    }
    if (
      this.recentConfig()?.status === 'RUNNING' &&
      !this.channel.manuallyCompletable
    ) {
      return true;
    }
    if (
      [
        VacancyStatusEnum.ARCHIVED,
        VacancyStatusEnum.DELETED,
        VacancyStatusEnum.SCHEDULED,
      ].includes(this.vacancy.status)
    ) {
      return true;
    }
    if (
      this.channel.maxRunningAndPendingSlotsPerUser &&
      !this.vacancy.iAmResponsible
    ) {
      this.switchDisabledReason = this.i18nPipe.transform(
        'onlyResponsibleCanChangeChannelConfig',
      );
      return true;
    }
    if (!this.vacancy.permissions?.canEdit) {
      this.switchDisabledReason = this.i18nPipe.transform(
        'onlyUserOfSameBranchCanChannelConfig',
      );
      return true;
    }
    if (
      this._switchState === false &&
      this.channel.maxRunningAndPendingSlotsPerUser &&
      (this.channel?.runningAndPendingSlotsTakenFromMe ?? 0) >=
        this.channel.maxRunningAndPendingSlotsPerUser
    ) {
      this.switchDisabledReason = this.i18nPipe.transform(
        'maxSlotsPerUserReached',
      );
      return true;
    }
    if (
      this.channel.maxRunningAndPendingSlotsTotal &&
      this._switchState === false &&
      (this.channel.runningAndPendingSlotsTotalTaken ?? 0) >=
        this.channel.maxRunningAndPendingSlotsTotal
    ) {
      this.switchDisabledReason = this.i18nPipe.transform(
        'maxSlotsTotalReached',
      );
      return true;
    }

    return false;
  }

  protected showConfigLights() {
    return true;
  }

  protected handleSwitchChange(switchIsOn: boolean) {
    if (this.switchDisabled()) {
      return;
    }

    this.isSwitching = true;

    if (switchIsOn && this.vacancy.status === VacancyStatusEnum.LIVE) {
      this.channelsService
        .liveActivate({
          channelUuid: this.channel.uuid,
          exportSettings: {},
          vacancyUuid: this.vacancy.uuid,
        })
        .subscribe();
    } else if (switchIsOn && this.vacancy.status !== VacancyStatusEnum.LIVE) {
      this.channelsService
        .draftChannel({
          channelUuid: this.channel.uuid,
          exportSettings: {},
          vacancyUuid: this.vacancy.uuid,
        })
        .subscribe();
    } else if (!switchIsOn) {
      this.channelsService
        .completeChannel({
          channelUuid: this.channel.uuid,
          vacancyUuid: this.vacancy.uuid,
        })
        .subscribe();
    }

    // to avoid double clicks and mass toggling
    setTimeout(() => {
      this.isSwitching = false;
      this.cd.markForCheck();
    }, 1000);
  }

  protected stateWording(config?: UniBaseXVacancyChannelFragment) {
    if (!config) {
      return '';
    }
    if (config.status === 'PENDING' && this.vacancy.status === 'DRAFT') {
      return this.i18nPipe.transform('willBePublishedAfter');
    }
    if (config.status === 'RUNNING') {
      const startDateStr = config?.runtimes?.[0]?.start;
      const startDate = startDateStr ? new Date(startDateStr) : new Date();
      return this.i18nPipe.transform(
        'activeSince',
        ChannelsService.daysFromDateFormatted(startDate),
      );
    }
    // special rendering for final completed item (no range)
    if (
      config.status === 'COMPLETED' &&
      this.recentConfig() === config &&
      this.vacancy.status !== 'ARCHIVED' &&
      this.vacancy.status !== 'DELETED'
    ) {
      const startDateStr = config?.runtimes?.[0]?.start;
      const startDate = startDateStr ? new Date(startDateStr) : new Date();
      return this.i18nPipe.transform(
        'inactiveSince',
        ChannelsService.daysFromDateFormatted(startDate),
      );
    }

    if (config.status === 'COMPLETED' && this.recentConfig() !== config) {
      const startDateStr = config?.runtimes?.[0]?.start;
      const startDate = startDateStr ? new Date(startDateStr) : new Date();
      const endDateStr = config?.runtimes?.[0]?.end;
      const endDate = endDateStr ? new Date(endDateStr) : new Date();
      return this.i18nPipe.transform('activeRuntime', {
        from: format(startDate, DATE_FORMAT),
        until: format(endDate, DATE_FORMAT),
      });
    }
  }

  protected neverConfiguredWording() {
    if (
      [VacancyStatusEnum.ARCHIVED, VacancyStatusEnum.DELETED].includes(
        this.vacancy.status,
      )
    ) {
      return this.i18nPipe.transform('hasNotBeenActivated');
    }
    if (
      VacancyStatusEnum.DRAFT === this.vacancy.status &&
      this._configs.length === 0
    ) {
      return this.i18nPipe.transform('willNotBePublished');
    }
  }

  protected light(config?: UniBaseXVacancyChannelFragment) {
    if (config?.status === 'PENDING') {
      return 'yellow';
    }
    if (config?.status === 'RUNNING') {
      return 'green';
    }
    return 'grey';
  }

  protected openSlotsSheet() {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.CHANNEL_SLOTS,
      uuid: this.channel.uuid,
    });
  }
}
