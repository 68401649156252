import { CountriesService } from '../../../../core/services/countries.service';
import {
  TalentArrayFields,
  TalentBooleanFields,
  TalentImageFields,
  TalentProsMirroredFields,
  TalentStringFields,
} from '../../talent-sheet/talent-sheet.types';
import {
  TalentFragment,
  TalentHistoryEntryFragment,
  TalentUpdateInput,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { prepareTalentChangLogString } from '../helpers/prepareTalentChangLogString';
import { prepareTalentChangLogForTypeBoolean } from '../helpers/prepareTalentChangLogForTypeBoolean';
import { prepareTalentChangLogForArrayField } from '../helpers/prepareTalentChangLogForArrayField';
import { Injectable } from '@angular/core';
import { prepareTalentChangLogImage } from '../helpers/prepareTalentChangLogImage';
import { prepareTalentChangLogForTypeProseMirror } from '../helpers/prepareTalentChangLogForTypeProseMirror';

@Injectable()
export class ChangelogTalentHelperService {
  constructor() {}

  talentFieldsTypeString: {
    [key in keyof TalentStringFields]: (
      entry: TalentHistoryEntryFragment,
      fieldName: keyof TalentStringFields,
      talent: TalentFragment,
      input: TalentUpdateInput,
      countriesService: CountriesService,
    ) => PreparedTalentChangeLog;
  } = {
    firstName: prepareTalentChangLogString,
    lastName: prepareTalentChangLogString,
    employmentType: prepareTalentChangLogString,
    salaryExpectationUnit: prepareTalentChangLogString,
    salaryExpectationCurrency: prepareTalentChangLogString,
    talentNumber: prepareTalentChangLogString,
    customerUuid: prepareTalentChangLogString,
    sex: prepareTalentChangLogString,
    dateOfBirth: prepareTalentChangLogString,
    placeOfBirth: prepareTalentChangLogString,
    nationality: prepareTalentChangLogString,
    civilOrigin: prepareTalentChangLogString,
    residencePermit: prepareTalentChangLogString,
    residencePermitValidUntil: prepareTalentChangLogString,
    religiousDenomination: prepareTalentChangLogString,
    maritalStatus: prepareTalentChangLogString,
    ahvNumber: prepareTalentChangLogString,
    iban: prepareTalentChangLogString,
    workAvailability: prepareTalentChangLogString,
    blackListedReason: prepareTalentChangLogString,
    availableForWorkAt: prepareTalentChangLogString,
    nextMilitaryServiceAt: prepareTalentChangLogString,
    availabilityNote: prepareTalentChangLogString,
    hasAllowedDataUsageAt: prepareTalentChangLogString,
    workloadPercentageMin: prepareTalentChangLogString,
    workloadPercentageMax: prepareTalentChangLogString,
    workloadPercentage: prepareTalentChangLogString,
    salaryExpectation: prepareTalentChangLogString,
    salaryExpectationMin: prepareTalentChangLogString,
    salaryExpectationMax: prepareTalentChangLogString,
    maxCommutingTimeMinutes: prepareTalentChangLogString,
    preferenceNote: prepareTalentChangLogString,
    salaryExpectationHoursPerWeek: prepareTalentChangLogString,
    salaryExpectationPaymentsPerYear: prepareTalentChangLogString,
    employmentCompany: prepareTalentChangLogString,
    employedSince: prepareTalentChangLogString,
    employedUntil: prepareTalentChangLogString,
    communicationLanguage: prepareTalentChangLogString,
  };

  talentFieldsTypeBoolean: {
    [key in keyof TalentBooleanFields]: (
      entry: TalentHistoryEntryFragment,
      fieldName: keyof TalentBooleanFields,
    ) => PreparedTalentChangeLog;
  } = {
    hasVehicle: prepareTalentChangLogForTypeBoolean,
    canEdit: prepareTalentChangLogForTypeBoolean,
    registeredWithRAV: prepareTalentChangLogForTypeBoolean,
    nonCompeteAgreement: prepareTalentChangLogForTypeBoolean,
    mustServeMilitaryService: prepareTalentChangLogForTypeBoolean,
    workloadFlexible: prepareTalentChangLogForTypeBoolean,
    salaryExpectationFlexible: prepareTalentChangLogForTypeBoolean,
    commutingByBicycle: prepareTalentChangLogForTypeBoolean,
    commutingByCar: prepareTalentChangLogForTypeBoolean,
    commutingByPublicTransport: prepareTalentChangLogForTypeBoolean,
  };

  talentFieldsTypeArray: {
    [key in keyof TalentArrayFields]: (
      entry: TalentHistoryEntryFragment,
      fieldName: keyof TalentArrayFields,
      talent: TalentFragment,
      input: TalentUpdateInput,
      countriesService: CountriesService,
    ) => PreparedTalentChangeLog;
  } = {
    children: prepareTalentChangLogForArrayField,
    addresses: prepareTalentChangLogForArrayField,
    phones: prepareTalentChangLogForArrayField,
    emails: prepareTalentChangLogForArrayField,
    links: prepareTalentChangLogForArrayField,
    sources: prepareTalentChangLogForArrayField,
    recommendedBy: prepareTalentChangLogForArrayField,
    targetJobTitle: prepareTalentChangLogForArrayField,
    workArrangement: prepareTalentChangLogForArrayField,
    driversLicense: prepareTalentChangLogForArrayField,
    preferredLocations: prepareTalentChangLogForArrayField,
    pages: prepareTalentChangLogForArrayField,
    files: prepareTalentChangLogForArrayField,
  };

  talentFieldsTypeImage: {
    [key in keyof TalentImageFields]: (
      entry: TalentHistoryEntryFragment,
      fieldName: keyof TalentImageFields,
      talent: TalentFragment,
    ) => PreparedTalentChangeLog;
  } = {
    avatar: prepareTalentChangLogImage,
  };

  talentProseMirrorFields: {
    [key in keyof TalentProsMirroredFields]: (
      entry: TalentHistoryEntryFragment,
      fieldName: keyof TalentProsMirroredFields,
      talent: TalentFragment,
      input: TalentUpdateInput,
    ) => PreparedTalentChangeLog;
  } = {
    internalNotes: prepareTalentChangLogForTypeProseMirror,
  };

  talentFields = {
    ...this.talentFieldsTypeString,
    ...this.talentFieldsTypeBoolean,
    ...this.talentFieldsTypeArray,
    ...this.talentFieldsTypeImage,
    ...this.talentProseMirrorFields,
  } as const;
}
