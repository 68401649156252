import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { WorkArrangementEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getWorkArrangementOptions =
  (): SelectOption<WorkArrangementEnum>[] => {
    return [
      {
        value: WorkArrangementEnum.FIELD_SERVICE,
        name: i18next.t('WorkArrangementEnum.FIELD_SERVICE'),
      },

      {
        value: WorkArrangementEnum.ON_CALL_DUTY,
        name: i18next.t('WorkArrangementEnum.ON_CALL_DUTY'),
      },

      {
        value: WorkArrangementEnum.POOL,
        name: i18next.t('WorkArrangementEnum.POOL'),
      },
      {
        value: WorkArrangementEnum.REMOTE,
        name: i18next.t('WorkArrangementEnum.REMOTE'),
      },

      {
        value: WorkArrangementEnum.SHIFT_WORK,
        name: i18next.t('WorkArrangementEnum.SHIFT_WORK'),
      },
      {
        value: WorkArrangementEnum.ON_SITE,
        name: i18next.t('WorkArrangementEnum.ON_SITE'),
      },
    ];
  };
