import { VacancyArchiveReasonEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getArchiveReasonsTextOptions = (i18next: I18NextPipe) => {
  return [
    {
      value: VacancyArchiveReasonEnum.WE_FOUND_PLACEMENT,
      label:
        i18next.transform('VacancyArchiveReasonEnum.WE_FOUND_PLACEMENT') +
        ' 🤘',
    },
    {
      value: VacancyArchiveReasonEnum.COMPANY_FOUND_PLACEMENT,
      label: i18next.transform(
        'VacancyArchiveReasonEnum.COMPANY_FOUND_PLACEMENT',
      ),
    },
    {
      value: VacancyArchiveReasonEnum.COMPANY_RECALLED,
      label: i18next.transform('VacancyArchiveReasonEnum.COMPANY_RECALLED'),
    },
    {
      value: VacancyArchiveReasonEnum.OTHER_FOUND_PLACEMENT,
      label:
        i18next.transform('VacancyArchiveReasonEnum.OTHER_FOUND_PLACEMENT') +
        ' 😫',
    },
    {
      value: VacancyArchiveReasonEnum.ENOUGH_APPLICANTS_RECEIVED,
      label: i18next.transform(
        'VacancyArchiveReasonEnum.ENOUGH_APPLICANTS_RECEIVED',
      ),
    },
    {
      value: VacancyArchiveReasonEnum.FALSE_ACTIVATION_OR_DUPLICATE,
      label: i18next.transform(
        'VacancyArchiveReasonEnum.FALSE_ACTIVATION_OR_DUPLICATE',
      ),
    },
    {
      value: VacancyArchiveReasonEnum.OTHER,
      label: i18next.transform('VacancyArchiveReasonEnum.OTHER'),
    },
  ] as const;
};
