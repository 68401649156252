<ui-library-simple-select
  class="uj-grow"
  size="M"
  [options]="archiveReasonsOptions"
  [placeholderText]="'selectReason' | i18next"
  design="outline"
  [(ngModel)]="archiveReason"
  (ngModelChange)="selectChange.emit($event)"
  backgroundColor="white">
</ui-library-simple-select>
