import { FormArray, FormGroup } from '@angular/forms';
import { disableFormControl } from './disableFormControl';
import { disableFormArray } from './disableFormArray';

export const disableFormGroup = (formGroup: FormGroup) => {
  formGroup.disable({
    emitEvent: false,
    onlySelf: true,
  });

  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.controls[key];
    if (control instanceof FormGroup) {
      disableFormGroup(control);
    } else if (control instanceof FormArray) {
      disableFormArray(control);
    } else {
      disableFormControl(control);
    }
  });
};
