import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopComponent } from './scroll-to-top.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { ButtonOutlineComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [ScrollToTopComponent],
  exports: [ScrollToTopComponent],
  imports: [CommonModule, SharedDefaultModule, ButtonOutlineComponent],
})
export class ScrollToTopModule {}
