<div>
  <div>
    <label class="uj-block text-m-b uj-mb-b uj-relative"
      >{{ "url" | i18next | firstUppercase }}
    </label>
    <div
      class="uj-mb-d"
      *ngFor="
        let linkGroup of formService.linksFormArray.controls;
        let i = index;
        trackBy: trackByUUID
      "
      [formGroup]="linkGroup">
      <app-talent-links-entry [linkGroup]="linkGroup"> </app-talent-links-entry>
    </div>
  </div>
  <app-talent-links-entry
    *ngIf="newURLEntry"
    [isNewEntry]="true"
    [linkGroup]="newURLEntry">
  </app-talent-links-entry>
</div>
