import { CommonModule } from '@angular/common';
import { GlobalSheetsComponent } from './global-sheets.component';
import { VacancyDetailModule } from '../vacancy-detail/vacancy-detail.module';
import { VacancyChangelogModule } from '../vacancy-changelog/vacancy-changelog.module';
import { ChannelSlotsModule } from '../channel-slots/channel-slots.module';
import { TalentChangelogComponent } from '../talent-changelog/talent-changelog.component';
import { TalentSheetComponent } from '../talent-sheet/talent-sheet.component';
import { MatchSheetComponent } from '../match-sheet/match-sheet.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [GlobalSheetsComponent],
  exports: [GlobalSheetsComponent],
  imports: [
    CommonModule,
    VacancyDetailModule,
    VacancyChangelogModule,
    ChannelSlotsModule,
    TalentSheetComponent,
    TalentChangelogComponent,
    MatchSheetComponent,
  ],
})
export class GlobalSheetsModule {}
