<div class="wrapper S not-selected white">
  <div class="list-item-container">
    <div class="uj-grow uj-flex uj-items-center uj-gap-d uj-mr-0 lg:uj-mr-d">
      <ui-library-avatar
        content="letters"
        [letters]="letters"
        size="s"></ui-library-avatar>
      <div class="uj-flex uj-flex-col uj-grow uj-w-0">
        <div class="text-m-b uj-truncate">
          <span>{{ title }}</span>
        </div>
        <div class="text-m">{{ description }}</div>
      </div>
    </div>
  </div>
</div>
