<div>
  <label class="uj-block text-m-b uj-mb-b uj-relative"
    >{{ "addresses" | i18next | firstUppercase }}
  </label>
  <div class="uj-flex uj-flex-col uj-gap-d">
    <div
      *ngFor="
        let formGroup of formService.addressesFormArray.controls;
        let i = index;
        trackBy: trackByUUID
      ">
      <app-talent-addresses-address
        (isInEditMode)="toggleAddressIsInEditMode($event)"
        [address]="formGroup"></app-talent-addresses-address>
    </div>
    <button
      *ngIf="!isInEditMode()"
      class="!uj-w-full"
      ui-library-button-outline
      iconLeft="plus"
      color="dark"
      (click)="addAddress()">
      {{ "addAddress" | i18next | firstUppercase }}
    </button>
  </div>
</div>
