import { TalentSourcePlatformEnum } from '../../graphql/generated';
import { sourceToPlatformMap } from './source-to-platform-map';

export const platformIcons = {
  [TalentSourcePlatformEnum.UNIVERSALJOB]:
    sourceToPlatformMap['universaljob-others'].iconPath,
  [TalentSourcePlatformEnum.JOBS_CH]: sourceToPlatformMap['jobs.ch'].iconPath,
  [TalentSourcePlatformEnum.LINKED_IN]:
    sourceToPlatformMap['linkedin'].iconPath,
  [TalentSourcePlatformEnum.JOB_SHOT]: sourceToPlatformMap['jobshot'].iconPath,
};
