<form
  [formGroup]="commissionForm"
  class="uj-flex uj-items-stretch commission-input-group">
  <app-big-select
    class="uj-w-3/4"
    [options]="filteredOptions"
    [selectedText]="selectedText"
    [optionText]="optionText"
    [placeholderAvatar]="placeholderAvatar"
    [placeholder]="'chooseCompanyContact' | i18next"
    [searchPlaceholder]="'searchCompanyContactPlaceholder' | i18next"
    (searchChangedDebounced)="updateSearchTerm($event)"
    (optionChosen)="changeSelectedUser($event)"
    (toggledOpen)="onToggledOpen($event)"
    [showEmptyOption]="!!(selectClearable && selectedOption)"
    [emptyOptionText]="emptyText"
    [emptyOptionAvatar]="emptyAvatar"
    [trackOptionsByFn]="trackOptionsByFn">
  </app-big-select>
  <input
    ui-library-text-input
    name="commissionCHF"
    class="!uj-w-1/4"
    style="height: unset"
    formControlName="commissionCHF"
    focusTracker="salaryExpectationHoursPerWeek"
    positionInGroup="end-seamless"
    type="number" />
</form>
<ng-template #placeholderAvatar>
  <ui-library-avatar
    content="icon"
    icon="user"
    size="s"></ui-library-avatar>
</ng-template>
<ng-template #selectedText>
  <ng-container *ngIf="selectedOption">
    <p class="uj-truncate text-m-b">
      {{ selectedOption.profile?.firstName }}
      {{ selectedOption.profile?.lastName }}
      {{
        selectedOption._id === loggedInUser?._id
          ? "(" + ("iMyself" | i18next) + ")"
          : ""
      }}
    </p>
    <p class="uj-text-text-m/[20px] uj-truncate -uj-mb-b">
      {{ selectedOption.profile?.position }}
    </p>
  </ng-container>
  <ng-container *ngIf="!selectedOption">
    <span>{{ "chooseCompanyContact" | i18next }}</span>
  </ng-container>
</ng-template>
<ng-template
  #optionText
  let-option="option">
  <p
    class="uj-truncate text-m-b"
    [innerHTML]="
      option.profile?.firstName +
        ' ' +
        option.profile?.lastName +
        (loggedInUser?._id === option._id
          ? ' (' + ('iMyself' | i18next) + ')'
          : '') | highlightSearch: searchTerm
    "></p>
</ng-template>
<ng-template #emptyText>
  <p
    class="uj-truncate text-m-b"
    [innerHTML]="'splitWithNoone' | i18next"></p>
</ng-template>

<ng-template #emptyAvatar>
  <ui-library-avatar
    letters="-"
    size="s"></ui-library-avatar>
</ng-template>
