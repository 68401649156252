import {
  ChangelogFieldDisplayType,
  TalentFragment,
  TalentHistoryEntryFragment,
  TalentUpdateInput,
  TalentUpdateOutput,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { TalentProsMirroredFields } from '../../talent-sheet/talent-sheet.types';
import { resolveChangelogAction } from './resolveChangelogAction';
import { proseMirrorToHtml } from '@intemp/unijob-ui';
import { getChangelogFieldLabel } from './getChangelogFieldLabel';

export function prepareTalentChangLogForTypeProseMirror(
  entry: TalentHistoryEntryFragment,
  fieldName: keyof TalentProsMirroredFields,
  talent: TalentFragment,
  input: TalentUpdateInput,
): PreparedTalentChangeLog {
  const displayedCurrentValue = getDisplayedValueByFieldName(fieldName, input);
  const displayedPreviousValue = getDisplayedValueByFieldName(
    fieldName,
    entry.revertInput,
  );

  return {
    ...entry,
    displayedCurrentValue,
    displayedPreviousValue,
    fieldName: fieldName,
    fieldLabel: getChangelogFieldLabel(fieldName),
    revertInput: entry.revertInput,
    action: resolveChangelogAction(
      displayedCurrentValue,
      displayedPreviousValue,
    ),
    contentIsIdentical: revertInputIsIdenticalWithCurrentValue(
      fieldName,
      entry,
      talent,
    ),
    mutationFieldType: ChangelogFieldDisplayType.PROSE_MIRROR,
  };
}

function getDisplayedValueByFieldName(
  fieldName: keyof TalentProsMirroredFields,
  input?: TalentUpdateInput | TalentUpdateOutput | null,
) {
  const value = input?.[fieldName];
  if (!value) return;
  return proseMirrorToHtml(value);
}

function revertInputIsIdenticalWithCurrentValue(
  fieldName: keyof TalentProsMirroredFields,
  changelog: PreparedTalentChangeLog,
  talent: TalentFragment,
): boolean {
  const currentValue = talent[fieldName];
  const revertValue = changelog.revertInput?.[fieldName];

  if (currentValue && !revertValue) return false;
  if (!currentValue && revertValue) return false;
  if (!currentValue && !revertValue) return true;

  return proseMirrorToHtml(currentValue) === proseMirrorToHtml(revertValue);
}
