import {
  ChangelogFieldDisplayType,
  TalentUpdateInput,
} from '../../../../graphql/generated';

export type ChangelogFieldDisplayTypes = {
  [key in keyof Omit<
    TalentUpdateInput,
    'uuid' | 'isRevertInput' | 'revertInputHistoryId'
  >]: ChangelogFieldDisplayType;
};

export const talentChangelogFieldTypesConstants = {
  // string
  firstName: ChangelogFieldDisplayType.STRING,
  lastName: ChangelogFieldDisplayType.STRING,
  sex: ChangelogFieldDisplayType.STRING,
  dateOfBirth: ChangelogFieldDisplayType.STRING,
  placeOfBirth: ChangelogFieldDisplayType.STRING,
  children: ChangelogFieldDisplayType.STRING,
  nationality: ChangelogFieldDisplayType.STRING,
  civilOrigin: ChangelogFieldDisplayType.STRING,
  driversLicense: ChangelogFieldDisplayType.STRING,
  religiousDenomination: ChangelogFieldDisplayType.STRING,
  maritalStatus: ChangelogFieldDisplayType.STRING,
  ahvNumber: ChangelogFieldDisplayType.STRING,
  iban: ChangelogFieldDisplayType.STRING,
  residencePermit: ChangelogFieldDisplayType.STRING,
  residencePermitValidUntil: ChangelogFieldDisplayType.STRING,
  workAvailability: ChangelogFieldDisplayType.STRING,
  emails: ChangelogFieldDisplayType.STRING,
  phones: ChangelogFieldDisplayType.STRING,
  addresses: ChangelogFieldDisplayType.STRING,
  links: ChangelogFieldDisplayType.STRING,
  sources: ChangelogFieldDisplayType.STRING,
  recommendedBy: ChangelogFieldDisplayType.STRING,
  availabilityNote: ChangelogFieldDisplayType.STRING,
  nextMilitaryServiceAt: ChangelogFieldDisplayType.STRING,
  availableForWorkAt: ChangelogFieldDisplayType.STRING,
  blackListedReason: ChangelogFieldDisplayType.STRING,
  targetJobTitle: ChangelogFieldDisplayType.STRING,
  workArrangement: ChangelogFieldDisplayType.STRING,
  salaryExpectation: ChangelogFieldDisplayType.STRING,
  salaryExpectationCurrency: ChangelogFieldDisplayType.STRING,
  salaryExpectationMax: ChangelogFieldDisplayType.STRING,
  salaryExpectationMin: ChangelogFieldDisplayType.STRING,
  salaryExpectationUnit: ChangelogFieldDisplayType.STRING,
  maxCommutingTimeMinutes: ChangelogFieldDisplayType.STRING,
  workloadPercentageMax: ChangelogFieldDisplayType.STRING,
  workloadPercentageMin: ChangelogFieldDisplayType.STRING,
  workloadPercentage: ChangelogFieldDisplayType.STRING,
  employmentType: ChangelogFieldDisplayType.STRING,
  preferredLocations: ChangelogFieldDisplayType.STRING,
  talentNumber: ChangelogFieldDisplayType.STRING,
  customerUuid: ChangelogFieldDisplayType.STRING,
  hasAllowedDataUsageAt: ChangelogFieldDisplayType.STRING,
  preferenceNote: ChangelogFieldDisplayType.STRING,
  salaryExpectationHoursPerWeek: ChangelogFieldDisplayType.STRING,
  salaryExpectationPaymentsPerYear: ChangelogFieldDisplayType.STRING,
  employmentCompany: ChangelogFieldDisplayType.STRING,
  employedSince: ChangelogFieldDisplayType.STRING,
  employedUntil: ChangelogFieldDisplayType.STRING,
  communicationLanguage: ChangelogFieldDisplayType.STRING,
  files: ChangelogFieldDisplayType.STRING,
  pages: ChangelogFieldDisplayType.STRING,

  // boolean
  hasVehicle: ChangelogFieldDisplayType.BOOLEAN,
  registeredWithRAV: ChangelogFieldDisplayType.BOOLEAN,
  nonCompeteAgreement: ChangelogFieldDisplayType.BOOLEAN,
  mustServeMilitaryService: ChangelogFieldDisplayType.BOOLEAN,
  salaryExpectationFlexible: ChangelogFieldDisplayType.BOOLEAN,
  commutingByPublicTransport: ChangelogFieldDisplayType.BOOLEAN,
  workloadFlexible: ChangelogFieldDisplayType.BOOLEAN,
  commutingByCar: ChangelogFieldDisplayType.BOOLEAN,
  commutingByBicycle: ChangelogFieldDisplayType.BOOLEAN,
  canEdit: ChangelogFieldDisplayType.BOOLEAN,

  //image
  avatar: ChangelogFieldDisplayType.IMAGE,

  //proseMirror
  internalNotes: ChangelogFieldDisplayType.PROSE_MIRROR,
} satisfies ChangelogFieldDisplayTypes;
