<div class="uj-flex uj-flex-col uj-gap-d uj-w-full">
  <div class="uj-flex uj-gap-c uj-w-full">
    <ui-library-form-field
      fullWidth
      [hint]="
        linkGroup.controls.url.errors?.message.key | i18next | firstUppercase
      "
      [feedback]="linkGroup.controls.url.errors ? 'invalid' : 'none'"
      [labelFor]="'addUrl' + componentId">
      <input
        ui-library-text-input
        [id]="'addUrl' + componentId"
        [placeholder]="'addUrl' | i18next"
        [formControl]="linkGroup.controls.url"
        (change)="onLinkChange($event)"
        type="text" />
    </ui-library-form-field>
    <ng-container *ngIf="!isNewEntry">
      <a
        *ngIf="linkGroup.value.url as link"
        [isDisabled]="linkGroup.controls.url.invalid"
        ui-library-button-outline
        type="button"
        [href]="linkGroup.controls.url.invalid ? undefined : (link | link)"
        [target]="'_blank'"
        (click)="
          linkGroup.controls.url.invalid ? $event.preventDefault() : null
        "
        color="dark"
        iconOnly="expand_share_send"></a>
      <button
        (click)="deleteUrl()"
        ui-library-button-outline
        type="button"
        color="dark"
        iconOnly="trash"
        class="hover:uj-text-ui-negative"></button>
    </ng-container>
  </div>
</div>
