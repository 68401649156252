<button
  ui-library-button-solid
  iconOnly="plus"
  elevation="elevated"
  size="S"
  corner="round"
  [contextMenu]="more"
  contextMenuBgColor="bg-pink"></button>
<ng-template #more>
  <div class="uj-flex uj-flex-col uj-gap-b">
    <button
      *ngFor="let option of popupMenuActions"
      (click)="option.action()"
      [attr.data-cy]="option.cyAttribute"
      class="!uj-w-full !uj-justify-start sm:uj-w-auto uj-whitespace-nowrap"
      ui-library-button-solid
      type="button"
      [iconLeft]="option.iconName">
      {{ option.label | i18next | firstUppercase }}
    </button>
  </div>
</ng-template>
