{
  "employmentNotFound": "Einsatz nicht gefunden",
  "consultantNotFound": "Personalberater konnte nicht gefunden werden",
  "employeeNotFound": "Mitarbeiter nicht gefunden",
  "serverError": "Serverfehler",
  "permissionDenied": "Zugriff verweigert!",
  "featureNotImplemented": "Feature noch nicht verfügbar",
  "userNotFound": "Benutzer nicht gefunden",
  "couldNotUpdateUser": "Benutzer konnte nicht aktualisiert werden",
  "customerNotFound": "Kunde nicht gefunden",
  "customerBranchNotFound": "Filiale nicht gefunden",
  "missingRequiredFieldX": "Fehlendes Pflichtfeld {{field}}",
  "updateMadeNoChanges": "Die gewünschte aktualisierung verursacht keine Veränderung",
  "tabSortError": "Die Tabs konnten nicht neu sortiert werden",
  "deleteTabError": "Der Tab konnte nicht gelöscht werden.",
  "tooManyAttempts": "Zu viele Versuche. Bitte versuche es später erneut.",
  "dayNotFound": "Der Tag Existiert auf dem Einsatz noch nicht.",
  "entryNotFound": "Der Eintrag existiert auf dem entsprechenden Tag noch nicht.",
  "missingPhoneNumber": "Fehlende Telefonnummer",
  "notImplemented": "Diese Funktion wurde noch nicht vollständig implementiert",
  "documentNotFound": "Das Dokument konnte nicht gefunden werden.",
  "notifySyncError": "Die Daten konnten nicht Synchronisiert werden",
  "couldNotCreateJobSubscription": "Der Job-Alarm konnte nicht abonniert werden"
}
