import {
  Maybe,
  SortDirEnum,
  SupportedLanguage,
  UserBotFragment,
  UserListItemFragment,
  UserSelfFragment,
} from '../graphql/generated';

export enum VerticalDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export interface FilterSortInputBase {
  sortBy?: Maybe<string>;
  sortDir?: Maybe<SortDirEnum>;
}

export type MappedCustomerBranch = {
  uuid: string;
  name: string;
};

export interface AuthUser {
  id: string;
  expires: number;
  email: string;
  emailVerified?: boolean;
  userId?: string;
  isVerified: boolean;
}

export type AnyUser = UserListItemFragment | UserBotFragment | UserSelfFragment;

export enum EmploymentTermEnum {
  FIXED_TERM = 'FIXED_TERM',
  FULL_TERM = 'FULL_TERM',
}

interface CountryOptions {
  name: string;
  shortName: string;
  continent: Continent;
  officialName?: string;
  genderNeutralWriting?: string;
  residentsMaleWriting?: string;
  residentsFemaleWriting?: string;
}
type Continent = 'Africa' | 'Americas' | 'Asia' | 'Europe' | 'Oceania';

type SupportLanguageOptions = {
  [key in SupportedLanguage]: CountryOptions;
};

export interface Country extends SupportLanguageOptions {
  code: string;
  isoCode: string;
  flag: string;
  continent: Continent;
}
