<div>
  <label class="uj-flex uj-items-center uj-gap-b text-m-b uj-mb-b uj-relative"
    >{{ "recommendation" | i18next | firstUppercase }}
    <span
      ><unijob-icon
        [tooltip]="'recommendationTooltip' | i18next"
        size="16"
        color="grey"
        icon="info_circle"></unijob-icon
    ></span>
  </label>
  <div class="uj-flex uj-flex-col uj-gap-d">
    <div
      *ngFor="
        let formGroup of formService.recommendations.controls;
        let i = index;
        trackBy: trackByUUID
      ">
      <app-talent-recommendations-entry
        (isInEditMode)="toggleRecommendationIsInEditMode($event)"
        [recommendation]="formGroup"></app-talent-recommendations-entry>
    </div>
    <button
      *ngIf="!childrenIsInEditMode()"
      class="!uj-w-full"
      ui-library-button-outline
      theme="flat"
      iconLeft="plus"
      size="M"
      corner="rounded"
      bgColor="bg-white"
      color="dark"
      (click)="addRecommendation()">
      {{ "addRecommendation" | i18next | firstUppercase }}
    </button>
  </div>
</div>
