import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ResponsiveService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  VacancyArchiveReasonEnum,
  VacancyDetailFragment,
  VacancyStatusEnum,
} from '../../../../graphql/generated';
import { disableFormGroup } from '../../../helpers/functions/disableFormGroup';
import { findMismatches } from '../../../helpers/functions/findMismatches';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { isValidURL } from '../../../helpers/functions/isValidURL';
import { parseValueToNumber } from '../../../helpers/functions/parseValueToNumber';
import { patchFormControl } from '../../../helpers/functions/patchFormControl';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import {
  SubmitVacancyUpdateInput,
  VacancyInternalFormData,
} from '../../../../pages/vacancies/vacancy.types';
import { FocusTrackerDirective } from '../focusTracker.directive';
import { ToastService } from '@intemp/unijob-ui2';
import { allowedSalaryInput } from '../../../helpers/functions/allowedSalaryInput';

@Component({
  selector: 'app-vacancy-detail-intern',
  templateUrl: './vacancy-detail-intern.component.html',
})
export class VacancyDetailInternComponent implements OnInit, OnChanges {
  @Input({ required: true }) internData!: VacancyInternalFormData;
  @Input({ required: true }) formRef!: FormGroup;
  @Input({ required: true }) vacancy!: VacancyDetailFragment;
  @Input() formIsDisabled = false;

  autoSaved: { [key in keyof any]?: boolean } = {};

  @Output() triggerAutoSave = new EventEmitter<SubmitVacancyUpdateInput>();

  originalUrl = new FormControl<string | null>(null);
  agePreferenceMin = new FormControl<number | null>(null);
  agePreferenceMax = new FormControl<number | null>(null);
  notes = new FormControl<string | null>(null);
  archiveReason = new FormControl<VacancyArchiveReasonEnum | null>(null);

  formGroup = new FormGroup({
    originalUrl: this.originalUrl,
    agePreferenceMin: this.agePreferenceMin,
    agePreferenceMax: this.agePreferenceMax,
    notes: this.notes,
    archiveReason: this.archiveReason,
  });

  keysThatNeedToBeParsedToNumber: { [key: string]: boolean } = {
    agePreferenceMin: true,
    agePreferenceMax: true,
  };

  isSmDown$ = this.responsiveService.isSmDown$;

  status = VacancyStatusEnum.DRAFT;

  programmaticUpdate = new BehaviorSubject<boolean>(false);

  originalUrlIsValid = false;

  @ViewChildren(FocusTrackerDirective)
  focusTrackers!: QueryList<FocusTrackerDirective>;

  constructor(
    private responsiveService: ResponsiveService,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe,
  ) {}

  ngOnInit(): void {
    this.subscribeToOriginalUrl();
    this.updateFormValues();
    this.subscribeFormChanges();
    this.formRef.setControl('intern', this.formGroup);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.firstChange &&
      changes.internData?.previousValue &&
      changes.internData?.currentValue
    ) {
      this.patchFormValuesOnChange(
        changes.internData.previousValue,
        changes.internData.currentValue,
      );
    }
    if (changes.vacancy) {
      this.status = changes.vacancy.currentValue.status;
    }
    if (changes.formIsDisabled) {
      if (changes.formIsDisabled.currentValue) {
        disableFormGroup(this.formGroup);
      } else {
        this.formGroup.enable({ emitEvent: false });
      }
    }
  }

  patchFormValuesOnChange(
    previous: VacancyInternalFormData,
    current: VacancyInternalFormData,
  ) {
    previous.notes = JSON.stringify(previous.notes);

    const currentCopy = structuredClone(current);
    currentCopy.notes = JSON.stringify(currentCopy.notes);

    const differences = findMismatches(previous, currentCopy);
    if (!differences.length) return;

    this.programmaticUpdate.next(true);

    for (const key of differences) {
      const control = this.formGroup.get(key);
      if (!control) continue;

      const isFocused = this.focusTrackers.find((f) => f.targetID === key)
        ?.isFocused$.value;

      if (isFocused) continue;

      let areNotSame = false;
      if (key === 'notes' && 'originalUrl') {
        areNotSame =
          JSON.stringify(control?.value) !== JSON.stringify(current[key]);
      } else {
        areNotSame =
          parseValueToNumber(control?.value) !==
          current[key as keyof VacancyInternalFormData];
      }
      if (control && areNotSame) {
        patchFormControl(
          control,
          current[key as keyof VacancyInternalFormData],
        );
      }
    }
    this.programmaticUpdate.next(false);
  }

  updateFormValues() {
    const {
      originalUrl,
      agePreferenceMin,
      agePreferenceMax,
      notes,
      archiveReason,
    } = this.internData;
    this.formGroup.patchValue({
      originalUrl,
      agePreferenceMin,
      agePreferenceMax,
      notes,
      archiveReason,
    });
  }

  subscribeFormChanges() {
    this.formGroup.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((controls) => {
        getObjectKeys(controls).forEach((key) => {
          const control = this.formGroup.controls[key];
          if (control.dirty) {
            this.updateInputFormControl(control, key);
          }
        });
      });

    if (this.internData.archiveReason) {
      this.archiveReason.valueChanges.subscribe((controls) => {
        this.updateInputFormControl(this.archiveReason, 'archiveReason');
      });
    }
  }

  updateInputFormControl(control: FormControl, key: string) {
    const submitDataInput: SubmitVacancyUpdateInput = {
      [key]: this.keysThatNeedToBeParsedToNumber[key]
        ? control.value !== null
          ? parseValueToNumber(control.value)
          : null
        : control.value,
    };

    control.markAsPristine();
    this.triggerSave(submitDataInput);
  }

  triggerSave(submitDataInput: SubmitVacancyUpdateInput) {
    this.triggerAutoSave.emit(submitDataInput);
    this.markAsAutoSaved(submitDataInput);
  }

  markAsAutoSaved(submitDataInput: SubmitVacancyUpdateInput) {
    Object.keys(submitDataInput).forEach((key) => {
      this.autoSaved[key] = true;
    });
  }

  navigateToOriginalAd() {
    window.open(this.internData.originalUrl, '_blank');
  }

  subscribeToOriginalUrl() {
    this.originalUrl.valueChanges.subscribe((value) => {
      this.originalUrlIsValid = !!(value && isValidURL(value));
    });
  }

  updateArchiveReason(event: VacancyArchiveReasonEnum) {
    this.archiveReason.setValue(event);
    this.archiveReason.markAsDirty();
  }

  protected readonly VacancyStatusEnum = VacancyStatusEnum;

  copyVacancyNumberToClipboard(uuid: string) {
    this.clipboard.copy(uuid);
    this.toastService.makeToast({
      type: 'SUCCESS',
      message: this.i18nPipe.transform('vacancyNumberCopied'),
      duration: 6000,
    });
  }

  protected readonly allowedSalaryInput = allowedSalaryInput;
}
