<ui-library-form-field
  fullWidth
  [label]="'matchCompany' | i18next | firstUppercase">
  <ui-library-card
    padding="8px"
    class="uj-flex uj-gap-d">
    <div class="uj-flex uj-gap-d uj-items-center">
      <ui-library-avatar
        icon="company_2"
        content="icon"
        size="s"></ui-library-avatar>
      <div class="uj-flex uj-flex-col uj-w-0 uj-grow">
        <div class="uj-flex">
          <span class="text-m-b uj-truncate">{{
            match().company?.companyLocation?.companyReference?.legalName
          }}</span>
        </div>
        <span
          class="uj-text-text-s uj-truncate"
          *ngIf="this.match().company?.companyLocation?.address as address">
          {{ address.street }}, {{ address.zip }} {{ address.location }},
          {{ address.country }}
        </span>
      </div>
      <div class="uj-flex uj-gap-c uj-items-center">
        <a
          *ngIf="this.match().company?.companyLocation?.address as address"
          target="_blank"
          [href]="address | addressToGoogleMapUrl"
          ui-library-button-text
          iconOnly="pin_location"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"></a>
      </div>
    </div>
  </ui-library-card>
</ui-library-form-field>
