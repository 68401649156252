import { TalentArrayFields } from '../../talent-sheet/talent-sheet.types';
import {
  TalentFragment,
  TalentUpdateInput,
} from '../../../../graphql/generated';
import {
  addressIsUnchanged,
  childIsUnchanged,
  emailIsUnchanged,
  fileIsUnchanged,
  linkIsUnchanged,
  pageIsUnchanged,
  phoneIsUnchanged,
  recommendedByIsUnchanged,
  sourceIsUnchanged,
} from './compare-values-functions';

export const arrayItemHasChangedHandler: {
  [key in keyof TalentArrayFields]: (
    item: NonNullable<TalentFragment[key]>[number],
    revertItem: NonNullable<TalentUpdateInput[key]>[number],
  ) => boolean;
} = {
  children: (input, revertInput) => {
    return childIsUnchanged(input, revertInput);
  },
  addresses: (input, revertInput) => {
    return addressIsUnchanged(input, revertInput);
  },
  phones: (input, revertInput) => {
    return phoneIsUnchanged(input, revertInput);
  },
  emails: (input, revertInput) => {
    return emailIsUnchanged(input, revertInput);
  },
  links: (input, revertInput) => {
    return linkIsUnchanged(input, revertInput);
  },
  sources: (input, revertInput) => {
    return sourceIsUnchanged(input, revertInput);
  },
  recommendedBy: (input, revertInput) => {
    return recommendedByIsUnchanged(input, revertInput);
  },
  targetJobTitle: (input, revertInput) => {
    return input.title === revertInput.title;
  },
  workArrangement: (input, revertInput) => {
    return input.arrangement === revertInput.arrangement;
  },
  driversLicense: (input, revertInput) => {
    return input.license === revertInput.license;
  },
  preferredLocations: (input, revertInput) => {
    return (
      input.location === revertInput.location &&
      input.radiusKm === revertInput.radiusKm
    );
  },
  pages: (input, revertInput) => {
    return pageIsUnchanged(input, revertInput);
  },
  files: (input, revertInput) => {
    return fileIsUnchanged(input, revertInput);
  },
};
