import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Type,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { LanguageProficiencyEnum } from '../../../../graphql/generated';
import { ResponsiveService } from '@intemp/unijob-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguagesFormGroup } from '../vacancy-form';
import { getTranslatedVacancyLocaleOptions } from '../../../../pages/vacancies/helpers/getTranslatedVacancyLocaleOptions';
import { LanguageService } from '../../../../core/services/language.service';
import {
  singleSelectBehavior,
  TextOption,
  TextSelectedItemComponent,
} from '@intemp/unijob-ui2';

@Component({
  selector: 'app-vacancy-language-requirement-row',
  templateUrl: './vacancy-language-requirement-row.component.html',
  styleUrls: ['./vacancy-language-requirement-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyLanguageRequirementRowComponent
  implements OnChanges, OnInit, OnDestroy
{
  destroyed$ = new Subject<void>();
  @Input({ required: true }) languageForm!: LanguagesFormGroup;
  @Input({ required: true }) formRef!: FormArray;

  @Output() removeEntry = new EventEmitter();

  @Output() languageChanged = new EventEmitter<string>();

  @Input({ required: true }) languageOptions!: readonly TextOption<string>[];
  @Input() selectedLanguages: string[] = [];

  @Input({ required: true })
  languageProficiencyEnumOptions!: readonly TextOption<LanguageProficiencyEnum>[];

  /**
   * Returns the languageProficiencyEnumOptions except for the last element, which should be
   * ADVANTAGEUOUS.
   */
  get languageProficiencyEnumOptionsWithoutLast(): readonly TextOption<LanguageProficiencyEnum>[] {
    return this.languageProficiencyEnumOptions.slice(0, -1);
  }

  displayedOptions: readonly TextOption<string>[] = [];

  isXsDown$ = this.responsiveService.isXsDown$;
  constructor(
    private responsiveService: ResponsiveService,
    private languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.languageForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.languageChanged.emit(value.language);
        this.languageForm.markAsDirty();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.languageOptions || changes.selectedLanguages) {
      this.updateDisplayedLanguages();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateDisplayedLanguages() {
    const optionsToHide = this.selectedLanguages.filter(
      (language) => language !== this.languageForm.value.language,
    );
    const activeLanguage = this.languageService.getActiveLanguage();
    this.displayedOptions = getTranslatedVacancyLocaleOptions(activeLanguage)
      .filter((option) => !optionsToHide.includes(option.value))
      .map(({ value, name }) => ({ value, label: name }));
  }

  protected readonly TextSelectedItemComponent: Type<TextSelectedItemComponent> =
    TextSelectedItemComponent;
  protected readonly singleSelectBehavior = singleSelectBehavior;
}
