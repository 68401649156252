import { Component, computed, input } from '@angular/core';
import {
  MatchFragment,
  WorkAvailabilityEnum,
} from '../../../../../graphql/generated';
import { intervalToDuration } from 'date-fns';
import { getDuration } from '../../../../helpers/functions/getDuration';
import {
  AvatarComponent,
  ButtonTextComponent,
  CardComponent,
  DotComponent,
  FormFieldComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';

@Component({
  standalone: true,
  selector: 'app-match-company-card',
  templateUrl: './match-company-card.component.html',
  imports: [
    CardComponent,
    AvatarComponent,
    I18NextModule,
    SharedDefaultModule,
    DotComponent,
    ButtonTextComponent,
    FormFieldComponent,
  ],
  providers: [],
})
export class MatchCompanyCardComponent {
  match = input.required<MatchFragment>();

  availableForWorkAt = computed(() => {
    const availableForWorkAt = this.match().talent.availableForWorkAt;
    if (!availableForWorkAt) return;

    const date = new Date(availableForWorkAt);
    const now = new Date();
    const durationSincePublished = intervalToDuration({
      start: date,
      end: now,
    });
    const { duration, durationUnit } = getDuration(durationSincePublished);

    return {
      date,
      duration,
      durationUnit,
    };
  });

  matchLetters = computed(() => {
    return this.match().company?.companyLocation?.companyReference?.legalName?.slice(
      0,
      2,
    );
  });

  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;
}
