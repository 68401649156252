<ui-library-form-field
  *ngIf="match().vacancy as matchVacany"
  fullWidth
  [label]="'vacancy' | i18next | firstUppercase">
  <ui-library-card
    padding="8px"
    interactable
    (click)="openVacancy(matchVacany.uuid)"
    class="uj-flex uj-gap-d">
    <div class="uj-flex uj-gap-d uj-items-center">
      <ui-library-avatar
        size="s"
        content="icon"
        icon="suitcase"></ui-library-avatar>
      <div class="uj-flex-1 uj-w-0 uj-grow">
        <div class="uj-flex">
          <span class="text-m-b uj-truncate">{{ match().vacancy?.title }}</span>
        </div>
        <div
          *ngIf="vacancyStatus() as values"
          class="uj-flex uj-gap-b uj-items-center uj-truncate">
          <div class="uj-flex uj-gap-b uj-items-center uj-truncate">
            <span
              class="uj-min-w-[8px]"
              ui-library-dot
              size="S"
              corner="round"
              [color]="values.dotColor">
            </span>
            <span class="uj-text-text text-s-b uj-truncate">{{
              "vacancyStatusEnum." + vacancy()?.status | i18next
            }}</span>
          </div>
          <span
            class="uj-hidden sm:uj-inline uj-text-text-s uj-w-0 uj-grow uj-truncate"
            *ngIf="values.date">
            {{
              values.dateTranslationKey || ""
                | i18next
                  : {
                      date: values.date | date,
                      duration: values.duration,
                      durationUnit: values.durationUnit
                        ? (values.durationUnit | i18next)
                        : {},
                    }
            }}
          </span>
        </div>
      </div>
      <div class="uj-flex uj-gap-c uj-items-center">
        <button
          ui-library-button-text
          iconOnly="preview_zoom"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"
          (click)="
            $event.stopPropagation(); openVacancyPreview(matchVacany.uuid)
          "></button>
        <div class="uj-h-e uj-w-[1px] uj-bg-border"></div>
        <button
          ui-library-button-text
          iconOnly="arrow_right"
          variant="tertiary"
          size="M"
          color="dark"
          class="collapse-nav-button"></button>
      </div>
    </div>
  </ui-library-card>
</ui-library-form-field>
