<div
  class="uj-relative uj-overflow-visible uj-h-g"
  [ngClass]="{
    'uj-z-10': showOptions,
    'uj-cursor-not-allowed': disabled,
  }">
  <div
    class="uj-border uj-rounded uni-dropdown ignore-click-outside hover:elevation-1 focus:uj-border-border-focus"
    (keydown.escape)="handleEsc($event)"
    (keydown.tab)="closeSelectField()"
    (keydown.arrowDown)="onKeyDownNavigate()"
    (keydown.arrowUp)="onKeyUpNavigate()"
    tabindex="0"
    (keydown.space)="onSpaceOpen()"
    (appClickedOutside)="closeSelectField()"
    [ngClass]="{
      'elevation-1': showOptions,
      'uj-pointer-events-none': disabled,
      'uj-border-ui-negative': hasError,
      'uj-border-border': !hasError,
      'uj-bg-surface-main': !disabled,
      'uj-bg-surface-grape': disabled,
    }">
    <ng-template #defaultAvatar>
      <ui-library-avatar
        letters=""
        size="s"></ui-library-avatar>
    </ng-template>
    <ng-template
      #defaultOptionText
      let-option="option"
      let-index="index">
      {{ option?.label ?? 'Please define "label" or define a optionTemplate' }}
    </ng-template>
    <ng-template #defaultEmptyText>
      {{ "emptyOption" | i18next }}
    </ng-template>
    <ng-template #defaultPlaceholderText>{{ placeholder }}</ng-template>
    <div>
      <!-- header when closed -->
      <div
        (click)="toggleOptions()"
        *ngIf="!showOptions"
        class="uj-relative uj-flex uj-items-center uj-gap-d uj-px-d uj-py-c uj-cursor-pointer">
        <!-- header item (current selection) -->
        <ng-container
          *ngTemplateOutlet="
            selectedAvatar || placeholderAvatar || defaultAvatar
          "></ng-container>
        <div class="uj-flex-grow uj-min-w-0">
          <ng-container
            *ngTemplateOutlet="
              selectedText || defaultPlaceholderText
            "></ng-container>
        </div>

        <!-- header desktop actions -->
        <ng-container *ngIf="actions">
          <div class="uj-hidden actions-wrapper lg:uj-flex">
            <button
              *ngFor="let action of actions"
              ui-library-button-text
              type="button"
              [iconOnly]="action.icon"
              [tooltip]="action.labelTranslationKey | i18next"
              (click)="$event.stopPropagation(); action.onClick()"
              color="dark"></button>
          </div>
          <div
            class="actions-divider uj-h-e uj-w-[1px] uj-bg-border uj-mx-b uj-hidden lg:uj-block"></div>
        </ng-container>
        <unijob-icon
          *ngIf="!this.loading"
          [class.uj-rotate-180]="showOptions"
          class="uj-hidden uj-py-c uj-pr-b lg:uj-block"
          [class.uj-opacity-54]="disabled"
          icon="chevron_down"></unijob-icon>
        <unijob-icon
          *ngIf="this.loading"
          class="uj-hidden uj-py-c uj-pr-b animate-spin lg:uj-block"
          icon="chances_progress"></unijob-icon>

        <!-- header mobile actions -->
        <unijob-icon
          *ngIf="!this.loading && !actions.length"
          [class.uj-rotate-180]="showOptions"
          class="uj-block uj-py-c uj-pr-b lg:uj-hidden"
          [class.uj-opacity-54]="disabled"
          icon="chevron_down"></unijob-icon>
        <unijob-icon
          *ngIf="this.loading && !actions.length"
          class="uj-block uj-py-c uj-pr-b lg:uj-hidden animate-spin"
          icon="chances_progress"></unijob-icon>
        <div
          class="uj-block lg:uj-hidden"
          *ngIf="actions">
          <button
            ui-library-button-outline
            *ngIf="moreActionsContextMenu && actions.length > 0"
            type="button"
            iconOnly="menu_dots_vertical"
            [contextMenu]="moreActionsContextMenu"
            (click)="$event.stopPropagation()"></button>
          <ng-template #moreActionsContextMenu>
            <button
              *ngFor="let action of actions"
              ui-library-button-text
              type="button"
              color="dark"
              [iconLeft]="action.icon"
              (click)="$event.stopPropagation(); action.onClick()">
              {{ action.labelTranslationKey | i18next }}
            </button>
            <div class="uj-mx-d uj-border-t uj-border-border"></div>
            <button
              ui-library-button-text
              type="button"
              color="dark"
              iconLeft="flexibility_arrows"
              (click)="toggleOptions()">
              {{ "changeSelection" | i18next }}
            </button>
          </ng-template>
        </div>
      </div>

      <!-- header when opened -->
      <div
        *ngIf="showOptions"
        class="uj-relative uj-flex uj-items-center uj-gap-d uj-px-d uj-py-c uj-pb-c uj-cursor-pointer">
        <label
          for="bigSelectSearch"
          class="uj-flex">
          <ng-container *ngTemplateOutlet="placeholderAvatar || defaultAvatar"
            >test</ng-container
          >
        </label>
        <input
          id="bigSelectSearch"
          type="text"
          class="uj-w-full input-text-m"
          autocomplete="off"
          [class.uj-text-text-grey]="searchTerm === ''"
          [placeholder]="searchPlaceholder"
          [(ngModel)]="searchTerm"
          tabindex="-1"
          (ngModelChange)="searchFieldInputChanged($event)"
          #searchElement />
        <unijob-icon
          class="uj-py-c uj-pr-b"
          icon="chevron_up"
          (click)="toggleOptions()"></unijob-icon>
      </div>
    </div>

    <!-- dropdown when opened -->
    <div
      *ngIf="showOptions"
      class="uj-overflow-y-auto uj-max-h-[40vh]">
      <div class="uj-mx-d uj-border-t uj-border-border"></div>
      <ul
        [@filterAnimation]="options.length"
        class="uni-dropdown-list">
        <ng-container *ngIf="showEmptyOption">
          <li
            tabindex="0"
            (click)="selectValue(null)"
            (keydown.enter)="selectValue(null)"
            class="uj-px-d uj-py-d uj-cursor-pointer focus:uj-bg-border hover:uj-bg-surface-grape">
            <div class="uj-relative uj-flex uj-items-center uj-gap-d">
              <ng-container
                *ngTemplateOutlet="
                  emptyOptionAvatar || placeholderAvatar || defaultAvatar
                "></ng-container>
              <div class="uj-flex-grow uj-min-w-0">
                <ng-container
                  *ngTemplateOutlet="emptyOptionText || defaultEmptyText">
                </ng-container>
              </div>
            </div>
          </li>
        </ng-container>
        <li
          tabindex="0"
          #optionList
          (click)="selectValue(optionObject)"
          (keydown.enter)="selectValue(optionObject)"
          *ngFor="
            let optionObject of options;
            let index = index;
            trackBy: trackOptionsByFn
          "
          class="uj-px-d uj-py-d uj-cursor-pointer focus:uj-bg-border hover:uj-bg-surface-grape"
          [ngClass]="{
            'uj-bg-border': optionObject === selectedOption,
          }">
          <div class="uj-relative uj-flex uj-items-center uj-gap-d">
            <ng-container
              *ngTemplateOutlet="
                optionAvatar || placeholderAvatar || defaultAvatar;
                context: { option: optionObject, index: index }
              "></ng-container>
            <div class="uj-flex-grow uj-min-w-0">
              <ng-container
                *ngTemplateOutlet="
                  optionText || defaultOptionText;
                  context: { option: optionObject, index: index }
                "></ng-container>
            </div>
          </div>
        </li>
      </ul>

      <!-- dropdown footer -->
      <div
        *ngIf="optionsFooter"
        class="uj-mx-d uj-mb-d uj-border-t uj-border-border">
        <ng-container *ngTemplateOutlet="optionsFooter"></ng-container>
      </div>
    </div>
  </div>
</div>
