<unijob-sheet
  [id]="sheetId"
  size="s">
  <ng-container slot="header">
    <div class="uj-mb-e title-m">{{ "addAddon" | i18next }}</div>
  </ng-container>
  <ng-container slot="content">
    <div class="title-s">{{ "searchAddon" | i18next }}</div>
    <div class="uj-flex uj-flex-col uj-gap-e">
      <div>
        <ng-container *ngIf="plans$ | async as plans">
          <ng-container *ngIf="plans.length > 0">
            <p class="uj-mt-b text-m">{{ "chooseAPlan" | i18next }}</p>
            <div class="uj-flex uj-flex-col uj-gap-c uj-mt-d">
              <!-- [ngClass]="
                  (selectedPlan$ | async) === plan.planType
                    ? 'uj-border-accent'
                    : 'uj-border-border' -->
              <label
                *ngFor="let plan of plans"
                [ngClass]="{
                  'uj-bg-surface-grape': recentConfig,
                }"
                class="uj-flex uj-flex-row uj-items-center uj-justify-between uj-gap-c uj-p-d uj-border uj-rounded">
                <div class="uj-flex uj-flex-row uj-items-center uj-gap-c">
                  <unijob-radio-button
                    [disabled]="!!recentConfig"
                    name="radio-1"
                    colorTheme="white"
                    [alignRight]="false"
                    elevation="outline"
                    [options]="[{ value: plan.planType }]"
                    [ngModel]="selectedPlan$ | async"
                    (ngModelChange)="selectedPlan$.next($event)">
                  </unijob-radio-button>
                  <ui-library-icon
                    [icon]="
                      getFromPlanInfoMap('icon', plan.planType)
                    "></ui-library-icon>
                  <span class="uj-font-bold">{{
                    getFromPlanInfoMap(
                      recentConfig ? "extensionLabel" : "label",
                      plan.planType
                    ) | i18next
                  }}</span>
                  <ui-library-icon
                    icon="info_circle"
                    class="uj-pointer-events-auto"
                    color="grey"
                    [tooltipTemplate]="planTooltip"
                    [tooltipTemplateContext]="{
                      featurePoints: getFromPlanInfoMap(
                        'includesFeaturePoints',
                        plan.planType
                      ),
                    }"></ui-library-icon>
                </div>
                <div class="uj-font-bold">
                  {{
                    (recentConfig ? (plan.extensionPrice ?? 0) : plan.price)
                      | chfCurrency
                  }}
                </div>
              </label>
              <ng-template
                #planTooltip
                let-featurePoints="featurePoints">
                <ul class="uj-flex uj-flex-col uj-gap-b uj-p-b">
                  <li
                    *ngFor="let point of allPlanFeaturePoints"
                    class="uj-flex uj-gap-b"
                    [ngClass]="{
                      'uj-text-text-grey': !featurePoints.includes(point),
                    }">
                    <ng-container *ngIf="featurePoints.includes(point)">
                      <ui-library-icon
                        icon="check"
                        size="16"
                        color="pink"></ui-library-icon>
                    </ng-container>
                    <ng-container *ngIf="!featurePoints.includes(point)">
                      <span class="uj-w-[16px] uj-text-center">-</span>
                    </ng-container>
                    {{ "SearchPlanFeaturePoint." + point | i18next }}
                  </li>
                </ul>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="options$ | async as options">
          <ng-container *ngIf="options.length > 0">
            <ng-container *ngIf="selectedOptions$ | async as selectedOptions">
              <p class="uj-mt-e uj-mb-b text-m-b">
                {{ "Options" | i18next }}
              </p>
              <ng-container *ngFor="let option of options">
                <label
                  class="uj-flex uj-flex-row uj-items-center uj-justify-between uj-gap-c uj-p-d uj-border uj-rounded"
                  [ngClass]="{
                    'uj-bg-surface-grape uj-pointer-events-none':
                      recentConfig ||
                      !selectedPlan$.value ||
                      !option.onlyForPlans.includes(selectedPlan$.value),
                  }">
                  <div class="uj-flex uj-flex-row uj-items-center uj-gap-c">
                    <!-- [isDisabled]="" did not work at implementation -->
                    <unijob-checkbox
                      colorTheme="white"
                      [alignRight]="false"
                      elevation="outline"
                      [isChecked]="selectedOptions.includes(option.optionType)"
                      [ngClass]="{
                        'uj-opacity-34 uj-pointer-events-none':
                          recentConfig ||
                          !selectedPlan$.value ||
                          !option.onlyForPlans.includes(selectedPlan$.value),
                      }"
                      (toggleState)="toggleOption(option.optionType)">
                    </unijob-checkbox>
                    <ui-library-icon
                      [icon]="
                        getFromOptionInfoMap('icon', option.optionType)
                      "></ui-library-icon>
                    <span class="uj-font-bold">{{
                      getFromOptionInfoMap("label", option.optionType)
                    }}</span>
                    <ui-library-icon
                      class="uj-pointer-events-auto"
                      color="grey"
                      icon="info_circle"
                      [tooltipTemplate]="optionTooltip"
                      [tooltipTemplateContext]="{
                        featurePoints: getFromOptionInfoMap(
                          'featurePoints',
                          option.optionType
                        ),
                        optionType: option.optionType,
                      }"></ui-library-icon>
                  </div>

                  <div
                    class="uj-font-bold"
                    *ngIf="(recentConfig ? 0 : option.price) > 0">
                    +
                    {{ (recentConfig ? 0 : option.price) | chfCurrency }}
                  </div>
                </label>
                <div
                  class="uj-mt-d"
                  *ngIf="
                    !recentConfig &&
                    selectedPlan$.value &&
                    selectedOptions.includes(option.optionType) &&
                    getFromOptionInfoMap('warningIfActive', option.optionType)
                  ">
                  <unijob-info-box
                    type="WARNING"
                    [message]="
                      getFromOptionInfoMap(
                        'warningIfActive',
                        option.optionType
                      ) ?? '' | i18next
                    ">
                  </unijob-info-box>
                </div>
              </ng-container>
              <ng-template
                #optionTooltip
                let-featurePoints="featurePoints"
                let-optionType="optionType">
                <ul class="uj-flex uj-flex-col uj-gap-b uj-p-b">
                  <li
                    *ngFor="let point of featurePoints"
                    class="uj-flex uj-gap-b">
                    <ui-library-icon
                      icon="check"
                      size="16"
                      color="pink"></ui-library-icon>
                    {{ optionType + "FeaturePoint." + point | i18next }}
                  </li>
                </ul>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
        <div class="uj-flex uj-flex-col uj-gap-c uj-mt-d"></div>
      </div>
      <div class="uj-flex uj-flex-col uj-gap-b">
        <p class="uj-font-bold">
          {{ (needsToBeRequested ? "wishDate" : "startDate") | i18next
          }}<span class="red-text">*</span>
        </p>
        <unijob-date-picker
          label="Datum"
          fontWeight="font-normal"
          [hasLastDay]="true"
          mode="single"
          language="de"
          errorMessage=""
          [dayDisabledFunctions]="[dayIsDisabled]"
          [ngModel]="startDate | async"
          (ngModelChange)="startDate.next($event)"
          [showInvalidState]="true"
          [showValidState]="false"></unijob-date-picker>
        <p class="text-xs">
          <ng-container *ngIf="dateWithDistance | async as dateWithDistance">
            {{
              "activeTill"
                | i18next
                  : {
                      until: dateWithDistance.endDateFormatted,
                      untilDays: dateWithDistance.endDateDistance,
                    }
            }}
          </ng-container>
        </p>
      </div>
      <div class="uj-flex uj-flex-col uj-gap-b">
        <p class="uj-font-bold">{{ "remarksForPublishing" | i18next }}</p>
        <unijob-textarea
          placeholder="{{ 'addRemarksLikeKeywords' | i18next }}"
          elevation="outline"
          theme="white"
          name="notes"
          id="notes"
          [footer]="false"
          [clearable]="false"
          [showValidState]="false"
          [showInvalidState]="true"
          [required]="false"
          [maxChars]="0"
          [displayAutoSaveIcon]="false"
          [rows]="3"
          [(ngModel)]="notes"
          errorMessage=""
          size="size-m"></unijob-textarea>
      </div>
      <div class="uj-flex uj-flex-col uj-gap-b">
        <ng-container *ngIf="totals$ | async as totals">
          <div class="uj-flex uj-mb-c">
            <div
              class="uj-flex uj-flex-row uj-items-center uj-flex-grow uj-gap-c">
              <span class="title-xs">{{ "commission" | i18next }}</span>
            </div>
            <span class="uj-w-1/4 uj-px-d text-l-b">{{
              totals.commission | chfCurrency
            }}</span>
          </div>
          <app-commission-input
            [options]="firstCommissionUserOptions"
            [selectedOption]="firstCommissionUser"
            [commissionTotal]="totals.commission"
            [commission]="(firstCommission | async) || 0"
            [otherCommissions]="(secondCommission | async) || 0"
            [loggedInUser]="loggedInUser"
            [splitCommission]="splitCommissionEvenly"
            (selectedUserChanged)="updateFirstChosen($event)"
            (commissionChanged)="firstCommission.next($event)">
          </app-commission-input>
          <div class="uj-my-c unijob-divider size-s uj-text-border">
            <span
              class="text-s uj-block uj-px-c uj-bg-surface-main uj-z-[1] uj-text-text-grey"
              >{{ "splitCommission" | i18next }}</span
            >
          </div>
          <app-commission-input
            [options]="secondCommissionUserOptions"
            [selectedOption]="secondCommissionUser"
            [commissionTotal]="totals.commission"
            [otherCommissions]="(firstCommission | async) || 0"
            [loggedInUser]="loggedInUser"
            [splitCommission]="splitCommissionEvenly"
            (selectedUserChanged)="updateSecondChosen($event)"
            (commissionChanged)="secondCommission.next($event)"
            selectClearable></app-commission-input>
        </ng-container>
        <unijob-error-hint
          *ngIf="
            commissionOverLimit ||
            noNegativeCommission ||
            commissionSplitDoesNotAddUpToTotal
          "
          [errorMessage]="
            commissionOverLimit
              ? ('commissionOverLimit' | i18next)
              : commissionSplitDoesNotAddUpToTotal
                ? ('commissionSplitDoesNotAddUpToTotal' | i18next)
                : ('noNegativeCommission' | i18next)
          ">
        </unijob-error-hint>
      </div>
    </div>
  </ng-container>
  <ng-container
    slot="footer"
    class="uj-border-t uj-border-border">
    <div class="uj-flex uj-justify-between uj-w-full uj-mt-d uj-mb-c">
      <span class="text-l">{{ "commissionTotalWithoutTax" | i18next }}</span>
      <span
        class="text-l-b"
        *ngIf="totals$ | async as totals"
        >{{ totals.price | chfCurrency }}</span
      >
    </div>
    <!-- uj-w-full is not working -->
    <button
      *ngIf="searchChannel$ | async as searchChannel"
      ui-library-button-solid
      type="button"
      iconLeft="money"
      class="uj-w-full uj-whitespace-nowrap"
      (click)="submitAddon(searchChannel)"
      style="width: 100%"
      [disabled]="!!(orderButtonIsDisabled$ | async)">
      {{ "orderWithCosts" | i18next }}
    </button>
  </ng-container>
</unijob-sheet>
