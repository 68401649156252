import {
  ChangelogFieldDisplayType,
  TalentFragment,
  TalentHistoryEntryFragment,
  TalentUpdateInput,
  TalentUpdateOutput,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { TalentStringFields } from '../../talent-sheet/talent-sheet.types';
import { resolveChangelogAction } from './resolveChangelogAction';
import i18next from 'i18next';
import { revertResultTypeIsIdentical } from './revertResultTypeIsIdentical';
import { CountriesService } from '../../../../core/services/countries.service';
import {
  getChangelogFieldLabel,
  getChangelogGroupLabel,
} from './getChangelogFieldLabel';
import { getSupportedLanguageOptions } from '../../vacancy-detail/helpers/getSupportedLanguageOptions';

export function prepareTalentChangLogString(
  entry: TalentHistoryEntryFragment,
  fieldName: keyof TalentStringFields,
  talent: TalentFragment,
  input: TalentUpdateInput,
  countriesService: CountriesService,
): PreparedTalentChangeLog {
  const displayedCurrentValue = getDisplayedValueByFieldName(
    fieldName,
    countriesService,
    input,
  );
  const displayedPreviousValue = getDisplayedValueByFieldName(
    fieldName,
    countriesService,
    entry.revertInput,
  );
  return {
    ...entry,
    fieldName,
    fieldLabel: getChangelogFieldLabel(fieldName),
    groupLabel: getChangelogGroupLabel(fieldName),
    displayedCurrentValue,
    displayedPreviousValue,
    revertInput: entry.revertInput,
    action: resolveChangelogAction(
      displayedCurrentValue,
      displayedPreviousValue,
    ),
    contentIsIdentical: revertResultTypeIsIdentical(fieldName, entry, talent),
    mutationFieldType: ChangelogFieldDisplayType.STRING,
  };
}

function getDisplayedValueByFieldName(
  fieldName: keyof TalentStringFields,
  countriesService: CountriesService,
  input?: TalentUpdateInput | TalentUpdateOutput | null,
) {
  if (!input?.[fieldName]) return;
  switch (fieldName) {
    case 'communicationLanguage':
      return getSupportedLanguageOptions(true).find(
        (o) => o.value === input[fieldName],
      )?.label;
    case 'maxCommutingTimeMinutes':
      return input[fieldName] + ' min';
    case 'workloadPercentageMax':
    case 'workloadPercentageMin':
    case 'workloadPercentage':
      return input[fieldName] + ' %';
    case 'employmentType':
      return i18next.t('EmploymentTypeEnum.' + input[fieldName]);
    case 'maritalStatus':
      return i18next.t('MaritalStatusEnum.' + input[fieldName]);
    case 'residencePermit':
      return i18next.t('ResidencePermitEnum.' + input[fieldName]);
    case 'sex':
      return i18next.t('Salutation.' + input[fieldName]);
    case 'nationality': {
      const code = input[fieldName];
      if (!code) return;
      return countriesService.getNationalityByCode(code)?.label;
    }
    case 'workAvailability':
      return i18next.t('WorkAvailabilityEnum.' + input[fieldName]);
    default:
      return input?.[fieldName]?.toString();
  }
}
