/**
 * Downloads a given Blob object as a file.
 *
 * @throws {Error} If the blob or filename is not valid.
 */
export function downloadBlobAsFile(blob: Blob, filename: string): void {
  if (filename.trim() === '') {
    throw new Error('Invalid filename');
  }

  try {
    const downloadURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadURL;
    a.download = filename.trim();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(downloadURL);
  } catch (error) {
    console.error('Failed to download the file:', error);
  }
}
