<unijob-sheet
  [id]="sheetId"
  (sheetClosed)="sheetClosed.emit()"
  size="s">
  <ng-container slot="header">
    <h2 class="title-m">
      {{ "readyToPublish" | i18next }}
      <ng-container>🤔</ng-container>
    </h2>
  </ng-container>
  <ng-container slot="content">
    <div class="uj-flex uj-flex-col uj-gap-f">
      <unijob-info-box
        *ngIf="invalidFields.length > 0; else allRequiredFieldsAreFilledOut"
        type="ERROR"
        [title]="'pleaseCheckTheFollowingFields' | i18next">
        <ng-container slot="message">
          <div class="uj-flex uj-flex-col uj-gap-c">
            <div
              *ngFor="let field of invalidFields"
              class="uj-flex uj-gap-c">
              <unijob-icon icon="arrow_right"></unijob-icon>
              <span class="text-l">{{ field | i18next }}</span>
            </div>
          </div>
        </ng-container>
      </unijob-info-box>
      <div class="uj-flex uj-flex-col uj-gap-c">
        <span class="title-xs">{{ "checklist" | i18next }}</span>
        <unijob-checkbox
          [(ngModel)]="spellingChecked"
          [label]="'spellingChecked' | i18next"></unijob-checkbox>
      </div>
      <!-- <unijob-switch [disabled]="status !== 'DRAFT'" does not work for some reason </unijob-switch> -->
      <div
        *ngIf="status === 'DRAFT'"
        class="uj-flex uj-flex-col uj-gap-c">
        <span class="title-xs">{{ "jobNotificationRequired" | i18next }}</span>
        <div class="uj-flex uj-gap-c uj-justify-between uj-items-center">
          <span>{{ "jobNotificationRequired" | i18next }}</span>

          <unijob-switch
            round
            [(ngModel)]="jobNotificationRequired"></unijob-switch>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <button
      *ngIf="status === 'SCHEDULED' || jobNotificationRequired"
      [disabled]="invalidFields.length > 0 || !spellingChecked"
      (click)="publishVacancy()"
      class="uj-w-full"
      ui-library-button-solid
      type="button"
      iconLeft="upload_cloud">
      {{
        (hasChangesSinceLastPublication
          ? "publishChangesInDays"
          : "publishVacancyInDays"
        )
          | i18next: { days: daysUntilPublish }
          | firstUppercase
      }}
    </button>
    <button
      *ngIf="status !== 'SCHEDULED' && !jobNotificationRequired"
      [disabled]="invalidFields.length > 0 || !spellingChecked"
      (click)="publishVacancy()"
      class="uj-w-full"
      ui-library-button-solid
      type="button"
      iconLeft="upload_cloud">
      {{
        (hasChangesSinceLastPublication ? "publishChanges" : "publishVacancy")
          | i18next
          | firstUppercase
      }}
    </button>
  </ng-container>
</unijob-sheet>

<ng-template #allRequiredFieldsAreFilledOut>
  <unijob-info-box
    type="SUCCESS"
    [message]="'allRequiredFieldsAreFilled' | i18next"></unijob-info-box>
</ng-template>
