import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DatePickerModule, InputTextModule } from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { Address, ArrayActionEnum } from '../../../../../../graphql/generated';
import { randomId } from '../../../../../helpers/functions/randomId';
import { AddressFormGroup } from '../../../talent-sheet.types';
import { TalentSharedService } from '../../../services/talent-shared.service';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import { CountriesService } from '../../../../../../core/services/countries.service';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  FormFieldComponent,
  ModalComponent,
  ModalService,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';
import { startWith, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getFormData } from '../../../../../helpers/functions/getFormData';

@Component({
  standalone: true,
  selector: 'app-talent-addresses-address',
  templateUrl: './talent-addresses-address.component.html',
  imports: [
    InputTextModule,
    I18NextModule,
    SharedDefaultModule,
    ReactiveFormsModule,
    SimpleSelectComponent,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    ButtonTextComponent,
    ModalComponent,
    DatePickerModule,
    FormFieldComponent,
    TextInputComponent,
  ],
})
export class TalentAddressesAddressComponent
  implements OnChanges, OnDestroy, OnInit
{
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentSharedService);
  countriesService = inject(CountriesService);
  countryOptions = this.countriesService.getCountries();
  modals = inject(ModalService);
  addressesFormArray = this.formService.addressesFormArray;

  destroyed$ = new Subject<void>();

  @Input({ required: true }) address!: FormGroup<AddressFormGroup>;
  @Output() isInEditMode = new EventEmitter<boolean>();

  isEditingAddress = false;

  deleteConfirmationModalId = 'deleteConfirmation' + randomId();

  addressForGoogleMaps: Address | null = null;
  componentId = randomId();

  ngOnInit() {
    this.address.valueChanges
      .pipe(takeUntil(this.destroyed$), startWith(this.address.value))
      .subscribe((address) => {
        if (
          !address ||
          !address.streetAndNumber ||
          !address.zip ||
          !address.location ||
          !address.country
        )
          return null;
        this.addressForGoogleMaps = {
          street: address.streetAndNumber,
          zip: address.zip,
          location: address.location,
          country: address.country,
        };
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const isNewEntry = changes.address.currentValue.value.isNewEntry;
    if (isNewEntry) {
      this.setEditingAddress(true);
    }
  }

  deleteAddress() {
    this.updateAddress(this.address, ArrayActionEnum.REMOVED);
    this.addressesFormArray.removeAt(
      this.addressesFormArray.controls.indexOf(this.address),
    );
    this.setEditingAddress(false);
  }

  editAddress() {
    this.setEditingAddress(true);
  }

  cancelEditAddress() {
    const addresses = this.formService.addressesFormArray;
    addresses.removeAt(addresses.controls.indexOf(this.address));
    this.setEditingAddress(false);
  }

  addAddress() {
    this.updateAddress(this.address, ArrayActionEnum.ADDED);
    this.address.controls.isNewEntry.setValue(false);
    this.setEditingAddress(false);
  }

  updateAddressEntry() {
    this.updateAddress(this.address, ArrayActionEnum.CHANGED);
    this.setEditingAddress(false);
  }

  updateAddress(
    childControl: FormGroup<AddressFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const addressData = getFormData(childControl);
    delete addressData.isNewEntry;
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !addressData) {
      console.error('Talent uuid is not valid');
      return;
    }

    addressData.type = arrayAction;

    this.formService
      .updateTalentApi({
        addresses: [addressData],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }

  setEditingAddress(value: boolean) {
    this.isEditingAddress = value;
    this.isInEditMode.emit(value);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
