{
  "absencesEnum": {
    "ACCIDENT": "Unfall",
    "BIRTH_OR_WEDDING_OF_CHILD": "Geburt oder Heirat eines Kindes",
    "CARING_FOR_SICK_CHILD": "Pflege eines kranken Kindes",
    "DEATH_IN_HOUSEHOLD": "Todesfall im Haushalt",
    "DEATH_OF_CLOSE_RELATIVE": "Todesfall eines nahen Verwandten",
    "FULFILLMENT_OF_LEGAL_OBLIGATIONS": "Erfüllung gesetzlicher Pflichten",
    "MATERNITY_LEAVE": "Mutterschaftsurlaub",
    "MILITARY_OR_CIVIL_SERVICE": "Militär/Zivildienst/Zivilschutz",
    "MILITARY_RECRUITMENT_OR_INSPECTION": "Militärische Rekrutierung/Inspektion",
    "RESIDENCE_MOVING": "Umzug",
    "SICKNESS": "Krankheit",
    "VACATION_PAID": "Ferien bezahlt",
    "VACATION_UNPAID": "Ferien unbezahlt",
    "WEDDING": "Hochzeit"
  },
  "dayStatusEnum": {
    "APPROVED": "Freigegeben",
    "HAS_REPORT": "Stunden als Dokument eingereicht",
    "OPEN": "Offen",
    "TO_BE_APPROVED": "Eingereicht"
  },
  "documentTypesEnum": {
    "AHV": "AHV-Karte",
    "CORRESPONDENCE": "Korrespondenz",
    "CRIMINAL_RECORD": "Auszug Strafregister",
    "CV": "Lebenslauf",
    "DEPT_ENFORCEMENT_REGISTER_REPORT": "Auszug Betreibungsregister",
    "EMPLOYER_STATEMENT": "Arbeiteitgeberbescheinigung",
    "FAMILY_ALLOWANCES": "Unterlagen Familienzulagen",
    "FOREIGNER_PERMIT": "Ausländerausweis",
    "HOURS_REPORT": "Stundenrapport",
    "INVOICE": "Rechnung",
    "MEDICAL_CERTIFICATE": "Ärztliches Attest",
    "OTHER": "Andere",
    "PAY_SLIP": "Lohnabrechnung",
    "RECEIPT": "Quittung",
    "WAGE_STATEMENT": "Lohnausweis",
    "WORK_PERMIT": "Arbeitsbewilligung"
  },
  "DriversLicenseEnum": {
    "A": "A",
    "A1": "A1",
    "B": "B",
    "B1": "B1",
    "BE": "BE",
    "BPT": "BPT",
    "C": "C",
    "C1": "C1",
    "C1E": "C1E",
    "CE": "CE",
    "CZV": "CZV",
    "D": "D",
    "D1": "D1",
    "D1E": "D1E",
    "DE": "DE",
    "F": "F",
    "G": "G",
    "M": "M",
    "NEGATIVE_A": "-A"
  },
  "EmploymentTypeEnum": {
    "PERMANENT": "Festanstellung",
    "TEMPORARY": "Temporär"
  },
  "expensesEnum": {
    "ACCOMMODATION": "Unterkunft",
    "MEAL": "Verpflegung",
    "OTHER": "Andere",
    "RIDE_COST": "Transport"
  },
  "LanguageProficiencyEnum": {
    "A1": "A1 - Anfänger",
    "A2": "A2 - Grundkenntnisse",
    "B1": "B1 - Fortgeschritten / Gut",
    "B2": "B2 - Sehr Gut",
    "C1": "C1 - Verhandlungssicher",
    "C2": "C2 - Muttersprachliches Niveau",
    "Advantageous": "Von Vorteil"
  },
  "ChannelExportUniversaljobSearchPlanEnum": {
    "PUSH": "Push",
    "TARGET": "Target",
    "MAX": "Max"
  },
  "ResidencePermitEnum": {
    "NONE": "Keine",
    "REPORTING_PROCEDURE": "Meldeverfahren",
    "B": "B - Aufenthaltsbewilligung",
    "C": "C - Niederlassungsbewilligung",
    "Ci": "Ci - Aufenthaltsbewilligung mit Erwerbstätigkeit für Drittstaatsangehörige",
    "F": "F - vorläufig aufgenommene Ausländer/innen",
    "G": "G - Grenzgängerbewilligung",
    "GMB": "GMB – Grenzgänger-Meldebestätigung FL",
    "L": "L - Kurzaufenthaltsbewilligung",
    "N": "N - Asylsuchende",
    "S": "S - vorübergehender Schutz an Schutzbedürftige"
  },
  "sexEnum": {
    "FEMALE": "Frau",
    "MALE": "Mann",
    "OTHER": "Andere"
  },
  "Salutation": {
    "FEMALE": "Liebe",
    "MALE": "Lieber",
    "OTHER": "Hallo"
  },
  "SupportedLanguageEnum": {
    "DE": "Deutsch",
    "FR": "Französisch",
    "EN": "Englisch"
  },
  "VacancyArchiveReasonEnum": {
    "COMPANY_FOUND_PLACEMENT": "Von Kunde selbst besetzt",
    "COMPANY_RECALLED": "Von Kunde nicht mehr benötigt",
    "ENOUGH_APPLICANTS_RECEIVED": "Genug Bewerber erhalten",
    "FALSE_ACTIVATION_OR_DUPLICATE": "Falsch aufgeschaltet / Duplikat",
    "OTHER_FOUND_PLACEMENT": "Von anderer Stellenvermittlung besetzt",
    "WE_FOUND_PLACEMENT": "Von uns erfolgreich besetzt",
    "OTHER": "Andere"
  },
  "VacancyEmploymentTypeEnum": {
    "APPRENTICESHIP": "Ausbildung",
    "FREELANCE": "Freiberuflich",
    "FULL_TIME": "Vollzeit",
    "INTERNSHIP": "Praktikum",
    "PART_TIME": "Teilzeit",
    "PER_DAY": "Tageweise",
    "PERMANENT": "Festanstellung",
    "TEMPORARY": "Temporär",
    "VOLUNTEER": "Freiwillig"
  },
  "VacancyPositionTypeEnum": {
    "EMPLOYEE": "Mitarbeiter",
    "LEAD": "Führungsposition",
    "OFFICER": "Fachverantwortung"
  },
  "vacancyStatusEnum": {
    "ARCHIVED": "Archiviert",
    "DELETED": "Gelöscht",
    "DRAFT": "Entwurf",
    "LIVE": "Aktiv",
    "SCHEDULED": "Warten"
  },
  "WorkArrangementEnum": {
    "FIELD_SERVICE": "Aussendienst",
    "ON_CALL_DUTY": "Bereitschaftsdienst",
    "ON_SITE": "Vor Ort",
    "POOL": "Pool",
    "REMOTE": "Homeoffice",
    "SHIFT_WORK": "Schichtarbeit"
  },
  "WorkAvailabilityEnum": {
    "BLACKLISTED": "Blacklist",
    "IN_EMPLOYMENT": "Im Einsatz",
    "NOT_OPEN_TO_WORK": "Not open to work / Passiv",
    "OPEN_TO_WORK": "Open to work",
    "PLACED": "Vermittelt"
  },
  "EmploymentTermEnum": {
    "FIXED_TERM": "Befristet",
    "FULL_TERM": "Unbefristet"
  },
  "ReligiousDenominationEnum": {
    "BUDDHIST": "Buddhist",
    "EVANGELICAL_REFORMED": "Evangelisch-reformiert",
    "HINDU": "Hindu",
    "JEWISH": "Jüdisch",
    "MUSLIM": "Muslimisch",
    "NON_RELIGIOUS": "Konfessionslos",
    "ROMAN_CATHOLIC": "Römisch-katholisch"
  },
  "MaritalStatusEnum": {
    "DIVORCED": "Geschieden",
    "MARRIED": "Verheirate",
    "SINGLE": "Ledig",
    "WIDOWED": "Verwitwet",
    "IN_REGISTERED_PARTNERSHIP": "In eingetragener Partnerschaft",
    "DISSOLVED_PARTNERSHIP": "Aufgelöste Partnerschaft"
  },
  "ContactPhoneTypeEnum": {
    "PRIVATE": "Privat",
    "BUSINESS": "Geschäftlich"
  },
  "ContactEmailTypeEnum": {
    "PRIVATE": "Privat",
    "BUSINESS": "Geschäftlich"
  },
  "TalentSourceTypeEnum": {
    "WALK_IN": "Walk-in",
    "EMAIL": "E-Mail",
    "PHONE": "Telefon",
    "SOCIAL_MEDIA": "Soziale Medien",
    "JOB_APPLICATION": "Bewerbung"
  },
  "TalentSourcePlatformEnum": {
    "UNIVERSALJOB": "universalJob.ch",
    "JOBS_CH": "Jobs.ch",
    "LINKED_IN": "LinkedIn",
    "JOB_SHOT": "JobShot"
  },
  "MatchProcessStepEnum": {
    "ENTRY": "Eingang",
    "PREPARATION": "Vorbereitung",
    "RECOMMENDATION": "Empfehlung",
    "EXAMINATION": "Prüfung",
    "CONCLUSION" : "Abschluss"
  },
  "MatchStatusEnum": {
    "NEW": "Ungelesen",
    "ACTIVE": "Aktiv",
    "ARCHIVED": "Archiviert"
  },
  "StaffRoleEnum": {
    "BRANCH_LEADER": "Filialleiter",
    "REGIONAL_LEADER": "Regionalleiter",
    "ASSISTANT": "Assistent",
    "CONSULTANT": "Personalberater",
    "MARKETING": "Marketing",
    "ADMIN": "Admin"
  }
}
