import { SalaryUnitEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getSalaryUnitTextOptions = (i18next: I18NextPipe) => {
  return [
    {
      value: SalaryUnitEnum.HOUR,
      label: i18next.transform('hour'),
    },
    {
      value: SalaryUnitEnum.MONTH,
      label: i18next.transform('month'),
    },

    {
      value: SalaryUnitEnum.YEAR,
      label: i18next.transform('year'),
    },
  ] as const;
};
