<app-logged-out-wrapper
  *ngIf="!(userService.authUser$ | async)?.isVerified; else loggedIn"
  class="logged-out-wrapper">
  <ng-container slot="content">
    <div class="uj-flex uj-flex-col uj-gap-e">
      <ng-container [ngTemplateOutlet]="template404"></ng-container>
      <p
        class="login-hint"
        *ngIf="errorDescription">
        {{ errorDescription | i18next }}
      </p>
      <div class="short-button">
        <button
          ui-library-button-solid
          routerLink="/login"
          type="button"
          iconLeft="log_in">
          {{ "gotoLogin" | i18next }}
        </button>
      </div>
    </div>
  </ng-container>
</app-logged-out-wrapper>
<ng-template #loggedIn>
  <ng-container [ngTemplateOutlet]="template404"></ng-container>
</ng-template>

<ng-template #template404>
  <div class="page-not-fount-hint">
    <h1>Ooops!</h1>
    <p class="info-text">
      {{ title | i18next }}
    </p>
  </div>
</ng-template>
