import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SheetService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import {
  addBusinessDays,
  addDays,
  intervalToDuration,
  startOfDay,
} from 'date-fns';
import {
  VacancyDetailFragment,
  VacancyPublishableFieldsEnum,
  VacancyStatusEnum,
  VacancyStatusTransitionEnum,
} from '../../../../graphql/generated';
import { SubmitVacancyUpdateInput } from '../../../../pages/vacancies/vacancy.types';

@Component({
  selector: 'app-vacancy-publish-sheet',
  templateUrl: './vacancy-publish-sheet.component.html',
})
export class VacancyPublishSheetComponent {
  @Input({ required: true }) sheetId!: string;
  @Input() invalidFields: string[] = [];
  hasChangesSinceLastPublication = true;

  @Input() set vacancy(vacancy: VacancyDetailFragment | undefined) {
    if (
      !vacancy?.uniBaseX?.unpublishedChanges ||
      vacancy?.uniBaseX?.unpublishedChanges?.length === 0
    ) {
      this.hasChangesSinceLastPublication = false;
      return;
    } else {
      this.hasChangesSinceLastPublication = true;
    }
    const hasSpellcheckChanges =
      vacancy.uniBaseX?.unpublishedChanges.includes(
        VacancyPublishableFieldsEnum.employerDescriptionText,
      ) ||
      vacancy.uniBaseX?.unpublishedChanges.includes(
        VacancyPublishableFieldsEnum.functionText,
      ) ||
      vacancy.uniBaseX?.unpublishedChanges.includes(
        VacancyPublishableFieldsEnum.requirementsText,
      ) ||
      vacancy.uniBaseX?.unpublishedChanges.includes(
        VacancyPublishableFieldsEnum.benefitsText,
      ) ||
      vacancy.uniBaseX?.unpublishedChanges.includes(
        VacancyPublishableFieldsEnum.title,
      ) ||
      vacancy.uniBaseX?.unpublishedChanges.includes(
        VacancyPublishableFieldsEnum.jobLocationAddress,
      );
    this.spellingChecked = !hasSpellcheckChanges;
  }

  @Input() status? = VacancyStatusEnum.DRAFT;
  @Output() triggerAutoSave = new EventEmitter<SubmitVacancyUpdateInput>();
  @Output() sheetClosed = new EventEmitter<undefined>();

  jobNotificationRequired = false;
  spellingChecked = false;
  publishByDateIfNew: Date = this.calculatePublishByDate();

  constructor(
    private i18nPipe: I18NextPipe,
    private sheetService: SheetService,
  ) {}

  calculatePublishByDate() {
    // +5 Workdays starting calculation at next daybreak 0:00 (in other words, current day does not count).
    const publishByDate = addBusinessDays(
      startOfDay(addDays(new Date(), 1)),
      5,
    );
    // set current hour minute second for publishByDate
    publishByDate.setHours(new Date().getHours());
    publishByDate.setMinutes(new Date().getMinutes());
    publishByDate.setSeconds(new Date().getSeconds());
    return publishByDate;
  }

  publishVacancy() {
    if (
      this.status === VacancyStatusEnum.DRAFT &&
      this.jobNotificationRequired
    ) {
      const publishByDate = this.calculatePublishByDate();
      this.triggerAutoSave.emit({
        transition: VacancyStatusTransitionEnum.SCHEDULE,
        requiresReportingPriorToPublication: true,
        publishByDate: publishByDate.toISOString(),
      });
    } else if (this.status === VacancyStatusEnum.DRAFT) {
      this.triggerAutoSave.emit({
        transition: VacancyStatusTransitionEnum.PUBLISH,
      });
    } else if (this.status === VacancyStatusEnum.LIVE) {
      this.triggerAutoSave.emit({
        transition: VacancyStatusTransitionEnum.CORRECT_OR_REPUBLISH,
      });
    } else if (this.status === VacancyStatusEnum.SCHEDULED) {
      this.triggerAutoSave.emit({
        transition: VacancyStatusTransitionEnum.CORRECT_OR_REPUBLISH,
      });
    }
    this.closeSheet();
  }

  closeSheet() {
    this.sheetService.close(this.sheetId);
    this.sheetClosed.emit();
  }

  protected readonly VacancyStatusEnum = VacancyStatusEnum;

  public get daysUntilPublish() {
    let publishByDate = this.publishByDateIfNew;
    if (VacancyStatusEnum.SCHEDULED && this.vacancy?.uniBaseX?.publishByDate) {
      publishByDate = new Date(this.vacancy?.uniBaseX?.publishByDate);
    }
    const durationSincePublished = intervalToDuration({
      start: new Date(),
      end: publishByDate,
    });
    return durationSincePublished.days;
  }
}
