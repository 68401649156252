import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompaniesService } from '../../../../models/unibase/companies/companies.service';
import { BigSelectAction } from '../../big-select/big-select.component';
import { VacancyUnibaseCompanyImportComponent } from './unibase-company-import/unibase-company-import.component';
import generateCompanyLocationName from '../../../../shared/helpers/functions/company/generateCompanyLocationName';
import { AddressToGoogleMapUrl } from '../../../../shared/helpers/pipes/addressToGoogleMapUrl.pipe';
import {
  CompanyFragment,
  CompanyLocationFragment,
} from '../../../../graphql/generated';
import {
  ISelectedItem,
  NewStateGenerator,
  Select2State,
  SelectComponent,
  singleSelectBehavior,
  TextboxListItemComponent,
} from '@intemp/unijob-ui2';
import { AvatarSelectedItemComponent } from './avatar-selected-item/avatar-selected-item.component';
import { TitleTextValue } from './avatar-list-item/avatar-list-item.component';
import { CompanyLocationAvatar } from './CompanyLocationAvatar';
import { filter, map, scan, shareReplay, take } from 'rxjs/operators';
import { flow, HashMap, identity, pipe, ReadonlyArray, String } from 'effect';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';

export type CompanyLocationOption = CompanyLocationFragment & {
  companyName: string;
};

@Component({
  selector: 'app-vacancy-company-location-select',
  templateUrl: './vacancy-company-location-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VacancyCompanyLocationSelectComponent),
      multi: true,
    },
  ],
})
export class VacancyCompanyLocationSelectComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() id = 'companyLocation';
  @Input() hasError = false;
  @ViewChild(VacancyUnibaseCompanyImportComponent)
  unibaseCompanyImportComponent?: VacancyUnibaseCompanyImportComponent;

  @ViewChild(SelectComponent)
  select2!: SelectComponent<
    string,
    CompanyLocationAvatar,
    string,
    ISelectedItem<string, CompanyLocationAvatar>,
    ISelectedItem<string, CompanyLocationAvatar>
  >;

  @ViewChild(TextboxListItemComponent)
  searchListItem!: TextboxListItemComponent<
    string,
    CompanyLocationAvatar,
    string
  >;

  @ViewChild('placeholderAvatar', { read: TemplateRef, static: true })
  placeholderAvatar!: TemplateRef<any>;

  chosenKey: Subject<string | undefined> = new BehaviorSubject<
    undefined | string
  >(undefined);
  /**
   * TODO: Loading state is not implemented
   */
  loading = false;
  disabled = false;

  importUnibaseCompanySheet = {
    open: () => {
      this.unibaseCompanyImportComponent?.openSheet();
      this.unibaseCompanyImportComponent?.focusSearchField();
      this.searchListItem.valueUpdated$
        .pipe(take(1))
        .subscribe((searchTerm) => {
          this.unibaseCompanyImportComponent?.setSearchTerm(searchTerm);
        });
    },
    close: () => {
      this.unibaseCompanyImportComponent?.closeSheet();
    },
    complete: (companyLocation: CompanyLocationOption) => {
      this.writeValue(companyLocation).then(() => {});
      this.onChange(companyLocation);
    },
  };
  private locationWithData$: Subject<
    HashMap.HashMap<
      string,
      {
        locationId: string;
        companyId: string;
        locationFragment: CompanyLocationFragment;
        companyLocationAvatar: CompanyLocationAvatar;
      }
    >
  > = new ReplaySubject(1);
  /**
   * Stores all previously fetched location data.
   * @private
   */
  private locationWithDataCumulated$: Observable<
    HashMap.HashMap<
      string,
      {
        locationId: string;
        companyId: string;
        locationFragment: CompanyLocationFragment;
        companyLocationAvatar: CompanyLocationAvatar;
      }
    >
  > = this.locationWithData$.pipe(
    scan((previous, now) => HashMap.union(previous, now)),
    shareReplay(1),
  );
  options5: Observable<CompanyLocationAvatar[]> =
    this.locationWithDataCumulated$.pipe(
      map(
        flow(
          HashMap.values,
          ReadonlyArray.fromIterable,
          ReadonlyArray.map(
            ({ companyLocationAvatar }) => companyLocationAvatar,
          ),
        ),
      ),
    );

  searchTermProvided$ = new ReplaySubject<boolean>(1);
  constructor(
    private companiesService: CompaniesService,
    private addressToGoogleMapUrl: AddressToGoogleMapUrl,
  ) {}

  ngAfterViewInit(): void {
    this.searchListItem.valueUpdated$
      .pipe(
        map((searchTerm) => searchTerm !== ''),
        distinctUntilChanged(),
      )
      .subscribe(this.searchTermProvided$);
    this.select2._isExpanded.pipe(filter(identity<boolean>)).subscribe(() => {
      this.textSearchCompanies('');
    });
    this.searchListItem.valueUpdated$.subscribe((searchTerm: string) => {
      this.textSearchCompanies(searchTerm);
    });
  }

  public onChange = (
    value: CompanyLocationFragment,
  ): CompanyLocationFragment => {
    console.log('value', value);
    return value;
  };

  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  async writeValue(
    companyLocation: CompanyLocationFragment | null,
  ): Promise<void> {
    if (!companyLocation) return;
    this.companiesService
      .companyQueryByLocation({ uuid: companyLocation.uuid })
      .subscribe((company) => {
        this.addData(companyLocation, company);
        this.chosenKey.next(companyLocation.uuid);
      });
  }

  private generateData(
    location: CompanyLocationFragment,
    company: CompanyFragment,
    addressToGoogleMapUrl: AddressToGoogleMapUrl,
  ) {
    return {
      locationId: location.uuid,
      companyId: company.uuid,
      locationFragment: location,
      companyLocationAvatar: new CompanyLocationAvatar(
        generateCompanyLocationName(company, location),
        location,
        addressToGoogleMapUrl,
        this.placeholderAvatar,
      ),
    };
  }

  addData(location: CompanyLocationFragment, company: CompanyFragment) {
    this.locationWithData$.next(
      HashMap.fromIterable([
        [
          location.uuid,
          {
            locationId: location.uuid,
            companyId: company.uuid,
            locationFragment: location,
            companyLocationAvatar: new CompanyLocationAvatar(
              generateCompanyLocationName(company, location),
              location,
              this.addressToGoogleMapUrl,
              this.placeholderAvatar,
            ),
          },
        ] as const,
      ]),
    );
  }

  // TODO: Extract a function to simplify this and only need to supply the function within HashMap.filter
  readonly searchFilter: (
    value: string,
  ) => NewStateGenerator<string, CompanyLocationAvatar, string> =
    (searchValue: string) =>
    (state: Select2State<string, CompanyLocationAvatar, string>) => ({
      ...state,
      allItems: state.allItems,
      hiddenItems: pipe(
        state.allItems,
        HashMap.filter<string, CompanyLocationAvatar>(
          (value) =>
            !String.includes(String.toLowerCase(searchValue))(
              String.toLowerCase(value.title),
            ) &&
            !String.includes(String.toLowerCase(searchValue))(
              String.toLowerCase(value.text),
            ),
        ),
        HashMap.keys,
        ReadonlyArray.fromIterable,
      ),
      decoration: searchValue,
    });

  newKeyChosen = (key: string) => {
    this.locationWithDataCumulated$
      .pipe(take(1))
      .subscribe((locationData) =>
        this.onChange(HashMap.unsafeGet(locationData, key).locationFragment),
      );
  };

  actions: BigSelectAction[] = [];

  textSearchCompanies(search: string) {
    this.loading = true;
    this.companiesService.companiesTextSearch(search).subscribe((result) => {
      this.locationWithData$.next(
        pipe(
          result.items ?? [],
          ReadonlyArray.flatMap((company) =>
            company.locations.map((location) => ({ location, company })),
          ),
          ReadonlyArray.map(({ location, company }) =>
            this.generateData(location, company, this.addressToGoogleMapUrl),
          ),
          ReadonlyArray.map((data) => [data.locationId, data] as const),
          HashMap.fromIterable,
        ),
      );
      this.loading = false;
    });
  }

  protected readonly singleSelectBehavior = singleSelectBehavior;
  selectedComponent: Type<ISelectedItem<string, TitleTextValue>> =
    AvatarSelectedItemComponent;

  trackByKey(index: number, hasKey: CompanyLocationAvatar) {
    return hasKey.key;
  }

  protected readonly HashMap = HashMap;
}
