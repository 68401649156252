import { Component, Input } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject } from 'rxjs';
import {
  ChannelFragment,
  UniBaseXVacancyChannelConfigurationStatusEnum,
  UniBaseXVacancyChannelFragment,
  VacancyDetailFragment,
  VacancyStatusEnum,
} from '../../../../graphql/generated';
import { ChannelsService } from '../../../../models/unibase/channels/channels.service';
import { dayIsInPast } from '../../../../shared/helpers/functions/date-helpers/dayIsInPast';
import { UserService } from '../../../../models/shared/user/user.service';

@Component({
  selector: 'app-addon-channel',
  templateUrl: './addon-channel.component.html',
})
export class AddonChannelComponent {
  public extendAddonTooltip = '';
  public addAddonTooltip = '';

  constructor(
    private i18nPipe: I18NextPipe,
    private channelsService: ChannelsService,
    private userService: UserService,
  ) {}

  @Input({ required: true }) public channel!: ChannelFragment;
  public ConfigStatus = UniBaseXVacancyChannelConfigurationStatusEnum;

  public _vacancy!: VacancyDetailFragment;
  @Input({ required: true })
  set vacancy(vacancy: VacancyDetailFragment) {
    this._vacancy = vacancy;
    this.addAddonTooltip = !this.userMayEditAddons
      ? this.i18nPipe.transform('onlyUsersOfSameBranchCanAddAddons')
      : '';
    this.extendAddonTooltip = !this.userMayEditAddons
      ? this.i18nPipe.transform('onlyUsersOfSameBranchCanExtendAddons')
      : '';
  }

  get vacancy(): VacancyDetailFragment {
    return this._vacancy;
  }

  get userMayEditAddons() {
    return this._vacancy.permissions?.canEdit ?? false;
  }

  get userCanAcceptSearchChannelRequests() {
    return this._vacancy.permissions?.canAcceptSearchChannelRequests ?? false;
  }

  public startDate = new BehaviorSubject<Date[] | null>(null);

  protected dayIsDisabled = (date: Date) => {
    return dayIsInPast(date);
  };

  public acceptRequest(config: UniBaseXVacancyChannelFragment) {
    const start = this.startDate.value?.[0];
    if (!start) {
      return;
    }
    console.log(start);

    this.channelsService
      .acceptRequestChannel({
        vacancyUuid: this._vacancy.uuid,
        channelUuid: config.channelReference.uuid,
        start: start.toISOString(),
      })
      .subscribe();
  }

  // optimistic toggle state of switch
  private _switchState = false;
  get switchState(): boolean {
    return this._switchState;
  }

  private _configs: readonly UniBaseXVacancyChannelFragment[] = [];
  @Input()
  set configs(value: UniBaseXVacancyChannelFragment[]) {
    this._configs = value ?? [];
    // set switch state based on most recent configuration
    this._switchState = ['PENDING', 'RUNNING'].includes(
      this.recentConfig()?.status || '',
    );
  }
  get configs(): readonly UniBaseXVacancyChannelFragment[] {
    return this._configs;
  }

  public isDisabled() {
    return [VacancyStatusEnum.ARCHIVED, VacancyStatusEnum.DELETED].includes(
      this.vacancy.status,
    );
  }

  protected channelType() {
    return ChannelsService.getChannelType(this.channel);
  }

  protected recentConfig() {
    return ChannelsService.getMostRecentConfig(this._configs);
  }

  protected title(config: UniBaseXVacancyChannelFragment): string {
    const searchConfig = config.exportSettings?.universaljobSearch?.enabled
      ? config.exportSettings?.universaljobSearch
      : undefined;
    if (searchConfig && searchConfig.isExtension) {
      return (
        this.channel.name +
        ' - ' +
        this.getFromPlanInfoMap('extensionLabel', searchConfig.plan)
      );
    } else if (searchConfig && !searchConfig.isExtension) {
      return (
        this.channel.name +
        ' - ' +
        this.getFromPlanInfoMap('label', searchConfig.plan)
      );
    }
    return this.channel.name;
  }

  protected showConfigLights(): boolean {
    if (this._configs?.length === 0) {
      return false;
    }

    return true;
  }

  protected stateWording(config?: UniBaseXVacancyChannelFragment) {
    if (!config) {
      return '';
    }
    const startDate = ChannelsService.getConfigStart(config);
    const endDate = ChannelsService.getConfigEnd(config);
    const expirationDate = ChannelsService.getConfigExpiration(config);

    if (config.status === 'REQUESTED') {
      return this.i18nPipe.transform(
        'channelRequested',
        ChannelsService.daysFromDateFormatted(
          startDate,
          ChannelsService.daysUntilDateFormatted(expirationDate),
        ),
      );
    }
    if (config.status === 'PENDING') {
      return this.i18nPipe.transform(
        'scheduledToBePublished',
        ChannelsService.daysFromDateFormatted(
          startDate,
          ChannelsService.daysUntilDateFormatted(expirationDate),
        ),
      );
    }
    if (config.status === 'RUNNING' && startDate < new Date()) {
      return this.i18nPipe.transform(
        'activeLimited',
        ChannelsService.daysFromDateFormatted(
          startDate,
          ChannelsService.daysUntilDateFormatted(expirationDate),
        ),
      );
    }
    if (config.status === 'RUNNING' && startDate > new Date()) {
      return this.i18nPipe.transform(
        'scheduledToBePublished',
        ChannelsService.daysFromDateFormatted(
          startDate,
          ChannelsService.daysUntilDateFormatted(expirationDate),
        ),
      );
    }
    if (config.status === 'COMPLETED' || config.status === 'EXPIRED') {
      return this.i18nPipe.transform(
        'finishedRuntime',
        ChannelsService.daysFromDateFormatted(
          startDate,
          ChannelsService.daysUntilDateFormatted(endDate),
        ),
      );
    }
  }

  protected neverConfiguredWording() {
    if (
      [VacancyStatusEnum.ARCHIVED, VacancyStatusEnum.DELETED].includes(
        this.vacancy.status,
      )
    ) {
      return this.i18nPipe.transform('hasNotBeenActivated');
    }
  }

  protected price(config: UniBaseXVacancyChannelFragment) {
    const unijobSearchConfig = config.exportSettings?.universaljobSearch;
    if (unijobSearchConfig?.enabled) {
      const chosenPlan = unijobSearchConfig?.plan;
      if (!chosenPlan) return 0;

      const chosenOptions = unijobSearchConfig.options;
      const price = unijobSearchConfig.isExtension
        ? (unijobSearchConfig?.pricePerPlan[chosenPlan].extensionPrice ?? 0)
        : (unijobSearchConfig?.pricePerPlan[chosenPlan].price ?? 0);
      const optionsTotal =
        chosenOptions?.reduce(
          (acc, optionType) =>
            acc +
            (unijobSearchConfig.isExtension
              ? 0
              : (unijobSearchConfig?.pricePerOption?.[optionType]?.price ?? 0)),
          0,
        ) ?? 0;

      return price + optionsTotal;
    }
    return 0;
  }

  protected switchVisible(config: UniBaseXVacancyChannelFragment) {
    if (['RUNNING'].includes(config.status)) {
      return true;
    }
    return false;
  }

  protected light(config?: UniBaseXVacancyChannelFragment) {
    if (!config) {
      return 'grey';
    }
    if (config.status === 'PENDING') {
      return 'yellow';
    }

    if (config.status === 'REQUESTED') {
      return 'yellow';
    }

    // we simulate pending for configs that are not yet started
    const startDate = ChannelsService.getConfigStart(config);
    if (config.status === 'RUNNING' && startDate > new Date()) {
      return 'yellow';
    }

    if (config.status === 'RUNNING') {
      return 'green';
    }
    return 'grey';
  }

  vacancyStatusEnum = VacancyStatusEnum;
  configStatusEnum = UniBaseXVacancyChannelConfigurationStatusEnum;

  protected disabledReason = () => {
    // button only enabled if vacancy is live
    if (this.vacancy.status !== VacancyStatusEnum.LIVE) {
      return 'onlyAfterPublishing';
    }

    if (this.configs.length === 0) {
      return undefined;
    }

    // button is disabled if there is already a running configuration
    const lastConf = this.recentConfig();
    if (!lastConf) {
      return undefined;
    }
    if (
      lastConf.status === UniBaseXVacancyChannelConfigurationStatusEnum.RUNNING
    ) {
      return 'cannotBeCombined';
    }

    // button is disabled if already been ordered
    if (lastConf) {
      return 'alreadyOrdered';
    }

    // otherwise button is enabled
    return undefined;
  };

  protected getFromOptionInfoMap =
    this.channelsService.getFromOptionInfoMap.bind(this.channelsService);

  protected getFromPlanInfoMap = this.channelsService.getFromPlanInfoMap.bind(
    this.channelsService,
  );
}
