<unijob-sheet
  [id]="sheetId"
  size="l"
  (sheetScrolled)="service.triggerAnchorScroll()"
  (sheetClosed)="handleSheetClosed(sheetId)"
  contentFlex>
  @if (openSheetOverlayAction()) {
    <ng-container
      slot="overlay"
      *ngIf="service.talent$ | async as talent">
      <app-talent-document-preview
        class="uj-relative uj-flex uj-items-stretch uj-overflow-hidden uj-grow"
        [talent]="talent"
        [mode]="openSheetOverlayAction()"
        [uuid]="openSheetOverlayUuid()" />
    </ng-container>
  }
  <ng-container slot="nav">
    @if (openSheetOverlayAction()) {
      <button
        ui-library-button-solid
        iconOnly="close_preview_sidebar_4"
        corner="round"
        (click)="this.closePreview()"></button>
    } @else {
      <button
        ui-library-button-outline
        iconOnly="open_preview_sidebar_4"
        corner="round"
        (click)="this.openPreview()"></button>
    }
  </ng-container>
  <ng-container slot="header">
    <app-talent-sheet-header
      *ngIf="service.talent$ | async as talent; else loading"
      [talent]="talent"></app-talent-sheet-header>
  </ng-container>
  <ng-container slot="content">
    <ng-container *ngIf="service.talent$ | async as talent; else loading">
      <ng-container *ngIf="openSheetAction === tabEnum.PROFILE">
        <div
          class="lg:uj-max-w-[550px] uj-grow uj-h-fit"
          #scrollContainerChild>
          <ui-library-accordion
            id="personalDataAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'personalData' | i18next"
            [open]="openAccordions().personalDataAccordion"
            (openChange)="setAccordionOpen('personalDataAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-personal-data />
          </ui-library-accordion>
          <ui-library-accordion
            id="contactAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'contact' | i18next"
            [open]="openAccordions().contactAccordion"
            (openChange)="setAccordionOpen('contactAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-contact />
          </ui-library-accordion>
          <ui-library-accordion
            id="availabilityAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'availability' | i18next"
            [open]="openAccordions().availabilityAccordion"
            (openChange)="setAccordionOpen('availabilityAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-availability />
          </ui-library-accordion>
          <ng-container *ngIf="!environment.hideBetaTalentFields">
            <ui-library-accordion
              id="preferencesAccordion"
              class="uj-block uj-w-full uj-mb-g"
              [label]="'preferences' | i18next"
              [open]="openAccordions().preferencesAccordion"
              (openChange)="setAccordionOpen('preferencesAccordion', $event)"
              [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
              <app-talent-availability />
            </ui-library-accordion>
          </ng-container>
          <ui-library-accordion
            id="dataProtectionAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'dataProtection' | i18next"
            [open]="openAccordions().dataProtectionAccordion"
            (openChange)="setAccordionOpen('dataProtectionAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-data-protection />
          </ui-library-accordion>
          <ui-library-accordion
            id="internAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'intern' | i18next"
            [open]="openAccordions().internAccordion"
            (openChange)="setAccordionOpen('internAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-intern />
          </ui-library-accordion>
        </div>
        <aside
          *ngIf="openSheetAction === tabEnum.PROFILE"
          class="uj-sticky uj-top-0 uj-hidden uj-w-0 lg:uj-block uj-ml-f uj-grow uj-max-w-[188px]">
          <app-anchor-navigation
            [thresholdNumber]="158"
            [scrollContainer]="scrollContainerChild.parentElement"
            [thresholdMultiplier]="0"
            [anchorList]="
              openSheetAction === tabEnum.PROFILE
                ? [
                    {
                      name: 'personalData' | i18next,
                      id: 'personalDataAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },

                    {
                      name: 'contact' | i18next,
                      id: 'contactAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                    {
                      name: 'availability' | i18next,
                      id: 'availabilityAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                    {
                      name: 'dataProtection' | i18next,
                      id: 'dataProtectionAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                    {
                      name: 'intern' | i18next,
                      id: 'internAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                  ]
                : []
            ">
          </app-anchor-navigation>
        </aside>
      </ng-container>
      <ng-container *ngIf="openSheetAction === tabEnum.DOCUMENTS">
        <app-talent-documents [talent]="talent" />
      </ng-container>
    </ng-container>
  </ng-container>
</unijob-sheet>

<ng-template #loading>
  <div><app-loading-spinner></app-loading-spinner></div>
</ng-template>
