import { FormArray, FormGroup } from '@angular/forms';
import { disableFormGroup } from './disableFormGroup';
import { disableFormControl } from './disableFormControl';

export const disableFormArray = (formArray: FormArray) => {
  formArray.disable({
    emitEvent: false,
    onlySelf: true,
  });
  formArray.controls.forEach((control) => {
    if (control instanceof FormGroup) {
      disableFormGroup(control);
    } else if (control instanceof FormArray) {
      disableFormArray(control);
    } else {
      disableFormControl(control);
    }
  });
};
