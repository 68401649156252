import { NgModule } from '@angular/core';
import { GlobalAddComponent } from './global-add.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { SharedFormsModule } from '../shared-forms/shared-forms.module';
import { ContextMenuModule } from '@intemp/unijob-ui';
import { ButtonSolidComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [GlobalAddComponent],
  exports: [GlobalAddComponent],
  imports: [
    SharedDefaultModule,
    SharedFormsModule,
    ContextMenuModule,
    ButtonSolidComponent,
  ],
})
export class GlobalAddModule {}
