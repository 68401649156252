import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  UnibaseCompaniesQueryGQL,
  UnibaseCompaniesQueryVariables,
  UnibaseCompanyImportGQL,
} from '../../../graphql/generated';

@Injectable({
  providedIn: 'root',
})
export class UnibaseCompaniesService {
  constructor(
    private unibaseCompaniesQueryGQL: UnibaseCompaniesQueryGQL,
    private unibaseCompanyImportGQL: UnibaseCompanyImportGQL,
  ) {}

  unibaseCompaniesQuery(variables: UnibaseCompaniesQueryVariables) {
    return this.unibaseCompaniesQueryGQL
      .fetch(variables, {
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return res.data[`unibaseCompaniesQuery`];
        }),
      );
  }

  unibaseCompanyImport(unibaseCompanyId: number) {
    return this.unibaseCompanyImportGQL.mutate({ unibaseCompanyId }).pipe(
      map((res) => {
        return res.data?.unibaseCompanyImport;
      }),
    );
  }
}
