import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommissionInputComponent } from './commission-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule, InputTextModule, UnijobModule } from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { BigSelectModule } from '../big-select/big-select.module';
import { AvatarComponent, TextInputComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [CommissionInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    AvatarComponent,
    InputTextModule,
    FormsModule,
    UnijobModule,
    SharedDefaultModule,
    BigSelectModule,
    TextInputComponent,
  ],
  exports: [CommissionInputComponent],
})
export class CommissionInputModule {}
