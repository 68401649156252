export function generateTitleFromFilename(path: string): string {
  // Step 1: Extract the filename from the path (handles both '/' and '\')
  const filename = path.split(/[/\\]/).pop() || '';

  // Step 2: Remove the file extension by only considering the last dot as the start of the extension
  let baseName = filename.replace(/\.[^.]*$/, '');

  // Step 2b: replace remaining dots with spaces
  baseName = baseName.replace(/\./g, ' ');

  // Step 3: Replace underscores and hyphens with spaces
  baseName = baseName.replace(/[_-]/g, ' ');

  // Step 4: Convert camelCase to spaces (e.g., "exampleFileName" -> "Example File Name")
  baseName = baseName.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Step 5: Remove any numbers (optional, comment out if numbers should be kept)
  //   baseName = baseName.replace(/\d+/g, '');

  // Step 6: Capitalize the first letter of each word and ensure proper casing with mixed delimiters
  baseName = baseName.replace(
    /\b\w+/g,
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );

  // Step 7: Remove special characters (optional)
  baseName = baseName.replace(/[^a-zA-Z0-9\s]/g, '');

  // Step 8: Trim any extra spaces (leading, trailing, or multiple spaces)
  baseName = baseName.replace(/\s+/g, ' ').trim();

  // if empty return ... instead of empty string
  if (baseName === '') {
    return '...';
  }

  return baseName;
}
