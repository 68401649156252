import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { unijobIcon } from '@intemp/unijob-ui2/particle/icon/unijob-icon.model';
import { debounceTime, Subject, Subscription } from 'rxjs';

export type BigSelectAction = {
  icon: unijobIcon;
  labelTranslationKey: string;
  onClick: () => void;
};

@Component({
  selector: 'app-big-select',
  templateUrl: './big-select.component.html',
  styleUrls: ['./big-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('filterAnimation', [
      transition(':enter, * => 0, * => -1', []),
      transition(':increment', [
        query(
          ':enter',
          [
            style({ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }),
            stagger(15, [
              animate(
                '100ms ease-out',
                style({
                  opacity: 1,
                  height: '*',
                  paddingTop: '*',
                  paddingBottom: '*',
                }),
              ),
            ]),
          ],
          { optional: true },
        ),
      ]),
      transition(':decrement', [
        query(':leave', [
          stagger(15, [
            animate(
              '100ms ease-out',
              style({ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }),
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class BigSelectComponent<optionShape = any>
  implements OnInit, OnDestroy
{
  @Input() selectedAvatar?: TemplateRef<any>;
  @Input() selectedText?: TemplateRef<any>;
  @Input() selectedOption?: optionShape;
  @Input() optionAvatar?: TemplateRef<any>;
  @Input() optionText?: TemplateRef<any>;
  @Input() placeholderAvatar?: TemplateRef<any>;
  @Input() emptyOptionText?: TemplateRef<any>;
  @Input() emptyOptionAvatar?: TemplateRef<any>;
  @Input() actions: BigSelectAction[] = [];
  @Input() moreActionsContextMenu?: TemplateRef<any>;
  @Input() optionsFooter?: TemplateRef<any>;
  @Input() options: readonly optionShape[] = [];
  @Input() placeholder = 'Auswählen';
  @Input() searchPlaceholder = 'Suchen';
  @Input() searchDebounceTime = 150;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() hasError = false;
  @Input() showEmptyOption = false;
  @Input() trackOptionsByFn = (index: number, option: optionShape): any => {
    return index;
  };

  showOptions = false;
  @ViewChildren('optionList') optionList: { _results: any } | undefined;
  @ViewChild('searchElement', { static: false }) searchElement?: ElementRef;

  optionIndex = -1;
  displayedOptions?: any[];
  @Output() optionChosen = new EventEmitter<optionShape | null>();
  @Output() searchChangedDebounced = new EventEmitter<string>();
  @Output() searchChanged = new EventEmitter<string>();
  @Output() toggledOpen = new EventEmitter<boolean>();

  public close(): void {
    this.showOptions = false;
    this.options = [];
    this.searchTerm = '';
    this.toggledOpen.emit(false);
    this.searchChanged.emit('');
  }

  public open(): void {
    this.showOptions = true;
    this.toggledOpen.emit(true);
  }

  private searchFieldSubscription?: Subscription;
  private searchFieldChangeEmitter = new Subject<string>();
  public searchTerm = '';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.searchFieldSubscription = this.searchFieldChangeEmitter
      .pipe(debounceTime(this.searchDebounceTime))
      .subscribe((searchTerm) => {
        this.searchChangedDebounced.emit(searchTerm);
      });
  }

  searchFieldInputChanged(searchTerm: string): void {
    this.searchChanged.emit(searchTerm);
    this.searchFieldChangeEmitter.next(searchTerm);
  }

  ngOnDestroy(): void {
    this.searchFieldSubscription?.unsubscribe();
  }

  selectValue(optionObject: optionShape | null): void {
    this.optionChosen.emit(optionObject);
    this.close();
  }

  toggleOptions(): void {
    setTimeout(() => {
      const newOpen = !this.showOptions;
      if (newOpen) {
        this.open();
        this.changeDetectorRef.detectChanges();
        this.searchElement?.nativeElement.focus();
      } else {
        this.close();
      }
    });
  }

  onKeyDownNavigate(): void {
    if (!this.showOptions) {
      this.open();
    }
    if (!this.options) {
      return;
    }
    if (this.optionIndex < this.options?.length - 1) {
      this.optionIndex++;
      this.optionList?._results[this.optionIndex].nativeElement.focus();
    }
  }

  onKeyUpNavigate(): void {
    if (this.disabled) return;
    if (!this.showOptions) {
      this.open();
    }
    if (this.optionIndex > 0) {
      this.optionIndex--;
      this.optionList?._results[this.optionIndex].nativeElement.focus();
    }
  }

  onSpaceOpen() {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement.nodeName === 'INPUT' || !activeElement) {
      return;
    }
    if (activeElement.classList?.contains('list-item')) {
      activeElement.click();
      return;
    }
    this.toggleOptions();
  }

  handleEsc(event: Event) {
    event.stopPropagation();
    this.close();
  }

  closeSelectField() {
    this.close();
  }
}
