import { Component, inject, Input } from '@angular/core';
import {
  InfoBoxModule,
  InputTextModule,
  ModalService,
  TooltipModule,
} from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { randomId } from '../../../../../helpers/functions/randomId';
import { LinksFormGroup } from '../../../talent-sheet.types';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import { I18NextPipe } from 'angular-i18next';
import { ArrayActionEnum } from '../../../../../../graphql/generated';
import { isValidUrl } from '../../../../shared-forms/customValidators';
import { TalentSharedService } from '../../../services/talent-shared.service';
import { RouterLink } from '@angular/router';
import { LinkPipe } from '../../../../../helpers/pipes/link.pipe';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import {
  ButtonOutlineComponent,
  FormFieldComponent,
  TextInputComponent,
  ToastService,
} from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-links-entry',
  templateUrl: './talent-links-entry.component.html',
  imports: [
    InputTextModule,
    SharedDefaultModule,
    InfoBoxModule,
    ReactiveFormsModule,
    TooltipModule,
    RouterLink,
    LinkPipe,
    ButtonOutlineComponent,
    FormFieldComponent,
    TextInputComponent,
  ],
})
export class TalentLinksEntryComponent {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentSharedService);
  i18next = inject(I18NextPipe);
  toastService = inject(ToastService);
  modals = inject(ModalService);

  linkFormArray = this.formService.linksFormArray;

  @Input({ required: true }) linkGroup!: FormGroup<LinksFormGroup>;
  @Input() isNewEntry = false;

  componentId = randomId();

  onLinkChange($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    this.linkGroup.controls.url.setErrors(null);

    const isValid = isValidUrl(value);
    if (!isValid) {
      this.linkGroup.controls.url.setErrors({
        message: {
          key: 'invalidUrl',
        },
        onlyOneSeparatorAllowed: true,
      });
    }

    if (!isValid) return;

    if (this.isNewEntry) {
      this.updateLink(this.linkGroup, ArrayActionEnum.ADDED);
      this.linkGroup.reset({
        uuid: randomId(),
        type: ArrayActionEnum.ADDED,
        url: null,
      });
    } else {
      this.updateLink(this.linkGroup, ArrayActionEnum.CHANGED);
    }
  }

  deleteUrl() {
    this.updateLink(this.linkGroup, ArrayActionEnum.REMOVED);
    this.linkFormArray.removeAt(
      this.linkFormArray.controls.indexOf(this.linkGroup),
    );
  }

  updateLink(
    childControl: FormGroup<LinksFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const linkData = getFormData(childControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !linkData) {
      console.error('Talent uuid is not valid');
      return;
    }

    if (arrayAction === ArrayActionEnum.ADDED) {
      this.formService.addNewLink({ ...linkData });
    }

    linkData.type = arrayAction;

    this.formService
      .updateTalentApi({
        links: [linkData],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }
}
