import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { InfoBoxModule } from '@intemp/unijob-ui';
import { TalentDataPrivacyPolicyComponent } from './talent-data-privacy-policy/talent-data-privacy-policy.component';
import { TalentPrivacyPolicyService } from '../services/talent-privacy-policy.service';
import { ButtonOutlineComponent } from '@intemp/unijob-ui2';
import { environment } from '../../../../../environments/environment';

@Component({
  standalone: true,
  selector: 'app-talent-data-protection',
  templateUrl: './talent-data-protection.component.html',
  imports: [
    I18NextModule,
    SharedDefaultModule,
    InfoBoxModule,
    TalentDataPrivacyPolicyComponent,
    ButtonOutlineComponent,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentDataProtectionComponent {
  i18next = inject(I18NextPipe);
  service = inject(TalentPrivacyPolicyService);
  protected readonly environment = environment;
}
