import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ResponsiveService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, combineLatest, debounceTime } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import {
  CurrencyEnum,
  SalaryUnitEnum,
  UniBaseXVacancyUpdateInput,
  VacancyDetailUniBaseXVacancyFragment,
  VacancyPublishableFieldsEnum,
} from '../../../graphql/generated';
import { allowedSalaryInput } from '../../helpers/functions/allowedSalaryInput';
import { disableFormGroup } from '../../helpers/functions/disableFormGroup';
import { findMismatches } from '../../helpers/functions/findMismatches';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { parseValueToNumber } from '../../helpers/functions/parseValueToNumber';
import { patchFormControl } from '../../helpers/functions/patchFormControl';
import { saveDebounceMs } from '../../static/saveDebounceMs';
import { FocusTrackerDirective } from '../vacancy-detail/focusTracker.directive';
import { validateFormControl } from '../vacancy-detail/helpers/validateFormControl';
import {
  SubmitVacancyUpdateInput,
  VacancyCriteriaItemFormData,
} from '../../../pages/vacancies/vacancy.types';
import { customValidators } from '../shared-forms/customValidators';
import { getSalaryCurrencyTextOptions } from '../vacancy-detail/helpers/getSalaryCurrencyTextOptions';
import { getSalaryUnitTextOptions } from '../vacancy-detail/helpers/getSalaryUnitTextOptions';
import { salaryPaymentsPerYearOptions } from '../../constants/salaryPaymentsPerYearOptions';

type HintErrorMessages = {
  salary?: string;
};

@Component({
  selector: 'app-vacancy-salary-input',
  templateUrl: './vacancy-salary-input.component.html',
  styleUrls: ['./vacancy-salary-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancySalaryInputComponent implements OnInit, OnChanges {
  @Input() vacancy?: VacancyDetailUniBaseXVacancyFragment;
  @Input({ required: true }) formRef!: FormGroup;
  @Input() formIsDisabled = false;
  @Input({ required: true }) criteriaData!: VacancyCriteriaItemFormData;

  @Output() triggerAutoSave = new EventEmitter<
    Omit<UniBaseXVacancyUpdateInput, 'vacancyUuid'>
  >();

  publishSalary = new FormControl<boolean>(false);
  salaryFlexible = new FormControl<boolean | null>(null);
  salaryUnit = new FormControl<SalaryUnitEnum | null>(null);
  salaryMin = new FormControl<string | undefined>(undefined);
  salaryMax = new FormControl<string | undefined>(undefined);
  salary = new FormControl<string | undefined>(undefined);
  salaryHoursPerWeek = new FormControl<string | undefined>(undefined);
  salaryCurrency = new FormControl<CurrencyEnum | undefined>(undefined);
  salaryPaymentsPerYear = new FormControl<number | undefined>(undefined);

  formGroup = new FormGroup({
    publishSalary: this.publishSalary,
    salaryFlexible: this.salaryFlexible,
    salaryUnit: this.salaryUnit,
    salaryMin: this.salaryMin,
    salaryMax: this.salaryMax,
    salary: this.salary,
    salaryHoursPerWeek: this.salaryHoursPerWeek,
    salaryCurrency: this.salaryCurrency,
    salaryPaymentsPerYear: this.salaryPaymentsPerYear,
  });

  VacancyPublishableFieldsEnum = VacancyPublishableFieldsEnum;
  hasChanges(field: VacancyPublishableFieldsEnum) {
    return this.vacancy?.unpublishedChanges?.includes(field);
  }

  autoSaved: { [key: string]: boolean } = {};

  salaryUnitOptions = getSalaryUnitTextOptions(this.i18next);
  salaryCurrencyOptions = getSalaryCurrencyTextOptions();

  protected readonly SalaryUnitEnum = SalaryUnitEnum;

  isXsDown$ = this.responsiveService.isXsDown$;

  programmaticUpdate = new BehaviorSubject<boolean>(false);

  hintErrorMessages: HintErrorMessages = {};

  keysThatNeedToBeParsedToNumber: { [key: string]: boolean } = {
    salaryMin: true,
    salaryMax: true,
    salary: true,
    salaryHoursPerWeek: true,
    salaryPaymentsPerYear: true,
  };

  currencyMask = {
    mask: Number,
    scale: 0,
    signed: false,
    thousandsSeparator: "'",
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: '.',
    mapToRadix: ['.'],
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  };

  @ViewChildren(FocusTrackerDirective)
  focusTrackers!: QueryList<FocusTrackerDirective>;
  constructor(
    private responsiveService: ResponsiveService,
    private i18next: I18NextPipe,
  ) {}

  async ngOnInit() {
    this.updateFormValues(this.vacancy);
    if (this.formIsDisabled) {
      disableFormGroup(this.formGroup);
    }

    this.subscribeSalaryInputs();
    this.subscribeFormChanges();
    this.formRef.setControl('salary', this.formGroup);
  }

  subscribeSalaryInputs() {
    combineLatest([
      this.salaryMin.valueChanges.pipe(startWith(this.salaryMin.value)),
      this.salaryMax.valueChanges.pipe(startWith(this.salaryMax.value)),
      this.salary.valueChanges.pipe(startWith(this.salary.value)),
    ]).subscribe(() => {
      delete this.hintErrorMessages.salary;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.firstChange &&
      changes.criteriaData?.previousValue &&
      changes.criteriaData?.currentValue
    ) {
      this.patchFormValuesOnChange(
        changes.criteriaData.previousValue,
        changes.criteriaData.currentValue,
      );
    }
    if (changes.formIsDisabled) {
      if (changes.formIsDisabled.currentValue) {
        disableFormGroup(this.formGroup);
      } else {
        this.formGroup.enable({ emitEvent: false });
      }
    }
  }

  patchFormValuesOnChange(
    previous: VacancyCriteriaItemFormData,
    current: VacancyCriteriaItemFormData,
  ) {
    const differences = findMismatches(previous, current);
    if (!differences.length) return;

    this.programmaticUpdate.next(true);

    for (const key of differences) {
      const control = this.formGroup.get(key);
      if (!control) continue;
      const isFocused = this.focusTrackers.find((f) => f.targetID === key)
        ?.isFocused$.value;

      if (isFocused) continue;
      patchFormControl(
        control,
        current[key as keyof VacancyCriteriaItemFormData],
      );
    }

    this.programmaticUpdate.next(false);
  }

  updateFormValues(vacancy?: VacancyDetailUniBaseXVacancyFragment) {
    const {
      salaryMin,
      salaryMax,
      salary,
      salaryFlexible,
      salaryHoursPerWeek,
      salaryUnit,
      salaryPaymentsPerYear,
      salaryCurrency,
      publishSalary,
    } = vacancy ?? {};

    this.formGroup.patchValue({
      salaryMin: String(salaryMin),
      salaryMax: String(salaryMax),
      salary: String(salary),
      publishSalary: publishSalary ?? false,
      salaryFlexible: !!salaryFlexible,
      salaryHoursPerWeek: salaryHoursPerWeek
        ? String(salaryHoursPerWeek)
        : '42',
      salaryUnit: salaryUnit ?? SalaryUnitEnum.MONTH,
      salaryCurrency: salaryCurrency ?? CurrencyEnum.CHF,
      salaryPaymentsPerYear: salaryPaymentsPerYear ?? 12,
    });
  }

  subscribeFormChanges() {
    this.formGroup.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((controls) => {
        getObjectKeys(controls).forEach((key) => {
          const control = this.formGroup.controls[key];
          if (control.dirty) {
            this.submitInputFormControl(control, key);
          }
        });
        this.markFormAsPristine();
      });
  }

  submitInputFormControl(control: FormControl, key: string) {
    const submitDataInput: SubmitVacancyUpdateInput = {
      [key]: this.keysThatNeedToBeParsedToNumber[key]
        ? control.value !== null
          ? parseValueToNumber(control.value)
          : null
        : control.value,
    };
    control.markAsPristine();
    this.triggerSave(submitDataInput);
  }

  markFormAsPristine() {
    getObjectKeys(this.formGroup.controls).forEach((key) => {
      const control = this.formGroup.controls[key];
      if (control.dirty) {
        control.markAsPristine();
      }
    });
  }

  triggerSave(submitDataInput: SubmitVacancyUpdateInput) {
    this.triggerAutoSave.emit(submitDataInput);
    this.markAsAutoSaved(submitDataInput);
  }

  markAsAutoSaved(submitDataInput: SubmitVacancyUpdateInput) {
    Object.keys(submitDataInput).forEach((key) => {
      this.autoSaved[key] = true;
    });
  }

  filterClipboardValue(event: ClipboardEvent) {
    const value = event.clipboardData?.getData('text');
    if (!value) return;
    const hasNotAllowedChar = value.match('-');
    if (hasNotAllowedChar) {
      const focusedInputControlName = this.focusTrackers.find(
        (f) => f.isFocused$.value,
      )?.targetID as keyof VacancySalaryInputComponent;
      if (focusedInputControlName) {
        const componentFormControl = this[
          focusedInputControlName
        ] as AbstractControl;
        if (!componentFormControl || !componentFormControl.setValue) {
          console.error(
            `targetId ${focusedInputControlName} not found as formControl of component`,
          );
          return;
        }
        const parsedValue = value.replace('-', '');
        componentFormControl.setValue(parseValueToNumber(parsedValue));
      }
      event.preventDefault();
    }
  }

  public validateForPublishing() {
    this.validateFields();
    if (this.hintErrorMessages.salary) {
      this.salary.setErrors({ salary: true });
    } else {
      this.salary.setErrors(null);
    }
    this.validateForm();
  }

  validateForm() {
    const controlKeys = getObjectKeys(this.formGroup.controls);
    for (const key of controlKeys) {
      const control = this.formGroup.controls[key];
      if (!control || control.validator === null) continue;
      validateFormControl(control);
    }
  }

  validateFields() {
    delete this.hintErrorMessages.salary;
    const min = this.salaryMin.value;
    const max = this.salaryMax.value;
    const flexible = this.salaryFlexible.value;

    if (flexible) {
      const parsedMin = typeof min !== 'number' ? parseValueToNumber(min) : min;
      const parsedMax = typeof max !== 'number' ? parseValueToNumber(max) : max;
      if (parsedMin && parsedMax && parsedMin === parsedMax) {
        this.hintErrorMessages.salary = 'switchToFixedSalary';
      } else if (parsedMin && parsedMax && parsedMin > parsedMax) {
        this.hintErrorMessages.salary = 'fromValueCanNotBeGreaterThanToValue';
      } else if (!parsedMin || !parsedMax) {
        this.hintErrorMessages.salary = 'salaryCanNotBeZero';
      }
    } else {
      if (!this.salary.value) {
        this.hintErrorMessages.salary = 'salaryCanNotBeZero';
      }
    }
  }

  public enablePublishedValidation() {
    const { isEmpty } = customValidators;

    this.salary.setValidators([]);
    this.salaryMin.setValidators([]);
    this.salaryMax.setValidators([]);

    this.salary.setErrors(null);
    this.salaryMin.setErrors(null);
    this.salaryMax.setErrors(null);

    if (this.publishSalary.value) {
      if (!this.salaryFlexible.value) {
        this.salary.addValidators([isEmpty]);
      } else {
        this.salaryMin.addValidators([
          isEmpty,
          Validators.min(5),
          Validators.max(1000000),
        ]);
        this.salaryMax.addValidators([
          isEmpty,
          Validators.min(5),
          Validators.max(1000000),
        ]);
      }
    }
  }

  validateHideSalarySwitch(event: Event) {
    if (this.formIsDisabled || !this.publishSalary.value) return;
    this.validateFields();
    if (this.hintErrorMessages.salary) {
      event.preventDefault();
    }
  }

  protected readonly allowedSalaryInput = allowedSalaryInput;
  protected readonly salaryPaymentsPerYearOptions =
    salaryPaymentsPerYearOptions;
}
