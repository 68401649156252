<div class="uj-relative uj-flex uj-gap-c uj-px-d uj-py-c uj-rounded">
  <div class="uj-flex-1">
    <div class="uj-relative uj-bg-surface-main">
      <span class="text-s uj-text-text-grey">{{
        record.createdAt | date: DATE_TIME_FORMAT
      }}</span>
    </div>
    <div class="uj-flex uj-flex-col">
      <div class="uj-flex uj-items-center uj-gap-c">
        <div class="uj-self-start">
          <app-user-avatar
            *ngIf="user$ | async as user"
            [user]="user"
            [contextMenu]="userAvatarTemplate"
            interactive
            size="xs">
          </app-user-avatar>
        </div>
        <div class="uj-flex uj-flex-wrap uj-flex-1 uj-gap-b uj-pt-a">
          <span
            *ngIf="user$ | async as user"
            [contextMenu]="userAvatarTemplate"
            class="uj-cursor-pointer text-m-b hover:uj-text-text-accent-hover"
            >{{ user.profile.firstName + " " + user.profile.lastName + " "
            }}<ng-container *ngIf="isCurrentUser$ | async"
              >({{ "me" | i18next }})</ng-container
            ></span
          >
          @if (record.groupLabel && record.fieldLabel) {
            <span
              class="text-m"
              [innerHTML]="
                'talentChangelogEntry.nestedEntry'
                  | i18next
                    : {
                        entryName: record.fieldLabel | i18next,
                        parentName: record.groupLabel | i18next,
                        action: 'changelogActions.' + record.action | i18next,
                      }
              "></span>
          } @else if (!record.groupName && record.fieldLabel && record.action) {
            <span
              class="text-m"
              [innerHTML]="
                'talentChangelogEntry.singleEntry'
                  | i18next
                    : {
                        entryName: record.fieldLabel | i18next,
                        action: 'changelogActions.' + record.action | i18next,
                      }
              "></span>
          } @else if (record.customEntry) {
            <span
              class="text-m"
              [innerHTML]="record.fieldLabel"></span>
          }
        </div>
      </div>
      <div
        class="uj-flex uj-items-center uj-gap-c"
        *ngIf="record.mutationFieldType as displayType">
        <div class="uj-self-start uj-min-w-[24px]"></div>
        @if (displayType === DisplayType.IMAGE) {
          <ng-container *ngTemplateOutlet="imageContent"></ng-container>
        } @else if (displayType === DisplayType.PROSE_MIRROR) {
          <ng-container *ngTemplateOutlet="proseMirrorContent"></ng-container>
        } @else if (
          displayType === DisplayType.STRING ||
          displayType === DisplayType.BOOLEAN
        ) {
          <div class="uj-flex uj-flex-wrap uj-items-center uj-gap-b uj-pt-b">
            <ng-container *ngIf="record.displayedPreviousValue">
              <div>
                <span
                  class="uj-px-b uj-py-a uj-rounded input-text-s uj-bg-ui-warning-2-light"
                  >{{ record.displayedPreviousValue }}</span
                >
              </div>
              <ng-container *ngTemplateOutlet="arrowRight"></ng-container>
            </ng-container>
            <div *ngIf="record.displayedCurrentValue">
              <span
                class="uj-px-b uj-py-a uj-rounded input-text-s uj-bg-ui-positive-light"
                >{{ record.displayedCurrentValue }}</span
              >
            </div>
          </div>
        } @else {
          <div class="uj-bg-border-warning-1">
            MutationFieldType {{ record.fieldLabel }} not implemented in view
          </div>
        }
      </div>
    </div>
  </div>
  <div *ngIf="!record.hideRevertButton && record.revertInput">
    <button
      class="uj-mt-e"
      ui-library-button-outline
      iconOnly="undo"
      size="M"
      theme="flat"
      (click)="openModal(revertChangesModalId)"
      color="dark"></button>
  </div>
</div>

<ui-library-modal
  [id]="revertChangesModalId"
  size="S">
  <ng-container slot="header">
    {{ "revertChange" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <ng-container *ngIf="record.contentIsIdentical">
      {{
        "changeCanNotBeRevertedAsContentIsIdentical" | i18next | firstUppercase
      }}
    </ng-container>
    <ng-container *ngIf="!record.contentIsIdentical">
      {{ "doYouWantToRevertThisChange" | i18next | firstUppercase }}
    </ng-container>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="closeModal(revertChangesModalId)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{
          (record.contentIsIdentical ? "close" : "cancel")
            | i18next
            | firstUppercase
        }}
      </button>
      <button
        *ngIf="!record.contentIsIdentical"
        (click)="revertChanges(record)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button"
        variant="primary">
        {{ "revert" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>

<ng-template #userAvatarTemplate>
  <ng-container *ngIf="user$ | async as user">
    <app-user-info-context-menu [user]="user"></app-user-info-context-menu>
  </ng-container>
</ng-template>

<ng-template #proseMirrorContent>
  <div class="uj-flex uj-flex-col uj-w-full uj-gap-b uj-pt-b">
    <ng-container *ngIf="record.displayedPreviousValue">
      <div
        class="uj-px-b uj-py-a uj-rounded input-text-s uj-bg-ui-warning-2-light">
        <span
          class="ProseMirror-editor-style [&>ul]:uj-m-0"
          [innerHTML]="record.displayedPreviousValue"></span>
      </div>
      <span
        *ngIf="record.displayedCurrentValue"
        class="uj-w-full uj-min-h-[20px] uj-items-center uj-flex uj-justify-center">
        <unijob-icon icon="arrow_down" />
      </span>
    </ng-container>
    <div
      *ngIf="record.displayedCurrentValue"
      class="uj-px-b uj-py-a uj-rounded input-text-s uj-bg-ui-positive-hover">
      <span
        class="ProseMirror-editor-style [&>ul]:uj-m-0"
        [innerHTML]="record.displayedCurrentValue"></span>
    </div>
  </div>
</ng-template>

<ng-template #imageContent>
  <div class="uj-flex uj-flex-wrap uj-items-center uj-gap-b uj-pt-b">
    <ng-container *ngIf="record.displayedPreviousValue">
      <div>
        <div class="uj-px-b uj-py-a uj-rounded uj-bg-ui-warning-2-light">
          <img
            width="54"
            height="54"
            [src]="record.displayedPreviousValue" />
        </div>
      </div>
      <ng-container *ngTemplateOutlet="arrowRight"></ng-container>
    </ng-container>
    <div *ngIf="record.displayedCurrentValue">
      <div class="uj-px-b uj-py-a uj-rounded uj-bg-ui-positive-light">
        <img
          width="54"
          height="54"
          [src]="record.displayedCurrentValue" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #arrowRight>
  <span
    *ngIf="record.displayedCurrentValue"
    class="uj-min-h-[14px] uj-flex uj-items-center">
    <unijob-icon
      size="12"
      icon="arrow_right" />
  </span>
</ng-template>
