<unijob-sheet
  [id]="sheetId"
  size="m"
  (sheetClosed)="handleSheetClosed(sheetId)">
  <ng-container slot="header">{{ "changeHistory" | i18next }}</ng-container>
  <ng-container slot="content">
    <ng-container
      *ngIf="service.talentHistoryList$ | async as talentHistoryList">
      <div
        class="uj-flex uj-flex-col uj-gap-c uj-relative"
        *ngIf="talentHistoryList.length; else emptyHistory">
        <div
          class="uj-absolute uj-z-0 uj-w-[1px] uj-left-[24px] uj-bg-border-border uj-h-full uj-top-[30px]"
          style="height: calc(100% - 60px)"></div>
        <ng-container
          *ngFor="let history of talentHistoryList; trackBy: trackById">
          <app-talent-changelog-record [record]="history" />
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</unijob-sheet>

<ng-template #emptyHistory>
  <div class="uj-text-text-grey">
    {{ "historyEntriesWillBeDisplayHere" | i18next }}
  </div>
</ng-template>
