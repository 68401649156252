<div class="uj-flex uj-flex-col uj-gap-d">
  <div
    *ngFor="
      let formGroup of formService.children.controls;
      let i = index;
      trackBy: trackByUUID
    ">
    <app-talent-children-child
      (isInEditMode)="toggleChildrenIsInEditMode($event)"
      [child]="formGroup"></app-talent-children-child>
  </div>
  <button
    *ngIf="!childrenIsInEditMode()"
    class="!uj-w-full"
    ui-library-button-outline
    iconLeft="plus"
    color="dark"
    (click)="addChild()">
    {{ "addChild" | i18next | firstUppercase }}
  </button>
</div>
