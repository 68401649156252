import {
  AfterViewInit,
  Component,
  inject,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { WorkAvailabilityEnum } from '../../../../graphql/generated';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { TalentFormEditService } from '../services/talentFormEdit.service';
import { FocusTrackerDirective } from '../../vacancy-detail/focusTracker.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import {
  FormFieldComponent,
  ModalService,
  SimpleSelectComponent,
  TextInputComponent,
} from '@intemp/unijob-ui2';
import {
  CheckboxModule,
  DatePickerModule,
  InfoBoxModule,
  InputTextareaModule,
  InputTextModule,
  randomId,
  ResponsiveService,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { TalentTargetJobTitleComponent } from './talent-target-job-title/talent-target-job-title.component';
import { TalentPreferencesEmploymentComponent } from './talent-preferences-employment/talent-preferences-employment.component';
import { getWorkArrangementOptions } from '../helpers/getWorkArrangementOptions';
import { TalentSalaryInputComponent } from './talent-salary-input/talent-salary-input.component';
import { TalentPreferredLocationsComponent } from './talent-preferred-locations/talent-preferred-locations.component';

@Component({
  standalone: true,
  selector: 'app-talent-preferences',
  templateUrl: './talent-preferences.component.html',
  imports: [
    ReactiveFormsModule,
    I18NextModule,
    SharedDefaultModule,
    SimpleSelectComponent,
    InfoBoxModule,
    DatePickerModule,
    InputTextareaModule,
    CheckboxModule,
    InputTextModule,
    TalentTargetJobTitleComponent,
    TalentPreferencesEmploymentComponent,
    UjSelectInputModule,
    TalentSalaryInputComponent,
    TalentPreferredLocationsComponent,
    FormFieldComponent,
    TextInputComponent,
  ],
  providers: [],
})
export class TalentPreferencesComponent implements AfterViewInit {
  i18next = inject(I18NextPipe);
  responsiveService = inject(ResponsiveService);
  formService = inject(TalentFormEditService);
  modals = inject(ModalService);

  isSmDown$ = this.responsiveService.isSmDown$;

  @Input() formIsDisabled = false;

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  workArrangement = this.formService.workArrangement;
  preferenceNote = this.formService.preferenceNote;
  workArrangementOptions = getWorkArrangementOptions();

  maxCommutingTimeMinutes = this.formService.maxCommutingTimeMinutes;
  commutingByBicycle = this.formService.commutingByBicycle;
  commutingByCar = this.formService.commutingByCar;
  commutingByPublicTransport = this.formService.commutingByPublicTransport;

  ngAfterViewInit() {
    this.listenToFocusTrackerChange();
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }

  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;
  componentId = randomId();
}
