<div class="uj-flex uj-flex-col">
  <label
    *ngIf="isSmDown$ | async"
    class="uj-block text-m-b uj-mb-b uj-relative"
    >{{ "telephone" | i18next | firstUppercase }} &
    {{ "type" | i18next | firstUppercase }}
  </label>
  <div class="uj-flex uj-flex-col uj-gap-c">
    <ng-container
      *ngFor="
        let phoneGroup of formService.phonesFormArray.controls;
        let i = index;
        trackBy: trackByUUID
      ">
      <app-talent-phones-entry
        [phoneGroup]="phoneGroup"
        [isFirstEntry]="i === 0">
      </app-talent-phones-entry>
      <!-- Divider -->
      @if (i === 0) {
        <span class="unijob-divider size-s border-color-grey uj-pt-b uj-z-0">
          <div class="caption color-grey">
            <span class="text-s">{{
              "otherNumbers" | i18next | firstUppercase
            }}</span>
          </div>
        </span>
      }
    </ng-container>
    <app-talent-phones-entry
      *ngIf="newPhoneEntry"
      [isNewEntry]="true"
      [isFirstEntry]="formService.phonesFormArray.controls.length === 0"
      [phoneGroup]="newPhoneEntry">
    </app-talent-phones-entry>
  </div>
</div>
