import { Component, inject } from '@angular/core';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { InfoBoxModule } from '@intemp/unijob-ui';
import { TalentPrivacyPolicyService } from '../../services/talent-privacy-policy.service';
import { ButtonOutlineComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-data-privacy-policy',
  templateUrl: './talent-data-privacy-policy.component.html',
  imports: [
    I18NextModule,
    SharedDefaultModule,
    InfoBoxModule,
    ButtonOutlineComponent,
  ],
})
export class TalentDataPrivacyPolicyComponent {
  i18next = inject(I18NextPipe);
  service = inject(TalentPrivacyPolicyService);
}
