import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { SupportedLanguage } from '../../graphql/generated';
import * as countriesJSONData from '../../../assets/countries.json';
import { Country } from '../../models/types';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';

type CountryValueAndLabel = {
  value: string;
  label: string;
};

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countriesRawObject = {
    ...countriesJSONData,
  };

  private countriesByCode: Record<string, CountryValueAndLabel> = {};
  private nationalitiesByCode: Record<string, CountryValueAndLabel> = {};

  countries: Country[] = [];

  constructor(private languageService: LanguageService) {
    this.prepareCountries();
  }

  private prepareCountries() {
    getObjectKeys(this.countriesRawObject).forEach((key) => {
      const country = this.countriesRawObject[key] as Country;
      if (!country) {
        console.error('Country not found at index', key, 'in countries.json');
        return;
      }
      if (!country.code) {
        console.error('Country code not found for', key);
        return;
      }
      this.countries.push(country);
      this.countriesByCode[country.code] = this.generateLabels('name', country);
      this.nationalitiesByCode[country.code] = this.generateLabels(
        'genderNeutralWriting',
        country,
      );
    });
  }

  private generateLabels(
    type: 'name' | 'genderNeutralWriting',
    country: Country,
  ) {
    const activeLanguage =
      this.languageService.getActiveLanguage() as SupportedLanguage;
    const nonBreakingSpaceUnicode = '\xa0';
    return {
      value: country.code,
      label:
        country.flag +
        nonBreakingSpaceUnicode +
        nonBreakingSpaceUnicode +
        country[activeLanguage]?.[type],
    };
  }

  getNationalities() {
    return Object.values(this.nationalitiesByCode);
  }

  getCountries() {
    return Object.values(this.countriesByCode);
  }

  getCountryByCode(code: string): CountryValueAndLabel {
    return this.countriesByCode[code];
  }

  getNationalityByCode(code: string): CountryValueAndLabel {
    return this.nationalitiesByCode[code];
  }
}
