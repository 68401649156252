import { TalentFragment } from '../../../../graphql/generated';

/**
 * Check if the field can be reverted, if not, the revert button  should not be displayed in the changelog.
 * @description
 * This is required because some fields should not be reverted.
 * @param fieldName
 */
export function canRevertChangelog(fieldName: keyof TalentFragment) {
  const fieldKeysToExclude: (keyof TalentFragment)[] = [
    'dataPrivacyPolicySentEmails',
    'talentDataAvailableUntil',
    'hasAllowedDataUsageAt',
  ];
  return !fieldKeysToExclude.includes(fieldName);
}
