import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OverlayService } from './overlay.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent {
  constructor(private overlayService: OverlayService) {}

  shown$ = this.overlayService.shown$;
  text$ = this.overlayService.text$;
}
