import { WorkAvailabilityEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getAvailabilityOptions = () => {
  return [
    {
      value: WorkAvailabilityEnum.OPEN_TO_WORK,
      label: i18next.t('WorkAvailabilityEnum.OPEN_TO_WORK'),
    },
    {
      value: WorkAvailabilityEnum.IN_EMPLOYMENT,
      label: i18next.t('WorkAvailabilityEnum.IN_EMPLOYMENT'),
    },
    {
      value: WorkAvailabilityEnum.PLACED,
      label: i18next.t('WorkAvailabilityEnum.PLACED'),
    },
    {
      value: WorkAvailabilityEnum.NOT_OPEN_TO_WORK,
      label: i18next.t('WorkAvailabilityEnum.NOT_OPEN_TO_WORK'),
    },
    {
      value: WorkAvailabilityEnum.BLACKLISTED,
      label: i18next.t('WorkAvailabilityEnum.BLACKLISTED'),
    },
  ];
};
