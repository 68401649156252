import { AbstractControl } from '@angular/forms';
import { markAsTouchedAndDirty } from './markAsTouchedAndDirty';
import { updateValueAndValidity } from './updateValueAndValidity';

export const validateFormControl = (control: AbstractControl): void => {
  markAsTouchedAndDirty(control);
  updateValueAndValidity(control);
  // this is needed to prevent the form from being dirty after validation
  control.markAsPristine();
};
