<div class="uj-flex uj-flex-col">
  <div class="uj-flex uj-flex-col sm:uj-flex-row uj-gap-c uj-relative">
    <div class="uj-flex sm:uj-grid sm:uj-grid-cols-7 uj-grow">
      <div
        [class.!uj-col-span-full]="isNewEntry()"
        [style.padding-left]="(isSmDown$ | async) ? '11px' : '0'"
        class="uj-col-span-1 sm:uj-col-span-4 uj-flex uj-flex-col uj-grow">
        <ui-library-form-field
          fullWidth
          [label]="
            isFirstEntry() && !(isSmDown$ | async)
              ? ('email' | i18next | firstUppercase)
              : ''
          "
          [labelFor]="'addEmail' + componentId">
          <input
            ui-library-text-input
            [id]="'addEmail' + componentId"
            [positionInGroup]="
              (isSmDown$ | async) || isNewEntry() ? 'default' : 'start'
            "
            [placeholder]="'addEmail' | i18next"
            [formControl]="emailGroup().controls.email"
            (change)="onEmailChange($event)"
            type="text" />
        </ui-library-form-field>
      </div>
      <ui-library-form-field
        class="uj-flex uj-flex-col sm:uj-col-span-3"
        *ngIf="!isNewEntry()"
        [label]="
          isFirstEntry() && !(isSmDown$ | async)
            ? ('typ' | i18next | firstUppercase)
            : ''
        ">
        @if (!(isSmDown$ | async)) {
          <ng-template [ngTemplateOutlet]="emailTypeInput"></ng-template>
        } @else {
          <ng-template [ngTemplateOutlet]="contextButton"></ng-template>
        }
      </ui-library-form-field>
    </div>
    <ng-container *ngIf="!isNewEntry()">
      @if (!(isSmDown$ | async)) {
        <div class="uj-flex sm:uj-col-end-7 sm:uj-items-end">
          <a
            ui-library-button-outline
            size="M"
            target="_blank"
            [href]="'mailto:' + emailGroup().controls.email.value"
            class="uj-pointer-events-auto"
            iconOnly="mail"
            color="dark">
          </a>
        </div>
        <div class="uj-flex sm:uj-col-end-8 sm:uj-items-end">
          <ng-template [ngTemplateOutlet]="contextButton"></ng-template>
        </div>
      } @else {
        <div class="uj-pl-[11px]">
          <ng-template [ngTemplateOutlet]="emailTypeInput"></ng-template>
        </div>
      }
    </ng-container>
    <div
      *ngIf="isSmDown$ | async"
      class="uj-absolute uj-w-[3px] uj-bg-violet-290/54 uj-rounded uj-h-full"></div>
  </div>

  <div
    *ngIf="
      showInputError && emailGroup().controls.email.errors?.message.key as error
    "
    class="uj-flex uj-gap-b uj-items-center uj-text-ui-negative">
    <unijob-icon
      icon="alert_triangle"
      size="12"></unijob-icon>
    <div class="text-s">
      {{ error | i18next | firstUppercase }}
    </div>
  </div>
</div>
<ng-template #emailTypeInput>
  <ui-library-simple-select
    [formControl]="emailGroup().controls.emailType"
    [placeholderText]="'chooseCategory' | i18next"
    [options]="emailTypeOptions"
    [positionInGroup]="(isSmDown$ | async) ? 'default' : 'end-seamless'"
    size="M"
    design="outline"
    backgroundColor="white"></ui-library-simple-select>
</ng-template>
<ng-template #contextButton>
  <div class="uj-flex sm:uj-col-end-8 sm:uj-items-end">
    <button
      ui-library-button-outline
      [positionInGroup]="(isSmDown$ | async) ? 'end' : 'default'"
      type="button"
      color="dark"
      iconOnly="menu_dots_vertical"
      [contextMenu]="moreActionsContextMenu"
      (mousedown)="$event.stopPropagation()"
      (touchstart)="$event.stopPropagation()"></button>
  </div>
</ng-template>

<ng-template #moreActionsContextMenu>
  <a
    *ngIf="isSmDown$ | async"
    ui-library-button-text
    type="button"
    color="dark"
    target="_blank"
    [href]="'mailto:' + emailGroup().controls.email.value"
    class="uj-pointer-events-auto"
    iconLeft="mail">
    {{ "writeEmail" | i18next }}
  </a>
  <button
    (click)="setPrimaryEmail()"
    ui-library-button-text
    disabled="!!emailGroup().controls.isPrimary.value"
    type="button"
    color="dark"
    [iconLeft]="emailGroup().controls.isPrimary.value ? 'pin_pinned' : 'pin'">
    {{ "primary" | i18next | firstUppercase }}
  </button>
  <button
    (click)="deleteEmail()"
    ui-library-button-text
    type="button"
    color="dark"
    iconLeft="trash"
    class="hover:uj-text-ui-negative">
    {{ "delete" | i18next }}
  </button>
</ng-template>

<ui-library-modal
  [id]="deleteConfirmationModalId"
  size="S">
  <ng-container slot="header">
    {{ "deleteEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <div class="uj-flex uj-flex-col uj-gap-d">
      <span>{{
        "areYouSureYouWantToDeleteThisEntry" | i18next | firstUppercase
      }}</span>
      <unijob-info-box
        class="uj-relative"
        [message]="
          formService.emailsFormArray.controls.at(1)?.value?.email ?? ''
        "></unijob-info-box>

      <span>{{ "willBeYourPrimaryEmail" | i18next }}</span>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="modals.close(deleteConfirmationModalId)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deletePrimaryEmail()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button"
        iconLeft="trash">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>

<ui-library-modal
  [id]="deleteConfirmationModalId"
  size="S">
  <ng-container slot="header">
    {{ "deleteEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "areYouSureYouWantToDeleteThisEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="modals.close(deleteConfirmationModalId)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deleteEmail()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button"
        iconLeft="trash">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>
