import { Component, inject, signal } from '@angular/core';
import {
  ContextMenuModule,
  DatePickerModule,
  IconModule,
  InputTextModule,
  OverflowGradientModule,
  randomId,
  SheetModule,
  SwitchModule,
  TabModule,
  TooltipModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { VacanciesStatusIndicatorModule } from '../../../vacancies-status-indicator/vacancies-status-indicator.module';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { UserAvatarModule } from '../../../user-avatar/user-avatar.module';
import { LetDirective } from '@ngrx/component';
import { UserInfoContextMenuModule } from '../../../user/user-info-context-menu/user-Info-context-menu.module';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { ArrayActionEnum } from '../../../../../graphql/generated';

import { TalentRecommendationsEntryComponent } from './talent-recommendations-entry/talent-recommendations-entry.component';
import { RecommendationsFormGroup } from '../../talent-sheet.types';
import { AvatarComponent, ButtonOutlineComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-recommendations',
  templateUrl: './talent-recommendations.component.html',
  imports: [
    SheetModule,
    AsyncPipe,
    AvatarComponent,
    I18NextModule,
    IconModule,
    NgIf,
    OverflowGradientModule,
    SharedDefaultModule,
    TabModule,
    UserAvatarModule,
    VacanciesStatusIndicatorModule,
    LetDirective,
    UserInfoContextMenuModule,
    ContextMenuModule,
    FormsModule,
    ReactiveFormsModule,
    UjSelectInputModule,
    InputTextModule,
    DatePickerModule,
    SwitchModule,
    TalentRecommendationsEntryComponent,
    TooltipModule,
    ButtonOutlineComponent,
  ],
})
export class TalentRecommendationsComponent {
  formService = inject(TalentFormEditService);
  childrenIsInEditMode = signal(false);

  addRecommendation() {
    const newRecommendation = new FormGroup<RecommendationsFormGroup>({
      uuid: new FormControl<string>(randomId()),
      type: new FormControl<ArrayActionEnum>(ArrayActionEnum.ADDED),
      firstName: new FormControl<string | null>('', [Validators.required]),
      lastName: new FormControl<string | null>('', [Validators.required]),
      email: new FormControl<string | null>('', [
        Validators.required,
        Validators.email,
      ]),
      isNewEntry: new FormControl<boolean>(true),
    });
    this.formService.recommendations.push(newRecommendation);
    this.childrenIsInEditMode.set(true);
  }

  trackByUUID(index: number, item: FormGroup<RecommendationsFormGroup>) {
    return item.value.uuid;
  }

  toggleRecommendationIsInEditMode($event: boolean) {
    this.childrenIsInEditMode.set($event);
  }
}
