<div class="uj-p-[24px]">
  <div class="uj-flex uj-gap-d">
    <unijob-icon
      [color]="hasError() ? 'red' : 'green'"
      [icon]="
        message() === 'loading'
          ? 'loading_spin'
          : message() === 'invalidToken'
            ? 'x_circle'
            : 'check'
      "></unijob-icon>
    <h1>{{ message() | i18next }}</h1>
  </div>
</div>
