import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { TalentFormEditService } from '../services/talentFormEdit.service';
import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { IconModule } from '@intemp/unijob-ui';
import { WorkAvailabilityEnum } from '../../../../graphql/generated';
import { DotComponent, TagComponent } from '@intemp/unijob-ui2';

type TalentStatus = 'dataPrivacyPolicyNotAccepted' | 'newApplication';

@Component({
  standalone: true,
  selector: 'app-talent-status-indicator',
  templateUrl: './talent-status-indicator.component.html',
  imports: [
    NgIf,
    I18NextModule,
    IconModule,
    DotComponent,
    TagComponent,
    NgForOf,
    NgTemplateOutlet,
    NgClass,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentStatusIndicatorComponent {
  formService = inject(TalentFormEditService);
  talent = this.formService.talent;
  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;

  isPositionedInHeader = input<boolean>(false);

  talentStatus = computed(() => {
    const status: TalentStatus[] = [];
    if (!this.talent()) return status;
    if (!this.talent()?.hasAllowedDataUsageAt) {
      status.push('dataPrivacyPolicyNotAccepted');
    }
    return status;
  });
}
