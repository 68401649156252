import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ContextMenuModule,
  IconModule,
  InputTextModule,
  SheetModule,
  TooltipModule,
  UnijobModule,
} from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { VacancyChangelogComponent } from './vacancy-changelog.component';
import { VacancyChangelogEntryComponent } from './vacancy-changelog-entry/vacancy-changelog-entry.component';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { VacancyChangelogRecordComponent } from './vacancy-changelog-record/vacancy-changelog-record.component';
import { VacancyChangelogStatusEntryComponent } from './vacancy-changelog-status-entry/vacancy-changelog-status-entry.component';
import { UserInfoContextMenuModule } from '../user/user-info-context-menu/user-Info-context-menu.module';
import { LetDirective } from '@ngrx/component';
import {
  AvatarComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ModalComponent,
} from '@intemp/unijob-ui2';

@NgModule({
  declarations: [
    VacancyChangelogComponent,
    VacancyChangelogEntryComponent,
    VacancyChangelogRecordComponent,
    VacancyChangelogStatusEntryComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    AvatarComponent,
    InputTextModule,
    FormsModule,
    UnijobModule,
    SharedDefaultModule,
    ContextMenuModule,
    TooltipModule,
    SheetModule,
    UserAvatarModule,
    UserInfoContextMenuModule,
    LetDirective,
    ButtonOutlineComponent,
    ButtonSolidComponent,
    ModalComponent,
  ],
  exports: [VacancyChangelogComponent],
})
export class VacancyChangelogModule {}
