import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { setTimeout$, SheetService } from '@intemp/unijob-ui';
import { filter, Observable, Subject, switchMap } from 'rxjs';
import {
  ChannelFragment,
  ChannelQuery,
  ChannelQueryGQL,
  FilterCriteriaConditionEnum,
  SortDirEnum,
  VacancyCriteriaFieldEnum,
  VacancyCriteriaItemInput,
  VacancyListItemFragment,
  VacancySortEnum,
} from '../../../graphql/generated';
import { UserService } from '../../../models/shared/user/user.service';
import { ChannelsService } from '../../../models/unibase/channels/channels.service';
import { VacanciesService } from '../../../models/unibase/vacancies/vacancies.service';
import { randomId } from '../../helpers/functions/randomId';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../global-sheets/global-sheets.service';

@Component({
  selector: 'app-channel-slots',
  templateUrl: './channel-slots.component.html',
  styleUrls: ['./channel-slots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('vacancyListAnimation', [
      transition(':enter, * => 0, * => -1', []),
      transition(':increment', [
        query(
          ':enter',
          [
            style({ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }),
            stagger(15, [
              animate(
                '100ms ease-out',
                style({
                  opacity: 1,
                  height: '*',
                  paddingTop: '*',
                  paddingBottom: '*',
                }),
              ),
            ]),
          ],
          { optional: true },
        ),
      ]),
      transition(':decrement', [
        query(':leave', [
          stagger(15, [
            animate(
              '100ms ease-out',
              style({ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }),
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class ChannelSlotsComponent implements AfterViewInit, OnDestroy {
  @Input() sheetId = 'channel-slots' + randomId();
  @Output() sheetClosed = new EventEmitter<string>();

  destroyed$ = new Subject<void>();

  _channelUuid: string | undefined;

  removedVacancies: string[] = [];

  @Input({ required: true })
  set channelUuid(uuid: string) {
    this._channelUuid = uuid;
    this.initChannelObservable(uuid);
  }

  public channel$: Observable<ApolloQueryResult<ChannelQuery>> | undefined =
    undefined;

  public vacancies$:
    | Observable<{
        items: readonly VacancyListItemFragment[];
        changedItems: readonly VacancyListItemFragment[];
        removedItems: readonly string[];
        addedItems: readonly VacancyListItemFragment[];
        totalCount: number | null;
      }>
    | undefined = undefined;

  constructor(
    public sheetService: SheetService,
    public vacanciesService: VacanciesService,
    public channelQueryGQL: ChannelQueryGQL,
    public userService: UserService,
    public cd: ChangeDetectorRef,
    public channelsService: ChannelsService,
    private globalSheetsService: GlobalSheetsService,
  ) {}

  getChannelFilterCriteriaItems(
    channel: ChannelFragment,
  ): VacancyCriteriaItemInput[] {
    return [
      {
        field: VacancyCriteriaFieldEnum.CURRENT_CHANNEL_UUID,
        values: [channel.uuid],
        condition: FilterCriteriaConditionEnum.EQ,
      },
      {
        field: VacancyCriteriaFieldEnum.CURRENT_CHANNEL_STATUS,
        values: ['PENDING', 'RUNNING'],
        condition: FilterCriteriaConditionEnum.CONTAINS,
      },
    ];
  }

  initVacanciesObservableForChannel(channel: ChannelFragment) {
    if (channel.maxRunningAndPendingSlotsPerUser) {
      this.vacancies$ = this.userService.user$.pipe(
        filter((user) => !!user),
        switchMap((user) =>
          this.vacanciesService.subscribeVacancies({
            limit: 100,
            sort: {
              sortBy: VacancySortEnum.CREATED_AT,
              sortDir: SortDirEnum.DESC,
            },
            filter: {
              criteria: [
                ...this.getChannelFilterCriteriaItems(channel),
                {
                  field: VacancyCriteriaFieldEnum.RESPONSIBLE_USER_ID,
                  values: [user ? user._id : ''],
                  condition: FilterCriteriaConditionEnum.EQ,
                },
              ],
            },
          }),
        ),
      );
    } else if (channel.maxRunningAndPendingSlotsTotal) {
      this.vacancies$ = this.vacanciesService.subscribeVacancies({
        limit: 100,
        sort: {
          sortBy: VacancySortEnum.CREATED_AT,
          sortDir: SortDirEnum.DESC,
        },
        filter: {
          criteria: this.getChannelFilterCriteriaItems(channel),
        },
      });
    }
  }

  initChannelObservable(uuid: string) {
    this.channel$ = this.channelQueryGQL.fetch({
      uuid: uuid,
    });

    this.channel$.subscribe((result) => {
      const channel = result.data?.channelQuery;
      if (channel) {
        this.initVacanciesObservableForChannel(channel);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout$(() => {
      this.openSheet().then();
    });
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }

  completeChannel(vacancy: VacancyListItemFragment, channel: ChannelFragment) {
    this.removedVacancies.push(vacancy.uuid);
    this.cd.markForCheck();
    this.channelsService
      .completeChannel({
        vacancyUuid: vacancy.uuid,
        channelUuid: channel.uuid,
      })
      .subscribe({});
  }

  isRemoving(vacancy: VacancyListItemFragment) {
    return this.removedVacancies.includes(vacancy.uuid);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getChannelConfig(vacancy: VacancyListItemFragment) {
    return vacancy?.derivedFields?.currentChannelConfigurations?.find(
      (config) => config.channelReference.uuid === this._channelUuid,
    );
  }

  openVacancy(vacancy: VacancyListItemFragment) {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.VACANCY_EDIT,
      uuid: vacancy.uuid,
      action: undefined,
    });
  }
}
