import { Component, computed, input } from '@angular/core';
import { MatchFragment } from '../../../../../graphql/generated';
import {
  AvatarComponent,
  ButtonTextComponent,
  CardComponent,
  DotComponent,
  FormFieldComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { TooltipModule } from '@intemp/unijob-ui';
import { LetDirective } from '@ngrx/component';

@Component({
  standalone: true,
  selector: 'app-match-responsible-contact-card',
  templateUrl: './match-responsible-contact-card.component.html',
  imports: [
    CardComponent,
    AvatarComponent,
    I18NextModule,
    SharedDefaultModule,
    DotComponent,
    ButtonTextComponent,
    TooltipModule,
    LetDirective,
    FormFieldComponent,
  ],
  providers: [],
})
export class MatchResponsibleContactCardComponent {
  match = input.required<MatchFragment>();

  companyContact = computed(() => {
    return this.match().company?.companyContact;
  });

  companyContactLetters = computed(() => {
    const firstName = this.companyContact()?.firstName;
    const lastName = this.companyContact()?.lastName;
    if (!firstName || !lastName) return;
    return firstName.slice(0, 1) + lastName.slice(0, 1);
  });
}
