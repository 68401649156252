import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  InputTextComponent,
  SheetService,
  ToastService,
} from '@intemp/unijob-ui';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ContactFragment,
  UnibaseContactNormalizedFragment,
} from '../../../../../graphql/generated';
import { UnibaseContactsService } from '../../../../../models/unibase/unibase-contacts/unibase-contacts.service';
import { randomId } from '../../../../helpers/functions/randomId';

@Component({
  selector: 'app-vacancy-unibase-contact-import',
  templateUrl: './unibase-contact-import.component.html',
})
export class VacancyUnibaseContactImportComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  @Input() sheetId = randomId();
  @Input() companyLocationUuid?: string = '';
  @Output() importComplete = new EventEmitter<ContactFragment>();
  @Output() sheetClosed = new EventEmitter<string>();

  @ViewChild(InputTextComponent, { static: false })
  searchElement?: InputTextComponent;

  public currentUnibaseContacts: UnibaseContactNormalizedFragment[] = [];
  public currentUnibaseContactOptions: { value: string }[] = [];
  public currentUnibaseContactsAlreadyImported: UnibaseContactNormalizedFragment[] =
    [];

  public selectedContactValue = '';
  public isLoading = false;
  public searchTerm = '';
  public loadingSearchTerm: string | undefined;

  private searchFieldChangeEmitter = new Subject<string>();

  constructor(
    public sheetService: SheetService,
    private unibaseContactsService: UnibaseContactsService,
    private toastService: ToastService,
  ) {}

  setSearchTerm(searchTerm: string): void {
    this.searchTerm = searchTerm;
  }

  focusSearchField(): void {
    setTimeout(() => {
      // Don't know why delay is required, could be related to animation?
      this.searchElement?.focus();
    }, 300);
  }

  ngOnInit(): void {
    this.searchFieldChangeEmitter
      .pipe(takeUntil(this.destroyed$), debounceTime(300))
      .subscribe((searchTerm) => {
        this.searchContacts(searchTerm);
      });
  }

  searchFieldInputChanged(searchTerm: string): void {
    const sheetIsOpen = this.sheetService.sheets.find(
      (sheet) => sheet.id === this.sheetId,
    )?.isOpen.value;
    if (
      this.loadingSearchTerm === searchTerm ||
      !this.companyLocationUuid ||
      !sheetIsOpen
    ) {
      return;
    }
    this.searchFieldChangeEmitter.next(searchTerm);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
  }

  async closeSheet(): Promise<void> {
    this.sheetService.close(this.sheetId);
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }

  async searchContacts(searchTerm: string): Promise<void> {
    this.isLoading = true;
    this.loadingSearchTerm = searchTerm;

    const contactObservable =
      await this.unibaseContactsService.unibaseContactsQuery({
        criteria: {
          search: searchTerm,
          companyLocationUuid: this.companyLocationUuid,
        },
      });
    contactObservable.subscribe({
      next: (result) => {
        this.currentUnibaseContacts = result.contacts.filter((company) => {
          return !company.importedContactUuid;
        });
        this.currentUnibaseContactsAlreadyImported = result.contacts.filter(
          (company) => {
            return !!company.importedContactUuid;
          },
        );
        this.currentUnibaseContactOptions = this.currentUnibaseContacts.map(
          (company) => {
            return {
              value: company.CSTIDs.join(''),
            };
          },
        );
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.toastService.makeToast({
          type: 'ERROR',
          message:
            'Keine Kontakte für diese Filiale gefunden. Stammt diese Filiale aus Unibase?',
        });
        this.isLoading = false;
        this.currentUnibaseContactOptions = [];
      },
    });
  }

  getContactByValue(value: string) {
    return this.currentUnibaseContacts.find((contact) => {
      return contact.CSTIDs.join('') === value;
    });
  }

  handleImportContactClick(): void {
    if (!this.selectedContactValue) {
      return;
    }
    if (!this.companyLocationUuid) {
      console.error('Missing companyLocationUuid in contact-import component');
      return;
    }
    const contact = this.getContactByValue(this.selectedContactValue);
    if (!contact) {
      console.error(
        'Internal error in contact-import component: Contact not found by ID ' +
          this.selectedContactValue,
      );
      return;
    }
    this.isLoading = true;
    this.unibaseContactsService
      .unibaseContactImport(contact.CSTIDs[0], this.companyLocationUuid)
      .subscribe((contact) => {
        this.isLoading = false;
        this.importComplete.emit(contact);
        this.selectedContactValue = '';
      });
  }
}
