import { AbstractControl } from '@angular/forms';
import { enableFormControl } from './enableFormControl';
import { disableFormControl } from './disableFormControl';

export const setFormControlEnabledState =
  (control: AbstractControl) => (enableIt: boolean) => {
    if (enableIt) {
      enableFormControl(control);
    } else {
      disableFormControl(control);
    }
  };
